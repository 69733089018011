<template>
  <el-avatar ref="myAvatar" :key="src + new Date()" :src="src" class="my-avatar" fit="cover" :size="size" @error="$returnTrue">
    <img src="@/assets/avatar.png">
  </el-avatar>
</template>

<script>
export default {
  name: 'Avatar',
  props: {
    src: {
      type: [String],
      default: ''
    },
    size: {
      type: [Number],
      default: 72
    }
  },
  data() {
    return {

    }
  },
  watch: {
    src: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          const children = this.$refs.myAvatar.$el.children
          if (children && children.length) {
            for (let i = 0; i < children.length; i++) {
              if (children[i].nodeName.toLocaleLowerCase() === 'img') {
                children[i].style.height = this.size + 'px'
                children[i].style.width = this.size + 'px'
              }
            }
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
