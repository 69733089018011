var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      _c("el-card", [
        _c("div", { staticClass: "card--title" }, [_vm._v("支付信息")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c(
              "el-row",
              { staticClass: "card--info", attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("支付编号：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderInfo.id)),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("商户：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderInfo.companySName)),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("平台服务商："),
                  ]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.paymentOrderInfo.platformServiceProvider)
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("支付清单：")]),
                  !_vm.$u.test.isEmpty(_vm.paymentOrderInfo.paymentInventory)
                    ? _c(
                        "span",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function ($event) {
                              return _vm.openFile(
                                _vm.paymentOrderInfo.paymentInventory
                              )
                            },
                          },
                        },
                        [_vm._v("查看")]
                      )
                    : _vm._e(),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("关联的任务："),
                  ]),
                  !_vm.$u.test.isEmpty(_vm.taskInfo.taskName)
                    ? _c(
                        "span",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function ($event) {
                              _vm.showTaskVo = true
                            },
                          },
                        },
                        [_vm._v("查看")]
                      )
                    : _vm._e(),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("交付支付验收单："),
                  ]),
                  !_vm.$u.test.isEmpty(
                    _vm.paymentOrderInfo.acceptanceCertificate
                  )
                    ? _c(
                        "span",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function ($event) {
                              return _vm.openFile(
                                _vm.paymentOrderInfo.acceptanceCertificate
                              )
                            },
                          },
                        },
                        [_vm._v("查看")]
                      )
                    : _vm._e(),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("总包支付回单："),
                  ]),
                  !_vm.$u.test.isEmpty(_vm.paymentOrderInfo.subpackagePayment)
                    ? _c(
                        "span",
                        {
                          staticClass: "pointer",
                          on: {
                            click: function ($event) {
                              return _vm.openFile(
                                _vm.paymentOrderInfo.subpackagePayment
                              )
                            },
                          },
                        },
                        [_vm._v("查看")]
                      )
                    : _vm._e(),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("状态：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("payTotalAndSubStatus")(
                          _vm.paymentOrderInfo.paymentOrderStatus
                        )
                      )
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("支付时间：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderInfo.paymentDate)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("开票清单明细信息")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c("worker-pay-list", { ref: "payList", attrs: { type: 0 } }),
            _c("div", { staticClass: "table-wrap" }, [
              _c("ul", { staticClass: "table" }, [
                _c("li", { staticClass: "u-flex" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", { staticClass: "txt" }, [_vm._v("购买方")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _vm._v("名称：" + _vm._s(_vm.buyerInfo.companyName)),
                    ]),
                    _c("p", [
                      _vm._v(
                        "纳税人识别号：" + _vm._s(_vm.buyerInfo.creditCode)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址、电话：" +
                          _vm._s(_vm.buyerInfo.addressAndTelephone)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        " 开户行及账号：" +
                          _vm._s(_vm.buyerInfo.bankAndAccount) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("li", { staticClass: "count" }, [
                  _vm._v(
                    "价税合计：" + _vm._s(_vm.$u.formatMoney(_vm.totalTaxPrice))
                  ),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "form-wrap" }),
          ],
          1
        ),
      ]),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("开票类目")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c("ul", { staticClass: "invoiceCatalogVo-wrap" }, [
              _c("li", { staticClass: "item flex align-items" }, [
                _c("span", [_vm._v("服务类型：")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.invoiceCatalogVo.billingCategory)),
                ]),
              ]),
              _c("li", { staticClass: "item flex align-items" }, [
                _c("span", [_vm._v("具体服务内容：")]),
                _c("span", [_vm._v(_vm._s(_vm.invoiceCatalogVo.serviceType))]),
              ]),
              _c("li", { staticClass: "item flex align-items" }, [
                _c("span", [_vm._v("开票类目：")]),
                _c("span", [_vm._v(_vm._s(_vm.invoiceCatalogVo.serviceType))]),
              ]),
            ]),
          ]
        ),
      ]),
      _c(
        "el-card",
        { staticClass: "u-m-t-20" },
        [
          _c(
            "el-form",
            {
              staticClass: "demo-form-inline",
              attrs: { "label-position": "right", "label-width": "100px" },
            },
            [
              _c("el-form-item", { attrs: { label: "发票：", required: "" } }, [
                _c(
                  "div",
                  { staticClass: "u-flex u-col-center" },
                  [
                    _vm.type !== "examine"
                      ? _c("button-upload", {
                          attrs: {
                            "custom-type": [
                              "pdf",
                              "jpg",
                              "png",
                              "rar",
                              "zip",
                              "7z",
                              "arj",
                            ],
                            text: "发票(png/jpg/png/zip)",
                          },
                          on: {
                            "upload-success": function ($event) {
                              return _vm.setField("makerInvoiceUrl", $event)
                            },
                          },
                        })
                      : _vm._e(),
                    !_vm.$u.test.isEmpty(_vm.formData.makerInvoiceUrl)
                      ? _c(
                          "el-button",
                          {
                            staticClass: "u-m-l-10",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.$preViewer(
                                  _vm.formData.makerInvoiceUrl
                                )
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("el-form-item", { attrs: { label: "税票：" } }, [
                _c(
                  "div",
                  { staticClass: "u-flex u-col-center" },
                  [
                    _vm.type !== "examine"
                      ? _c("button-upload", {
                          attrs: {
                            "custom-type": [
                              "pdf",
                              "jpg",
                              "png",
                              "rar",
                              "zip",
                              "7z",
                              "arj",
                            ],
                            text: "上传税票(png/jpg/png/zip)",
                          },
                          on: {
                            "upload-success": function ($event) {
                              return _vm.setField("makerTaxUrl", $event)
                            },
                          },
                        })
                      : _vm._e(),
                    !_vm.$u.test.isEmpty(_vm.formData.makerTaxUrl)
                      ? _c(
                          "el-button",
                          {
                            staticClass: "u-m-l-10",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.$preViewer(_vm.formData.makerTaxUrl)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _vm.type == "add" || _vm.type == "editor"
            ? _c(
                "div",
                {
                  staticClass: "flex justify-start align-items",
                  staticStyle: { height: "100px", "margin-left": "80px" },
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v("提交")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "关联的任务", visible: _vm.showTaskVo },
          on: {
            "update:visible": function ($event) {
              _vm.showTaskVo = $event
            },
          },
        },
        [
          _c("div", [
            _c("span", { staticClass: "label" }, [_vm._v("任务编号：")]),
            _c("span", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.taskInfo.taskCode)),
            ]),
          ]),
          _c("div", [
            _c("span", { staticClass: "label" }, [_vm._v("任务名称：")]),
            _c("span", { staticClass: "value" }, [
              _vm._v(_vm._s(_vm.taskInfo.taskName)),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }