var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: !_vm.isComponent ? "u-p-20 wrap-fixed-content" : "" },
    [
      +_vm.baseInfo.paymentOrderStatus === 5
        ? _c(
            "el-card",
            {
              staticClass: "u-m-b-10",
              staticStyle: { border: "1px solid #FF1A2E" },
            },
            [
              _c("div", { staticClass: "u-flex u-col-center u-p-lf-40" }, [
                _c("div", { staticClass: "u-text-center" }, [
                  _c("i", {
                    staticClass: "el-icon-error",
                    staticStyle: { "font-size": "72px", color: "#FF1A2E" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "u-m-t-10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [_vm._v("被驳回")]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "u-m-l-20 u-p-l-40 u-flex",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("div", { staticClass: "u-flex-1" }, [
                      _c(
                        "div",
                        { staticClass: "u-tips-color u-font-14 u-m-l-20" },
                        [_vm._v("请核对并修改以下信息后，再重新提交。")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "u-p-20 u-m-t-10",
                          staticStyle: { "background-color": "#fafafa" },
                        },
                        [
                          _c("div", { staticClass: "u-font-16 u-m-b-10" }, [
                            _vm._v("您提交的内容有如下问题："),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "u-font-14",
                              staticStyle: { color: "#8e9193" },
                            },
                            [_vm._v(_vm._s(_vm.baseInfo.reasonsForRejection))]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "card--title" }, [_vm._v("总包+分包信息")]),
          _c(
            "div",
            { staticClass: "card--content" },
            [
              _c(
                "el-row",
                { staticClass: "card--info", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付编号："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.paymentOrderId)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [_vm._v("商户：")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.companyName)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("平台服务商："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.platformServiceProvider)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("项目合同："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: { url: _vm.baseInfo.companyContract, btn: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付清单："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: {
                            url: _vm.baseInfo.paymentInventory,
                            btn: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("关联任务："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        !_vm.$u.test.isEmpty(_vm.baseInfo.taskId)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showTask(_vm.baseInfo.taskId)
                                  },
                                },
                              },
                              [_vm._v("查看 ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("交付支付验收单："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: {
                            url: _vm.baseInfo.acceptanceCertificate,
                            btn: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [_vm._v("状态：")]),
                    _c("span", {
                      staticClass: "value",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.filterContent(
                            _vm.baseInfo.paymentOrderStatus,
                            _vm.$enum.payTotalAndSubStatus()
                          )
                        ),
                      },
                    }),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("创建时间："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.creatDate)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付时间："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.paymentDate)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("总包支付回单："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _vm.baseInfo.turnkeyProjectPayment
                          ? _c("file-icon", {
                              attrs: {
                                url: _vm.baseInfo.turnkeyProjectPayment,
                              },
                            })
                          : _c("button-upload", {
                              attrs: {
                                "default-action":
                                  "/platform/paymentOrder/uploadPackagePaymentReceipt?id=" +
                                  _vm.baseInfo.paymentOrderId,
                                "success-toast": false,
                                "error-toast": false,
                                "return-all": "",
                                name: "uploadJpgOrPdf",
                                type: "image/pdf",
                                "btn-type": "text",
                                text: "上传(jpg)",
                              },
                              on: {
                                "upload-success": function ($event) {
                                  return _vm.uploadExcel($event)
                                },
                                "upload-error": _vm.showErrorModal,
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "u-m-t-20",
        },
        [
          _c("div", { staticClass: "card--title" }, [_vm._v("支付清单明细")]),
          _c(
            "div",
            { staticStyle: { padding: "20px 10px" } },
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: { inline: true, model: _vm.formData, size: "small" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.formData.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mobile", $$v)
                          },
                          expression: "formData.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号", prop: "idCode" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.formData.idCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "idCode", $$v)
                          },
                          expression: "formData.idCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "支付状态", prop: "orderStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formData.orderStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "orderStatus", $$v)
                            },
                            expression: "formData.orderStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "支付失败", value: "-1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "未支付", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "支付中", value: "4" },
                          }),
                          _c("el-option", {
                            attrs: { label: "支付成功", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已关闭", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getPaymentInventoryList("reset")
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c("el-button", { on: { click: _vm.reset } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card--content" },
            [
              _vm.type == "edit"
                ? _c(
                    "el-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading },
                      on: { click: _vm.closePay },
                    },
                    [_vm._v("批量关闭支付")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.downloadExcel },
                },
                [_vm._v("导出Excel")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.OneclickExcel },
                },
                [_vm._v("一键导出")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.ExReceipt },
                },
                [_vm._v("导出回单")]
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "end",
                    "align-items": "center",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "风控校验中 " +
                        _vm._s(_vm.progressInfo.countInProgress || 0) +
                        " 人，风控校验成功 " +
                        _vm._s(_vm.progressInfo.countSuccess || 0) +
                        " 人，风控校验失败 " +
                        _vm._s(_vm.progressInfo.countFailed || 0) +
                        " 人"
                    ),
                  ]),
                  _vm.progressInfo.countFailed
                    ? _c(
                        "el-button",
                        {
                          staticClass: "u-m-l-10",
                          attrs: {
                            type: "primary",
                            size: "small",
                            loading: _vm.errLoading,
                          },
                          on: { click: _vm.downloadErrList },
                        },
                        [_vm._v("下载校验错误清单")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tbLoading,
                      expression: "tbLoading",
                    },
                  ],
                  ref: "multipleTable",
                  attrs: {
                    "row-key": "id",
                    data: _vm.list,
                    "max-height": "600px",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "selection",
                      "reserve-selection": "",
                      width: "55",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "workerName",
                      label: "姓名",
                      width: "100",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mobileCode",
                      width: "120",
                      label: "电话",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "idCardCode",
                      width: "180",
                      label: "身份证号",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bankName",
                      label: "开户行",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "bankCode",
                      label: "收款账号",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(" " + _vm._s(scope.row.bankCode) + " "),
                            _vm.isEdit || +scope.row.paymentStatus !== 1
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleChangeBank(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("修改")]
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "发放金额", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$u.test.isEmptyNotZero(
                                    scope.row.taskMoney
                                  )
                                    ? "￥" +
                                        _vm.$u.formatMoney(scope.row.taskMoney)
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "创客到手费", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$u.test.isEmptyNotZero(
                                    scope.row.realMoney
                                  )
                                    ? "￥" +
                                        _vm.$u.formatMoney(scope.row.realMoney)
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "服务费",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "创客：" +
                                  _vm._s(
                                    "￥" +
                                      (
                                        (+scope.row.receviceTax *
                                          +scope.row.taskMoney) /
                                          100 || 0
                                      ).toFixed(2)
                                  )
                              ),
                            ]),
                            _c(
                              "div",
                              [
                                _c("span", [
                                  _vm._v(
                                    "商户：" +
                                      _vm._s("￥" + scope.row.serviceMoney)
                                  ),
                                ]),
                                _vm.isCheck
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleChangeServiceMoney(
                                              scope.row,
                                              scope.$index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("修改")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "服务费率",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "创客：" +
                                  _vm._s(
                                    !_vm.$u.test.isEmptyNotZero(
                                      scope.row.receviceTax
                                    )
                                      ? scope.row.receviceTax + "%"
                                      : "-"
                                  )
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "商户：" +
                                  _vm._s(
                                    !_vm.$u.test.isEmptyNotZero(
                                      scope.row.merchantTax
                                    )
                                      ? scope.row.merchantTax + "%"
                                      : "-"
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商户总支出", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$u.test.isEmptyNotZero(
                                    scope.row.merchantPaymentMoney
                                  )
                                    ? "￥" +
                                        _vm.$u.formatMoney(
                                          scope.row.merchantPaymentMoney
                                        )
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "支付状态", width: "120", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$options.filters.filterContent(
                                    scope.row.paymentStatus,
                                    _vm.$enum.paymentStatus()
                                  )
                                ),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "回单", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.receipt
                              ? _c("file-icon", {
                                  attrs: { url: scope.row.receipt },
                                })
                              : _c("button-upload", {
                                  attrs: {
                                    "default-action":
                                      "/platform/paymentOrder/uploadReceipt?paymentInventoryId=" +
                                      scope.row.id,
                                    "success-toast": false,
                                    "error-toast": false,
                                    "return-all": "",
                                    name: "uploadJpgOrPdf",
                                    type: "image/pdf",
                                    "btn-type": "text",
                                    text: "上传(pdf)",
                                  },
                                  on: {
                                    "upload-success": function ($event) {
                                      return _vm.uploadExcel($event)
                                    },
                                    "upload-error": _vm.showErrorModal,
                                  },
                                }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "tradeFailReason",
                      label: "支付失败原因",
                      align: "center",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pager-box" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next, jumper",
                      "current-page": _vm.paramsData.pageNo,
                      "page-size": _vm.paramsData.pageSize,
                      "page-count": _vm.pageTotal,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.paramsData, "pageNo", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.paramsData, "pageNo", $event)
                      },
                      "current-change": _vm.getPaymentInventoryList,
                    },
                  }),
                ],
                1
              ),
              _vm.baseInfo.companyMoney
                ? _c("div", { staticClass: "u-m-t-10" }, [
                    _vm._v(
                      "合计（商户总支出）：￥" +
                        _vm._s(_vm.$u.formatMoney(_vm.baseInfo.realMoney))
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.show
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("总包支付信息")]),
            _c("div", { staticClass: "card--content" }, [
              _c(
                "div",
                { staticClass: "card--info" },
                [
                  _c("div", { staticClass: "card--info-item" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付方式："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("allUnionpayBankType")(
                              _vm.baseInfo.paymentMode
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _vm.totalForm.payType === 0
                    ? [
                        _c("table", { staticClass: "c-table is-edit" }, [
                          _c("tr", [
                            _c("td", [
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("支付方："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.baseInfo.companyName)),
                                ]),
                              ]),
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("开户行："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.baseInfo.bankName)),
                                ]),
                              ]),
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("银行账号："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.baseInfo.bankCode)),
                                ]),
                              ]),
                            ]),
                            _c("td", [
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("收款方："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.baseInfo.payee)),
                                ]),
                              ]),
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("开户行："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.baseInfo.payeeBankName)),
                                ]),
                              ]),
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("银行账号："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.baseInfo.payeeBankCode)),
                                ]),
                              ]),
                            ]),
                          ]),
                          _c("tr", { class: _vm.isEdit ? "" : "no-border" }, [
                            _c("td", { attrs: { colspan: "2" } }, [
                              _c(
                                "div",
                                {
                                  staticClass: "blue-box",
                                  class: _vm.isEdit ? "is-edit" : "is-view",
                                },
                                [
                                  _vm._v("转账金额："),
                                  _c("span", { staticClass: "money" }, [
                                    _vm._v(
                                      "￥ " +
                                        _vm._s(
                                          _vm.$u.formatMoney(
                                            _vm.baseInfo.realMoney
                                          )
                                        )
                                    ),
                                  ]),
                                  _c(
                                    "span",
                                    [
                                      _vm._v(" （ "),
                                      _c("span", [
                                        _vm._v(
                                          "发放金额:￥" +
                                            _vm._s(
                                              _vm.$u.formatMoney(
                                                _vm.baseInfo.workerMoney
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]),
                                      _c("span", { staticClass: "u-m-l-10" }, [
                                        _vm._v(
                                          "服务费:￥" +
                                            _vm._s(
                                              _vm.$u.formatMoney(
                                                _vm.baseInfo.serviceMoney
                                              )
                                            )
                                        ),
                                      ]),
                                      _vm.baseInfo.paymentOrderStatus == 6
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "u-m-l-10",
                                              attrs: {
                                                type: "text",
                                                size: "small",
                                              },
                                              on: {
                                                click:
                                                  _vm.handleChangeRealMoney,
                                              },
                                            },
                                            [_vm._v("修改")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" ） "),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm.isEdit
                          ? _c(
                              "div",
                              { staticClass: "card--info-item u-m-t-20" },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("支付回单："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _c(
                                    "span",
                                    { staticClass: "u-flex u-col-center" },
                                    [
                                      _c("button-upload", {
                                        attrs: {
                                          type: "image/pdf",
                                          text: "上传支付回单(pdf/jpg)",
                                        },
                                        on: {
                                          "upload-success": function ($event) {
                                            return _vm.setTotalField(
                                              "turnkeyProjectPayment",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      !_vm.$u.test.isEmpty(
                                        _vm.totalForm.turnkeyProjectPayment
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "u-m-l-10",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$preViewer(
                                                    _vm.totalForm
                                                      .turnkeyProjectPayment
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("支付回单："),
                              ]),
                              _c(
                                "span",
                                { staticClass: "value" },
                                [
                                  _c("file-icon", {
                                    attrs: {
                                      url: _vm.baseInfo.turnkeyProjectPayment,
                                      btn: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                      ]
                    : !_vm.isEdit
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "blue-box is-view u-flex u-col-center",
                          },
                          [
                            _vm._v("转账金额："),
                            _c("span", { staticClass: "money" }, [
                              _vm._v(
                                "￥ " +
                                  _vm._s(
                                    _vm.$u.formatMoney(_vm.baseInfo.realMoney)
                                  )
                              ),
                            ]),
                            _c(
                              "span",
                              [
                                _vm._v(" （ "),
                                _c("span", [
                                  _vm._v(
                                    "发放金额:￥" +
                                      _vm._s(
                                        _vm.$u.formatMoney(
                                          _vm.baseInfo.workerMoney
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("span", { staticClass: "u-m-l-10" }, [
                                  _vm._v(
                                    "服务费:￥" +
                                      _vm._s(
                                        _vm.$u.formatMoney(
                                          _vm.baseInfo.serviceMoney
                                        )
                                      )
                                  ),
                                ]),
                                _vm.baseInfo.paymentOrderStatus == 6
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "u-m-l-10",
                                        attrs: { type: "text", size: "small" },
                                        on: {
                                          click: _vm.handleChangeRealMoney,
                                        },
                                      },
                                      [_vm._v("修改")]
                                    )
                                  : _vm._e(),
                                _vm._v(" ） "),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm.unionPay.length || _vm.aliPay.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "u-flex u-col-top u-row-left u-flex-wrap u-m-b-10",
                              },
                              [
                                _vm._l(_vm.unionPay, function (i, k) {
                                  return _c("union-info", {
                                    key: k + Date.now(),
                                    staticClass: "u-m-r-20",
                                    attrs: { "show-balance": false, info: i },
                                  })
                                }),
                                _vm._l(_vm.aliPay, function (i, k) {
                                  return _c("union-info", {
                                    key: k + Date.now(),
                                    attrs: {
                                      isAliPay: "",
                                      "show-balance": false,
                                      info: i,
                                    },
                                  })
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      _vm.isSubPay || [6].indexOf(+_vm.baseInfo.paymentOrderStatus) !== -1
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("分包支付信息")]),
            _c(
              "div",
              { staticClass: "card--content" },
              [
                _vm.totalForm.payType === 0
                  ? _c("div", { staticClass: "card--info" }, [
                      _c("table", { staticClass: "c-table is-edit" }, [
                        _c("tr", [
                          _c("td", [
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("支付方："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payee)),
                              ]),
                            ]),
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("开户行："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payeeBankName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("银行账号："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payeeBankCode)),
                              ]),
                            ]),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("收款方："),
                              ]),
                              _c(
                                "span",
                                { staticClass: "value" },
                                [
                                  !_vm.$u.test.isEmpty(
                                    _vm.baseInfo.paymentInventory
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "u-m-l-10",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$preViewer(
                                                _vm.baseInfo.paymentInventory
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查看")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", { class: _vm.isEdit ? "" : "no-border" }, [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "blue-box",
                                class: _vm.isEdit ? "is-edit" : "is-view",
                              },
                              [
                                _vm._v("转账金额："),
                                _c("span", { staticClass: "money" }, [
                                  _vm._v(
                                    "￥ " +
                                      _vm._s(
                                        _vm.$u.formatMoney(
                                          _vm.baseInfo.workerMoney
                                        )
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", [
                        _vm.isSubPay
                          ? _c(
                              "div",
                              { staticClass: "card--info-item u-m-t-20" },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("支付回单："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _c(
                                    "span",
                                    { staticClass: "u-flex u-col-center" },
                                    [
                                      _c("button-upload", {
                                        attrs: {
                                          type: "image/pdf",
                                          text: "上传支付回单(pdf/jpg)",
                                        },
                                        on: {
                                          "upload-success": function ($event) {
                                            return _vm.setCrowdField(
                                              "subpackagePayment",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      !_vm.$u.test.isEmpty(
                                        _vm.subForm.subpackagePayment
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "u-m-l-10",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$preViewer(
                                                    _vm.subForm
                                                      .subpackagePayment
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("支付回单："),
                              ]),
                              _c(
                                "span",
                                { staticClass: "value" },
                                [
                                  _c("file-icon", {
                                    attrs: {
                                      url: _vm.baseInfo.subpackagePayment,
                                      btn: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                      ]),
                    ])
                  : [
                      _vm.aliPay.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "u-flex u-col-top u-row-left u-flex-wrap u-m-b-10",
                            },
                            _vm._l(_vm.aliPay, function (info, k) {
                              return _c(
                                "el-card",
                                {
                                  key: k + Date.now(),
                                  staticStyle: { width: "43%" },
                                  attrs: {
                                    shadow: "never",
                                    "body-style": { padding: 0 },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix u-text-center",
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [_vm._v(" 支付宝 ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "u-flex u-col-top u-row-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "u-flex-1 u-padding-10 u-border-right",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v(
                                                "子账号户名：" +
                                                  _vm._s(info.subAccountName)
                                              ),
                                            ]
                                          ),
                                          info.unionpayBankType === "SJBK"
                                            ? _c(
                                                "div",
                                                { staticClass: "u-m-b-10" },
                                                [
                                                  _vm._v(
                                                    "来款银行账号：" +
                                                      _vm._s(info.inBankNo)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v(
                                                "子账户账号：" +
                                                  _vm._s(info.subAccountCode)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "u-flex-1 u-padding-10",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v("收款方： "),
                                              !_vm.$u.test.isEmpty(
                                                _vm.baseInfo.paymentInventory
                                              )
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "u-m-l-10",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$preViewer(
                                                            _vm.baseInfo
                                                              .paymentInventory
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "u-flex u-col-top u-row-left u-flex-wrap u-m-b-10",
                            },
                            _vm._l(_vm.unionPay, function (info, k) {
                              return _c(
                                "el-card",
                                {
                                  key: k + Date.now(),
                                  staticClass: "u-m-r-20",
                                  staticStyle: { width: "43%" },
                                  attrs: {
                                    shadow: "never",
                                    "body-style": { padding: 0 },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix u-text-center",
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _vm._v(
                                        " 银行类型: " +
                                          _vm._s(
                                            _vm._f("unionpayBankType")(
                                              info.unionpayBankType
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "u-flex u-col-top u-row-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "u-flex-1 u-padding-10 u-border-right",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v(
                                                "子账号户名：" +
                                                  _vm._s(info.subAccountName)
                                              ),
                                            ]
                                          ),
                                          info.unionpayBankType === "SJBK"
                                            ? _c(
                                                "div",
                                                { staticClass: "u-m-b-10" },
                                                [
                                                  _vm._v(
                                                    "来款银行账号：" +
                                                      _vm._s(info.inBankNo)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v(
                                                "子账户账号：" +
                                                  _vm._s(info.subAccountCode)
                                              ),
                                            ]
                                          ),
                                          info.bankName
                                            ? _c(
                                                "div",
                                                { staticClass: "u-m-b-10" },
                                                [
                                                  _vm._v(
                                                    "开户行：" +
                                                      _vm._s(info.bankName)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "u-flex-1 u-padding-10",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v("收款方： "),
                                              !_vm.$u.test.isEmpty(
                                                _vm.baseInfo.paymentInventory
                                              )
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "u-m-l-10",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$preViewer(
                                                            _vm.baseInfo
                                                              .paymentInventory
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          ),
                      _c("div", { staticClass: "blue-box is-view" }, [
                        _vm._v("转账金额："),
                        _c("span", { staticClass: "money" }, [
                          _vm._v(
                            "￥ " +
                              _vm._s(
                                _vm.$u.formatMoney(_vm.baseInfo.workerMoney)
                              )
                          ),
                        ]),
                      ]),
                    ],
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.taxInfo
        ? _c(
            "el-card",
            { staticClass: "u-m-t-20" },
            [
              _c("div", { staticClass: "card--title" }, [_vm._v("发票信息")]),
              _c("div", { staticClass: "card--content" }, [
                _c("div", { staticClass: "card--info" }, [
                  _c("div", { staticClass: "card--info-item" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("总包发票："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: { url: _vm.taxInfo.invoice, btn: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "card--info-item" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("分包发票："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: {
                            url: _vm.taxInfo.subpackageInvoice,
                            btn: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              !_vm.$u.test.isEmpty(_vm.express.expressCode)
                ? [
                    _c("div", { staticClass: "card--title" }, [
                      _vm._v("物流信息"),
                    ]),
                    _c("div", { staticClass: "card--content" }, [
                      _c("div", { staticClass: "wl-box" }, [
                        _c("div", [
                          _vm._v(
                            "物流公司：" +
                              _vm._s(_vm.express.expressCompanyName)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "物流单号：" + _vm._s(_vm.express.expressCode)
                          ),
                        ]),
                      ]),
                      !_vm.$u.test.isEmpty(_vm.express.expressLogisticsInfos)
                        ? _c(
                            "div",
                            { staticClass: "wl-content" },
                            _vm._l(
                              _vm.express.expressLogisticsInfos,
                              function (i, k) {
                                return _c(
                                  "div",
                                  { key: k, staticClass: "wl-content-item" },
                                  [
                                    _c("span", { staticClass: "u-m-r-20" }, [
                                      _vm._v(_vm._s(i.AcceptTime)),
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(i.acceptStation)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      !_vm.isComponent
        ? _c(
            "div",
            { staticClass: "wrap-fixed-footer btn--box" },
            [
              _vm.isCheck
                ? [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.submitLoading,
                          size: "small",
                          type: "primary",
                        },
                        on: { click: _vm.doPay },
                      },
                      [_vm._v("确认收款")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.submitLoading,
                          size: "small",
                          type: "warning",
                        },
                        on: {
                          click: function ($event) {
                            _vm.checkDialog = true
                          },
                        },
                      },
                      [_vm._v("支付驳回")]
                    ),
                  ]
                : _vm._e(),
              _vm.isSubPay
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        loading: _vm.submitLoading,
                        size: "small",
                        type: "primary",
                      },
                      on: { click: _vm.paySubSubmit },
                    },
                    [_vm._v("确认 ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看关联任务",
            visible: _vm.visibleDialog,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleDialog = $event
            },
          },
        },
        [_c("task-detail", { ref: "taskDetail" })],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "checkDialog",
          attrs: {
            title: "请填写驳回原因",
            visible: _vm.checkDialog,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.checkDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { rows: 4, type: "textarea", placeholder: "请输入" },
                model: {
                  value: _vm.checkData.reasonsForRejection,
                  callback: function ($$v) {
                    _vm.$set(_vm.checkData, "reasonsForRejection", $$v)
                  },
                  expression: "checkData.reasonsForRejection",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.checkDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.rejectPay },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改银行卡",
            visible: _vm.dialogShow,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { model: _vm.form, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "银行卡号", required: "" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.form.bankCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "bankCode", $$v)
                      },
                      expression: "form.bankCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.doChangeBank },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改商户服务费",
            visible: _vm.feeDialogShow,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.feeDialogShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { model: _vm.inventoryFeeDTO, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "服务费", required: "" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.inventoryFeeDTO.newFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.inventoryFeeDTO, "newFee", $$v)
                      },
                      expression: "inventoryFeeDTO.newFee",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.doChangeServiceMoney },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "修改服务费",
            visible: _vm.orderFeeDialogShow,
            width: "500px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.orderFeeDialogShow = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { model: _vm.paymentOrderFeeDTO, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "服务费", required: "" } },
                [
                  _c("el-input", {
                    attrs: { type: "number" },
                    model: {
                      value: _vm.paymentOrderFeeDTO.newFee,
                      callback: function ($$v) {
                        _vm.$set(_vm.paymentOrderFeeDTO, "newFee", $$v)
                      },
                      expression: "paymentOrderFeeDTO.newFee",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.doChangeRealMoney },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }