<template>
  <div>
    <el-button v-if="isCheck" type="primary" :loading="loading" size="small" @click="batchCheck">批量验收</el-button>
    <el-button :loading="exportLoading" type="primary" size="small" @click="handleExport">批量导出</el-button>
    <el-button v-if="isCheck" type="primary" :loading="loading" size="small" @click="AcceptanceCheck">一键验收</el-button>
    <el-table ref="multipleTable" v-loading="loading" :data="list" tooltip-effect="dark" style="width: 100%"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="wid" label="创客编号" width="100" show-overflow-tooltip align="center" />
      <el-table-column prop="workerName" label="姓名" width="100" align="center" />
      <el-table-column prop="mobileCode" label="电话" width="120" align="center" />
      <el-table-column prop="idcardCode" label="身份证号" width="160" align="center" />
      <el-table-column label="实名认证" align="center">
        <template slot-scope="scope">
          <div v-html="
            $options.filters.filterContent(
              scope.row.attestation,
              $enum.workAttestation(),
              true
            )
          " />
        </template>
      </el-table-column>
      <el-table-column label="加盟合同" align="center">
        <template slot-scope="scope">
          <file-icon :url="scope.row.agreementUrl" />
        </template>
      </el-table-column>
      <el-table-column label="交付" align="center">
        <template slot-scope="scope">
          <div v-html="
            $options.filters.filterContent(
              scope.row.isDeliver,
              deliverList,
              true
            )
          " />
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="120">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
          <!-- v-if="isCheck && +scope.row.isDeliver === 1" -->
          <el-popconfirm v-if="isCheck && +scope.row.isDeliver === 1" class="u-m-l-5" confirm-button-text="确定"
            cancel-button-text="取消" title="你确定要验收吗？" @onConfirm="checkItem(scope.row)">
            <el-button slot="reference" type="text" size="small">验收</el-button>
          </el-popconfirm>
          <el-popconfirm v-else-if="+scope.row.isDeliver !== 1" confirm-button-text="确定" cancel-button-text="取消"
            title="你确定要踢除该创客吗？" @onConfirm="delItem(scope.row)">
            <el-button slot="reference" type="text" class="u-m-l-5">踢除</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-box">
      <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo"
        :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
    </div>
    <el-dialog key="viewItem" :visible.sync="viewItemDialog" title="查看创客交付成果">
      <div class="viewItemContent">
        <div style="color: #666">
          {{ viewItem.name }}
          <span class="u-tips-color u-m-l-10">{{
            viewItem.achievementDate
          }}</span>
        </div>
        <div class="u-m-t-10">文字交付说明：</div>
        <div>{{ viewItem.achievementDesc }}</div>
        <div class="u-m-t-20">图片交付:</div>
        <div>
          <el-image v-for="i in viewItem.fileArray" :key="i + new Date()" :src="i" @click="$preViewer(i)" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getJoinList, getJoinListForCheck, getAcceptance } from '@/api/worker'
import { checkMoney, delWorker } from '@/api/task'
import XLSX from 'xlsx'

export default {
  name: 'JoinWorkList',
  props: {
    taskId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      selectedWorkerId: [],
      listExcelVO: [],
      viewItemDialog: false,
      viewItem: {
        fileArray: []
      },
      deliverList: [
        { name: '未交付', value: 0, type: 'danger' },
        { name: '已交付', value: 1, type: 'success' }
      ],
      loading: false,
      exportLoading: false,
      list: [],
      pageTotal: 0,
      money: null,
      isCheck: false,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.paramsData.taskId = this.taskId
    this.isCheck = this.$route.meta.isCheck
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      if (this.isCheck) {
        getJoinListForCheck(Object.assign({}, this.paramsData))
          .then((res) => {
            console.log('res', res)
            this.list = res.data || []
            this.pageTotal = res.pageCount
            this.loading = false
          })
          .catch((res) => {
            this.loading = false
          })
      } else {
        getJoinList(Object.assign({}, this.paramsData))
          .then((res) => {
            this.list = res.data || []
            this.pageTotal = res.pageCount
            this.loading = false
          })
          .catch((res) => {
            this.loading = false
          })
      }
    },
    delItem(i) {
      this.loading = true
      delWorker({
        workerId: i.wid,
        taskId: this.taskId
      })
        .then((res) => {
          this.$message.success(res.message)
          this.fetchData()
        })
        .catch((res) => {
          this.loading = false
        })
    },
    checkItem(i) {
      checkMoney({
        workerId: i.wid,
        taskId: this.taskId
      }).then((res) => {
        this.$message.success('验收成功')
        this.fetchData()
      })
    },
    batchCheck() {
      if (this.selectedWorkerId.length) {
        checkMoney({
          workerId: this.selectedWorkerId.join(),
          taskId: this.taskId
        }).then((res) => {
          console.log('res', res)
          this.$message.success('验收成功')
          this.fetchData()
          this.$router.go(-1)
        })
      } else {
        this.$message.error('请先选中内容，后再操作')
      }
    },
    // 一键验收
    AcceptanceCheck() {
      getAcceptance({
        taskId: this.taskId
      }).then((res) => {
        console.log('res', res)
        this.$message.success('验收成功')
        this.fetchData()
        this.$router.go(-1)
      })
    },
    handleSelectionChange(val) {
      this.selectedWorkerId = []
      this.listExcelVO = []
      if (val.length) {
        val.forEach((element) => {
          this.selectedWorkerId.push(element.wid)
          this.listExcelVO.push(element)
        })
        // console.log('selectedWorker:', this.selectedWorkerId)
      }
    },
    handleExport() {
      if (this.listExcelVO.length) {
        this.loading = true
        this.exportLoading = true

        const tableData = [
          ['姓名', '手机号', '身份证号', '银行卡号', '开户行']
        ]
        let rowData = []
        this.listExcelVO.forEach((item, index) => {
          rowData = [
            item.workerName,
            item.mobileCode,
            item.idcardCode,
            item.bankCode,
            item.bankName
          ]
          tableData.push(rowData)
        })
        const ws = XLSX.utils.aoa_to_sheet(tableData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, '创客明细')
        XLSX.writeFile(wb, '创客明细.xlsx')

        setTimeout(() => {
          this.loading = false
          this.exportLoading = false
        }, 4000)
      } else {
        this.$message.error('请先选中内容，后再点击导出')
      }
    },
    handleShow(row) {
      let fileArray = []
      if (!this.$u.test.isEmpty(row.achievementFiles)) {
        fileArray = row.achievementFiles.split(',')
      }
      this.viewItem = Object.assign({}, row, {
        fileArray: fileArray
      })
      this.$nextTick(() => {
        this.viewItemDialog = true
      })
    }
  }
}
</script>

<style scoped lang="scss">
.viewItemContent {
  margin-top: -20px;
  font-size: 14px;
  color: #555;
  line-height: 1.6;
}
</style>
