var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.formData, size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "个体户名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入个体户名称" },
                    model: {
                      value: _vm.formData.ibname,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "ibname", $$v)
                      },
                      expression: "formData.ibname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "请选择状态", clearable: "" },
                      model: {
                        value: _vm.formData.ibstate,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "ibstate", $$v)
                        },
                        expression: "formData.ibstate",
                      },
                    },
                    _vm._l(_vm.stateList, function (i, k) {
                      return _c("el-option", {
                        key: k,
                        attrs: { label: i.name, value: i.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "请选择注册时间",
                    },
                    model: {
                      value: _vm.formData.beginDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "beginDate", $$v)
                      },
                      expression: "formData.beginDate",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", [_vm._v(" 至 ")]),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "请选择注册时间",
                    },
                    model: {
                      value: _vm.formData.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "endDate", $$v)
                      },
                      expression: "formData.endDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.resetPage },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          !_vm.isService
            ? _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-receiving",
                  },
                  on: { click: _vm.create },
                },
                [_vm._v("申请注册个体户")]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "u-m-25 u-p-30 bg-white" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list, "tooltip-effect": "dark" },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "serviceProviderName",
                  label: "服务商",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "个体户名称",
                  align: "center",
                  width: "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$ibname(
                                scope.row.ibname,
                                scope.row.candidatedNames
                              )
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ibtaxNo",
                  label: "纳税人识别号",
                  width: "120",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "bizName",
                  label: "经营者",
                  width: "100",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "营业执照", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("file-icon", {
                          attrs: { url: scope.row.businessLicenceUrl },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "状态",
                  align: "center",
                  width: "100",
                  "show-overflow-tooltip": "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("filterState")(scope.row.ibstate)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "registeredDate",
                  label: "注册时间",
                  align: "center",
                  width: "150",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "200", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShow(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.edit(scope.row)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        !_vm.isService && scope.row.ibstate === "EDITING"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleService(scope.row)
                                  },
                                },
                              },
                              [_vm._v("匹配服务商")]
                            )
                          : _vm._e(),
                        scope.row.serviceProviderName &&
                        !scope.row.businessLicenceUrl &&
                        !scope.row.registeredDate
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleLicence(scope.row)
                                  },
                                },
                              },
                              [_vm._v("上传营业执照")]
                            )
                          : _vm._e(),
                        scope.row.ibstate !== "CANCELLED"
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text", size: "small" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleCancel(scope.row)
                                  },
                                },
                              },
                              [_vm._v("注销")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pager-box" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next, jumper",
                  "current-page": _vm.paramsData.pageNo,
                  "page-size": _vm.paramsData.pageSize,
                  "page-count": _vm.pageTotal,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.paramsData, "pageNo", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.paramsData, "pageNo", $event)
                  },
                  "current-change": _vm.fetchData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          key: "b",
          attrs: {
            title: "选择服务商",
            visible: _vm.visibleDialogB,
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleDialogB = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.serviceData.serviceProviderId,
                callback: function ($$v) {
                  _vm.$set(_vm.serviceData, "serviceProviderId", $$v)
                },
                expression: "serviceData.serviceProviderId",
              },
            },
            _vm._l(_vm.serviceList, function (i, k) {
              return _c("el-option", {
                key: k,
                attrs: { label: i.taxName, value: i.id },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visibleDialogB = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doService } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          key: "c",
          attrs: {
            title: "上传营业执照",
            visible: _vm.visibleDialogC,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleDialogC = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "u-flex u-col-center" },
            [
              _c("span", [_vm._v("营业执照：")]),
              _c("button-upload", {
                attrs: { type: "image", text: "上传营业执照(png/jpg)" },
                on: {
                  "upload-success": function ($event) {
                    return _vm.setField("businessLicenceUrl", $event)
                  },
                },
              }),
              !_vm.$u.test.isEmpty(_vm.licenceData.businessLicenceUrl)
                ? _c(
                    "el-button",
                    {
                      staticClass: "u-m-l-10",
                      attrs: { type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.$preViewer(
                            _vm.licenceData.businessLicenceUrl
                          )
                        },
                      },
                    },
                    [_vm._v("查看")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "u-flex u-m-t-20" },
            [
              _c("span", { staticStyle: { width: "16%" } }, [
                _vm._v("信用代码："),
              ]),
              _c("el-input", {
                attrs: { placeholder: "请输入社会信用代码" },
                model: {
                  value: _vm.licenceData.ibtaxNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.licenceData, "ibtaxNo", $$v)
                  },
                  expression: "licenceData.ibtaxNo",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "u-flex u-m-t-20" },
            [
              _c("span", { staticStyle: { width: "14%" } }, [
                _vm._v("注册时间："),
              ]),
              _c("el-date-picker", {
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择日期",
                },
                model: {
                  value: _vm.licenceData.buildDateTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.licenceData, "buildDateTime", $$v)
                  },
                  expression: "licenceData.buildDateTime",
                },
              }),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visibleDialogC = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doLicence } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }