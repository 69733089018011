<template>
  <div>
    <el-form ref="addressFormData" v-loading="loading" :model="formData" :rules="rules" label-width="250px">
      <el-form-item label="收件人" prop="linkName">
        <el-input v-model="formData.linkName" placeholder="请输入收件人" />
      </el-form-item>
      <el-form-item label="手机号" prop="linkMobile">
        <el-input v-model="formData.linkMobile" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item label="详细地址" prop="addressName">
        <el-input v-model="formData.addressName" type="textarea" placeholder="请输入详细地址" />
      </el-form-item>
      <el-form-item label="默认" prop="isNot">
        <el-switch v-model="formData.isNot" :active-value="0" :nactive-value="1" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('addressFormData')">确定</el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="tableLoading"
      :data="list"
      tooltip-effect="dark"
      max-height="350px"
      style="width: 100%"
    >
      <el-table-column
        prop="linkName"
        label="收件人"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column
        prop="linkMobile"
        label="手机号"
        align="center"
      />
      <el-table-column
        label="地址"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.addressName }}
        </template>
      </el-table-column>
      <el-table-column
        label="默认"
        align="center"
      >
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.isNot, $enum.defaultStatus(), true)" />
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
      >
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.status, $enum.authStatus(), true)" />
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="120"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleDel(scope.row)">删除</el-button>
          <el-button type="text" size="small" @click="handleToggle(scope.row)">{{ scope.row.status === 0 ? '停用' : '启用' }}</el-button>
          <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { delAddress, getAddressList, updateAddress, updateAddressStatus } from '@/api/shop'
const defaultForm = {
  'companyId': '',
  'createDate': '',
  'id': '',
  'updateDate': '',
  'linkName': '',
  'linkMobile': '',
  'addressName': '',
  'status': 0,
  'isNot': 0
}
export default {
  name: 'AddressEdit',
  props: {
    merchantId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      formData: Object.assign({}, defaultForm),
      loading: false,
      rules: {
        linkName: [
          { required: true, message: '请输入收件人', trigger: 'blur' }
        ],
        linkMobile: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.mobile(value)) {
                callback(new Error('手机号码不正确'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        addressName: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ]
      },
      tableLoading: false,
      list: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.tableLoading = true
      getAddressList(Object.assign({}, this.paramsData, {
        merchantId: this.merchantId
      })).then(res => {
        this.list = res.data || []
        this.tableLoading = false
      }).catch(res => {
        this.tableLoading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          this.loading = true
          updateAddress(form, {
            merchantId: this.merchantId
          }).then(res => {
            this.loading = false
            this.formData = Object.assign({}, defaultForm)
            this.$message.success(res.message)
            this.fetchData()
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    handleDel(i) {
      this.$confirm('是否删除该地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableLoading = true
        delAddress({
          addressId: i.id
        }).then(res => {
          this.$message.success('删除成功')
          this.fetchData()
        }).catch(() => {
          this.tableLoading = false
        })
      }).catch(() => {
      })
    },
    handleToggle(i) {
      const status = i.status
      const text = i.status === 0 ? '停用' : '启用'
      this.$confirm('是否' + text + '该地址?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.tableLoading = true
        updateAddressStatus({
          addressId: i.id,
          status: status === 0 ? 1 : 0
        }).then(res => {
          this.$message.success(text + '成功')
          this.fetchData()
        }).catch(() => {
          this.tableLoading = false
        })
      }).catch(() => {
      })
    },
    handleEdit(i) {
      this.formData = Object.assign({}, i, {
        id: i.id
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.u-form {
  font-size: 14px;
  ::v-deep {
    .el-form-item__content {
      max-width: 480px;
    }
  }
}
.box-uncheck {
  opacity: .3;
}
</style>
