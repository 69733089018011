<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-card>
      <!-- <div class="card--title">【总包+分包】 总包开票</div> -->
      <div class="card--title">支付信息</div>
      <div class="card--content" style="margin-left: 20px">
        <el-row :gutter="20" class="card--info">
          <el-col :md="12">
            <span class="label">支付编号：</span>
            <span class="value">{{ paymentOrderList.id }}</span>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">商户：</span>
            <span class="value">{{ paymentOrderList.companySName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">平台服务商：</span>
            <span class="value">{{
              paymentOrderList.platformServiceProvider
            }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付清单：</span>
            <span
              v-for="(item, index) of paymentOrderList.paymentInventoryList"
              :key="index"
              class="pointer"
              @click="openFile(item)"
              >查看</span
            >
            <!-- <span class="value">{{paymentOrderList.paymentInventory}}</span> -->
          </el-col>
          <el-col :md="8">
            <span class="label">关联的任务：</span>
            <span
              v-for="(item, index) of paymentOrderList.taskVoList"
              :key="index"
              class="pointer"
              @click="openTaskVo(item)"
              >查看</span
            >
            <!-- <span class="value">{{paymentOrderList.taskVo}}</span> -->
          </el-col>

          <el-col :md="8">
            <span class="label">交付支付验收单：</span>
            <span
              v-for="(
                item, index
              ) of paymentOrderList.acceptanceCertificateList"
              :key="index"
              class="pointer"
              @click="openFile(item)"
              >查看</span
            >
            <!-- <span class="value">{{paymentOrderList.acceptanceCertificate}}</span> -->
          </el-col>
          <el-col :md="8">
            <span class="label">总包支付回单：</span>
            <span
              v-for="(item, index) of paymentOrderList.subpackagePaymentList"
              :key="index"
              class="pointer"
              @click="openFile(item)"
              >查看</span
            >
          </el-col>

          <el-col :md="8">
            <span class="label">状态：</span>
            <span class="value">{{
              paymentOrderList.paymentOrderStatus | payTotalAndSubStatus
            }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付时间：</span>
            <!-- | filterDateTime -->
            <span class="value">{{ paymentOrderList.paymentDate }}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="u-m-t-20">
      <div class="card--title">开票清单明细信息</div>
      <div class="card--content" style="margin-left: 20px">
        <worker-pay-list ref="payList" :type="0" />
        <div class="table-wrap">
          <ul class="table">
            <li class="u-flex">
              <div class="title">
                <span class="txt">购买方</span>
              </div>
              <div class="content">
                <p>名称：{{ BuyerInfo.companyName }}</p>
                <p>纳税人识别号：{{ BuyerInfo.creditCode }}</p>
                <p>地址、电话：{{ BuyerInfo.addressAndTelephone }}</p>
                <p>开户行及账号：{{ BuyerInfo.bankAndAccount }}</p>
              </div>
            </li>
            <li class="count">价税合计：{{ $u.formatMoney(count) }}</li>
            <li class="u-flex">
              <div class="title">
                <span class="txt">销售方</span>
              </div>
              <div class="content">
                <p>名称：{{ sellerInfo.invoiceEnterpriseName }}</p>
                <p>纳税人识别号：{{ sellerInfo.invoiceTaxNo }}</p>
                <p>地址、电话：{{ sellerInfo.invoiceAddressPhone }}</p>
                <p>开户行及账号：{{ sellerInfo.invoiceBankNameAccount }}</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="form-wrap">
          <el-form
            v-if="type == 'add' || type == 'editor'"
            ref="form"
            label-width="80px"
          >
            <el-form-item required label="开票类目" style="width: 830px">
              <el-table
                ref="singleTable"
                class="table-radio"
                :data="tableData"
                max-height="400px"
                style="width: 100%"
              >
                <el-table-column type="index" label="单选" width="50">
                  <template slot-scope="scope">
                    <input
                      v-model="billingRadio"
                      type="radio"
                      :value="scope.row.id"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  property="serviceType"
                  label="服务类型"
                  width="120"
                  align="center"
                />
                <el-table-column
                  property="serviceContent"
                  label="具体服务内容"
                  width="400"
                  align="center"
                />
                <el-table-column
                  property="billingCategory"
                  label="开票类目"
                  align="center"
                />
              </el-table>
            </el-form-item>
          </el-form>

          <div class="radio_box">
            <div><span>*</span> 票种:</div>
            <div>
              <el-radio-group v-model="submitFome.invoiceForm">
                <el-radio :label="1">普通发票</el-radio>
                <el-radio :label="2">增值税专用发票</el-radio>
              </el-radio-group>
            </div>
          </div>
          <div class="radio_box">
            <div><span>*</span> 发票类型:</div>
            <div>
              <el-radio-group v-model="submitFome.invoiceType" @input="changeRadio">
                <el-radio :label="1">电子发票</el-radio>
                <el-radio :label="2">纸质发票</el-radio>
              </el-radio-group>
            </div>
          </div>

          <el-form v-if="type == 'add' || type == 'editor'" label-width="80px">
            <el-form-item
              required
              :label="submitFome.invoiceType != 1 ? '收件地址' : ''"
              style="width: 830px"
            >
              <el-table
                v-if="submitFome.invoiceType != 1"
                ref="singleTable"
                class="table-radio"
                :data="addressList"
                max-height="400px"
                style="width: 100%"
              >
                <el-table-column type="index" label="单选" width="50">
                  <template slot-scope="scope">
                    <input
                      v-model="addressRadio"
                      type="radio"
                      :value="scope.row.id"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  property="linkName"
                  label="收件人"
                  width="120"
                  align="center"
                />
                <el-table-column
                  property="addressName"
                  label="详细地址"
                  width="400"
                  align="center"
                />
                <el-table-column
                  property="linkMobile"
                  label="联系电话"
                  align="center"
                />
              </el-table>
              <div
                v-if="submitFome.invoiceType != 1"
                class="add-address"
                @click="showAddress = true"
              >
                + 新增收获地址
              </div>
            </el-form-item>
            <el-form-item label="备注说明">
              <el-input
                v-model="submitFome.applicationDesc"
                placeholder="请输入备注说明"
                type="textarea"
              />
            </el-form-item>
          </el-form>

          <el-form v-if="type === 'examine'" label-width="80px">
            <el-form-item label="备注说明">
              <el-input
                v-model="submitFome.applicationDesc"
                :disabled="true"
                placeholder="请输入备注说明"
                type="textarea"
              />
            </el-form-item>
          </el-form>

          <div
            v-if="type == 'add' || type == 'editor'"
            style="height: 100px; margin-left: 80px"
            class="flex justify-start align-items"
          >
            <el-button type="primary" @click="submit">提交</el-button>
            <!-- <el-button plain>取消</el-button> -->
          </div>
        </div>
      </div>
    </el-card>

    <el-card v-if="type === 'examine'" class="u-m-t-20">
      <div class="card--title">开票类目</div>
      <div class="card--content" style="margin-left: 20px">
        <ul class="invoiceCatalogVo-wrap">
          <li class="item flex align-items">
            <span>服务类型：</span>
            <span>{{ invoiceCatalogVo.billingCategory }}</span>
          </li>
          <li class="item flex align-items">
            <span>具体服务内容：</span>
            <span>{{ invoiceCatalogVo.serviceContent }}</span>
          </li>
          <li class="item flex align-items">
            <span>开票类目：</span>
            <span>{{ invoiceCatalogVo.serviceType }}</span>
          </li>
        </ul>
      </div>
    </el-card>

    <el-card v-if="type === 'examine'" class="u-m-t-20">
      <div class="card--title">收件信息</div>
      <div class="card--content" style="margin-left: 20px">
        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">收件人：</span>
            <span class="value">{{ sendAndReceiveVo.addressee }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">联系电话：</span>
            <span class="value">{{ sendAndReceiveVo.addresseeTelephone }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">详细地址：</span>
            <span class="value">{{ sendAndReceiveVo.toAddress }}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card v-if="type === 'examine' && tax.invoiceUrl" class="u-m-t-20">
      <div class="card--title">发票信息</div>
      <div class="card--content" style="margin-left: 20px">
        <div>
          <span class="label">发票：</span>
          <span class="value">
            <el-button
              v-if="!$u.test.isEmpty(tax.invoiceUrl)"
              class="u-m-l-10"
              type="text"
              @click="$preViewer(tax.invoiceUrl)"
              >查看</el-button
            >
          </span>
        </div>
        <div>
          <span class="label">税票：</span>
          <span class="value">
            <el-button
              v-if="!$u.test.isEmpty(tax.taxReceiptUrl)"
              class="u-m-l-10"
              type="text"
              @click="$preViewer(tax.taxReceiptUrl)"
              >查看</el-button
            >
          </span>
        </div>
      </div>
    </el-card>

    <el-card v-if="type === 'examine'" class="u-m-t-20">
      <div class="card--title">物流信息</div>
      <div class="card--content" style="margin-left: 20px">
        <div class="express-info">
          <ul>
            <li>物流公司：{{ sendAndReceiveVo.logisticsCompany }}</li>
            <li>物流单号：{{ sendAndReceiveVo.logisticsOrderNo }}</li>
            <li>收件信息：{{ sendAndReceiveVo.toAddress }}</li>
            <li>发件信息：{{ sendAndReceiveVo.sendingAddress }}</li>
          </ul>
        </div>
        <div class="logistics-info">
          <el-row
            v-for="(item, index) of expressLogisticsInfoList"
            :key="index"
            :gutter="10"
            style="margin-bottom: 15px"
          >
            <el-col :md="6">
              <span class="label">{{ item.acceptTime }}</span>
            </el-col>
            <el-col :md="18">
              <span class="label">{{ item.acceptStation }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>

    <!-- 添加收获地址 -->
    <el-dialog
      :model="addressFrom"
      title="添加收货地址"
      :visible.sync="showAddress"
    >
      <el-form v-show="showAddress" label-width="80px">
        <el-form-item required label="收件人">
          <el-input v-model="addressFrom.linkName" placeholder="请输入收件人" />
        </el-form-item>
        <el-form-item required label="联系电话">
          <el-input
            v-model="addressFrom.linkMobile"
            placeholder="请输入联系电话"
          />
        </el-form-item>
        <el-form-item required label="详细地址">
          <el-input
            v-model="addressFrom.addressName"
            type="textarea"
            placeholder="请输入详细地址"
          />
        </el-form-item>
        <el-form-item label="设置默认">
          <el-switch v-model="defaultAddress" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showAddress = false">取 消</el-button>
        <el-button type="primary" @click="addAddress">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 关联任务 -->
    <el-dialog title="关联的任务" :visible.sync="showTaskVo">
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">任务编号：</span>
          <span class="value">{{ taskInfo.taskCode }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">任务名称：</span>
          <span class="value">{{ taskInfo.taskName }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">任务类型：</span>
          <span class="value">{{ taskInfo.industryType }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">开始时间：</span>
          <span class="value">{{ taskInfo.releaseDate }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">结束时间：</span>
          <span class="value">{{ taskInfo.deadlineDate }}</span>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTaskVo = false">确定</el-button>
      </div>
    </el-dialog>

    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import {
  goInvApplication,
  getListInv,
  addInvApplication,
  queryApplicationInfo,
  updateApplication,
  getInvInfoById,
} from "@/api/totalTax";
import { getAddressList, updateAddress } from "@/api/shop";
import WorkerPayList from "@/views/tax/components/workerPayList";

export default {
  components: { WorkerPayList },
  data() {
    return {
      loading: false,
      taskInfo: {
        taskCode: "",
        taskName: "",
        industryType: "",
        releaseDate: "",
        deadlineDate: "",
      }, // 关联任务显示内容
      showTaskVo: false, // 关联任务弹出控制变量
      type: "", // 页面类型
      showAddress: false, // 是否添加地址
      paymentOrderList: {}, // 支付信息
      billingInfoList: [], // 开票信息
      BuyerInfo: {}, // 购买方信息
      tableData: [], // 开票类目
      sellerInfo: {}, //  销售方
      expressLogisticsInfoList: [], // 物流信息
      addressList: [], // 地址信息
      sendAndReceiveVo: {}, // 快递信息
      invoiceCatalogVo: {}, // 已开票-开票类目信息
      addressFrom: {
        linkName: "",
        linkMobile: "",
        addressName: "",
        isNot: false,
      }, // 新加住址表单
      addressRadio: "1", // 收获地址单选
      billingRadio: "1", // 开票类目
      defaultAddress: false,
      //
      submitFome: {
        applicationAddress: "", // 地址id
        applicationDesc: "", // 备注说明
        applicationPerson: "", // 申请人,商户名称
        id: "", // id主键
        invoiceCatalogType: "", // 开票类目
        invoiceTotalAmount: "", // 开票总额
        paymentOrderId: "", // 支付id,多个支付id以逗号隔开
        invoiceType: 1, // 发票类型 1 电子发票 2 纸质发票
        invoiceForm: null, // 发票形式 1 普通发票 2 增值税专用发票
      },
      tax: {},
      id: undefined,
      payId: undefined,
      totalMoney: 0,
    };
  },
  computed: {
    count() {
      let num = 0;
      this.billingInfoList.forEach((item) => {
        num += item.realMoney;
      });
      return num.toFixed(2);
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.payId = this.$route.query.payId;
    if (this.type === "add") {
      this.infoData(goInvApplication);
    } else if (this.type === "editor") {
      this.infoData(queryApplicationInfo);
    } else if (this.type === "examine") {
      this.infoData(getInvInfoById);
    }
  },
  methods: {
    openTaskVo(item) {
      this.showTaskVo = true;
      if (item) {
        this.taskInfo = item;
      }
    },
    openFile(url) {
      this.$preViewer(url);
    },
    //  初始化数据
    infoData(callback) {
      const parms = {};
      if (this.type === "add") {
        parms.payIds = this.id;
      } else if (this.type === "editor") {
        parms.applicationId = this.id;
      } else if (this.type === "examine") {
        parms.InvId = this.id;
      }
      callback(parms).then((res) => {
        const paymentOrderVoList = JSON.parse(
          JSON.stringify(res.obj.paymentOrderVoList)
        );
        const data = res.obj || {};
        const form = {};
        const idList = [];

        this.tax = {
          invoiceUrl: data.invoiceUrl,
          taxReceiptUrl: data.taxReceiptUrl,
        };

        form.companySName = paymentOrderVoList[0].companySName;
        form.platformServiceProvider =
          paymentOrderVoList[0].platformServiceProvider;
        form.paymentOrderStatus = paymentOrderVoList[0].paymentOrderStatus;
        form.paymentDate = paymentOrderVoList[0].paymentDate;

        form.paymentInventoryList = [];
        form.taskVoList = [];
        form.subpackagePaymentList = [];
        form.acceptanceCertificateList = [];

        paymentOrderVoList.forEach((item) => {
          idList.push(item.id);
          form.paymentInventoryList.push(item.paymentInventory);
          form.subpackagePaymentList.push(item.subpackagePayment);
          form.taskVoList.push(item.taskVo);
          form.acceptanceCertificateList.push(item.acceptanceCertificate);
        });
        form.id = idList.join(",");
        this.$nextTick(() => {
          this.$refs.payList.fetchData(form.id);
        });
        this.paymentOrderList = form;
        this.billingInfoList = res.obj.billingInfoVoList;
        this.BuyerInfo = res.obj.buyerVo;
        this.sellerInfo = res.obj.sellerVo;
        this.totalMoney = res.obj.totalMoney;
        if (this.type === "examine") {
          this.expressLogisticsInfoList = res.obj.expressLogisticsInfoList;
          this.sendAndReceiveVo = res.obj.sendAndReceiveVo;
        }
        if (this.type === "editor") {
          this.submitFome = res.obj.invoiceApplicationVo;
          this.addressRadio = res.obj.invoiceApplicationVo.applicationAddress;
          this.billingRadio = res.obj.invoiceApplicationVo.invoiceCatalogType; // 开票类目
        }

        if (this.type === "examine") {
          this.submitFome.applicationDesc =
            res.obj.invoiceApplicationVo.applicationDesc;
          this.invoiceCatalogVo = res.obj.invoiceCatalogVo;
        }

        if (this.type !== "examine") {
          const data = {
            payId: this.payId,
            packageStatus: 0,
          };

          getListInv(data).then((res) => {
            this.tableData = res.data;
          });

          getAddressList({
            merchantId: this.billingInfoList[0].companyId,
          }).then((res) => {
            this.addressList = res.data;
          });
        }
      });
    },
    // 电子发票纸质发票切换
    changeRadio() {
      this.addressRadio = 1
      this.submitFome.applicationAddress = ''
    },
    submit() {
      this.loading = true;
      this.submitFome.applicationAddress = this.addressRadio;
      this.submitFome.paymentOrderId = this.id;
      this.submitFome.invoiceCatalogType = this.billingRadio;
      this.submitFome.invoiceTotalAmount = this.count;
      this.submitFome.applicationPerson = this.paymentOrderList.companySName;
      if (this.type === "editor") {
        const parms = {};
        parms.applicationAddress = this.submitFome.applicationAddress;
        parms.applicationDesc = this.submitFome.applicationDesc;
        parms.invoiceCatalogType = this.submitFome.invoiceCatalogType;
        parms.id = this.submitFome.id;
        updateApplication(parms)
          .then((res) => {
            this.$message.success("修改成功");
            this.loading = false;
            this.goBack();
          })
          .catch((res) => {
            this.loading = false;
          });
      } else if (this.type === "add") {
        if (this.submitFome.invoiceType == 1) {
        } else {
          if (this.submitFome.applicationAddress === "1") {
            this.$message.error("请选择地址");
            return;
          }
        }
        if (this.submitFome.invoiceCatalogType === "1") {
          this.$message.error("请选择开票类目");
          return;
        }
        addInvApplication(this.submitFome).then((res) => {
          this.$message.success("提交成功");
          this.loading = false;
          this.goBack();
        });
      }
    },
    addAddress() {
      if (!this.addressFrom.linkName) {
        this.$message.error("姓名不能为空!");
        return;
      }
      if (!this.addressFrom.linkMobile) {
        this.$message.error("电话不能为空!");
        return;
      }
      if (!this.addressFrom.addressName) {
        this.$message.error("详细地址不能为空!");
        return;
      }

      if (this.addressFrom.linkMobile.length !== 11) {
        this.$message.error("电话格式不对!");
        return;
      }

      if (this.defaultAddress) {
        this.addressFrom.isNot = 1;
      } else {
        this.addressFrom.isNot = 0;
      }
      updateAddress(this.addressFrom, {
        merchantId: this.billingInfoList[0].companyId,
      }).then((res) => {
        this.showAddress = false;
        this.$message.success("提交成功");
        getAddressList({
          merchantId: this.billingInfoList[0].companyId,
        }).then((res) => {
          this.addressList = res.data;
        });
      });
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
  },
};
</script>

<style scoped lang="scss">
ul,
li,
p {
  list-style: none;
  /*清除列表默认样式*/
  padding: 0;
  /*清除padding*/
  margin: 0;
}

.invoiceVo-wrap,
.invoiceCatalogVo-wrap {
  .item {
    padding-bottom: 10px;

    li {
      span {
        margin-right: 8px;
      }
    }
  }
}

.card--content {
  .el-col {
    .value {
      // width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .pointer {
    margin-left: 10px;
    color: #3f9ffc;
    cursor: pointer;
    // text-decoration: underline;
  }
}

.blue-box {
  max-width: 700px;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  .money {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
}

.add-address {
  margin-top: 30px;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  border: 1px dotted #ebeef5;
}

.logistics-info,
.express-info {
  ul,
  li,
  p {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
}

.express-info {
  ul {
    padding: 10px 15px;
    width: 928px;
    background: #f6faff;
    border: 1px solid #badcfe;

    li {
      height: 30px;
      line-height: 30px;
    }
  }
}

.logistics-info {
  padding: 10px 25px;
  width: 928px;
  min-height: 200px;
  background: #fbfbfc;
  border: 1px solid #eaeaea;
}

.tabl-title {
  width: 100%;
  text-align: center;
  height: 30px;
  font-size: 16px;
}

.form-wrap {
  margin-top: 20px;
  width: 614px;
}

.table-wrap {
  margin-top: 20px;
  margin-left: 80px;

  ul,
  li,
  p {
    list-style: none;
    /*清除列表默认样式*/
    padding: 0;
    /*清除padding*/
    margin: 0;
  }

  .table {
    border: 1px solid #ebeef5;
    min-width: 750px;

    .title {
      position: relative;
      width: 80px;
      text-align: center;

      // line-height: 160px;
      .txt {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -33px;
        margin-left: -10px;
        display: inline-block;
        width: 20px;
      }
    }

    .content {
      border-left: 1px solid #ebeef5;
      width: 100%;

      p {
        width: 100%;
        line-height: 1.6;
        padding: 5px 20px;
      }

      // p:not(:last-child){
      //      border-bottom: 1px solid #EBEEF5;

      // }
    }

    .count {
      border: 1px solid #ebeef5;
      border-left: 0;
      border-right: 0;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
    }
  }
}
.radio_box {
  width: 100%;
  display: flex;
  > div:first-child {
    span {
      font-size: 18px;
      color: red;
    }
  }
  > div:last-child {
    margin-left: 10px;
  }
}
</style>
