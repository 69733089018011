var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c("div", { staticClass: "topbar u-flex u-row-between u-col-center" }, [
      _c("div", { staticClass: "u-flex u-col-center" }, [
        _c("img", {
          attrs: { src: require("@/assets/logo.png"), width: "160px" },
        }),
        _c("span", { staticClass: "u-m-l-15 u-font-16" }, [
          _vm._v(_vm._s(_vm.slogan)),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "u-flex u-col-center" },
        [
          !_vm.$u.test.isEmpty(_vm.homeUrl)
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.toMain },
                },
                [_vm._v("返回官网")]
              )
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "login-form" }, [
      _c("div", { staticClass: "logo-title" }, [
        _vm._v(_vm._s(_vm.title || "平台") + "管理中心"),
      ]),
      !_vm.forgetPwd
        ? _c(
            "div",
            { staticClass: "login-box" },
            [
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  attrs: {
                    model: _vm.loginForm,
                    rules: _vm.loginRules,
                    "auto-complete": "on",
                    "label-position": "left",
                  },
                },
                [
                  _c(
                    "el-tabs",
                    {
                      attrs: { stretch: "", "before-leave": _vm.changeTabs },
                      model: {
                        value: _vm.loginForm.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.loginForm, "type", $$v)
                        },
                        expression: "loginForm.type",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        {
                          staticClass: "tab-name",
                          attrs: { label: "账户密码登录", name: "pwd" },
                        },
                        [
                          _vm.loginForm.type === "pwd"
                            ? _c(
                                "div",
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "username" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "svg-container" },
                                        [
                                          _c("svg-icon", {
                                            attrs: { "icon-class": "username" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("el-input", {
                                        ref: "username",
                                        attrs: {
                                          placeholder: "请输入用户名",
                                          name: "username",
                                          type: "text",
                                          tabindex: "1",
                                          "auto-complete": "on",
                                        },
                                        model: {
                                          value: _vm.loginForm.username,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.loginForm,
                                              "username",
                                              $$v
                                            )
                                          },
                                          expression: "loginForm.username",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    { attrs: { prop: "password" } },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "svg-container" },
                                        [
                                          _c("svg-icon", {
                                            attrs: { "icon-class": "password" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c("el-input", {
                                        key: _vm.passwordType,
                                        ref: "password",
                                        attrs: {
                                          type: _vm.passwordType,
                                          placeholder: "请输入密码",
                                          name: "password",
                                          tabindex: "2",
                                          "auto-complete": "on",
                                        },
                                        nativeOn: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.handleLogin($event)
                                          },
                                        },
                                        model: {
                                          value: _vm.loginForm.password,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.loginForm,
                                              "password",
                                              $$v
                                            )
                                          },
                                          expression: "loginForm.password",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "show-pwd",
                                          on: { click: _vm.showPwd },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class":
                                                _vm.passwordType === "password"
                                                  ? "eye"
                                                  : "eye-open",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-form-item",
                                    [
                                      _vm.loginForm.type === "pwd"
                                        ? _c("verify", {
                                            key: "pwd",
                                            attrs: {
                                              type: 3,
                                              "show-button": false,
                                              "bar-size": {
                                                width: "357px",
                                                height: "42px",
                                              },
                                            },
                                            on: {
                                              success: function ($event) {
                                                _vm.verifyBool = true
                                              },
                                              error: function ($event) {
                                                _vm.verifyBool = false
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]
                      ),
                      _vm.isLgy
                        ? _c(
                            "el-tab-pane",
                            {
                              staticClass: "tab-name",
                              attrs: { label: "测试账户登录", name: "test" },
                            },
                            [
                              _vm.loginForm.type === "test"
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "serialNumber" } },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "svg-container" },
                                            [
                                              _c("i", {
                                                staticClass: "el-icon-edit",
                                              }),
                                            ]
                                          ),
                                          _c("el-input", {
                                            ref: "serialNumber",
                                            attrs: {
                                              placeholder: "请输入序列号",
                                              name: "serialNumber",
                                              type: "text",
                                              tabindex: "1",
                                              "auto-complete": "on",
                                            },
                                            model: {
                                              value: _vm.loginForm.serialNumber,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.loginForm,
                                                  "serialNumber",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "loginForm.serialNumber",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "username" } },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "svg-container" },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "username",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("el-input", {
                                            ref: "username",
                                            attrs: {
                                              placeholder: "请输入用户名",
                                              name: "username",
                                              type: "text",
                                              tabindex: "1",
                                              "auto-complete": "on",
                                            },
                                            model: {
                                              value: _vm.loginForm.username,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.loginForm,
                                                  "username",
                                                  $$v
                                                )
                                              },
                                              expression: "loginForm.username",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        { attrs: { prop: "password" } },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "svg-container" },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class": "password",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("el-input", {
                                            key: _vm.passwordType,
                                            ref: "password",
                                            attrs: {
                                              type: _vm.passwordType,
                                              placeholder: "请输入密码",
                                              name: "password",
                                              tabindex: "2",
                                              "auto-complete": "on",
                                            },
                                            nativeOn: {
                                              keyup: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                return _vm.handleLogin($event)
                                              },
                                            },
                                            model: {
                                              value: _vm.loginForm.password,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.loginForm,
                                                  "password",
                                                  $$v
                                                )
                                              },
                                              expression: "loginForm.password",
                                            },
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "show-pwd",
                                              on: { click: _vm.showPwd },
                                            },
                                            [
                                              _c("svg-icon", {
                                                attrs: {
                                                  "icon-class":
                                                    _vm.passwordType ===
                                                    "password"
                                                      ? "eye"
                                                      : "eye-open",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-form-item",
                                        [
                                          _vm.loginForm.type === "test"
                                            ? _c("verify", {
                                                key: "pwd",
                                                attrs: {
                                                  type: 3,
                                                  "show-button": false,
                                                  "bar-size": {
                                                    width: "357px",
                                                    height: "42px",
                                                  },
                                                },
                                                on: {
                                                  success: function ($event) {
                                                    _vm.verifyBool = true
                                                  },
                                                  error: function ($event) {
                                                    _vm.verifyBool = false
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%", "margin-bottom": "30px" },
                  attrs: { loading: _vm.loading, type: "primary" },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [_vm._v("登 录")]
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "login-box" },
            [
              _c("forget", {
                on: {
                  "change-type": function ($event) {
                    _vm.forgetPwd = false
                  },
                },
              }),
            ],
            1
          ),
    ]),
    _c(
      "a",
      { staticClass: "footer", attrs: { href: "https://beian.miit.gov.cn/" } },
      [_vm._v("COPYRIGHT © " + _vm._s(_vm.copyright))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }