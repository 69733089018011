<template>
  <div class="u-m-25 u-p-30 bg-white">
    <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
      <el-form-item label="监督部门名称" prop="regulatorName">
        <el-input v-model="formData.regulatorName" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="formData.address" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="联系人" prop="linkman">
        <el-input v-model="formData.linkman" placeholder="请输入联系人" />
      </el-form-item>
      <el-form-item label="电话" prop="linkMobile">
        <el-input v-model="formData.linkMobile" placeholder="请输入电话" />
      </el-form-item>
      <el-form-item label="登录账号" prop="userName">
        <el-input v-model="formData.userName" placeholder="请输入登录账号" />
      </el-form-item>
      <el-form-item v-if="$u.test.isEmpty(formData.id)" label="初始密码" prop="passWord">
        <el-input v-model="formData.passWord" type="password" placeholder="请输入初始密码" />
      </el-form-item>
      <el-form-item v-else label="修改密码">
        <el-input v-model="formData.passWord" type="password" placeholder="为空则不修改" />
      </el-form-item>
      <el-form-item label="确认密码">
        <el-input v-model="formData.confirmPassWord" type="password" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="formData.status">
          <el-radio v-for="(i, k) in $enum.authStatus()" :key="k" :label="i.value">{{ i.name }}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formData')">确定</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDetail, edit, add } from '@/api/supervision'

const defaultForm = () => {
  return {
    'address': '',
    'confirmPassWord': '',
    'id': '',
    'linkMobile': '',
    'linkman': '',
    'passWord': '',
    'regulatorName': '',
    'status': 0,
    'userName': ''
  }
}

export default {
  data() {
    const DefaultForm = defaultForm()
    return {
      loading: false,
      formData: Object.assign({}, DefaultForm),
      rules: {
        regulatorName: [
          { required: true, message: '请输入监督部门名称', trigger: 'blur' }
        ],
        userName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        linkMobile: [
          { required: true, message: '请输入电话', trigger: 'blur' }
          // {
          //   validator: (rule, value, callback) => {
          //     if (!this.$u.test.mobile(value)) {
          //       callback(new Error('手机号码不正确'))
          //     }
          //     callback()
          //   },
          //   trigger: 'blur'
          // }
        ],
        linkman: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        passWord: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.fetchData(this.$route.query.id)
    }
  },
  methods: {
    fetchData(id) {
      this.loading = true
      getDetail({
        regulatorId: id
      }).then(res => {
        const data = res.obj || {}
        const DefaultForm = defaultForm()
        this.formData = Object.assign({}, DefaultForm, data)
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          if (form.passWord !== form.confirmPassWord) {
            return this.$message.error('两次密码不一致')
          }
          this.loading = true
          if (this.$u.test.isEmpty(form.id)) {
            add(form).then(res => {
              this.$message.success(res.message)
              this.loading = false
              this.$router.go(-1)
            }).catch(res => {
              this.loading = false
            })
          } else {
            edit(form).then(res => {
              this.$message.success(res.message)
              this.loading = false
              this.$router.go(-1)
            }).catch(res => {
              this.loading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
