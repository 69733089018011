<template>
  <div>
    <el-form ref="withdrawForm" v-loading="withdrawLoading" :model="withdrawForm" :rules="withdrawRules" label-width="100px" style="max-width: 400px">
      <el-form-item label="账户名" prop="destAcctName">
        <el-input v-model="withdrawForm.destAcctName" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="账号" prop="destAcctNo">
        <el-input v-model="withdrawForm.destAcctNo" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="目标银行名称" prop="bankName">
        <el-input v-model="withdrawForm.bankName" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="联行号" prop="destBranchCode">
        <el-input v-model="withdrawForm.destBranchCode" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="联行名称" prop="destBranchName">
        <el-input v-model="withdrawForm.destBranchName" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="提现金额" prop="amount">
        <el-input
          v-model="withdrawForm.amount"
          placeholder="请输入"
        />
      </el-form-item>
      <!--      <el-form-item label="手机号">-->
      <!--        <el-input-->
      <!--          v-model="tel"-->
      <!--          readonly-->
      <!--        />-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="验证码" prop="checkCode">-->
      <!--        <el-input v-model="withdrawForm.checkCode" placeholder="请输入">-->
      <!--          <el-button slot="append" type="default" :style="{background: (disabled ? 'inherit' : '#fff'), color: (disabled ? 'inherit' : '#000') }" :disabled="disabled" @click="getCode">{{ btnText }}</el-button>-->
      <!--        </el-input>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="支付密码" prop="payPwd">-->
      <!--        <el-input v-model="withdrawForm.payPwd" placeholder="请输入" />-->
      <!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="submitForm('withdrawForm')">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import { getCode } from '@/api/common'
import { withdrawByAlipayUnionPay } from '@/api/service'

const _defaultWithdrawForm = () => {
  return {
    amount: null,
    destAcctName: '',
    destAcctNo: '',
    signOrderId: '',
    bankName: '',
    destBranchCode: '',
    destBranchName: '',
    alipaySignOrderType: ''
  }
}
const btnText = '获取验证码'
export default {
  props: {
    taxId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      countdown: null,
      disabled: false,
      withdrawLoading: false,
      btnText: btnText,
      withdrawForm: Object.assign({}, _defaultWithdrawForm()),
      withdrawRules: {
        destAcctName: [
          { required: true, trigger: 'blur', message: '请输入账户名' }
        ],
        destAcctNo: [
          { required: true, trigger: 'blur', message: '请输入账号' }
        ],
        bankName: [
          { required: true, trigger: 'blur', message: '目标银行名称' }
        ],
        destBranchCode: [
          { required: true, trigger: 'blur', message: '请输入联行号' }
        ],
        destBranchName: [
          { required: true, trigger: 'blur', message: '请输入联行名称' }
        ],
        amount: [
          { required: true, trigger: 'blur', message: '请选择提现金额' },
          { trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!this.$u.test.validateRange(value, '0~', false)) {
                callback(new Error('请输入大于0的数'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      tel: ''
    }
  },
  methods: {
    setData(data = {}) {
      this.$refs['withdrawForm'].resetFields()
      this.withdrawForm.signOrderId = data.signOrderId
      this.withdrawForm.alipaySignOrderType = data.alipayUnionpaySignType
      this.disabled = true
      clearInterval(this.countdown)
      this.btnText = btnText
      // getShopPhone({
      //   merchantId: this.merchantId
      // }).then(res => {
      //   this.tel = res.obj || ''
      //   this.disabled = false
      // }).catch(res => {
      //   this.disabled = false
      // })
    },
    doLoop: function(seconds) {
      seconds = seconds || 60
      this.btnText = seconds + 's后获取'
      this.countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = btnText
          this.disabled = false
          clearInterval(this.countdown)
        }
      }, 1000)
    },
    getCode() {
      const mobile = this.tel || ''
      if (!this.$u.test.mobile(mobile)) {
        return this.$message.error('手机号不正确')
      }
      this.disabled = true
      getCode({
        mobileCode: this.tel,
        taxId: this.taxId
      }).then(res => {
        this.$message.success('发送短信成功')
        setTimeout(() => {
          this.doLoop(60)
        }, 500)
      }).catch(res => {
        this.disabled = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.withdrawLoading = true
          withdrawByAlipayUnionPay(Object.assign({}, this.withdrawForm)).then(res => {
            this.$message.success(res.message)
            this.$emit('success')
            this.withdrawLoading = false
          }).catch(res => {
            this.withdrawLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
