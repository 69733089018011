<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="平台服务商">
          <el-select v-model="formData.platformServiceProvider" style="width: 100%" placeholder="平台服务商" clearable filterable>
            <el-option v-for="i in serviceList" :key="i.id" :label="i.taxName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker v-model="formData.applicationDateOne" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          至
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.applicationDateTwo" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
          <el-button type="primary" :loading="btnLoading" @click="exportExcel">导出</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table ref="multipleTable" row-key="id" v-loading="loading" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" reserve-selection width="55" />
        <el-table-column prop="invoiceCode" label="发票编号" align="center" width="150" />
        <el-table-column prop="companySName" label="商户" width="150" show-overflow-tooltip align="center" />
        <el-table-column prop="platformServiceProvider" label="平台服务商" align="center" width="150" show-overflow-tooltip />
        <!-- <el-table-column prop="workerMoney" label="支付总金额(元)" width="100" align="center" show-overflow-tooltip /> -->
        <el-table-column label="支付总金额(元)" align="center" width="200">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.list" :key="k">{{ $u.formatMoney(i.realMoney) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付编号" align="center" width="200">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.list" :key="k">
              {{ i.id }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="支付清单" align="center">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.list" :key="k">
              <file-icon v-if="i.paymentInventory" :url="i.paymentInventory" />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="总包支付回单" align="center" width="120">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.list" :key="k">
              <file-icon v-if="i.turnkeyProjectPayment" :url="i.turnkeyProjectPayment" />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商户申请" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.isInvoice, statusContent, true)" />
          </template>
        </el-table-column>
        <el-table-column prop="applicationDesc" label="开票申请备注说明" align="center" width="150" show-overflow-tooltip />
        <el-table-column prop="realMoney" label="开票总金额(元)" width="100" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $u.formatMoney(scope.row.realMoney) }}
          </template>
        </el-table-column>
        <el-table-column label="总包发票" align="center">
          <template slot-scope="scope">
            <file-icon :url="scope.row.invoiceUrl" />
          </template>
        </el-table-column>
        <el-table-column label="总包税票" align="center">
          <template slot-scope="scope">
            <file-icon v-if="scope.row.taxReceiptUrl" :url="scope.row.taxReceiptUrl" />
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.isInvoice, $enum.invoiceStatus(), true)" />
          </template>
        </el-table-column>
        <el-table-column prop="invoicePrintDate" label="开票时间" align="center" width="150" />
        <el-table-column label="操作" width="120" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleShow(scope.row,'editor')">编辑</el-button>
            <el-button type="text" size="small" @click="handleShow(scope.row,'examine')">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { getDoneList } from '@/api/totalTax'
import { getServiceList } from '@/api/common'

export default {
  name: 'TotalDone',
  data() {
    return {
      statusContent: [
        { value: 0, name: '未申请', type: 'danger' },
        { value: 1, name: '已申请', type: 'success' }
      ],
      loading: false,
      btnLoading:false,
      serviceList: [],
      formData: {
        platformServiceProvider: '',
        applicationDateOne: '',
        applicationDateTwo: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      },
      multipleSelection:[]
    }
  },
  created() {
    console.log(this.$options.name + '总包已开票-----created')
    this.fetchData()
    this.fetchService()
  },
  methods: {
    fetchService() {
      getServiceList({
        packageStatus: 0
      }).then(res => {
        this.serviceList = res.data || []
      })
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getDoneList(Object.assign({}, this.formData, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleShow(i, type) {
      this.$router.push({
        path: '/tax/total/done-editor',
        query: {
          id: i.id,
          type
        }
      })
    },
    // 多选按钮触发
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    exportExcel () {
      if (this.multipleSelection.length) {
        this.btnLoading = true
        const tableData = [
          ['商户','平台服务商','开票时间','支付编号','开票金额']
        ]
        let rowData = []
        this.multipleSelection.forEach((item, index) => {
          let pid = item.list.reduce((pv, item) => {
            return `${item.id},${pv}`
          },'')
          rowData = [item.companySName,item.platformServiceProvider,item.invoicePrintDate,pid,item.invoiceMoney]
          tableData.push(rowData)
        })
        const ws = XLSX.utils.aoa_to_sheet(tableData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, '待开票')
        XLSX.writeFile(wb, '已开票列表.xlsx')
        this.$refs.multipleTable.clearSelection()
        setTimeout(() => {
          this.btnLoading = false
        }, 1000);
      } else {
        this.$message.error('请先选中内容，后再点击导出')
      }
    }
  }
}
</script>

<style scoped>
</style>
