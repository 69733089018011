export function _defaultForm() {
  // return {
  //   'taxId': undefined,
  //   'bankCode': 'string',
  //   'bankName': 'string',
  //   'businessLicense': 'string',
  //   'creditCode': 'string',
  //   'invoiceAddressPhone': 'string',
  //   'invoiceBankNameAccount': 'string',
  //   'invoiceEnterpriseName': 'string',
  //   'invoiceTaxNo': 'string',
  //   'joinContract': 'string',
  //   'linkEmail': 'string@qq.com',
  //   'linkMan': 'string',
  //   'linkMobile': '18856499251',
  //   'linkPosition': 'string',
  //   'loginMobile': '18856499251',
  //   'userName': 'string',
  //   'passWord': 'string',
  //   'receiptAddress': 'string',
  //   'receiptName': 'string',
  //   'receiptPhone': '18856499251',
  //   'taxAddress': 'string',
  //   'taxCreateDate': 'string',
  //   'taxMan': 'string',
  //   'taxManIdcard': 'string',
  //   'taxName': 'string',
  //   'titleOfAccount': 'string',
  //   'manyLadders': [
  //   ],
  //   'manyServiceLadders': [
  //     {
  //       'endMoney': null,
  //       'packaegStatus': 5,
  //       'rate': null,
  //       'startMoney': 0,
  //       'status': 0
  //     }
  //   ],
  //   'manyTaxPackage': {
  //     'packageStatus': 1,
  //     'supportCategory': '',
  //     'taxPrice': null
  //   },
  //   'totalLadders': [
  //   ],
  //   'totalServiceLadders': [
  //     {
  //       'endMoney': null,
  //       'packaegStatus': 4,
  //       'rate': null,
  //       'startMoney': 0,
  //       'status': 0
  //     }
  //   ],
  //   'totalTaxPackage': {
  //     'packageStatus': 0,
  //     'supportCategory': '',
  //     'taxPrice': null
  //   }
  // }
  return {
    'taxId': undefined,
    'bankCode': '',
    'bankName': '',
    'businessLicense': '',
    'creditCode': '',
    'invoiceAddressPhone': '',
    'invoiceBankNameAccount': '',
    'invoiceEnterpriseName': '',
    'invoiceTaxNo': '',
    'joinContract': '',
    'linkEmail': '',
    'linkMan': '',
    'linkMobile': '',
    'linkPosition': '',
    'loginMobile': '',
    'userName': '',
    'passWord': '',
    'receiptAddress': '',
    'receiptName': '',
    'receiptPhone': '',
    'taxAddress': '',
    'taxCreateDate': '',
    'taxMan': '',
    'taxManIdcard': '',
    'taxName': '',
    'titleOfAccount': '',
    'manyLadders': [
    ],
    'manyServiceLadders': [
      {
        'endMoney': null,
        'packaegStatus': 5,
        'rate': null,
        'startMoney': 0,
        'status': 0
      }
    ],
    'manyTaxPackage': {
      'packageStatus': 1,
      'supportCategory': '',
      'taxPrice': null
    },
    'totalLadders': [
    ],
    'totalServiceLadders': [
      {
        'endMoney': null,
        'packaegStatus': 4,
        'rate': null,
        'startMoney': 0,
        'status': 0
      }
    ],
    'totalTaxPackage': {
      'packageStatus': 0,
      'supportCategory': '',
      'taxPrice': null
    },
    'workerSigningType': 'AUTHENTANDSIGN',
    'relevance': 1,
    'quota': 'NOTHING'
  }
}
