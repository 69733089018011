var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      _c("el-card", [
        _c("div", { staticClass: "card--title" }, [_vm._v("支付信息")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c(
              "el-row",
              { staticClass: "card--info", attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { md: 12 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("支付编号：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderList.id)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "card--info", attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("商户：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderList.companySName)),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("平台服务商："),
                  ]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.paymentOrderList.platformServiceProvider)
                    ),
                  ]),
                ]),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付清单："),
                    ]),
                    _vm._l(
                      _vm.paymentOrderList.paymentInventoryList,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openFile(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("关联的任务："),
                    ]),
                    _vm._l(
                      _vm.paymentOrderList.taskVoList,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openTaskVo(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("交付支付验收单："),
                    ]),
                    _vm._l(
                      _vm.paymentOrderList.acceptanceCertificateList,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openFile(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("总包支付回单："),
                    ]),
                    _vm._l(
                      _vm.paymentOrderList.subpackagePaymentList,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openFile(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("状态：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("payTotalAndSubStatus")(
                          _vm.paymentOrderList.paymentOrderStatus
                        )
                      )
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("支付时间：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderList.paymentDate)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("开票清单明细信息")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c("worker-pay-list", { ref: "payList", attrs: { type: 0 } }),
            _c("div", { staticClass: "table-wrap" }, [
              _c("ul", { staticClass: "table" }, [
                _c("li", { staticClass: "u-flex" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", { staticClass: "txt" }, [_vm._v("购买方")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _vm._v("名称：" + _vm._s(_vm.BuyerInfo.companyName)),
                    ]),
                    _c("p", [
                      _vm._v(
                        "纳税人识别号：" + _vm._s(_vm.BuyerInfo.creditCode)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址、电话：" +
                          _vm._s(_vm.BuyerInfo.addressAndTelephone)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "开户行及账号：" + _vm._s(_vm.BuyerInfo.bankAndAccount)
                      ),
                    ]),
                  ]),
                ]),
                _c("li", { staticClass: "count" }, [
                  _vm._v("价税合计：" + _vm._s(_vm.$u.formatMoney(_vm.count))),
                ]),
                _c("li", { staticClass: "u-flex" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", { staticClass: "txt" }, [_vm._v("销售方")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _vm._v(
                        "名称：" + _vm._s(_vm.sellerInfo.invoiceEnterpriseName)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "纳税人识别号：" + _vm._s(_vm.sellerInfo.invoiceTaxNo)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址、电话：" +
                          _vm._s(_vm.sellerInfo.invoiceAddressPhone)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "开户行及账号：" +
                          _vm._s(_vm.sellerInfo.invoiceBankNameAccount)
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "form-wrap" },
              [
                _vm.type == "add" || _vm.type == "editor"
                  ? _c(
                      "el-form",
                      { ref: "form", attrs: { "label-width": "80px" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "830px" },
                            attrs: { required: "", label: "开票类目" },
                          },
                          [
                            _c(
                              "el-table",
                              {
                                ref: "singleTable",
                                staticClass: "table-radio",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  data: _vm.tableData,
                                  "max-height": "400px",
                                },
                              },
                              [
                                _c("el-table-column", {
                                  attrs: {
                                    type: "index",
                                    label: "单选",
                                    width: "50",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (scope) {
                                          return [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.billingRadio,
                                                  expression: "billingRadio",
                                                },
                                              ],
                                              attrs: { type: "radio" },
                                              domProps: {
                                                value: scope.row.id,
                                                checked: _vm._q(
                                                  _vm.billingRadio,
                                                  scope.row.id
                                                ),
                                              },
                                              on: {
                                                change: function ($event) {
                                                  _vm.billingRadio =
                                                    scope.row.id
                                                },
                                              },
                                            }),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    4075245572
                                  ),
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "serviceType",
                                    label: "服务类型",
                                    width: "120",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "serviceContent",
                                    label: "具体服务内容",
                                    width: "400",
                                    align: "center",
                                  },
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    property: "billingCategory",
                                    label: "开票类目",
                                    align: "center",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "radio_box" }, [
                  _c("div", [_c("span", [_vm._v("*")]), _vm._v(" 票种:")]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.submitFome.invoiceForm,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitFome, "invoiceForm", $$v)
                            },
                            expression: "submitFome.invoiceForm",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("普通发票"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("增值税专用发票"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "radio_box" }, [
                  _c("div", [_c("span", [_vm._v("*")]), _vm._v(" 发票类型:")]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.changeRadio },
                          model: {
                            value: _vm.submitFome.invoiceType,
                            callback: function ($$v) {
                              _vm.$set(_vm.submitFome, "invoiceType", $$v)
                            },
                            expression: "submitFome.invoiceType",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("电子发票"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("纸质发票"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
                _vm.type == "add" || _vm.type == "editor"
                  ? _c(
                      "el-form",
                      { attrs: { "label-width": "80px" } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticStyle: { width: "830px" },
                            attrs: {
                              required: "",
                              label:
                                _vm.submitFome.invoiceType != 1
                                  ? "收件地址"
                                  : "",
                            },
                          },
                          [
                            _vm.submitFome.invoiceType != 1
                              ? _c(
                                  "el-table",
                                  {
                                    ref: "singleTable",
                                    staticClass: "table-radio",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: _vm.addressList,
                                      "max-height": "400px",
                                    },
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        type: "index",
                                        label: "单选",
                                        width: "50",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (scope) {
                                              return [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: _vm.addressRadio,
                                                      expression:
                                                        "addressRadio",
                                                    },
                                                  ],
                                                  attrs: { type: "radio" },
                                                  domProps: {
                                                    value: scope.row.id,
                                                    checked: _vm._q(
                                                      _vm.addressRadio,
                                                      scope.row.id
                                                    ),
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.addressRadio =
                                                        scope.row.id
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        203153284
                                      ),
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        property: "linkName",
                                        label: "收件人",
                                        width: "120",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        property: "addressName",
                                        label: "详细地址",
                                        width: "400",
                                        align: "center",
                                      },
                                    }),
                                    _c("el-table-column", {
                                      attrs: {
                                        property: "linkMobile",
                                        label: "联系电话",
                                        align: "center",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.submitFome.invoiceType != 1
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "add-address",
                                    on: {
                                      click: function ($event) {
                                        _vm.showAddress = true
                                      },
                                    },
                                  },
                                  [_vm._v(" + 新增收获地址 ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注说明" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入备注说明",
                                type: "textarea",
                              },
                              model: {
                                value: _vm.submitFome.applicationDesc,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.submitFome,
                                    "applicationDesc",
                                    $$v
                                  )
                                },
                                expression: "submitFome.applicationDesc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type === "examine"
                  ? _c(
                      "el-form",
                      { attrs: { "label-width": "80px" } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注说明" } },
                          [
                            _c("el-input", {
                              attrs: {
                                disabled: true,
                                placeholder: "请输入备注说明",
                                type: "textarea",
                              },
                              model: {
                                value: _vm.submitFome.applicationDesc,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.submitFome,
                                    "applicationDesc",
                                    $$v
                                  )
                                },
                                expression: "submitFome.applicationDesc",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.type == "add" || _vm.type == "editor"
                  ? _c(
                      "div",
                      {
                        staticClass: "flex justify-start align-items",
                        staticStyle: { height: "100px", "margin-left": "80px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.submit },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.type === "examine"
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("开票类目")]),
            _c(
              "div",
              {
                staticClass: "card--content",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c("ul", { staticClass: "invoiceCatalogVo-wrap" }, [
                  _c("li", { staticClass: "item flex align-items" }, [
                    _c("span", [_vm._v("服务类型：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.invoiceCatalogVo.billingCategory)),
                    ]),
                  ]),
                  _c("li", { staticClass: "item flex align-items" }, [
                    _c("span", [_vm._v("具体服务内容：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.invoiceCatalogVo.serviceContent)),
                    ]),
                  ]),
                  _c("li", { staticClass: "item flex align-items" }, [
                    _c("span", [_vm._v("开票类目：")]),
                    _c("span", [
                      _vm._v(_vm._s(_vm.invoiceCatalogVo.serviceType)),
                    ]),
                  ]),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.type === "examine"
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("收件信息")]),
            _c(
              "div",
              {
                staticClass: "card--content",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c(
                  "el-row",
                  { staticClass: "card--info", attrs: { gutter: 20 } },
                  [
                    _c("el-col", { attrs: { md: 8 } }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("收件人："),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm.sendAndReceiveVo.addressee)),
                      ]),
                    ]),
                    _c("el-col", { attrs: { md: 8 } }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("联系电话："),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm.sendAndReceiveVo.addresseeTelephone)),
                      ]),
                    ]),
                    _c("el-col", { attrs: { md: 8 } }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("详细地址："),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm.sendAndReceiveVo.toAddress)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.type === "examine" && _vm.tax.invoiceUrl
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("发票信息")]),
            _c(
              "div",
              {
                staticClass: "card--content",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("发票：")]),
                  _c(
                    "span",
                    { staticClass: "value" },
                    [
                      !_vm.$u.test.isEmpty(_vm.tax.invoiceUrl)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(_vm.tax.invoiceUrl)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("税票：")]),
                  _c(
                    "span",
                    { staticClass: "value" },
                    [
                      !_vm.$u.test.isEmpty(_vm.tax.taxReceiptUrl)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(_vm.tax.taxReceiptUrl)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.type === "examine"
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("物流信息")]),
            _c(
              "div",
              {
                staticClass: "card--content",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c("div", { staticClass: "express-info" }, [
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "物流公司：" +
                          _vm._s(_vm.sendAndReceiveVo.logisticsCompany)
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "物流单号：" +
                          _vm._s(_vm.sendAndReceiveVo.logisticsOrderNo)
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "收件信息：" + _vm._s(_vm.sendAndReceiveVo.toAddress)
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "发件信息：" +
                          _vm._s(_vm.sendAndReceiveVo.sendingAddress)
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "logistics-info" },
                  _vm._l(_vm.expressLogisticsInfoList, function (item, index) {
                    return _c(
                      "el-row",
                      {
                        key: index,
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { gutter: 10 },
                      },
                      [
                        _c("el-col", { attrs: { md: 6 } }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(_vm._s(item.acceptTime)),
                          ]),
                        ]),
                        _c("el-col", { attrs: { md: 18 } }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(_vm._s(item.acceptStation)),
                          ]),
                        ]),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            model: _vm.addressFrom,
            title: "添加收货地址",
            visible: _vm.showAddress,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showAddress = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showAddress,
                  expression: "showAddress",
                },
              ],
              attrs: { "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { required: "", label: "收件人" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入收件人" },
                    model: {
                      value: _vm.addressFrom.linkName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressFrom, "linkName", $$v)
                      },
                      expression: "addressFrom.linkName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "联系电话" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入联系电话" },
                    model: {
                      value: _vm.addressFrom.linkMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressFrom, "linkMobile", $$v)
                      },
                      expression: "addressFrom.linkMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { required: "", label: "详细地址" } },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入详细地址" },
                    model: {
                      value: _vm.addressFrom.addressName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addressFrom, "addressName", $$v)
                      },
                      expression: "addressFrom.addressName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "设置默认" } },
                [
                  _c("el-switch", {
                    model: {
                      value: _vm.defaultAddress,
                      callback: function ($$v) {
                        _vm.defaultAddress = $$v
                      },
                      expression: "defaultAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showAddress = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.addAddress } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "关联的任务", visible: _vm.showTaskVo },
          on: {
            "update:visible": function ($event) {
              _vm.showTaskVo = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("任务编号：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.taskCode)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("任务名称：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.taskName)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("任务类型：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.industryType)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("开始时间：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.releaseDate)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("结束时间：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.deadlineDate)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showTaskVo = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }