var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  { staticClass: "u-flex u-col-center user-base-box" },
                  [
                    _c(
                      "div",
                      { staticClass: "name u-flex u-col-center u-flex-1" },
                      [
                        _c("avatar", {
                          staticClass: "u-m-r-10",
                          attrs: { src: _vm.queryData.user_head, size: 50 },
                        }),
                        _c("div", [
                          _c("div", { staticClass: "u-font-18" }, [
                            _vm._v(
                              "商户：" + _vm._s(_vm.queryData.merchantName)
                            ),
                          ]),
                          _c("div", { staticClass: "u-font-14 u-m-t-5" }, [
                            _vm._v(
                              "商户编号：" + _vm._s(_vm.queryData.merchantId)
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 12 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  { staticClass: "u-flex u-col-center user-base-box" },
                  [
                    _c(
                      "div",
                      { staticClass: "name u-flex u-col-center u-flex-1" },
                      [
                        _c("avatar", {
                          staticClass: "u-m-r-10",
                          attrs: { src: _vm.queryData.user_head, size: 50 },
                        }),
                        _c("div", [
                          _c("div", { staticClass: "u-font-18" }, [
                            _vm._v("服务商：" + _vm._s(_vm.queryData.taxName)),
                          ]),
                          _c("div", { staticClass: "u-font-14 u-m-t-5" }, [
                            _vm._v(
                              "服务商编号：" + _vm._s(_vm.queryData.taxId)
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      +_vm.queryData.packageStatus === 0
        ? _c(
            "div",
            { staticClass: "u-m-t-20" },
            [_c("total-detail", { attrs: { "is-component": "" } })],
            1
          )
        : _c(
            "div",
            { staticClass: "u-m-t-20" },
            [_c("crowd-detail", { attrs: { "is-component": "" } })],
            1
          ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }