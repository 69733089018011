<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-card header="支付方式" class="u-m-t-20">
      <union-list :tax-id="id" />
    </el-card>
    <el-card header="交易记录" class="u-m-t-20">
      <pay-history-list :tax-id="id" />
    </el-card>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import UnionList from '@/views/service/components/unionList'
import PayHistoryList from '@/views/service/components/payHistoryList'
export default {
  components: { PayHistoryList, UnionList },
  data() {
    return {
      id: undefined
    }
  },
  created() {
    this.id = this.$route.query.id
  }
}
</script>

<style scoped>

</style>
