var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { inline: true, model: _vm.paramsData, size: "small" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "组织结构" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    placeholder: "请选择",
                    clearable: "",
                    filterable: "",
                  },
                  model: {
                    value: _vm.paramsData.customerType,
                    callback: function ($$v) {
                      _vm.$set(_vm.paramsData, "customerType", $$v)
                    },
                    expression: "paramsData.customerType",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "直属商户流水", value: 0 },
                  }),
                  _c("el-option", { attrs: { label: "下属代理商", value: 1 } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.resetPage } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, "tooltip-effect": "dark" },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "tradeNo",
              label: "订单号",
              width: "100",
              "show-overflow-tooltip": "",
              align: "center",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "companyName", label: "商户名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { prop: "taxName", label: "服务商名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "代理商", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.$u.test.isEmpty(scope.row.agentName)
                            ? scope.row.agentName
                            : "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "合作类型", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("cooperateMode")(scope.row.paymentOrderType)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "peopleNum", label: "总人数", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "实发总额", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.$u.test.isEmptyNotZero(scope.row.workerMoney)
                            ? "￥" + _vm.$u.formatMoney(scope.row.workerMoney)
                            : "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "服务费\n企业/个人", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.$u.test.isEmptyNotZero(
                            scope.row.merchantServiceCharge
                          )
                            ? "￥" +
                                _vm.$u.formatMoney(
                                  scope.row.merchantServiceCharge
                                )
                            : "-"
                        ) +
                        " / " +
                        _vm._s(
                          !_vm.$u.test.isEmptyNotZero(
                            scope.row.workerServiceCharge
                          )
                            ? "￥" +
                                _vm.$u.formatMoney(
                                  scope.row.workerServiceCharge
                                )
                            : "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "提成金额", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          !_vm.$u.test.isEmptyNotZero(scope.row.totalDifference)
                            ? "￥" +
                                _vm.$u.formatMoney(scope.row.totalDifference)
                            : "-"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", width: "80", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleShow(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pager-box" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next, jumper",
              "current-page": _vm.paramsData.pageNo,
              "page-size": _vm.paramsData.pageSize,
              "page-count": _vm.pageTotal,
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.paramsData, "pageNo", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.paramsData, "pageNo", $event)
              },
              "current-change": _vm.fetchData,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }