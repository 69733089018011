<template>
  <div class="wrap-fixed-content">
    <div class="bg-white u-flex u-row-right u-col-center u-p-lf-30 u-p-b-20 u-font-14">
      <div class="u-p-r-20">众包支付清单模板下载：<el-button v-loading="downloadLoading" type="success" size="small" icon="el-icon-download" @click="download(1)">点击下载</el-button>
      </div>
      <div>交付支付验收单模板下载：<el-button v-loading="downloadLoading" type="success" size="small" icon="el-icon-download" @click="download(2)">点击下载</el-button>
      </div>
    </div>
    <div class="u-m-25 u-p-30 bg-white">
      <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
        <el-form-item label="商户" prop="merchantId">
          <el-select v-model="formData.merchantId" style="width: 100%" placeholder="商户" clearable filterable @change="showPrice">
            <el-option v-for="i in shopList" :key="i.id" :label="i.companyName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="平台服务商" prop="taxId">
          <el-select v-model="formData.taxId" style="width: 100%" placeholder="平台服务商" clearable filterable @change="showPrice">
            <el-option v-for="i in serviceList" :key="i.id" :label="i.taxName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="项目合同" required>
          <div class="u-flex u-col-center">
            <button-upload type="image/pdf" text="上传项目合同(pdf/jpg)" @upload-success="setField('companyContract', $event)" />
            <el-button v-if="!$u.test.isEmpty(formData.companyContract)" class="u-m-l-10" type="text" @click="$preViewer(formData.companyContract)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="支付清单" required>
          <div class="u-flex u-col-center">
            <button-upload :default-action="action" :success-toast="false" :error-toast="false" return-all name="uploadInvoice" type="excel" text="上传支付清单(Excel)" @upload-success="uploadExcel($event)" @upload-error="showErrorModal" />
            <el-button v-if="!$u.test.isEmpty(formData.paymentInventory)" class="u-m-l-10" type="text" @click="$preViewer(formData.paymentInventory)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="关联任务">
          <div class="u-dashed-border u-text-center u-point" style="max-width: 800px" @click="showDialog"><i class="el-icon-plus u-m-r-10" />添加关联任务</div>
          <el-table v-show="taskList.length" :data="taskList" max-height="200" tooltip-effect="dark" style="width: 100%">
            <el-table-column prop="taskCode" label="任务编号" width="80" align="center" show-overflow-tooltip />
            <el-table-column prop="taskName" label="任务名称" align="center" show-overflow-tooltip />
            <el-table-column label="操作" align="center" width="80">
              <template slot-scope="scope">
                <el-button type="text" @click="removeTask(scope.$index)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="任务名称" prop="taskName">
          <el-input v-model="formData.taskName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="交付支付验收单">
          <div class="u-flex u-col-center">
            <button-upload type="image/pdf" text="上传交付支付验收单(pdf/jpg)" @upload-success="setField('acceptanceCertificate', $event)" />
            <el-button v-if="!$u.test.isEmpty(formData.acceptanceCertificate)" class="u-m-l-10" type="text" @click="$preViewer(formData.acceptanceCertificate)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="支付回单">
          <div class="u-flex u-col-center">
            <button-upload type="image/pdf" text="上传支付回单(pdf/jpg)" @upload-success="setField('manyPayment', $event)" />
            <el-button v-if="!$u.test.isEmpty(formData.manyPayment)" class="u-m-l-10" type="text" @click="$preViewer(formData.manyPayment)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="服务费率" prop="taxStatus">
          <el-radio-group v-model="formData.taxStatus">
            <el-radio :label="0">商户承担</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="!$u.test.isEmptyNotZero(priceData)">
          <el-form-item v-if="typeof priceData === 'object'" label="服务费率梯度价">
            <div v-for="(i, k) in priceData" :key="k">
              {{ $u.formatMoney(i.startMoney) }}元 ~ {{ $u.formatMoney(i.endMoney) }}元 : {{ i.serviceCharge }}%
            </div>
          </el-form-item>
          <el-form-item v-else label="服务费率一口价">
            {{ priceData }}%
          </el-form-item>
        </template>
      </el-form>
    </div>
    <div class="wrap-fixed-footer btn--box">
      <el-button v-loading="loading" size="small" type="primary" @click="onSubmit('formData')">下一步</el-button>
      <el-button size="small" @click="$router.go(-1)">取消</el-button>
    </div>
    <el-dialog title="选择工单" :visible.sync="dialogTableVisible" width="1000px">
      <task-lookup ref="taskLookup" :merchant-id="formData.merchantId" :cooperate-mode="1" @on-select="onSelect" />
    </el-dialog>
  </div>
</template>

<script>
import { getServiceList, getCrowdShopList } from '@/api/common'
import ButtonUpload from '@/components/Upload/buttonUpload'
import { downloadCrowdTemp, getCrowdDetail, getPrice, updateCrowd } from '@/api/pay'
import { downFile } from '@/utils'
import TaskLookup from '@/views/pay/components/taskLookup'
const defaultForm = {
  'id': '',
  'merchantId': '',
  'acceptanceCertificate': '',
  'companyContract': '',
  'paymentInventory': '',
  'manyPayment': '',
  'taskId': '',
  'taskName': '',
  'taskType': '',
  'taxId': '',
  'taxStatus': 0
}
export default {
  name: 'PayCrowdAdd',
  components: { TaskLookup, ButtonUpload },
  data() {
    return {
    //   action: readExcel(1),
      loading: false,
      downloadLoading: false,
      excelData: [],
      priceData: undefined,
      shopList: [],
      serviceList: [],
      taskList: [],
      dialogTableVisible: false,
      formData: Object.assign({}, defaultForm),
      rules: {
        taxId: [
          { required: true, message: '请选择平台服务商', trigger: ['change', 'blur'] }
        ],
        merchantId: [
          { required: true, message: '请选择商户', trigger: ['change', 'blur'] }
        ],
        taxStatus: [
          { required: true, message: '请选择服务费率', trigger: ['change', 'blur'] }
        ],
        taskName: [
          { required: true, message: '请输入任务名称', trigger: ['change', 'blur'] }
        ],
        taskType: [
          { required: true, message: '请选择任务类型', trigger: ['change', 'blur'] }
        ]
      }
    }
  },
  computed: {
    action: function() {
      return '/platform/file/uploadInvoice?isNot=1&taxId=' + this.formData.taxId
    }
  },
  created() {
    this.fetchService()
    this.fetchShop()
    if (this.$route.query.id) {
      this.fetchData(this.$route.query.id)
    }
  },
  methods: {
    showPrice() {
      const { merchantId, taxId } = this.formData
      if (!this.$u.test.isEmpty(merchantId) && !this.$u.test.isEmpty(taxId)) {
        getPrice({
          merchantId: merchantId,
          taxId: taxId,
          packageStatus: 1
        }).then(res => {
          this.priceData = res.data || res.obj
        }).catch(res => {
          this.priceData = ''
        })
      } else {
        this.priceData = ''
      }
    },
    showErrorModal(res) {
      this.$errorModal(res.message)
    },
    fetchData(id) {
      this.loading = true
      getCrowdDetail({
        id: id
      }).then(res => {
        const data = res.obj || {}
        const formData = data.paymentOrderInfoPO || {}
        const form = Object.assign({}, defaultForm)
        Object.keys(form).forEach(key => {
          form[key] = formData[key] !== undefined ? formData[key] : undefined
        })
        form.id = id
        this.formData = form
        this.excelData = data.paymentInventories || []
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    download(type) {
      this.downloadLoading = true
      downloadCrowdTemp(type).then(res => {
        downFile(res.obj)
        this.downloadLoading = false
      }).catch(res => {
        this.downloadLoading = false
      })
    },
    showDialog() {
      if (this.$u.test.isEmpty(this.formData.merchantId)) {
        return this.$message.error('请选择商户后进行此操作')
      }
      this.dialogTableVisible = true
      this.$nextTick(() => {
        this.$refs.taskLookup.resetPage()
      })
    },
    setField(field, val) {
      this.formData[field] = val
    },
    uploadExcel(e) {
      const data = e.obj || {}
      this.formData.paymentInventory = data.accessPath || ''
      this.excelData = data.paymentInventorys || []
      if (typeof data.workerList === 'object' && data.workerList.length > 0) {
        const msg = data.workerList.join(',')
        this.$errorModal(msg, 'el-icon-warning')
      } else {
        this.$successModal('Excel上传成功')
      }
    },
    fetchService() {
      getServiceList().then(res => {
        this.serviceList = res.data || []
      })
    },
    fetchShop() {
      getCrowdShopList().then(res => {
        this.shopList = res.data || []
      })
    },
    onSelect(e) {
      this.formData.taskType = e.industryType
      this.formData.taskName = e.taskName
      this.taskList = [e]
      this.dialogTableVisible = false
    },
    removeTask(index) {
      this.taskList.splice(index, 1)
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          if (this.$u.test.isEmpty(form.paymentInventory)) {
            return this.$message.error('请上传支付清单')
          }
          if (this.$u.test.isEmpty(form.companyContract)) {
            return this.$message.error('请上传项目合同')
          }
          if (this.$u.test.isEmpty(form.manyPayment)) {
            return this.$message.error('请上传支付回单')
          }
          const newForm = {
            paymentOrderManyDto: {},
            paymentInventories: []
          }
          newForm.paymentInventories = this.excelData
          if (this.taskList.length) {
            const task = this.taskList[0]
            form.taskId = task.id
            form.taskName = task.taskName
          }
          newForm.paymentOrderManyDto = form
          this.loading = true
          updateCrowd(newForm, {
            merchantId: form.merchantId
          }).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$router.go(-1)
          }).catch(res => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
