/**
 * 过滤
 * @param value
 * @param data
 * @param isNumber
 * @returns {string|*}
 * @private
 */
function __filter(value, data, isNumber) {
  if (value === undefined) {
    return data;
  }
  value = isNumber === false ? value : parseInt(value);
  let name = '';
  for (let i = 0, len = data.length; i < len; i++) {
    if (data[i].value === value) {
      name = data[i].name;
      break;
    }
  }
  return name;
}
/**
 * 合作类型
 * @param {Object|undefined} value
 */
function cooperateMode(value) {
  const data = [
    { value: 0, name: '总包+分包' },
    // { value: 1, name: '众包' }
  ];
  return __filter(value, data);
}

/**
 * 任务模式
 * @param value
 * @returns {string|[{name: string, value: number}, {name: string, value: number}]}
 */
function taskMode(value) {
  const data = [
    { value: 0, name: '派单' },
    { value: 1, name: '抢单' },
    { value: 2, name: '混合' },
  ];
  return __filter(value, data);
}

/**
 * 任务状态
 * @param value
 * @returns {[{name: string, type: string, value: number}, {name: string, type: string, value: number}, {name: string, type: string, value: number}, {name: string, type: string, value: number}, {name: string, type: string, value: number}]|string}
 */
function taskState(value) {
  const data = [
    { value: 0, name: '发布中', type: 'success' },
    { value: 1, name: '已关单', type: 'info' },
    { value: 2, name: '验收中', type: 'danger' },
    { value: 3, name: '已完毕', type: 'primary' },
    { value: 4, name: '已作废', type: 'info' },
  ];
  return __filter(value, data);
}

/**
 * 创客认证状态
 * @param value
 * @returns {string|[{name: string, type: string, value: number}, {name: string, type: string, value: number}]}
 */
function workAttestation(value) {
  const data = [
    { value: 0, name: '未认证', type: 'danger' },
    { value: 1, name: '已认证', type: 'success' },
    { value: 2, name: '认证失败', type: 'danger' },
  ];
  return __filter(value, data);
}

/**
 * 创客加盟合同状态
 * @param value
 * @returns {string|*}
 */
function workAgreementSign(value) {
  const data = [
    { value: 0, name: '未签约', type: 'info' },
    { value: 1, name: '签约中', type: 'primary' },
    { value: 2, name: '签约成功', type: 'success' },
    { value: 3, name: '签约失败', type: 'danger' },
  ];
  return __filter(value, data);
}
/**
 * 创客任务状态
 * @param value
 * @returns {string|*}
 */
function workerTaskStatus(value) {
  const data = [
    { value: 0, name: '进行中', type: 'primary' },
    { value: 1, name: '已完成', type: 'success' },
    { value: 2, name: '已失效', type: 'info' },
    { value: 3, name: '已提交', type: 'success' },
    { value: 4, name: '已验收', type: 'success' },
    { value: 5, name: '已失效', type: 'info' },
  ];
  return __filter(value, data);
}

/**
 * 总包支付状态
 * @param value
 * @returns {string|*}
 */
function payTotalAndSubStatus(value) {
  const data = [
    { value: -1, name: '支付失败', type: 'danger' },
    { value: 0, name: '申请中', type: 'info' },
    { value: 1, name: '待支付', type: 'info' },
    { value: 2, name: '已支付', type: 'primary' },
    // { value: 3, name: '已确认收款', type: 'success' },
    { value: 4, name: '支付中', type: 'primary' },
    { value: 5, name: '已驳回', type: 'danger' },
    { value: 6, name: '已完成', type: 'success' },
    { value: 7, name: '分包交易中', type: 'primary' },
    { value: 10, name: '资金校验中', type: 'primary' },
    { value: 11, name: '风控校验中', type: 'primary' },
    { value: 12, name: '重新支付中', type: 'primary' },
    { value: 14, name: '分包异常', type: 'danger' },
  ];
  return __filter(value, data);
}

/**
 * 众包支付状态
 * @param value
 * @returns {string|*}
 */
function payCrowdStatus(value) {
  const data = [
    { value: 0, name: '申请中', type: 'info' },
    { value: 1, name: '待支付', type: 'info' },
    { value: 2, name: '已支付', type: 'primary' },
    { value: 3, name: '已完成', type: 'success' },
    { value: 4, name: '已取消', type: 'danger' },
    { value: 5, name: '已驳回', type: 'danger' },
  ];
  return __filter(value, data);
}

/**
 * 税率承担方式
 * @param value
 * @returns {string|*}
 */
function taxType(value) {
  const data = [
    { value: 0, name: '商户承担' },
    { value: 1, name: '创客承担' },
    { value: 2, name: '商户和创客共同承担' },
  ];
  return __filter(value, data);
}

/**
 * 账号状态
 * @param value
 * @returns {string|*}
 */
function authStatus(value) {
  const data = [
    { value: 0, name: '正常', type: 'success' },
    { value: 1, name: '停用', type: 'danger' },
  ];
  return __filter(value, data);
}

/**
 * 开启关闭状态
 * @param value
 * @returns {string|*}
 */
function openStatus(value) {
  const data = [
    { value: 0, name: '开启', type: 'success' },
    { value: 1, name: '关闭', type: 'danger' },
  ];
  return __filter(value, data);
}
/**
 * 开票状态
 * @param value
 * @returns {string|*}
 */
function taxStatus(value) {
  const data = [
    { value: 0, name: '未开票', type: 'danger' },
    { value: 1, name: '已完成', type: 'success' },
  ];
  return __filter(value, data);
}

/**
 * 商户申请状态
 * @param value
 * @returns {string|*}
 */
function applicationState(value) {
  const data = [
    { value: 0, name: '未申请', type: 'danger' },
    { value: 1, name: '开票申请中', type: 'danger' },
    { value: 2, name: '已拒绝', type: 'danger' },
    { value: 3, name: '开票完成', type: 'success' },
    { value: 4, name: '未开票', type: 'danger' },
  ];
  return __filter(value, data);
}

/**
 * 开票状态
 * @param value
 * @returns {string|*}
 */
function invoiceStatus(value) {
  const data = [
    { value: 0, name: '未开票', type: 'danger' },
    { value: 1, name: '已开票', type: 'success' },
  ];
  return __filter(value, data);
}

/**
 * 开票状态
 * @param value
 * @returns {string|*}
 */
function invoiceStatus2(value) {
  const data = [
    { value: 0, name: '否', type: 'danger' },
    { value: 1, name: '是', type: 'success' },
  ];
  return __filter(value, data);
}

/**
 * 默认状态
 * @param value
 * @returns {string|*}
 */
function defaultStatus(value) {
  const data = [
    { value: 0, name: '是', type: 'success' },
    { value: 1, name: '否', type: 'danger' },
  ];
  return __filter(value, data);
}

/**
 * 分包开票状态
 * @param value
 * @returns {string|*}
 */
function subpackageStatus(value) {
  const data = [
    { value: 0, name: '开票', type: 'danger' },
    { value: 1, name: '已开票', type: 'success' },
  ];
  return __filter(value, data);
}
/**
 * 支付状态
 * @param value
 * @returns {string|*}
 */
function paymentStatus(value) {
  const data = [
    { value: -1, name: '支付失败', type: 'danger' },
    { value: 0, name: '未支付', type: 'info' },
    { value: 1, name: '支付成功', type: 'success' },
    { value: 2, name: '已关闭', type: 'danger' },
    { value: 4, name: '支付中', type: 'info' },
    { value: 12, name: '重新支付中', type: 'primary' },
  ];
  return __filter(value, data);
}

/**
 * 支付方式
 * @param value
 * @returns {string|*}
 */
function payType(value) {
  const data = [
    { value: 1, name: '线下支付' },
    { value: 2, name: '银联支付', disabled: true },
    { value: 'alipay', name: '支付宝' },
    { value: 'alipayUnionPay', name: '支付宝银联联动支付' },
  ];
  return __filter(value, data);
}

/**
 * 银联银行类型
 * @param value
 * @returns {string|*}
 */
function unionpayBankType(value) {
  const data = [
    { value: 'PABK', name: '平安银行', mode: 4 },
    { value: 'SJBK', name: '盛京银行', mode: 3 },
    { value: 'WSBK', name: '网商银行', mode: 5 },
    { value: 'ZSBK', name: '招商银行', mode: 7 },
  ];
  return __filter(value, data, false);
}

/**
 * 所有的支付方式
 * @param value
 * @returns {string|*}
 */
function allUnionpayBankType(value) {
  const data = [
    { value: 0, name: '线下支付' },
    { value: 1, name: '支付宝' },
    { value: 2, name: '网商银行支付' },
    { value: 3, name: '银联盛京银行' },
    { value: 4, name: '银联平安银行' },
    { value: 5, name: '银联网商银行' },
    { value: 6, name: '支付宝银联联动支付' },
    { value: 7, name: '招商银行' },
  ];
  return __filter(value, data, true);
}
/**
 * 所有的支付方式
 * @param value
 * @returns {string|*}
 */
function settlementState(value) {
  const data = [
    { value: 0, name: '未结算', type: 'danger' },
    { value: 1, name: '已结算', type: 'success' },
  ];
  return __filter(value, data, true);
}

/**
 * 获取个体户/个独状态
 * @param value
 * @returns {[{name: string, value: string}]|string}
 */
export function getIbState(value) {
  const data = [
    { value: 'EDITING', name: '编辑中' },
    { value: 'BUSINESSREGISTERING', name: '工商注册中' },
    { value: 'BANKACCOUNTING', name: '银行开户中' },
    { value: 'TAXREGISTERING', name: '税务登记中' },
    { value: 'OPERATING', name: '运营中' },
    { value: 'CANCELLED', name: '已注销' },
  ];
  if (!value) {
    return data;
  }
  let name = '';
  for (let i = 0, len = data.length; i < len; i++) {
    if (data[i].value === value) {
      name = data[i].name;
      break;
    }
  }
  return name;
}

/**
 * 获取个体户税种
 * @param {Object} value
 */
export function getPersonalTax(value) {
  const data = [
    { value: 'SMALL', name: '小规模 \n 3%普票、专票，开票额：450万元' },
  ];
  if (!value) {
    return data;
  }
  let name = '';
  for (let i = 0, len = data.length; i < len; i++) {
    if (data[i].value === value) {
      name = data[i].name;
      break;
    }
  }
  return name;
}

/**
 * 任务状态
 * @param value
 * @returns {string|*}
 */
function taskCheckStatus(value) {
  const data = [
    { value: 'UNAUDIT', name: '审核中', type: 'info' },
    { value: 'PASSAUDIT', name: '审核成功', type: 'success' },
    { value: 'NOTAUDIT', name: '审核失败', type: 'danger' },
  ];
  return __filter(value, data, false);
}

/**
 * 支付宝协议状态
 * @param value
 * @returns {string|*}
 */
function alipayStatus(value) {
  const data = [
    { value: 'TEMP', name: '暂存，协议未生效过', type: 'danger' },
    { value: 'NORMAL', name: '正常', type: 'success' },
    { value: 'STOP', name: '暂停', type: 'danger' },
  ];
  return __filter(value, data, false);
}

/**
 * 记事本类型
 * @param value
 * @returns {string|*}
 */
function alipaySignType(value) {
  const data = [
    { value: 'COMMON', name: '通用记事本', type: 'info' },
    { value: 'SERVICECHARGE', name: '手续费记事本', type: 'info' },
  ];
  return __filter(value, data, false);
}
/**
 * 记事本类型
 * @param value
 * @returns {string|*}
 */
function alipayUnionpaySignType(value) {
  const data = [
    { value: 'COMMON', name: '通用子账户', type: 'info' },
    { value: 'SERVICECHARGE', name: '手续费子账户', type: 'info' },
  ];
  return __filter(value, data, false);
}
function agreementTypeStatus(value) {
  const data = [
    { value: 'WORKER_JOIN_AGREEMENT', name: '创客加盟协议' },
    { value: 'ENTERPRISE_JOIN_AGREEMENT', name: '商户加盟协议' },
    { value: 'TAX_JOIN_AGREEMENT', name: '服务商加盟协议' },
    { value: 'AGENT_JOIN_AGREEMENT', name: '代理商加盟协议' },
    { value: 'WORKER_POWER_AGREEMENT', name: '创客授权协议' },
    { value: 'TAX_ENTERPRISE_AGREEMENT', name: '服务商跟商户协议' },
    { value: 'TAX_WORKER_AGREEMENT', name: '服务商与创客承揽协议' },
  ];
  return __filter(value, data);
}
function signStatus(value) {
  const data = [
    { value: 'UNSIGN', name: '未签', type: 'danger' },
    { value: 'SIGNED', name: '已签', type: 'success' },
  ];
  return __filter(value, data, true);
}

// 招行分行号
export function getBbknBr() {
  const data = [
    {
      value: '10',
      label: '北京',
    },
    {
      value: '12',
      label: '离岸分行',
    },
    {
      value: '13',
      label: '总行离岸中心',
    },
    {
      value: '20',
      label: '广州',
    },
    {
      value: '21',
      label: '上海',
    },
    {
      value: '22',
      label: '天津',
    },
    {
      value: '23',
      label: '重庆',
    },
    {
      value: '24',
      label: '沈阳',
    },
    {
      value: '25',
      label: '南京',
    },
    {
      value: '27',
      label: '武汉',
    },
    {
      value: '28',
      label: '成都',
    },
    {
      value: '29',
      label: '西安',
    },
    {
      value: '35',
      label: '太原',
    },
    {
      value: '37',
      label: '郑州',
    },
    {
      value: '38',
      label: '石家庄',
    },
    {
      value: '39',
      label: '唐山',
    },
    {
      value: '41',
      label: '大连',
    },
    {
      value: '43',
      label: '长春',
    },
    {
      value: '45',
      label: '哈尔滨',
    },
    {
      value: '47',
      label: '呼和浩特',
    },
    {
      value: '51',
      label: '银川',
    },
    {
      value: '52',
      label: '苏州',
    },
    {
      value: '53',
      label: '青岛',
    },
    {
      value: '54',
      label: '宁波',
    },
    {
      value: '55',
      label: '合肥',
    },
    {
      value: '56',
      label: '济南',
    },
    {
      value: '57',
      label: '杭州',
    },
    {
      value: '58',
      label: '温州',
    },
    {
      value: '59',
      label: '福州',
    },
    {
      value: '60',
      label: '泉州',
    },
    {
      value: '62',
      label: '无锡',
    },
    {
      value: '63',
      label: '南通',
    },
    {
      value: '65',
      label: '烟台',
    },
    {
      value: '69',
      label: '东莞',
    },
    {
      value: '71',
      label: '南宁',
    },
    {
      value: '72',
      label: '西宁',
    },
    {
      value: '73',
      label: '长沙',
    },
    {
      value: '75',
      label: '深圳',
    },
    {
      value: '77',
      label: '佛山',
    },
    {
      value: '79',
      label: '南昌',
    },
    {
      value: '85',
      label: '贵阳',
    },
    {
      value: '87',
      label: '昆明',
    },
    {
      value: '89',
      label: '海口',
    },
    {
      value: '91',
      label: '乌鲁木齐',
    },
    {
      value: '92',
      label: '厦门',
    },
    {
      value: '93',
      label: '兰州',
    },
    {
      value: '97',
      label: '香港',
    },
    {
      value: '03',
      label: '总行会计部',
    },
    {
      value: '01',
      label: '总行',
    },
  ];
  return data;
}
export default {
  subpackageStatus,
  openStatus,
  defaultStatus,
  invoiceStatus2,
  applicationState,
  invoiceStatus,
  cooperateMode,
  taskMode,
  taskState,
  workAttestation,
  workAgreementSign,
  workerTaskStatus,
  payTotalAndSubStatus,
  payCrowdStatus,
  taxType,
  authStatus,
  taxStatus,
  paymentStatus,
  payType,
  unionpayBankType,
  allUnionpayBankType,
  settlementState,
  taskCheckStatus,
  alipayStatus,
  alipaySignType,
  agreementTypeStatus,
  signStatus,
  alipayUnionpaySignType,
};
