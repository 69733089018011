var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      +_vm.baseInfo.paymentOrderStatus === 5
        ? _c(
            "el-card",
            {
              staticClass: "u-m-b-10",
              staticStyle: { border: "1px solid #FF1A2E" },
            },
            [
              _c("div", { staticClass: "u-flex u-col-center u-p-lf-40" }, [
                _c("div", { staticClass: "u-text-center" }, [
                  _c("i", {
                    staticClass: "el-icon-error",
                    staticStyle: { "font-size": "72px", color: "#FF1A2E" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "u-m-t-10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [_vm._v("被驳回")]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "u-m-l-20 u-p-l-40 u-flex",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("div", { staticClass: "u-flex-1" }, [
                      _c(
                        "div",
                        { staticClass: "u-tips-color u-font-14 u-m-l-20" },
                        [_vm._v("请核对并修改以下信息后，再重新提交。")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "u-p-20 u-m-t-10",
                          staticStyle: { "background-color": "#fafafa" },
                        },
                        [
                          _c("div", { staticClass: "u-font-16 u-m-b-10" }, [
                            _vm._v("您提交的内容有如下问题："),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "u-font-14",
                              staticStyle: { color: "#8e9193" },
                            },
                            [_vm._v(_vm._s(_vm.baseInfo.reasonsForRejection))]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "card--title" }, [_vm._v("总包+分包信息")]),
          _c(
            "div",
            { staticClass: "card--content" },
            [
              _c(
                "el-row",
                { staticClass: "card--info", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付编号："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.paymentOrderId)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [_vm._v("商户：")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.companyName)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("平台服务商："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.platformServiceProvider)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("项目合同："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: { url: _vm.baseInfo.companyContract, btn: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付清单："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: {
                            url: _vm.baseInfo.paymentInventory,
                            btn: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("关联任务："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        !_vm.$u.test.isEmpty(_vm.baseInfo.taskId)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showTask(_vm.baseInfo.taskId)
                                  },
                                },
                              },
                              [_vm._v("查看 ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("交付支付验收单："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: {
                            url: _vm.baseInfo.acceptanceCertificate,
                            btn: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [_vm._v("状态：")]),
                    _c("span", {
                      staticClass: "value",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.filterContent(
                            _vm.baseInfo.paymentOrderStatus,
                            _vm.$enum.payTotalAndSubStatus()
                          )
                        ),
                      },
                    }),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("创建时间："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.creatDate)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付时间："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.paymentDate)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "u-m-t-20",
        },
        [
          _c("div", { staticClass: "card--title" }, [_vm._v("支付清单明细")]),
          _c(
            "div",
            { staticStyle: { padding: "20px 10px" } },
            [
              _c(
                "el-form",
                {
                  ref: "elForm",
                  attrs: { inline: true, model: _vm.formData, size: "small" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "姓名", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "name", $$v)
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "电话", prop: "mobile" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.formData.mobile,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "mobile", $$v)
                          },
                          expression: "formData.mobile",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "身份证号", prop: "idCode" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入", clearable: "" },
                        model: {
                          value: _vm.formData.idCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "idCode", $$v)
                          },
                          expression: "formData.idCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "支付状态", prop: "orderStatus" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            placeholder: "请选择",
                            clearable: "",
                            filterable: "",
                          },
                          model: {
                            value: _vm.formData.orderStatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "orderStatus", $$v)
                            },
                            expression: "formData.orderStatus",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "支付失败", value: "-1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "未支付", value: "0" },
                          }),
                          _c("el-option", {
                            attrs: { label: "支付中", value: "4" },
                          }),
                          _c("el-option", {
                            attrs: { label: "支付成功", value: "1" },
                          }),
                          _c("el-option", {
                            attrs: { label: "已关闭", value: "2" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.getPaymentInventoryList("reset")
                            },
                          },
                        },
                        [_vm._v("查询")]
                      ),
                      _c("el-button", { on: { click: _vm.reset } }, [
                        _vm._v("重置"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "card--content" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "end",
                    "align-items": "center",
                  },
                },
                [
                  _c("span", [
                    _vm._v(
                      "风控校验中 " +
                        _vm._s(_vm.progressInfo.countInProgress || 0) +
                        " 人，风控校验成功 " +
                        _vm._s(_vm.progressInfo.countSuccess || 0) +
                        " 人，风控校验失败 " +
                        _vm._s(_vm.progressInfo.countFailed || 0) +
                        " 人"
                    ),
                  ]),
                  _vm.progressInfo.countFailed
                    ? _c(
                        "el-button",
                        {
                          staticClass: "u-m-l-10",
                          attrs: {
                            type: "primary",
                            size: "small",
                            loading: _vm.errLoading,
                          },
                          on: { click: _vm.downloadErrList },
                        },
                        [_vm._v("下载校验错误清单")]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("pay-list", {
                attrs: {
                  list: _vm.list,
                  "is-edit": _vm.isEdit,
                  "base-info": _vm.baseInfo,
                },
              }),
              _vm.baseInfo.companyMoney
                ? _c("div", { staticClass: "u-m-t-10" }, [
                    _vm._v(
                      "合计（商户总支出）：￥" +
                        _vm._s(_vm.$u.formatMoney(_vm.baseInfo.realMoney))
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm.show
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("总包支付信息")]),
            _c("div", { staticClass: "card--content" }, [
              _c(
                "div",
                { staticClass: "card--info" },
                [
                  _vm.isEdit
                    ? [
                        _c("div", { staticClass: "card--info-item" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("支付方式："),
                          ]),
                          _c(
                            "span",
                            { staticClass: "value" },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  model: {
                                    value: _vm.totalForm.payType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.totalForm, "payType", $$v)
                                    },
                                    expression: "totalForm.payType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    {
                                      attrs: {
                                        label: 1,
                                        disabled: !_vm.totalPayType.length,
                                      },
                                    },
                                    [_vm._v("银联支付")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "alipay" } },
                                    [_vm._v("支付宝")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "alipayUnionPay" } },
                                    [_vm._v("支付宝银联联动支付")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "zsPay" } },
                                    [_vm._v("招行")]
                                  ),
                                  _c("el-radio", { attrs: { label: 0 } }, [
                                    _vm._v("线下支付"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _vm.totalForm.payType === 1
                          ? [
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("选择银行："),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        on: { change: _vm.fetchBalance },
                                        model: {
                                          value: _vm.totalForm.paymentMode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.totalForm,
                                              "paymentMode",
                                              $$v
                                            )
                                          },
                                          expression: "totalForm.paymentMode",
                                        },
                                      },
                                      _vm._l(_vm.totalPayType, function (i, k) {
                                        return _c(
                                          "el-radio",
                                          { key: k, attrs: { label: i.mode } },
                                          [_vm._v(_vm._s(i.name))]
                                        )
                                      }),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _vm.unionPay.length
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "u-flex u-col-top u-row-left u-flex-wrap u-m-b-10",
                                    },
                                    _vm._l(_vm.unionPay, function (i, k) {
                                      return _c("union-info", {
                                        key: k + Date.now(),
                                        attrs: { info: i },
                                      })
                                    }),
                                    1
                                  )
                                : _vm._e(),
                            ]
                          : _vm._e(),
                      ]
                    : [
                        _c("div", { staticClass: "card--info-item" }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v("支付方式："),
                          ]),
                          _c("span", { staticClass: "value" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("allUnionpayBankType")(
                                    _vm.baseInfo.paymentMode
                                  )
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ],
                  _vm.totalForm.payType === 0
                    ? _c("table", { staticClass: "c-table is-edit" }, [
                        _c("tr", [
                          _c("td", [
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("支付方："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.companyName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("开户行："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.bankName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("银行账号："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.bankCode)),
                              ]),
                            ]),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("收款方："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payee)),
                              ]),
                            ]),
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("开户行："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payeeBankName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("银行账号："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payeeBankCode)),
                              ]),
                            ]),
                          ]),
                        ]),
                        _c("tr", { class: _vm.isEdit ? "" : "no-border" }, [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "blue-box",
                                class: _vm.isEdit ? "is-edit" : "is-view",
                              },
                              [
                                _vm._v("转账金额："),
                                _c("span", { staticClass: "money" }, [
                                  _vm._v(
                                    "￥ " +
                                      _vm._s(
                                        _vm.$u.formatMoney(
                                          _vm.baseInfo.realMoney
                                        )
                                      )
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(" （ "),
                                  _c("span", [
                                    _vm._v(
                                      "发放金额:￥" +
                                        _vm._s(
                                          _vm.$u.formatMoney(
                                            _vm.baseInfo.workerMoney
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("span", { staticClass: "u-m-l-10" }, [
                                    _vm._v(
                                      "服务费:￥" +
                                        _vm._s(
                                          _vm.$u.formatMoney(
                                            _vm.baseInfo.serviceMoney
                                          )
                                        )
                                    ),
                                  ]),
                                  _vm._v(" ） "),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.isEdit
                    ? [
                        _vm.totalForm.payType === 0
                          ? _c(
                              "div",
                              { staticClass: "card--info-item u-m-t-20" },
                              [
                                _c("span", { staticClass: "label" }, [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("支付回单："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _c(
                                    "span",
                                    { staticClass: "u-flex u-col-center" },
                                    [
                                      _c("button-upload", {
                                        attrs: {
                                          type: "image/pdf",
                                          text: "上传支付回单(pdf/jpg)",
                                        },
                                        on: {
                                          "upload-success": function ($event) {
                                            return _vm.setTotalField(
                                              "turnkeyProjectPayment",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                      !_vm.$u.test.isEmpty(
                                        _vm.totalForm.turnkeyProjectPayment
                                      )
                                        ? _c(
                                            "el-button",
                                            {
                                              staticClass: "u-m-l-10",
                                              attrs: { type: "text" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$preViewer(
                                                    _vm.totalForm
                                                      .turnkeyProjectPayment
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("查看")]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            )
                          : _c("div", { staticClass: "blue-box is-view" }, [
                              _vm._v("转账金额："),
                              _c("span", { staticClass: "money" }, [
                                _vm._v(
                                  "￥ " +
                                    _vm._s(
                                      _vm.$u.formatMoney(_vm.baseInfo.realMoney)
                                    )
                                ),
                              ]),
                              _c("span", [
                                _vm._v(" （ "),
                                _c("span", [
                                  _vm._v(
                                    "发放金额:￥" +
                                      _vm._s(
                                        _vm.$u.formatMoney(
                                          _vm.baseInfo.workerMoney
                                        )
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("span", { staticClass: "u-m-l-10" }, [
                                  _vm._v(
                                    "服务费:￥" +
                                      _vm._s(
                                        _vm.$u.formatMoney(
                                          _vm.baseInfo.serviceMoney
                                        )
                                      )
                                  ),
                                ]),
                                _vm._v(" ） "),
                              ]),
                            ]),
                        _vm.totalForm.payType !== 0
                          ? [
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _vm._v("手机号："),
                                ]),
                                _c("span", { staticClass: "value" }, [
                                  _vm._v(_vm._s(_vm.baseInfo.loginMobile)),
                                ]),
                              ]),
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("验证码："),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c(
                                      "el-input",
                                      {
                                        attrs: { placeholder: "请输入" },
                                        model: {
                                          value: _vm.totalForm.checkCode,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.totalForm,
                                              "checkCode",
                                              $$v
                                            )
                                          },
                                          expression: "totalForm.checkCode",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-button",
                                          {
                                            style: {
                                              background: _vm.disabled
                                                ? "inherit"
                                                : "#fff",
                                              color: _vm.disabled
                                                ? "inherit"
                                                : "#000",
                                            },
                                            attrs: {
                                              slot: "append",
                                              type: "default",
                                              disabled: _vm.disabled,
                                            },
                                            on: { click: _vm.getCode },
                                            slot: "append",
                                          },
                                          [_vm._v(_vm._s(_vm.btnText))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                              _c("div", { staticClass: "card--info-item" }, [
                                _c("span", { staticClass: "label" }, [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v("*"),
                                  ]),
                                  _vm._v("支付密码："),
                                ]),
                                _c(
                                  "span",
                                  { staticClass: "value" },
                                  [
                                    _c("el-input", {
                                      attrs: {
                                        "show-password": "",
                                        placeholder: "请输入",
                                      },
                                      model: {
                                        value: _vm.totalForm.payPwd,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.totalForm, "payPwd", $$v)
                                        },
                                        expression: "totalForm.payPwd",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          : _vm._e(),
                      ]
                    : _vm.totalForm.payType === 0
                    ? _c("div", { staticClass: "card--info-item" }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("支付回单："),
                        ]),
                        _c(
                          "span",
                          { staticClass: "value" },
                          [
                            _c("file-icon", {
                              attrs: {
                                url: _vm.baseInfo.turnkeyProjectPayment,
                                btn: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : !_vm.isEdit
                    ? [
                        _vm.unionPay.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "u-flex u-col-top u-row-left u-flex-wrap u-m-b-10",
                              },
                              _vm._l(_vm.unionPay, function (i, k) {
                                return _c("union-info", {
                                  key: k + Date.now(),
                                  attrs: { "show-balance": false, info: i },
                                })
                              }),
                              1
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "blue-box is-view" }, [
                          _vm._v("转账金额："),
                          _c("span", { staticClass: "money" }, [
                            _vm._v(
                              "￥ " +
                                _vm._s(
                                  _vm.$u.formatMoney(_vm.baseInfo.realMoney)
                                )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(" （ "),
                            _c("span", [
                              _vm._v(
                                "发放金额:￥" +
                                  _vm._s(
                                    _vm.$u.formatMoney(_vm.baseInfo.workerMoney)
                                  ) +
                                  " "
                              ),
                            ]),
                            _c("span", { staticClass: "u-m-l-10" }, [
                              _vm._v(
                                "服务费:￥" +
                                  _vm._s(
                                    _vm.$u.formatMoney(
                                      _vm.baseInfo.serviceMoney
                                    )
                                  )
                              ),
                            ]),
                            _vm._v(" ） "),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]),
          ])
        : _vm._e(),
      [6].indexOf(+_vm.baseInfo.paymentOrderStatus) !== -1
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("分包支付信息")]),
            _c(
              "div",
              { staticClass: "card--content" },
              [
                _vm.totalForm.payType === 0
                  ? _c("div", { staticClass: "card--info" }, [
                      _c("table", { staticClass: "c-table is-edit" }, [
                        _c("tr", [
                          _c("td", [
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("支付方："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payee)),
                              ]),
                            ]),
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("开户行："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payeeBankName)),
                              ]),
                            ]),
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("银行账号："),
                              ]),
                              _c("span", { staticClass: "value" }, [
                                _vm._v(_vm._s(_vm.baseInfo.payeeBankCode)),
                              ]),
                            ]),
                          ]),
                          _c("td", [
                            _c("div", { staticClass: "card--info-item" }, [
                              _c("span", { staticClass: "label" }, [
                                _vm._v("收款方："),
                              ]),
                              _c(
                                "span",
                                { staticClass: "value" },
                                [
                                  !_vm.$u.test.isEmpty(
                                    _vm.baseInfo.paymentInventory
                                  )
                                    ? _c(
                                        "el-button",
                                        {
                                          staticClass: "u-m-l-10",
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.$preViewer(
                                                _vm.baseInfo.paymentInventory
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("查看")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                        _c("tr", { class: _vm.isEdit ? "" : "no-border" }, [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "blue-box",
                                class: _vm.isEdit ? "is-edit" : "is-view",
                              },
                              [
                                _vm._v("转账金额："),
                                _c("span", { staticClass: "money" }, [
                                  _vm._v(
                                    "￥ " +
                                      _vm._s(
                                        _vm.$u.formatMoney(
                                          _vm.baseInfo.workerMoney
                                        )
                                      )
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _c("div", { staticClass: "card--info-item" }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("支付回单："),
                        ]),
                        _c(
                          "span",
                          { staticClass: "value" },
                          [
                            _c("file-icon", {
                              attrs: {
                                url: _vm.baseInfo.subpackagePayment,
                                btn: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : [
                      _vm.unionPay.length
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "u-flex u-col-top u-row-left u-flex-wrap u-m-b-10",
                            },
                            _vm._l(_vm.unionPay, function (info, k) {
                              return _c(
                                "el-card",
                                {
                                  key: k + Date.now(),
                                  staticStyle: { width: "43%" },
                                  attrs: {
                                    shadow: "never",
                                    "body-style": { padding: 0 },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix u-text-center",
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _vm._v(
                                        " 银行类型: " +
                                          _vm._s(
                                            _vm._f("unionpayBankType")(
                                              info.unionpayBankType
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "u-flex u-col-top u-row-between",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "u-flex-1 u-padding-10 u-border-right",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v(
                                                "子账号户名：" +
                                                  _vm._s(info.subAccountName)
                                              ),
                                            ]
                                          ),
                                          info.unionpayBankType === "SJBK"
                                            ? _c(
                                                "div",
                                                { staticClass: "u-m-b-10" },
                                                [
                                                  _vm._v(
                                                    "来款银行账号：" +
                                                      _vm._s(info.inBankNo)
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v(
                                                "子账户账号：" +
                                                  _vm._s(info.subAccountCode)
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "u-flex-1 u-padding-10",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "u-m-b-10" },
                                            [
                                              _vm._v("收款方： "),
                                              !_vm.$u.test.isEmpty(
                                                _vm.baseInfo.paymentInventory
                                              )
                                                ? _c(
                                                    "el-button",
                                                    {
                                                      staticClass: "u-m-l-10",
                                                      attrs: { type: "text" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.$preViewer(
                                                            _vm.baseInfo
                                                              .paymentInventory
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("查看")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "blue-box is-view" }, [
                        _vm._v("转账金额："),
                        _c("span", { staticClass: "money" }, [
                          _vm._v(
                            "￥ " +
                              _vm._s(
                                _vm.$u.formatMoney(_vm.baseInfo.workerMoney)
                              )
                          ),
                        ]),
                      ]),
                    ],
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _vm.isEdit
            ? _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.submitLoading,
                    size: "small",
                    type: "primary",
                  },
                  on: { click: _vm.submit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看关联任务",
            visible: _vm.visibleDialog,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleDialog = $event
            },
          },
        },
        [_c("task-detail", { ref: "taskDetail" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }