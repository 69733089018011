<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="商户名称">
          <el-input v-model="formData.merchantName" placeholder="请输入商户名称" />
        </el-form-item>
        <el-form-item label="联系人手机号">
          <el-input v-model="formData.linkMobile" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button v-if="!isWait" type="primary" size="small" icon="el-icon-plus" @click="handleAdd()">
        添加商户
      </el-button>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="merchantName"
          label="商户名称"
          min-width="160"
          align="center"
        />
        <el-table-column
          prop="linkName"
          label="联系人"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="linkMobile"
          label="联系人电话"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="structure"
          label="组织结构(业务员/代理商)"
          width="180"
          align="center"
        />
        <el-table-column
          label="平台加盟合同"
          align="center"
        >
          <template slot-scope="scope">
            <file-icon :url="scope.row.contract" />
          </template>
        </el-table-column>
        <!-- 易工派 -->
        <template v-if="projectName === 'yiGongPai'">
          <el-table-column
            label="状态"
            align="center"
            width="140"
          >
            <template slot-scope="scope">
              <el-select v-model="scope.row.status" placeholder="请选择">
                <el-option label="正常状态" :value="0" />
                <el-option label="冻结状态" :value="1" />
                <el-option label="非法状态" :value="2" />
              </el-select>
            </template>
          </el-table-column>
        </template>
        <template v-else>
          <el-table-column
            label="状态"
            align="center"
          >
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.status, $enum.authStatus())" />
            </template>
          </el-table-column>
        </template>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
          width="160"
          show-overflow-tooltip
        />
        <el-table-column
          label="操作"
          width="180"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDel(scope.row)">删除</el-button>
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <template v-if="isWait">
              <el-button type="text" size="small" @click="handlePass(scope.row)">审核</el-button>
            </template>
            <template v-else>
              <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
              <el-button type="text" size="small" @click="handleShowPay(scope.row)">支付方式</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getPassList, del, getWaitList, pass } from '@/api/shop'
import { getProjectName } from '@/utils'

export default {
  name: 'ShopIndex',
  data() {
    return {
      projectName: getProjectName(),
      loading: false,
      isWait: false,
      formData: {
        merchantName: '',
        linkMobile: '',
        merchantId: '',
        managersId: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.isWait = this.$route.meta.isWait
    this.fetchData()
  },
  activated() {
    // console.log(this.$options.name + '商户管理-----activated')
    // if (this.$store.getters.isUpdatePage) {
    //   console.log(this.$options.name + '商户管理-----activated--刷新数据')
    //   this.$store.commit('common/setUpdatePage', false)
    //   this.fetchData()
    // }
    this.isWait = this.$route.meta.isWait
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      if (this.isWait) {
        getWaitList(Object.assign({}, this.formData, this.paramsData)).then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.loading = false
        }).catch(res => {
          this.loading = false
        })
      } else {
        getPassList(Object.assign({}, this.formData, this.paramsData)).then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.loading = false
        }).catch(res => {
          this.loading = false
        })
      }
    },
    handleAdd() {
      this.$store.commit('common/setUpdatePage', true)
      this.$router.push({
        path: '/shop/add'
      })
    },
    handleEdit(i) {
      this.$router.push({
        path: '/shop/edit',
        query: {
          id: i.merchantId
        }
      })
    },
    handlePass(i) {
      this.$confirm('你确定要审核吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        pass({
          companyId: i.merchantId
        }).then(res => {
          this.resetPage()
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    },
    handleShow(i) {
      this.$router.push({
        path: '/shop/view',
        query: {
          id: i.merchantId
        }
      })
    },
    handleShowPay(i) {
      this.$router.push({
        path: '/shop/pay',
        query: {
          id: i.merchantId
        }
      })
    },
    handleDel(i) {
      this.$confirm('你确定要删除吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        del({
          companyId: i.merchantId
        }).then(res => {
          this.resetPage()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
