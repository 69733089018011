<template>
  <div class="u-m-25 wrap-fixed-content">
    <el-card header="基本信息">
      <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
        <el-form-item label="代理商名称" prop="agentName">
          <el-input v-model="formData.agentName" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="登录账号" prop="userName">
          <el-input v-model="formData.userName" placeholder="请输入登录账号" />
        </el-form-item>
        <el-form-item label="联系人" prop="linkMan">
          <el-input v-model="formData.linkMan" placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="电话" prop="linkMobile">
          <el-input v-model="formData.linkMobile" placeholder="请输入电话" />
        </el-form-item>
        <el-form-item label="代理商加盟合同" required>
          <div class="u-flex u-col-center">
            <button-upload type="image" text="上传合同(png/jpg)" @upload-success="setField('contractFile', $event)" />
            <el-button v-if="!$u.test.isEmpty(formData.contractFile)" class="u-m-l-10" type="text" @click="$preViewer(formData.contractFile)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="平台业务专员" prop="salesManId">
          <el-select v-model="formData.salesManId" style="width: 100%" placeholder="平台业务专员" clearable filterable>
            <el-option v-for="i in salesMan" :key="i.id" :label="i.realName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="$u.test.isEmpty(formData.agentId)" label="初始密码" prop="initPassWord">
          <el-input v-model="formData.initPassWord" type="text" class="no-autofill-pwd" placeholder="请输入初始密码" />
        </el-form-item>
        <el-form-item v-else label="修改密码">
          <el-input v-model="formData.initPassWord" type="text" class="no-autofill-pwd" placeholder="为空则不修改" />
        </el-form-item>
        <el-form-item label="确认密码">
          <el-input v-model="formData.confirmPassWord" type="text" class="no-autofill-pwd" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="formData.agentStatus">
            <el-radio v-for="(i, k) in $enum.authStatus()" :key="k" :label="i.value">{{ i.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card>
    <!--<el-card v-loading="loading" header="佣金汇率" class="u-m-t-20">
      <commission-detail v-model="formData" />
    </el-card>-->
    <el-card header="结算信息（按服务费率差额提成）" class="u-m-t-20">
      <tax-detail ref="taxDetail" v-model="customForm" />
    </el-card>
    <div class="wrap-fixed-footer btn--box">
      <el-button :loading="loading" size="small" type="primary" @click="submitForm('formData')">确定</el-button>
      <el-button size="small" @click="$router.go(-1)">取消</el-button>
    </div>
  </div>
</template>

<script>
import { getDetail, update } from '@/api/agent'
import ButtonUpload from '@/components/Upload/buttonUpload'
import { getAssistantList } from '@/api/common'
import TaxDetail from '@/views/organization/agent/components/TaxDetail'
import { deepClone, getGuid } from '@/utils'

const defaultForm = () => {
  return {
    'agentId': '',
    'agentName': '',
    'agentStatus': 0,
    'confirmPassWord': '',
    'contractFile': '',
    'initPassWord': '',
    'linkMan': '',
    'linkMobile': '',
    'salesManId': '',
    'userName': '',
    'agentTaxDtos': [],
    'directCommissionProportion': [],
    'settlementMethod': -1
  }
}
const _customForm = () => {
  return {
    '_totalTax': [
      {
        'addCompanyLadderServiceDtoList': [
          {
            'agentTaxId': undefined,
            'endMoney': null,
            'serviceCharge': null,
            'startMoney': 0
          }
        ],
        'agentId': undefined,
        'chargeStatus': 0,
        'contract': '',
        'packageStatus': 0,
        'serviceCharge': null,
        'taxId': undefined
      }
    ],
    '_totalCheck': false,
    '_crowdTax': [
      {
        'addCompanyLadderServiceDtoList': [
          {
            'agentTaxId': undefined,
            'endMoney': null,
            'serviceCharge': null,
            'startMoney': 0
          }
        ],
        'agentId': undefined,
        'chargeStatus': 0,
        'contract': '',
        'packageStatus': 1,
        'serviceCharge': null,
        'taxId': undefined
      }
    ],
    '_crowdCheck': false
  }
}

export default {
  components: { TaxDetail, ButtonUpload },
  data() {
    const DefaultForm = defaultForm()
    return {
      loading: false,
      formData: Object.assign({}, DefaultForm),
      customForm: Object.assign({}, _customForm()),
      salesMan: [],
      rules: {
        agentName: [
          { required: true, message: '请输入代理商名称', trigger: 'blur' }
        ],
        linkMan: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        salesManId: [
          { required: true, message: '请选择平台业务专员', trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        linkMobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.mobile(value)) {
                callback(new Error('手机号码不正确'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        initPassWord: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.fetchAssistant()
    if (this.$route.query.id) {
      this.fetchData(this.$route.query.id)
    }
  },
  methods: {
    fetchAssistant() {
      getAssistantList().then(res => {
        this.salesMan = res.data || []
      })
    },
    setField(field, file) {
      this.formData[field] = file
    },
    fetchData(id) {
      this.loading = true
      getDetail({
        agentId: id
      }).then(res => {
        const data = res.obj || {}
        const DefaultForm = defaultForm()
        this.formData = Object.assign({}, DefaultForm, {
          'agentId': id,
          'agentName': data.agentName,
          'agentStatus': data.agentStatus,
          'contractFile': data.contractFile,
          'linkMan': data.linkMan,
          'linkMobile': data.linkMobile,
          'salesManId': data.salesManId,
          'userName': data.userName,
          'directCommissionProportion': data.directCommissionProportion,
          agentTaxDtos: []
        })
        const totalTax = []
        const crowdTax = []
        const totalTaxIds = []
        const crowdTaxIds = []
        if (data.agentTaxVo) {
          data.agentTaxVo.map(val => {
            if ((+val.packageStatus) === 0) {
              // 总包
              totalTax.push({
                guid: getGuid(),
                id: val.id,
                'addCompanyLadderServiceDtoList': val.agentLadderServices || [],
                'agentId': val.agentId || undefined,
                'chargeStatus': val.chargeStatus,
                'contract': val.contract || '',
                'packageStatus': 0,
                'serviceCharge': val.serviceCharge,
                'taxId': val.taxId
              })
              if (totalTaxIds.indexOf(val.taxId) === -1) {
                totalTaxIds.push(val.taxId)
              }
            } else {
              crowdTax.push({
                guid: getGuid(),
                id: val.id,
                'addCompanyLadderServiceDtoList': val.agentLadderServices || [],
                'agentId': val.agentId || undefined,
                'chargeStatus': val.chargeStatus,
                'contract': val.contract || '',
                'packageStatus': 1,
                'serviceCharge': val.serviceCharge,
                'taxId': val.taxId
              })
              if (crowdTaxIds.indexOf(val.taxId) === -1) {
                crowdTaxIds.push(val.taxId)
              }
            }
          })
        }
        // 初始化服务商信息
        totalTaxIds.map(val => {
          this.$refs.taxDetail.initService(0, val)
        })
        crowdTaxIds.map(val => {
          this.$refs.taxDetail.initService(1, val)
        })
        if (totalTax.length) {
          this.customForm._totalTax = totalTax
          this.customForm._totalCheck = true
        }
        if (crowdTax.length) {
          this.customForm._crowdTax = crowdTax
          this.customForm._crowdCheck = true
        }
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = deepClone(this.formData)
          if (form.initPassWord !== form.confirmPassWord) {
            return this.$message.error('两次密码不一致')
          }
          const agentTaxDtos = this.$refs.taxDetail.getData()
          if (agentTaxDtos === false) {
            return
          }
          form.agentTaxDtos = agentTaxDtos
          console.log(this.customForm._totalCheck)
          console.log(this.customForm._crowdCheck)
          console.log('---')
          form.settlementMethod = (this.customForm._totalCheck === false && this.customForm._crowdCheck === false) ? -1 : 1
          this.loading = true
          update(form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$router.go(-1)
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
