<template>
  <div class="u-m-20 wrap-fixed-content" style="min-width: 1200px">
    <el-card class="u-m-b-20">
      <el-steps :active="step" align-center>
        <el-step title="信息填写" />
        <el-step title="业务配置" />
        <el-step title="完成" />
      </el-steps>
    </el-card>
    <step-one v-show="step === 0" v-model="formData" :is-edit="!!id" @next="next" />
    <step-two
      v-if="lastStep > 0"
      v-show="step === 1"
      v-model="formData"
      :is-edit="!!id"
      :load="true"
      @next="submit"
      @prev="prev"
    />
    <el-card v-show="step === 2">
      <div class="u-text-center u-m-t-40">
        <i style="font-size: 80px" class="el-icon-circle-check text-success" />
      </div>
      <div class="u-text-center u-font-32 u-m-tb-40">
        {{ id ? '编辑' : '新建' }}服务商成功
      </div>
      <div class="wrap-fixed-footer btn--box">
        <el-button size="small" @click="finish">返回</el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import StepOne from '@/views/service/components/StepOne'
import StepTwo from '@/views/service/components/StepTwo'
import {
  deepClone
} from '@/utils'
import {
  update
} from '@/api/service'
import {
  _defaultForm
} from '@/views/service/defaultForm'

export default {
  name: 'ServiceAdd',
  components: {
    StepTwo,
    StepOne
  },
  data() {
    return {
      id: undefined,
      step: 0,
      lastStep: 0,
      formData: Object.assign({}, _defaultForm())
    }
  },
  created() {
    this.id = this.$route.query.id
  },
  methods: {
    next() {
      this.step++
      if (this.lastStep < this.step) {
        this.lastStep = this.step
      }
    },
    prev() {
      this.step--
    },
    finish() {
      this.step = 0
      this.$router.go(-1)
    },
    submit(e) {
      const form = deepClone(this.formData)
      const loading = this.$loading()
      update(Object.assign(form, e || {})).then(res => {
        this.$message.success(res.message)
        loading.close()
        this.next()
      }).catch(res => {
        loading.close()
      })
    }
  }
}
</script>

<style scoped>

</style>
