<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="平台服务商">
          <el-select v-model="formData.platformServiceProvider" style="width: 100%" placeholder="平台服务商" clearable
            filterable>
            <el-option v-for="i in serviceList" :key="i.id" :label="i.taxName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker v-model="formData.paymentDateOne" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"
            style="width: 160px;" />
        </el-form-item>
        <el-form-item>至</el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.paymentDateTwo" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"
            style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
          <el-button type="primary" :loading="btnLoading" @click="exportExcel">导出</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">合并开票</el-button>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table ref="multipleTable" :row-key="rowKey" v-loading="loading" :data="list" tooltip-effect="dark"
        style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column :selectable="canCheck" type="selection" reserve-selection width="55" />
        <el-table-column prop="companySName" label="商户" width="150" show-overflow-tooltip align="center" />
        <el-table-column prop="platformServiceProvider" label="平台服务商" align="center" width="150" show-overflow-tooltip />
        <!-- <el-table-column prop="workerMoney" label="支付总金额(元)" width="100" align="center" /> -->
        <el-table-column label="支付总金额(元)" align="center">
          <template slot-scope="scope">
            <div>{{ $u.formatMoney(scope.row.realMoney) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付编号" align="center" width="170">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.list" :key="k">{{ i.id }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付清单" align="center">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.list" :key="k">
              <file-icon v-if="i.paymentInventory" :url="i.paymentInventory" />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="总包支付回单" align="center">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.list" :key="k">
              <file-icon v-if="i.turnkeyProjectPayment" :url="i.turnkeyProjectPayment" />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="applicationDesc" label="开票申请备注说明" align="center" show-overflow-tooltip />
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.isInvoice, statusContent, true)" />
          </template>
        </el-table-column>
        <el-table-column prop="applicationDate" label="支付时间" align="center" width="180">
          <template slot-scope="scope">
            <div v-for="(item, index) in scope.row.list" :key="index">{{ item.paymentDate }}</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button v-if="parseInt(scope.row.isInvoice) === 0" type="text" size="small"
              @click="toPayInfo(scope.row)">查看</el-button>
            <el-button v-if="parseInt(scope.row.isInvoice) === 0" type="text" size="small"
              @click="handleEditor(scope.row, 'add')">申请开票</el-button>
            <el-button v-if="parseInt(scope.row.isInvoice) === 1" type="text" size="small"
              @click="handleEditor(scope.row, 'editor')">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { getWaitApplyList } from '@/api/totalTax'
import { getServiceList } from '@/api/common'

export default {
  data() {
    return {
      multipleSelection: [],
      statusContent: [
        { value: 0, name: '未申请', type: 'danger' },
        { value: 1, name: '已申请', type: 'success' }
      ],
      loading: false,
      btnLoading: false,
      serviceList: [],
      formData: {
        platformServiceProvider: '',
        paymentDateOne: '',
        paymentDateTwo: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 20
      }
    }
  },
  created() {
    this.fetchData()
    this.fetchService()
  },
  methods: {
    handleEditor(item, type) {
      let id
      let payId
      if (type === 'add') {
        payId = item.list[0].id
        id = item.list[0].id
      } else if (type === 'editor') {
        payId = item.list[0].id
        id = item.invoiceApplicationId
      }

      this.$router.push({
        path: '/tax/total/apply-editor',
        query: {
          id,
          type,
          payId
        }
      })
    },
    toPayInfo(i) {
      const id = i.list[0].id
      this.$router.push({
        path: '/pay/totalAndSub/view',
        query: {
          id,
          type: 'editor'
        }
      })
    },
    canCheck(row) {
      return parseInt(row.isInvoice) === 0
    },
    // 判断数组是否相同
    isAllEqual(array) {
      if (array.length > 0) {
        return !array.some((item, index) => {
          return (
            item.platformServiceProvider !== array[0].platformServiceProvider
          )
        })
      } else {
        return true
      }
    },
    // 多选按钮触发
    handleSelectionChange(val) {
      // const result = this.isAllEqual(val)
      // if (result) {
      this.multipleSelection = val
      // } else {
      //   this.$errorModal('合并开票必须是同一个平台服务商！！')
      // }
    },
    fetchService() {
      getServiceList({
        packageStatus: 0
      }).then(res => {
        this.serviceList = res.data || []
      })
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getWaitApplyList(Object.assign({}, this.formData, this.paramsData))
        .then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.loading = false
        })
        .catch(res => {
          this.loading = false
        })
    },
    handleAdd() {
      if (this.multipleSelection.length <= 1) {
        this.$errorModal('请选择多个代开清单!!')
        return
      }
      const isCompanySName = this.multipleSelection.every(item => item.companySName == this.multipleSelection[0].companySName)
      const isService = this.multipleSelection.every(item => item.platformServiceProvider == this.multipleSelection[0].platformServiceProvider)
      if (!isService || !isCompanySName) {
        return this.$errorModal("合并开票必须是同一商户和同一服务商！！");
      }
      this.$confirm(
        '合并开票为多个待开票清单合并一个开票清单,您确定合并吗?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        const arr = []
        this.multipleSelection.forEach(subItem => {
          subItem.list.forEach(item => {
            arr.push(item.id)
          })
        })
        const id = arr.join(',')
        this.$router.push({
          path: '/tax/total/apply-editor',
          query: {
            id,
            type: 'add',
            payId: id
          }
        })
      })
    },
    rowKey(row) {
      return row.list[0].id
    },
    exportExcel() {
      if (this.multipleSelection.length) {
        this.btnLoading = true
        const tableData = [
          ['商户', '平台服务商', '支付时间', '支付编号', '支付总金额(元)']
        ]
        let rowData = []
        this.multipleSelection.forEach((item, index) => {
          rowData = [item.companySName, item.platformServiceProvider, item.list[0].paymentDate, item.list[0].id, item.realMoney]
          tableData.push(rowData)
        })
        const ws = XLSX.utils.aoa_to_sheet(tableData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, '待申请开票')
        XLSX.writeFile(wb, '待申请开票列表.xlsx')
        this.$refs.multipleTable.clearSelection()
        setTimeout(() => {
          this.btnLoading = false
        }, 1000);
      } else {
        this.$message.error('请先选中内容，后再点击导出')
      }
    }
  }
}
</script>

<style scoped></style>
