var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "wrap-header u-flex u-col-center" },
        [
          _c("avatar", { attrs: { src: _vm.avatar, size: 72 } }),
          _c("div", { staticClass: "u-p-l-25" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                _vm._s(_vm.dayType) +
                  "，" +
                  _vm._s(_vm.myName) +
                  "，祝你开心每一天！"
              ),
            ]),
            _c("div", { staticClass: "sub" }, [
              _vm._v(_vm._s(_vm.name) + " | " + _vm._s(_vm.userinfo.roleName)),
            ]),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "u-p-15" }, [_c("count")], 1),
      _c(
        "el-row",
        { staticClass: "u-p-15", attrs: { gutter: 20 } },
        [_c("el-col", { attrs: { md: 24 } }, [_c("chart")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }