<template>
  <!-- 众包-未开票页面 -->
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="平台服务商">
          <el-select v-model="formData.platformServiceProvider" style="width: 100%" placeholder="平台服务商" clearable filterable>
            <el-option v-for="i in serviceList" :key="i.id" :label="i.taxName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker v-model="formData.applicationDateOne" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>至</el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.applicationDateTwo" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table ref="multipleTable" v-loading="loading" :data="list" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="id" label="发票编号" width="180" show-overflow-tooltip align="center" />
        <el-table-column prop="companySName" label="商户" width="180" show-overflow-tooltip align="center" />
        <el-table-column prop="platformServiceProvider" label="平台服务商" align="center" width="180" show-overflow-tooltip />
        <!-- <el-table-column prop="workerMoney" label="支付总金额(元)" width="100" align="center" show-overflow-tooltip /> -->
        <el-table-column label="支付总金额(元)" align="center" width="180">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.payVOList" :key="k">{{ $u.formatMoney(i.realMoney) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付编号" align="center" width="180">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.payVOList" :key="k">{{ i.pid }}</div>
          </template>
        </el-table-column>

        <el-table-column label="支付清单" align="center">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.payVOList" :key="k">
              <file-icon v-if="i.paymentTnventory" :url="i.paymentTnventory" />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="总包支付回单" width="120" prop="manyPayment" align="center">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.payVOList" :key="k">
              <file-icon v-if="i.subpackagePayment" :url="i.subpackagePayment" />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="realMoney" label="开票总金额(元)" width="100" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $u.formatMoney(scope.row.realMoney) }}
          </template>
        </el-table-column>
        <el-table-column label="总包发票" align="center">
          <template slot-scope="scope">
            <file-icon v-if="scope.row.invoiceUrl" :url="scope.row.invoiceUrl" />
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="总包税票" align="center">
          <template slot-scope="scope">
            <file-icon v-if="scope.row.taxReceiptUrl" :url="scope.row.taxReceiptUrl" />
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.isSubpackage, $enum.invoiceStatus(), true)" />
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="申请时间" align="center" width="180" />
        <el-table-column label="操作" width="120" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleShow(scope.row,'editor')">编辑</el-button>
            <el-button type="text" size="small" @click="handleShow(scope.row,'examine')">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
    </div>
  </div>
</template>

<script>
import { getDoneList } from '@/api/subPackage'
import { getServiceList } from '@/api/common'

export default {
  name: 'SubWait',
  data() {
    return {
      multipleSelection: [],
      statusContent: [
        { value: 0, name: '未申请', type: 'danger' },
        { value: 1, name: '已申请', type: 'success' }
      ],
      loading: false,
      serviceList: [],
      formData: {
        platformServiceProvider: '',
        applicationDateOne: '',
        applicationDateTwo: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
    this.fetchService()
  },
  methods: {
    handleShow(item, type) {
      this.$router.push({
        path: '/tax/subPackage/done-editor',
        query: {
          id: item.id,
          type
        }
      })
    },
    canCheck(row) {
      return parseInt(row.isInvoice) === 0
    },
    // 判断数组是否相同
    isAllEqual(array) {
      if (array.length > 0) {
        return !array.some((item, index) => {
          return (
            item.platformServiceProvider !== array[0].platformServiceProvider
          )
        })
      } else {
        return true
      }
    },
    fetchService() {
      getServiceList({
        packageStatus: 0
      }).then(res => {
        this.serviceList = res.data || []
      })
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getDoneList(Object.assign({}, this.formData, this.paramsData))
        .then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.loading = false
        })
        .catch(res => {
          this.loading = false
        })
    },
    handleAdd() {
      if (this.multipleSelection.length <= 1) {
        this.$errorModal('请选择多个代开清单!!')
        return
      }

      this.$confirm(
        '合并开票为多个待开票清单合并一个开票清单,您确定合并吗?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        const arr = []
        this.multipleSelection.forEach(subItem => {
          subItem.list.forEach(item => {
            arr.push(item.id)
          })
        })
        const id = arr.join(',')
        this.$router.push({
          path: '/tax/total/wait-editor',
          query: {
            id,
            type: 'add'
          }
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
