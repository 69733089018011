import request from '@/utils/request';

/**
 * 获取所有商户列表
 * @returns {AxiosPromise}
 */
export function getAllList() {
  return request({
    url: '/platform/merchant/getIdAndName',
    method: 'get',
  });
}

/**
 * 获取服务商合作信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getServicePackage(params) {
  let url;
  const { companyId } = params;
  if (companyId) {
    url = '/platform/merchant/queryCompanyPackage';
  } else {
    url = '/platform/merchant/queryTaxPackage';
  }
  return request({
    url: url,
    method: 'get',
    params,
  });
}

/**
 * 获取已签约商户
 * @param params
 * @returns {AxiosPromise}
 */
export function getPassList(params) {
  return request({
    url: '/platform/merchant/getMerchantList',
    method: 'post',
    params,
  });
}
/**
 * 获取待签约商户
 * @param params
 * @returns {AxiosPromise}
 */
export function getWaitList(params) {
  return request({
    url: '/platform/merchant/getAuditMerchantList',
    method: 'post',
    params,
  });
}

/**
 * 删除商户
 * @param params
 * @returns {AxiosPromise}
 */
export function del(params) {
  return request({
    url: '/platform/merchant/removeMerchant',
    method: 'post',
    params,
  });
}
/**
 * @param data
 * @returns {AxiosPromise}
 */
export function add(data) {
  return request({
    url: '/platform/merchant/addMerchant',
    method: 'post',
    data,
  });
}

/**
 * @param data
 * @returns {AxiosPromise}
 */
export function edit(data) {
  return request({
    url: '/platform/merchant/updateCompanyInfo',
    method: 'post',
    data,
  });
}

/**
 * @param params
 * @returns {AxiosPromise}
 */
export function pass(params) {
  return request({
    url: '/platform/merchant/auditMerchant',
    method: 'post',
    params,
  });
}
/**
 * @param params
 * @returns {AxiosPromise}
 */
export function getDetail(params) {
  return request({
    url: '/platform/merchant/queryCompanyInfoById',
    method: 'get',
    params,
  });
}
/**
 * 统计
 * @returns {AxiosPromise}
 */
export function count(params) {
  return request({
    url: '/platform/merchant/merchantInfo',
    method: 'post',
    params,
  });
}

/**
 * 快递地址信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getAddressList(params) {
  return request({
    url: '/platform/merchant/getAddressAll',
    method: 'get',
    params,
  });
}

/**
 * 更新快递地址
 * @param data
 * @param params
 * @returns {AxiosPromise}
 */
export function updateAddress(data, params) {
  return request({
    url: '/platform/merchant/addOrUpdataAddress',
    method: 'post',
    data,
    params,
  });
}

/**
 * 删除地址
 * @param params
 * @returns {AxiosPromise}
 */
export function delAddress(params) {
  return request({
    url: '/platform/merchant/removeAddressById',
    method: 'post',
    params,
  });
}

/**
 * 更新地址状态
 * @param params
 * @returns {AxiosPromise}
 */
export function updateAddressStatus(params) {
  return request({
    url: '/platform/merchant/updataAddressStatus',
    method: 'post',
    params,
  });
}
/**
 * 联系人信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getLinkList(params) {
  return request({
    url: '/platform/merchant/getLinkmanAll',
    method: 'get',
    params,
  });
}

/**
 * 更新联系人
 * @param data
 * @param params
 * @returns {AxiosPromise}
 */
export function updateLink(data, params) {
  return request({
    url: '/platform/merchant/addOrUpdataLinkman',
    method: 'post',
    data,
    params,
  });
}

/**
 * 删除联系人
 * @param params
 * @returns {AxiosPromise}
 */
export function delLink(params) {
  return request({
    url: '/platform/merchant/removeLinkmenById',
    method: 'post',
    params,
  });
}

/**
 * 更新联系人状态
 * @param params
 * @returns {AxiosPromise}
 */
export function updateLinkStatus(params) {
  return request({
    url: '/platform/merchant/updataLinkmanStatus',
    method: 'post',
    params,
  });
}

/**
 * 获取服务商列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getServiceList(params) {
  return request({
    url: '/platform/tax/queryTaxList',
    method: 'post',
    params,
  });
}

/**
 * 查询线下支付关联的服务商列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getOfflineList(params) {
  return request({
    url: '/platform/merchant/queryOfflineTaxList',
    method: 'get',
    params,
  });
}

/**
 * 查询服务商线下来款银行账号信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getOfflineDetail(params) {
  return request({
    url: '/platform/merchant/queryTaxInBankInfo',
    method: 'get',
    params,
  });
}

/**
 * 查询线下支付关联的服务商列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getUnionPayList(params) {
  return request({
    url: '/platform/merchant/queryUninopayTaxList',
    method: 'get',
    params,
  });
}

/**
 * 查询服务商线下来款银行账号信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getUnionPayDetail(params) {
  return request({
    url: '/platform/merchant/queryCompanyUnionpayDetail',
    method: 'get',
    params,
  });
}
/**
 * 查看与服务商的合作信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getServiceCoop(params) {
  return request({
    url: '/platform/merchant/queryCompanyTaxInfo',
    method: 'get',
    params,
  });
}

/**
 * 获取合作服务商
 */
export function getCooperationService(params) {
  return request({
    url: '/platform/merchant/queryTaxTransactionFlow',
    method: 'post',
    params,
  });
}

/**
 * 获取合作服务商流水统计
 * @param params
 * @returns {AxiosPromise}
 */
export function getCoopServiceCount(params) {
  return request({
    url: '/platform/merchant/taxMerchantInfoPaas',
    method: 'post',
    params,
  });
}
/**
 * 获取合作服务商流水列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getCoopServicePayList(params) {
  return request({
    url: '/platform/merchant/transactionRecord',
    method: 'post',
    params,
  });
}

/**
 * 获取交易记录
 * @param params
 * @returns {AxiosPromise}
 */
export function getPayHistoryList(params) {
  return request({
    url: '/platform/merchant/queryPaymentHistoryList',
    method: 'get',
    params,
  });
}

/**
 * 查询支付宝授权列表
 */
export function getAlipayList(params) {
  return request({
    url: '/platform/merchant/queryAlipaySign',
    method: 'get',
    params,
  });
}
/**
 * 查询支付宝授权页面连接
 * @param params
 * @param isNew {boolean} 无数据时为true
 */
export function getAlipayAgreement(params, isNew) {
  return request({
    url: isNew
      ? '/platform/merchant/createAlipaySign'
      : '/platform/merchant/getAlipayUserAgreementPageSign',
    method: isNew ? 'post' : 'get',
    params,
  });
}

/**
 * 支付宝解约
 * @param params
 * @returns {AxiosPromise}
 */
export function alipayUnSign(params) {
  return request({
    url: '/platform/merchant/alipayUserAgreementUnsign',
    method: 'get',
    params,
  });
}
/**
 * 查询支付宝余额
 */
export function getAlipayBalance(params) {
  return request({
    url: '/platform/merchant/queryAccountbookBalance',
    method: 'get',
    params,
  });
}
/**
 * 获取商户管理员电话
 * @param params
 * @returns {AxiosPromise}
 */
export function getShopPhone(params) {
  return request({
    url: '/platform/merchant/queryMainTaxloginMobile',
    method: 'get',
    params,
  });
}
/**
 * 银联提现
 * @param data
 * @returns {AxiosPromise}
 */
export function withdrawByUnionPay(data) {
  return request({
    url: '/platform/merchant/uninopayWithdrawal',
    method: 'post',
    data,
  });
}
/**
 * 支付宝提现
 * @param data
 * @returns {AxiosPromise}
 */
export function alipayTransferBackToTax(data) {
  return request({
    url: '/platform/merchant/alipayTransferBackToTax',
    method: 'post',
    data,
  });
}
/**
 * 支付宝提现
 * @param data
 * @returns {AxiosPromise}
 */
export function withdrawByAliPay(data) {
  return request({
    url: '/platform/merchant/alipayWithdrawal',
    method: 'post',
    data,
  });
}
/**
 * 查询支付宝银联联动授权列表
 */
export function getAlipayUnionPayList(params) {
  return request({
    url: '/platform/merchant/queryAlipayUnionpaySign',
    method: 'get',
    params,
  });
}
/**
 * 查询支付宝银联联动授权页面连接
 * @param data
 * @param params
 */
export function getAlipayUnionPayAgreement(data, params) {
  return request({
    url: '/platform/merchant/toAlipayUnionpaySign',
    method: 'post',
    data,
    params,
  });
}
/**
 * 创建银联支付宝标识
 * @param params
 */
export function creatAUSign(params) {
  return request({
    url: '/platform/merchant/creatAUSign',
    method: 'post',
    params,
  });
}

/**
 * 支付宝解约
 * @param params
 * @returns {AxiosPromise}
 */
// export function alipayUnSign(params) {
//   return request({
//     url: '/platform/merchant/alipayUserAgreementUnsign',
//     method: 'get',
//     params
//   })
// }
/**
 * 查询支付宝银联联动余额
 */
export function getAlipayUnionPayBalance(params) {
  return request({
    url: '/platform/merchant/queryAlipayUnionpayBalance',
    method: 'post',
    params,
  });
}
/**
 * 支付宝银联联动提现
 * @param data
 * @returns {AxiosPromise}
 */
export function withdrawByAlipayUnionPay(data) {
  return request({
    url: '/platform/merchant/alipayUnionpayWithdrawal',
    method: 'post',
    data,
  });
}

/**
 * 查询招行支付关联的服务商
 */
export function queryZspayTaxList(params) {
  return request({
    url: '/platform/merchant/queryZspayTaxList',
    method: 'get',
    params,
  });
}
/**
 * 查询商户招行余额
 */
export function queryZspayCompanyBalance(params) {
  return request({
    url: '/platform/merchant/queryZspayCompanyBalance',
    method: 'get',
    params,
  });
}
/**
 * 招商---商户招行提现
 * @param data
 * @returns {AxiosPromise}
 */
export function companyZsWithdrawal(data) {
  return request({
    url: '/platform/merchant/companyZsWithdrawal',
    method: 'post',
    data,
  });
}

/**
 * 查询支付宝关联的服务商
 * @param params
 * @returns {AxiosPromise}
 */
export function queryAlipayTaxList(params) {
  return request({
    url: '/platform/merchant/queryAlipayTaxList',
    method: 'get',
    params,
  });
}
