var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "base-echarts" }, [
    _c("div", {
      ref: "echartsRef",
      style: { width: _vm.width, height: _vm.height },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }