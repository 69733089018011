var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "u-m-20 wrap-fixed-content",
      staticStyle: { "min-width": "1200px" },
    },
    [
      _c("step-one", {
        ref: "stepOne",
        attrs: { "is-edit": true },
        on: { next: _vm.submit },
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
      _c(
        "el-card",
        { staticClass: "u-m-t-20", attrs: { header: "银联信息" } },
        [_c("union-pay-edit", { attrs: { "tax-id": _vm.id } })],
        1
      ),
      _c(
        "el-card",
        { staticClass: "u-m-t-20", attrs: { header: "支付宝配置" } },
        [_c("aliPayEdit", { attrs: { "tax-id": _vm.id } })],
        1
      ),
      _c("step-two", {
        ref: "stepTwo",
        staticClass: "u-m-t-20",
        attrs: { load: _vm.load, "is-edit": true },
        model: {
          value: _vm.formData,
          callback: function ($$v) {
            _vm.formData = $$v
          },
          expression: "formData",
        },
      }),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.next },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }