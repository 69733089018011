var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticClass: "u-form",
          attrs: { model: _vm.value, rules: _vm.rules, "label-width": "250px" },
        },
        [
          _c(
            "el-card",
            { attrs: { header: "基本信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "taxName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.taxName,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "taxName", $$v)
                      },
                      expression: "value.taxName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "社会信用代码", prop: "creditCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.creditCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "creditCode", $$v)
                      },
                      expression: "value.creditCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法定代表人", prop: "taxMan" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.taxMan,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "taxMan", $$v)
                      },
                      expression: "value.taxMan",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "是否关联任务", prop: "relevance" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.value.relevance,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "relevance", $$v)
                        },
                        expression: "value.relevance",
                      },
                    },
                    [
                      _c("el-option", { attrs: { label: "是", value: 1 } }),
                      _c("el-option", { attrs: { label: "否", value: 0 } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创客发放限制", prop: "workerSigningType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.value.workerSigningType,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "workerSigningType", $$v)
                        },
                        expression: "value.workerSigningType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "认证+签约", value: "AUTHENTANDSIGN" },
                      }),
                      _c("el-option", {
                        attrs: { label: "认证", value: "AUTHENT" },
                      }),
                      _c("el-option", {
                        attrs: { label: "无限制", value: "UNLIMITED" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创客发放限额", required: "" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.selectChange },
                      model: {
                        value: _vm.value.quota,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "quota", $$v)
                        },
                        expression: "value.quota",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "不设额度", value: "NOTHING" },
                      }),
                      _c("el-option", {
                        attrs: { label: "年月", value: "YEARANDMOUTH" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.value.quota == "MOUTH" || _vm.value.quota == "YEARANDMOUTH"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "月限额(万元)" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          min: "0",
                          type: "number",
                        },
                        model: {
                          value: _vm.value.mouthPay,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "mouthPay", $$v)
                          },
                          expression: "value.mouthPay",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.value.quota == "YEAR" || _vm.value.quota == "YEARANDMOUTH"
                ? _c(
                    "el-form-item",
                    { attrs: { label: "年限额(万元)" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入",
                          min: "0",
                          type: "number",
                        },
                        model: {
                          value: _vm.value.yearPay,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "yearPay", $$v)
                          },
                          expression: "value.yearPay",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "联系人信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "linkMan" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.linkMan,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "linkMan", $$v)
                      },
                      expression: "value.linkMan",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "职位", prop: "linkPosition" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.value.linkPosition,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "linkPosition", $$v)
                        },
                        expression: "value.linkPosition",
                      },
                    },
                    _vm._l(_vm.roleList, function (i, k) {
                      return _c("el-option", {
                        key: k,
                        attrs: { label: i, value: i },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系电话", prop: "linkMobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.linkMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "linkMobile", $$v)
                      },
                      expression: "value.linkMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人邮箱", prop: "linkEmail" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.linkEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "linkEmail", $$v)
                      },
                      expression: "value.linkEmail",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "开票信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "invoiceEnterpriseName" } },
                [
                  _c("div", { staticClass: "u-flex u-col-center" }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            readonly: _vm.isEdit,
                            placeholder: "请输入",
                          },
                          model: {
                            value: _vm.value.invoiceEnterpriseName,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "invoiceEnterpriseName", $$v)
                            },
                            expression: "value.invoiceEnterpriseName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "u-tips-color u-font-14 u-p-l-10" },
                      [_vm._v("不支持修改")]
                    ),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "纳税识别号", prop: "invoiceTaxNo" } },
                [
                  _c("div", { staticClass: "u-flex u-col-center" }, [
                    _c(
                      "div",
                      [
                        _c("el-input", {
                          attrs: {
                            readonly: _vm.isEdit,
                            placeholder: "请输入",
                          },
                          model: {
                            value: _vm.value.invoiceTaxNo,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "invoiceTaxNo", $$v)
                            },
                            expression: "value.invoiceTaxNo",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "u-tips-color u-font-14 u-p-l-10" },
                      [_vm._v("不支持修改")]
                    ),
                  ]),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址电话", prop: "invoiceAddressPhone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.invoiceAddressPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "invoiceAddressPhone", $$v)
                      },
                      expression: "value.invoiceAddressPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "开户行及账号",
                    prop: "invoiceBankNameAccount",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.invoiceBankNameAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "invoiceBankNameAccount", $$v)
                      },
                      expression: "value.invoiceBankNameAccount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            {
              staticClass: "u-m-t-20",
              attrs: { header: "对公账户信息(用于线下支付收款)" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "收款单位名称", prop: "titleOfAccount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.titleOfAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "titleOfAccount", $$v)
                      },
                      expression: "value.titleOfAccount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收款单位账号", prop: "bankCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.bankCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "bankCode", $$v)
                      },
                      expression: "value.bankCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开户行名称", prop: "bankName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.bankName,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "bankName", $$v)
                      },
                      expression: "value.bankName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "快递信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "收件人", prop: "receiptName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.receiptName,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "receiptName", $$v)
                      },
                      expression: "value.receiptName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件人手机号", prop: "receiptPhone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.receiptPhone,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "receiptPhone", $$v)
                      },
                      expression: "value.receiptPhone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "收件地址", prop: "receiptName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.receiptAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "receiptAddress", $$v)
                      },
                      expression: "value.receiptAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "附件信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "营业执照", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-center" },
                    [
                      _c("button-upload", {
                        attrs: { type: "image", text: "上传(png/jpg)" },
                        model: {
                          value: _vm.value.businessLicense,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "businessLicense", $$v)
                          },
                          expression: "value.businessLicense",
                        },
                      }),
                      !_vm.$u.test.isEmpty(_vm.value.businessLicense)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(
                                    _vm.value.businessLicense
                                  )
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c("el-form-item", { attrs: { label: "法人身份证" } }, [
                _c(
                  "div",
                  { staticClass: "u-flex u-col-center" },
                  [
                    _c("button-upload", {
                      attrs: { type: "image", text: "上传(png/jpg)" },
                      model: {
                        value: _vm.value.taxManIdcard,
                        callback: function ($$v) {
                          _vm.$set(_vm.value, "taxManIdcard", $$v)
                        },
                        expression: "value.taxManIdcard",
                      },
                    }),
                    !_vm.$u.test.isEmpty(_vm.value.taxManIdcard)
                      ? _c(
                          "el-button",
                          {
                            staticClass: "u-m-l-10",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.$preViewer(_vm.value.taxManIdcard)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "平台加盟合同", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-center" },
                    [
                      _c("button-upload", {
                        attrs: { type: "image/pdf", text: "上传(jpg/pdf)" },
                        model: {
                          value: _vm.value.joinContract,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "joinContract", $$v)
                          },
                          expression: "value.joinContract",
                        },
                      }),
                      !_vm.$u.test.isEmpty(_vm.value.joinContract)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(_vm.value.joinContract)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "管理员账号信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "realName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "realName", $$v)
                      },
                      expression: "value.realName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账户号", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "userName", $$v)
                      },
                      expression: "value.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "loginMobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.value.loginMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "loginMobile", $$v)
                      },
                      expression: "value.loginMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "密码",
                    prop: _vm.isEdit ? "none" : "passWord",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "no-autofill-pwd",
                    attrs: {
                      type: "text",
                      placeholder: "请输入",
                      autocomplete: "new-password",
                    },
                    model: {
                      value: _vm.value.passWord,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "passWord", $$v)
                      },
                      expression: "value.passWord",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "确认密码",
                    prop: _vm.isEdit ? "none" : "repassWord",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "no-autofill-pwd",
                    attrs: {
                      placeholder: "请输入",
                      type: "text",
                      autocomplete: "new-password",
                    },
                    model: {
                      value: _vm.value.repassWord,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "repassWord", $$v)
                      },
                      expression: "value.repassWord",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "支付密码",
                    prop: _vm.isEdit ? "none" : "payPwd",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "no-autofill-pwd",
                    attrs: {
                      type: "text",
                      autocomplete: "new-password",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.value.payPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "payPwd", $$v)
                      },
                      expression: "value.payPwd",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "确认支付密码",
                    prop: _vm.isEdit ? "none" : "repayPwd",
                  },
                },
                [
                  _c("el-input", {
                    staticClass: "no-autofill-pwd",
                    attrs: {
                      type: "text",
                      autocomplete: "new-password",
                      placeholder: "请输入",
                    },
                    model: {
                      value: _vm.value.repayPwd,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "repayPwd", $$v)
                      },
                      expression: "value.repayPwd",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isEdit
        ? _c(
            "div",
            { staticClass: "wrap-fixed-footer btn--box" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.next("formData")
                    },
                  },
                },
                [_vm._v("下一步")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }