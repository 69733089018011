import request from '@/utils/request'

/**
 * 账号密码登录
 * @param params
 * @returns {AxiosPromise}
 */
export function login(params) {
  return request({
    url: '/platform/managers/passLogin',
    method: 'post',
    params
  })
}
/**
 * 测试账号密码登录
 * @param params
 * @returns {AxiosPromise}
 */
export function testPassLogin(params) {
  return request({
    url: '/platform/managers/testPassLogin',
    method: 'post',
    params
  })
}

/**
 * 短信登录
 * @param data
 * @returns {AxiosPromise}
 */
export function loginByMobile(params) {
  return request({
    url: '/platform/managers/mobileLogin',
    method: 'post',
    params
  })
}

export function updateHead(params) {
  return request({
    url: '/platform/managers/updateHeadPortrait',
    method: 'post',
    params
  })
}

/**
 * 获取当前用户信息
 * @param token
 * @returns {AxiosPromise}
 */
export function getInfo(token) {
  return request({
    url: '/platform/managers/getCustomizedInfo',
    method: 'post',
    params: {
      token
    }
  })
}

export function logout() {
  return request({
    url: '/platform/managers/managerLogout',
    method: 'post'
  })
}

/**
 * 修改密码
 * @returns {AxiosPromise}
 */
export function updatePassword(params) {
  return request({
    url: '/platform/managers/updataPassWord',
    method: 'post',
    params
  })
}

/**
 * 获取权限
 * @returns {AxiosPromise}
 */
export function getAuth() {
  return request({
    url: '/Menu/queryMenuList',
    method: 'get'
  })
}
