var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: !_vm.isComponent ? "u-p-20 wrap-fixed-content" : "" },
    [
      +_vm.baseInfo.paymentOrderStatus === 5
        ? _c(
            "el-card",
            {
              staticClass: "u-m-b-10",
              staticStyle: { border: "1px solid #FF1A2E" },
            },
            [
              _c("div", { staticClass: "u-flex u-col-center u-p-lf-40" }, [
                _c("div", { staticClass: "u-text-center" }, [
                  _c("i", {
                    staticClass: "el-icon-error",
                    staticStyle: { "font-size": "72px", color: "#FF1A2E" },
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "u-m-t-10",
                      staticStyle: { "font-size": "24px" },
                    },
                    [_vm._v("被驳回")]
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "u-m-l-20 u-p-l-40 u-flex",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c("div", { staticClass: "u-flex-1" }, [
                      _c(
                        "div",
                        { staticClass: "u-tips-color u-font-14 u-m-l-20" },
                        [_vm._v("请核对并修改以下信息后，再重新提交。")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "u-p-20 u-m-t-10",
                          staticStyle: { "background-color": "#fafafa" },
                        },
                        [
                          _c("div", { staticClass: "u-font-16 u-m-b-10" }, [
                            _vm._v("您提交的内容有如下问题："),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "u-font-14",
                              staticStyle: { color: "#8e9193" },
                            },
                            [_vm._v(_vm._s(_vm.baseInfo.reasonsForRejection))]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "card--title" }, [_vm._v("众包信息")]),
          _c(
            "div",
            { staticClass: "card--content" },
            [
              _c(
                "el-row",
                { staticClass: "card--info", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付编号："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.paymentOrderId)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [_vm._v("商户：")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.companyName)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("平台服务商："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.platformServiceProvider)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("项目合同："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: { url: _vm.baseInfo.companyContract, btn: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付清单："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: {
                            url: _vm.baseInfo.paymentInventory,
                            btn: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("关联任务："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        !_vm.$u.test.isEmpty(_vm.baseInfo.taskId)
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.showTask(_vm.baseInfo.taskId)
                                  },
                                },
                              },
                              [_vm._v("查看")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("交付支付验收单："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: {
                            url: _vm.baseInfo.acceptanceCertificate,
                            btn: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [_vm._v("状态：")]),
                    _c("span", {
                      staticClass: "value",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.filterContent(
                            _vm.baseInfo.paymentOrderStatus,
                            _vm.$enum.payCrowdStatus()
                          )
                        ),
                      },
                    }),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("创建时间："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.creatDate)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付时间："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.paymentDate)),
                    ]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "u-m-t-20",
        },
        [
          _c("div", { staticClass: "card--title" }, [_vm._v("支付清单明细")]),
          _c(
            "div",
            { staticClass: "card--content" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.downloadExcel },
                },
                [_vm._v("导出Excel")]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.list,
                    "max-height": "600px",
                    "summary-method": _vm.getSummaries,
                    "show-summary": "",
                    "tooltip-effect": "dark",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "workerName",
                      label: "姓名",
                      width: "100",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "mobileCode",
                      width: "120",
                      label: "电话",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "idCardCode",
                      width: "180",
                      label: "身份证号",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "发放金额", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$u.test.isEmptyNotZero(
                                    scope.row.taskMoney
                                  )
                                    ? "￥" +
                                        _vm.$u.formatMoney(scope.row.taskMoney)
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "创客到手费", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$u.test.isEmptyNotZero(
                                    scope.row.realMoney
                                  )
                                    ? "￥" +
                                        _vm.$u.formatMoney(scope.row.realMoney)
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "服务费",
                      width: "150",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "创客：" +
                                  _vm._s(
                                    "￥" +
                                      (
                                        (+scope.row.receviceTax *
                                          +scope.row.taskMoney) /
                                          100 || 0
                                      ).toFixed(2)
                                  )
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "商户：" +
                                  _vm._s(
                                    "￥" +
                                      (
                                        (+scope.row.merchantTax *
                                          +scope.row.taskMoney) /
                                          100 || 0
                                      ).toFixed(2)
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "服务费率",
                      width: "120",
                      "show-overflow-tooltip": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "创客：" +
                                  _vm._s(
                                    !_vm.$u.test.isEmptyNotZero(
                                      scope.row.receviceTax
                                    )
                                      ? scope.row.receviceTax + "%"
                                      : "-"
                                  )
                              ),
                            ]),
                            _c("div", [
                              _vm._v(
                                "商户：" +
                                  _vm._s(
                                    !_vm.$u.test.isEmptyNotZero(
                                      scope.row.merchantTax
                                    )
                                      ? scope.row.merchantTax + "%"
                                      : "-"
                                  )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "商户总支出", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$u.test.isEmptyNotZero(
                                    scope.row.merchantPaymentMoney
                                  )
                                    ? "￥" +
                                        _vm.$u.formatMoney(
                                          scope.row.merchantPaymentMoney
                                        )
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "支付状态", width: "120", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$options.filters.filterContent(
                                    scope.row.paymentStatus,
                                    _vm.$enum.paymentStatus()
                                  )
                                ),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "tradeFailReason",
                      label: "支付失败原因",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("众包支付信息")]),
        _c("div", { staticClass: "card--content" }, [
          _c(
            "div",
            { staticClass: "card--info" },
            [
              _c("div", { staticClass: "card--info-item" }, [
                _c("span", { staticClass: "label" }, [_vm._v("支付方式：")]),
                _c("span", { staticClass: "value" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm._f("allUnionpayBankType")(_vm.baseInfo.paymentMode)
                      ) +
                      " "
                  ),
                ]),
              ]),
              +_vm.baseInfo.paymentMode === 0
                ? [
                    _c("table", { staticClass: "c-table is-edit" }, [
                      _c("tr", [
                        _c("td", [
                          _c("div", { staticClass: "card--info-item" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("支付方："),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.baseInfo.companyName)),
                            ]),
                          ]),
                          _c("div", { staticClass: "card--info-item" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("开户行："),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.baseInfo.bankName)),
                            ]),
                          ]),
                          _c("div", { staticClass: "card--info-item" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("银行账号："),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.baseInfo.bankCode)),
                            ]),
                          ]),
                        ]),
                        _c("td", [
                          _c("div", { staticClass: "card--info-item" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("收款方："),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.baseInfo.payee)),
                            ]),
                          ]),
                          _c("div", { staticClass: "card--info-item" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("开户行："),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.baseInfo.payeeBankName)),
                            ]),
                          ]),
                          _c("div", { staticClass: "card--info-item" }, [
                            _c("span", { staticClass: "label" }, [
                              _vm._v("银行账号："),
                            ]),
                            _c("span", { staticClass: "value" }, [
                              _vm._v(_vm._s(_vm.baseInfo.payeeBankCode)),
                            ]),
                          ]),
                        ]),
                      ]),
                      _c("tr", { class: _vm.isEdit ? "" : "no-border" }, [
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c(
                            "div",
                            {
                              staticClass: "blue-box",
                              class: _vm.isEdit ? "is-edit" : "is-view",
                            },
                            [
                              _vm._v("转账金额："),
                              _c("span", { staticClass: "money" }, [
                                _vm._v(
                                  "￥ " +
                                    _vm._s(
                                      _vm.$u.formatMoney(_vm.baseInfo.realMoney)
                                    )
                                ),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "card--info-item" }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("支付回单："),
                      ]),
                      _c(
                        "span",
                        { staticClass: "value" },
                        [
                          _c("file-icon", {
                            attrs: { url: _vm.baseInfo.manyPayment, btn: "" },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                : !_vm.isEdit
                ? [
                    _vm.unionPay.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "u-flex u-col-top u-row-left u-flex-wrap u-m-b-10",
                          },
                          _vm._l(_vm.unionPay, function (i, k) {
                            return _c("union-info", {
                              key: k + Date.now(),
                              attrs: { "show-balance": false, info: i },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "blue-box is-view" }, [
                      _vm._v("转账金额："),
                      _c("span", { staticClass: "money" }, [
                        _vm._v(
                          "￥ " +
                            _vm._s(_vm.$u.formatMoney(_vm.baseInfo.realMoney))
                        ),
                      ]),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ]),
      ]),
      _vm.taxInfo
        ? _c(
            "el-card",
            { staticClass: "u-m-t-20" },
            [
              _c("div", { staticClass: "card--title" }, [_vm._v("发票信息")]),
              _c("div", { staticClass: "card--content" }, [
                _c("div", { staticClass: "card--info" }, [
                  _c("div", { staticClass: "card--info-item" }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("众包发票："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: { url: _vm.taxInfo.invoice, btn: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              !_vm.$u.test.isEmpty(_vm.express.expressCode)
                ? [
                    _c("div", { staticClass: "card--title" }, [
                      _vm._v("物流信息"),
                    ]),
                    _c("div", { staticClass: "card--content" }, [
                      _c("div", { staticClass: "wl-box" }, [
                        _c("div", [
                          _vm._v(
                            "物流公司：" +
                              _vm._s(_vm.express.expressCompanyName)
                          ),
                        ]),
                        _c("div", [
                          _vm._v(
                            "物流单号：" + _vm._s(_vm.express.expressCode)
                          ),
                        ]),
                      ]),
                      !_vm.$u.test.isEmpty(_vm.express.expressLogisticsInfos)
                        ? _c(
                            "div",
                            { staticClass: "wl-content" },
                            _vm._l(
                              _vm.express.expressLogisticsInfos,
                              function (i, k) {
                                return _c(
                                  "div",
                                  { key: k, staticClass: "wl-content-item" },
                                  [
                                    _c("span", { staticClass: "u-m-r-20" }, [
                                      _vm._v(_vm._s(i.AcceptTime)),
                                    ]),
                                    _c("span", [
                                      _vm._v(_vm._s(i.acceptStation)),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _vm._e(),
                    ]),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      !_vm.isComponent
        ? _c(
            "div",
            { staticClass: "wrap-fixed-footer btn--box" },
            [
              _vm.isPay
                ? [
                    _c(
                      "el-button",
                      {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.submitLoading,
                            expression: "submitLoading",
                          },
                        ],
                        attrs: { size: "small", type: "primary" },
                        on: { click: _vm.doPay },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "u-flex u-row-between u-col-center" },
                          [
                            _c("i", {
                              staticClass: "el-icon-success text-success",
                            }),
                            _c("div", [_vm._v(" 点击确认收款")]),
                          ]
                        ),
                      ]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          loading: _vm.submitLoading,
                          size: "small",
                          type: "warning",
                        },
                        on: {
                          click: function ($event) {
                            _vm.checkDialog = true
                          },
                        },
                      },
                      [_vm._v("支付驳回")]
                    ),
                  ]
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("返回")]
              ),
            ],
            2
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看关联任务",
            visible: _vm.visibleDialog,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleDialog = $event
            },
          },
        },
        [_c("task-detail", { ref: "taskDetail" })],
        1
      ),
      _c(
        "el-dialog",
        {
          ref: "checkDialog",
          attrs: {
            title: "请填写驳回原因",
            visible: _vm.checkDialog,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.checkDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                attrs: { rows: 4, type: "textarea", placeholder: "请输入" },
                model: {
                  value: _vm.checkData.reasonsForRejection,
                  callback: function ($$v) {
                    _vm.$set(_vm.checkData, "reasonsForRejection", $$v)
                  },
                  expression: "checkData.reasonsForRejection",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.checkDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loading, type: "primary" },
                  on: { click: _vm.rejectPay },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }