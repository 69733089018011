var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap-fixed-content" },
    [
      _c(
        "div",
        {
          staticClass:
            "bg-white u-flex u-row-right u-col-center u-p-lf-30 u-p-b-20 u-font-14",
        },
        [
          _c(
            "div",
            { staticClass: "u-p-r-20" },
            [
              _vm._v("众包支付清单模板下载："),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.downloadLoading,
                      expression: "downloadLoading",
                    },
                  ],
                  attrs: {
                    type: "success",
                    size: "small",
                    icon: "el-icon-download",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.download(1)
                    },
                  },
                },
                [_vm._v("点击下载")]
              ),
            ],
            1
          ),
          _c(
            "div",
            [
              _vm._v("交付支付验收单模板下载："),
              _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.downloadLoading,
                      expression: "downloadLoading",
                    },
                  ],
                  attrs: {
                    type: "success",
                    size: "small",
                    icon: "el-icon-download",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.download(2)
                    },
                  },
                },
                [_vm._v("点击下载")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "u-m-25 u-p-30 bg-white" },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formData",
              staticStyle: { "max-width": "800px" },
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "250px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商户", prop: "merchantId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "商户",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.showPrice },
                      model: {
                        value: _vm.formData.merchantId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "merchantId", $$v)
                        },
                        expression: "formData.merchantId",
                      },
                    },
                    _vm._l(_vm.shopList, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.companyName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台服务商", prop: "taxId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "平台服务商",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.showPrice },
                      model: {
                        value: _vm.formData.taxId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "taxId", $$v)
                        },
                        expression: "formData.taxId",
                      },
                    },
                    _vm._l(_vm.serviceList, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.taxName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "项目合同", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-center" },
                    [
                      _c("button-upload", {
                        attrs: {
                          type: "image/pdf",
                          text: "上传项目合同(pdf/jpg)",
                        },
                        on: {
                          "upload-success": function ($event) {
                            return _vm.setField("companyContract", $event)
                          },
                        },
                      }),
                      !_vm.$u.test.isEmpty(_vm.formData.companyContract)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(
                                    _vm.formData.companyContract
                                  )
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "支付清单", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-center" },
                    [
                      _c("button-upload", {
                        attrs: {
                          "default-action": _vm.action,
                          "success-toast": false,
                          "error-toast": false,
                          "return-all": "",
                          name: "uploadInvoice",
                          type: "excel",
                          text: "上传支付清单(Excel)",
                        },
                        on: {
                          "upload-success": function ($event) {
                            return _vm.uploadExcel($event)
                          },
                          "upload-error": _vm.showErrorModal,
                        },
                      }),
                      !_vm.$u.test.isEmpty(_vm.formData.paymentInventory)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(
                                    _vm.formData.paymentInventory
                                  )
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联任务" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "u-dashed-border u-text-center u-point",
                      staticStyle: { "max-width": "800px" },
                      on: { click: _vm.showDialog },
                    },
                    [
                      _c("i", { staticClass: "el-icon-plus u-m-r-10" }),
                      _vm._v("添加关联任务"),
                    ]
                  ),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.taskList.length,
                          expression: "taskList.length",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.taskList,
                        "max-height": "200",
                        "tooltip-effect": "dark",
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          prop: "taskCode",
                          label: "任务编号",
                          width: "80",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          prop: "taskName",
                          label: "任务名称",
                          align: "center",
                          "show-overflow-tooltip": "",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: { label: "操作", align: "center", width: "80" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeTask(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "任务名称", prop: "taskName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.taskName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "taskName", $$v)
                      },
                      expression: "formData.taskName",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "交付支付验收单" } }, [
                _c(
                  "div",
                  { staticClass: "u-flex u-col-center" },
                  [
                    _c("button-upload", {
                      attrs: {
                        type: "image/pdf",
                        text: "上传交付支付验收单(pdf/jpg)",
                      },
                      on: {
                        "upload-success": function ($event) {
                          return _vm.setField("acceptanceCertificate", $event)
                        },
                      },
                    }),
                    !_vm.$u.test.isEmpty(_vm.formData.acceptanceCertificate)
                      ? _c(
                          "el-button",
                          {
                            staticClass: "u-m-l-10",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.$preViewer(
                                  _vm.formData.acceptanceCertificate
                                )
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("el-form-item", { attrs: { label: "支付回单" } }, [
                _c(
                  "div",
                  { staticClass: "u-flex u-col-center" },
                  [
                    _c("button-upload", {
                      attrs: {
                        type: "image/pdf",
                        text: "上传支付回单(pdf/jpg)",
                      },
                      on: {
                        "upload-success": function ($event) {
                          return _vm.setField("manyPayment", $event)
                        },
                      },
                    }),
                    !_vm.$u.test.isEmpty(_vm.formData.manyPayment)
                      ? _c(
                          "el-button",
                          {
                            staticClass: "u-m-l-10",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.$preViewer(_vm.formData.manyPayment)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "el-form-item",
                { attrs: { label: "服务费率", prop: "taxStatus" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.taxStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "taxStatus", $$v)
                        },
                        expression: "formData.taxStatus",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("商户承担"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.$u.test.isEmptyNotZero(_vm.priceData)
                ? [
                    typeof _vm.priceData === "object"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "服务费率梯度价" } },
                          _vm._l(_vm.priceData, function (i, k) {
                            return _c("div", { key: k }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$u.formatMoney(i.startMoney)) +
                                  "元 ~ " +
                                  _vm._s(_vm.$u.formatMoney(i.endMoney)) +
                                  "元 : " +
                                  _vm._s(i.serviceCharge) +
                                  "% "
                              ),
                            ])
                          }),
                          0
                        )
                      : _c(
                          "el-form-item",
                          { attrs: { label: "服务费率一口价" } },
                          [_vm._v(" " + _vm._s(_vm.priceData) + "% ")]
                        ),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.onSubmit("formData")
                },
              },
            },
            [_vm._v("下一步")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择工单",
            visible: _vm.dialogTableVisible,
            width: "1000px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("task-lookup", {
            ref: "taskLookup",
            attrs: {
              "merchant-id": _vm.formData.merchantId,
              "cooperate-mode": 1,
            },
            on: { "on-select": _vm.onSelect },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }