<template>
  <div>
    <el-form ref="withdrawForm" v-loading="withdrawLoading" :model="withdrawForm" :rules="withdrawRules"
      label-width="100px" style="max-width: 400px">
      <el-form-item label="开户行" prop="destBankCode">
        <el-select v-model="withdrawForm.destBankCode" filterable placeholder="请选择">
          <el-option v-for="(i, k) in bankOptions" :key="k" :label="i.dictValue" :value="i.dictKey" />
        </el-select>
      </el-form-item>
      <el-form-item label="账户名" prop="acctName">
        <el-input v-model="withdrawForm.acctName" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="银行卡号" prop="acctNo">
        <el-input v-model="withdrawForm.acctNo" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="提现金额" prop="money">
        <el-input v-model="withdrawForm.money" placeholder="请输入" />
      </el-form-item>
      <!--      <el-form-item label="手机号">-->
      <!--        <el-input-->
      <!--          v-model="tel"-->
      <!--          readonly-->
      <!--        />-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="验证码" prop="checkCode">-->
      <!--        <el-input v-model="withdrawForm.checkCode" placeholder="请输入">-->
      <!--          <el-button slot="append" type="default" :style="{background: (disabled ? 'inherit' : '#fff'), color: (disabled ? 'inherit' : '#000') }" :disabled="disabled" @click="getCode">{{ btnText }}</el-button>-->
      <!--        </el-input>-->
      <!--      </el-form-item>-->
      <!--      <el-form-item label="支付密码" prop="payPwd">-->
      <!--        <el-input v-model="withdrawForm.payPwd" placeholder="请输入" />-->
      <!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" @click="submitForm('withdrawForm')">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import { getCode, getBankList } from '@/api/common'
import { withdrawByUnionPay, alipayTransferBackToTax } from '@/api/shop'

const _defaultWithdrawForm = () => {
  return {
    acctName: '',
    acctNo: '',
    checkCode: '',
    companyUnionpayId: '',
    money: null,
    payPwd: '',
    destBankCode: '',
    taxUnionpayId: ''
  }
}
const btnText = '获取验证码'
export default {
  props: {
    merchantId: {
      type: [Number, String],
      required: true
    },
    isAliPay: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      bankOptions: [],
      countdown: null,
      disabled: false,
      withdrawLoading: false,
      btnText: btnText,
      withdrawForm: Object.assign({}, _defaultWithdrawForm()),
      withdrawRules: {
        destBankCode: [
          { required: true, trigger: 'blur', message: '请输入开户行' }
        ],
        acctName: [
          { required: true, trigger: 'blur', message: '请输入账户名' }
        ],
        acctNo: [
          { required: true, trigger: 'blur', message: '请输入银行卡号' }
        ],
        checkCode: [
          { required: true, trigger: 'blur', message: '请输入短信验证码' }
        ],
        payPwd: [
          { required: true, trigger: 'blur', message: '请输入支付密码' }
        ],
        money: [
          { required: true, trigger: 'blur', message: '请选择提现金额' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!this.$u.test.validateRange(value, '0~', false)) {
                callback(new Error('请输入大于0的数'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      tel: ''
    }
  },
  created() {
    this.fetchDict()
  },
  methods: {
    fetchDict() {
      getBankList({
        code: 'bank'
      }).then(res => {
        this.bankOptions = res.data || []
      })
    },
    setData(data = {}) {
      this.$refs['withdrawForm'].resetFields()
      this.withdrawForm.companyUnionpayId = data.companyUnionpayId
      this.withdrawForm.taxUnionpayId = data.taxUnionpayId
      this.disabled = true
      clearInterval(this.countdown)
      this.btnText = btnText
      // getShopPhone({
      //   merchantId: this.merchantId
      // }).then(res => {
      //   this.tel = res.obj || ''
      //   this.disabled = false
      // }).catch(res => {
      //   this.disabled = false
      // })
    },
    doLoop: function (seconds) {
      seconds = seconds || 60
      this.btnText = seconds + 's后获取'
      this.countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = btnText
          this.disabled = false
          clearInterval(this.countdown)
        }
      }, 1000)
    },
    getCode() {
      const mobile = this.tel || ''
      if (!this.$u.test.mobile(mobile)) {
        return this.$message.error('手机号不正确')
      }
      this.disabled = true
      getCode({
        mobileCode: this.tel,
        merchantId: this.merchantId
      }).then(res => {
        this.$message.success('发送短信成功')
        setTimeout(() => {
          this.doLoop(60)
        }, 500)
      }).catch(res => {
        this.disabled = false
      })
    },
    withdrawByUnionPay() {
      withdrawByUnionPay(Object.assign({}, this.withdrawForm, {
        merchantId: this.merchantId
      })).then(res => {
        this.$message.success(res.message)
        this.$emit('success')
        this.withdrawLoading = false
      }).catch(res => {
        this.withdrawLoading = false
      })
    },
    alipayTransferBackToTax() {
      alipayTransferBackToTax(this.withdrawForm).then(res => {
        this.$message.success(res.message)
        this.$emit('success')
        this.withdrawLoading = false
      }).catch(res => {
        this.withdrawLoading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.withdrawLoading = true
          if (this.isAliPay) {
            this.alipayTransferBackToTax()
          } else {
            this.withdrawByUnionPay()
          }
        }
      })
    }
  }
}
</script>

<style scoped></style>
