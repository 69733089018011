var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "u-flex u-col-center user-base-box" }, [
            _c(
              "div",
              { staticClass: "name u-flex u-col-center u-flex-1" },
              [
                _c("avatar", {
                  staticClass: "u-m-r-10",
                  attrs: { src: _vm.baseInfo.user_head, size: 50 },
                }),
                _c("div", [
                  _c("div", { staticClass: "u-font-18" }, [
                    _vm._v("平台业务专员：" + _vm._s(_vm.baseInfo.realName)),
                  ]),
                  _c("div", { staticClass: "u-font-14 u-m-t-5" }, [
                    _vm._v("业务员编号：" + _vm._s(_vm.baseInfo.id)),
                  ]),
                ]),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.countLoading,
              expression: "countLoading",
            },
          ],
          attrs: { gutter: 20 },
        },
        [
          _c(
            "el-col",
            { staticClass: "u-m-t-20", attrs: { md: { span: "w20" }, sm: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "u-font-14",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("最近30天支付流水")]
                ),
                _c("div", [
                  _c("div", { staticClass: "u-font-14 u-tips-color" }, [
                    _vm._v("总包+分包"),
                  ]),
                  _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                    _vm._v(
                      "￥ " +
                        _vm._s(
                          _vm.$u.formatMoney(_vm.count.payment30TotalMoney) || 0
                        )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "u-font-14 u-tips-color u-m-t-10" },
                    [_vm._v("众包")]
                  ),
                  _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                    _vm._v(
                      "￥ " +
                        _vm._s(
                          _vm.$u.formatMoney(_vm.count.payment30ManyMoney) || 0
                        )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "u-m-t-20", attrs: { md: { span: "w20" }, sm: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "u-font-14",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("总支付流水")]
                ),
                _c("div", [
                  _c("div", { staticClass: "u-font-14 u-tips-color" }, [
                    _vm._v("总包+分包"),
                  ]),
                  _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                    _vm._v(
                      "￥ " +
                        _vm._s(
                          _vm.$u.formatMoney(_vm.count.paymentTotalMoney) || 0
                        )
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "u-font-14 u-tips-color u-m-t-10" },
                    [_vm._v("众包")]
                  ),
                  _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                    _vm._v(
                      "￥ " +
                        _vm._s(
                          _vm.$u.formatMoney(_vm.count.paymentManyMoney) || 0
                        )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "u-m-t-20", attrs: { md: { span: "w20" }, sm: 8 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "u-font-14",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("总包+分包发票")]
                ),
                _c("div", [
                  _c("div", { staticClass: "u-font-14 u-tips-color" }, [
                    _vm._v("已开票数"),
                  ]),
                  _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                    _vm._v(_vm._s(_vm.count.invoiceTotalCount || 0)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "u-font-14 u-tips-color u-m-t-10" },
                    [_vm._v("已开票金额")]
                  ),
                  _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                    _vm._v(
                      "￥ " +
                        _vm._s(
                          _vm.$u.formatMoney(_vm.count.invoiceTotalMoney) || 0
                        )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "u-m-t-20", attrs: { md: { span: "w20" }, sm: 12 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "u-font-14",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("众包发票")]
                ),
                _c("div", [
                  _c("div", { staticClass: "u-font-14 u-tips-color" }, [
                    _vm._v("已开票数"),
                  ]),
                  _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                    _vm._v(_vm._s(_vm.count.invoiceManyCount || 0)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "u-font-14 u-tips-color u-m-t-10" },
                    [_vm._v("已开票金额")]
                  ),
                  _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                    _vm._v(
                      "￥ " +
                        _vm._s(
                          _vm.$u.formatMoney(_vm.count.invoiceManyMoney) || 0
                        )
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "u-m-t-20", attrs: { md: { span: "w20" }, sm: 12 } },
            [
              _c("el-card", [
                _c(
                  "div",
                  {
                    staticClass: "u-font-14",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [_vm._v("商户&创客")]
                ),
                _c("div", [
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-center u-row-between" },
                    [
                      _c("div", [
                        _c("div", { staticClass: "u-font-14 u-tips-color" }, [
                          _vm._v("总商户数"),
                        ]),
                        _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                          _vm._v(_vm._s(_vm.count.merchantTotal || 0)),
                        ]),
                      ]),
                      _c("div", [
                        _c("div", { staticClass: "u-font-14 u-tips-color" }, [
                          _vm._v("总创客数"),
                        ]),
                        _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                          _vm._v(_vm._s(_vm.count.workerTotal || 0)),
                        ]),
                      ]),
                    ]
                  ),
                  _c("div", [
                    _c(
                      "div",
                      { staticClass: "u-font-14 u-tips-color u-m-t-10" },
                      [_vm._v("总代理商数")]
                    ),
                    _c("div", { staticClass: "u-font-20 u-m-t-5" }, [
                      _vm._v(_vm._s(_vm.count.agentTotal || 0)),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "u-m-t-20", attrs: { header: "成交列表明细" } },
        [_c("pay-list", { attrs: { "sales-man-id": _vm.id } })],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }