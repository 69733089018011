<template>
  <div>
    <el-form :inline="true" :model="paramsData" size="small">
      <el-form-item label="交易类型">
        <el-select v-model="paramsData.orderType" placeholder="全部" clearable>
          <el-option v-for="item in orderTypeOptions" :key="item.value" :label="item.name" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="交易方式">
        <el-select v-model="paramsData.paymentMethod" placeholder="全部" clearable>
          <el-option v-for="item in paymentMethodOptions" :key="item.value" :label="item.name" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="交易结果">
        <el-select v-model="paramsData.tradeStatus" placeholder="全部" clearable>
          <el-option v-for="item in tradeStatusOptions" :key="item.value" :label="item.name" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="paramsData.beginDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
      </el-form-item>
      <el-form-item>
        至
      </el-form-item>
      <el-form-item>
        <el-date-picker v-model="paramsData.endDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="resetPage">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="multipleTable" v-loading="listLoading" :data="list" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="tradeNo" label="交易订单号" align="center" />
      <el-table-column prop="outerTradeNo" label="第三方订单号" align="center" />
      <el-table-column label="交易类型" align="center">
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.orderType, orderTypeOptions, false)" />
        </template>
      </el-table-column>
      <el-table-column label="交易方式" align="center">
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.paymentMethod, paymentMethodOptions, false)" />
        </template>
      </el-table-column>
      <el-table-column label="交易金额" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.amount) ? ('￥' + $u.formatMoney(scope.row.amount)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="交易结果" align="center">
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.tradeStatus, tradeStatusOptions, false)" />
        </template>
      </el-table-column>
      <el-table-column prop="tradeFailReason" label="交易失败原因" align="center" />
      <el-table-column prop="createDate" label="创建时间" width="180" align="center" />
    </el-table>
    <div class="pager-box">
      <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
    </div>
  </div>
</template>

<script>
import { getPayHistoryList } from '@/api/service'

export default {
  name: 'PayHistoryList',
  props: {
    taxId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      paymentMethodOptions: [
        { name: '线下支付', value: 'OFFLINE' },
        { name: '支付宝', value: 'ALIPAY' },
        { name: '支付宝银联联动支付', value: 'ALIPAYUNIONPAY' },
        // { name: '连连支付', value: 'LIANLIAN' },
        { name: '网商银行支付', value: 'WANGSHANG' },
        { name: '银联盛京银行支付', value: 'UNIONSJBK' },
        { name: '银联平安银行支付', value: 'UNIONPABK' },
        { name: '银联网商银行支付', value: 'UNIONWSBK' },
        { name: '银联招商银行支付', value: 'UNIONZSBK' },
        { name: '招商银行支付', value: 'ZSPAYBK' }
      ],
      orderTypeOptions: [
        { name: '清分', value: 'CLEAR' },
        { name: '充值', value: 'RECHARGE' },
        { name: '提现', value: 'WITHDRAW' },
        { name: '总包服务费支付', value: 'TOTALORDER' },
        { name: '分包发薪转账', value: 'SUBPACKAGE' },
        { name: '众包服务费支付', value: 'MANYORDER' },
        { name: '分包支付订单支付', value: 'INVENTORY' }
      ],
      tradeStatusOptions: [
        { name: '未交易', value: 'UNTRADE', type: 'default' },
        { name: '交易中', value: 'TRADING', type: 'primary' },
        { name: '交易成功', value: 'SUCCESS', type: 'success' },
        { name: '交易失败', value: 'FAIL', type: 'danger' }
      ],
      listLoading: false,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10,
        beginDate: '',
        endDate: '',
        orderType: '',
        paymentMethod: '',
        tradeStatus: ''
      }
    }
  },
  created() {
    this.resetPage()
  },
  methods: {
    resetPage() {
      this.paramsData.pageNo = 1
      this.fetchData()
    },
    fetchData() {
      this.listLoading = true
      const form = Object.assign({}, {
        taxId: this.taxId
      }, this.paramsData)
      Object.keys(form).forEach(key => {
        if (this.$u.test.isEmptyNotZero(form[key])) {
          form[key] = undefined
        }
      })
      getPayHistoryList(form).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.listLoading = false
      }).catch(res => {
        this.listLoading = false
      })
    }
  }
}
</script>

<style scoped>
</style>
