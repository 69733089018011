var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "user-info-box u-m-t-10",
        },
        [
          _c("div", { staticClass: "title" }, [_vm._v("个体户基础信息")]),
          _c("div", { staticClass: "info-item u-flex u-col-center" }, [
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("个体户编号：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm.item.id)),
              ]),
            ]),
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("服务商：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm.item.serviceProviderName)),
              ]),
            ]),
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("个体户名称：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(
                  _vm._s(_vm.$ibname(_vm.item.ibname, _vm.item.candidatedNames))
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "info-item u-flex u-col-center" }, [
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("纳税人识别号：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm.item.ibtaxNo)),
              ]),
            ]),
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("行业类型：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm.item.mainIndustry)),
              ]),
            ]),
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("经营范围：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm.item.bizScope)),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "info-item u-flex u-col-center" }, [
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("核定税种：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm._f("filterTax")(_vm.item.bizType))),
              ]),
            ]),
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("经营者：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm.item.bizName)),
              ]),
            ]),
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("注册资金：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(
                  _vm._s(_vm.$u.formatMoney(_vm.item.registeredMoney)) + " 万"
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "info-item u-flex u-col-center" }, [
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("营业执照：")]),
              _c(
                "span",
                { staticClass: "u-tips-color" },
                [
                  !_vm.$u.test.isEmpty(_vm.item.businessLicenceUrl)
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.$preViewer(_vm.item.businessLicenceUrl)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("状态：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm._f("filterState")(_vm.item.ibstate))),
              ]),
            ]),
            _c("div", { staticClass: "u-flex-1" }, [
              _c("span", {}, [_vm._v("注册时间：")]),
              _c("span", { staticClass: "u-tips-color" }, [
                _vm._v(_vm._s(_vm.item.createTime)),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }