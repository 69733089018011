<template>
  <div>
    <el-button type="primary" :loading="loading" @click="downloadExcel">导出Excel</el-button>
    <el-button type="primary" :loading="loading" @click="OneclickExcel">一键导出</el-button>
    <!-- show-summary :summary-method="getSummaries" -->
    <el-table ref="multipleTable" v-loading="loading" :data="list" max-height="600px" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" />
      <el-table-column prop="workerName" label="姓名" width="100" align="center" />
      <el-table-column prop="mobileCode" width="120" label="电话" align="center" />
      <el-table-column prop="idCardCode" width="180" label="身份证号" align="center" />
      <el-table-column label="银行卡号" align="center" width="150">
        <template slot-scope="scope">
          {{ scope.row.bankCode }}
          <el-button v-if="isEdit || (+scope.row.paymentStatus !== 1)" type="text" size="small" @click="handleChangeBank(scope.row, scope.$index)">修改</el-button>
        </template>
      </el-table-column>
      <el-table-column label="发放金额" align="center" width="120">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.taskMoney) ? ('￥' + $u.formatMoney(scope.row.taskMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="创客到手费" align="center" width="120">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.realMoney) ? ('￥' + $u.formatMoney(scope.row.realMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="服务费" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>创客：{{ ('￥' + (((+scope.row.receviceTax) * (+scope.row.taskMoney) / 100) || 0).toFixed(2)) }}</div>
          <div>商户：{{ ('￥' + (((+scope.row.merchantTax) * (+scope.row.taskMoney) / 100) || 0).toFixed(2)) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="服务费率" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>创客：{{ !$u.test.isEmptyNotZero(scope.row.receviceTax) ? (scope.row.receviceTax + '%') : '-' }}</div>
          <div>商户：{{ !$u.test.isEmptyNotZero(scope.row.merchantTax) ? (scope.row.merchantTax + '%') : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="阶梯服务费" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.excessServiceCharge) ? ('￥' + $u.formatMoney(scope.row.excessServiceCharge)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="商户总支出" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.merchantPaymentMoney) ? ('￥' + $u.formatMoney(scope.row.merchantPaymentMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="支付状态" width="120" align="center">
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.paymentStatus, $enum.paymentStatus())" />
        </template>
      </el-table-column>
      <el-table-column label="回单" align="center">
        <template slot-scope="scope">
          <file-icon v-if="scope.row.receipt" :url="scope.row.receipt" />
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="tradeFailReason" label="支付失败原因" align="center" width="150" show-overflow-tooltip />
    </el-table>
    <el-dialog title="修改银行卡" :visible.sync="dialogShow" width="500px">
      <el-form v-loading="loading" :model="form" label-width="80px">
        <el-form-item label="银行卡号" required>
          <el-input v-model="form.bankCode" type="number" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doChangeBank">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { updateBankCode, getInventory } from '@/api/pay'
export default {
  props: {
    list: {
      type: Array,
      default() {
        return []
      }
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    baseInfo: {
      type: Object,
      default() {
        return {}
      }
    }
    // id: {
    //   type: [Number, String],
    //   default: ''
    // }
  },
  data() {
    return {
      tableList: [],
      // selectAry: [],
      paymentListExcelVO: [],
      loading: false,
      form: {
        bankCode: '',
        index: undefined,
        paymentInventoryId: undefined
      },
      dialogShow: false
    }
  },
  watch: {
    list: {
      immediate: true,
      handler(newVal) {
        this.tableList = newVal
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    // console.log("this.id",this.id);
  },
  methods: {
    // getSummaries(param) {
    //   const { columns, data } = param
    //   const sums = []
    //   columns.forEach((column, index) => {
    //     if (index === 0) {
    //       sums[index] = '合计'
    //     } else if (index === (columns.length - 3)) {
    //       let total = 0
    //       data.map(item => {
    //         total = total + (!this.$u.test.isEmptyNotZero(item.merchantPaymentMoney) ? (+item.merchantPaymentMoney) : 0)
    //       })
    //       sums[index] = '￥' + total.toFixed(2)
    //     } else {
    //       sums[index] = ''
    //     }
    //   })
    //   return sums
    // },
    // fetchData(){
    //   for(var i in this.list){

    //     console.log("i",i);
    //   }
    //   // getInventory({paymentOrderId: this.id}).then(res => {
    //   //   console.log("res",res);
    //   // })
    // },
    handleSelectionChange(val) {
      this.paymentListExcelVO = []
      let tmpObj = {}
      if (val.length) {
        // this.selectAry = val;
        val.forEach(element => {
          tmpObj = Object.assign({}, element)
          tmpObj.paymentOrderId = this.baseInfo.paymentOrderId
          tmpObj.creatDate = this.baseInfo.creatDate
          tmpObj.paymentDate = this.baseInfo.paymentDate
          tmpObj.companyName = this.baseInfo.companyName
          tmpObj.platformServiceProvider = this.baseInfo.platformServiceProvider
          tmpObj.workerMoney = (tmpObj.receviceTax * tmpObj.taskMoney / 100).toFixed(2)
          tmpObj.merchantMoney = (tmpObj.merchantTax * tmpObj.taskMoney / 100).toFixed(2)
          if (element.paymentStatus === 1) {
            tmpObj.paymentStatus = '支付成功'
          } else if (element.paymentStatus === 0) {
            tmpObj.paymentStatus = '未支付'
          } else {
            tmpObj.paymentStatus = '支付失败'
          }
          this.paymentListExcelVO.push(tmpObj)
        })
        console.log('paymentListExcelVO:', this.paymentListExcelVO)
      }
    },
    downloadExcel() {
      if (this.paymentListExcelVO.length) {
        this.loading = true

        const tableData = [
          ['支付单编号', '创建时间', '商户', '服务商', '姓名', '电话', '身份证号', '银行卡号', '发放金额', '创客服务费率', '创客服务费', '商户服务费率', '商户服务费', '创客到手金额', '商户支出', '支付状态', '支付失败原因', '支付时间']
        ]
        let rowData = []
        this.paymentListExcelVO.forEach((item, index) => {
          rowData = [
            item.paymentOrderId, item.creatDate, item.companyName, item.platformServiceProvider, item.workerName, item.mobileCode, item.idCardCode, item.bankCode, item.taskMoney, item.receviceTax, item.workerMoney, item.merchantTax, item.merchantMoney, item.realMoney, item.merchantPaymentMoney, item.paymentStatus, item.tradeFailReason, item.paymentDate
          ]
          tableData.push(rowData)
        })
        const ws = XLSX.utils.aoa_to_sheet(tableData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, '支付明细')
        XLSX.writeFile(wb, '支付清单明细.xlsx')

        setTimeout(() => {
          this.loading = false
        }, 4000)
      } else {
        this.$message.error('请先选中内容，后再点击导出')
      }
    },
    // 一键导出
    OneclickExcel() {
      this.loading = true
      this.paymentListExcelVO = []
      getInventory({ paymentOrderId: this.id }).then(res => {
        this.loading = false
        const data = res.data || res.obj
        console.log('data', data)
        this.paymentListExcelVO = data
        console.log(' this.paymentListExcelVO', this.paymentListExcelVO)
        if (this.paymentListExcelVO.length) {
          this.loading = true
          const tableData = [
            ['支付单编号', '创建时间', '商户', '服务商', '姓名', '电话', '身份证号', '银行卡号', '发放金额', '创客服务费率', '创客服务费', '商户服务费率', '商户服务费',
              '创客到手金额', '商户支出', '支付状态', '支付失败原因', '支付时间'
            ]
          ]
          let rowData = []
          this.paymentListExcelVO.forEach((item, index) => {
            item.paymentOrderId = this.baseInfo.paymentOrderId
            item.creatDate = this.baseInfo.creatDate
            item.paymentDate = this.baseInfo.paymentDate
            item.companyName = this.baseInfo.companyName
            item.platformServiceProvider = this.baseInfo.platformServiceProvider
            item.workerMoney = (item.receviceTax * item.taskMoney / 100).toFixed(2)
            console.log('item.workerMoney', item.workerMoney)
            item.merchantMoney = (item.merchantTax * item.taskMoney / 100).toFixed(2)
            console.log('item.merchantMoney', item.merchantMoney)
            if (item.paymentStatus === 1) {
              item.paymentStatus = '支付成功'
            } else if (item.paymentStatus === 0) {
              item.paymentStatus = '未支付'
            } else {
              item.paymentStatus = '支付失败'
            }
            rowData = [
              item.paymentOrderId, item.creatDate, item.companyName, item.platformServiceProvider, item
                .workerName, item.mobileCode, item.idCardCode, item.bankCode, item.taskMoney, item.receviceTax, item
                .workerMoney, item.merchantTax, item.merchantMoney, item.realMoney, item.merchantPaymentMoney, item
                .paymentStatus, item.tradeFailReason, item.paymentDate
            ]
            tableData.push(rowData)
          })
          const ws = XLSX.utils.aoa_to_sheet(tableData)
          const wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, '创客明细')
          XLSX.writeFile(wb, '创客明细.xlsx')

          setTimeout(() => {
            this.loading = false
          }, 4000)
        } else {
          this.$message.error('没有查询到数据')
        }
      }).catch(res => {
        this.loading = false
      })
    },
    handleChangeBank(row, index) {
      this.form.bankCode = row.bankCode
      this.form.paymentInventoryId = row.id
      console.log('this.form.paymentInventoryId', this.form.paymentInventoryId)
      this.form.index = index
      this.loading = false
      this.$nextTick(() => {
        this.dialogShow = true
      })
    },
    doChangeBank() {
      const form = Object.assign({}, this.form)
      if (this.$u.test.isEmpty(form.bankCode)) {
        return this.$message.error('请输入银行卡号')
      }
      this.$confirm('你确定要修改吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        updateBankCode(form).then(res => {
          this.tableList[form.index].bankCode = form.bankCode
          this.$message.success(res.message)
          this.dialogShow = false
          this.loading = false
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
</style>
