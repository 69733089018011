// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@charset \"UTF-8\"\n/* 改变主题色变量 */\n", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#fff",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#4065E1",
	"menuHover": "#2B4BB5",
	"subMenuBg": "#183597",
	"subMenuHover": "#183597",
	"sideBarWidth": "260px",
	"loginBg": "#4064E1"
};
module.exports = exports;
