var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-25" },
    [
      _c(
        "div",
        { staticClass: "search-box" },
        [
          _c(
            "el-form",
            { attrs: { inline: true, model: _vm.formData, size: "small" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商户名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入商户名称" },
                    model: {
                      value: _vm.formData.merchantName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "merchantName", $$v)
                      },
                      expression: "formData.merchantName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "任务名称" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入任务名称" },
                    model: {
                      value: _vm.formData.taskName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "taskName", $$v)
                      },
                      expression: "formData.taskName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "合作类型" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { placeholder: "合作类型" },
                      model: {
                        value: _vm.formData.cooperateMode,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "cooperateMode", $$v)
                        },
                        expression: "formData.cooperateMode",
                      },
                    },
                    _vm._l(_vm.cooperateMode, function (i, k) {
                      return _c("el-option", {
                        key: k,
                        attrs: { label: i.name, value: i.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "发布时间" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "请选择时间",
                    },
                    model: {
                      value: _vm.formData.releaseDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "releaseDate", $$v)
                      },
                      expression: "formData.releaseDate",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", [_vm._v(" 至 ")]),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      type: "date",
                      "value-format": "yyyy-MM-dd",
                      placeholder: "请选择时间",
                    },
                    model: {
                      value: _vm.formData.deadlineDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "deadlineDate", $$v)
                      },
                      expression: "formData.deadlineDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.resetPage },
                    },
                    [_vm._v("查询")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-plus" },
              on: {
                click: function ($event) {
                  return _vm.handleAdd()
                },
              },
            },
            [_vm._v(" 发布新任务 ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "u-p-lf-10 bg-white" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "multipleTable",
              attrs: { data: _vm.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "taskCode",
                  label: "任务编号",
                  width: "100",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "merchantName",
                  label: "商户名称",
                  align: "center",
                  width: "160",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "taskName",
                  label: "任务名称",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "合作类型", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("cooperateMode")(scope.row.cooperateMode)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "任务模式", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("taskMode")(scope.row.taskMode)) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "状态", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.auditState === "PASSAUDIT"
                          ? [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.filterContent(
                                      scope.row.state,
                                      _vm.$enum.taskState()
                                    )
                                  ),
                                },
                              }),
                            ]
                          : [
                              _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    _vm.$options.filters.filterContent(
                                      scope.row.auditState,
                                      _vm.$enum.taskCheckStatus()
                                    )
                                  ),
                                },
                              }),
                            ],
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "发布时间", align: "center", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.createDate) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "120",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleDel(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleShow(scope.row)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                        scope.row.auditState === "PASSAUDIT"
                          ? [
                              scope.row.state === 0
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleClose(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("关单")]
                                  )
                                : scope.row.state === 1
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleOpen(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("开启")]
                                  )
                                : _vm._e(),
                              scope.row.state === 1 || scope.row.state === 2
                                ? _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text", size: "small" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCheck(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("验收")]
                                  )
                                : _vm._e(),
                            ]
                          : [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleCheckStatus(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("审核")]
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pager-box" },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  layout: "prev, pager, next, jumper",
                  "current-page": _vm.paramsData.pageNo,
                  "page-size": _vm.paramsData.pageSize,
                  "page-count": _vm.pageTotal,
                },
                on: {
                  "update:currentPage": function ($event) {
                    return _vm.$set(_vm.paramsData, "pageNo", $event)
                  },
                  "update:current-page": function ($event) {
                    return _vm.$set(_vm.paramsData, "pageNo", $event)
                  },
                  "current-change": _vm.fetchData,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          key: "b",
          attrs: { title: "审核", visible: _vm.visibleDialogB, width: "400px" },
          on: {
            "update:visible": function ($event) {
              _vm.visibleDialogB = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "100%" },
              attrs: { placeholder: "请选择" },
              model: {
                value: _vm.checkData.auditState,
                callback: function ($$v) {
                  _vm.$set(_vm.checkData, "auditState", $$v)
                },
                expression: "checkData.auditState",
              },
            },
            _vm._l(_vm.$enum.taskCheckStatus(), function (i, k) {
              return _c("el-option", {
                key: k,
                attrs: { label: i.name, value: i.value },
              })
            }),
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.visibleDialogB = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.doCheck } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }