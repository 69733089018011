<template>
  <div class="u-p-25 wrap-fixed-content">
    <el-card v-loading="loading">
      <div class="section-title">任务信息</div>
      <el-row class="section-body" :gutter="10">
        <el-col :span="md">
          <span class="label">任务编号:</span>
          <span>{{ item.taskCode }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">商户名称:</span>
          <span>{{ item.merchantName }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">任务名称:</span>
          <span>{{ item.taskName }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">任务说明文字:</span>
          <span>{{ item.taskDesc }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">费用:</span>
          <span>{{ item.taskCostMin }} 至 {{ item.taskCostMax }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">创客所需技能:</span>
          <span>{{ item.taskSkill }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">任务上限人数:</span>
          <span>{{ item.upperLimit }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">合作类型:</span>
          <span>{{ item.cooperateMode | cooperateMode }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">任务模式:</span>
          <span>{{ item.taskMode | taskMode }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">状态:</span>
          <span>{{ item.state | taskState }}</span>
        </el-col>
        <el-col :span="md">
          <span class="label">发布时间:</span>
          <span>{{ item.createDate }}</span>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="u-m-t-20">
      <div class="section-title">已接/抢单创客明细</div>
      <join-work-list :task-id="id" />
    </el-card>
    <div class="wrap-fixed-footer bg-white u-p-lf-20 u-flex u-col-center u-row-right">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getDetail } from '@/api/task'
import JoinWorkList from '@/views/task/components/joinWorkList'

export default {
  components: { JoinWorkList },
  data() {
    return {
      md: 8,
      id: undefined,
      item: {},
      list: [],
      loading: false
    }
  },
  created() {
    this.id = this.$route.query.id
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      getDetail({
        taskId: this.id
      }).then(res => {
        this.item = res.obj
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleShow(i) {}
  }
}
</script>

<style lang="scss" scoped>
.section-body {
  > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .label {
    margin-right: 5px;
  }
}
</style>
