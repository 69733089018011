<template>
  <div>
    <template v-if="info">
      <el-divider>线下来款银行账号信息</el-divider>
      <div class="u-m-b-10">开户行名称：{{ info.bankName }}</div>
      <div class="u-m-b-10">银行卡号：{{ info.bankCode }}</div>
      <div class="u-m-b-10">账户名称：{{ info.titleOfAccount }}</div>
    </template>
    <template v-if="unionPay && !aliPay">
      <el-divider>银联详情</el-divider>
      <div class="u-flex u-col-top u-row-around u-flex-wrap">
        <union-info v-for="(i, k) in unionPay" :key="k + Date.now()" :info="i" :withdraw="true"
          :merchant-id="merchantId" />
      </div>
    </template>
    <template v-if="aliPay">
      <div class=" u-font-28">可用余额：￥{{ useBal.toFixed(2) }}</div>
      <el-divider>余额详情</el-divider>
      <div class="u-row-center" style="display:flex">
        <union-info v-for="(i, k) in unionPay" :key="k + Date.now()" :info="i" :withdraw="false" />
        <union-info class="u-m-l-10" v-for="(i, k) in aliPay" :key="k + Date.now()" :info="i"
          :companyAlipayId="companyAlipayId" isAliPay withdraw @getTotalAlipayBalance="getTotalAlipayBalance" />
      </div>
    </template>
    <template v-if="zhaoHangBalance">
      <el-divider>招行详情</el-divider>
      <div class="u-flex u-row-around">
        <div class="u-padding-10 u-border-right">
          <div class="u-m-b-10">可用余额：￥{{ zhaoHangBalance.actbal }}</div>
          <div class="u-m-b-10">主账户：{{ zhaoHangBalance.accnbr }}</div>
          <div class="u-m-b-10">记账子单元名称：{{ zhaoHangBalance.dmanam }}</div>
          <div class="u-m-b-10">充值账户：{{ zhaoHangBalance.accnbr + zhaoHangBalance.dmanbr }}</div>
          <div class="u-m-b-10">子账户：{{ zhaoHangBalance.dmanbr }}</div>
          <div class="u-m-b-10">户名：{{ zhaoHangBalance.accountName }}</div>
          <div class="u-m-b-10">开户行：{{ zhaoHangBalance.bankDeposit }}</div>
        </div>
        <div class="u-text-center u-p-b-10">
          <el-button type="primary" @click="handleWithdrawZs()">提现</el-button>
        </div>
      </div>
    </template>
    <el-dialog title="提现" append-to-body :visible.sync="withdrawDialogZs" width="600px">
      <withdraw-by-zhao-hang ref="withdrawFormZs" @success="withdrawDialogZs = false" />
    </el-dialog>
    <template v-if="totalList.length">
      <el-divider>合作信息(总包+分包)</el-divider>
      <el-form v-loading="coopLoading">
        <div class="u-flex u-col-top u-row-around u-flex-wrap">
          <div v-for="item in totalList" :key="item.guid" class="u-m-t-30 u-p-r-20" style="width: 45%">
            <div class="u-border u-p-20" style="position: relative">
              <el-form-item label="平台服务商" label-width="100px">
                <el-input v-model="item.taxName" readonly />
              </el-form-item>
              <el-form-item label="综合税费率" label-width="100px">
                {{ (+item.chargeStatus) === 0 ? '一口价' : '梯度价' }}
              </el-form-item>
              <el-form-item v-if="item.chargeStatus === 0" label-width="100px">
                <el-input v-model.number="item.serviceCharge" type="number" min="0">
                  <span slot="suffix" style="line-height: 40px">%</span>
                </el-input>
              </el-form-item>
              <el-form-item v-else label-width="100px">
                <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                  <div class="u-flex-2">单人单月额度</div>
                  <div class="u-flex-1">服务费率</div>
                  <div style="width: 50px" />
                </div>
                <div v-if="item.addCompanyLadderServiceDtoList">
                  <div v-for="i in item.addCompanyLadderServiceDtoList" :key="i.guid"
                    class="u-flex u-col-center u-row-center u-m-t-10">
                    <div class="u-flex-2 u-flex u-col-center u-row-center">
                      <el-input v-model="i.startMoney" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                      <span class="u-p-lf-5">至</span>
                      <el-input v-model="i.endMoney" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                    </div>
                    <div class="u-flex-1 u-p-l-10">
                      <el-input v-model="i.serviceCharge" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">%</span>
                      </el-input>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="合作合同" required label-width="100px">
                <div class="u-flex u-col-center">
                  <el-button v-if="!$u.test.isEmpty(item.contract)" class="u-m-l-10" type="text"
                    @click="$preViewer(item.contract)">查看</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </template>
    <template v-if="crowdList.length">
      <el-divider>合作信息(众包)</el-divider>
      <el-form v-loading="coopLoading">
        <div class="u-flex u-col-top u-row-around u-flex-wrap">
          <div v-for="item in crowdList" :key="item.guid" class="u-m-t-30 u-p-r-20" style="width: 45%">
            <div class="u-border u-p-20" style="position: relative">
              <el-form-item label="平台服务商" label-width="100px">
                <el-input v-model="item.taxName" readonly />
              </el-form-item>
              <el-form-item label="综合税费率" label-width="100px">
                {{ (+item.chargeStatus) === 0 ? '一口价' : '梯度价' }}
              </el-form-item>
              <el-form-item v-if="item.chargeStatus === 0" label-width="100px">
                <el-input v-model.number="item.serviceCharge" type="number" min="0">
                  <span slot="suffix" style="line-height: 40px">%</span>
                </el-input>
              </el-form-item>
              <el-form-item v-else label-width="100px">
                <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                  <div class="u-flex-2">单人单月额度</div>
                  <div class="u-flex-1">服务费率</div>
                  <div style="width: 50px" />
                </div>
                <div v-if="item.addCompanyLadderServiceDtoList">
                  <div v-for="i in item.addCompanyLadderServiceDtoList" :key="i.guid"
                    class="u-flex u-col-center u-row-center u-m-t-10">
                    <div class="u-flex-2 u-flex u-col-center u-row-center">
                      <el-input v-model="i.startMoney" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                      <span class="u-p-lf-5">至</span>
                      <el-input v-model="i.endMoney" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                    </div>
                    <div class="u-flex-1 u-p-l-10">
                      <el-input v-model="i.serviceCharge" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">%</span>
                      </el-input>
                    </div>
                  </div>
                </div>
              </el-form-item>
              <el-form-item label="合作合同" required label-width="100px">
                <div class="u-flex u-col-center">
                  <el-button v-if="!$u.test.isEmpty(item.contract)" class="u-m-l-10" type="text"
                    @click="$preViewer(item.contract)">查看</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </template>
  </div>
</template>

<script>
import { getOfflineDetail, getServiceCoop, getUnionPayDetail, queryZspayCompanyBalance } from '@/api/shop'
import { getTotalAlipayBalance } from '@/api/pay'
import WithdrawByZhaoHang from '@/views/shop/components/WithdrawByZhaoHang'
import { getGuid } from '@/utils'
import UnionInfo from '@/components/UnionInfo'

/**
 * 服务商与商户合作信息
 */
export default {
  name: 'ServiceInfo',
  components: { UnionInfo, WithdrawByZhaoHang },
  props: {
    merchantId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      totalList: [],
      crowdList: [],
      info: undefined,
      unionPay: undefined,
      aliPay: undefined,
      infoLoading: false,
      zhaoHangBalance: undefined, // 招行余额查询结果
      withdrawDialogZs: false,
      coopLoading: false,
      useBal: 0,
      taxId: '',
      companyAlipayId: ''
    }
  },
  methods: {
    resetData() {
      this.totalList = []
      this.crowdList = []
      this.info = undefined
      this.unionPay = undefined
      this.aliPay = undefined
      this.useBal = 0
    },
    fetchData(taxId, type) {
      this.taxId = taxId
      this.resetData()
      if (type === 'offline') {
        this.infoLoading = true
        getOfflineDetail({
          taxId: taxId
        }).then(res => {
          this.info = res.obj || {}
          this.infoLoading = false
        }).catch(res => {
          this.infoLoading = false
        })
      } else if (type === 'zsPay') {
        this.infoLoading = true
        queryZspayCompanyBalance({
          taxId: taxId,
          merchantId: this.merchantId
        }).then(res => {
          this.infoLoading = false
          if (res.obj) {
            try {
              this.zhaoHangBalance = JSON.parse(res.obj.result).response.body.ntdmabalz[0]
              this.zhaoHangBalance.taxZspayId = res.obj.taxZspayId
              this.zhaoHangBalance.companyZspayId = res.obj.companyZspayId
              this.zhaoHangBalance.accountName = res.obj.accountName
              this.zhaoHangBalance.bankDeposit = res.obj.bankDeposit
            } catch (error) {
              console.log('服务器返回格式有变')
            }
          } else {
            console.log('服务器返回格式有变')
          }
        }).catch(res => {
          this.infoLoading = false
        })
      } else if (type === 'alipay') {
        this.infoLoading = true
        this.getTotalAlipayBalance()
        this.getUnionPayDetail()
      } else {
        this.infoLoading = true
        getUnionPayDetail({
          taxId: taxId,
          merchantId: this.merchantId
        }).then(res => {
          this.unionPay = res.data || []
          this.infoLoading = false
        }).catch(res => {
          this.infoLoading = false
        })
      }
      this.coopLoading = true
      getServiceCoop({
        taxId: taxId,
        merchantId: this.merchantId
      }).then(res => {
        const data = res.data || []
        if (data.length) {
          data.forEach(val => {
            if (parseInt(val.packageStatus) === 0) {
              this.addTotalList(val)
            } else {
              this.addCrowdList(val)
            }
          })
        }
        this.coopLoading = false
      }).catch(res => {
        this.coopLoading = false
      })
    },
    getTotalAlipayBalance() {
      getTotalAlipayBalance({
        taxId: this.taxId,
        merchantId: this.merchantId
      }).then(res => {
        this.aliPay = res.data || []
        if (this.aliPay.length) {
          this.useBal += this.aliPay[0].useBal
          this.companyAlipayId = this.aliPay[0].companyAlipayId
        }
        this.infoLoading = false
      }).catch(res => {
        this.infoLoading = false
      })
    },
    getUnionPayDetail() {
      getUnionPayDetail({
        taxId: this.taxId,
        merchantId: this.merchantId
      }).then(res => {
        this.unionPay = res.data || []
        if (this.unionPay.length) {
          this.useBal += this.unionPay[0].useBal
        }
        this.infoLoading = false
      }).catch(res => {
        this.infoLoading = false
      })
    },
    handleWithdrawZs() {
      this.withdrawDialogZs = true
      this.$nextTick(() => {
        this.$refs.withdrawFormZs.setData({
          taxZspayId: this.zhaoHangBalance.taxZspayId,
          companyZspayId: this.zhaoHangBalance.companyZspayId
        })
      })
    },
    addTotalList(val) {
      this.totalChecked = true
      const addCompanyLadderServiceDtoList = []
      val = val || {}
      if (val.companyLadderServiceVos) {
        val.companyLadderServiceVos.forEach(x => {
          addCompanyLadderServiceDtoList.push(this.addLadderItem(x))
        })
      }
      const unionpayBankTypeList = val.unionpayBankTypeList || []
      const _payType = unionpayBankTypeList.length ? ['offline', 'unionPay'] : ['offline']
      this.totalList.push({
        guid: getGuid(),
        taxName: val.taxName || '',
        'addCompanyLadderServiceDtoList': addCompanyLadderServiceDtoList,
        'chargeStatus': val.chargeStatus ? parseInt(val.chargeStatus) : 0,
        'companyId': val.companyId || '',
        'contract': val.contract || '',
        'packageStatus': val.packageStatus || 0,
        'serviceCharge': val.serviceCharge || 0,
        'taxId': val.taxId || '',
        _payType: _payType, // 支付方式
        inBankNo: val.inBankNo || '',
        unionpayBankTypeList: unionpayBankTypeList
      })
    },
    addCrowdList(val) {
      this.crowdChecked = true
      const addCompanyLadderServiceDtoList = []
      val = val || {}
      if (val.companyLadderServiceVos) {
        val.companyLadderServiceVos.forEach(x => {
          addCompanyLadderServiceDtoList.push(this.addLadderItem(x))
        })
      }
      const unionpayBankTypeList = val.unionpayBankTypeList || []
      const _payType = unionpayBankTypeList.length ? ['offline', 'unionPay'] : ['offline']
      this.crowdList.push({
        guid: getGuid(),
        'addCompanyLadderServiceDtoList': addCompanyLadderServiceDtoList,
        'chargeStatus': val.chargeStatus ? parseInt(val.chargeStatus) : 0,
        taxName: val.taxName || '',
        'contract': val.contract || '',
        'packageStatus': val.packageStatus || 1,
        'serviceCharge': val.serviceCharge || 0,
        'taxId': val.taxId || '',
        _payType: _payType, // 支付方式
        inBankNo: val.inBankNo || '',
        unionpayBankTypeList: unionpayBankTypeList
      })
    },
    addLadderItem(val) {
      val = val || {}
      return {
        guid: getGuid(),
        'companyTaxId': '',
        id: val.companyLadderServiceId || '',
        'endMoney': val.endMoney || null,
        'serviceCharge': val.serviceCharge || null,
        'startMoney': val.startMoney || null
      }
    }
  }
}
</script>

<style scoped></style>
