var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "u-flex u-col-center user-base-box" }, [
            _c(
              "div",
              { staticClass: "name u-flex u-col-center" },
              [
                _c("avatar", {
                  staticClass: "u-m-r-10",
                  attrs: { src: _vm.query.user_head, size: 50 },
                }),
                _c("div", [
                  _c("div", { staticClass: "u-font-18" }, [
                    _vm._v("代理商：" + _vm._s(_vm.query.realName)),
                  ]),
                  _c("div", { staticClass: "u-font-14 u-m-t-5" }, [
                    _vm._v("代理商编号：" + _vm._s(_vm.query.managersId)),
                  ]),
                ]),
              ],
              1
            ),
            _c("div", { staticClass: "u-flex u-col-center u-row-left" }, [
              _c("div", { staticClass: "u-p-20" }, [
                _c("div", { staticClass: "u-tips-color u-font-14" }, [
                  _vm._v("流水提成（直属商户）"),
                ]),
                _c("div", { staticClass: "u-font-22 u-m-t-10" }, [
                  _vm._v(
                    "¥ " +
                      _vm._s(
                        _vm.$u.formatMoney(_vm.count.totalMerchantCommission) ||
                          0
                      )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "u-p-20" }, [
                _c("div", { staticClass: "u-tips-color u-font-14" }, [
                  _vm._v("差额提成（直属商户）"),
                ]),
                _c("div", { staticClass: "u-font-22 u-m-t-10" }, [
                  _vm._v(
                    "¥ " +
                      _vm._s(
                        _vm.$u.formatMoney(_vm.count.totalMerchantDifference) ||
                          0
                      )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "u-p-20" }, [
                _c("div", { staticClass: "u-tips-color u-font-14" }, [
                  _vm._v("提成总额"),
                ]),
                _c("div", { staticClass: "u-font-22 u-m-t-10" }, [
                  _vm._v(
                    "¥ " +
                      _vm._s(_vm.$u.formatMoney(_vm.count.totalCommission) || 0)
                  ),
                ]),
              ]),
            ]),
          ]),
        ]
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "u-m-t-20",
          attrs: { header: "流水提成信息" },
        },
        [
          _c(
            "el-table",
            {
              staticClass: "c-table",
              staticStyle: { width: "100%" },
              attrs: { data: _vm.list, "tooltip-effect": "dark" },
            },
            [
              _c("el-table-column", {
                attrs: { label: "时间", width: "150", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.row.createDate) + " ")]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "流水（直属商户）", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.$u.test.isEmptyNotZero(
                                scope.row.merchantBusiness
                              )
                                ? "￥" +
                                    _vm.$u.formatMoney(
                                      scope.row.merchantBusiness
                                    )
                                : "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "提成率", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.$u.test.isEmptyNotZero(
                                scope.row.merchantRate
                              )
                                ? scope.row.merchantRate + "%"
                                : "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "流水提成（直属商户）", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              !_vm.$u.test.isEmptyNotZero(
                                scope.row.merchantCommission
                              )
                                ? "￥" +
                                    _vm.$u.formatMoney(
                                      scope.row.merchantCommission
                                    )
                                : "-"
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "合计", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " ￥" +
                            _vm._s(
                              _vm.$u.formatMoney(+scope.row.merchantCommission)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "el-row",
                { staticClass: "box", attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { md: 12, sm: 24 } },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "直属商户流水：¥ " +
                            _vm._s(_vm.$u.formatMoney(_vm.merchantBusiness)) +
                            " "
                        ),
                      ]),
                      _vm._l(_vm.directCommissionProportion, function (i) {
                        return _c(
                          "div",
                          {
                            key: i.id,
                            staticClass:
                              "u-flex u-col-center u-row-center u-m-t-10",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "u-p-r-5",
                                staticStyle: { width: "50px" },
                              },
                              [
                                _vm.merchantBusiness >= +i.startMoney &&
                                _vm.merchantBusiness < +i.endMoney
                                  ? _c("i", {
                                      staticClass:
                                        "el-icon-success text-success u-font-18",
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "u-flex-2 u-flex u-col-center u-row-center",
                              },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: i.startMoney,
                                      callback: function ($$v) {
                                        _vm.$set(i, "startMoney", $$v)
                                      },
                                      expression: "i.startMoney",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "line-height": "40px" },
                                        attrs: { slot: "suffix" },
                                        slot: "suffix",
                                      },
                                      [_vm._v("元")]
                                    ),
                                  ]
                                ),
                                _c("span", { staticClass: "u-p-lf-5" }, [
                                  _vm._v("至"),
                                ]),
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: i.endMoney,
                                      callback: function ($$v) {
                                        _vm.$set(i, "endMoney", $$v)
                                      },
                                      expression: "i.endMoney",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "line-height": "40px" },
                                        attrs: { slot: "suffix" },
                                        slot: "suffix",
                                      },
                                      [_vm._v("元")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "u-flex-1 u-p-l-10" },
                              [
                                _c(
                                  "el-input",
                                  {
                                    attrs: {
                                      readonly: "",
                                      placeholder: "请输入",
                                    },
                                    model: {
                                      value: i.commissionRate,
                                      callback: function ($$v) {
                                        _vm.$set(i, "commissionRate", $$v)
                                      },
                                      expression: "i.commissionRate",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "line-height": "40px" },
                                        attrs: { slot: "suffix" },
                                        slot: "suffix",
                                      },
                                      [_vm._v("%")]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        { staticClass: "u-m-t-20", attrs: { header: "差额提成信息" } },
        [
          _c("count-detail-pay-list", {
            attrs: { "managers-id": _vm.query.managersId },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }