var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-m-25 wrap-fixed-content" },
    [
      _c(
        "el-card",
        { attrs: { header: "基本信息" } },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formData",
              staticStyle: { "max-width": "800px" },
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "250px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "realName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formData.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "realName", $$v)
                      },
                      expression: "formData.realName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录账号", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入登录账号" },
                    model: {
                      value: _vm.formData.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "userName", $$v)
                      },
                      expression: "formData.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号码", prop: "mobileCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入手机号" },
                    model: {
                      value: _vm.formData.mobileCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "mobileCode", $$v)
                      },
                      expression: "formData.mobileCode",
                    },
                  }),
                ],
                1
              ),
              _vm.$u.test.isEmpty(_vm.formData.id)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "初始密码", prop: "initPassWord" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "请输入初始密码",
                        },
                        model: {
                          value: _vm.formData.initPassWord,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "initPassWord", $$v)
                          },
                          expression: "formData.initPassWord",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "修改密码" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: "为空则不修改",
                        },
                        model: {
                          value: _vm.formData.initPassWord,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "initPassWord", $$v)
                          },
                          expression: "formData.initPassWord",
                        },
                      }),
                    ],
                    1
                  ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "confirmPassWord" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.confirmPassWord,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "confirmPassWord", $$v)
                      },
                      expression: "formData.confirmPassWord",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "status", $$v)
                        },
                        expression: "formData.status",
                      },
                    },
                    _vm._l(_vm.$enum.authStatus(), function (i, k) {
                      return _c(
                        "el-radio",
                        { key: k, attrs: { label: i.value } },
                        [_vm._v(_vm._s(i.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "u-m-t-20",
          attrs: { header: "佣金结算方式（可多选）" },
        },
        [
          _c("commission-detail", {
            model: {
              value: _vm.formData,
              callback: function ($$v) {
                _vm.formData = $$v
              },
              expression: "formData",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formData")
                },
              },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }