<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="监督部门名称">
          <el-input v-model="formData.regulatorName" placeholder="请输入监管部门名称" />
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker v-model="formData.startDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          至
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.endDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd()">
        添加监管部门
      </el-button>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="regulatorName"
          label="监督部门名称"
          align="center"
          width="140"
          show-overflow-tooltip
        />
        <el-table-column
          prop="userName"
          label="登录账号"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="linkman"
          label="联系人"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="linkMobile"
          label="联系电话"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.status, $enum.authStatus())" />
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
          width="100"
          show-overflow-tooltip
        />
        <el-table-column
          label="操作"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from '@/api/supervision'

export default {
  data() {
    return {
      loading: false,
      isWait: false,
      formData: {
        regulatorName: '',
        startDate: '',
        endDate: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getList(Object.assign({}, this.formData, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleAdd() {
      this.$router.push({
        path: '/supervision/add'
      })
    },
    handleEdit(i) {
      this.$router.push({
        path: '/supervision/edit',
        query: {
          id: i.id
        }
      })
    },
    handleShow(i) {
      this.$router.push({
        path: '/supervision/view',
        query: {
          id: i.id
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
