<template>
  <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" class="u-form" label-width="150px" style="max-width: 1200px">
    <el-form-item label="服务类型" prop="serviceType">
      <el-input v-model="formData.serviceType" placeholder="请输入" />
    </el-form-item>
    <el-form-item label="具体服务内容" prop="serviceContent">
      <el-input v-model="formData.serviceContent" type="textarea" placeholder="请输入" />
    </el-form-item>
    <el-form-item label="开票类目" prop="billingCategory">
      <el-input v-model="formData.billingCategory" placeholder="请输入" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submit('formData')">提交</el-button>
      <el-button @click="cancel">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { addTaxType } from '@/api/service'
const defaultForm = () => {
  return {
    'billingCategory': '',
    'serviceContent': '',
    'serviceType': ''
  }
}
export default {
  name: 'AddTax',
  data() {
    const form = defaultForm()
    return {
      loading: false,
      formData: Object.assign({}, form),
      rules: {
        serviceType: [
          { required: true, message: '请输入服务类型', trigger: ['blur'] }
        ],
        serviceContent: [
          { required: true, message: '请输入具体服务内容', trigger: ['blur'] }
        ],
        billingCategory: [
          { required: true, message: '请输入开票类目', trigger: ['blur'] }
        ]
      }
    }
  },
  methods: {
    resetForm() {
      const form = defaultForm()
      this.formData = Object.assign({}, form)
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true
          const form = this.formData
          addTaxType(form).then(res => {
            this.loading = false
            this.$message.success('操作成功')
            this.$emit('success')
          }).catch(res => {
            this.loading = false
          })
        }
      })
    },
    cancel() {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>
