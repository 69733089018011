<template>
  <div class="base-echarts">
    <div ref="echartsRef" :style="{ width: width, height: height }"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "BaseEcharts",
  props: {
    options: {
      type: Object,
      require: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "280px",
    },
  },
  data(){
   return {
     echartsInstance:null,
   }
  },
  mounted() {
    const el = this.$refs["echartsRef"];
    //创建实例
    this.echartsInstance = echarts.init(el);
    this.echartsInstance.setOption(this.options);
    //适应屏幕缩放
    window.addEventListener("resize", () => {
      this.echartsInstance.resize();
    });
  },
  watch:{
    options:{
      handler () { 
        console.log(this.options,'options');
        //监听options的改变，重新调用setOption方法
        this.echartsInstance.setOption(this.options, {notMerge:true});
      },
      deep:true
    }
  }
};
</script>