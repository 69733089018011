import request from '@/utils/request'

/**
 * 分包开票，待开票列表
 */
export function getWaitList(data) {
  return request({
    url: '/platform/invoice/listSubQuery',
    method: 'post',
    data
  })
}

/**
 * 汇总代开详情
 */
export function getInvoiceInfo(params) {
  return request({
    url: '/platform/invoice/queryMakerTotalInvoiceInfo',
    method: 'post',
    params
  })
}

/**
 * 分包开票，开票清单明细信息
 */
export function getInvoiceListQuery(params) {
  return request({
    url: '/platform/invoice/invoiceListQuery',
    method: 'post',
    params
  })
}

/**
 * 分包开票，汇总代开
 */
export function saveInvoiceInfo(data) {
  return request({
    url: '/platform/invoice/saveOrUpdateMakerTotalInvoice',
    method: 'post',
    data
  })
}

/**
 *分包已开票，汇总代开列表
 */
export function getDoneList(data) {
  return request({
    url: '/platform/invoice/queryMakerTotalInvoice',
    method: 'post',
    data
  })
}

/**
 *分包已开票，汇总代开详情
 */
export function getInvoiceDetails(params) {
  return request({
    url: '/platform/invoice/queryMakerTotalInvoiceDetails',
    method: 'post',
    params
  })
}

/**
 *分包已开票，汇总代开支付清单详情
 */
export function getMakerList(params) {
  return request({
    url: '/platform/invoice/getMakerTotalInvoicePayList',
    method: 'post',
    params
  })
}

/** ***********************/

/**
//  * 汇总代开,发票信息,创客到手明细
//  */
// export function getListByInvoiceId(params) {
//   return request({
//     url: '/merchant/subpackage/getListByInvoiceId',
//     method: 'post',
//     params
//   })
// }

// /**
//  *
// 汇总代开,发票信息
// */
// export function getSubpackageInfo(params) {
//   return request({
//     url: '/merchant/subpackage/getSubpackageInfo',
//     method: 'post',
//     params
//   })
// }

// /**
//  *
// 汇总代开,支付信息，税价总和
// */
// export function getSummary(params) {
//   return request({
//     url: '/merchant/subpackage/getSummary',
//     method: 'post',
//     params
//   })
// }
