<template>
  <div class="u-m-20 wrap-fixed-content" style="min-width: 1200px">
    <step-one ref="stepOne" v-model="formData" :is-edit="true" @next="submit" />
    <el-card header="银联信息" class="u-m-t-20">
      <union-pay-edit :tax-id="id" />
    </el-card>
    <el-card header="支付宝配置" class="u-m-t-20">
      <aliPayEdit :tax-id="id" />
    </el-card>
    <step-two ref="stepTwo" v-model="formData" :load="load" :is-edit="true" class="u-m-t-20" />
    <div class="wrap-fixed-footer btn--box">
      <el-button type="primary" size="small" @click="next">确定</el-button>
      <!--      <el-button type="warning" size="small" @click="editStepTwo">业务配置</el-button>-->
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import { _defaultForm } from '@/views/service/defaultForm'
import { getDetail, update } from '@/api/service'
import StepOne from '@/views/service/components/StepOne'
import StepTwo from '@/views/service/components/StepTwo'
import { deepClone } from '@/utils'
import UnionPayEdit from '@/views/service/components/unionPayEdit'
import aliPayEdit from '@/views/service/components/aliPayEdit'

export default {
  components: { UnionPayEdit,aliPayEdit, StepTwo, StepOne },
  data() {
    return {
      id: undefined,
      load: undefined,
      step: 0,
      lastStep: 0,
      formData: Object.assign({}, _defaultForm())
    }
  },
  created() {
    this.id = this.$route.query.id
    if (this.id) {
      this.fetchData()
    }
  },
  methods: {
    fetchData() {
      getDetail({
        taxId: this.id
      }).then(res => {
        const data = res.obj || {}
        data.taxId = this.id
        const defaultForm = _defaultForm()
        const index = ['manyTaxPackage', 'totalTaxPackage', 'manyLadders', 'totalLadders']
        index.map(val => {
          if (!data[val]) {
            data[val] = defaultForm[val]
          }
        })
        this.formData = data
        this.load = true
      }).catch(res => {
      })
    },
    next() {
      this.$refs.stepOne.next()
    },
    submit() {
      const form = deepClone(this.formData)
      form.manyLadders = undefined
      form.manyServiceLadders = undefined
      form.manyTaxPackage = undefined
      form.totalLadders = undefined
      form.totalServiceLadders = undefined
      form.totalTaxPackage = undefined
      const loading = this.$loading()
      update(form).then(res => {
        this.$message.success(res.message)
        loading.close()
        this.$router.go(-1)
      }).catch(res => {
        loading.close()
      })
    },
    editStepTwo() {
      this.$refs.stepTwo.submit()
    }
  }
}
</script>

<style scoped>
</style>
