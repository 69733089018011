import Vue from 'vue';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import 'text-security/text-security-disc.css';
import ElementUI from 'element-ui';
// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false;
ElementUI.MessageBox.setDefaults({
  closeOnClickModal: false,
});
// import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/theme/index.scss';
import locale from 'element-ui/lib/locale/lang/zh-CN'; // lang i18n

import '@/styles/index.scss'; // global css

import App from './App';
import store from './store';
import router from './router';

import '@/icons'; // icon
import '@/permission'; // permission control

const dayjs = require('dayjs');
Vue.prototype.$dayjs = dayjs;

/**
 * 日期格式化
 * {{ 日期 | filterDate('YYYY-MM-DD HH:mm:ss') }}
 * YYYY-MM-DD HH:mm:ss
 */
Vue.filter('filterDate', (string, format) => {
  if (!string) return '';
  format = format || 'YYYY-MM-DD';
  return dayjs(string).format(format);
});

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'development') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
Vue.use(ElementUI, { locale });

Vue.config.productionTip = false;

// Vue.prototype.$upload = process.env.VUE_APP_UPLOAD_IMG
// Vue.prototype.$uploadExcel = process.env.VUE_APP_UPLOAD_EXCEL

/**
 * 判断文件类型
 * @param url
 * @returns {string|boolean}
 */
Vue.prototype.$checkFileType = function (url) {
  if (url) {
    const index = url.lastIndexOf('.');
    if (index) {
      let type = url.substr(index + 1);
      if (typeof type === 'string') {
        type = type.toLowerCase();
      }
      if (type === 'pdf') {
        return 'pdf';
      } else if (['xlsx', 'xls', 'csv'].indexOf(type) > -1) {
        return 'excel';
      } else if (['jpg', 'png', 'gif', 'bmp'].indexOf(type) > -1) {
        return 'img';
      } else if (['mp4', 'mov', 'm4v', 'avi', 'm3u8'].indexOf(type) > -1) {
        return 'video';
      } else {
        return 'file';
      }
    }
  }
  return false;
};
Vue.prototype.$returnTrue = function () {
  return true;
};
import Viewer from '@/components/Viewer/viewer';
import FileIcon from '@/components/FileIcon/FileIcon';
import Avatar from '@/components/Avatar';

Vue.component('Viewer', Viewer);
Vue.component('FileIcon', FileIcon);
Vue.component('Avatar', Avatar);

// 规则检验
import test from '@/utils/test';
import {
  contentHtml,
  formatContent as filterContent,
  formatMoney,
} from '@/utils/filter';

const $u = {
  test,
  filterContent,
  formatMoney,
};
/**
 * 状态类型渲染filter，可以utils/enum.js结合使用
 * 用法：<div v-html="$options.filters.filterContent('格式化字段', $enum.taskState())" />
 */
Vue.filter('filterContent', (value, option, number) => {
  if (number) {
    value = parseInt(value);
  }
  return filterContent(value, option);
});
Vue.filter('filterContentItem', (type, text) => {
  return contentHtml(type, text);
});

Vue.prototype.$u = $u;

/**
 * 返回个体户名称
 * @param ibname {string} 主名称，若没有则解析 othername
 * @param othername {string}
 * @returns {string|{length}|*}
 */
Vue.prototype.$ibname = function (ibname, othername) {
  if (ibname && ibname.length) {
    return ibname;
  }
  if (othername && othername.length) {
    const data = othername.split(',');
    return data.length ? data[0] : '';
  }
  return '';
};

import enumFunc from '@/utils/enum';

/**
 * 全局枚举，对应utils/enum.js
 * 如 this.$enum.workAttestation()
 */
Vue.prototype.$enum = enumFunc;

/**
 * 全局filters,对应utils/enum.js
 * 如 {{ xx | workAttestation }}
 */
Object.keys(enumFunc).forEach((key) => {
  Vue.filter(key, function (value) {
    return test.isEmptyNotZero(value) ? '' : enumFunc[key](value);
  });
});

import { MessageBox } from 'element-ui';

/**
 * 成功样式弹窗，用于特殊模式下，弹窗类型提醒
 * @param msg
 * @param callback {function}
 * @returns {Promise<MessageBoxData>}
 */
Vue.prototype.$successModal = (msg, callback) => {
  return MessageBox.alert(
    '<div class="u-text-center"><i class="el-icon-success text-success" style="font-size: 48px;margin-bottom: 12px"></i><div style="font-size: 18px">' +
      (msg || '操作成功') +
      '</div></div>',
    '系统提示',
    {
      dangerouslyUseHTMLString: true,
      center: true,
      callback: () => {
        if (callback) {
          callback();
        }
      },
    }
  );
};

/**
 * 延迟1.8s返回上一个也买你
 */
Vue.prototype.goBack = () => {
  setTimeout(() => {
    router.go(-1);
  }, 1800);
};

/**
 * 失败样式弹窗，用于特殊模式下，弹窗类型提醒
 * @param msg
 * @returns {Promise<MessageBoxData>}
 */
Vue.prototype.$errorModal = (msg) => {
  return MessageBox.alert(
    '<div class="u-text-center"><i class="el-icon-error text-danger" style="font-size: 48px;margin-bottom: 12px"></i><div style="font-size: 18px">' +
      (msg || '操作失败') +
      '</div></div>',
    '系统提示',
    {
      dangerouslyUseHTMLString: true,
      center: true,
    }
  );
};

const vm = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});

Vue.prototype.$preViewer = function (url) {
  vm.$children[0].$refs.appViewer.show(url);
};
