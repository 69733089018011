<template>
  <div>
    <el-table ref="multipleTable" v-loading="listLoading" :data="list" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="taxName" width="120" label="服务商名称" align="center" />
      <el-table-column label="交易次数" align="center">
        <template slot-scope="scope">
          {{ (+scope.row.manyPackageCount) + (+scope.row.totalPackageCount) }}
        </template>
      </el-table-column>
      <el-table-column label="总包+分包流水" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.totalPackageMoney) ? ('￥' + $u.formatMoney(scope.row.totalPackageMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="众包流水" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.manyPackageMoney) ? ('￥' + $u.formatMoney(scope.row.manyPackageMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <div v-if="(+scope.row.cooperationState) === 1" v-html="$options.filters.filterContentItem('success', '合作中')" />
          <div v-else v-html="$options.filters.filterContentItem('danger', '暂停合作')" />
        </template>
      </el-table-column>
      <el-table-column prop="startCooperationDate" width="180" label="开始合作时间" align="center" />
      <el-table-column prop="latelyCooperationDate" width="180" label="最近交易时间" align="center" />
      <el-table-column label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-box">
      <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
    </div>
  </div>
</template>

<script>
import { getCooperationService } from '@/api/shop'

export default {
  name: 'CooperationService',
  props: {
    merchantId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      listLoading: false,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.listLoading = true
      getCooperationService(Object.assign({}, {
        merchantId: this.merchantId
      }, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.listLoading = false
      }).catch(res => {
        this.listLoading = false
      })
    },
    handleShow(row) {
      this.$router.push({
        path: '/shop/serviceDetail',
        query: {
          merchantId: this.merchantId,
          merchantName: row.merchantName,
          taxId: row.taxId,
          taxName: row.taxName
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
