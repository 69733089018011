<template>
  <div>
    <personal-table />
  </div>
</template>

<script>
import PersonalTable from '@/views/individualBusiness/components/personalTable'
export default {
  components: { PersonalTable },
  data() {
    return {

    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
