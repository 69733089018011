import request from '@/utils/request'
// 平台业务员接口
/**
 * @returns {AxiosPromise}
 */
export function getList(params) {
  return request({
    url: '/platform/struture/getSalesManAll',
    method: 'post',
    params
  })
}
/**
 * 详情
 * @returns {AxiosPromise}
 */
export function getDetail(params) {
  return request({
    url: '/platform/struture/findBySalesManId',
    method: 'post',
    params
  })
}
/**
 * 删除
 * @returns {AxiosPromise}
 */
export function del(params) {
  return request({
    url: '/platform/struture/removeSalesMan',
    method: 'post',
    params
  })
}
/**
 * 更新
 * @returns {AxiosPromise}
 */
export function update(data) {
  return request({
    url: '/platform/struture/addSalesMan',
    method: 'post',
    data
  })
}
/**
 * 统计
 * @returns {AxiosPromise}
 */
export function count(params) {
  return request({
    url: '/platform/struture/getSalesManPaymentListCount',
    method: 'post',
    params
  })
}
/**
 * 流水
 * @returns {AxiosPromise}
 */
export function getPayList(params) {
  return request({
    url: '/platform/struture/getSalesManPaymentList',
    method: 'post',
    params
  })
}
/**
 * 统计列表
 * @returns {AxiosPromise}
 */
export function getCountList(params) {
  return request({
    url: '/platform/struture/salesmanAndAgentStatistics',
    method: 'get',
    params
  })
}

/**
 * 统计列表统计
 * @returns {AxiosPromise}
 */
export function getCountListTotal(params) {
  return request({
    url: '/platform/struture/totalSalesmanSAndAgentStatistics',
    method: 'get',
    params
  })
}
/**
 * 统计详情列表
 * @returns {AxiosPromise}
 */
export function getCountDetailList(params) {
  return request({
    url: '/platform/struture/salesmanSAndAgentDetail',
    method: 'get',
    params
  })
}

/**
 * 统计详情统计
 * @returns {AxiosPromise}
 */
export function getCountDetailTotal(params) {
  return request({
    url: '/platform/struture/salesmanAndAgentStatisticsDetail',
    method: 'get',
    params
  })
}

/**
 * 结算
 * @param params
 * @returns {AxiosPromise}
 */
export function settle(params) {
  return request({
    url: '/platform/struture/commissionSettlement',
    method: 'post',
    params
  })
}

/**
 * 获取查询所有服务商总包或众包合作信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getTaxPackageAll(params) {
  return request({
    url: '/platform/struture/queryTaxPackageAll',
    method: 'get',
    params
  })
}

