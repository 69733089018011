<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="商户">
          <el-input v-model="formData.companySName" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="平台服务商">
          <el-select v-model="formData.platformServiceProvider" style="width: 100%" placeholder="平台服务商" clearable
            filterable>
            <el-option v-for="i in serviceList" :key="i.id" :label="i.taxName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker v-model="formData.applicationDateOne" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"
            style="width: 160px;" />
        </el-form-item>
        <el-form-item>至</el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.applicationDateTwo" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"
            style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
          <el-button type="primary" :loading="btnLoading" @click="exportExcel">导出</el-button>
        </el-form-item>
      </el-form>
      <!-- <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">合并开票</el-button> -->
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table ref="multipleTable" row-key="invoiceApplicationId" v-loading="loading" :data="list" tooltip-effect="dark"
        style="width: 100%" @selection-change="handleSelectionChange">
        <!-- <el-table-column :selectable="canCheck" type="selection" width="55" /> -->
        <el-table-column type="selection" reserve-selection width="55" />
        <el-table-column prop="companySName" label="商户" width="150" show-overflow-tooltip align="center" />
        <el-table-column prop="platformServiceProvider" label="平台服务商" align="center" width="200" show-overflow-tooltip />
        <!-- <el-table-column prop="workerMoney" label="支付总金额(元)" width="100" align="center" show-overflow-tooltip /> -->
        <el-table-column label="支付总金额(元)" align="center" width="200">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.payVo" :key="k">{{ $u.formatMoney(i.realMoney) }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付编号" width="200" align="center">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.payVo" :key="k">{{ i.pid }}</div>
          </template>
        </el-table-column>
        <el-table-column label="支付清单" align="center">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.payVo" :key="k">
              <file-icon v-if="i.paymentTnventory" :url="i.paymentTnventory" />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="总包支付回单" align="center">
          <template slot-scope="scope">
            <div v-for="(i, k) in scope.row.payVo" :key="k">
              <file-icon v-if="i.subpackagePayment" :url="i.subpackagePayment" />
              <span v-else>--</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceCatalogAmount" label="开票总金额(元)" width="100" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $u.formatMoney(scope.row.invoiceCatalogAmount) }}
          </template>
        </el-table-column>
        <el-table-column label="商户申请" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.isInvoice, $enum.invoiceStatus2(), true)" />
          </template>
        </el-table-column>
        <el-table-column prop="applicationDesc" label="开票申请备注说明" width="250" align="center" show-overflow-tooltip />
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.applicationState, $enum.applicationState(), true)" />
          </template>
        </el-table-column>
        <el-table-column prop="applicationDate" label="申请时间" align="center" width="180" />
        <el-table-column label="操作" width="120" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" @click="handleEditor(scope.row)">开票</el-button>
            <!-- <el-button type="text" @click="handleDelete(scope.row)">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
import { getWaitList, deleteTax } from '@/api/totalTax'
import { getServiceList } from '@/api/common'

export default {
  name: 'TotalTaxWait',
  data() {
    return {
      multipleSelection: [],
      statusContent: [
        { value: 0, name: '未申请', type: 'danger' },
        { value: 1, name: '已申请', type: 'success' }
      ],
      loading: false,
      btnLoading: false,
      serviceList: [],
      formData: {
        platformServiceProvider: '',
        applicationDateOne: '',
        applicationDateTwo: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    console.log(this.$options.name + '总包待开票-----created')
    this.fetchData()
    this.fetchService()
  },
  activated() {
    console.log(this.$options.name + '总包待开票-----activated')
    // this.fetchData()
    // this.fetchService()
  },
  methods: {
    handleDelete(i) {
      this.$confirm('你确定要删除吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteTax({ invoiceApplicationId: i.invoiceApplicationId }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.resetPage()
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => { })
    },
    handleEditor(item) {
      const id = item.invoiceApplicationId
      this.$router.push({
        path: '/tax/total/wait-editor',
        query: {
          id,
          type: 'add'
        }
      })
    },

    canCheck(row) {
      return parseInt(row.applicationState) !== 3
    },
    // 判断数组是否相同
    isAllEqual(array) {
      if (array.length > 0) {
        return !array.some((item, index) => {
          return (
            item.platformServiceProvider !== array[0].platformServiceProvider
          )
        })
      } else {
        return true
      }
    },
    // 多选按钮触发
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    fetchService() {
      getServiceList({
        packageStatus: 0
      }).then(res => {
        this.serviceList = res.data || []
      })
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getWaitList(Object.assign({}, this.formData, this.paramsData))
        .then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.loading = false
        })
        .catch(res => {
          this.loading = false
        })
    },
    handleAdd() {
      if (this.multipleSelection.length <= 1) {
        this.$errorModal('请选择多个代开清单!!')
        return
      }

      this.$confirm(
        '合并开票为多个待开票清单合并一个开票清单,您确定合并吗?',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        const arr = []
        this.multipleSelection.forEach(subItem => {
          subItem.list.forEach(item => {
            arr.push(item.id)
          })
        })
        const id = arr.join(',')
        this.$router.push({
          path: '/tax/total/wait-editor',
          query: {
            id,
            type: 'add'
          }
        })
      })
    },
    exportExcel() {
      if (this.multipleSelection.length) {
        this.btnLoading = true
        const tableData = [
          ['商户', '平台服务商', '申请时间', '支付编号', '开票金额']
        ]
        let rowData = []
        this.multipleSelection.forEach((item, index) => {
          let pid = item.payVo.reduce((pv, item) => {
            return `${item.pid},${pv}`
          }, '')
          rowData = [item.companySName, item.platformServiceProvider, item.applicationDate, pid, item.invoiceCatalogAmount]
          tableData.push(rowData)
        })
        const ws = XLSX.utils.aoa_to_sheet(tableData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, '待开票')
        XLSX.writeFile(wb, '待开票列表.xlsx')
        this.$refs.multipleTable.clearSelection()
        setTimeout(() => {
          this.btnLoading = false
        }, 1000);
      } else {
        this.$message.error('请先选中内容，后再点击导出')
      }
    }
  }
}
</script>

<style scoped></style>
