var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-avatar",
    {
      key: _vm.src + new Date(),
      ref: "myAvatar",
      staticClass: "my-avatar",
      attrs: { src: _vm.src, fit: "cover", size: _vm.size },
      on: { error: _vm.$returnTrue },
    },
    [_c("img", { attrs: { src: require("@/assets/avatar.png") } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }