<template>
  <div>
    <!-- <el-button type="primary" @click="handleUpload">上传合同</el-button> -->
    <el-table
      ref="multipleTable"
      v-loading="listLoading"
      :data="list"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column
        prop="id"
        label="合同编号"
        width="100"
        show-overflow-tooltip
        align="center"
      />
      <el-table-column label="合同名称" align="center" width="300">
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.agreementType, $enum.agreementTypeStatus())" />
        </template>
      </el-table-column>
      <el-table-column
        prop="partyAName"
        label="甲方"
        align="center"
      />
      <el-table-column label="合同" align="center">
        <template slot-scope="scope">
          <file-icon :url="scope.row.agreementUrl" />
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
      >
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.signState, $enum.signStatus())" />
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        width="180"
        label="完成时间"
        align="center"
      />
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleDownload(scope.row)">下载</el-button>
          <el-button type="text" size="small" @click="handleUpload(scope.row)">上传合同</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-box">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :current-page.sync="paramsData.pageNo"
        :page-size="paramsData.pageSize"
        :page-count="pageTotal"
        @current-change="fetchData"
      />
    </div>

    <el-dialog title="上传合同" :visible.sync="dialogUploadShow">
      <el-form ref="refAppoint" :loading="loading">
        <!-- <el-form-item label="合同类型" required>
          <el-select v-model="formData.type" placeholder="请选择">
            <el-option label="平台加盟合同" value="WORKER_JOIN_AGREEMENT" />
            <el-option label="服务商与创客承揽协议" value="TAX_WORKER_AGREEMENT" />
          </el-select>
        </el-form-item>
        <el-form-item label="服务商" prop="taxId">
          <el-select v-model="formData.taxId" placeholder="请选择">
            <el-option v-for="(item,i) in serviceList" :key="i" :label="item.taxName" :value="item.id" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="请上传合同" required>
          <button-upload v-model="formDataDialog.agreementUrl" type="image/pdf" text="上传(png/jpg/pdf)" show-preview />
          <div>注意：上传文件体积10mb以内</div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogUploadShow = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="submitDialog()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { queryWorkerTaxContract, uploadAgreement } from '@/api/worker'
import { getServiceList } from '@/api/common'
import ButtonUpload from '@/components/Upload/buttonUpload'

export default {
  name: 'PayList',
  components: { ButtonUpload },
  props: {
    workerId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      serviceList: [],
      dialogUploadShow: false,
      formDataDialog: {
        workerId: this.workerId,
        // agreementId: '',
        agreementUrl: '',
        // agreementType: ''
      },
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.listLoading = true
      queryWorkerTaxContract(Object.assign({}, {
        workerId: this.workerId
      }, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.listLoading = false
      }).catch(res => {
        this.listLoading = false
      })
    },
    handleDownload(i) {
      if (!this.$u.test.isEmpty(i.agreementUrl)) {
        const url = i.agreementUrl
        const index = url.lastIndexOf('/')
        const a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', url.substr(index + 1));
        a.click();
      }
    },
    fetchService() {
      getServiceList({
        packageStatus: 0
      }).then(res => {
        this.serviceList = res.data || []
      })
    },
    handleUpload(row) {
      if (this.serviceList.length === 0) {
        this.fetchService()
      }
      this.formDataDialog.agreementUrl = ''
      this.formDataDialog.agreementId = row.id
      this.dialogUploadShow = true
    },
    submitDialog() {
      if (!this.formDataDialog.agreementUrl) {
        return this.$message.error('请先上传合同')
      }
      this.loading = true
      uploadAgreement(this.formDataDialog).then(res => {
        this.loading = false
        this.$message.success('操作成功')
        this.dialogUploadShow = false
        this.fetchData(this.$route.query.id)
      }).catch(res => {
        this.loading = false
        this.dialogUploadShow = false
      })
    }
  }
}
</script>

<style scoped>

</style>
