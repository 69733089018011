<template>
  <div>
    <el-form ref="formData" :model="value" label-width="250px">
      <div class="coop-box u-m-l-60" :class="value._totalCheck ? '' : 'box-uncheck'">
        <el-checkbox v-model="value._totalCheck">合作类型：总包+分包（6%专票）</el-checkbox>
        <div class="u-flex u-col-top u-row-left u-flex-wrap">
          <div v-for="(item, k) in value._totalTax" :key="item.guid" class="u-m-t-30 u-p-r-20" style="width: 45%">
            <div class="u-border u-p-20" style="position: relative">
              <i class="el-icon-remove-outline text-danger u-font-18 u-point" style="position: absolute;right: 5px;top: 5px" @click="removeTotalList(k)" />
              <el-form-item label="平台服务商" required label-width="100px">
                <el-select v-model="item.taxId" placeholder="平台服务商" clearable filterable @change="changeTotalService(k, $event)">
                  <el-option v-for="serviceItem in totalServiceList" :key="serviceItem.id" :label="serviceItem.taxName" :value="serviceItem.id" />
                </el-select>
              </el-form-item>
              <template v-if="serviceTotalPackage[item.taxId]">
                <el-form-item label="综合税费率" required label-width="100px">
                  <el-radio-group v-model="item.chargeStatus">
                    <el-radio v-if="serviceTotalPackage[item.taxId].taxPrice" :label="0">一口价</el-radio>
                    <el-radio v-if="serviceTotalPackage[item.taxId].invoiceLadderPriceDetailVOList" :label="1">梯度价</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item v-if="item.chargeStatus === 0" label-width="100px">
                  <el-input
                    :key="item.taxId"
                    v-model.number="item.serviceCharge"
                    type="number"
                    :min="0"
                    @blur="checkItem(item, serviceTotalPackage[item.taxId].taxPrice || 0, $event)"
                  >
                    <span slot="suffix" style="line-height: 40px">%</span>
                  </el-input>
                  <div class="u-tips">参考值(不低于)：{{ serviceTotalPackage[item.taxId] ? (serviceTotalPackage[item.taxId].taxPrice || 0) : 0 }}%</div>
                </el-form-item>
                <el-form-item v-else label-width="100px">
                  <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                    <div class="u-flex-2">单人单月额度</div>
                    <div class="u-flex-1">服务费率</div>
                    <div style="width: 50px" />
                  </div>
                  <div v-if="item.addCompanyLadderServiceDtoList">
                    <div v-for="(i, j) in item.addCompanyLadderServiceDtoList" :key="i.guid" class="u-m-t-10">
                      <div class="u-flex u-col-center u-row-center">
                        <div class="u-flex-2 u-flex u-col-center u-row-center">
                          <el-input v-model="i.startMoney" readonly placeholder="请输入">
                            <span slot="suffix" style="line-height: 40px">元</span>
                          </el-input>
                          <span class="u-p-lf-5">至</span>
                          <el-input v-model="i.endMoney" readonly placeholder="请输入">
                            <span slot="suffix" style="line-height: 40px">元</span>
                          </el-input>
                        </div>
                        <div class="u-flex-1 u-p-l-10">
                          <el-input
                            v-model="i.serviceCharge"
                            placeholder="请输入"
                            @blur="checkItem(i, serviceTotalPackage[item.taxId].invoiceLadderPriceDetailVOList[j].rate || 0, $event)"
                          >
                            <span slot="suffix" style="line-height: 40px">%</span>
                          </el-input>
                        </div>
                      </div>
                      <div class="u-tips u-text-right">参考值(不低于)：
                        {{ serviceTotalPackage[item.taxId].invoiceLadderPriceDetailVOList[j].rate || 0 }}%
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </template>
              <el-form-item label="合作合同" required label-width="100px">
                <div class="u-flex u-col-center">
                  <button-upload v-model="item.contract" type="image/pdf" text="上传 jpg/pdf" />
                  <el-button v-if="!$u.test.isEmpty(item.contract)" class="u-m-l-10" type="text" @click="$preViewer(item.contract)">查看</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="u-point" style="display: inline-block;margin-top: 100px" @click="addTotalList">
            <i class="el-icon-circle-plus-outline u-font-30" />
            <div class="u-m-t-5">添加</div>
          </div>
        </div>
      </div>
      <div class="coop-box u-m-l-60 u-m-t-40" :class="value._crowdCheck ? '' : 'box-uncheck'">
        <el-checkbox v-model="value._crowdCheck">合作类型：众包(3%普票)</el-checkbox>
        <div class="u-flex u-col-top u-row-left u-flex-wrap">
          <div v-for="(item, k) in value._crowdTax" :key="item.guid" class="u-m-t-30 u-p-r-20" style="width: 45%">
            <div class="u-border u-p-20" style="position: relative">
              <i class="el-icon-remove-outline text-danger u-font-18 u-point" style="position: absolute;right: 5px;top: 5px" @click="removeCrowdList(k)" />
              <el-form-item label="平台服务商" required label-width="100px">
                <el-select v-model="item.taxId" placeholder="平台服务商" clearable filterable @change="changeCrowdService(k, $event)">
                  <el-option v-for="serviceItem in crowdServiceList" :key="serviceItem.id" :label="serviceItem.taxName" :value="serviceItem.id" />
                </el-select>
              </el-form-item>
              <template v-if="serviceCrowdPackage[item.taxId]">
                <el-form-item label="综合税费率" required label-width="100px">
                  <el-radio-group v-model="item.chargeStatus">
                    <el-radio v-if="serviceCrowdPackage[item.taxId].taxPrice" :label="0">一口价</el-radio>
                    <el-radio v-if="serviceCrowdPackage[item.taxId].invoiceLadderPriceDetailVOList" :label="1">梯度价</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item v-if="item.chargeStatus === 0" label-width="100px">
                  <el-input
                    v-model.number="item.serviceCharge"
                    type="number"
                    min="0"
                    @blur="checkItem(item, serviceCrowdPackage[item.taxId].taxPrice || 0, $event)"
                  >
                    <span slot="suffix" style="line-height: 40px">%</span>
                  </el-input>
                  <div class="u-tips">参考值(不低于)：{{ serviceCrowdPackage[item.taxId] ? (serviceCrowdPackage[item.taxId].taxPrice || 0) : 0 }}%</div>
                </el-form-item>
                <el-form-item v-else label-width="100px">
                  <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                    <div class="u-flex-2">单人单月额度</div>
                    <div class="u-flex-1">服务费率</div>
                    <div style="width: 50px" />
                  </div>
                  <div v-if="item.addCompanyLadderServiceDtoList">
                    <div v-for="(i, j) in item.addCompanyLadderServiceDtoList" :key="i.guid" class="u-m-t-10">
                      <div class="u-flex u-col-center u-row-center u-m-t-10">
                        <div class="u-flex-2 u-flex u-col-center u-row-center">
                          <el-input v-model="i.startMoney" readonly placeholder="请输入">
                            <span slot="suffix" style="line-height: 40px">元</span>
                          </el-input>
                          <span class="u-p-lf-5">至</span>
                          <el-input v-model="i.endMoney" readonly placeholder="请输入">
                            <span slot="suffix" style="line-height: 40px">元</span>
                          </el-input>
                        </div>
                        <div class="u-flex-1 u-p-l-10">
                          <el-input
                            v-model="i.serviceCharge"
                            placeholder="请输入"
                            @blur="checkItem(i, serviceCrowdPackage[item.taxId].invoiceLadderPriceDetailVOList[j].rate || 0, $event)"
                          >
                            <span slot="suffix" style="line-height: 40px">%</span>
                          </el-input>
                        </div>
                      </div>
                      <div class="u-tips u-text-right">参考值(不低于)：
                        {{ serviceCrowdPackage[item.taxId].invoiceLadderPriceDetailVOList[j].rate || 0 }}%
                      </div>
                    </div>
                  </div>
                </el-form-item>
              </template>
              <el-form-item label="合作合同" required label-width="100px">
                <div class="u-flex u-col-center">
                  <button-upload v-model="item.contract" type="image/pdf" text="上传 jpg/pdf" />
                  <el-button v-if="!$u.test.isEmpty(item.contract)" class="u-m-l-10" type="text" @click="$preViewer(item.contract)">查看</el-button>
                </div>
              </el-form-item>
            </div>
          </div>
          <div class="u-point" style="display: inline-block;margin-top: 100px" @click="addCrowdList">
            <i class="el-icon-circle-plus-outline u-font-30" />
            <div class="u-m-t-5">添加</div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import { getServiceList, getServicePackage } from '@/api/shop'
import { getGuid } from '@/utils'
import ButtonUpload from '@/components/Upload/buttonUpload'

export default {
  components: { ButtonUpload },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      totalServiceList: [], // 总包平台服务商
      crowdServiceList: [], // 众包平台服务商
      serviceTotalPackage: {}, // 服务商可用合作信息
      serviceCrowdPackage: {} // 服务商可用合作信息
    }
  },
  created() {
    this.fetchService()
  },
  methods: {
    fetchService() {
      getServiceList({
        packageStatus: 0
      }).then(res => {
        this.totalServiceList = res.data || []
      })
      getServiceList({
        packageStatus: 1
      }).then(res => {
        this.crowdServiceList = res.data || []
      })
    },
    // 验证总包数据
    checkItem(item, validata, e) {
      validata = validata || 0
      if (!this.$u.test.validateRange(item.serviceCharge, validata + '~')) {
        e.target.style.borderColor = '#f56c6c'
        return this.$message.error('输入数据需不低于' + validata)
      } else {
        e.target.style.borderColor = '#DCDFE6'
      }
    },
    // 删除总包合作模块
    removeTotalList(index) {
      this.value._totalTax.splice(index, 1)
    },
    // 删除众包合作模块
    removeCrowdList(index) {
      this.value._crowdTax.splice(index, 1)
    },
    addTotalList(edit, val) {
      this.value._totalCheck = true
      const addCompanyLadderServiceDtoList = []
      if (edit === true) {
        val = val || {}
        if (val.companyLadderServiceVos) {
          val.companyLadderServiceVos.forEach(x => {
            addCompanyLadderServiceDtoList.push(this.addLadderItem(x))
          })
        }
      } else {
        val = {}
        addCompanyLadderServiceDtoList.push(this.addLadderItem())
      }
      this.value._totalTax.push({
        guid: getGuid(),
        'addCompanyLadderServiceDtoList': addCompanyLadderServiceDtoList,
        id: val.id || '',
        'chargeStatus': val.chargeStatus ? parseInt(val.chargeStatus) : 0,
        'agentId': val.agentId || '',
        'contract': val.contract || '',
        'packageStatus': val.packageStatus || 0,
        'serviceCharge': val.serviceCharge || 0,
        'taxId': val.taxId
      })
    },
    addCrowdList(edit, val) {
      this.value._crowdCheck = true
      const addCompanyLadderServiceDtoList = []
      if (edit === true) {
        val = val || {}
        if (val.companyLadderServiceVos) {
          val.companyLadderServiceVos.forEach(x => {
            addCompanyLadderServiceDtoList.push(this.addLadderItem(x))
          })
        }
      } else {
        val = {}
        addCompanyLadderServiceDtoList.push(this.addLadderItem())
      }
      this.value._crowdTax.push({
        guid: getGuid(),
        'addCompanyLadderServiceDtoList': addCompanyLadderServiceDtoList,
        id: val.id || '',
        'chargeStatus': val.chargeStatus ? parseInt(val.chargeStatus) : 0,
        'agentId': val.agentId || '',
        'contract': val.contract || '',
        'packageStatus': val.packageStatus || 1,
        'serviceCharge': val.serviceCharge || 0,
        'taxId': val.taxId || ''
      })
    },
    addLadderItem(val) {
      val = val || {}
      return {
        guid: getGuid(),
        'agentTaxId': undefined,
        id: val.companyLadderServiceId || '',
        'endMoney': val.endMoney || null,
        'serviceCharge': val.serviceCharge || null,
        'startMoney': val.startMoney || 0
      }
    },
    /**
     * 手动调用
     * 用于编辑时获取需要的服务商信息
     * 此接口为异步接口，需要过滤重复taxId后遍历调用
     * @param packageStatus {number} 0总包 1众包
     * @param taxId 服务商id
     **/
    initService(packageStatus, taxId) {
      getServicePackage({
        taxId: taxId,
        packageStatus: packageStatus
      }).then(res => {
        if (packageStatus === 0) {
          this.serviceTotalPackage = Object.assign({}, this.serviceTotalPackage, { [taxId]: res.obj || {}})
        } else {
          this.serviceCrowdPackage = Object.assign({}, this.serviceCrowdPackage, { [taxId]: res.obj || {}})
        }
      }).catch(res => {
      })
    },
    changeTotalService(index, val) {
      // 获取服务商合作信息
      this._setTotalServicePackage(val, index)
    },
    async _setTotalServicePackage(taxId, index) {
      if (this.$u.test.isEmpty(taxId)) {
        return
      }
      if (!this.serviceTotalPackage[taxId]) {
        await getServicePackage({
          taxId: taxId,
          packageStatus: 0
        }).then(res => {
          this.serviceTotalPackage = Object.assign({}, this.serviceTotalPackage, { [taxId]: res.obj || {}})
        }).catch(res => {
        })
      }
      const list = this.serviceTotalPackage[taxId].invoiceLadderPriceDetailVOList || []
      const totalList = []
      list.map(val => {
        totalList.push(this.addLadderItem({
          'endMoney': val.endMoney || null,
          'serviceCharge': val.rate || null,
          'startMoney': val.startMoney || 0
        }))
      })
      this.value._totalTax[index].addCompanyLadderServiceDtoList = totalList
    },
    changeCrowdService(index, val) {
      this._setCrowdServicePackage(val, index)
    },
    async _setCrowdServicePackage(taxId, index) {
      if (this.$u.test.isEmpty(taxId)) {
        return
      }
      if (!this.serviceCrowdPackage[taxId]) {
        await getServicePackage({
          taxId: taxId,
          packageStatus: 1
        }).then(res => {
          this.serviceCrowdPackage = Object.assign({}, this.serviceCrowdPackage, { [taxId]: res.obj || {}})
        }).catch(res => {
        })
      }
      const list = this.serviceCrowdPackage[taxId].invoiceLadderPriceDetailVOList || []
      const crowdList = []
      list.map(val => {
        crowdList.push(this.addLadderItem({
          'endMoney': val.endMoney || null,
          'serviceCharge': val.rate || null,
          'startMoney': val.startMoney || 0
        }))
      })
      this.value._crowdTax[index].addCompanyLadderServiceDtoList = crowdList
    },
    // 梯度价/一口价检测
    validateLadders(list) {
      let result = true
      if (!list.length) {
        return {
          success: false,
          data: []
        }
      }
      const error = {
        msg: '数据错误',
        index: null
      }
      const newData = []
      for (let i = 0, len = list.length; i < len; i++) {
        const listItem = list[i]
        if (this.$u.test.isEmpty(listItem.taxId)) {
          error.msg = '请选择平台服务商'
          error.index = i
          result = false
          break
        }
        if (this.$u.test.isEmpty(listItem.contract)) {
          error.msg = '请上传合作合同'
          error.index = i
          result = false
          break
        }
        const newItem = [] // 用于存放梯度价
        if (parseInt(listItem.chargeStatus) === 1) {
          const ladders = listItem.addCompanyLadderServiceDtoList || []
          // 梯度价检测
          if (!ladders.length) {
            result = false
            break
          }
          for (let j = 0, jlen = ladders.length; j < jlen; j++) {
            const item = ladders[j]
            if (!this.$u.test.validateRange(item.serviceCharge, '0~100')) {
              result = false
              break
            }
            if (!this.$u.test.validateRange(item.startMoney, '0~')) {
              result = false
              break
            }
            item.startMoney = parseFloat(item.startMoney)
            if (!this.$u.test.validateRange(item.endMoney, item.startMoney + '~')) {
              result = false
              break
            }
            item.endMoney = parseFloat(item.endMoney)
            item.serviceCharge = parseFloat(item.serviceCharge)
            newItem.push(item)
          }
          if (!result) {
            error.msg = '梯度价不正确'
            error.index = i
            break
          }
          // 不设置一口价
          listItem.serviceCharge = ''
        } else {
          // 一口价检测
          if (!this.$u.test.validateRange(listItem.serviceCharge, '0~100')) {
            error.msg = '一口价不正确'
            error.index = i
            result = false
            break
          }
          listItem.serviceCharge = parseFloat(listItem.serviceCharge)
        }

        listItem.addCompanyLadderServiceDtoList = newItem
        newData.push(listItem)
      }
      return {
        success: result,
        data: newData,
        error: error
      }
    },
    /**
     * 获取最终结果，false 则验证失败
     * @returns {boolean|[]}
     */
    getData() {
      let form = []
      if (this.value._totalCheck) {
        const totalValidate = this.validateLadders(this.value._totalTax)
        if (!totalValidate.success) {
          this.$message.error('总包' + totalValidate.error.msg + '！')
          return false
        }
        form = form.concat(totalValidate.data)
      }
      if (this.value._crowdCheck) {
        const crowdValidate = this.validateLadders(this.value._crowdTax)
        if (!crowdValidate.success) {
          this.$message.error('众包' + crowdValidate.error.msg + '！')
          return false
        }
        form = form.concat(crowdValidate.data)
      }
      return form
    }
  }
}
</script>

<style scoped lang="scss">
.box-uncheck {
  opacity: .3;
}
.u-tips {
  line-height: 2;
  font-size: 12px;
  color: #909399;
}
.error-line {
  ::v-deep {
    .el-input__inner {
      border-color: #f56c6c;
    }
  }
}
</style>
