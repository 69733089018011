import request from '@/utils/request'
// 监管接口
/**
 * @returns {AxiosPromise}
 */
export function getList(data) {
  return request({
    url: '/platform/regulator/getRegulatorQuery',
    method: 'post',
    data
  })
}
/**
 * 详情
 * @returns {AxiosPromise}
 */
export function getDetail(params) {
  return request({
    url: '/platform/regulator/getByRegulatorId',
    method: 'post',
    params
  })
}
/**
 * 更新
 * @returns {AxiosPromise}
 */
export function add(data) {
  return request({
    url: '/platform/regulator/addRegulator',
    method: 'post',
    data
  })
}
/**
 * 更新
 * @returns {AxiosPromise}
 */
export function edit(data) {
  return request({
    url: '/platform/regulator/updateRegulator',
    method: 'post',
    data
  })
}
/**
 * 统计
 * @returns {AxiosPromise}
 */
export function count(params) {
  return request({
    url: '/platform/regulator/getRegulatorPaymentCount',
    method: 'post',
    params
  })
}
/**
 * 获取服务商列表
 * @returns {AxiosPromise}
 */
export function getServiceList(params) {
  return request({
    url: '/platform/regulator/getTaxAll',
    method: 'get',
    params
  })
}

/**
 * 获取服务商列表
 * @returns {AxiosPromise}
 */
export function getRelatedService(params) {
  return request({
    url: '/platform/regulator/getRegulatorTaxAll',
    method: 'post',
    params
  })
}

/**
 * 编辑服务商监管状态
 * @param params
 * @returns {AxiosPromise}
 */
export function updateServiceStatus(params) {
  return request({
    url: '/platform/regulator/updateRegulatorTaxStatus',
    method: 'post',
    params
  })
}
/**
 * 关联服务商
 * @param data
 * @returns {AxiosPromise}
 */
export function relatedService(data) {
  return request({
    url: '/platform/regulator/addRegulatorTax',
    method: 'post',
    data
  })
}

/**
 * 流水
 * @returns {AxiosPromise}
 */
export function getPayList(params) {
  return request({
    url: '/platform/regulator/getRegulatorTaxPaymentList',
    method: 'post',
    params
  })
}
