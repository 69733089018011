<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-tabs v-model="activeName">
        <el-tab-pane label="创客黑名单" name="WORKER" />
        <el-tab-pane label="商户黑名单" name="MERCHANT" />
      </el-tabs>
      <worker-list v-if="activeName === 'WORKER'" />
      <shop-list v-if="activeName === 'MERCHANT'" />
    </div>
  </div>
</template>

<script>
import WorkerList from './workerList'
import ShopList from './shopList'

export default {
  components: { WorkerList, ShopList },
  data() {
    return {
      activeName: 'WORKER'
    }
  },
  created() {
    // this.fetchData()
  },
  methods: {
    // resetPage() {
    // }
  }
}
</script>

<style scoped>

</style>
