<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-card v-if="(+baseInfo.paymentOrderStatus) === 5" style="border: 1px solid #FF1A2E" class="u-m-b-10">
      <div class="u-flex u-col-center u-p-lf-40">
        <div class="u-text-center">
          <i class="el-icon-error" style="font-size: 72px;color: #FF1A2E" />
          <div class="u-m-t-10" style="font-size: 24px;">被驳回</div>
        </div>
        <div class="u-m-l-20 u-p-l-40 u-flex" style="width: 100%">
          <div class="u-flex-1">
            <div class="u-tips-color u-font-14 u-m-l-20">请核对并修改以下信息后，再重新提交。</div>
            <div style="background-color: #fafafa" class="u-p-20 u-m-t-10">
              <div class="u-font-16 u-m-b-10">您提交的内容有如下问题：</div>
              <div class="u-font-14" style="color: #8e9193">{{ baseInfo.reasonsForRejection }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-loading="loading">
      <div class="card--title">总包+分包信息</div>
      <div class="card--content">
        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">支付编号：</span>
            <span class="value">{{ baseInfo.paymentOrderId }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">商户：</span>
            <span class="value">{{ baseInfo.companyName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">平台服务商：</span>
            <span class="value">{{ baseInfo.platformServiceProvider }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">项目合同：</span>
            <span class="value">
              <file-icon :url="baseInfo.companyContract" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付清单：</span>
            <span class="value">
              <file-icon :url="baseInfo.paymentInventory" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">关联任务：</span>
            <span class="value">
              <el-button v-if="!$u.test.isEmpty(baseInfo.taskId)" type="text" @click="showTask(baseInfo.taskId)">查看
              </el-button>
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">交付支付验收单：</span>
            <span class="value">
              <file-icon :url="baseInfo.acceptanceCertificate" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">状态：</span>
            <span class="value"
              v-html="$options.filters.filterContent(baseInfo.paymentOrderStatus, $enum.payTotalAndSubStatus())" />
          </el-col>
          <el-col :md="8">
            <span class="label">创建时间：</span>
            <span class="value">{{ baseInfo.creatDate }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付时间：</span>
            <span class="value">{{ baseInfo.paymentDate }}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card v-loading="loading" class="u-m-t-20">
      <div class="card--title">支付清单明细</div>
      <div style="padding:20px 10px">
        <el-form ref="elForm" :inline="true" :model="formData" size="small">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="formData.name" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input v-model="formData.mobile" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="身份证号" prop="idCode">
            <el-input v-model="formData.idCode" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="支付状态" prop="orderStatus">
            <el-select v-model="formData.orderStatus" style="width: 100%" placeholder="请选择" clearable filterable>
              <el-option label="支付失败" value="-1" />
              <el-option label="未支付" value="0" />
              <el-option label="支付中" value="4" />
              <el-option label="支付成功" value="1" />
              <el-option label="已关闭" value="2" />
              <!-- <el-option label="校验不通过" value="11" />
              <el-option label="校验通过" value="12" /> -->
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getPaymentInventoryList('reset')">查询</el-button>
            <el-button @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="card--content">
        <div style="display: flex;justify-content: end;align-items: center;">
          <span>风控校验中 {{ progressInfo.countInProgress || 0 }} 人，风控校验成功 {{ progressInfo.countSuccess || 0 }} 人，风控校验失败 {{
            progressInfo.countFailed || 0 }} 人</span>
          <el-button v-if="progressInfo.countFailed" class="u-m-l-10" type="primary" size="small" @click="downloadErrList"
            :loading="errLoading">下载校验错误清单</el-button>
        </div>
        <pay-list :list="list" :is-edit="isEdit" :base-info="baseInfo" />
        <div v-if="baseInfo.companyMoney" class="u-m-t-10">合计（商户总支出）：￥{{ $u.formatMoney(baseInfo.realMoney) }}</div>
      </div>
    </el-card>
    <el-card v-if="show" class="u-m-t-20">
      <div class="card--title">总包支付信息</div>
      <div class="card--content">
        <div class="card--info">
          <template v-if="isEdit">
            <div class="card--info-item">
              <span class="label">支付方式：</span>
              <span class="value">
                <el-radio-group v-model="totalForm.payType">
                  <el-radio :label="1" :disabled="!totalPayType.length">银联支付</el-radio>
                  <el-radio :label="'alipay'">支付宝</el-radio>
                  <el-radio :label="'alipayUnionPay'">支付宝银联联动支付</el-radio>
                  <el-radio :label="'zsPay'">招行</el-radio>
                  <el-radio :label="0">线下支付</el-radio>
                </el-radio-group>
              </span>
            </div>
            <template v-if="totalForm.payType === 1">
              <div class="card--info-item">
                <span class="label"><span class="text-danger">*</span>选择银行：</span>
                <span class="value">
                  <el-radio-group v-model="totalForm.paymentMode" @change="fetchBalance">
                    <el-radio v-for="(i, k) in totalPayType" :key="k" :label="i.mode">{{ i.name }}</el-radio>
                  </el-radio-group>
                </span>
              </div>
              <div v-if="unionPay.length" class="u-flex u-col-top u-row-left u-flex-wrap u-m-b-10">
                <union-info v-for="(i, k) in unionPay" :key="k + Date.now()" :info="i" />
              </div>
            </template>
          </template>
          <template v-else>
            <div class="card--info-item">
              <span class="label">支付方式：</span>
              <span class="value">
                {{ baseInfo.paymentMode | allUnionpayBankType }}
              </span>
            </div>
          </template>

          <table v-if="totalForm.payType === 0" class="c-table is-edit">
            <tr>
              <td>
                <div class="card--info-item">
                  <span class="label">支付方：</span>
                  <span class="value">{{ baseInfo.companyName }}</span>
                </div>
                <div class="card--info-item">
                  <span class="label">开户行：</span>
                  <span class="value">{{ baseInfo.bankName }}</span>
                </div>
                <div class="card--info-item">
                  <span class="label">银行账号：</span>
                  <span class="value">{{ baseInfo.bankCode }}</span>
                </div>
              </td>
              <td>
                <div class="card--info-item">
                  <span class="label">收款方：</span>
                  <span class="value">{{ baseInfo.payee }}</span>
                </div>
                <div class="card--info-item">
                  <span class="label">开户行：</span>
                  <span class="value">{{ baseInfo.payeeBankName }}</span>
                </div>
                <div class="card--info-item">
                  <span class="label">银行账号：</span>
                  <span class="value">{{ baseInfo.payeeBankCode }}</span>
                </div>
              </td>
            </tr>
            <tr :class="isEdit ? '' : 'no-border'">
              <td colspan="2">
                <div class="blue-box" :class="isEdit ? 'is-edit' : 'is-view'">转账金额：<span class="money">￥
                    {{ $u.formatMoney(baseInfo.realMoney) }}</span>
                  <span>
                    （
                    <span>发放金额:￥{{ $u.formatMoney(baseInfo.workerMoney) }} </span>
                    <span class="u-m-l-10">服务费:￥{{ $u.formatMoney(baseInfo.serviceMoney) }}</span>
                    ）
                  </span>
                </div>
              </td>
            </tr>
          </table>
          <template v-if="isEdit">
            <div v-if="totalForm.payType === 0" class="card--info-item u-m-t-20">
              <span class="label"><span class="text-danger">*</span>支付回单：</span>
              <span class="value">
                <span class="u-flex u-col-center">
                  <button-upload type="image/pdf" text="上传支付回单(pdf/jpg)"
                    @upload-success="setTotalField('turnkeyProjectPayment', $event)" />
                  <el-button v-if="!$u.test.isEmpty(totalForm.turnkeyProjectPayment)" class="u-m-l-10" type="text"
                    @click="$preViewer(totalForm.turnkeyProjectPayment)">查看</el-button>
                </span>
              </span>
            </div>
            <div v-else class="blue-box is-view">转账金额：<span class="money">￥ {{ $u.formatMoney(baseInfo.realMoney)
            }}</span>
              <span>
                （
                <span>发放金额:￥{{ $u.formatMoney(baseInfo.workerMoney) }} </span>
                <span class="u-m-l-10">服务费:￥{{ $u.formatMoney(baseInfo.serviceMoney) }}</span>
                ）
              </span>
            </div>
            <template v-if="totalForm.payType !== 0">
              <div class="card--info-item">
                <span class="label">手机号：</span>
                <span class="value">{{ baseInfo.loginMobile }}</span>
              </div>
              <div class="card--info-item">
                <span class="label"><span class="text-danger">*</span>验证码：</span>
                <span class="value">
                  <el-input v-model="totalForm.checkCode" placeholder="请输入">
                    <el-button slot="append" type="default"
                      :style="{ background: (disabled ? 'inherit' : '#fff'), color: (disabled ? 'inherit' : '#000') }"
                      :disabled="disabled" @click="getCode">{{ btnText }}</el-button>
                  </el-input>
                </span>
              </div>
              <div class="card--info-item">
                <span class="label"><span class="text-danger">*</span>支付密码：</span>
                <span class="value">
                  <el-input v-model="totalForm.payPwd" show-password placeholder="请输入" />
                </span>
              </div>
            </template>
          </template>
          <div v-else-if="totalForm.payType === 0" class="card--info-item">
            <span class="label">支付回单：</span>
            <span class="value">
              <file-icon :url="baseInfo.turnkeyProjectPayment" btn />
            </span>
          </div>
          <!--    线上查看模式      -->
          <template v-else-if="!isEdit">
            <div v-if="unionPay.length" class="u-flex u-col-top u-row-left u-flex-wrap u-m-b-10">
              <union-info v-for="(i, k) in unionPay" :key="k + Date.now()" :show-balance="false" :info="i" />
            </div>
            <div class="blue-box is-view">转账金额：<span class="money">￥ {{ $u.formatMoney(baseInfo.realMoney) }}</span>
              <span>
                （
                <span>发放金额:￥{{ $u.formatMoney(baseInfo.workerMoney) }} </span>
                <span class="u-m-l-10">服务费:￥{{ $u.formatMoney(baseInfo.serviceMoney) }}</span>
                ）
              </span>
            </div>
          </template>
        </div>
      </div>
    </el-card>
    <el-card v-if="[6].indexOf((+baseInfo.paymentOrderStatus)) !== -1" class="u-m-t-20">
      <div class="card--title">分包支付信息</div>
      <div class="card--content">
        <div v-if="totalForm.payType === 0" class="card--info">
          <table class="c-table is-edit">
            <tr>
              <td>
                <div class="card--info-item">
                  <span class="label">支付方：</span>
                  <span class="value">{{ baseInfo.payee }}</span>
                </div>
                <div class="card--info-item">
                  <span class="label">开户行：</span>
                  <span class="value">{{ baseInfo.payeeBankName }}</span>
                </div>
                <div class="card--info-item">
                  <span class="label">银行账号：</span>
                  <span class="value">{{ baseInfo.payeeBankCode }}</span>
                </div>
              </td>
              <td>
                <div class="card--info-item">
                  <span class="label">收款方：</span>
                  <span class="value">
                    <el-button v-if="!$u.test.isEmpty(baseInfo.paymentInventory)" class="u-m-l-10" type="text"
                      @click="$preViewer(baseInfo.paymentInventory)">查看</el-button>
                  </span>
                </div>
              </td>
            </tr>
            <tr :class="isEdit ? '' : 'no-border'">
              <td colspan="2">
                <div class="blue-box" :class="isEdit ? 'is-edit' : 'is-view'">转账金额：<span class="money">￥
                    {{ $u.formatMoney(baseInfo.workerMoney) }}</span></div>
              </td>
            </tr>
          </table>
          <div class="card--info-item">
            <span class="label">支付回单：</span>
            <span class="value">
              <file-icon :url="baseInfo.subpackagePayment" btn />
            </span>
          </div>
        </div>
        <template v-else>
          <div v-if="unionPay.length" class="u-flex u-col-top u-row-left u-flex-wrap u-m-b-10">
            <el-card v-for="(info, k) in unionPay" :key="k + Date.now()" shadow="never" style="width: 43%"
              :body-style="{ padding: 0 }">
              <div slot="header" class="clearfix u-text-center">
                银行类型: {{ info.unionpayBankType | unionpayBankType }}
              </div>
              <div class="u-flex u-col-top u-row-between">
                <div class="u-flex-1 u-padding-10 u-border-right">
                  <div class="u-m-b-10">子账号户名：{{ info.subAccountName }}</div>
                  <div v-if="info.unionpayBankType === 'SJBK'" class="u-m-b-10">来款银行账号：{{ info.inBankNo }}</div>
                  <div class="u-m-b-10">子账户账号：{{ info.subAccountCode }}</div>
                </div>
                <div class="u-flex-1 u-padding-10">
                  <div class="u-m-b-10">收款方：
                    <el-button v-if="!$u.test.isEmpty(baseInfo.paymentInventory)" class="u-m-l-10" type="text"
                      @click="$preViewer(baseInfo.paymentInventory)">查看</el-button>
                  </div>
                </div>
              </div>
            </el-card>
          </div>
          <div class="blue-box is-view">转账金额：<span class="money">￥ {{ $u.formatMoney(baseInfo.workerMoney) }}</span></div>
        </template>
      </div>
    </el-card>
    <div class="wrap-fixed-footer btn--box">
      <el-button v-if="isEdit" :loading="submitLoading" size="small" type="primary" @click="submit">提交</el-button>
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog title="查看关联任务" :visible.sync="visibleDialog" width="800px">
      <task-detail ref="taskDetail" />
    </el-dialog>
  </div>
</template>

<script>
import {
  getTotalAndSubDetail,
  payTotal,
  getTotalUnionBalance,
  getPaymentInventoryList
} from '@/api/pay'
import TaskDetail from '@/views/task/components/taskDetail'
import ButtonUpload from '@/components/Upload/buttonUpload'
import {
  getSMSCode
} from '@/api/common'
import PayList from '@/views/pay/components/payList'
import UnionInfo from '@/components/UnionInfo'
const btnText = '获取验证码'

export default {
  components: {
    UnionInfo,
    PayList,
    ButtonUpload,
    TaskDetail
  },
  data() {
    return {
      disabled: false,
      btnText: btnText,
      isEdit: false,
      id: undefined,
      visibleDialog: false,
      loading: false,
      submitLoading: false,
      baseInfo: {},
      progressInfo: {},
      list: [],
      unionPay: [], // 银联余额
      totalForm: {
        turnkeyProjectPayment: '',
        checkCode: '',
        payPwd: '',
        paymentMode: 0, // 银联支付方式
        payType: 'alipay' // 支付方式
      },
      totalPayType: [], // 总包支付方式
      subForm: {
        subpackagePayment: '',
        payType: 1
      },
      show: false,
      paramsData: {
        pageNo: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      formData: {
        name: '',
        mobile: '',
        idCode: '',
        orderStatus: '',
      },
    }
  },
  created() {
    this.id = this.$route.query.id
    this.fetchData()
    this.getPaymentInventoryList()
    this.isEdit = this.$route.meta.isEdit // 编辑
  },
  methods: {
    setTotalField(field, val) {
      this.totalForm[field] = val
    },
    fetchData() {
      console.log('===============================================>')
      this.loading = true
      getTotalAndSubDetail({
        isPayment: 1, // 是否商户端支付 0 否 1 是
        id: this.id
      }).then(res => {
        const data = res.obj || {}

        if (data.paymentOrderInfoPO.taxStatus > 1) {
          this.show = true
        }

        if (this.$route.meta.title === '支付') {
          this.show = true
        }

        this.baseInfo = data.paymentOrderInfoPO || {}
        this.progressInfo = data.progressInfo || {}
        const paymentMode = +this.baseInfo.paymentMode
        this.baseInfo.paymentMode = paymentMode
        if (paymentMode !== 0) {
          // if (paymentMode === 1) {
          //   this.totalForm.payType = 'alipay'
          // } else if (paymentMode === 6) {
          //   this.totalForm.payType = 'alipayUnionPay'
          // } else {
          //   this.totalForm.payType = 1
          //   this.fetchBalance(paymentMode)
          // }
          // this.totalForm.paymentMode = paymentMode
          if ([2, 3, 4, 5].includes(paymentMode)) {
            this.fetchBalance(paymentMode)
          }
          this.totalForm.payType = paymentMode
          this.totalForm.paymentMode = paymentMode
        }
        const companyUnionpayBankTypeList = this.baseInfo.companyUnionpayBankTypeList || []
        const totalPayType = []
        const allType = this.$enum.unionpayBankType()
        companyUnionpayBankTypeList.map(val => {
          allType.every(item => {
            if (item.value === val) {
              totalPayType.push(item)
              return false
            }
            return true
          })
        })
        this.totalPayType = totalPayType
        // this.list = data.paymentInventories || []
        // if (this.totalForm.paymentMode === 0 && totalPayType.length) {
        //   // 若未设置。则默认选择第一个银行
        //   this.fetchBalance(totalPayType[0].mode)
        //   this.totalForm.paymentMode = totalPayType[0].mode
        // }
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    async getPaymentInventoryList(type) {
      if (type == 'reset') {
        this.paramsData = {
          pageNo: 1,
          pageSize: 10,
        }
        this.pageTotal = 0
      }
      this.tbLoading = true
      const res = await getPaymentInventoryList({
        orderId: this.id,
        ...this.paramsData,
        ...this.formData
      })
      this.list = res.data || [];
      this.pageTotal = res.pageCount
      this.tbLoading = false
    },
    reset() {
      this.$refs.elForm.resetFields()
      this.getPaymentInventoryList()
    },
    fetchBalance(val) {
      if (this.$u.test.isEmpty(val)) {
        this.unionPay = []
      } else {
        const unionpayBankTypeList = this.$enum.unionpayBankType()
        let unionpayBankType = ''
        unionpayBankTypeList.every(item => {
          if (item.mode === val) {
            unionpayBankType = item.value
            return false
          }
          return true
        })
        getTotalUnionBalance({
          merchantId: this.baseInfo.merchantId,
          taxId: this.baseInfo.taxId,
          unionpayBankType: unionpayBankType
        }).then(res => {
          this.unionPay = res.data || []
        }).catch(res => {
          this.unionPay = []
        })
      }
    },
    doLoop: function (seconds) {
      seconds = seconds || 60
      this.btnText = seconds + 's后获取'
      const countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = btnText
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    },
    getCode() {
      const mobile = this.baseInfo.loginMobile || ''
      if (!this.$u.test.mobile(mobile)) {
        return this.$message.error('手机号不正确')
      }
      this.disabled = true
      getSMSCode({
        codeType: 'UPDATEPASSWORD',
        merchantId: this.baseInfo.merchantId,
        mobileCode: mobile
      }).then(res => {
        this.$message.success('发送短信成功')
        setTimeout(() => {
          this.doLoop(60)
        }, 500)
      }).catch(res => {
        this.disabled = false
      })
    },
    // 总包支付
    async submit() {
      const form = Object.assign({}, this.totalForm)
      if (form.payType === 0) {
        // 线下支付
        if (this.$u.test.isEmpty(form.turnkeyProjectPayment)) {
          return this.$message.error('请上传总包支付回单')
        }
        form.paymentMode = 0
      } else {
        if (form.payType === 'alipay') {
          form.paymentMode = 1 // 设置为支付宝
        } else if (form.payType === 'alipayUnionPay') {
          form.paymentMode = 6 // 设置为支付宝银联联动支付
        } else if (form.payType === 'zsPay') {
          form.paymentMode = 7
        }
        if (this.$u.test.isEmpty(form.paymentMode)) {
          return this.$message.error('请选择银行')
        }
        if (this.$u.test.isEmpty(form.checkCode)) {
          return this.$message.error('请输入验证码')
        }
        if (this.$u.test.isEmpty(form.payPwd)) {
          return this.$message.error('请输入支付密码')
        }
      }
      this.submitLoading = true
      const success = await payTotal(Object.assign(form, {
        paymentOrderId: this.id
      })).then(res => {
        return true
      }).catch(res => {
        return false
      })
      if (!success) {
        this.submitLoading = false
        return
      }
      this.submitLoading = false
      if (success) {
        this.$message.success('操作成功')
        this.$router.go(-1)
      }
    },
    showTask(taskId) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.taskDetail.fetchData(taskId)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.is-edit {
  &.c-table {
    border-spacing: 0;
    border-collapse: collapse;

    td {
      min-width: 300px;
      padding: 8px;
      vertical-align: top;
      border: 1px solid #ddd;
    }
  }

  .no-border {
    td {
      border: 1px solid #fff !important;
    }
  }
}

.is-view {
  &.c-table {
    border-spacing: 0;
    border-collapse: collapse;

    td {
      min-width: 300px;
      padding: 0;
      vertical-align: top;
      border: 0;
    }
  }

  &.blue-box {
    margin-bottom: 20px;
    max-width: 700px;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);

    .money {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }
}
</style>
