<template>
  <div class="u-m-25 u-p-30 bg-white">
    <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
      <el-form-item label="图片">
        <div class="u-flex u-col-center">
          <button-upload type="image" text="上传图片" @upload-success="setField('picture', $event)" />
          <el-button v-if="!$u.test.isEmpty(formData.picture)" class="u-m-l-10" type="text" @click="$preViewer(formData.picture)">查看</el-button>
        </div>
      </el-form-item>
      <el-form-item label="排序" prop="serialNumber">
        <el-input v-model.number="formData.serialNumber" type="number" min="0" step="1" placeholder="请输入" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formData')">确定</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getBannerDetail as getDetail, updateBanner as update } from '@/api/applet'
import ButtonUpload from '@/components/Upload/buttonUpload'

const defaultForm = () => {
  return {
    'id': '',
    'picture': '',
    'serialNumber': 0
  }
}

export default {
  components: { ButtonUpload },
  data() {
    const DefaultForm = defaultForm()
    return {
      loading: false,
      formData: Object.assign({}, DefaultForm),
      salesMan: [],
      rules: {
        serialNumber: [
          { required: true, message: '请输入排序', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.fetchData(this.$route.query.id)
    }
  },
  methods: {
    setField(field, file) {
      this.formData[field] = file
    },
    fetchData(id) {
      this.loading = true
      getDetail({
        id: id
      }).then(res => {
        const data = res.obj || {}
        const DefaultForm = defaultForm()
        this.formData = Object.assign({}, DefaultForm, data)
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          if (this.$u.test.isEmpty(form.picture)) {
            return this.$message.error('请上传图片')
          }
          this.loading = true
          update(form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$router.go(-1)
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
