var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-p-25" }, [
    _c(
      "div",
      { staticClass: "search-box" },
      [
        _c(
          "el-form",
          {
            staticClass: "u-border-bottom",
            attrs: { inline: true, model: _vm.formData, size: "small" },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "时间" } },
              [
                _c("el-date-picker", {
                  attrs: {
                    type: "month",
                    placeholder: "请选择",
                    "value-format": "yyyy-MM",
                  },
                  model: {
                    value: _vm.formData.time,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "time", $$v)
                    },
                    expression: "formData.time",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              [
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.resetPage } },
                  [_vm._v("查询")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "u-flex u-col-center u-row-left" }, [
          _c("div", { staticClass: "u-p-20" }, [
            _c("div", { staticClass: "u-tips-color u-font-14" }, [
              _vm._v("代理商总人数"),
            ]),
            _c("div", { staticClass: "u-font-22 u-m-t-10" }, [
              _vm._v(_vm._s(_vm.count.num || 0)),
            ]),
          ]),
          _c("div", { staticClass: "u-p-20" }, [
            _c("div", { staticClass: "u-tips-color u-font-14" }, [
              _vm._v("差额提成"),
            ]),
            _c("div", { staticClass: "u-font-22 u-m-t-10" }, [
              _vm._v(
                "¥ " +
                  _vm._s(
                    _vm.$u.formatMoney(_vm.count.totalMerchantDifference) || 0
                  ) +
                  " / " +
                  _vm._s(
                    _vm.$u.formatMoney(_vm.count.totalAgentDifference) || 0
                  )
              ),
            ]),
          ]),
          _c("div", { staticClass: "u-p-20" }, [
            _c("div", { staticClass: "u-tips-color u-font-14" }, [
              _vm._v("提成总额"),
            ]),
            _c("div", { staticClass: "u-font-22 u-m-t-10" }, [
              _vm._v(
                "¥ " +
                  _vm._s(_vm.$u.formatMoney(_vm.count.totalCommission) || 0)
              ),
            ]),
          ]),
        ]),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "u-p-lf-10 bg-white u-m-t-20" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "c-table",
            staticStyle: { width: "100%" },
            attrs: { data: _vm.list, "tooltip-effect": "dark" },
          },
          [
            _c("el-table-column", {
              attrs: { prop: "realName", label: "代理商", align: "center" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "merchantCount",
                label: "直属商户",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: { label: "商户交易额", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$u.test.isEmptyNotZero(
                              scope.row.merchantBusiness
                            )
                              ? "￥" +
                                  _vm.$u.formatMoney(scope.row.merchantBusiness)
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "代理交易额", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$u.test.isEmptyNotZero(scope.row.agentBusiness)
                              ? "￥" +
                                  _vm.$u.formatMoney(scope.row.agentBusiness)
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                width: "180",
                label: "差额提成\n（直属商户）",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$u.test.isEmptyNotZero(
                              scope.row.merchantDifference
                            )
                              ? "￥" +
                                  _vm.$u.formatMoney(
                                    scope.row.merchantDifference
                                  )
                              : "-"
                          ) +
                          " / "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "提成总额", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            !_vm.$u.test.isEmptyNotZero(
                              scope.row.totalCommission
                            )
                              ? "￥" +
                                  _vm.$u.formatMoney(scope.row.totalCommission)
                              : "-"
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "结算状态", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.filterContent(
                              scope.row.settlementState,
                              _vm.$enum.settlementState(),
                              true
                            )
                          ),
                        },
                      }),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "时间", width: "150", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [_vm._v(" " + _vm._s(scope.row.createDate) + " ")]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { width: "120", label: "操作", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      +scope.row.settlementState === 0
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "text", size: "small" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleSettle(scope.row)
                                },
                              },
                            },
                            [_vm._v("结算")]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text", size: "small" },
                          on: {
                            click: function ($event) {
                              return _vm.handleShow(scope.row)
                            },
                          },
                        },
                        [_vm._v("查看")]
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "pager-box" },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "prev, pager, next, jumper",
                "current-page": _vm.paramsData.pageNo,
                "page-size": _vm.paramsData.pageSize,
                "page-count": _vm.pageTotal,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.paramsData, "pageNo", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.paramsData, "pageNo", $event)
                },
                "current-change": _vm.fetchData,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }