const state = {
  isUpdatePage: false // 用来判断是否刷新页面
}

const mutations = {
  setUpdatePage(state, isUpdatePage) {
    state.isUpdatePage = isUpdatePage
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
