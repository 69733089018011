import request from '@/utils/request';
// 服务商商接口
/**
 * @returns {AxiosPromise}
 */
export function getList(data) {
  return request({
    url: '/platform/tax/getTaxList',
    method: 'post',
    data,
  });
}
/**
 * 详情
 * @returns {AxiosPromise}
 */
export function getDetail(params) {
  return request({
    url: '/platform/tax/getTaxInfo',
    method: 'get',
    params,
  });
}
/**
 * 更新
 * @returns {AxiosPromise}
 */
export function update(data) {
  return request({
    url: '/platform/tax/saveTax',
    method: 'post',
    data,
  });
}
/**
 * 更新合作信息
 * @returns {AxiosPromise}
 */
export function updateTaxPackage(data) {
  return request({
    url: '/platform/tax/updateTaxPackage',
    method: 'post',
    data,
  });
}
/**
 * 修改状态
 * @returns {AxiosPromise}
 */
export function changeStatus(params) {
  return request({
    url: '/platform/tax/updateTaxStatus',
    method: 'post',
    params,
  });
}
/**
 * 统计
 * @returns {AxiosPromise}
 */
export function count(params) {
  return request({
    url: '/platform/tax/transactionRecordCount',
    method: 'post',
    params,
  });
}

/**
 * 添加开票类目
 * @param data
 * @returns {AxiosPromise}
 */
export function addTaxType(data) {
  return request({
    url: '/platform/tax/saveCatalog',
    method: 'post',
    data,
  });
}

/**
 * 查询服务商银联
 * @param params
 * @returns {AxiosPromise}
 */
export function getUnionPayList(params) {
  return request({
    url: '/platform/tax/queryTaxUnionpayList',
    method: 'get',
    params,
  });
}
/**
 * 查询服务商支付宝
 * @param params
 * @returns {AxiosPromise}
 */
export function getTaxAlipayList(params) {
  return request({
    url: '/platform/tax/queryTaxAlipayList',
    method: 'get',
    params,
  });
}
/**
 * 更新银联账号
 * @param data
 */
export function updateUnionPay(data) {
  return request({
    url: '/platform/tax/addOrUpdateTaxUnionpay',
    method: 'post',
    data,
  });
}
/**
 * 添加或修改服务商支付宝设置
 * @param data
 */
export function updateTaxAlipay(data) {
  return request({
    url: '/platform/tax/addOrUpdateTaxAlipay',
    method: 'post',
    data,
  });
}

/**
 * 查看银联列表
 */
export function getUnionList(params) {
  return request({
    url: '/platform/tax/queryTaxUnionpayList',
    method: 'get',
    params,
  });
}
/**
 * 查看银联余额
 */
export function getUnionBalance(params) {
  return request({
    url: '/platform/tax/queryTaxUnionpayBalance',
    method: 'get',
    params,
  });
}

/**
 * 开启或关闭服务商银联
 * @param params
 */
export function toggleUnionPayStatus(params) {
  return request({
    url: '/platform/tax/boolEnableTaxUnionpay',
    method: 'post',
    params,
  });
}
/**
 * 开启或关闭服务商支付宝
 * @param params
 */
export function toggleAliPayStatus(params) {
  return request({
    url: '/platform/tax/boolEnableTaxAlipay',
    method: 'post',
    params,
  });
}

/**
 * 查询服务商拥有的支付方式
 * @param params
 */
export function getUnionPayType(params) {
  return request({
    url: '/platform/merchant/queryTaxUnionpayMethod',
    method: 'get',
    params,
  });
}

/**
 * 查询服务商拥有的阿里支付方式
 * @param params
 */
export function getAlipayMethod(params) {
  return request({
    url: '/platform/merchant/queryTaxAlipayMethod',
    method: 'get',
    params,
  });
}

/**
 * 获取合作商户
 */
export function getCooperationShop(params) {
  return request({
    url: '/platform/tax/queryTaxTransactionFlow',
    method: 'post',
    params,
  });
}

/**
 * 获取合作服务商流水统计
 * @param params
 * @returns {AxiosPromise}
 */
export function getCoopServiceCount(params) {
  return request({
    url: '/platform/tax/taxMerchantInfoPaas',
    method: 'post',
    params,
  });
}
/**
 * 获取合作服务商流水列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getCoopShopPayList(params) {
  return request({
    url: '/platform/tax/transactionRecord',
    method: 'post',
    params,
  });
}
/**
 * 获取交易记录
 * @param params
 * @returns {AxiosPromise}
 */
export function getPayHistoryList(params) {
  return request({
    url: '/platform/tax/queryPaymentHistoryList',
    method: 'get',
    params,
  });
}

/**
 * 获取关联商户银联列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getShopUnionList(params) {
  return request({
    url: '/platform/tax/queryTaxUnionpayCompanyUnionpayList',
    method: 'get',
    params,
  });
}

/**
 * 服务商清分操作
 * @param params
 * @returns {AxiosPromise}
 */
export function clarify(params) {
  return request({
    url: '/platform/tax/clarify',
    method: 'post',
    params,
  });
}

/**
 * 银联对账文件下载
 */
export function downloadBillFile(params) {
  return (
    process.env.VUE_APP_BASE_API +
    '/platform/tax/downloadTaxPlatformReconciliationFile'
  );
  // return request({
  //   url: '/platform/tax/downloadTaxPlatformReconciliationFile',
  //   method: 'get',
  //   params
  // })
}

/**
 * 查询支付宝授权列表
 */
export function getAlipayList(params) {
  return request({
    url: '/platform/tax/queryAlipaySign',
    method: 'get',
    params,
  });
}
/**
 * 查询支付宝授权页面连接
 * @param params
 * @param isNew {boolean} 无数据时为true
 */
export function getAlipayAgreement(params, isNew) {
  return request({
    url: isNew
      ? '/platform/tax/createAlipaySign'
      : '/platform/tax/getAlipayUserAgreementPageSign',
    method: isNew ? 'post' : 'get',
    params,
  });
}
/**
 * 支付宝解约
 * @param params
 * @returns {AxiosPromise}
 */
export function alipayUnSign(params) {
  return request({
    url: '/platform/tax/alipayUserAgreementUnsign',
    method: 'get',
    params,
  });
}
/**
 * 查询支付宝余额
 */
export function getAlipayBalance(params) {
  return request({
    url: '/platform/tax/queryAccountbookBalance',
    method: 'get',
    params,
  });
}

/**
 * 获取服务商手机号
 * @param params
 * @returns {AxiosPromise}
 */
export function getServicePhone(params) {
  return request({
    url: '/platform/tax/queryMainTaxloginMobile',
    method: 'get',
    params,
  });
}
/**
 * 银联提现
 * @param data
 * @returns {AxiosPromise}
 */
export function withdrawByUnionPay(data) {
  return request({
    url: '/platform/tax/uninopayWithdrawal',
    method: 'post',
    data,
  });
}

/**
 * 支付宝提现
 * @param data
 * @returns {AxiosPromise}
 */
export function withdrawByAliPay(data) {
  return request({
    url: '/platform/tax/alipayWithdrawal',
    method: 'post',
    data,
  });
}

/**
 * 查询支付宝银联联动授权列表
 */
export function getAlipayUnionPayList(params) {
  return request({
    url: '/platform/tax/queryAlipayUnionpaySign',
    method: 'get',
    params,
  });
}
/**
 * 查询支付宝银联联动授权页面连接
 * @param data
 * @param params
 */
export function toAlipayUnionpaySignSERVICE(data, params) {
  return request({
    url: '/platform/tax/toAlipayUnionpaySignSERVICE',
    method: 'post',
    data,
    params,
  });
}
/**
 * 查询支付宝银联联动授权页面连接-通用子账户
 * @param data
 * @param params
 */
export function toAlipayUnionpaySignCOMMON(data, params) {
  return request({
    url: '/platform/tax/toAlipayUnionpaySignCOMMON',
    method: 'post',
    data,
    params,
  });
}
/**
 * 创建银联支付宝标识
 * @param params
 */
export function creatAUSign(params) {
  return request({
    url: '/platform/tax/creatAUSign',
    method: 'post',
    params,
  });
}

/**
 * 银联支付宝-服务商手续费子账户退款
 * @param data
 * @returns {AxiosPromise}
 */
export function feeSubAccountRefund(params) {
  return request({
    url: '/platform/tax/feeSubAccountRefund',
    method: 'post',
    params,
  });
}

/**
 * 支付宝解约
 * @param params
 * @returns {AxiosPromise}
 */
// export function alipayUnSign(params) {
//   return request({
//     url: '/platform/tax/alipayUserAgreementUnsign',
//     method: 'get',
//     params
//   })
// }
/**
 * 查询支付宝银联联动余额
 */
export function getAlipayUnionPayBalance(params) {
  return request({
    url: '/platform/tax/queryAlipayUnionpayBalance',
    method: 'get',
    params,
  });
}
/**
 * 支付宝银联联动提现
 * @param data
 * @returns {AxiosPromise}
 */
export function withdrawByAlipayUnionPay(data) {
  return request({
    url: '/platform/tax/alipayUnionpayWithdrawal',
    method: 'post',
    data,
  });
}

/**
 * 招商---服务商查询招行信息
 * @param params
 * @returns {AxiosPromise}
 */
export function queryTaxZS(params) {
  return request({
    url: '/platform/tax/queryTaxZS',
    method: 'get',
    params,
  });
}
/**
 * 招商---查询服务商是否拥有招行支付--创建商户时查询
 * @param params
 * @returns {AxiosPromise}
 */
export function queryTaxZspayMethod(params) {
  return request({
    url: '/platform/tax/queryTaxZspayMethod',
    method: 'get',
    params,
  });
}
/**
 * 招商---添加或修改服务商招行
 * @param data
 */
export function saveTaxZSBalance(data) {
  return request({
    url: '/platform/tax/saveTaxZSBalance',
    method: 'post',
    data,
  });
}

/**
 * 招商---查询服务商招行余额
 */
export function queryTaxZSBalance(params) {
  return request({
    url: '/platform/tax/queryTaxZSBalance',
    method: 'get',
    params,
  });
}
/**
 * 开启或关闭服务商招行
 * @param params
 */
export function updateBoolEnable(params) {
  return request({
    url: '/platform/tax/updateBoolEnable',
    method: 'post',
    params,
  });
}
/**
 * 招商---服务商招行提现
 * @param data
 * @returns {AxiosPromise}
 */
export function serviceZsWithdrawal(data) {
  return request({
    url: '/platform/tax/serviceZsWithdrawal',
    method: 'post',
    data,
  });
}
/**
 * 查询服务商支付宝余额
 */
export function queryTaxAlipayBalance(params) {
  return request({
    url: '/tax/paymentMethod/queryTaxAlipayBalance',
    method: 'get',
    params,
  });
}
