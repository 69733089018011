var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.$u.test.isEmpty(_vm.url)
    ? _c(
        "div",
        { staticStyle: { display: "inline-block" } },
        [
          _vm.img
            ? [
                _c("el-image", {
                  style: _vm.imgStyle,
                  attrs: { src: _vm.url },
                  on: { click: _vm.show },
                }),
              ]
            : _vm.btn
            ? [
                _c(
                  "el-button",
                  {
                    attrs: { type: "text", size: "small" },
                    on: { click: _vm.show },
                  },
                  [_vm._v(_vm._s(_vm.btnText))]
                ),
              ]
            : [
                _vm.type === "pdf"
                  ? _c("svg-icon", {
                      attrs: { "class-name": "pdf", "icon-class": "pdf" },
                      on: { click: _vm.show },
                    })
                  : _vm.type === "img"
                  ? _c("svg-icon", {
                      attrs: { "class-name": "img", "icon-class": "img" },
                      on: { click: _vm.show },
                    })
                  : _vm.type === "excel"
                  ? _c("svg-icon", {
                      attrs: { "class-name": "excel", "icon-class": "excel" },
                      on: { click: _vm.show },
                    })
                  : _vm.type === "video"
                  ? _c("i", {
                      staticClass: "el-icon-video-camera",
                      on: { click: _vm.show },
                    })
                  : _vm.type === "file"
                  ? _c("i", {
                      staticClass: "el-icon-tickets",
                      on: { click: _vm.show },
                    })
                  : _vm._e(),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }