<template>
  <div>
    <div class="u-text-right u-m-b-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">
        添加监管服务商
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="list"
      tooltip-effect="dark"
      style="width: 100%"
    >
      <el-table-column
        prop="taxName"
        label="服务商名称"
        align="center"
      />
      <el-table-column
        label="总包+分包交易流水"
        align="center"
      >
        <template slot-scope="scope">
          ￥{{ scope.row.totalTab || 0 }}
        </template>
      </el-table-column>
      <el-table-column
        label="众包交易流水"
        align="center"
      >
        <template slot-scope="scope">
          ￥{{ scope.row.manyTba || 0 }}
        </template>
      </el-table-column>
      <el-table-column
        label="状态"
        align="center"
      >
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.status, $enum.openStatus(), true)" />
        </template>
      </el-table-column>
      <el-table-column
        label="开始监管时间"
        align="center"
      >
        <template slot-scope="scope">
          {{ scope.row.startRegulatorDate }}
        </template>
      </el-table-column>
      <el-table-column
        width="150"
        label="操作"
        align="center"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
          <el-button type="text" size="small" @click="handleToggle(scope.row)">{{ scope.row.status === 0 ? '关闭' : '开启' }}</el-button>
          <el-button type="text" size="small" @click="handleCancel(scope.row)">撤销</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-box">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :current-page.sync="paramsData.pageNo"
        :page-size="paramsData.pageSize"
        :page-count="pageTotal"
        @current-change="fetchData"
      />
    </div>
    <el-dialog title="查看关联任务" :visible.sync="visibleDialog" width="800px">
      <check-service-list ref="checkTable" :regulator-id="regulatorId" @success="onSuccess" @close="visibleDialog = false" />
    </el-dialog>
  </div>
</template>

<script>
import { getRelatedService, updateServiceStatus } from '@/api/supervision'
import CheckServiceList from '@/views/supervision/components/checkServiceList'

export default {
  name: 'ServiceList',
  components: { CheckServiceList },
  props: {
    regulatorId: {
      type: [Number, String],
      required: true
    },
    regulatorName: {
      type: [String],
      default: ''
    }
  },
  data() {
    return {
      visibleDialog: false,
      loading: false,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getRelatedService(Object.assign({}, this.paramsData, {
        regulatorId: this.regulatorId
      })).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleShow(i) {
      this.$router.push({
        path: '/supervision/serviceDetail',
        query: {
          id: i.taxId,
          taxId: i.taxId,
          regulatorId: this.regulatorId,
          taxName: i.taxName,
          regulatorName: this.regulatorName
        }
      })
    },
    handleCancel(i) {
      const text = '撤销'
      this.$confirm('是否' + text + '该服务商?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        updateServiceStatus({
          taxId: i.taxId,
          regulatorId: this.regulatorId,
          status: 2
        }).then(res => {
          this.$message.success(text + '成功')
          this.fetchData()
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
      })
    },
    handleToggle(i) {
      const status = i.status
      const text = i.status === 0 ? '关闭' : '开启'
      this.$confirm('是否' + text + '该服务商?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        updateServiceStatus({
          regulatorId: this.regulatorId,
          taxId: i.taxId,
          status: status === 0 ? 1 : 0
        }).then(res => {
          this.$message.success(text + '成功')
          this.fetchData()
        }).catch(() => {
          this.loading = false
        })
      }).catch(() => {
      })
    },
    handleAdd() {
      this.visibleDialog = true
    },
    onSuccess() {
      this.visibleDialog = false
      this.resetPage()
    }
  }
}
</script>

<style scoped>

</style>
