<template>
  <div class="u-p-20">
    <el-card v-loading="loading" class="user-info-box u-m-t-10">
      <div class="title">个体户基础信息</div>
      <div class="info-item u-flex u-col-center">
        <div class="u-flex-1">
          <span class="">个体户编号：</span>
          <span class="u-tips-color">{{ item.id }}</span>
        </div>
        <div class="u-flex-1">
          <span class="">服务商：</span>
          <span class="u-tips-color">{{ item.serviceProviderName }}</span>
        </div>
        <div class="u-flex-1">
          <span class="">个体户名称：</span>
          <span class="u-tips-color">{{ $ibname(item.ibname, item.candidatedNames) }}</span>
        </div>
      </div>
      <div class="info-item u-flex u-col-center">
        <div class="u-flex-1">
          <span class="">纳税人识别号：</span>
          <span class="u-tips-color">{{ item.ibtaxNo }}</span>
        </div>
        <div class="u-flex-1">
          <span class="">行业类型：</span>
          <span class="u-tips-color">{{ item.mainIndustry }}</span>
        </div>
        <div class="u-flex-1">
          <span class="">经营范围：</span>
          <span class="u-tips-color">{{ item.bizScope }}</span>
        </div>
      </div>
      <div class="info-item u-flex u-col-center">
        <div class="u-flex-1">
          <span class="">核定税种：</span>
          <span class="u-tips-color">{{ item.bizType | filterTax }}</span>
        </div>
        <div class="u-flex-1">
          <span class="">经营者：</span>
          <span class="u-tips-color">{{ item.bizName }}</span>
        </div>
        <div class="u-flex-1">
          <span class="">注册资金：</span>
          <span class="u-tips-color">{{ $u.formatMoney(item.registeredMoney) }} 万</span>
        </div>
      </div>
      <div class="info-item u-flex u-col-center">
        <div class="u-flex-1">
          <span class="">营业执照：</span>
          <span class="u-tips-color">
            <el-button v-if="!$u.test.isEmpty(item.businessLicenceUrl)" type="text" @click="$preViewer(item.businessLicenceUrl)">查看</el-button>
          </span>
        </div>
        <div class="u-flex-1">
          <span class="">状态：</span>
          <span class="u-tips-color">{{ item.ibstate | filterState }}</span>
        </div>
        <div class="u-flex-1">
          <span class="">注册时间：</span>
          <span class="u-tips-color">{{ item.createTime }}</span>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { getDetail } from '@/api/individualBusiness'
import { getPersonalTax, getIbState } from '@/utils/enum.js'

export default {
  filters: {
    filterTax(val) {
      if (val) {
        return getPersonalTax(val)
      }
      return ''
    },
    filterState(val) {
      if (val) {
        return getIbState(val)
      }
      return ''
    }
  },
  data() {
    return {
      loading: false,
      id: undefined,
      visibleDialog: false,
      status: undefined,
      item: {}
    }
  },
  computed: {
    isService() {
      return !!this.$route.meta.isService
    }
  },
  created() {
    this.id = this.$route.query.id
    this.status = this.$route.query.status
    this.$nextTick(() => {
      this.fetchData()
    })
  },
  methods: {
    fetchData() {
      this.loading = true
      getDetail({
        individualBusinessId: this.id
      }, this.isService).then(res => {
        this.loading = false
        this.item = res.obj || {}
        console.log(this.item,'this.item')
      }).catch(res => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.renzheng {
  width: 12px;
  height: 12px;
  color: #ffbd00;
}
.user-base-box {
  .name {
    margin-left: 20px;
    font-size: 20px;
    color: #333;
  }

  .tag {
    display: inline-block;
    margin-left: 2px;
    height: 20px;
    line-height: 16px;
    padding: 2px 5px;
    background: rgba(64, 108, 255, 1);
    border-radius: 25px;
    font-size: 9px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .sub {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }

  .value {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
  }
}
.title {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}
.user-info-box {
  font-size: 14px;

  .info-item {
    min-height: 40px;
    line-height: 1.6;
  }
}
</style>

