<template>
  <div class="u-p-25">
    <div class="u-p-lf-10 bg-white">
      <el-table
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="entryName"
          label="项目名称"
          align="center"
        />
        <el-table-column
          prop="content"
          label="内容"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="!$u.test.isEmpty(scope.row.content)">
              <el-image v-if="scope.row.type === 'IMG'" :src="scope.row.content" fit="cover" style="max-height: 100px" @click="$preViewer(scope.row.content)" />
              <div v-else>{{ scope.row.content }}</div>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createDate }}
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="handleView(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getOtherList as getList } from '@/api/applet'

export default {
  data() {
    return {
      loading: false,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getList(Object.assign({}, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleView(i) {
      this.$router.push({
        path: '/applet/other/view',
        query: {
          id: i.id,
          type: i.type
        }
      })
    },
    handleEdit(i) {
      this.$router.push({
        path: '/applet/other/edit',
        query: {
          id: i.id,
          type: i.type
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
