<template>
  <div class="u-m-25 wrap-fixed-content">
    <el-card header="基本信息">
      <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="formData.realName" placeholder="请输入姓名" />
        </el-form-item>
        <el-form-item label="登录账号" prop="userName">
          <el-input v-model="formData.userName" placeholder="请输入登录账号" />
        </el-form-item>
        <el-form-item label="手机号码" prop="mobileCode">
          <el-input v-model="formData.mobileCode" placeholder="请输入手机号" />
        </el-form-item>
        <el-form-item v-if="$u.test.isEmpty(formData.id)" label="初始密码" prop="initPassWord">
          <el-input v-model="formData.initPassWord" type="password" placeholder="请输入初始密码" />
        </el-form-item>
        <el-form-item v-else label="修改密码">
          <el-input v-model="formData.initPassWord" type="password" placeholder="为空则不修改" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassWord">
          <el-input v-model="formData.confirmPassWord" type="password" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="状态">
          <el-radio-group v-model="formData.status">
            <el-radio v-for="(i, k) in $enum.authStatus()" :key="k" :label="i.value">{{ i.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card v-loading="loading" header="佣金结算方式（可多选）" class="u-m-t-20">
      <commission-detail v-model="formData" />
    </el-card>
    <div class="wrap-fixed-footer btn--box">
      <el-button :loading="loading" size="small" type="primary" @click="submitForm('formData')">确定</el-button>
      <el-button size="small" @click="$router.go(-1)">取消</el-button>
    </div>
  </div>
</template>

<script>
import { getDetail, update, getTaxPackageAll } from '@/api/assistant'
import CommissionDetail from '@/views/organization/assistant/components/CommissionDetail'

const defaultForm = () => {
  return {
    'confirmPassWord': '',
    'id': '',
    'initPassWord': '',
    'mobileCode': '',
    'realName': '',
    'status': 0,
    'userName': '',
    'settlementMethod': '',
    'settlementMethodCalc': { waterCommission: false, serviceRateBalance: false },
    'agentCommissionProportion': [],
    'agentCommissionProportionStatus0': [],
    'agentCommissionProportionStatus1': [],
    'directCommissionProportion': [],
    'directCommissionProportionStatus0': [],
    'directCommissionProportionStatus1': [],
    'serviceRateBalanceList': []
  }
}

export default {
  components: { CommissionDetail },
  data() {
    const DefaultForm = defaultForm()
    return {
      loading: false,
      formData: Object.assign({}, DefaultForm),
      rules: {
        realName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        roleName: [
          { required: true, message: '请选择职位', trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        mobileCode: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.mobile(value)) {
                callback(new Error('手机号码不正确'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        initPassWord: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ],
        confirmPassWord: [
          { required: this.$u.test.isEmpty(this.$route.query.id), message: '请输入确认密码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    if (this.$route.query.id) {
      this.fetchData(this.$route.query.id)
    }
    this.getTaxPackageAll()
  },
  methods: {
    fetchData(id) {
      this.loading = true
      getDetail({
        managersId: id
      }).then(res => {
        const data = res.obj || {}
        const DefaultForm = defaultForm()
        this.formData = Object.assign({}, DefaultForm, {
          'id': id,
          'mobileCode': data.mobileCode,
          'realName': data.realName,
          'status': data.status,
          'userName': data.userName,
          'agentCommissionProportionStatus0': data.agentTotal || [],
          'agentCommissionProportionStatus1': data.agentMany || [],
          'directCommissionProportionStatus0': data.directCustomerTotal || [],
          'directCommissionProportionStatus1': data.directCustomerMany || []
        })
        if (data.settlementMethod === -1) {
          this.formData.settlementMethodCalc.waterCommission = false
          this.formData.settlementMethodCalc.serviceRateBalance = false
        } else if (data.settlementMethod === 0) {
          this.formData.settlementMethodCalc.waterCommission = true
          this.formData.settlementMethodCalc.serviceRateBalance = false
        } else if (data.settlementMethod === 1) {
          this.formData.settlementMethodCalc.waterCommission = false
          this.formData.settlementMethodCalc.serviceRateBalance = true
        } else {
          this.formData.settlementMethodCalc.waterCommission = true
          this.formData.settlementMethodCalc.serviceRateBalance = true
        }
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          if (form.initPassWord !== form.confirmPassWord) {
            return this.$message.error('两次密码不一致')
          }
          form.agentCommissionProportion = form.agentCommissionProportionStatus0.concat(form.agentCommissionProportionStatus1)
          form.directCommissionProportion = form.directCommissionProportionStatus0.concat(form.directCommissionProportionStatus1)
          if (!form.settlementMethodCalc.waterCommission && !form.settlementMethodCalc.serviceRateBalance) {
            form.settlementMethod = -1
          } else if (form.settlementMethodCalc.waterCommission && !form.settlementMethodCalc.serviceRateBalance) {
            form.settlementMethod = 0
          } else if (!form.settlementMethodCalc.waterCommission && form.settlementMethodCalc.serviceRateBalance) {
            form.settlementMethod = 1
          } else {
            form.settlementMethod = 2
          }
          this.loading = true
          update(form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$router.go(-1)
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    getTaxPackageAll() {
      this.loading = true
      getTaxPackageAll({}).then(res => {
        var serviceRateBalanceList = []
        var map = new Map()
        res.data.forEach((item, index) => {
          var obj = map.get(item.taxName)
          if (typeof (obj) === 'undefined') {
            if (item.packageStatus === 0) {
              obj = { 0: item }
              map.set(item.taxName, obj)
            } else {
              obj = { 1: item }
              map.set(item.taxName, obj)
            }
          } else {
            if (item.packageStatus === 0) {
              obj[0] = item
              map.set(item.taxName, obj)
            } else {
              obj[1] = item
              map.set(item.taxName, obj)
            }
          }
        })
        map.forEach(function(value, key) {
          var keyName = key
          serviceRateBalanceList.push({ taxName: keyName, taxItem: value })
        })
        this.formData.serviceRateBalanceList = serviceRateBalanceList
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
