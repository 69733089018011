<template>
  <div>
    <el-table
      v-loading="loading"
      :data="list"
      tooltip-effect="dark"
      style="width: 100%"
      row-key="id"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        reserve-selection
        type="selection"
        width="55"
      />
      <el-table-column
        prop="taxName"
        label="服务商名称"
        align="center"
      />
      <el-table-column
        label="状态"
        align="center"
      >
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.taxStatus, $enum.authStatus(), true)" />
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-box">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :current-page.sync="paramsData.pageNo"
        :page-size="paramsData.pageSize"
        :page-count="pageTotal"
        @current-change="fetchData"
      />
    </div>
    <div class="u-text-right u-p-20">
      <el-button size="small" @click="close">关闭</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>

<script>
import { getServiceList, relatedService } from '@/api/supervision'

export default {
  name: 'CheckServiceList',
  props: {
    regulatorId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      pageTotal: 0,
      multipleSelection: [],
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getServiceList(Object.assign({}, this.paramsData, {
        regulatorId: this.regulatorId
      })).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    submit() {
      const form = []
      this.multipleSelection.forEach(val => {
        form.push({
          'regulatorId': this.regulatorId,
          'status': 0,
          'taxId': val.id
        })
      })
      if (form.length) {
        this.loading = true
        relatedService(form).then(res => {
          this.loading = false
          this.multipleSelection = []
          this.$message.success('新增成功')
          this.resetPage()
          this.$emit('success')
        }).catch(res => {
          this.loading = false
        })
      } else {
        return this.$message.error('请选择服务商')
      }
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>

</style>
