import request from '@/utils/request';

/**
 * 获取短信验证码
 * @param params
 * @returns {AxiosPromise}
 */
export function getCode(params) {
  return request({
    url: '/platform/managers/senSMS',
    method: 'post',
    params,
  });
}

/**
 * 获取短信验证码
 * @param params
 * @returns {AxiosPromise}
 */
export function getSMSCode(params) {
  return request({
    url: '/platform/paymentOrder/senSMS',
    method: 'post',
    params,
  });
}

/**
 * 获取平台服务商
 * @returns {AxiosPromise}
 */
export function getServiceList() {
  return request({
    url: '/platform/tax/queryTaxPaasList',
    method: 'get',
  });
}

/**
 * 根据商户id获取服务商列表
 * @returns {AxiosPromise}
 */
export function getTaxListByMerchantId(params) {
  return request({
    url: '/platform/tax/getTaxListByMerchantId',
    method: 'get',
    params,
  });
}

/**
 * 获取开票类目
 * @returns {AxiosPromise}
 */
export function getTaxType() {
  return request({
    url: '/platform/tax/getCatalogAll',
    method: 'get',
  });
}

/**
 * 删除开票类目
 * @param params
 * @returns {AxiosPromise}
 */
export function delTaxType(params) {
  return request({
    url: '/platform/tax/deleteInvoiceCatalog\n',
    method: 'get',
    params,
  });
}

/**
 * 获取总包商户列表
 * @returns {AxiosPromise}
 */
export function getTotalShopList() {
  return request({
    url: '/platform/paymentOrder/findMerchant',
    method: 'post',
  });
}
/**
 * 获取分包商户列表
 * @returns {AxiosPromise}
 */
export function getCrowdShopList() {
  return request({
    url: '/platform/paymentOrderMany/findMerchant',
    method: 'post',
  });
}

/**
 * 获取业务员列表
 * @returns {AxiosPromise}
 */
export function getAssistantList() {
  return request({
    url: '/platform/struture/querySalesman',
    method: 'get',
  });
}
/**
 * 获取代理商列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getAgentList(params) {
  return request({
    url: '/platform/merchant/queryAgent',
    method: 'get',
    params,
  });
}
/**
 * 获取字典信息
 *  快递公司: express
 *  银行类型: bank
 * @returns {AxiosPromise}
 */
export function getDict(params) {
  return request({
    url: '/dict/queryDictValues',
    method: 'get',
    params,
  });
}
/**
 * 获取字典列表
 *  快递公司: express
 *  银行类型: bank
 * @returns {AxiosPromise}
 */
export function getDictList(params) {
  return request({
    url: '/dict/queryDictList',
    method: 'get',
    params,
  });
}

/**
 * 银行编码
 * @param params
 * @returns {AxiosPromise}
 */
export function getBankList(params) {
  return request({
    url: '/dict/queryDictValueAndKey',
    method: 'get',
    params,
  });
}

/**
 * 查询商户的快递地址信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getAddressAll(params) {
  return request({
    url: '/merchant/merchant/getAddressAll',
    method: 'get',
    params,
  });
}

/**
 * 添加或修改快递地址
 * @param params
 * @returns {AxiosPromise}
 */
export function addOrUpdataAddress(data) {
  return request({
    url: '/merchant/merchant/addOrUpdataAddress',
    method: 'post',
    data,
  });
}
