<template>
  <div class="wrap-fixed-content">
    <div class="bg-white u-flex u-row-right u-col-center u-p-lf-30 u-p-b-20 u-font-14">
      <div class="u-p-r-20">创客任务模板下载：<el-button type="success" size="small" icon="el-icon-download" @click="download">
        点击下载</el-button>
      </div>
    </div>
    <div class="u-m-25 u-p-30 bg-white wrap-fixed-content">
      <el-form
        ref="formData"
        v-loading="loading"
        :model="formData"
        :rules="rules"
        label-width="250px"
        style="max-width: 800px"
      >
        <el-form-item label="商户名称" prop="merchantId">
          <el-select
            v-model="formData.merchantId"
            filterable
            style="width: 100%"
            placeholder="请选择"
            @change="changeMerchant"
          >
            <el-option v-for="(i, k) in shop" :key="k" :label="i.companyName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="任务名称" prop="taskName">
          <el-input v-model="formData.taskName" placeholder="请输入任务名称" />
        </el-form-item>
        <el-form-item label="任务描述" prop="taskDesc">
          <el-input v-model="formData.taskDesc" placeholder="请输入任务描述" type="textarea" />
        </el-form-item>
        <el-form-item label="图文说明" prop="taskIllustration">
          <div class="u-flex u-col-center">
            <button-upload type="image" text="上传 jpg" @upload-success="setFields('taskIllustration', $event)" />
            <el-button
              v-if="!$u.test.isEmpty(formData.taskIllustration)"
              class="u-m-l-10"
              type="text"
              @click="$preViewer(formData.taskIllustration)"
            >查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="费用">
          <el-col :span="11">
            <el-form-item prop="taskCostMin">
              <el-input
                v-model.number="formData.taskCostMin"
                min="0"
                type="number"
                placeholder="最低"
                style="width: 100%;"
              >
                <span slot="suffix">元</span>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col class="u-text-center" :span="2">至</el-col>
          <el-col :span="11">
            <el-form-item prop="taskCostMax">
              <el-input
                v-model.number="formData.taskCostMax"
                min="0"
                type="number"
                placeholder="最高"
                style="width: 100%;"
              >
                <span slot="suffix">元</span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item label="创客所需技能" prop="taskSkill">
          <el-input v-model="formData.taskSkill" placeholder="请输入创客所需技能" />
        </el-form-item>
        <el-form-item label="任务上限人数" prop="upperLimit">
          <el-input v-model.number="formData.upperLimit" min="0" step="1" type="number" placeholder="请输入任务上限人数">
            <span slot="suffix">人</span>
          </el-input>
        </el-form-item>
        <el-form-item label="合作类型" prop="cooperateMode">
          <el-radio-group v-model="formData.cooperateMode">
            <el-radio v-for="(i, k) in cooperateModeList" :key="k" :label="i.value">{{ i.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="任务模式" prop="taskMode">
          <el-radio-group v-model="formData.taskMode">
            <el-radio v-for="(i, k) in taskModeList" :key="k" :label="i.value">{{ i.name }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="parseInt(formData.taskMode) !== 1">
          <div class="u-dashed-border u-point add-worker" @click="showWorkerDialog"><i
            class="el-icon-plus u-m-r-10"
          />添加指定派单创客</div>
          <button-upload
            :default-action="action"
            :success-toast="false"
            :error-toast="false"
            return-all
            name="workerTaskExcel"
            type="excel"
            text="上传创客(Excel)"
            class="upload-btn"
            @upload-success="uploadExcel($event)"
            @upload-error="showErrorModal"
          />
          <div class="u-p-t-10">
            <el-table height="250" :data="selectWorker" style="width: 100%">
              <el-table-column prop="accountName" label="创客姓名" />
              <el-table-column prop="mobileCode" label="创客手机号" width="180" />
              <el-table-column label="操作">
                <template slot-scope="scope">
                  <el-button type="text" size="small" @click="handleDel(scope.row, scope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-form-item>
      </el-form>
      <div class="wrap-fixed-footer bg-white u-p-lf-20 u-flex u-col-center u-row-right">
        <el-button type="primary" size="small" @click="submitForm('formData')">确定</el-button>
        <el-button size="small" @click="$router.go(-1)">取消</el-button>
      </div>
      <el-dialog title="选择创客" :visible.sync="dialogTableVisible" width="1200px">
        <worker-lookup v-if="dialogTableVisible" ref="workerLookup" :merchant-id="formData.merchantId" @on-select="onSelect" />
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  add,
  getImportWorkerTask
} from '@/api/task'
import WorkerLookup from '@/views/task/components/workerLookup'
import {
  getAllList
} from '@/api/shop'
import ButtonUpload from '@/components/Upload/buttonUpload'

export default {
  name: 'TaskAdd',
  components: {
    ButtonUpload,
    WorkerLookup
  },
  data() {
    const validateNumber = (rule, value, callback) => {
      if (this.$u.test.isEmpty(value) || (typeof value === 'number' && value >= 0)) {
        callback()
      } else {
        callback(new Error('请输入大于等于0的数字'))
      }
    }
    return {
      // action: '/platform/file/uploadWorkerTask',
      dialogTableVisible: false,
      loading: false,
      selectObj: {},
      selectWorker: [],
      cooperateModeList: this.$enum.cooperateMode(),
      taskModeList: this.$enum.taskMode(),
      shop: [],
      formData: {
        'cooperateMode': 0,
        'deadlineDate': '',
        'deadlineTime': '',
        'id': '',
        'industryType': '',
        'makerIds': '',
        'merchantId': '',
        'releaseDate': '',
        'releaseTime': '',
        'state': 0,
        'taskCode': '',
        'taskCostMax': null,
        'taskCostMin': null,
        'taskDesc': '',
        'taskMode': 0,
        'taskName': '',
        'taskSkill': '',
        'upperLimit': null,
        'paymentInventory': '',
        taskIllustration: ''
      },
      rules: {
        merchantId: [{
          required: true,
          message: '请选择商户',
          trigger: ['change', 'blur']
        }],
        taskName: [{
          required: true,
          message: '请输入任务名称',
          trigger: ['change', 'blur']
        }],
        taskDesc: [{
          required: true,
          message: '请输入任务描述',
          trigger: ['change', 'blur']
        }],
        taskCostMin: [{
          validator: validateNumber,
          trigger: ['change', 'blur']
        }],
        taskCostMax: [{
          validator: validateNumber,
          trigger: ['change', 'blur']
        }],
        releaseDate: [{
          required: true,
          message: '请选择日期',
          trigger: ['change', 'blur']
        }],
        deadlineDate: [{
          required: true,
          message: '请选择日期',
          trigger: ['change', 'blur']
        }],
        upperLimit: [{
          type: 'number',
          required: true,
          message: '请输入任务上限人数',
          trigger: ['change', 'blur']
        },
        {
          validator: validateNumber,
          trigger: ['change', 'blur']
        }
        ],
        cooperateMode: [{
          required: true,
          message: '请选择合作类型',
          trigger: ['change', 'blur']
        }],
        taskMode: [{
          required: true,
          message: '请选择任务模式',
          trigger: ['change', 'blur']
        }]
      }
    }
  },
  computed: {
    action: function() {
      return '/platform/file/uploadWorkerTask?merchantId=' + this.formData.merchantId
    }
  },
  created() {
    this.fetchShop()
  },
  methods: {
    download() {
      getImportWorkerTask().then(res => {
        location.href = res.obj
      })
    },
    setFields(fields, val) {
      this.formData[fields] = val
    },
    changeMerchant() {
      if (this.$refs.workerLookup) {
        this.$refs.workerLookup.handleClear()
        this.selectObj = {}
        this.selectWorker = []
        this.$refs.workerLookup.resetPage()
      }
    },
    showWorkerDialog() {
      if (this.$u.test.isEmpty(this.formData.merchantId)) {
        return this.$message.error('请选择商户')
      }
      this.dialogTableVisible = true
    },
    fetchShop() {
      getAllList().then(res => {
        this.shop = res.data
      })
    },
    submitForm(formName) {
      // const form = Object.assign({}, this.formData)
      // if (this.$dayjs(form.deadlineDate).isBefore(this.$dayjs(form.releaseDate))) {
      //   return this.$message.error('开始日期不能大于截至日期')
      // }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          if (typeof form.taskCostMin === 'number' && typeof form.taskCostMax === 'number') {
            if (form.taskCostMax < form.taskCostMin) {
              return this.$message.error('最高费用不能低于最低费用')
            }
          }
          this.loading = true
          if (parseInt(form.taskMode) !== 1) {
            const worker = []
            if (this.selectWorker.length) {
              this.selectWorker.forEach(row => {
                worker.push(row.id)
              })
              form.makerIds = worker.join(',')
            }
          }
          add(form).then(res => {
            this.$message.success('操作成功')
            this.$router.go(-1)
            this.loading = false
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    uploadExcel(res) {
      // const data = e
      // if (data && data.code === 200) {
      //   if (data.message) {
      //     this.formData.makerIds = data.message
      //     const aryTmp = data.message.split(',')
      //     if (this.formData.upperLimit < aryTmp.length) {
      //       this.$message.error('上传创客超过人数上限')
      //     } else {
      //       this.$successModal('Excel上传成功')
      //     }
      //   } else {
      //     console.log('后台返回格式有变化')
      //   }
      // } else {
      //   this.$errorModal('上传失败，状态码：', data.code)
      // }
      if (res && res.code === 200) {
        if (res?.data.length) {
          this.onSelect(res.data)
          const tempAry = []
          res.data.forEach(element => {
            tempAry.push(element.id)
          })
          this.formData.makerIds = tempAry.join()
        } else {
          console.log('上传失败')
        }
      } else {
        this.$errorModal('上传失败，状态码：', res.code)
      }
    },
    showErrorModal(res) {
      this.$errorModal(res.message)
    },
    onSelect(i) {
      if (i === false) {
        this.dialogTableVisible = false
        return
      }
      if (!this.$u.test.isEmpty(this.formData.upperLimit)) {
        if (parseInt(this.formData.upperLimit) < i.length) {
          return this.$message.error('选择创客超过人数上限')
        }
      }
      if (i.length) {
        for (let n = 0, len = i.length; n < len; n++) {
          const item = i[n]
          if (!this.selectObj[item.id]) {
            this.selectObj[item.id] = item.id
            this.selectWorker.push(item)
          }
        }
      }
      this.dialogTableVisible = false
    },
    handleDel(item, index) {
      const id = this.selectWorker[index].id
      delete this.selectObj[id]
      this.selectWorker.splice(index, 1)
      this.$refs.workerLookup.changeSelection([].concat(this.selectWorker))
    }
  }
}
</script>

<style lang="scss" scoped>
  .upload-btn {
    display: inline-block;
    margin-left: 18px;
  }

  .add-worker {
    display: inline-block;
    padding: 0px 20px;
  }
</style>
