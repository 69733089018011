<template>
  <div>
    <el-form ref="withdrawForm" v-loading="withdrawLoading" :model="withdrawForm" :rules="withdrawRules" label-width="150px" style="max-width: 400px">
      <!-- <el-form-item label="外部会员名称" prop="accountName">
        <el-input v-model="withdrawForm.accountName" placeholder="请输入" />
      </el-form-item> -->
      <!-- <el-form-item label="企业税号" prop="companyTaxNum">
        <el-input v-model="withdrawForm.companyTaxNum" placeholder="请输入" />
      </el-form-item> -->
      <el-form-item label="签约的支付宝用户号" prop="agreeAlipayUserId">
        <el-input v-model="withdrawForm.agreeAlipayUserId" placeholder="请输入" />
      </el-form-item>
      <!-- <el-form-item label="提现支付宝用户名" prop="txAlipayName">
        <el-input v-model="withdrawForm.txAlipayName" placeholder="请输入" />
      </el-form-item> -->
      <!-- <el-form-item label="提现支付宝账户" prop="txAlipayAccNo">
        <el-input v-model="withdrawForm.txAlipayAccNo" placeholder="请输入" />
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="submitForm('withdrawForm')">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import { getCode } from '@/api/common'
import { toAlipayUnionpaySignCOMMON, toAlipayUnionpaySignSERVICE } from '@/api/service'

const _defaultWithdrawForm = () => {
  return {
    // accountName: '',
    // companyTaxNum: '',
    agreeAlipayUserId: ''
  }
}
const btnText = '获取验证码'
export default {
  props: {
    uid: {
      type: [Number, String],
      required: true
    },
    type: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      countdown: null,
      disabled: false,
      withdrawLoading: false,
      btnText: btnText,
      withdrawForm: Object.assign({}, _defaultWithdrawForm()),
      withdrawRules: {
        // accountName: [
        //   { required: true, trigger: 'blur', message: '请输入外部会员名称' }
        // ],
        // companyTaxNum: [
        //   { required: true, trigger: 'blur', message: '请输入企业税号' }
        // ],
        agreeAlipayUserId: [
          { required: true, trigger: 'blur', message: '请输入签约的支付宝用户号' }
        ]
        // txAlipayName: [
        //   { required: true, trigger: 'blur', message: '请输入提现支付宝用户名' }
        // ],
        // txAlipayAccNo: [
        //   { required: true, trigger: 'blur', message: '请输入提现支付宝账户' }
        // ]
      },
      tel: ''
    }
  },
  methods: {
    setData(data = {}) {
      this.$refs['withdrawForm'].resetFields()
      this.withdrawForm.alipaySignId = data.alipaySignId
      this.disabled = true
      clearInterval(this.countdown)
      this.btnText = btnText
      // getShopPhone({
      //   merchantId: this.merchantId
      // }).then(res => {
      //   this.tel = res.obj || ''
      //   this.disabled = false
      // }).catch(res => {
      //   this.disabled = false
      // })
    },
    doLoop: function(seconds) {
      seconds = seconds || 60
      this.btnText = seconds + 's后获取'
      this.countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = btnText
          this.disabled = false
          clearInterval(this.countdown)
        }
      }, 1000)
    },
    getCode() {
      const mobile = this.tel || ''
      if (!this.$u.test.mobile(mobile)) {
        return this.$message.error('手机号不正确')
      }
      this.disabled = true
      getCode({
        mobileCode: this.tel,
        taxId: this.taxId
      }).then(res => {
        this.$message.success('发送短信成功')
        setTimeout(() => {
          this.doLoop(60)
        }, 500)
      }).catch(res => {
        this.disabled = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.withdrawLoading = true
          if (this.type === 'COMMON') {
            toAlipayUnionpaySignCOMMON(Object.assign({}, this.withdrawForm), {
              uId: this.uid
            }).then(res => {
              window.open(res.obj || res.message)
              this.$emit('success')
              this.withdrawLoading = false
            }).catch(res => {
              this.withdrawLoading = false
            })
          } else {
            toAlipayUnionpaySignSERVICE(Object.assign({}, this.withdrawForm), {
              uId: this.uid
            }).then(res => {
              window.open(res.obj || res.message)
              this.$emit('success')
              this.withdrawLoading = false
            }).catch(res => {
              this.withdrawLoading = false
            })
          }
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
