var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _c(
        "div",
        { staticClass: "right-menu u-flex u-col-center" },
        [
          _c(
            "el-badge",
            { staticClass: "item", attrs: { "is-dot": _vm.isDot } },
            [
              _c("i", {
                staticClass: "el-icon-bell u-font-16 u-point",
                on: {
                  click: function ($event) {
                    return _vm.handleNotice()
                  },
                },
              }),
            ]
          ),
          _c("span", [_vm._v(_vm._s(_vm.myName))]),
          _c("span", { staticClass: "line" }, [
            _vm._v(_vm._s(_vm.userinfo.roleName)),
          ]),
          _c("span", { staticClass: "line" }, [_vm._v(_vm._s(_vm.name))]),
          _c(
            "el-dropdown",
            { staticClass: "avatar-container", attrs: { trigger: "click" } },
            [
              _c(
                "div",
                { staticClass: "avatar-wrapper" },
                [_c("avatar", { attrs: { src: _vm.avatar, size: 24 } })],
                1
              ),
              _c(
                "el-dropdown-menu",
                {
                  staticClass: "user-dropdown",
                  attrs: { slot: "dropdown" },
                  slot: "dropdown",
                },
                [
                  _c(
                    "el-dropdown-item",
                    {
                      nativeOn: {
                        click: function ($event) {
                          return _vm.uploadHead($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("上传头像"),
                      ]),
                    ]
                  ),
                  _c(
                    "el-dropdown-item",
                    {
                      attrs: { divided: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.logout($event)
                        },
                      },
                    },
                    [
                      _c("span", { staticStyle: { display: "block" } }, [
                        _vm._v("退出登录"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { display: "none" } },
        [
          _c("button-upload", {
            ref: "myUpload",
            attrs: { "success-toast": false, type: "image", text: "上传 jpg" },
            on: { "upload-success": _vm.setFields },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }