<template>
  <div>
    <div class="search-box" style="padding: 0 2px">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="创客姓名">
          <el-input v-model="formData.accountName" clearable />
        </el-form-item>
        <el-form-item label="身份证">
          <el-input v-model="formData.idcardCode" clearable />
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="formData.mobileCode" clearable />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        row-key="id"
        :data="list"
        tooltip-effect="dark"
        height="500"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="multiple"
          type="selection"
          reserve-selection
          align="center"
          width="55"
        />
        <el-table-column
          prop="accountName"
          label="姓名"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="idcardCode"
          label="身份证"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="mobileCode"
          label="手机号"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          v-if="!multiple"
          label="操作"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="selectItem(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
      <div v-if="multiple" class="u-text-right">
        <el-button size="small" @click="selectItem(false)">取消</el-button>
        <el-button size="small" type="primary" @click="selectItem">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkList } from '@/api/task'

export default {
  name: 'WorkerLookup',
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    merchantId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      formData: {
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      },
      multipleSelection: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    changeSelection(rows) {
      this.$refs.multipleTable.clearSelection()
      this.$nextTick(() => {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      })
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleClear() {
      this.$refs.multipleTable.clearSelection()
      this.multipleSelection = []
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        page: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getWorkList(Object.assign({}, this.formData, this.paramsData, {
        merchantId: this.merchantId
      })).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    selectItem(type) {
      if (type === false) {
        this.$emit('on-select', false)
      } else {
        if (this.multiple) {
          this.$emit('on-select', [].concat(this.multipleSelection))
        } else {
          this.$emit('on-select', [].concat([type]))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
