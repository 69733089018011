<template>
  <div>
    <el-form ref="formData" v-loading="loading" :model="value" class="u-form" label-width="150px">
      <el-card header="业务配置">
        <el-form-item label="合作类型">
          <el-checkbox v-model="total.checked">自然人【总包+分包】 (6%专票)</el-checkbox>
        </el-form-item>
        <div v-if="total.checked" class="u-border u-p-lf-10 u-p-tb-40" style="margin-left: 150px">
          <el-form-item :label-width="subLabelWidth" label="综合税费率(多选)">
            <el-col :span="8" :class="total.one ? '' : 'box-un-active'">
              <el-checkbox v-model="total.one">一口价</el-checkbox>
              <div>
                <el-input v-model="value.totalTaxPackage.taxPrice" min="1" class="r80" placeholder="请输入最低服务费率">
                  <span slot="suffix">%</span>
                </el-input>
              </div>
            </el-col>
            <el-col :span="16" :class="total.more ? '' : 'box-un-active'">
              <el-checkbox v-model="total.more">梯度价</el-checkbox>
              <el-row :gutter="10">
                <el-col :span="14" class="u-text-center">
                  单人单月额度
                </el-col>
                <el-col :span="10">
                  服务费率
                </el-col>
              </el-row>
              <template v-if="loadTag.totalServiceLadders">
                <el-row v-for="(i, k) in totalServiceLadders" :key="i.guid" :gutter="10" class="u-m-b-10">
                  <el-col :span="13">
                    <el-col :span="11">
                      <el-input v-model="i.startMoney" readonly placeholder="请输入">
                        <span slot="suffix">元</span>
                      </el-input>
                    </el-col>
                    <el-col :span="2" class="u-text-center">至</el-col>
                    <el-col :span="11">
                      <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('totalServiceLadders', k, $event)">
                        <span slot="suffix">元</span>
                      </el-input>
                    </el-col>
                  </el-col>
                  <el-col :span="9">
                    <el-input v-model="i.rate" placeholder="请输入最低服务费率" :class="{'default-input': isEdit}" @input.native="changeBg">
                      <span slot="suffix">%</span>
                    </el-input>
                  </el-col>
                  <el-col :span="2">
                    <i v-if="k !== 0 && k === (totalServiceLadders.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('totalServiceLadders', k)" />
                  </el-col>
                </el-row>
              </template>
              <div class="u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point" style="width: 100px;margin: auto" @click="addList('totalServiceLadders')">
                <i class="el-icon-circle-plus-outline u-font-24" />添加
              </div>
            </el-col>
          </el-form-item>
          <el-form-item :label-width="subLabelWidth" class="auto-width" label="可开票类目" required>
            <div class="u-border">
              <el-table
                ref="totalTable"
                v-loading="taxLoading"
                :data="taxTypeList"
                tooltip-effect="dark"
                max-height="400px"
                style="width: 100%"
                @selection-change="handleSelectionChange('totalTable', $event)"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  label="序号"
                  align="center"
                  width="55"
                >
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="serviceType"
                  align="center"
                  width="120"
                  label="服务类型"
                />
                <el-table-column
                  prop="serviceContent"
                  label="具体服务内容"
                />
                <el-table-column
                  prop="billingCategory"
                  width="200"
                  label="开票类目"
                />
                <el-table-column
                  width="80"
                  label="操作"
                >
                  <template slot-scope="scope">
                    <el-button type="text" size="small" @click="delTax(scope.row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="u-dashed-border u-text-center u-point u-m-t-10" @click="dialogTableVisible = true"><i class="el-icon-plus u-m-r-10" />添加可开票类目</div>
            </div>
          </el-form-item>
          <check-total ref="checkTotal" :total-checked="total.checked" :data="value.totalLadders" />
        </div>
        <el-form-item class="u-m-t-20">
          <el-checkbox v-model="crowd.checked">自然人【众包】 (3%普票)</el-checkbox>
        </el-form-item>
        <div v-if="crowd.checked" class="u-border u-p-lf-10 u-p-tb-40" style="margin-left: 150px">
          <el-form-item :label-width="subLabelWidth" label="综合税费率(多选)">
            <el-col :span="8" :class="crowd.one ? '' : 'box-un-active'">
              <el-checkbox v-model="crowd.one">一口价</el-checkbox>
              <div>
                <el-input v-model="value.manyTaxPackage.taxPrice" min="1" onkeyup="value=value.replace(/[^0-9]/g,'');if(String(value)==='0'){value=1}" class="r80" placeholder="请输入最低服务费率">
                  <span slot="suffix">%</span>
                </el-input>
              </div>
            </el-col>
            <el-col :span="16" :class="crowd.more ? '' : 'box-un-active'">
              <el-checkbox v-model="crowd.more">梯度价</el-checkbox>
              <el-row :gutter="10">
                <el-col :span="14" class="u-text-center">
                  单人单月额度
                </el-col>
                <el-col :span="10">
                  服务费率
                </el-col>
              </el-row>
              <template v-if="loadTag.manyServiceLadders">
                <el-row v-for="(i, k) in manyServiceLadders" :key="i.guid" :gutter="10" class="u-m-b-10">
                  <el-col :span="13">
                    <el-col :span="11">
                      <el-input v-model="i.startMoney" readonly placeholder="请输入">
                        <span slot="suffix">元</span>
                      </el-input>
                    </el-col>
                    <el-col :span="2" class="u-text-center">至</el-col>
                    <el-col :span="11">
                      <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('manyServiceLadders', k, $event)">
                        <span slot="suffix">元</span>
                      </el-input>
                    </el-col>
                  </el-col>
                  <el-col :span="9">
                    <el-input v-model="i.rate" placeholder="请输入最低服务费率" :class="{'default-input': isEdit}" @input.native="changeBg">
                      <span slot="suffix">%</span>
                    </el-input>
                  </el-col>
                  <el-col :span="2">
                    <i v-if="k !== 0 && k === (manyServiceLadders.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('manyServiceLadders', k)" />
                  </el-col>
                </el-row>
              </template>
              <div class="u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point" style="width: 100px;margin: auto" @click="addList('manyServiceLadders')">
                <i class="el-icon-circle-plus-outline u-font-24" />添加
              </div>
            </el-col>
          </el-form-item>
          <el-form-item :label-width="subLabelWidth" class="auto-width" label="可开票类目" required>
            <div class="u-border">
              <el-table
                ref="crowdTable"
                v-loading="taxLoading"
                :data="taxTypeList"
                tooltip-effect="dark"
                max-height="400px"
                style="width: 100%"
                @selection-change="handleSelectionChange('crowdTable', $event)"
              >
                <el-table-column
                  type="selection"
                  width="55"
                />
                <el-table-column
                  label="序号"
                  align="center"
                  width="55"
                >
                  <template slot-scope="scope">
                    {{ scope.$index + 1 }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="serviceType"
                  align="center"
                  width="120"
                  label="服务类型"
                />
                <el-table-column
                  prop="serviceContent"
                  label="具体服务内容"
                />
                <el-table-column
                  prop="billingCategory"
                  width="200"
                  label="开票类目"
                />
              </el-table>
              <div class="u-dashed-border u-text-center u-point u-m-t-10" @click="dialogTableVisible = true"><i class="el-icon-plus u-m-r-10" />添加可开票类目</div>
            </div>
          </el-form-item>
          <check-crowd ref="checkCrowd" :crowd-checked="crowd.checked" :data="value.manyLadders" />
        </div>
        <el-form-item v-if="isEdit" class="u-m-t-20">
          <el-button type="primary" size="large" @click="submit">确定</el-button>
        </el-form-item>
      </el-card>
    </el-form>
    <div v-if="!isEdit" class="wrap-fixed-footer btn--box">
      <el-button type="primary" size="small" @click="next('formData')">下一步</el-button>
      <el-button size="small" @click="prev">上一步</el-button>
    </div>
    <el-dialog title="新增开票类目" :visible.sync="dialogTableVisible" width="800px">
      <add-tax ref="addTax" @success="successAddTax" @cancel="dialogTableVisible = false" />
    </el-dialog>
  </div>
</template>

<script>
import { deepClone, getGuid } from '@/utils'
import AddTax from '@/views/service/components/addTax'
import CheckTotal from '@/views/service/components/CheckTotal'
import { delTaxType, getTaxType } from '@/api/common'
import CheckCrowd from '@/views/service/components/CheckCrowd'
import { updateTaxPackage } from '@/api/service'
import { _defaultForm } from '@/views/service/defaultForm'

export default {
  components: { CheckCrowd, AddTax, CheckTotal },
  props: {
    value: {
      type: Object,
      required: true
    },
    // 加载标识符，当编辑时动态更改
    load: {
      type: Boolean,
      default: false
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: undefined,
      subLabelWidth: '150px',
      loading: false,
      total: {
        checked: false,
        one: false,
        more: false
      },
      crowd: {
        checked: false,
        one: false,
        more: false
      },
      loadTag: {
        totalServiceLadders: false, // 重组标识
        manyServiceLadders: false // 重组标识
      },
      totalServiceLadders: [],
      manyServiceLadders: [],
      taxLoading: false,
      taxTypeList: [],
      dialogTableVisible: false
    }
  },
  watch: {
    load: function(newVal) {
      if (newVal) {
        this.fetchTaxType(this.id)
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    if (this.load) {
      this.fetchTaxType(this.id)
    }
    if (!this.id) {
      this.reloadList()
    }
  },
  methods: {
    changeBg(e) {
      e.target.style.backgroundColor = '#fff'
    },
    delTax(row) {
      this.$confirm('你确定要删除此开票类目吗？', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delTaxType({
          invoiceCatalogId: row.id
        }).then(res => {
          this.$message.success(res.message)
          this.fetchTaxType(this.id)
        })
      }).catch(() => {})
    },
    // 梯度价上修改
    changeEnd(name, index, val) {
      if (this[name].length > index + 1) {
        this[name][index + 1].startMoney = val
      }
    },
    // 数据重组
    reloadList() {
      ['manyServiceLadders', 'totalServiceLadders'].map(name => {
        this.$nextTick(() => {
          if (this[name] && this[name].length) {
            const data = deepClone(this[name])
            this[name] = data.map(val => {
              val.guid = getGuid()
              return val
            })
          }
          this.loadTag[name] = true
        })
      })
    },
    prev() {
      this.$emit('prev')
    },
    /**
     * 删除梯度价
     * @param name 变量名
     * @param index 序号
     */
    removeList(name, index) {
      this[name].splice(index, 1)
    },
    /**
     * 添加梯度价
     * @param name 变量名
     * @param data
     */
    addList(name, data = {}) {
      data = Object.assign({}, data)
      const guid = getGuid()
      if (this.$u.test.isEmptyNotZero(data.startMoney)) {
        const length = this[name].length
        if (length === 0) {
          data.startMoney = 0
        } else {
          const last = this[name][length - 1]
          if (!this.$u.test.validateRange(last.endMoney, '0~')) {
            return this.$message.error('请填写正确的梯度价')
          }
          if ((+last.startMoney) >= (+last.endMoney)) {
            return this.$message.error('请填写正确的梯度价')
          }
          if (!this.$u.test.validateRange(last.rate, '0~')) {
            return this.$message.error('请填写正确的服务费率')
          }
          data.startMoney = last.endMoney
        }
      }
      this[name].push({
        'guid': guid,
        'id': data.id,
        'startMoney': data.startMoney || 0,
        'endMoney': data.endMoney || '',
        'rate': data.rate || '',
        packaegStatus: name === 'manyServiceLadders' ? 5 : 4,
        status: 0
      })
    },
    fetchTaxType(serviceId) {
      this.taxLoading = true
      const defaultForm = _defaultForm()
      getTaxType().then(res => {
        this.taxTypeList = res.data || []
        if (serviceId) {
          this.$nextTick(() => {
            if (this.value.totalTaxPackage && !this.$u.test.isEmpty(this.value.totalTaxPackage.taxPrice)) {
              this.total.checked = true
              this.total.one = true
            }
            if (this.value.totalLadders && this.value.totalLadders.length) {
              const totalServiceLadders = this.value.totalLadders.filter(val => {
                return (+val.packaegStatus) === 4
              })
              if (totalServiceLadders.length) {
                this.total.checked = true
                this.total.more = true
                this.totalServiceLadders = totalServiceLadders
              }
            }
            if (!this.totalServiceLadders) {
              this.totalServiceLadders = defaultForm['totalServiceLadders']
            }
            if (this.value.manyTaxPackage && !this.$u.test.isEmpty(this.value.manyTaxPackage.taxPrice)) {
              this.crowd.checked = true
              this.crowd.one = true
            }
            if (this.value.manyLadders && this.value.manyLadders.length) {
              const manyServiceLadders = this.value.manyLadders.filter(val => {
                return (+val.packaegStatus) === 5
              })
              if (manyServiceLadders.length) {
                this.crowd.checked = true
                this.crowd.more = true
                this.manyServiceLadders = manyServiceLadders
              }
            }
            if (!this.manyServiceLadders) {
              this.manyServiceLadders = defaultForm['manyServiceLadders']
            }
            this.reloadList()
            this.updateTableSelected()
            this.taxLoading = false
          })
        } else {
          this.updateTableSelected()
          this.taxLoading = false
        }
      }).catch(res => {
        this.taxLoading = false
      })
    },
    updateTableSelected() {
      if (this.crowd.checked) {
        const taxIds = this.value.manyTaxPackage.supportCategory
        if (!this.$u.test.isEmpty(taxIds)) {
          const taxIdArr = taxIds.split(',')
          this.$nextTick(() => {
            this.taxTypeList.forEach(val => {
              if (taxIdArr.indexOf(val.id) !== -1) {
                this.$refs.crowdTable.toggleRowSelection(val)
              }
            })
          })
        }
      }
      if (this.total.checked) {
        const taxIds = this.value.totalTaxPackage.supportCategory
        if (!this.$u.test.isEmpty(taxIds)) {
          const taxIdArr = taxIds.split(',')
          this.$nextTick(() => {
            this.taxTypeList.forEach(val => {
              if (taxIdArr.indexOf(val.id) !== -1) {
                this.$refs.totalTable.toggleRowSelection(val)
              }
            })
          })
        }
      }
    },
    successAddTax() {
      this.dialogTableVisible = false
      this.$refs.addTax.resetForm()
      this.fetchTaxType()
    },
    handleSelectionChange(tableName, rows) {
      const ids = []
      rows.forEach(val => {
        ids.push(val.id)
      })
      const idString = ids.join(',')
      if (tableName === 'crowdTable') {
        this.value.manyTaxPackage.supportCategory = idString
      } else {
        this.value.totalTaxPackage.supportCategory = idString
      }
    },
    getData() {
      if (!this.total.checked && !this.crowd.checked) {
        this.$message.error('请选择至少一种合作类型')
        return false
      }
      let { totalTaxPackage, manyTaxPackage } = deepClone(this.value)
      let totalServiceLadders = deepClone(this.totalServiceLadders)
      let manyServiceLadders = deepClone(this.manyServiceLadders)
      if (this.total.checked) {
        if (!this.total.one) {
          totalTaxPackage.taxPrice = null
        } else {
          if (!this.$u.test.validateRange(totalTaxPackage.taxPrice, '0~')) {
            this.$message.error('总包一口价不正确')
            return false
          }
        }
        if (!this.total.more) {
          totalServiceLadders = undefined
        }
      } else {
        totalTaxPackage = undefined
        totalServiceLadders = undefined
      }
      if (this.crowd.checked) {
        if (!this.crowd.one) {
          manyTaxPackage.taxPrice = null
        } else {
          if (!this.$u.test.validateRange(manyTaxPackage.taxPrice, '0~')) {
            this.$message.error('众包一口价不正确')
            return false
          }
        }
        if (!this.crowd.more) {
          manyServiceLadders = undefined
        }
      } else {
        manyTaxPackage = undefined
        manyServiceLadders = undefined
      }
      if (totalTaxPackage.taxPrice === null && totalServiceLadders === undefined && manyTaxPackage.taxPrice === null && manyServiceLadders === undefined) {
        this.$message.error('请选择至少一种综合费率')
        return false
      }

      let totalLadders = []
      if (this.$refs.checkTotal) {
        totalLadders = this.$refs.checkTotal.getLadders()
      }

      if (!totalLadders) {
        return false
      }
      let totalSingleLadders = totalLadders.totalSingleLadders || []
      if (!totalSingleLadders.length) {
        totalSingleLadders = undefined
      }
      let totalCollectLadders = totalLadders.totalCollectLadders || []
      if (!totalCollectLadders.length) {
        totalCollectLadders = undefined
      }

      let manyLadders = this.getCheckLadder('checkCrowd')

      if (!manyLadders) {
        return false
      }
      if (!manyLadders.length) {
        manyLadders = undefined
      }

      return {
        totalTaxPackage, totalServiceLadders, manyTaxPackage, manyServiceLadders, totalSingleLadders, totalCollectLadders, manyLadders
      }
    },
    next() {
      const data = this.getData()
      if (data) {
        this.$emit('next', data)
      }
    },
    // 获取代开信息
    getCheckLadder(ref) {
      if (this.$refs[ref]) {
        return this.$refs[ref].getLadders()
      }
      return []
    },
    submit() {
      const data = this.getData()
      if (data) {
        this.loading = true
        updateTaxPackage(Object.assign({
          taxId: this.$route.query.id
        }, data)).then(res => {
          this.$message.success('操作成功')
          this.loading = false
        }).catch(res => {
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .r80 {
    max-width: 80%;
  }
  .u-form {
    ::v-deep {
      .el-form-item__content {
        max-width: 80%;
      }

      .auto-width {
        .el-form-item__content {
          max-width: 800px;
        }
      }
    }
  }

  .error-line {
    ::v-deep {
      .el-input__inner {
        border-color: #f56c6c;
      }
    }
  }
  .default-input {
    ::v-deep {
      .el-input__inner {
        background-color: #ececec;
      }
    }
  }

  .box-un-active {
    .error-line {
      ::v-deep {
        .el-input__inner {
          border-color: #dcdfe6;
        }
      }
    }

    opacity: .3;
  }
</style>
