import { asyncRoutes, constantRoutes } from '@/router'
import { getAuth } from '@/api/user'

// 判断是否有权限访问该菜单
function hasPermission(menus, route) {
  if (route.hidden !== undefined && route.hidden) {
    return true
  } else if (route.redirect === 'noRedirect') {
    return true
  } else {
    return getMenu(route.redirect || route.path, menus)
  }
}

// 根据路由名称获取菜单
function getMenu(path, menus) {
  return (menus.indexOf(path) !== -1)
}

// 对菜单进行排序
// function sortRouters(accessedRouters) {
//   for (let i = 0; i < accessedRouters.length; i++) {
//     const router = accessedRouters[i]
//     if (router.children && router.children.length > 0) {
//       router.children.sort(compare('sort'))
//     }
//   }
//   accessedRouters.sort(compare('sort'))
// }

// 降序比较函数
// function compare(p) {
//   return function(m, n) {
//     const a = m[p]
//     const b = n[p]
//     return b - a
//   }
// }
const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, superAdmin) {
    return new Promise((resolve, reject) => {
      if (superAdmin) {
        const accessedRoutes = asyncRoutes || []
        if (accessedRoutes.length) {
          if (accessedRoutes[accessedRoutes.length - 1].path !== '*') {
            accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
          }
        } else {
          accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
        }
        commit('SET_ROUTES', accessedRoutes)
        resolve(accessedRoutes)
      } else {
        getAuth().then(res => {
          const menus = res.data || []
          const accessedRoutes = asyncRoutes.filter(v => {
            // admin帐号直接返回所有菜单
            // if(username==='admin') return true;
            if (hasPermission(menus, v)) {
              if (v.children && v.children.length > 0) {
                let noHiddenMenu = false
                v.children = v.children.filter(child => {
                  if (hasPermission(menus, child)) {
                    if (child.children && child.children.length > 0) {
                      let noHiddenMenu2 = false
                      child.children = child.children.filter(child2 => {
                        if (hasPermission(menus, child2)) {
                          if (!child2.hidden) {
                            noHiddenMenu2 = true
                            noHiddenMenu = true
                          }
                          return child2
                        }
                        return false
                      })
                      return noHiddenMenu2 ? child : false
                    } else {
                      if (!child.hidden) {
                        noHiddenMenu = true
                      }
                      return child
                    }
                  }
                  return false
                })
                return noHiddenMenu ? v : false
              } else {
                return v
              }
            }
            return false
          })
          commit('SET_ROUTES', accessedRoutes || [])
          resolve(accessedRoutes)
        }).catch(res => {
          reject(res)
        })
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
