import { render, staticRenderFns } from "./wait.vue?vue&type=template&id=5dfeecde&scoped=true&"
import script from "./wait.vue?vue&type=script&lang=js&"
export * from "./wait.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5dfeecde",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/dev-lhyg_admin_qd/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5dfeecde')) {
      api.createRecord('5dfeecde', component.options)
    } else {
      api.reload('5dfeecde', component.options)
    }
    module.hot.accept("./wait.vue?vue&type=template&id=5dfeecde&scoped=true&", function () {
      api.rerender('5dfeecde', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/tax/total/wait.vue"
export default component.exports