<template>
  <div :class="!isComponent ? 'u-p-20 wrap-fixed-content' : ''">
    <el-card v-if="(+baseInfo.paymentOrderStatus) === 5" style="border: 1px solid #FF1A2E" class="u-m-b-10">
      <div class="u-flex u-col-center u-p-lf-40">
        <div class="u-text-center">
          <i class="el-icon-error" style="font-size: 72px;color: #FF1A2E" />
          <div class="u-m-t-10" style="font-size: 24px;">被驳回</div>
        </div>
        <div class="u-m-l-20 u-p-l-40 u-flex" style="width: 100%">
          <div class="u-flex-1">
            <div class="u-tips-color u-font-14 u-m-l-20">请核对并修改以下信息后，再重新提交。</div>
            <div style="background-color: #fafafa" class="u-p-20 u-m-t-10">
              <div class="u-font-16 u-m-b-10">您提交的内容有如下问题：</div>
              <div class="u-font-14" style="color: #8e9193">{{ baseInfo.reasonsForRejection }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-loading="loading">
      <div class="card--title">总包+分包信息</div>
      <div class="card--content">
        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">支付编号：</span>
            <span class="value">{{ baseInfo.paymentOrderId }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">商户：</span>
            <span class="value">{{ baseInfo.companyName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">平台服务商：</span>
            <span class="value">{{ baseInfo.platformServiceProvider }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">项目合同：</span>
            <span class="value">
              <file-icon :url="baseInfo.companyContract" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付清单：</span>
            <span class="value">
              <file-icon :url="baseInfo.paymentInventory" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">关联任务：</span>
            <span class="value">
              <el-button v-if="!$u.test.isEmpty(baseInfo.taskId)" type="text" @click="showTask(baseInfo.taskId)">查看
              </el-button>
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">交付支付验收单：</span>
            <span class="value">
              <file-icon :url="baseInfo.acceptanceCertificate" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">状态：</span>
            <span class="value"
              v-html="$options.filters.filterContent(baseInfo.paymentOrderStatus, $enum.payTotalAndSubStatus())" />
          </el-col>
          <el-col :md="8">
            <span class="label">创建时间：</span>
            <span class="value">{{ baseInfo.creatDate }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付时间：</span>
            <span class="value">{{ baseInfo.paymentDate }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">总包支付回单：</span>
            <span class="value">
              <file-icon v-if="baseInfo.turnkeyProjectPayment" :url="baseInfo.turnkeyProjectPayment" />
              <button-upload v-else
                :default-action="'/platform/paymentOrder/uploadPackagePaymentReceipt?id=' + baseInfo.paymentOrderId"
                :success-toast="false" :error-toast="false" return-all name="uploadJpgOrPdf" type="image/pdf"
                btn-type="text" text="上传(jpg)" @upload-success="uploadExcel($event)" @upload-error="showErrorModal" />
            </span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card v-loading="loading" class="u-m-t-20">
      <div class="card--title">支付清单明细</div>
      <div style="padding:20px 10px">
        <el-form ref="elForm" :inline="true" :model="formData" size="small">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="formData.name" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="电话" prop="mobile">
            <el-input v-model="formData.mobile" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="身份证号" prop="idCode">
            <el-input v-model="formData.idCode" placeholder="请输入" clearable />
          </el-form-item>
          <el-form-item label="支付状态" prop="orderStatus">
            <el-select v-model="formData.orderStatus" style="width: 100%" placeholder="请选择" clearable filterable>
              <el-option label="支付失败" value="-1" />
              <el-option label="未支付" value="0" />
              <el-option label="支付中" value="4" />
              <el-option label="支付成功" value="1" />
              <el-option label="已关闭" value="2" />
              <!-- <el-option label="校验不通过" value="11" />
              <el-option label="校验通过" value="12" /> -->
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getPaymentInventoryList('reset')">查询</el-button>
            <el-button @click="reset">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="card--content">
        <el-button v-if="type == 'edit'" type="primary" :loading="loading" @click="closePay">批量关闭支付</el-button>
        <el-button type="primary" :loading="loading" @click="downloadExcel">导出Excel</el-button>
        <el-button type="primary" :loading="loading" @click="OneclickExcel">一键导出</el-button>
        <el-button type="primary" :loading="loading" @click="ExReceipt">导出回单</el-button>
        <div style="display: flex;justify-content: end;align-items: center;">
          <span>风控校验中 {{ progressInfo.countInProgress || 0 }} 人，风控校验成功 {{ progressInfo.countSuccess || 0 }} 人，风控校验失败 {{
            progressInfo.countFailed || 0 }} 人</span>
          <el-button v-if="progressInfo.countFailed" class="u-m-l-10" type="primary" size="small" @click="downloadErrList"
            :loading="errLoading">下载校验错误清单</el-button>
        </div>
        <el-table ref="multipleTable" row-key="id" v-loading="tbLoading" :data="list" max-height="600px"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" reserve-selection width="55" />
          <el-table-column prop="workerName" label="姓名" width="100" align="center" />
          <el-table-column prop="mobileCode" width="120" label="电话" align="center" />
          <el-table-column prop="idCardCode" width="180" label="身份证号" align="center" />
          <el-table-column prop="bankName" label="开户行" align="center" />
          <el-table-column prop="bankCode" label="收款账号" align="center">
            <template slot-scope="scope">
              {{ scope.row.bankCode }}
              <el-button v-if="isEdit || (+scope.row.paymentStatus !== 1)" type="text" size="small"
                @click="handleChangeBank(scope.row, scope.$index)">修改</el-button>
            </template>
          </el-table-column>
          <el-table-column label="发放金额" align="center">
            <template slot-scope="scope">
              {{ !$u.test.isEmptyNotZero(scope.row.taskMoney) ? ('￥' + $u.formatMoney(scope.row.taskMoney)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="创客到手费" align="center">
            <template slot-scope="scope">
              {{ !$u.test.isEmptyNotZero(scope.row.realMoney) ? ('￥' + $u.formatMoney(scope.row.realMoney)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="服务费" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>创客：{{ ('￥' + (((+scope.row.receviceTax) * (+scope.row.taskMoney) / 100) || 0).toFixed(2)) }}</div>
              <div>
                <span>商户：{{ ('￥' + scope.row.serviceMoney) }}</span>
                <el-button v-if="isCheck" type="text" size="small"
                  @click="handleChangeServiceMoney(scope.row, scope.$index)">修改</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="服务费率" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>创客：{{ !$u.test.isEmptyNotZero(scope.row.receviceTax) ? (scope.row.receviceTax + '%') : '-' }}</div>
              <div>商户：{{ !$u.test.isEmptyNotZero(scope.row.merchantTax) ? (scope.row.merchantTax + '%') : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="商户总支出" align="center">
            <template slot-scope="scope">
              {{ !$u.test.isEmptyNotZero(scope.row.merchantPaymentMoney) ? ('￥' +
                $u.formatMoney(scope.row.merchantPaymentMoney)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="支付状态" width="120" align="center">
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.paymentStatus, $enum.paymentStatus())" />
            </template>
          </el-table-column>
          <el-table-column label="回单" align="center">
            <template slot-scope="scope">
              <file-icon v-if="scope.row.receipt" :url="scope.row.receipt" />
              <button-upload v-else
                :default-action="'/platform/paymentOrder/uploadReceipt?paymentInventoryId=' + scope.row.id"
                :success-toast="false" :error-toast="false" return-all name="uploadJpgOrPdf" type="image/pdf"
                btn-type="text" text="上传(pdf)" @upload-success="uploadExcel($event)" @upload-error="showErrorModal" />
            </template>
          </el-table-column>
          <el-table-column prop="tradeFailReason" label="支付失败原因" align="center" width="150" show-overflow-tooltip />
        </el-table>
        <div class="pager-box">
          <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo"
            :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="getPaymentInventoryList" />
        </div>
        <div v-if="baseInfo.companyMoney" class="u-m-t-10">合计（商户总支出）：￥{{ $u.formatMoney(baseInfo.realMoney) }}</div>
      </div>
    </el-card>
    <el-card v-if="show" class="u-m-t-20">
      <div class="card--title">总包支付信息</div>
      <div class="card--content">
        <div class="card--info">
          <div class="card--info-item">
            <span class="label">支付方式：</span>
            <span class="value">
              {{ baseInfo.paymentMode | allUnionpayBankType }}
            </span>
          </div>
          <template v-if="totalForm.payType === 0">
            <table class="c-table is-edit">
              <tr>
                <td>
                  <div class="card--info-item">
                    <span class="label">支付方：</span>
                    <span class="value">{{ baseInfo.companyName }}</span>
                  </div>
                  <div class="card--info-item">
                    <span class="label">开户行：</span>
                    <span class="value">{{ baseInfo.bankName }}</span>
                  </div>
                  <div class="card--info-item">
                    <span class="label">银行账号：</span>
                    <span class="value">{{ baseInfo.bankCode }}</span>
                  </div>
                </td>
                <td>
                  <div class="card--info-item">
                    <span class="label">收款方：</span>
                    <span class="value">{{ baseInfo.payee }}</span>
                  </div>
                  <div class="card--info-item">
                    <span class="label">开户行：</span>
                    <span class="value">{{ baseInfo.payeeBankName }}</span>
                  </div>
                  <div class="card--info-item">
                    <span class="label">银行账号：</span>
                    <span class="value">{{ baseInfo.payeeBankCode }}</span>
                  </div>
                </td>
              </tr>
              <tr :class="isEdit ? '' : 'no-border'">
                <td colspan="2">
                  <div class="blue-box" :class="isEdit ? 'is-edit' : 'is-view'">转账金额：<span class="money">￥
                      {{ $u.formatMoney(baseInfo.realMoney) }}</span>
                    <span>
                      （
                      <span>发放金额:￥{{ $u.formatMoney(baseInfo.workerMoney) }} </span>
                      <span class="u-m-l-10">服务费:￥{{ $u.formatMoney(baseInfo.serviceMoney) }}</span>
                      <el-button v-if="baseInfo.paymentOrderStatus == 6" class="u-m-l-10" type="text" size="small"
                        @click="handleChangeRealMoney">修改</el-button>
                      ）
                    </span>
                  </div>
                </td>
              </tr>
            </table>
            <div v-if="isEdit" class="card--info-item u-m-t-20">
              <span class="label"><span class="text-danger">*</span>支付回单：</span>
              <span class="value">
                <span class="u-flex u-col-center">
                  <button-upload type="image/pdf" text="上传支付回单(pdf/jpg)"
                    @upload-success="setTotalField('turnkeyProjectPayment', $event)" />
                  <el-button v-if="!$u.test.isEmpty(totalForm.turnkeyProjectPayment)" class="u-m-l-10" type="text"
                    @click="$preViewer(totalForm.turnkeyProjectPayment)">查看</el-button>
                </span>
              </span>
            </div>
            <div v-else class="card--info-item">
              <span class="label">支付回单：</span>
              <span class="value">
                <file-icon :url="baseInfo.turnkeyProjectPayment" btn />
              </span>
            </div>
          </template>
          <!--    线上查看模式      -->
          <template v-else-if="!isEdit">
            <div class="blue-box is-view u-flex u-col-center">转账金额：<span class="money">￥ {{
              $u.formatMoney(baseInfo.realMoney) }}</span>
              <span>
                （
                <span>发放金额:￥{{ $u.formatMoney(baseInfo.workerMoney) }} </span>
                <span class="u-m-l-10">服务费:￥{{ $u.formatMoney(baseInfo.serviceMoney) }}</span>
                <el-button v-if="baseInfo.paymentOrderStatus == 6" class="u-m-l-10" type="text" size="small"
                  @click="handleChangeRealMoney">修改</el-button>
                ）
              </span>
            </div>
            <div v-if="unionPay.length || aliPay.length" class="u-flex u-col-top u-row-left u-flex-wrap u-m-b-10">
              <union-info v-for="(i, k) in unionPay" :key="k + Date.now()" class="u-m-r-20" :show-balance="false"
                :info="i" />
              <union-info v-for="(i, k) in aliPay" :key="k + Date.now()" isAliPay :show-balance="false" :info="i" />
            </div>
          </template>
        </div>
      </div>
    </el-card>
    <el-card v-if="isSubPay || [6].indexOf((+baseInfo.paymentOrderStatus)) !== -1" class="u-m-t-20">
      <div class="card--title">分包支付信息</div>
      <div class="card--content">
        <div v-if="totalForm.payType === 0" class="card--info">
          <table class="c-table is-edit">
            <tr>
              <td>
                <div class="card--info-item">
                  <span class="label">支付方：</span>
                  <span class="value">{{ baseInfo.payee }}</span>
                </div>
                <div class="card--info-item">
                  <span class="label">开户行：</span>
                  <span class="value">{{ baseInfo.payeeBankName }}</span>
                </div>
                <div class="card--info-item">
                  <span class="label">银行账号：</span>
                  <span class="value">{{ baseInfo.payeeBankCode }}</span>
                </div>
              </td>
              <td>
                <div class="card--info-item">
                  <span class="label">收款方：</span>
                  <span class="value">
                    <el-button v-if="!$u.test.isEmpty(baseInfo.paymentInventory)" class="u-m-l-10" type="text"
                      @click="$preViewer(baseInfo.paymentInventory)">查看</el-button>
                  </span>
                </div>
              </td>
            </tr>
            <tr :class="isEdit ? '' : 'no-border'">
              <td colspan="2">
                <div class="blue-box" :class="isEdit ? 'is-edit' : 'is-view'">转账金额：<span class="money">￥
                    {{ $u.formatMoney(baseInfo.workerMoney) }}</span></div>
              </td>
            </tr>
          </table>
          <div>
            <div v-if="isSubPay" class="card--info-item u-m-t-20">
              <span class="label"><span class="text-danger">*</span>支付回单：</span>
              <span class="value">
                <span class="u-flex u-col-center">
                  <button-upload type="image/pdf" text="上传支付回单(pdf/jpg)"
                    @upload-success="setCrowdField('subpackagePayment', $event)" />
                  <el-button v-if="!$u.test.isEmpty(subForm.subpackagePayment)" class="u-m-l-10" type="text"
                    @click="$preViewer(subForm.subpackagePayment)">查看</el-button>
                </span>
              </span>
            </div>
            <div v-else class="card--info-item">
              <span class="label">支付回单：</span>
              <span class="value">
                <file-icon :url="baseInfo.subpackagePayment" btn />
              </span>
            </div>
          </div>
        </div>
        <template v-else>
          <div v-if="aliPay.length" class="u-flex u-col-top u-row-left u-flex-wrap u-m-b-10">
            <el-card v-for="(info, k) in aliPay" :key="k + Date.now()" shadow="never" style="width: 43%"
              :body-style="{ padding: 0 }">
              <div slot="header" class="clearfix u-text-center">
                支付宝
              </div>
              <div class="u-flex u-col-top u-row-between">
                <div class="u-flex-1 u-padding-10 u-border-right">
                  <div class="u-m-b-10">子账号户名：{{ info.subAccountName }}</div>
                  <div v-if="info.unionpayBankType === 'SJBK'" class="u-m-b-10">来款银行账号：{{ info.inBankNo }}</div>
                  <div class="u-m-b-10">子账户账号：{{ info.subAccountCode }}</div>
                  <!-- <div class="u-m-b-10" v-if="info.bankName">开户行：{{ info.bankName }}</div> -->
                </div>
                <div class="u-flex-1 u-padding-10">
                  <div class="u-m-b-10">收款方：
                    <el-button v-if="!$u.test.isEmpty(baseInfo.paymentInventory)" class="u-m-l-10" type="text"
                      @click="$preViewer(baseInfo.paymentInventory)">查看</el-button>
                  </div>
                </div>
              </div>
            </el-card>
          </div>

          <div v-else class="u-flex u-col-top u-row-left u-flex-wrap u-m-b-10">
            <el-card v-for="(info, k) in unionPay" :key="k + Date.now()" class="u-m-r-20" shadow="never"
              style="width: 43%" :body-style="{ padding: 0 }">
              <div slot="header" class="clearfix u-text-center">
                银行类型: {{ info.unionpayBankType | unionpayBankType }}
              </div>
              <div class="u-flex u-col-top u-row-between">
                <div class="u-flex-1 u-padding-10 u-border-right">
                  <div class="u-m-b-10">子账号户名：{{ info.subAccountName }}</div>
                  <div v-if="info.unionpayBankType === 'SJBK'" class="u-m-b-10">来款银行账号：{{ info.inBankNo }}</div>
                  <div class="u-m-b-10">子账户账号：{{ info.subAccountCode }}</div>
                  <div class="u-m-b-10" v-if="info.bankName">开户行：{{ info.bankName }}</div>
                </div>
                <div class="u-flex-1 u-padding-10">
                  <div class="u-m-b-10">收款方：
                    <el-button v-if="!$u.test.isEmpty(baseInfo.paymentInventory)" class="u-m-l-10" type="text"
                      @click="$preViewer(baseInfo.paymentInventory)">查看</el-button>
                  </div>
                </div>
              </div>
            </el-card>

          </div>
          <div class="blue-box is-view">转账金额：<span class="money">￥ {{ $u.formatMoney(baseInfo.workerMoney) }}</span></div>
        </template>
      </div>
    </el-card>
    <el-card v-if="taxInfo" class="u-m-t-20">
      <div class="card--title">发票信息</div>
      <div class="card--content">
        <div class="card--info">
          <div class="card--info-item">
            <span class="label">总包发票：</span>
            <span class="value">
              <file-icon :url="taxInfo.invoice" btn />
            </span>
          </div>
          <div class="card--info-item">
            <span class="label">分包发票：</span>
            <span class="value">
              <file-icon :url="taxInfo.subpackageInvoice" btn />
            </span>
          </div>
        </div>
      </div>
      <template v-if="!$u.test.isEmpty(express.expressCode)">
        <div class="card--title">物流信息</div>
        <div class="card--content">
          <div class="wl-box">
            <div>物流公司：{{ express.expressCompanyName }}</div>
            <div>物流单号：{{ express.expressCode }}</div>
          </div>
          <div v-if="!$u.test.isEmpty(express.expressLogisticsInfos)" class="wl-content">
            <div v-for="(i, k) in express.expressLogisticsInfos" :key="k" class="wl-content-item">
              <span class="u-m-r-20">{{ i.AcceptTime }}</span>
              <span>{{ i.acceptStation }}</span>
            </div>
          </div>
        </div>
      </template>
    </el-card>
    <div v-if="!isComponent" class="wrap-fixed-footer btn--box">
      <!--      <el-button v-if="isEdit" :loading="submitLoading" size="small" type="primary" @click="submit">提交</el-button>-->
      <template v-if="isCheck">
        <el-button :loading="submitLoading" size="small" type="primary" @click="doPay">确认收款</el-button>
        <el-button :loading="submitLoading" size="small" type="warning" @click="checkDialog = true">支付驳回</el-button>
      </template>
      <el-button v-if="isSubPay" :loading="submitLoading" size="small" type="primary" @click="paySubSubmit">确认
      </el-button>
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog title="查看关联任务" :visible.sync="visibleDialog" width="800px">
      <task-detail ref="taskDetail" />
    </el-dialog>
    <el-dialog ref="checkDialog" title="请填写驳回原因" :visible.sync="checkDialog" width="800px">
      <div>
        <el-input v-model="checkData.reasonsForRejection" :rows="4" type="textarea" placeholder="请输入" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialog = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="rejectPay">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="修改银行卡" :visible.sync="dialogShow" width="500px">
      <el-form v-loading="loading" :model="form" label-width="80px">
        <el-form-item label="银行卡号" required>
          <el-input v-model="form.bankCode" type="number" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doChangeBank">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改商户服务费" :visible.sync="feeDialogShow" width="500px">
      <el-form v-loading="loading" :model="inventoryFeeDTO" label-width="80px">
        <el-form-item label="服务费" required>
          <el-input v-model="inventoryFeeDTO.newFee" type="number" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doChangeServiceMoney">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="修改服务费" :visible.sync="orderFeeDialogShow" width="500px">
      <el-form v-loading="loading" :model="paymentOrderFeeDTO" label-width="80px">
        <el-form-item label="服务费" required>
          <el-input v-model="paymentOrderFeeDTO.newFee" type="number" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doChangeRealMoney">确 定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTotalAndSubDetail,
  paySubOffline,
  checkTotalPay,
  getTotalUnionBalance,
  getTotalAlipayBalance,
  getInventory,
  getInventoryIssue,
  batchClosePayment,
  updateBankCode,
  updateInventoryFee,
  downloadReceipt,
  getPaymentInventoryList,
  updatePaymentOrderFee
} from "@/api/pay";
import TaskDetail from "@/views/task/components/taskDetail";
import ButtonUpload from "@/components/Upload/buttonUpload";
import UnionInfo from "@/components/UnionInfo/index";
import XLSX from "xlsx";

export default {
  components: {
    UnionInfo,
    ButtonUpload,
    TaskDetail,
  },
  props: {
    isComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isEdit: false,
      id: undefined,
      visibleDialog: false,
      loading: false,
      submitLoading: false,
      baseInfo: {},
      progressInfo: {},
      paymentListExcelVO: [], // 用来传参导出elcel
      taxInfo: undefined,
      express: {},
      list: [],
      unionPay: [], // 银联余额
      aliPay: [], // 支付宝余额
      totalForm: {
        turnkeyProjectPayment: "",
        payType: 0,
        paymentMode: 0, // 银联支付方式
      },
      totalPayType: [], // 总包支付方式
      subForm: {
        subpackagePayment: "",
        payType: 1,
      },
      checkDialog: false,
      checkData: {
        reasonsForRejection: "",
      },
      isSubPay: false,
      show: false,
      ids: [],
      selectAry: [],
      type: '',
      form: {
        bankCode: '',
        index: undefined,
        inventoryId: undefined
      },
      inventoryFeeDTO: {
        newFee: '',
        index: undefined,
        paymentInventoryId: undefined
      },
      paymentOrderFeeDTO: {
        newFee: '',
        paymentOrderId: ''
      },
      dialogShow: false,
      feeDialogShow: false,
      orderFeeDialogShow: false,
      errLoading: false,
      paramsData: {
        pageNo: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      formData: {
        name: '',
        mobile: '',
        idCode: '',
        orderStatus: '',
      },
      tbLoading: false
    };
  },
  computed: {
    // 是否是审核
    isCheck() {
      return this.$route.meta.isCheck;
    },
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.fetchData();
    this.getPaymentInventoryList()
    this.isEdit = this.$route.meta.isEdit; // 编辑
    this.isSubPay = this.$route.meta.isSubPay;
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
        } else if (index === columns.length - 3) {
          let total = 0;
          data.map((item) => {
            total =
              total +
              (!this.$u.test.isEmptyNotZero(item.merchantPaymentMoney)
                ? +item.merchantPaymentMoney
                : 0);
          });
          sums[index] = "￥" + this.$u.formatMoney(total.toFixed(2));
        } else {
          sums[index] = "";
        }
      });
      return sums;
    },
    setTotalField(field, val) {
      this.totalForm[field] = val;
    },
    setCrowdField(field, val) {
      this.subForm[field] = val;
    },
    fetchData() {
      this.loading = true;
      getTotalAndSubDetail({
        id: this.id,
      })
        .then((res) => {
          const data = res.obj || {};
          if (data.paymentOrderInfoPO.taxStatus > 1) {
            this.show = true;
          }
          if (this.$route.meta.title === "分包支付") {
            this.show = true;
          }
          this.show = true;
          this.baseInfo = data.paymentOrderInfoPO || {};
          this.progressInfo = data.progressInfo || {}
          const paymentMode = +this.baseInfo.paymentMode;
          this.baseInfo.paymentMode = paymentMode;
          if (paymentMode !== 0) {
            if (paymentMode === 1) {
              this.totalForm.payType = "alipay";
              this.fetchAliPayBalance()
              this.fetchBalance(4);
            } else if (paymentMode === 6) {
              this.totalForm.payType = "alipayUnionPay";
            } else {
              this.totalForm.payType = 1;
              this.fetchBalance(paymentMode);
            }
            this.totalForm.paymentMode = paymentMode;
          }
          const companyUnionpayBankTypeList =
            this.baseInfo.companyUnionpayBankTypeList || [];
          const totalPayType = [];
          const allType = this.$enum.unionpayBankType();
          companyUnionpayBankTypeList.map((val) => {
            allType.every((item) => {
              if (item.value === val) {
                totalPayType.push(item);
                return false;
              }
              return true;
            });
          });
          this.totalPayType = totalPayType;
          // this.list = data.paymentInventories || [];
          this.express = data.expressInfoVO || {};
          if (
            !this.$u.test.isEmpty(data.invoice) ||
            !this.$u.test.isEmpty(data.subpackageInvoice)
          ) {
            this.taxInfo = {
              invoice: data.invoice,
              subpackageInvoice: data.subpackageInvoice,
            };
          }
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    async getPaymentInventoryList(type) {
      if (type == 'reset') {
        this.paramsData = {
          pageNo: 1,
          pageSize: 10,
        }
        this.pageTotal = 0
      }
      this.tbLoading = true
      const res = await getPaymentInventoryList({
        orderId: this.id,
        ...this.paramsData,
        ...this.formData
      })
      this.list = res.data || [];
      this.pageTotal = res.pageCount
      this.tbLoading = false
    },
    reset() {
      this.$refs.elForm.resetFields()
      this.getPaymentInventoryList()
    },
    fetchBalance(val) {
      if (this.$u.test.isEmpty(val)) {
        this.unionPay = [];
      } else {
        const unionpayBankTypeList = this.$enum.unionpayBankType();
        let unionpayBankType = "";
        unionpayBankTypeList.every((item) => {
          if (item.mode === val) {
            unionpayBankType = item.value;
            return false;
          }
          return true;
        });
        getTotalUnionBalance({
          taxId: this.baseInfo.taxId,
          merchantId: this.baseInfo.merchantId,
          unionpayBankType: unionpayBankType,
        })
          .then((res) => {
            this.unionPay = res.data || [];
          })
          .catch((res) => {
            this.unionPay = [];
          });

      }
    },
    fetchAliPayBalance() {
      getTotalAlipayBalance({
        taxId: this.baseInfo.taxId,
        merchantId: this.baseInfo.merchantId,
      })
        .then((res) => {
          this.aliPay = res.data || [];
        })
        .catch((res) => {
          this.aliPay = [];
        });
    },
    doPay() {
      this.$confirm("是否确认收款?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.submitLoading = true;
          checkTotalPay({
            boolPass: true,
            paymentOrderId: this.id,
          })
            .then((res) => {
              this.$message.success("操作成功");
              this.$router.go(-1);
              this.submitLoading = false;
            })
            .catch((res) => {
              this.submitLoading = false;
            });
        })
        .catch(() => { });
    },
    rejectPay() {
      if (this.$u.test.isEmpty(this.checkData.reasonsForRejection)) {
        return this.$message.error("请填写驳回原因");
      }
      this.loading = true;
      checkTotalPay({
        boolPass: false,
        paymentOrderId: this.id,
        reasonsForRejection: this.checkData.reasonsForRejection,
      })
        .then((res) => {
          this.$message.success("操作成功");
          this.$router.go(-1);
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    paySubSubmit() {
      const subUrl = this.subForm.subpackagePayment;
      if (this.totalForm.payType === 0 && this.$u.test.isEmpty(subUrl)) {
        return this.$message.error("请上传分包支付回单");
      }
      this.submitLoading = true;
      paySubOffline({
        paymentOrderId: this.id,
        subpackagePayment: subUrl,
      })
        .then((res) => {
          this.isSubPay = false;
          this.fetchData();
          this.getPaymentInventoryList()
          this.$successModal(res.message, () => { });
          this.submitLoading = false;
        })
        .catch((res) => {
          this.submitLoading = false;
        });
    },
    handleSelectionChange(val) {
      this.paymentListExcelVO = [];
      this.ids = [];
      let tmpObj = {};
      if (val.length) {
        this.selectAry = val;
        val.forEach((element) => {
          tmpObj = Object.assign({}, element);
          tmpObj.paymentOrderId = this.baseInfo.paymentOrderId;
          tmpObj.creatDate = this.baseInfo.creatDate;
          tmpObj.paymentDate = this.baseInfo.paymentDate;
          tmpObj.companyName = this.baseInfo.companyName;
          tmpObj.platformServiceProvider =
            this.baseInfo.platformServiceProvider;
          tmpObj.workerMoney = (
            (tmpObj.receviceTax * tmpObj.taskMoney) /
            100
          ).toFixed(2);
          tmpObj.merchantMoney = (
            (tmpObj.merchantTax * tmpObj.taskMoney) /
            100
          ).toFixed(2);
          if (element.paymentStatus === 1) {
            tmpObj.paymentStatus = "支付成功";
          } else if (element.paymentStatus === 0) {
            tmpObj.paymentStatus = "未支付";
          } else {
            tmpObj.paymentStatus = "支付失败";
          }
          this.paymentListExcelVO.push(tmpObj);
          this.ids.push(tmpObj.id);
          console.log("tmpObj", tmpObj);
        });
        console.log("paymentListExcelVO:", this.paymentListExcelVO);
      }
    },
    closePay() {
      if (this.ids.length) {
        let isOk = this.selectAry.every(element => element.paymentStatus == -1);
        if (!isOk) {
          return this.$message.error("请选择支付失败的数据")
        }
        this.loading = true;
        batchClosePayment({
          paymentOrderId: this.baseInfo.paymentOrderId,
          paymentInventoryIds: this.ids.toString(),
        })
          .then((res) => {
            this.loading = false;
            this.$message.success("操作成功");
            this.fetchData();
            this.getPaymentInventoryList()
          })
          .finally(() => {
            console.log("fshabgf");
            this.loading = false;
          });
      } else {
        this.$message.error("请先选中内容，后再操作");
      }
    },
    downloadExcel() {
      if (this.paymentListExcelVO.length) {
        this.loading = true;
        const tableData = [
          [
            "支付单编号",
            "创建时间",
            "商户",
            "服务商",
            "姓名",
            "电话",
            "身份证号",
            '开户行',
            "银行卡号",
            "发放金额",
            "创客服务费率",
            "创客服务费",
            "商户服务费率",
            "商户服务费",
            "创客到手金额",
            "商户支出",
            "支付状态",
            "支付失败原因",
            "支付时间",
          ],
        ];
        let rowData = [];
        this.paymentListExcelVO.forEach((item, index) => {
          rowData = [
            item.paymentOrderId,
            item.creatDate,
            item.companyName,
            item.platformServiceProvider,
            item.workerName,
            item.mobileCode,
            item.idCardCode,
            item.bankName,
            item.bankCode,
            item.taskMoney,
            item.receviceTax,
            item.workerMoney,
            item.merchantTax,
            item.serviceMoney,
            item.realMoney,
            item.merchantPaymentMoney,
            item.paymentStatus,
            item.tradeFailReason,
            item.paymentDate,
          ];
          tableData.push(rowData);
        });
        const ws = XLSX.utils.aoa_to_sheet(tableData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "支付明细");
        XLSX.writeFile(wb, "支付清单明细.xlsx");
        this.$refs.multipleTable.clearSelection()
        setTimeout(() => {
          this.loading = false;
        }, 4000);
      } else {
        this.$message.error("请先选中内容，后再点击导出");
      }
    },
    // 一键导出
    OneclickExcel() {
      this.loading = true;
      this.paymentListExcelVO = [];
      getInventory({ paymentOrderId: this.id })
        .then((res) => {
          this.loading = false;
          const data = res.data || res.obj;
          console.log("data", data);
          this.paymentListExcelVO = data;
          console.log(" this.paymentListExcelVO", this.paymentListExcelVO);
          if (this.paymentListExcelVO.length) {
            this.loading = true;

            const tableData = [
              [
                "支付单编号",
                "创建时间",
                "商户",
                "服务商",
                "姓名",
                "电话",
                "身份证号",
                '开户行',
                "银行卡号",
                "发放金额",
                "创客服务费率",
                "创客服务费",
                "商户服务费率",
                "商户服务费",
                "创客到手金额",
                "商户支出",
                "支付状态",
                "支付失败原因",
                "支付时间",
              ],
            ];
            let rowData = [];
            this.paymentListExcelVO.forEach((item, index) => {
              item.paymentOrderId = this.baseInfo.paymentOrderId;
              item.creatDate = this.baseInfo.creatDate;
              item.paymentDate = this.baseInfo.paymentDate;
              item.companyName = this.baseInfo.companyName;
              item.platformServiceProvider =
                this.baseInfo.platformServiceProvider;
              item.workerMoney = (
                (item.receviceTax * item.taskMoney) /
                100
              ).toFixed(2);
              console.log("item.workerMoney", item.workerMoney);
              item.merchantMoney = (
                (item.merchantTax * item.taskMoney) /
                100
              ).toFixed(2);
              console.log("item.merchantMoney", item.merchantMoney);
              if (item.paymentStatus === 1) {
                item.paymentStatus = "支付成功";
              } else if (item.paymentStatus === 0) {
                item.paymentStatus = "未支付";
              } else {
                item.paymentStatus = "支付失败";
              }
              rowData = [
                item.paymentOrderId,
                item.creatDate,
                item.companyName,
                item.platformServiceProvider,
                item.workerName,
                item.mobileCode,
                item.idCardCode,
                item.bankName,
                item.bankCode,
                item.taskMoney,
                item.receviceTax,
                item.workerMoney,
                item.merchantTax,
                item.serviceMoney,
                item.realMoney,
                item.merchantPaymentMoney,
                item.paymentStatus,
                item.tradeFailReason,
                item.paymentDate,
              ];
              tableData.push(rowData);
            });
            const ws = XLSX.utils.aoa_to_sheet(tableData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "创客明细");
            XLSX.writeFile(wb, "创客明细.xlsx");

            setTimeout(() => {
              this.loading = false;
            }, 4000);
          } else {
            this.$message.error("没有查询到数据");
          }
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    async ExReceipt() {
      this.loading = true;
      try {
        const res = await downloadReceipt({
          paymentInventoryIds: this.ids.toString(),
          paymentOrderId: this.baseInfo.paymentOrderId
        })
        window.location.href = res.obj
        this.$message.success(res.message)
        this.$refs.multipleTable.clearSelection()
        this.loading = false;
      } catch {
        this.loading = false;
      }
    },
    async downloadErrList() {
      const res = await getInventoryIssue({ paymentOrderId: this.id })
      this.errLoading = true;
      const tableData = [
        [
          "序号",
          "收款方账户名",
          "实名手机",
          "身份证号",
          "开户行",
          "收款方账号",
          "发放金额",
          '错误信息',
        ],
      ];
      let rowData = [];
      res.data.forEach((item, index) => {
        rowData = [
          index + 1,
          item.workerName,
          item.mobileCode,
          item.idCardCode,
          item.bankName,
          item.bankCode,
          item.realMoney,
          item.tradeFailReason,
        ];
        tableData.push(rowData);
      });
      const ws = XLSX.utils.aoa_to_sheet(tableData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "校验错误清单");
      XLSX.writeFile(wb, "校验错误清单.xlsx");
      this.errLoading = false;
    },
    handleChangeBank(row, index) {
      this.form.bankCode = row.bankCode
      this.form.paymentInventoryId = row.id
      console.log('this.form.paymentInventoryId', this.form.paymentInventoryId)
      this.form.index = index
      this.loading = false
      this.$nextTick(() => {
        this.dialogShow = true
      })
    },
    doChangeBank() {
      const form = Object.assign({}, this.form)
      if (this.$u.test.isEmpty(form.bankCode)) {
        return this.$message.error('请输入银行卡号')
      }
      this.$confirm('你确定要修改吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        updateBankCode(form).then(res => {
          this.list[form.index].bankCode = form.bankCode
          this.$message.success(res.message)
          this.dialogShow = false
          this.loading = false
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => { })
    },
    handleChangeServiceMoney(row, index) {
      this.inventoryFeeDTO.newFee = row.serviceMoney
      this.inventoryFeeDTO.inventoryId = row.id
      console.log('this.form.paymentInventoryId', this.form.paymentInventoryId)
      this.inventoryFeeDTO.index = index
      this.loading = false
      this.$nextTick(() => {
        this.feeDialogShow = true
      })
    },
    doChangeServiceMoney() {
      const inventoryFeeDTO = Object.assign({}, this.inventoryFeeDTO)
      if (this.$u.test.isEmpty(inventoryFeeDTO.newFee)) {
        return this.$message.error('请输入服务费')
      }
      this.$confirm('你确定要修改吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updateInventoryFee(inventoryFeeDTO).then(res => {
          this.list[inventoryFeeDTO.index].serviceMoney = inventoryFeeDTO.newFee
          this.$message.success(res.message)
          this.fetchData()
          this.getPaymentInventoryList()
          this.feeDialogShow = false
        }).catch(res => {
          this.feeDialogShow = false
        })
      }).catch(() => { })
    },
    handleChangeRealMoney() {
      this.paymentOrderFeeDTO.paymentOrderId = this.id
      this.paymentOrderFeeDTO.newFee = this.baseInfo.serviceMoney
      this.orderFeeDialogShow = true
    },
    doChangeRealMoney() {
      const paymentOrderFeeDTO = Object.assign({}, this.paymentOrderFeeDTO)
      if (this.$u.test.isEmpty(paymentOrderFeeDTO.newFee)) {
        return this.$message.error('请输入服务费')
      }
      this.$confirm('你确定要修改吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        updatePaymentOrderFee(paymentOrderFeeDTO).then(res => {
          this.$message.success(res.message)
          this.fetchData()
          this.orderFeeDialogShow = false
        }).catch(res => {
          this.orderFeeDialogShow = false
        })
      }).catch(() => { })
    },
    handleShow() { },
    showErrorModal(res) {
      console.log(res);
      this.$errorModal(res.message);
    },
    uploadExcel(e) {
      this.fetchData();
    },
    showTask(taskId) {
      this.visibleDialog = true;
      this.$nextTick(() => {
        this.$refs.taskDetail.fetchData(taskId);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.is-edit {
  &.c-table {
    border-spacing: 0;
    border-collapse: collapse;

    td {
      min-width: 300px;
      padding: 8px;
      vertical-align: top;
      border: 1px solid #ddd;
    }
  }

  .no-border {
    td {
      border: 1px solid #fff !important;
    }
  }
}

.is-view {
  &.c-table {
    border-spacing: 0;
    border-collapse: collapse;

    td {
      min-width: 300px;
      padding: 0;
      vertical-align: top;
      border: 0;
    }
  }

  &.blue-box {
    margin-bottom: 20px;
    max-width: 700px;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);

    .money {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }
}

.wl-box {
  padding: 20px;
  font-size: 14px;
  line-height: 1.6;
  max-width: 930px;
  width: 80%;
  background: rgba(246, 250, 255, 1);
  border: 1px solid rgba(186, 220, 254, 1);

  >div {
    margin: 5px 0;
  }
}

.wl-content {
  max-width: 930px;
  width: 80%;
  padding: 20px;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  background: #fbfbfc;
  border: 1px solid #eaeaea;
}
</style>
