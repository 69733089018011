<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="resetPage">
      <el-tab-pane label="线下支付" name="offline" />
      <el-tab-pane label="银联支付" name="unionPay" />
      <el-tab-pane label="支付宝" name="alipay" />
      <el-tab-pane label="招行" name="zsPay" />
    </el-tabs>
    <div v-if="activeName === 'zsPay' || activeName === 'unionPay' || activeName === 'offline' || activeName === 'alipay'">
      <el-table ref="multipleTable" v-loading="listLoading" :data="list" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="taxName" label="服务商名称" align="center" />
        <el-table-column prop="creditCode" label="统一社会信用代码" align="center" />
        <el-table-column prop="linkMan" label="公司联系人" align="center" />
        <el-table-column prop="linkMobile" label="公司联系电话" align="center" />
        <el-table-column prop="taxCreateDate" width="180" label="公司成立时间" align="center" />
        <el-table-column width="80" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
    </div>
    <div v-else-if="activeName === 'AliPayUnionPay'">
      <div v-if="$u.test.isEmpty(alipayUnionPayData)" class="u-text-center">
        <el-button :loading="listLoading" type="primary" @click="handleAlipayUnionPayAgreement(true)">点击授权</el-button>
      </div>
      <el-table v-else key="AliPayUnionPayTable" v-loading="listLoading" :data="alipayUnionPayData" tooltip-effect="dark" style="width: 100%">
        <!--        <el-table-column-->
        <!--          prop="signOrderType"-->
        <!--          label="授权签约对象"-->
        <!--          align="center"-->
        <!--        />-->
        <el-table-column prop="signOrderId" label="用户签约号" align="center" />
        <el-table-column key="alipayUnionpaySignType" label="子账户类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.alipayUnionpaySignType | alipayUnionpaySignType }}
          </template>
        </el-table-column>
        <el-table-column prop="alipayUserId" label="支付宝唯一用户号" align="center" />
        <el-table-column prop="subAccountCode" label="子账户账号" align="center" />
        <el-table-column label="子账户户名" prop="subAccountName" align="center" />
        <el-table-column prop="createDate" width="180" label="创建时间" align="center" />
        <el-table-column key="alipayBalance" label="可用余额" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleAlipayUnionPayBalance(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column width="120" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="scope.row.subAccountCode" type="text" @click="handleAlipayUnionPayWithdraw(scope.row)">提现</el-button>
            <el-button v-else type="text" @click="handleAlipayUnionPaySub(scope.row)">开通子账户</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <!-- <div v-if="$u.test.isEmpty(alipayData)" class="u-text-center">
        <el-button :loading="listLoading" type="primary" @click="handleAlipayAgreement(true)">点击授权</el-button>
      </div> -->
      <div>
        <el-table ref="multipleTable1" v-loading="listLoading" :data="alipayData" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="agreementNo" label="支付宝协议号" align="center" />
          <el-table-column prop="accountBookId" label="资金记账本ID" align="center" />
          <el-table-column prop="alipayUserId" label="支付宝唯一用户号" align="center" />
          <el-table-column key="alipayStatus" label="协议当前状态" align="center">
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.status, $enum.alipayStatus(), false)" />
            </template>
          </el-table-column>
          <el-table-column key="alipaySignType" label="记事本类型" align="center">
            <template slot-scope="scope">
              {{ scope.row.alipaySignType | alipaySignType }}
            </template>
          </el-table-column>
          <el-table-column prop="createDate" width="180" label="创建时间" align="center" />
          <el-table-column key="alipayBalance" label="可用余额" align="center">
            <template slot-scope="scope">
              {{ scope.row.alipayBalance ? scope.row.alipayBalance : '获取中' }}
            </template>
          </el-table-column>
          <el-table-column width="120" label="操作" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status !== 'NORMAL'" type="text" @click="handleAlipayAgreement(false, scope.row)">授权</el-button>
              <el-button v-else type="text" @click="handleAlipayUnsign(scope.row)">解约</el-button>
              <el-button v-if="scope.row.status === 'NORMAL'" type="text" @click="handleAlipayWithdraw(scope.row)">提现</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog title="服务商合作信息" :visible.sync="dialogVisible" width="90vw" top="5vh">
      <service-info ref="serviceInfo" :merchant-id="merchantId" />
    </el-dialog>
    <el-dialog title="支付宝提现" :visible.sync="alipayWithdrawDialog">
      <withdraw-by-alipay ref="withdrawByAlipay" width="600px" :merchant-id="merchantId" @success="alipayWithdrawDialog = false" />
    </el-dialog>
    <!-- 银联支付宝-开通子账户 -->
    <el-dialog title="开通子账户" :visible.sync="alipayUnionPaySignDialog">
      <alipay-union-pay-sign ref="alipayUnionPaySign" width="600px" :uid="uuidAlipayUnionPay" @success="alipayUnionPaySignDialog = false" />
    </el-dialog>
    <el-dialog title="支付宝银联联动余额" :visible.sync="alipayUnionPayBalanceDialog.show">
      <el-row v-loading="alipayUnionPayBalanceDialog.loading" class="">
        <div class="u-m-b-10">可用余额：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.useBal) }}</div>
        <div class="u-m-b-10">冻结余额(平台)：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.pfrzBal) }}</div>
        <div class="u-m-b-10">冻结余额(银行)：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.bfrzBal) }}</div>
        <div class="u-m-b-10">在途余额(入)：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.iwayBal) }}</div>
        <div class="u-m-b-10">在途余额(出)：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.owayBal) }}</div>
        <div class="u-m-b-10">账面余额：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.actBal) }}</div>
      </el-row>
    </el-dialog>
    <el-dialog title="支付宝银联联动提现" :visible.sync="alipayUnionPayWithdrawDialog">
      <withdraw-by-alipay-union-pay ref="withdrawByAlipayUnionPay" width="600px" :merchant-id="merchantId" @success="alipayUnionPayWithdrawDialog = false" />
    </el-dialog>
  </div>
</template>

<script>
import { creatAUSign, getOfflineList, getUnionPayList, queryAlipayTaxList, getAlipayAgreement, getAlipayBalance, alipayUnSign, getAlipayUnionPayList, getAlipayUnionPayBalance, queryZspayTaxList } from '@/api/shop'
import ServiceInfo from '@/views/shop/components/serviceInfo'
import WithdrawByAlipay from '@/views/shop/components/WithdrawByAlipay'
import AlipayUnionPaySign from '@/views/shop/components/AlipayUnionPaySign'
import WithdrawByAlipayUnionPay from '@/views/shop/components/WithdrawByAlipayUnionPay'

export default {
  name: 'PayTypeList',
  components: { WithdrawByAlipayUnionPay, AlipayUnionPaySign, WithdrawByAlipay, ServiceInfo },
  props: {
    merchantId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      uuidAlipayUnionPay: '',
      dialogVisible: false,
      listLoading: false,
      list: [],
      activeName: 'unionPay',
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      },
      alipayData: undefined,
      balanceLoading: false,
      alipayBalance: '获取中',
      alipayWithdrawDialog: false,
      alipayUnionPayData: undefined,
      alipayUnionPaySignDialog: false,
      alipayUnionPayWithdrawDialog: false,
      alipayUnionPayBalanceDialog: {
        loading: false,
        data: {},
        show: false
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.listLoading = true
      if (this.activeName === 'offline') {
        getOfflineList(Object.assign({}, {
          merchantId: this.merchantId
        }, this.paramsData)).then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      } else if (this.activeName === 'unionPay') {
        getUnionPayList(Object.assign({}, {
          merchantId: this.merchantId
        }, this.paramsData)).then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      } else if (this.activeName === 'zsPay') {
        queryZspayTaxList(Object.assign({}, {
          merchantId: this.merchantId
        }, this.paramsData)).then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      } else if (this.activeName === 'alipay') {
        queryAlipayTaxList(Object.assign({}, {
          merchantId: this.merchantId
        }, this.paramsData)).then(res => {
          this.list = res.data || []
          this.pageTotal = res.pageCount
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      } else if (this.activeName === 'AliPayUnionPay') {
        getAlipayUnionPayList({
          signOrderId: this.merchantId
        }).then(res => {
          const data = res.data || []
          const isNull = !data.length
          this.alipayUnionPayData = isNull ? [] : data
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      }
    },
    handleShow(row) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.serviceInfo.fetchData(row.id, this.activeName)
      })
    },
    handleAlipayAgreement(isNew, row = {}) {
      this.listLoading = true
      getAlipayAgreement({
        merchantId: this.merchantId,
        alipaySignId: row.id
      }, isNew === true).then(res => {
        if (isNew) {
          this.fetchData()
        } else {
          window.open(res.obj)
        }
        this.listLoading = false
      }).catch(res => {
        this.listLoading = false
      })
    },
    handleAlipayUnionPayAgreement() {
      // this.alipayUnionPaySignDialog = true
      this.listLoading = true
      creatAUSign({
        merchantId: this.merchantId
      }).then(res => {
        this.listLoading = false
        this.fetchData()
      }).catch(res => {
        this.listLoading = false
      })
    },
    handleAlipayUnionPaySub(item) {
      this.uuidAlipayUnionPay = item.uid
      this.alipayUnionPaySignDialog = true
    },
    async handleAlipayBalance(arr) {
      const newData = []
      await Promise.all(arr.map(async(row) => {
        const balance = await getAlipayBalance({
          alipaySignId: row.id
        }).then(res => {
          return '￥' + (res.obj || 0)
        }).catch(res => {
          return '获取失败'
        })
        newData.push(Object.assign({}, row, {
          alipayBalance: balance
        }))
      }))
      this.alipayData = newData
    },
    handleAlipayUnionPayBalance(row) {
      this.alipayUnionPayBalanceDialog.show = true
      this.alipayUnionPayBalanceDialog.loading = true
      getAlipayUnionPayBalance({
        signOrderId: row.signOrderId
      }).then(res => {
        this.alipayUnionPayBalanceDialog.data = res.obj || {}
        this.alipayUnionPayBalanceDialog.loading = false
      }).catch(res => {
        this.alipayUnionPayBalanceDialog.loading = false
      })
    },
    handleAlipayUnsign(row) {
      this.$confirm('你确定要解约吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true
        alipayUnSign({
          merchantId: this.merchantId,
          alipaySignId: row.id
        }).then(res => {
          this.$message.success(res.message)
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      }).catch(() => {})
    },
    handleAlipayWithdraw(row) {
      this.alipayWithdrawDialog = true
      this.$nextTick(() => {
        this.$refs.withdrawByAlipay.setData({
          alipaySignId: row.id
        })
      })
    },
    handleAlipayUnionPayWithdraw(row) {
      this.alipayUnionPayWithdrawDialog = true
      this.$nextTick(() => {
        this.$refs.withdrawByAlipayUnionPay.setData({
          signOrderId: row.signOrderId,
          alipayUnionpaySignType: row.alipayUnionpaySignType
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
