<template>
  <div class="u-p-20">
    <div class="bg-white u-p-20">
      <el-button v-if="masterList.length && userSign === 3" type="primary" size="small" icon="el-icon-plus" @click="handleAdd({})">
        添加子账号
      </el-button>
      <div class="u-m-t-20">
        <div class="title">主账号</div>
        <el-table v-loading="loading" :data="masterList" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="realNmae" label="姓名" align="center" />
          <el-table-column label="角色" align="center">
            <template slot-scope="scope">
              {{ scope.row.roleName }}
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="登录账号" align="center" />
          <el-table-column prop="loginMoblie" label="手机号" align="center" />
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.statue, $enum.authStatus(), true)" />
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.createDate }}
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handlePwd(scope.row)">修改密码</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="userSign === 3" class="u-m-t-50">
        <div class="title">子账号</div>
        <el-table ref="multipleTable" v-loading="loading" :data="list" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="realNmae" label="姓名" align="center" />
          <el-table-column label="角色" align="center">
            <template slot-scope="scope">
              {{ scope.row.roleName }}
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="登录账号" align="center" />
          <el-table-column prop="loginMoblie" label="手机号" align="center" />
          <el-table-column label="权限" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.list | filterMenus }}
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.statue, $enum.authStatus(), true)" />
            </template>
          </el-table-column>
          <el-table-column label="创建时间" align="center">
            <template slot-scope="scope">
              {{ scope.row.createDate }}
            </template>
          </el-table-column>
          <el-table-column width="200" label="操作" align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="handleDel(scope.row)">删除</el-button>
              <el-button v-if="scope.row.roleName === '测试'" type="text" size="small" @click="handleSerialNumber(scope.row)">序列号</el-button>
              <el-button v-if="scope.row.statue === 1" type="text" size="small" @click="handleStop(scope.row, 'STARTUSING')">启用</el-button>
              <el-button v-else-if="scope.row.statue === 0" type="text" size="small" @click="handleStop(scope.row, 'BLOCKUP')">停用</el-button>
              <el-button type="text" size="small" @click="handleAdd(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-dialog title="修改密码" :visible.sync="dialogVisible" width="800px">
        <forget ref="forget" @success="dialogVisible = false" />
      </el-dialog>
      <el-dialog v-loading="dialogLoading" title="序列号" :visible.sync="dialogVisibleTest" width="800px">
        <div style="height: 40px;">序列号：{{ serialData.serialNumber }}</div>
        <div>过期时间
          <el-date-picker
            v-model="serialData.expireDate"
            type="datetime"
            placeholder="选择日期时间"
            value-format="yyyy-MM-dd HH:mm"
            default-time="23:00:00"
          />
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleTest = false">取 消</el-button>
          <el-button type="primary" @click="refreshNumber()">刷新</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getAuthList, changeAuthStatus, delAuth, getSerialNumber, refreshTestManagers } from '@/api/auth'
import Forget from '@/views/auth/components/forget'

export default {
  components: { Forget },
  filters: {
    filterMenus(val) {
      const menus = []
      if (val && val.length) {
        for (let i = 0; i < val.length; i++) {
          menus.push(val[i].menuZhname)
        }
      }
      return menus.join(',')
    }
  },
  data() {
    return {
      serialData: {
        serialNumber: '',
        id: '',
        expireDate: ''
      },
      dialogVisible: false,
      dialogVisibleTest: false,
      loading: false,
      dialogLoading: false,
      formData: {
        keyWord: ''
      },
      masterList: [],
      list: []
    }
  },
  computed: {
    // 1.代理商 2.业务员 3.平台管理员
    userSign() {
      const userSign = this.$store.getters.userinfo.userSign || 3
      return (+userSign)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getAuthList().then(res => {
        const data = res.data || []
        const list = []
        const masterList = []
        for (let i = 0, len = data.length; i < len; i++) {
          const parentId = (+data[i].parentId)
          if (this.$u.test.isEmpty(parentId)) {
            masterList.push(data[i])
          } else {
            list.push(data[i])
          }
        }
        this.list = list
        this.masterList = masterList
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleStop(i, code) {
      this.$confirm('是否确认此操作?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        changeAuthStatus({
          managersId: i.managersId,
          status: i.statue === 0 ? 1 : 0
        }).then(res => {
          this.$message.success('操作成功')
          this.fetchData()
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {
      })
    },
    handleDel(i, code) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        delAuth({
          managersId: i.managersId
        }).then(res => {
          this.$message.success('操作成功')
          this.fetchData()
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {
      })
    },
    handleAdd(row) {
      this.$router.push({
        path: '/auth/add',
        query: {
          id: row.managersId
        }
      })
    },
    handlePwd(i) {
      this.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.forget.setPhone(i.loginMoblie)
      })
    },
    handleSerialNumber(i) {
      this.dialogVisibleTest = true
      this.dialogLoading = true
      this.serialData = {}
      getSerialNumber({
        managerId: i.managersId
      }).then(res => {
        this.dialogLoading = false
        // this.$message.success('操作成功')
        if (res.obj) {
          this.serialData = res.obj
        }
        console.log(this.serialData)
      }).catch(res => {
        this.dialogLoading = false
      })
    },
    refreshNumber() {
      if (!this.serialData.expireDate) {
        this.$message.error('请选择过期时间')
        return
      }
      this.dialogLoading = true
      refreshTestManagers({
        expireDate: this.serialData.expireDate,
        testManagerId: this.serialData.id
      }).then(res => {
        this.dialogLoading = false
        this.$message.success('刷新成功')
        if (res.obj) {
          this.serialData = res.obj
        }
      }).catch(res => {
        this.dialogLoading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.title {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 24px;
}
</style>
