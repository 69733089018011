<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-card v-loading="loading">
      <div class="u-flex u-col-center user-base-box">
        <div class="name u-flex u-col-center u-flex-1">
          <avatar class="u-m-r-10" :src="baseInfo.user_head" :size="50" />
          <div>
            <div class="u-font-18">商户：{{ baseInfo.companyName }}</div>
            <div class="u-font-14 u-m-t-5">商户编号：{{ baseInfo.id }}</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-row v-loading="countLoading" :gutter="20">
      <el-col :md="9" :xs="24" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">总支付流水</div>
          <div>
            <div class="u-font-14 u-tips-color">总包+分包(交易金额/服务费)</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.paymentTotalMoney) || 0 }} / &yen;{{ $u.formatMoney(count.paymentTotalServiceMoney) || 0 }}</div>
            <div class="u-font-14 u-tips-color u-m-t-10">众包</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.paymentManyMoney) || 0 }} / &yen;{{ $u.formatMoney(count.paymentManyServiceMoney) || 0 }}</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="5" :xs="8" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">总包+分包发票</div>
          <div>
            <div class="u-font-14 u-tips-color">已开票数</div>
            <div class="u-font-20 u-m-t-5">{{ count.invoiceTotalCount || 0 }}</div>
            <div class="u-font-14 u-tips-color u-m-t-10">已开票金额</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.invoiceTotalMoney) || 0 }}</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="5" :xs="8" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">众包发票</div>
          <div>
            <div class="u-font-14 u-tips-color">已开票数</div>
            <div class="u-font-20 u-m-t-5">{{ count.invoiceManyCount || 0 }}</div>
            <div class="u-font-14 u-tips-color u-m-t-10">已开票金额/代开金额</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.invoiceManyMoney) || 0 }} / &yen;{{ $u.formatMoney(count.invoiceManyDKMoney) || 0 }}</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="5" :xs="8" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">创客</div>
          <div>
            <div class="u-flex u-col-center u-row-between">
              <div>
                <div class="u-font-14 u-tips-color">总创客数</div>
                <div class="u-font-20 u-m-t-5">{{ count.workerTotal || 0 }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card header="合作服务商" class="u-m-t-20">
      <cooperation-service :merchant-id="id" />
    </el-card>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getDetail, count } from '@/api/shop'
import CooperationService from '@/views/shop/components/cooperationService'

export default {
  components: { CooperationService },
  data() {
    return {
      id: undefined,
      loading: false,
      countLoading: false,
      baseInfo: {},
      count: {}
    }
  },
  created() {
    this.id = this.$route.query.id
    this.fetchDetail()
  },
  methods: {
    fetchDetail() {
      this.loading = true
      getDetail({
        merchantId: this.id
      }).then(res => {
        this.baseInfo = res.obj.companyInfoVo || {}
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
      count({
        merchantId: this.id
      }).then(res => {
        this.count = res.obj || {}
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.renzheng {
  width: 12px;
  height: 12px;
  color: #ffbd00;
}
.user-base-box {
  .name {
    margin-left: 20px;
    font-size: 20px;
    color: #333;
  }

  .tag {
    display: inline-block;
    margin-left: 2px;
    height: 20px;
    line-height: 16px;
    padding: 2px 5px;
    background: rgba(64, 108, 255, 1);
    border-radius: 25px;
    font-size: 9px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .sub {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }

  .value {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
