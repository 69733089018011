<template>
  <el-row v-loading="loading" :gutter="10">
    <el-col :md="9" :xs="24">
      <el-card class="c-card u-m-b-10">
        <div slot="header" class="header">总支付流水</div>
        <div class="sub">
          总包+分包(交易金额/服务费)
        </div>
        <div class="value">&yen;{{ $u.formatMoney(item.paymentTotalMoney) || 0 }} / &yen;{{ $u.formatMoney(item.paymentTotalServiceMoney) || 0 }}</div>
        <div class="sub">
          众包(交易金额/服务费)
        </div>
        <div class="value">&yen;{{ $u.formatMoney(item.paymentManyMoney) || 0 }} / &yen;{{ $u.formatMoney(item.paymentManyServiceMoney) || 0 }}</div>
      </el-card>
    </el-col>
    <el-col :md="5" :xs="12">
      <el-card class="c-card u-m-b-10">
        <div slot="header" class="header">总包+分包发票</div>
        <div class="sub">
          已开票数
        </div>
        <div class="value">{{ item.invoiceTotalCount || 0 }}</div>
        <div class="sub">
          已开票金额
        </div>
        <div class="value">&yen;{{ $u.formatMoney(item.invoiceTotalMoney) || 0 }}</div>
      </el-card>
    </el-col>
    <el-col :md="5" :xs="12">
      <el-card class="c-card u-m-b-10">
        <div slot="header" class="header">众包发票</div>
        <div class="sub">
          已开票数
        </div>
        <div class="value">{{ item.invoiceManyCount || 0 }}</div>
        <div class="sub">
          已开票金额/代开金额
        </div>
        <div class="value">&yen;{{ $u.formatMoney(item.invoiceManyMoney) || 0 }} / &yen;{{ $u.formatMoney(item.invoiceManyDKMoney) || 0 }}</div>
      </el-card>
    </el-col>
    <el-col :md="5" :xs="12">
      <el-card class="c-card u-m-b-10">
        <div slot="header" class="header">商户&创客</div>
        <div class="sub">
          总商户数
        </div>
        <div class="value">{{ item.merchantTotal || 0 }}</div>
        <div class="sub">
          总创客数
        </div>
        <div class="value">{{ item.workerTotal || 0 }}</div>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
import { getInfo } from '@/api/dashboard'

export default {
  name: 'Count',
  data() {
    return {
      loading: false,
      item: {}
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      getInfo().then(res => {
        this.item = res.obj || {}
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.sub {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
}
.value {
  margin-top: 4px;
  margin-bottom: 13px;
  font-size: 24px;
  font-weight: 400;
  color: #333333;
}
.c-card {
  min-height: 215px;
}
</style>
