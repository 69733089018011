<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card>
          <div class="u-flex u-col-center user-base-box">
            <div class="name u-flex u-col-center u-flex-1"><avatar class="u-m-r-10" :src="queryData.user_head" :size="50" />
              <div>
                <div class="u-font-18">商户：{{ queryData.merchantName }}</div>
                <div class="u-font-14 u-m-t-5">商户编号：{{ queryData.merchantId }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div class="u-flex u-col-center user-base-box">
            <div class="name u-flex u-col-center u-flex-1"><avatar class="u-m-r-10" :src="queryData.user_head" :size="50" />
              <div>
                <div class="u-font-18">服务商：{{ queryData.taxName }}</div>
                <div class="u-font-14 u-m-t-5">服务商编号：{{ queryData.taxId }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <div v-if="(+queryData.packageStatus) === 0" class="u-m-t-20">
      <total-detail is-component />
    </div>
    <div v-else class="u-m-t-20">
      <crowd-detail is-component />
    </div>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>

import TotalDetail from '@/views/pay/totalAndSub/view'
import CrowdDetail from '@/views/pay/crowd/view'
export default {
  components: { TotalDetail, CrowdDetail },
  data() {
    return {
      queryData: {},
      md: 8
    }
  },
  created() {
    const { paymentId, packageStatus, merchantId, merchantName, taxId, taxName } = this.$route.query
    this.queryData = { paymentId, packageStatus, merchantId, merchantName, taxId, taxName }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.is-edit {
  &.c-table {
    border-spacing: 0;
    border-collapse: collapse;
    td {
      min-width: 300px;
      padding: 8px;
      vertical-align: top;
      border: 1px solid #ddd;
    }
  }
}
.is-view {
  &.c-table {
    border-spacing: 0;
    border-collapse: collapse;
    td {
      min-width: 300px;
      padding: 0;
      vertical-align: top;
      border: 0;
    }
  }
  &.blue-box {
    margin-bottom: 20px;
    max-width: 700px;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    .money {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }
}
.section-body {
  > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .label {
    margin-right: 5px;
  }
}
::v-deep{
  .el-table .select-row {
    background: #d6f0ca;
    border: 1px solid #52c41a;
  }
}
.wl-box {
  padding: 20px;
  font-size:14px;
  line-height: 1.6;
  max-width: 930px;
  width: 80%;
  background:rgba(246,250,255,1);
  border:1px solid rgba(186,220,254,1);
  >div {
    margin: 5px 0;
  }
}
.wl-content {
  max-width: 930px;
  width: 80%;
  padding: 20px;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  background: #fbfbfc;
  border: 1px solid #eaeaea;
}
</style>
