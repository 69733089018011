var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
        },
        [
          _c("div", { staticClass: "u-flex u-col-center user-base-box" }, [
            _c(
              "div",
              { staticClass: "name u-flex u-col-center u-flex-1" },
              [
                _c("avatar", {
                  staticClass: "u-m-r-10",
                  attrs: { src: _vm.baseInfo.avatar, size: 50 },
                }),
                _vm._v(" " + _vm._s(_vm.baseInfo.accountName) + " "),
                parseInt(_vm.baseInfo.attestation) === 2
                  ? _c(
                      "span",
                      { staticClass: "tag" },
                      [
                        _c("svg-icon", {
                          attrs: {
                            "class-name": "renzheng",
                            "icon-class": "renzheng",
                          },
                        }),
                        _vm._v("认证用户 "),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "u-flex u-col-center u-flex-2 u-row-around" },
              [
                _c("div", [
                  _c("div", { staticClass: "sub" }, [_vm._v("任务总数")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.count.taskNumber || 0)),
                  ]),
                ]),
                _c("div", [
                  _c("div", { staticClass: "sub" }, [_vm._v("收入总额")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.count.totalRevenue || 0)),
                  ]),
                ]),
                _c("div", [
                  _c("div", { staticClass: "sub" }, [_vm._v("纳税总额")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.count.totalTax || 0)),
                  ]),
                ]),
                _c("div", [
                  _c("div", { staticClass: "sub" }, [_vm._v("对应发票数")]),
                  _c("div", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.count.invoiceNumber || 0)),
                  ]),
                ]),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "u-m-t-20",
        },
        [
          _c("div", { staticClass: "card--title" }, [_vm._v("创客信息")]),
          _c(
            "div",
            { staticClass: "card--content" },
            [
              _c(
                "el-row",
                { staticClass: "card--info", attrs: { gutter: 20 } },
                [
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("创客编号："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.id)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("创客姓名："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.accountName)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [_vm._v("手机号：")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.mobileCode)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("实名认证："),
                    ]),
                    _c("span", {
                      staticClass: "value",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.filterContent(
                            _vm.baseInfo.attestation,
                            _vm.$enum.workAttestation()
                          )
                        ),
                      },
                    }),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("签约状态："),
                    ]),
                    _c("span", {
                      staticClass: "value",
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.filterContent(
                            _vm.baseInfo.agreementSign,
                            _vm.$enum.workAgreementSign()
                          )
                        ),
                      },
                    }),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("身份证号："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.idcardCode)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("身份证照片："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          staticClass: "u-m-r-5",
                          attrs: {
                            url: _vm.baseInfo.idcardFront,
                            btn: "",
                            "btn-text": "查看正面",
                          },
                        }),
                        _c("file-icon", {
                          attrs: {
                            url: _vm.baseInfo.idcardBack,
                            btn: "",
                            "btn-text": "查看背面",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("银行卡号："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.bankCode)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [_vm._v("开户行：")]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.bankName)),
                    ]),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("认证视频："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: {
                            url: _vm.baseInfo.attestationVideo,
                            btn: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("加盟合同："),
                    ]),
                    _c(
                      "span",
                      { staticClass: "value" },
                      [
                        _c("file-icon", {
                          attrs: { url: _vm.baseInfo.agreementUrl, btn: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("el-col", { attrs: { md: 8 } }, [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("入驻时间："),
                    ]),
                    _c("span", { staticClass: "value" }, [
                      _vm._v(_vm._s(_vm.baseInfo.createDate)),
                    ]),
                  ]),
                  !_vm.boolMakerVerify
                    ? _c("el-col", { attrs: { md: 8 } }, [
                        _c("span", { staticClass: "label" }, [
                          _vm._v("自由者职业协议："),
                        ]),
                        _c(
                          "span",
                          { staticClass: "value" },
                          [
                            _c("file-icon", {
                              attrs: {
                                url: _vm.baseInfo.freeWorkerAgreementUrl,
                                btn: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("合同详情")]),
        _c(
          "div",
          { staticClass: "card--content" },
          [_c("contract-list", { attrs: { "worker-id": _vm.id } })],
          1
        ),
      ]),
      _c(
        "el-card",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading",
            },
          ],
          staticClass: "u-m-t-20",
        },
        [
          _c("div", { staticClass: "card--title" }, [
            _vm._v("创客任务详情信息"),
          ]),
          _c(
            "div",
            { staticClass: "card--content" },
            [
              _c(
                "el-table",
                {
                  ref: "multipleTable",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.list, "tooltip-effect": "dark" },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "accountName",
                      label: "姓名",
                      width: "100",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "companyName",
                      width: "120",
                      label: "发包商户名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "taskName",
                      width: "180",
                      label: "任务名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "任务金额", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  !_vm.$u.test.isEmptyNotZero(
                                    scope.row.taskAmount
                                  )
                                    ? "￥" +
                                        _vm.$u.formatMoney(scope.row.taskAmount)
                                    : "-"
                                ) +
                                " "
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { label: "任务状态", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.$options.filters.filterContent(
                                    scope.row.taskStatus,
                                    _vm.$enum.taskState()
                                  )
                                ),
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "createDate",
                      label: "接单时间",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "checkDate",
                      label: "完成时间",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pager-box" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next, jumper",
                      "current-page": _vm.paramsData.pageNo,
                      "page-size": _vm.paramsData.pageSize,
                      "page-count": _vm.pageTotal,
                    },
                    on: {
                      "update:currentPage": function ($event) {
                        return _vm.$set(_vm.paramsData, "pageNo", $event)
                      },
                      "update:current-page": function ($event) {
                        return _vm.$set(_vm.paramsData, "pageNo", $event)
                      },
                      "current-change": _vm.fetchData,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("支付列表明细")]),
        _c(
          "div",
          { staticClass: "card--content" },
          [_c("pay-list", { attrs: { "worker-id": _vm.id } })],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }