var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-m-25 wrap-fixed-content" },
    [
      _c(
        "el-card",
        { attrs: { header: "基本信息" } },
        [
          _c(
            "el-form",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "formData",
              staticStyle: { "max-width": "800px" },
              attrs: {
                model: _vm.formData,
                rules: _vm.rules,
                "label-width": "250px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "代理商名称", prop: "agentName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入姓名" },
                    model: {
                      value: _vm.formData.agentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "agentName", $$v)
                      },
                      expression: "formData.agentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "登录账号", prop: "userName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入登录账号" },
                    model: {
                      value: _vm.formData.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "userName", $$v)
                      },
                      expression: "formData.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "linkMan" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入联系人" },
                    model: {
                      value: _vm.formData.linkMan,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "linkMan", $$v)
                      },
                      expression: "formData.linkMan",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话", prop: "linkMobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入电话" },
                    model: {
                      value: _vm.formData.linkMobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "linkMobile", $$v)
                      },
                      expression: "formData.linkMobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "代理商加盟合同", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-center" },
                    [
                      _c("button-upload", {
                        attrs: { type: "image", text: "上传合同(png/jpg)" },
                        on: {
                          "upload-success": function ($event) {
                            return _vm.setField("contractFile", $event)
                          },
                        },
                      }),
                      !_vm.$u.test.isEmpty(_vm.formData.contractFile)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(
                                    _vm.formData.contractFile
                                  )
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "平台业务专员", prop: "salesManId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        placeholder: "平台业务专员",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.formData.salesManId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "salesManId", $$v)
                        },
                        expression: "formData.salesManId",
                      },
                    },
                    _vm._l(_vm.salesMan, function (i) {
                      return _c("el-option", {
                        key: i.id,
                        attrs: { label: i.realName, value: i.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.$u.test.isEmpty(_vm.formData.agentId)
                ? _c(
                    "el-form-item",
                    { attrs: { label: "初始密码", prop: "initPassWord" } },
                    [
                      _c("el-input", {
                        staticClass: "no-autofill-pwd",
                        attrs: { type: "text", placeholder: "请输入初始密码" },
                        model: {
                          value: _vm.formData.initPassWord,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "initPassWord", $$v)
                          },
                          expression: "formData.initPassWord",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    "el-form-item",
                    { attrs: { label: "修改密码" } },
                    [
                      _c("el-input", {
                        staticClass: "no-autofill-pwd",
                        attrs: { type: "text", placeholder: "为空则不修改" },
                        model: {
                          value: _vm.formData.initPassWord,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "initPassWord", $$v)
                          },
                          expression: "formData.initPassWord",
                        },
                      }),
                    ],
                    1
                  ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码" } },
                [
                  _c("el-input", {
                    staticClass: "no-autofill-pwd",
                    attrs: { type: "text", placeholder: "请输入" },
                    model: {
                      value: _vm.formData.confirmPassWord,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "confirmPassWord", $$v)
                      },
                      expression: "formData.confirmPassWord",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.agentStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "agentStatus", $$v)
                        },
                        expression: "formData.agentStatus",
                      },
                    },
                    _vm._l(_vm.$enum.authStatus(), function (i, k) {
                      return _c(
                        "el-radio",
                        { key: k, attrs: { label: i.value } },
                        [_vm._v(_vm._s(i.name))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-card",
        {
          staticClass: "u-m-t-20",
          attrs: { header: "结算信息（按服务费率差额提成）" },
        },
        [
          _c("tax-detail", {
            ref: "taxDetail",
            model: {
              value: _vm.customForm,
              callback: function ($$v) {
                _vm.customForm = $$v
              },
              expression: "customForm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formData")
                },
              },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }