<template>
  <div v-if="!$u.test.isEmpty(url)" style="display: inline-block">
    <template v-if="img">
      <el-image
        :style="imgStyle"
        :src="url"
        @click="show"
      />
    </template>
    <template v-else-if="btn">
      <el-button type="text" size="small" @click="show">{{ btnText }}</el-button>
    </template>
    <template v-else>
      <svg-icon v-if="type === 'pdf'" class-name="pdf" icon-class="pdf" @click="show" />
      <svg-icon v-else-if="type === 'img'" class-name="img" icon-class="img" @click="show" />
      <svg-icon v-else-if="type === 'excel'" class-name="excel" icon-class="excel" @click="show" />
      <i v-else-if="type === 'video'" class="el-icon-video-camera" @click="show" />
      <i v-else-if="type === 'file'" class="el-icon-tickets" @click="show" />
    </template>
  </div>
</template>

<script>

export default {
  name: 'FileIcon',
  props: {
    url: {
      type: String,
      default: ''
    },
    img: {
      type: Boolean,
      default: false
    },
    btn: {
      type: Boolean,
      default: false
    },
    btnText: {
      type: String,
      default: '查看'
    },
    imgStyle: {
      type: String,
      default: ''
    },
    multi: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      type: ''
    }
  },
  watch: {
    url: function(newVal) {
      this.type = this.$checkFileType(newVal)
    }
  },
  created() {
    this.type = this.$checkFileType(this.url)
  },
  methods: {
    show() {
      this.$preViewer(this.url)
    }
  }
}
</script>

<style lang="scss" scoped>
  .pdf {
    width: 30px;
    height: 30px;
    color: #F4696C;
  }
  .img {
    width: 30px;
    height: 30px;
    color: #2178AB;
  }
  .excel {
    width: 30px;
    height: 30px;
    color: #58B994;
  }
  .el-icon-tickets, .el-icon-video-camera {
    font-size: 30px;
    color: #58B994;
  }
</style>
