<template>
  <div class="u-p-24">
    <div class="u-p-20 bg-white">
      <span>商户名称：</span>
      <span>{{ workerData.name }}</span>
    </div>
    <div class="u-p-lf-10 bg-white">
      <div class="u-p-l-10">待处理预警({{ itemsCount }})</div>
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="编号"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="cause"
          label="预警原因"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.status|statusFilter }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="预警时间"
          align="center"
        />
        <el-table-column
          label="操作"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <el-button v-if="scope.row.status == 0" type="text" size="small" @click="handleExamine(scope.row)">线下审核</el-button>
            <el-button type="text" size="small" @click="handleRelieve(scope.row)">解除预警</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>

    <el-dialog title="解除预警" :visible.sync="visibleDialog" width="600px">
      <el-form ref="relieveFormRef" v-loading="loading" :model="relieveFormData" :rules="relieveRules" label-width="106px">
        <el-form-item label="预警原因">
          <span>{{ selectObject.cause | ellipsis }}</span>
        </el-form-item>
        <el-form-item label="预警时间">
          <span>{{ selectObject.createDate }}</span>
        </el-form-item>
        <el-form-item label="备注说明" prop="note">
          <el-input v-model="relieveFormData.note" type="textarea" :rows="4" placeholder="请输入" maxlength="100" show-word-limit />
        </el-form-item>
        <el-form-item label="上传凭证文件" prop="url">
          <button-upload v-model="relieveFormData.url" :custom-type="['pdf', 'jpg', 'png', 'rar', 'zip', '7z', 'docx', 'doc']" text="上传文件" show-preview />
          <div>支持10M以内以下格式文件：.rar .zip .doc .docx .pdf .jpg...</div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitRelieve('relieveFormRef')">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="拉入黑名单" :visible.sync="visibleDialogB" width="600px">
      <el-form ref="workerFormRef" v-loading="loading" :model="addFormData" label-width="106px">
        <el-form-item label="商户名">
          <el-input v-model="addFormData.name" :disabled="onlyRead" />
        </el-form-item>
        <el-form-item label="原因备注">
          <el-input v-model="addFormData.cause" type="textarea" :rows="4" placeholder="请输入" maxlength="80" show-word-limit />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleDialogB = false">取 消</el-button>
        <el-button type="primary" @click="submitBlack('workerFormRef')">确 定</el-button>
      </span>
    </el-dialog>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
      <el-button v-loading="loading" size="small" type="danger" @click="addBlack()">拉入黑名单</el-button>
    </div>
  </div>
</template>

<script>
import { getCompanyInfo, postBlacklist, companyCancelWarning, companyOfflineAudit } from '@/api/blackList'
import ButtonUpload from '@/components/Upload/buttonUpload'
export default {
  components: {
    ButtonUpload
  },
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length >= 25) {
        return value.slice(0, 25) + '...'
      }
      return value
    },
    statusFilter(value) {
      if (value == '0') {
        return '待处理'
      } else if (value == '1') {
        return '线下审核中'
      } else if (value == '2') {
        return '已解除'
      } else {
        return ''
      }
    }
  },
  data() {
    return {
      visibleDialog: false,
      visibleDialogB: false,
      loading: false,
      onlyRead: true,
      workerData: { id: undefined, name: '', code: '' },
      addFormData: {
        objectId: '',
        cause: '',
        name: '',
        userType: 'MERCHANT',
        code: ''
      },
      relieveFormData: {
        id: undefined,
        note: '',
        url: ''
      },
      list: [],
      itemsCount: 0,
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      },
      selectObject: {},
      relieveRules: {
        note: [
          { required: true, message: '请输入备注说', trigger: 'blur' }
        ],
        url: [
          { required: true, message: '请上传凭证文件', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    // debugger
    this.workerData = this.$route.query
    if (this.workerData.name) {
      this.onlyRead = true
      this.addFormData.objectId = this.workerData.objectId
      this.addFormData.name = this.workerData.name
      this.addFormData.code = this.workerData.code
    } else {
      this.onlyRead = false
    }
    this.fetchData()
  },
  methods: {
    setFields(fields, val) {
      this.workerFormData[fields] = val
    },
    fetchData() {
      this.loading = true
      getCompanyInfo(Object.assign({ objectId: this.workerData.objectId }, this.paramsData)).then(res => {
        this.list = res.data || []
        this.itemsCount = res.itemsCount
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    submitBlack(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visibleDialogB = false
          this.loading = true
          postBlacklist(this.addFormData).then(res => {
            this.loading = false
            this.fetchData()
            this.$refs[formName].resetFields()
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    addBlack() {
      this.visibleDialogB = true
    },
    handleExamine(i) {
      this.$confirm('您确定线下审核吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        companyOfflineAudit({
          id: i.id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.fetchData()
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    },
    submitRelieve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visibleDialog = false
          this.loading = true
          companyCancelWarning(this.relieveFormData).then(res => {
            this.loading = false
            this.fetchData()
            this.$refs[formName].resetFields()
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    handleRelieve(i) {
      this.visibleDialog = true
      this.relieveFormData.id = i.id
      this.selectObject = i
    }
  }
}
</script>

<style scoped>
  .batch{
    text-align: left;
  }
</style>
