import request from '@/utils/request'

/**
 * 获取首页基本信息
 * @returns {AxiosPromise}
 */
export function getInfo() {
  return request({
    url: '/platform/homePage/homePageInfo',
    method: 'post'
  })
}

/**
 * 获取商户首页图表统计数据
 * @param type 类型 totalSub 总包+分包   crowd 众包
 * @param dateType 日期类型  day 日 week 周  month 月 year 年
 * @returns {AxiosPromise}
 */
export function getChart(type, dateType) {
  let url
  // 总众包
  if (type === 'totalSub') {
    // 日
    if (dateType === 'day') {
      url = '/platform/homePage/totalDayInfo'
    } else if (dateType === 'week') {
      url = '/platform/homePage/totalWeekInfo'
    } else if (dateType === 'month') {
      url = '/platform/homePage/totalMonthInfo'
    } else if (dateType === 'year') {
      url = '/platform/homePage/totalYearInfo'
    }
  } else if (type === 'crowd') {
    if (dateType === 'day') {
      url = '/platform/homePage/manyDayInfo'
    } else if (dateType === 'week') {
      url = '/platform/homePage/manyWeekInfo'
    } else if (dateType === 'month') {
      url = '/platform/homePage/manyMonthInfo'
    } else if (dateType === 'year') {
      url = '/platform/homePage/manyYearInfo'
    }
  }
  if (url) {
    return request({
      url: url,
      method: 'post'
    })
  }
  return new Promise((resolve, reject) => {
    reject({
      msg: '请求类型不正确',
      code: 300,
      data: null
    })
  })
}
