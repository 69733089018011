<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="支付编号">
          <el-input v-model="formData.paymentOrderId" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="平台服务商">
          <el-select v-model="formData.taxId" style="width: 100%" placeholder="平台服务商" clearable filterable>
            <el-option v-for="i in serviceList" :key="i.id" :label="i.taxName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker v-model="formData.beginDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"
            style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          至
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.endDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"
            style="width: 160px;" />
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker v-model="formData.payBeginDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"
            style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          至
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.payEndDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间"
            style="width: 160px;" />
        </el-form-item>
        <el-form-item label="商户">
          <el-input v-model="formData.merchantName" clearable placeholder="请输入" />
        </el-form-item>
        <el-form-item label="支付状态">
          <el-select v-model="formData.paymentOrderStatus" style="width: 100%" placeholder="支付状态" clearable filterable>
            <el-option v-for="item in payTotalAndSubStatus" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
          <el-button v-loading="loadingBtn" type="success" @click="exportExcel">导出</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd()">
        新建总包+分包支付单
      </el-button>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table ref="multipleTable" row-key="id" v-loading="loading" :data="list" tooltip-effect="dark"
        style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" reserve-selection />
        <el-table-column prop="id" label="支付编号" width="170" show-overflow-tooltip align="center" />
        <el-table-column prop="companySName" label="商户名称" align="center" />
        <el-table-column prop="platformServiceProvider" label="平台服务商" align="center" />
        <el-table-column label="项目合同" align="center">
          <template slot-scope="scope">
            <file-icon :url="scope.row.companyContract" />
          </template>
        </el-table-column>
        <el-table-column prop="realMoney" label="支付金额(元)" width="120" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ $u.formatMoney(scope.row.realMoney) }}
          </template>
        </el-table-column>
        <el-table-column label="支付清单" align="center">
          <template slot-scope="scope">
            <file-icon :url="scope.row.paymentInventory" />
          </template>
        </el-table-column>
        <el-table-column label="总包支付回单" align="center">
          <template slot-scope="scope">
            <i
              v-if="$u.test.isEmpty(scope.row.turnkeyProjectPayment) && [2, 3, 6].indexOf((+scope.row.paymentOrderStatus)) !== -1" />
            <file-icon v-else :url="scope.row.turnkeyProjectPayment" />
          </template>
        </el-table-column>
        <el-table-column label="分包支付回单" align="center">
          <template slot-scope="scope">
            <i v-if="$u.test.isEmpty(scope.row.subpackagePayment) && [6].indexOf((+scope.row.paymentOrderStatus)) !== -1"
              class="el-icon-success text-success" />
            <file-icon v-else :url="scope.row.subpackagePayment" />
          </template>
        </el-table-column>
        <el-table-column label="关联的任务" align="center">
          <template slot-scope="scope">
            <el-button v-if="!$u.test.isEmpty(scope.row.taskId)" type="text" @click="showTask(scope.row.taskId)">查看
            </el-button>
            <span v-else>--</span>
          </template>
        </el-table-column>
        <el-table-column label="交付支付验收单" align="center">
          <template slot-scope="scope">
            <file-icon :url="scope.row.acceptanceCertificate" />
          </template>
        </el-table-column>
        <el-table-column label="状态" align="center" width="120">
          <template slot-scope="scope">
            <!-- <div v-html="$options.filters.filterContent(scope.row.invoice, $enum.invoiceStatus())" />
               <div v-html="$options.filters.filterContent(scope.row.invoiceStatus, $enum.applicationState())" /> -->
            <div v-html="$options.filters.filterContent(scope.row.paymentOrderStatus, $enum.payTotalAndSubStatus())" />
          </template>
        </el-table-column>
        <el-table-column prop="paymentDate" label="支付时间" align="center" width="100" />
        <el-table-column label="开票状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.isNotInvoice == 1" v-html="$options.filters.filterContentItem('success', '开票完成')" />
            <div v-else-if="scope.row.is_invoice == 1" v-html="$options.filters.filterContentItem('primary', '开票中')" />
            <div v-else v-html="$options.filters.filterContentItem('info', '未开票')" />
            <!-- <div v-else-if="scope.row.is_invoice === 0" v-html="$options.filters.filterContent(scope.row.paymentOrderStatus, $enum.payTotalAndSubStatus())" /> -->
          </template>
        </el-table-column>
        <el-table-column prop="failCount" label="发放失败人数" align="center" />
        <el-table-column prop="reasonsForRejection" label="驳回理由" align="center" />
        <el-table-column prop="tradeFailReason" label="交易失败原因" align="center" />
        <el-table-column prop="createDate" label="创建时间" align="center" width="100" />
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleShow(scope.row, 'check')">查看</el-button>
            <el-button v-if="scope.row.paymentOrderStatus === 14" type="text" size="small"
              @click="handleShow(scope.row, 'edit')">处理</el-button>
            <!--            <el-button v-if="scope.row.paymentOrderStatus === 0" type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>-->
            <!--            <el-button v-if="scope.row.paymentOrderStatus === 0" type="text" size="small" @click="handlePay(scope.row)">支付</el-button>-->
            <el-button
              v-if="scope.row.paymentOrderStatus === -1 || scope.row.paymentOrderStatus === 0 || scope.row.paymentOrderStatus === 5"
              type="text" size="small" @click="handleDel(scope.row)">删除</el-button>
            <el-button v-if="scope.row.paymentOrderStatus === 4" type="text" size="small"
              @click="handleToPage('check', scope.row)">审核</el-button>
            <el-button
              v-else-if="scope.row.paymentOrderStatus === 2 && scope.row.paymentMode === 1 && scope.row.boolAlipaySubpackage === false"
              type="text" size="small" @click="handleAlipaySub(scope.row)">分包发薪转账</el-button>
            <el-button v-else-if="scope.row.paymentOrderStatus === 2 || scope.row.paymentOrderStatus === 14" type="text"
              size="small" @click="handleToPage('subPay', scope.row)">分包支付</el-button>
            <el-button
              v-if="$store.getters.userinfo.userSign == 1 && (scope.row.paymentOrderStatus === 0 || scope.row.paymentOrderStatus === 5)"
              type="text" size="small" @click="handlePay(scope.row)">支付</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
    </div>
    <el-dialog title="查看关联任务" :visible.sync="visibleDialog" width="800px">
      <task-detail ref="taskDetail" />
    </el-dialog>
    <el-dialog :visible.sync="importDialog" width="400px">
      <el-upload ref="importUpload" name="zip" class="upload-demo" drag :action="action" :limit="1"
        :on-success="uploadSuccess" multiple>
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或<em>点击上传</em>
          <div style="margin-top:5px">只支持 .zip 文件格式,大小在50M以内</div>
        </div>
      </el-upload>
      <div style="margin-top:20px">
        说明：压缩包中的文件，请按照以下命名规范对进行命名，并压缩上传
      </div>
      <div style="margin:20px 0">
        <div>(1) 支付单编号 + 图片"</div>
        <div>示例：440902155512120420.png或者440902155512120420.jpg</div>
      </div>
      <div style="margin:20px 0">
        <div>(2) 支付单编号 + pdf"</div>
        <div>示例：440902155512120420.pdf</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getTotalAndSubList,
  alipaySubpackage,
  downloadInvoice,
  downloadPayZip,
  downloadYsd,
  delAndSub,
} from "@/api/pay";
import { getServiceList } from "@/api/common";
import TaskDetail from "@/views/task/components/taskDetail";
import XLSX from "xlsx";
import { downloadFile } from "@/utils";

export default {
  name: "PayTotal",
  components: {
    TaskDetail,
  },
  data() {
    return {
      loading: false,
      action: process.env.VUE_APP_BASE_API + "/platform/file/details-upload",
      importDialog: false,
      loadingBtn: false,
      visibleDialog: false,
      serviceList: [],
      formData: {
        paymentOrderId: "",
        taxId: "",
        beginDate: "",
        endDate: "",
        payBeginDate: "",
        payEndDate: "",
        paymentOrderStatus: "",
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10,
      },
      ids: [],
      paymentListExcel: [],
      payTotalAndSubStatus: this.$enum.payTotalAndSubStatus(),
    };
  },
  created() {
    console.log(this.$options.name + "总包+分包支付-----created");
    this.fetchData();
    this.fetchService();
  },
  activated() {
    // console.log(this.$options.name + '总包+分包支付-----activated')
    // if (this.$store.getters.isUpdatePage) {
    //   this.$store.commit('common/setUpdatePage', false)
    //   this.fetchData()
    // }
    this.fetchData();
    this.fetchService();
  },

  methods: {
    handleSelectionChange(val) {
      this.ids = [];
      val.map((v) => {
        this.ids.push(v.id);
      });
    },
    exportExcel() {
      var params = {
        ids: this.ids.join(),
      };
      var data = Object.assign({}, this.formData, this.paramsData);
      downloadInvoice(params, data).then((res) => {
        this.paymentListExcel = res.data;
        this.downloadExcel();
      });
    },
    downloadExcel() {
      this.loading = true;
      const tableData = [
        [
          "支付单编号",
          "创建时间",
          "商户",
          "服务商",
          "姓名",
          "电话",
          "身份证号",
          "开户行",
          "银行卡号",
          "发放金额",
          "创客服务费率",
          "创客服务费",
          "商户服务费率",
          "商户服务费",
          "创客到手金额",
          "商户支出",
          "支付状态",
          "支付失败原因",
          "支付时间",
        ],
      ];
      let rowData = [];
      this.paymentListExcel.forEach((item, index) => {
        if (item.paymentStatus === -1) {
          item.paymentStatus = "支付失败";
        }
        if (item.paymentStatus === 0) {
          item.paymentStatus = "未支付";
        }
        if (item.paymentStatus === 1) {
          item.paymentStatus = "已支付";
        }
        rowData = [
          item.paymentOrderId,
          item.createDate,
          item.companySName,
          item.platformServiceProvider,
          item.workerName,
          item.mobileCode,
          item.idCardCode,
          item.bankName,
          item.bankCode,
          item.taskMoney,
          item.compositeTax,
          item.serviceMoney,
          item.merchantTax,
          item.merchantServiceCharge,
          item.realMoney,
          item.merchantPaymentMoney,
          item.paymentStatus,
          item.tradeFailReason,
          item.paymentDate,
        ];
        tableData.push(rowData);
      });
      const ws = XLSX.utils.aoa_to_sheet(tableData);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "支付明细");
      XLSX.writeFile(wb, "支付清单明细.xlsx");

      setTimeout(() => {
        this.loading = false;
      }, 4000);
    },
    fetchService() {
      getServiceList().then((res) => {
        this.serviceList = res.data || [];
      });
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10,
      });
      this.list = [];
      this.pageTotal = 0;
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    fetchData() {
      this.loading = true;
      getTotalAndSubList(Object.assign({}, this.formData, this.paramsData))
        .then((res) => {
          this.list = res.data || [];
          this.pageTotal = res.pageCount;
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    handleAdd() {
      this.$store.commit("common/setUpdatePage", true);
      this.$router.push({
        path: "/pay/totalAndSub/add",
      });
    },
    handleEdit(i) {
      this.$router.push({
        path: "/pay/totalAndSub/add",
        query: {
          id: i.id,
        },
      });
    },
    handleShow(i, type) {
      this.$router.push({
        path: "/pay/totalAndSub/view",
        query: {
          id: i.id,
          type,
        },
      });
    },
    handlePay(i) {
      this.$router.push({
        path: "/pay/totalAndSub/pay",
        query: {
          id: i.id,
        },
      });
    },
    handleDoPay(i) {
      this.$router.push({
        path: "/pay/totalAndSub/doPay",
        query: {
          id: i.id,
        },
      });
    },
    // 删除
    handleDel(i) {
      this.$confirm("你确定要删除任务吗?", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          delAndSub({
            paymentOrderId: i.id,
          })
            .then((res) => {
              this.resetPage();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.fetchData();
            })
            .catch((res) => {
              this.loading = false;
            });
        })
        .catch(() => { });
    },
    // 跳转页面
    handleToPage(type, row) {
      let url;
      switch (type) {
        case "check": // 审核
          url = "/pay/totalAndSub/check";
          break;
        case "subPay": // 分包支付
          url = "/pay/totalAndSub/subPay";
          break;
      }
      if (url) {
        this.$router.push({
          path: url,
          query: {
            id: row.id,
          },
        });
      }
    },
    showTask(taskId) {
      this.visibleDialog = true;
      this.$nextTick(() => {
        this.$refs.taskDetail.fetchData(taskId);
      });
    },
    uploadSuccess(e) {
      if (e.code == 200) {
        this.$message.success(e.message);
      } else {
        this.$message.error(e.message);
      }
      this.fetchData();
      this.$refs.importUpload.clearFiles();
      this.importDialog = false;
    },
    downloadZip() {
      if (this.ids.length) {
        this.loadingBtn = true;
        downloadPayZip({ ids: this.ids.join() })
          .then((res) => {
            this.loadingBtn = false;
            if (res.size) {
              downloadFile(res, "支付清单.zip");
              this.$refs.multipleTable.clearSelection();
            } else {
              this.$message.error("没有查询到数据");
            }
          })
          .catch((res) => {
            this.loadingBtn = false;
          });
      } else {
        this.$message.error("请先选中内容，后再操作");
      }
    },
    downloadYsd() {
      if (this.ids.length) {
        this.loadingBtn = true;
        downloadYsd({ ids: this.ids.join() })
          .then((res) => {
            this.loadingBtn = false;
            if (res.size) {
              downloadFile(res, "支付验收单.zip");
              this.$refs.multipleTable.clearSelection();
            } else {
              this.$message.error("没有查询到数据");
            }
          })
          .catch((res) => {
            this.loadingBtn = false;
          });
      } else {
        this.$message.error("请先选中内容，后再操作");
      }
    },
    handleAlipaySub(row) {
      this.loading = true;
      alipaySubpackage({
        paymentOrderId: row.id,
      })
        .then((res) => {
          this.fetchData();
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped></style>
