<template>
  <div>
    <div style="padding:20px 10px">
      <el-form ref="elForm" :inline="true" :model="formData" size="small">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="formData.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="formData.mobile" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="身份证号" prop="idCode">
          <el-input v-model="formData.idCode" placeholder="请输入" clearable />
        </el-form-item>
        <!-- <el-form-item label="支付状态">
          <el-select v-model="formData.paymentOrderStatus" style="width: 100%" placeholder="支付状态" clearable filterable>
            <el-option v-for="item in payTotalAndSubStatus" :key="item.value" :label="item.name" :value="item.value" />
          </el-select>
        </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table ref="multipleTable" v-loading="tbLoading" :data="list" max-height="600px" tooltip-effect="dark"
      style="width: 100%">
      <el-table-column prop="workerName" label="姓名" width="100" align="center" />
      <el-table-column prop="mobileCode" width="120" label="电话" align="center" />
      <el-table-column prop="idCardCode" width="180" label="身份证号" align="center" />
      <el-table-column v-if="type === 0" prop="bankCode" label="银行卡号" align="center" />
      <el-table-column label="发放金额" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.taskMoney) ? ('￥' + $u.formatMoney(scope.row.taskMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="创客到手费" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.realMoney) ? ('￥' + $u.formatMoney(scope.row.realMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="服务费" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>创客：{{ ('￥' + (((+scope.row.receviceTax) * (+scope.row.taskMoney) / 100) || 0).toFixed(2)) }}</div>
          <div>商户：{{ ('￥' + (((+scope.row.merchantTax) * (+scope.row.taskMoney) / 100) || 0).toFixed(2)) }}</div>
        </template>
      </el-table-column>
      <el-table-column label="服务费率" width="120" show-overflow-tooltip>
        <template slot-scope="scope">
          <div>创客：{{ !$u.test.isEmptyNotZero(scope.row.receviceTax) ? (scope.row.receviceTax + '%') : '-' }}</div>
          <div>商户：{{ !$u.test.isEmptyNotZero(scope.row.merchantTax) ? (scope.row.merchantTax + '%') : '-' }}</div>
        </template>
      </el-table-column>
      <el-table-column label="商户总支出" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.merchantPaymentMoney) ? ('￥' +
            $u.formatMoney(scope.row.merchantPaymentMoney)) : '-' }}
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-box">
      <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo"
        :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="getPayList" />
    </div>
  </div>
</template>

<script>
import { getPayList } from '@/api/totalTax'
export default {
  name: 'WorkerPayList',
  props: {
    // 0总包 1众包
    type: {
      type: Number,
      required: true
    },
    value: {
      type: [Number, String],
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      list: [],
      paramsData: {
        pageNo: 1,
        pageSize: 10,
      },
      pageTotal: 0,
      formData: {
        name: '',
        mobile: '',
        idCode: ''
      },
      tbLoading: false,
      paymentOrderIds: ''
    }
  },
  created() {
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === (columns.length - 1)) {
          let total = 0
          data.map(item => {
            total = total + (!this.$u.test.isEmptyNotZero(item.merchantPaymentMoney) ? (+item.merchantPaymentMoney) : 0)
          })
          sums[index] = '￥' + this.$u.formatMoney(total.toFixed(2))
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    fetchData(paymentOrderIds) {
      if (this.$u.test.isEmpty(paymentOrderIds)) {
        return
      }
      this.paymentOrderIds = paymentOrderIds
      this.getPayList()
    },
    getPayList() {
      this.tbLoading = true
      getPayList({
        paymentOrderIds: this.paymentOrderIds,
        type: this.type,
        ...this.paramsData,
        ...this.formData
      }).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        let total = 0
        this.list.map(val => {
          total += ((+val.taskMoney) || 0)
        })
        this.$emit('input', '￥' + total)
        this.tbLoading = false
      }).catch(res => {
        this.tbLoading = false
      })
    },
    resetPage() {
      this.paramsData = {
        pageNo: 1,
        pageSize: 10,
      }
      this.pageTotal = 0
      this.getPayList()
    },
    reset() {
      this.$refs.elForm.resetFields()
      this.getPayList()
    }
  }
}
</script>

<style scoped></style>
