var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap-fixed-content" }, [
    _c(
      "div",
      {
        staticClass:
          "bg-white u-flex u-row-right u-col-center u-p-lf-30 u-p-b-20 u-font-14",
      },
      [
        _c(
          "div",
          { staticClass: "u-p-r-20" },
          [
            _vm._v("创客任务模板下载："),
            _c(
              "el-button",
              {
                attrs: {
                  type: "success",
                  size: "small",
                  icon: "el-icon-download",
                },
                on: { click: _vm.download },
              },
              [_vm._v(" 点击下载")]
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "u-m-25 u-p-30 bg-white wrap-fixed-content" },
      [
        _c(
          "el-form",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            ref: "formData",
            staticStyle: { "max-width": "800px" },
            attrs: {
              model: _vm.formData,
              rules: _vm.rules,
              "label-width": "250px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "商户名称", prop: "merchantId" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { filterable: "", placeholder: "请选择" },
                    on: { change: _vm.changeMerchant },
                    model: {
                      value: _vm.formData.merchantId,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "merchantId", $$v)
                      },
                      expression: "formData.merchantId",
                    },
                  },
                  _vm._l(_vm.shop, function (i, k) {
                    return _c("el-option", {
                      key: k,
                      attrs: { label: i.companyName, value: i.id },
                    })
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "任务名称", prop: "taskName" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入任务名称" },
                  model: {
                    value: _vm.formData.taskName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "taskName", $$v)
                    },
                    expression: "formData.taskName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "任务描述", prop: "taskDesc" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入任务描述", type: "textarea" },
                  model: {
                    value: _vm.formData.taskDesc,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "taskDesc", $$v)
                    },
                    expression: "formData.taskDesc",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "图文说明", prop: "taskIllustration" } },
              [
                _c(
                  "div",
                  { staticClass: "u-flex u-col-center" },
                  [
                    _c("button-upload", {
                      attrs: { type: "image", text: "上传 jpg" },
                      on: {
                        "upload-success": function ($event) {
                          return _vm.setFields("taskIllustration", $event)
                        },
                      },
                    }),
                    !_vm.$u.test.isEmpty(_vm.formData.taskIllustration)
                      ? _c(
                          "el-button",
                          {
                            staticClass: "u-m-l-10",
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.$preViewer(
                                  _vm.formData.taskIllustration
                                )
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "el-form-item",
              { attrs: { label: "费用" } },
              [
                _c(
                  "el-col",
                  { attrs: { span: 11 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "taskCostMin" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              min: "0",
                              type: "number",
                              placeholder: "最低",
                            },
                            model: {
                              value: _vm.formData.taskCostMin,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "taskCostMin",
                                  _vm._n($$v)
                                )
                              },
                              expression: "formData.taskCostMin",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "suffix" }, slot: "suffix" },
                              [_vm._v("元")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { staticClass: "u-text-center", attrs: { span: 2 } },
                  [_vm._v("至")]
                ),
                _c(
                  "el-col",
                  { attrs: { span: 11 } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { prop: "taskCostMax" } },
                      [
                        _c(
                          "el-input",
                          {
                            staticStyle: { width: "100%" },
                            attrs: {
                              min: "0",
                              type: "number",
                              placeholder: "最高",
                            },
                            model: {
                              value: _vm.formData.taskCostMax,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.formData,
                                  "taskCostMax",
                                  _vm._n($$v)
                                )
                              },
                              expression: "formData.taskCostMax",
                            },
                          },
                          [
                            _c(
                              "span",
                              { attrs: { slot: "suffix" }, slot: "suffix" },
                              [_vm._v("元")]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "创客所需技能", prop: "taskSkill" } },
              [
                _c("el-input", {
                  attrs: { placeholder: "请输入创客所需技能" },
                  model: {
                    value: _vm.formData.taskSkill,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "taskSkill", $$v)
                    },
                    expression: "formData.taskSkill",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "任务上限人数", prop: "upperLimit" } },
              [
                _c(
                  "el-input",
                  {
                    attrs: {
                      min: "0",
                      step: "1",
                      type: "number",
                      placeholder: "请输入任务上限人数",
                    },
                    model: {
                      value: _vm.formData.upperLimit,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "upperLimit", _vm._n($$v))
                      },
                      expression: "formData.upperLimit",
                    },
                  },
                  [
                    _c("span", { attrs: { slot: "suffix" }, slot: "suffix" }, [
                      _vm._v("人"),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "合作类型", prop: "cooperateMode" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.formData.cooperateMode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "cooperateMode", $$v)
                      },
                      expression: "formData.cooperateMode",
                    },
                  },
                  _vm._l(_vm.cooperateModeList, function (i, k) {
                    return _c(
                      "el-radio",
                      { key: k, attrs: { label: i.value } },
                      [_vm._v(_vm._s(i.name))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "任务模式", prop: "taskMode" } },
              [
                _c(
                  "el-radio-group",
                  {
                    model: {
                      value: _vm.formData.taskMode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "taskMode", $$v)
                      },
                      expression: "formData.taskMode",
                    },
                  },
                  _vm._l(_vm.taskModeList, function (i, k) {
                    return _c(
                      "el-radio",
                      { key: k, attrs: { label: i.value } },
                      [_vm._v(_vm._s(i.name))]
                    )
                  }),
                  1
                ),
              ],
              1
            ),
            parseInt(_vm.formData.taskMode) !== 1
              ? _c(
                  "el-form-item",
                  [
                    _c(
                      "div",
                      {
                        staticClass: "u-dashed-border u-point add-worker",
                        on: { click: _vm.showWorkerDialog },
                      },
                      [
                        _c("i", { staticClass: "el-icon-plus u-m-r-10" }),
                        _vm._v("添加指定派单创客"),
                      ]
                    ),
                    _c("button-upload", {
                      staticClass: "upload-btn",
                      attrs: {
                        "default-action": _vm.action,
                        "success-toast": false,
                        "error-toast": false,
                        "return-all": "",
                        name: "workerTaskExcel",
                        type: "excel",
                        text: "上传创客(Excel)",
                      },
                      on: {
                        "upload-success": function ($event) {
                          return _vm.uploadExcel($event)
                        },
                        "upload-error": _vm.showErrorModal,
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "u-p-t-10" },
                      [
                        _c(
                          "el-table",
                          {
                            staticStyle: { width: "100%" },
                            attrs: { height: "250", data: _vm.selectWorker },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { prop: "accountName", label: "创客姓名" },
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "mobileCode",
                                label: "创客手机号",
                                width: "180",
                              },
                            }),
                            _c("el-table-column", {
                              attrs: { label: "操作" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDel(
                                                  scope.row,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                4239259052
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass:
              "wrap-fixed-footer bg-white u-p-lf-20 u-flex u-col-center u-row-right",
          },
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.submitForm("formData")
                  },
                },
              },
              [_vm._v("确定")]
            ),
            _c(
              "el-button",
              {
                attrs: { size: "small" },
                on: {
                  click: function ($event) {
                    return _vm.$router.go(-1)
                  },
                },
              },
              [_vm._v("取消")]
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "选择创客",
              visible: _vm.dialogTableVisible,
              width: "1200px",
            },
            on: {
              "update:visible": function ($event) {
                _vm.dialogTableVisible = $event
              },
            },
          },
          [
            _vm.dialogTableVisible
              ? _c("worker-lookup", {
                  ref: "workerLookup",
                  attrs: { "merchant-id": _vm.formData.merchantId },
                  on: { "on-select": _vm.onSelect },
                })
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }