<template>
  <div class="navbar">
    <hamburger :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb class="breadcrumb-container" />

    <div class="right-menu u-flex u-col-center">
      <el-badge :is-dot="isDot" class="item">
        <i class="el-icon-bell u-font-16 u-point" @click="handleNotice()" />
      </el-badge>
      <span>{{ myName }}</span>
      <span class="line">{{ userinfo.roleName }}</span>
      <span class="line">{{ name }}</span>
      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <avatar :src="avatar" :size="24" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <el-dropdown-item @click.native="uploadHead">
            <span style="display:block;">上传头像</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span style="display:block;">退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div style="display: none">
      <button-upload ref="myUpload" :success-toast="false" type="image" text="上传 jpg" @upload-success="setFields" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { myName } from '@/settings'
import Hamburger from '@/components/Hamburger'
import Breadcrumb from '@/components/Breadcrumb'
import ButtonUpload from '@/components/Upload/buttonUpload'
import { updateHead } from '@/api/user'
import { notificationList } from '@/api/blackList'

export default {
  components: {
    ButtonUpload,
    Breadcrumb,
    Hamburger
  },
  data() {
    return {
      myName: myName,
      isDot: false
    }
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'name',
      'userinfo'
    ])
  },
  created() {
    this.getNotice()
    setInterval(() => {
      this.getNotice()
    }, 1000 * 60)
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    uploadHead() {
      this.$refs.myUpload.$refs.elUpload.$el.click()
    },
    setFields(data) {
      updateHead({
        headPortrait: data
      }).then(res => {
        this.$message.success('上传成功')
        this.$store.dispatch('user/refreshHead', data)
      }).catch(res => {
      })
    },
    getNotice() {
      notificationList().then(res => {
        if (res?.data?.length) {
          this.isDot = true
        } else {
          this.isDot = false
        }
      })
    },
    handleNotice() {
      this.$router.push({
        path: '/warn/shop/notice'
      })
    },
    async logout() {
      await this.$store.dispatch('user/logout')
      this.$router.push(`/login`)
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  border-bottom: 1px solid #eee;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .right-menu {
    float: right;
    height: 100%;
    /*line-height: 50px;*/

    > span {
      font-size:14px;
      padding: 0 10px;
      min-height: 16px;
      /*line-height: 50px;*/
      display: inline-block;
      color:rgba(0,0,0,0.65);
      position: relative;

      &.line::before {
        position: absolute;
        content: "|";
        left: 0;
        top: 0;
      }
    }

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
