<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-card>
      <!-- <div class="card--title">【总包+分包】 总包开票</div> -->
      <div class="card--title">支付信息</div>
      <div class="card--content" style="margin-left: 20px">
        <el-row :gutter="20" class="card--info">
          <el-col :md="12">
            <span class="label">支付编号：</span>
            <span class="value">{{ paymentOrderList.id }}</span>
          </el-col>
        </el-row>

        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">商户：</span>
            <span class="value">{{ paymentOrderList.companySName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">平台服务商：</span>
            <span class="value">{{
              paymentOrderList.platformServiceProvider
            }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付清单：</span>
            <span
              v-for="(item, index) of paymentOrderList.paymentInventoryList"
              :key="index"
              class="pointer"
              @click="openFile(item)"
              >查看</span
            >
            <!-- <span class="value">{{paymentOrderList.paymentInventory}}</span> -->
          </el-col>
          <el-col :md="8">
            <span class="label">关联的任务：</span>
            <span
              v-for="(item, index) of paymentOrderList.taskVoList"
              :key="index"
              class="pointer"
              @click="openTaskVo(item)"
              >查看</span
            >
            <!-- <span class="value">{{paymentOrderList.taskVo}}</span> -->
          </el-col>

          <el-col :md="8">
            <span class="label">交付支付验收单：</span>
            <span
              v-for="(
                item, index
              ) of paymentOrderList.acceptanceCertificateList"
              :key="index"
              class="pointer"
              @click="openFile(item)"
              >查看</span
            >
            <!-- <span class="value">{{paymentOrderList.acceptanceCertificate}}</span> -->
          </el-col>
          <el-col :md="8">
            <span class="label">总包支付回单：</span>
            <span
              v-for="(item, index) of paymentOrderList.subpackagePaymentList"
              :key="index"
              class="pointer"
              @click="openFile(item)"
              >查看</span
            >
          </el-col>

          <el-col :md="8">
            <span class="label">状态：</span>
            <span class="value">{{
              paymentOrderList.paymentOrderStatus | payTotalAndSubStatus
            }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付时间：</span>
            <span class="value">{{ paymentOrderList.paymentDate }}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="u-m-t-20">
      <div class="card--title">开票信息</div>
      <div class="card--content" style="margin-left: 20px">
        <worker-pay-list ref="payList" :type="0" />
        <div class="table-wrap">
          <ul class="table">
            <li class="u-flex">
              <div class="title">
                <span class="txt">购买方</span>
              </div>
              <div class="content">
                <p>名称：{{ BuyerInfo.companyName }}</p>
                <p>纳税人识别号：{{ BuyerInfo.creditCode }}</p>
                <p>地址、电话：{{ BuyerInfo.addressAndTelephone }}</p>
                <p>开户行及账号：{{ BuyerInfo.bankAndAccount }}</p>
              </div>
            </li>
            <li class="count">价税合计：{{ $u.formatMoney(totalMoney) }}</li>
            <li class="u-flex">
              <div class="title">
                <span class="txt">销售方</span>
              </div>
              <div class="content">
                <p>名称：{{ sellerInfo.invoiceEnterpriseName }}</p>
                <p>纳税人识别号：{{ sellerInfo.invoiceTaxNo }}</p>
                <p>地址、电话：{{ sellerInfo.invoiceAddressPhone }}</p>
                <p>开户行及账号：{{ sellerInfo.invoiceBankNameAccount }}</p>
              </div>
            </li>
          </ul>
        </div>
        <el-row :gutter="20" class="card--info" style="margin-top: 20px;">
          <el-col :md="8">
            <span class="label">票种：</span>
            <span class="value">{{ invoiceApplicationVo.invoiceForm == 1 ? '普通发票' : '增值税专用发票' }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">发票类型：</span>
            <span class="value">{{ invoiceApplicationVo.invoiceType == 1 ? '电子发票' : '纸质发票' }}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="u-m-t-20" v-if="invoiceApplicationVo.invoiceType != 1">
      <div class="card--title">收件信息</div>
      <div class="card--content" style="margin-left: 20px">
        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">收件人：</span>
            <span class="value">{{ sendAndReceiveVo.linkName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">联系电话：</span>
            <span class="value">{{ sendAndReceiveVo.linkMobile }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">详细地址：</span>
            <span class="value">{{ sendAndReceiveVo.addressName }}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>

    <el-card class="u-m-t-20">
      <div class="card--title">发票备注</div>
      <div class="card--content" style="margin-left: 20px">
        <div>
          <span class="label">内容：</span>
          <span class="value">{{ suibmitFome.applicationDesc }}</span>
        </div>
      </div>
    </el-card>

    <el-card class="u-m-t-20">
      <div class="card--title">开票类目</div>
      <div class="card--content" style="margin-left: 20px">
        <ul class="invoiceCatalogVo-wrap">
          <li class="item flex align-items">
            <span>服务类型：</span>
            <span>{{ invoiceCatalogVo.serviceType }}</span>
          </li>
          <li class="item flex align-items">
            <span>具体服务内容：</span>
            <span>{{ invoiceCatalogVo.serviceContent }}</span>
          </li>
          <li class="item flex align-items">
            <span>开票类目：</span>
            <span>{{ invoiceCatalogVo.billingCategory }}</span>
          </li>
        </ul>
      </div>
    </el-card>

    <el-card class="u-m-t-20" v-if="type === 'examine'">
      <div class="card--title">发票信息</div>
      <div class="card--content" style="margin-left: 20px">
        <ul class="invoiceCatalogVo-wrap">
          <li class="item flex align-items">
            <span>发票文件：</span>
            <el-button type="primary" @click="downFiles">下载</el-button>
          </li>
        </ul>
      </div>
    </el-card>

    <el-card class="u-m-t-20" v-if="type != 'examine'">
      <div class="card--content" style="margin-left: 20px">
        <el-form
          label-position="right"
          label-width="100px"
          class="demo-form-inline"
        >
          <el-form-item label="发票：" required v-if="type == 'add' || type == 'editor'">
            <div class="u-flex u-col-center">
              <button-upload
                :custom-type="['pdf','ofd']"
                text="上传发票(pdf/ofd)格式文件"
                @upload-success="setField('invoiceUrl', $event)"
              />
              <!-- , 'jpg', 'png', 'rar', 'zip', '7z', 'arj' -->
              <el-button
                v-if="!$u.test.isEmpty(formData.invoiceUrl)"
                class="u-m-l-10"
                type="text"
                @click="$preViewer(formData.invoiceUrl)"
                >查看</el-button
              >
            </div>
          </el-form-item>
          <!-- <el-form-item label="税票：">
            <div class="u-flex u-col-center">
              <button-upload
                v-if="type !== 'examine'"
                :custom-type="['pdf', 'jpg', 'png', 'rar', 'zip', '7z', 'arj']"
                text="上传税票(png/jpg/png/zip)"
                @upload-success="setField('taxReceiptUrl', $event)"
              />
              <el-button
                v-if="!$u.test.isEmpty(formData.taxReceiptUrl)"
                class="u-m-l-10"
                type="text"
                @click="$preViewer(formData.taxReceiptUrl)"
                >查看</el-button
              >
            </div>
          </el-form-item> -->
          <el-form-item label="物流公司：" v-if="(type == 'add' || type == 'editor') && invoiceApplicationVo.invoiceType != 1">
            <!-- <el-input
              v-model="formData.expressCompanyName"
              disabled
              style="width: 300px"
              placeholder="请输入物流公司名称"
            /> -->
            <el-select
              v-model="formData.expressCompanyName"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="(i, k) in expressOptions"
                :key="k"
                :label="i"
                :value="i"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="运单号：" v-if="(type == 'add' || type == 'editor') && invoiceApplicationVo.invoiceType != 1">
            <el-input
              v-model="formData.expressSheetNo"
              :disabled="type === 'examine'"
              style="width: 300px"
              placeholder="请输入运单号"
            />
          </el-form-item>
        </el-form>
        <div
          v-if="type === 'add' || type === 'editor'"
          style="height: 100px; margin-left: 40px"
          class="flex justify-start align-items"
        >
          <el-button type="primary" :loading="loading" @click="submit"
            >提交</el-button
          >
        </div>
      </div>
    </el-card>

    <el-card v-if="type == 'examine' && invoiceApplicationVo.invoiceType != 1" class="u-m-t-20">
      <div class="card--title">物流信息</div>
      <div class="card--content" style="margin-left: 20px">
        <div class="express-info">
          <ul>
            <li>物流公司：{{ sendAndReceiveVo.logisticsCompany }}</li>
            <li>物流单号：{{ sendAndReceiveVo.logisticsOrderNo }}</li>
            <li>
              收件信息：{{ sendAndReceiveVo.addressee }}
              {{ sendAndReceiveVo.addresseeTelephone }}
              {{ sendAndReceiveVo.toAddress }}
            </li>
            <li>
              发件信息：{{ sendAndReceiveVo.from }}
              {{ sendAndReceiveVo.fromTelephone }}
              {{ sendAndReceiveVo.sendingAddress }}
            </li>
          </ul>
        </div>
        <div class="logistics-info">
          <el-row
            v-for="(item, index) of expressLogisticsInfoList"
            :key="index"
            :gutter="10"
            style="margin-bottom: 15px"
          >
            <el-col :md="6">
              <span class="label">{{ item.acceptTime }}</span>
            </el-col>
            <el-col :md="18">
              <span class="label">{{ item.acceptStation }}</span>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-card>

    <!-- 关联任务 -->
    <el-dialog title="关联的任务" :visible.sync="showTaskVo">
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">任务编号：</span>
          <span class="value">{{ taskInfo.taskCode }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">任务名称：</span>
          <span class="value">{{ taskInfo.taskName }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">任务类型：</span>
          <span class="value">{{ taskInfo.industryType }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">开始时间：</span>
          <span class="value">{{ taskInfo.releaseDate }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="8" style="margin-bottom: 15px">
          <span class="label">结束时间：</span>
          <span class="value">{{ taskInfo.deadlineDate }}</span>
        </el-col>
      </el-row>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTaskVo = false">确定</el-button>
      </div>
    </el-dialog>

    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import { addInvoice, getPlaInvoiceInfo, updateInvoice } from "@/api/totalTax";
import ButtonUpload from "@/components/Upload/buttonUpload";
import WorkerPayList from "@/views/tax/components/workerPayList";
import { getDict } from "@/api/common";
import { downFile } from "@/utils";
export default {
  components: {
    WorkerPayList,
    ButtonUpload,
  },
  data() {
    return {
      expressOptions: [],
      loading: false,
      taskInfo: {
        taskCode: "",
        taskName: "",
        industryType: "",
        releaseDate: "",
        deadlineDate: "",
      }, // 关联任务显示内容
      showTaskVo: false, // 关联任务弹出控制变量
      type: "", // 页面类型
      showAddress: false, // 是否添加地址
      paymentOrderList: {}, // 支付信息
      billingInfoList: [], // 开票信息
      BuyerInfo: {}, // 购买方信息
      // tableData: [], // 开票类目
      sellerInfo: {}, //  销售方
      expressLogisticsInfoList: [], // 物流信息
      // addressList: [], // 地址信息
      sendAndReceiveVo: {}, // 快递信息
      invoiceCatalogVo: {}, // 已开票-开票类目信息
      addressFrom: {
        linkName: "",
        linkMobile: "",
        addressName: "",
        isNot: false,
      }, // 新加住址表单
      addressRadio: "1", // 收获地址单选
      billingRadio: "1", // 开票类目
      defaultAddress: false,
      formData: {
        // id: '', //  修改时用
        applicationId: "", // 申请开票id
        applicationInvoicePerson: "", // 申请开票人,购买方
        invoicePrintPerson: "", // 开票人,销售方
        invoiceCatalog: "", // 开票类目
        invoiceMoney: 0, // 发票金额
        invoiceUrl: "", // 发票存放url
        taxReceiptUrl: "", // 税票存放url
        expressCompanyName: "", // 快递公司名称
        expressSheetNo: "", // 快递单号
        invoiceCodeNo: "", // 发票代码
        invoiceDesc: "", // 开票说明
        invoiceNumber: "", // 发票数字
        invoiceNumbers: 1, // 发票张数,当前默认为1
      },
      // 提交
      suibmitFome: {
        applicationAddress: "", // 地址id
        applicationDesc: "", // 备注说明
        applicationPerson: "", // 申请人,商户名称
        id: "", // id主键
        invoiceCatalogType: "", // 开票类目
        invoiceTotalAmount: "", // 开票总额
        paymentOrderId: "", // 支付id,多个支付id以逗号隔开
      },
      totalMoney: 0,
      invoiceApplicationVo: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.fetchDict();
    if (this.type === "add") {
      this.infoData(getPlaInvoiceInfo);
    } else if (this.type === "editor" || this.type === "examine") {
      this.infoData(updateInvoice);
    }
  },
  methods: {
    // 下载
    downFiles() {
      window.open(this.invoiceApplicationVo.invoiceUrl,'_blank')
    },
    fetchDict() {
      getDict({
        code: "express",
      }).then((res) => {
        this.expressOptions = res.data || [];
      });
    },
    openTaskVo(item) {
      this.showTaskVo = true;
      if (item) {
        this.taskInfo = item;
      }
    },
    // 上传文件
    setField(field, file) {
      this.formData[field] = file;
    },
    openFile(url) {
      this.$preViewer(url);
    },
    //  初始化数据
    infoData(callback) {
      const params = {};
      if (this.type === "add") {
        params.applicationID = this.id;
      } else if (this.type === "editor" || this.type === "examine") {
        params.invoiceId = this.id;
      }

      callback(params).then((res) => {
        let invoiceType = res.obj.invoiceApplicationVo
        if(invoiceType) {
          this.invoiceApplicationVo = res.obj.invoiceApplicationVo
        }else {
          this.invoiceApplicationVo = res.obj.plaInvoiceInfoVo
          
        }
        
        const paymentOrderVoList = JSON.parse(
          JSON.stringify(res.obj.paymentOrderVoList)
        );
        const form = {};
        const idList = [];

        form.companySName = paymentOrderVoList[0].companySName;
        form.platformServiceProvider =
          paymentOrderVoList[0].platformServiceProvider;
        form.paymentOrderStatus = paymentOrderVoList[0].paymentOrderStatus;
        form.paymentDate = paymentOrderVoList[0].paymentDate;

        form.paymentInventoryList = [];
        form.taskVoList = [];
        form.subpackagePaymentList = [];
        form.acceptanceCertificateList = [];

        paymentOrderVoList.forEach((item) => {
          idList.push(item.id);
          form.paymentInventoryList.push(item.paymentInventory);
          form.subpackagePaymentList.push(item.subpackagePayment);
          form.taskVoList.push(item.taskVo);
          form.acceptanceCertificateList.push(item.acceptanceCertificate);
        });

        form.id = idList.join(",");
        this.$nextTick(() => {
          this.$refs.payList.fetchData(form.id);
        });
        this.paymentOrderList = form;
        this.billingInfoList = res.obj.billingInfoVoList;
        this.BuyerInfo = res.obj.buyerVo || {};
        this.suibmitFome = res.obj.invoiceApplicationVo || {};
        this.sellerInfo = res.obj.sellerVo || {};
        this.totalMoney = res.obj.totalMoney;
        this.sendAndReceiveVo = res.obj.addressVo || {};
        this.invoiceCatalogVo = res.obj.invoiceCatalogVo;

        if (this.type === "editor" || this.type === "examine") {
          this.formData = res.obj.plaInvoiceInfoVo;
          if (res.obj.sendAndReceiveVo) {
            this.sendAndReceiveVo = Object.assign(
              this.sendAndReceiveVo,
              res.obj.sendAndReceiveVo || {}
            );
          }
          if (res.obj.expressLogisticsInfoList) {
            this.expressLogisticsInfoList = res.obj.expressLogisticsInfoList;
          }
        }
      });
    },

    submit() {
      this.loading = true;
      if (this.type === "add") {
        this.formData.applicationId = this.id;
        this.formData.applicationInvoicePerson = this.BuyerInfo.companyName;
        this.formData.invoicePrintPerson =
          this.sellerInfo.invoiceEnterpriseName;
        this.formData.invoiceCatalog = this.invoiceCatalogVo.id;
        this.formData.invoiceMoney = this.totalMoney;
      }
      this.formData.invoiceType = this.invoiceApplicationVo.invoiceType
      this.formData.invoiceForm = this.invoiceApplicationVo.invoiceForm


      const data = JSON.parse(JSON.stringify(this.formData));
      addInvoice(data)
        .then((res) => {
          this.loading = false;
          this.$message.success(res.message);
          this.goBack();
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
  },
};
</script>

<style scoped lang="scss">
ul,
li,
p {
  list-style: none; /*清除列表默认样式*/
  /*padding: 0; !*清除padding*!*/
  /*margin: 0;*/
}
.invoiceVo-wrap,
.invoiceCatalogVo-wrap {
  .item {
    padding-bottom: 10px;
    li {
      span {
        margin-right: 8px;
      }
    }
  }
}
.card--content {
  .el-col {
    .value {
      // width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .pointer {
    margin-left: 10px;
    color: #3f9ffc;
    cursor: pointer;
    // text-decoration: underline;
  }
}
.blue-box {
  max-width: 700px;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  .money {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
}
.add-address {
  margin-top: 30px;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  border: 1px dotted #ebeef5;
}
.table-radio {
  // border: 1px solid red;
  max-height: 400px;
  overflow-y: auto;
}

.logistics-info,
.express-info {
  ul,
  li,
  p {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
}
.express-info {
  ul {
    padding: 10px 15px;
    width: 928px;
    background: #f6faff;
    border: 1px solid #badcfe;
    li {
      height: 30px;
      line-height: 30px;
    }
  }
}

.logistics-info {
  padding: 10px 25px;
  width: 928px;
  min-height: 200px;
  background: #fbfbfc;
  border: 1px solid #eaeaea;
}
.tabl-title {
  width: 100%;
  text-align: center;
  height: 30px;
  font-size: 16px;
}
.form-wrap {
  margin-top: 20px;
  width: 614px;
}
.table-wrap {
  margin-top: 20px;
  margin-left: 80px;
  ul,
  li,
  p {
    list-style: none; /*清除列表默认样式*/
    padding: 0; /*清除padding*/
    margin: 0;
  }
  .table {
    border: 1px solid #ebeef5;
    min-width: 750px;

    .title {
      position: relative;
      width: 80px;
      text-align: center;
      // line-height: 160px;
      .txt {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -33px;
        margin-left: -10px;
        display: inline-block;
        width: 20px;
      }
    }
    .content {
      border-left: 1px solid #ebeef5;
      width: 100%;
      p {
        width: 100%;
        line-height: 1.6;
        padding: 5px 20px;
      }
      // p:not(:last-child){
      //      border-bottom: 1px solid #EBEEF5;

      // }
    }
    .count {
      border: 1px solid #ebeef5;
      border-left: 0;
      border-right: 0;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
    }
  }
}
</style>
