<template>
  <div class="">
    <div class="">
      <!--      <el-form :inline="true" :model="formData" size="small">-->
      <!--        &lt;!&ndash;        <el-form-item label="任务名称">&ndash;&gt;-->
      <!--        &lt;!&ndash;          <el-input v-model="formData.taskName" placeholder="请输入任务名称" />&ndash;&gt;-->
      <!--        &lt;!&ndash;        </el-form-item>&ndash;&gt;-->
      <!--        <el-form-item>-->
      <!--          <el-button type="primary" @click="resetPage">查询</el-button>-->
      <!--        </el-form-item>-->
      <!--      </el-form>-->
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        max-height="500px"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="taskCode"
          label="任务编号"
          width="100"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="taskName"
          label="任务名称"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="合作类型"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.cooperateMode | cooperateMode }}
          </template>
        </el-table-column>
        <el-table-column
          label="任务模式"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.taskMode | taskMode }}
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.state, $enum.taskState(), true)" />
          </template>
        </el-table-column>
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
          width="100"
          show-overflow-tooltip
        />
        <el-table-column
          label="操作"
          width="80"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="selectItem(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getTaskList } from '@/api/pay'

export default {
  name: 'TaskLookup',
  props: {
    merchantId: {
      type: [String, Number],
      required: true
    },
    cooperateMode: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        taskName: '',
        cooperateMode: 0
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getTaskList(Object.assign({}, this.formData, this.paramsData, {
        merchantId: this.merchantId,
        cooperateMode: this.cooperateMode
      })).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    selectItem(row) {
      this.$emit('on-select', Object.assign({}, row))
    }
  }
}
</script>

<style scoped>

</style>
