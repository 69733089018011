import request from '@/utils/request'

/**
 * 服务商加盟平台合同
 * @param params
 * @returns {AxiosPromise}
 */
export function contractTax(params) {
  return request({
    url: '/platform/contract/tax',
    method: 'get',
    params
  })
}
/**
 * 商户加盟平台合同
 * @param params
 * @returns {AxiosPromise}
 */
 export function contractCompany(params) {
    return request({
      url: '/platform/contract/company',
      method: 'get',
      params
    })
  }
/**
 * 服务商-商户合同
 * @param params
 * @returns {AxiosPromise}
 */
 export function taxCompany(params) {
    return request({
      url: '/platform/contract/tax-company',
      method: 'get',
      params
    })
  }
  /**
 * 服务商-创客合同
 * @param params
 * @returns {AxiosPromise}
 */
 export function taxWorker(params) {
    return request({
      url: '/platform/contract/tax-worker',
      method: 'get',
      params
    })
  }
  /**
 * 创客加盟平台合同
 * @param params
 * @returns {AxiosPromise}
 */
 export function contractWorker(params) {
    return request({
      url: '/platform/contract/worker',
      method: 'get',
      params
    })
  }
    /**
 * 商户名字列表
 * @param params
 * @returns {AxiosPromise}
 */
 export function companyName() {
    return request({
      url: '/platform/contract/company-name-list',
      method: 'get',
    })
  }
      /**
 * 服务商名字列表
 * @param params
 * @returns {AxiosPromise}
 */
 export function taxName() {
  return request({
    url: '/platform/contract/tax-name-list',
    method: 'get',
  })
}
      /**
 * 批量发送创客签约短链
 * @param params
 * @returns {AxiosPromise}
 */
 export function sendUrl(data) {
  return request({
    url: '/platform/contract/send-short-url',
    method: 'post',
    data
  })
}