import request from '@/utils/request'

/**
 * 获取菜单树
 * @returns {AxiosPromise}
 */
export function getMenus() {
  return request({
    url: '/platform/menu/getPlatformMenuList',
    method: 'get'
  })
}
/**
 * 获取测试账户信息
 * @returns {AxiosPromise}
 */
export function getSerialNumber(params) {
  return request({
    url: '/platform/menu/getSerialNumber',
    method: 'get',
    params
  })
}
/**
 * 刷新序列号
 * @returns {AxiosPromise}
 */
export function refreshTestManagers(params) {
  return request({
    url: '/platform/menu/refreshTestManagers',
    method: 'post',
    params
  })
}
/**
 * 获取权限
 * @returns {AxiosPromise}
 */
export function getAuth() {
  return request({
    url: '/platform/menu/getMenuList',
    method: 'get'
  })
}

/**
 * 账号列表
 * @returns {AxiosPromise}
 */
export function getAuthList() {
  return request({
    url: '/platform/menu/getAllRole',
    method: 'post'
  })
}

/**
 * 账号详情
 * @returns {AxiosPromise}
 */
export function getAuthDetail(params) {
  return request({
    url: '/platform/menu/getManagersInfo',
    method: 'post',
    params
  })
}

/**
 * 修改子账号状态
 * @param params
 * @returns {AxiosPromise}
 */
export function changeAuthStatus(params) {
  return request({
    url: '/platform/menu/updataRoleStatus',
    method: 'post',
    params
  })
}
/**
 * 修改子账号
 * @param data
 * @returns {AxiosPromise}
 */
export function updateAuth(data) {
  return request({
    url: '/platform/menu/addManagers',
    method: 'post',
    data
  })
}

/**
 * 删除账号
 * @param params
 * @returns {AxiosPromise}
 */
export function delAuth(params) {
  return request({
    url: '/platform/menu/daleteRole',
    method: 'post',
    params
  })
}

