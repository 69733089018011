<!-- 商户预警通知 -->
<template>
  <div class="u-p-24 bg-white notice">
    <div class="u-m-b-36">
      <span>消息通知</span>
      <el-button type="primary" size="small" style="float: right;" @click="clearNotice()">一键清空消息</el-button>
    </div>
    <div class="box">
      <div style="width: 60%">
        <div v-for="(notice, k) in noticeList" :key="k" class="box-item">
          <i class="el-icon-close" style="float: right" @click="clearOneNotice(notice)" />
          <div class="u-flex u-row-between u-p-8">
            <!-- <div>
              <div>
                <span class="shop-name">{{ notice.name|ellipsis }}</span>
                <span>触发了一条预警</span>
                <span class="shop-date">({{ notice.updateDate }})</span>
              </div>
              <div class="ellipsis2">{{ notice.cause }}</div>
            </div>
            <div>
              <el-button type="text" size="small" @click="goNotice(notice)">去处理</el-button>
            </div> -->
              <span class="shop-name">{{ notice.name }}</span>
              <div>
                <span class="u-badge success" :class="notice.status==0?'primary':'success'"></span>
                {{ notice.status==0?'处理中':'已完成' }}
              </div>
              <span class="shop-date">{{ notice.createDate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-if="noticeList&&noticeList.length" class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
  </div>
</template>

<script>
import { notificationList, deleteNotification } from '@/api/blackList'
export default {
  filters: {
    ellipsis(value) {
      if (!value) return ''
      if (value.length >= 20) {
        return value.slice(0, 20) + '...'
      }
      return value
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        // name: '',
        // beginDate: '',
        // endDate: '',
        // code: ''
      },
      noticeList: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    setFields(fields, val) {
      this.workerFormData[fields] = val
    },
    fetchData() {
      this.loading = true
      notificationList(Object.assign({}, this.formData, this.paramsData)).then(res => {
        this.loading = false
        this.noticeList = res.data || []
        this.pageTotal = res.pageCount
      }).catch(res => {
        this.loading = false
      })
    },
    clearOneNotice(i) {
      this.$confirm('您确定要删除此条消息吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteNotification({
          id: i.id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.fetchData()
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    },
    clearNotice() {
      this.$confirm('您确定要清空消息吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        deleteNotification({
          id: 'all'
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.$router.go(-1)
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    },
    goNotice(i) {
      this.$router.push({
        path: '/warn/shop/detail',
        query: i
      })
    }
  }
}
</script>

<style scoped>
  .notice {
    min-height: calc(100vh - 90px);
  }
  .box {
    display: flex;
    justify-content: center;
  }
  .box-item{
    border: 1px solid #d8dce5;
    padding: 4px;
  }
  .shop-name {
    color: #409EFF;
    display: inline-block;
    width: 300px;
    margin-bottom: 8px;
  }
  .shop-date {
    margin-left: 8px;
    color: #8C8C8C;
  }
  .ellipsis2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
</style>
