var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formData",
          staticClass: "u-form",
          attrs: { model: _vm.value, "label-width": "150px" },
        },
        [
          _c(
            "el-card",
            { attrs: { header: "业务配置" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "合作类型" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.total.checked,
                        callback: function ($$v) {
                          _vm.$set(_vm.total, "checked", $$v)
                        },
                        expression: "total.checked",
                      },
                    },
                    [_vm._v("自然人【总包+分包】 (6%专票)")]
                  ),
                ],
                1
              ),
              _vm.total.checked
                ? _c(
                    "div",
                    {
                      staticClass: "u-border u-p-lf-10 u-p-tb-40",
                      staticStyle: { "margin-left": "150px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": _vm.subLabelWidth,
                            label: "综合税费率(多选)",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              class: _vm.total.one ? "" : "box-un-active",
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.total.one,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.total, "one", $$v)
                                    },
                                    expression: "total.one",
                                  },
                                },
                                [_vm._v("一口价")]
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "r80",
                                      attrs: {
                                        min: "1",
                                        placeholder: "请输入最低服务费率",
                                      },
                                      model: {
                                        value:
                                          _vm.value.totalTaxPackage.taxPrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.value.totalTaxPackage,
                                            "taxPrice",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "value.totalTaxPackage.taxPrice",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v("%")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              class: _vm.total.more ? "" : "box-un-active",
                              attrs: { span: 16 },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.total.more,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.total, "more", $$v)
                                    },
                                    expression: "total.more",
                                  },
                                },
                                [_vm._v("梯度价")]
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "u-text-center",
                                      attrs: { span: 14 },
                                    },
                                    [_vm._v(" 单人单月额度 ")]
                                  ),
                                  _c("el-col", { attrs: { span: 10 } }, [
                                    _vm._v(" 服务费率 "),
                                  ]),
                                ],
                                1
                              ),
                              _vm.loadTag.totalServiceLadders
                                ? _vm._l(
                                    _vm.totalServiceLadders,
                                    function (i, k) {
                                      return _c(
                                        "el-row",
                                        {
                                          key: i.guid,
                                          staticClass: "u-m-b-10",
                                          attrs: { gutter: 10 },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 13 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 11 } },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        readonly: "",
                                                        placeholder: "请输入",
                                                      },
                                                      model: {
                                                        value: i.startMoney,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            i,
                                                            "startMoney",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "i.startMoney",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "suffix",
                                                          },
                                                          slot: "suffix",
                                                        },
                                                        [_vm._v("元")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "u-text-center",
                                                  attrs: { span: 2 },
                                                },
                                                [_vm._v("至")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 11 } },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        placeholder: "请输入",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeEnd(
                                                            "totalServiceLadders",
                                                            k,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: i.endMoney,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            i,
                                                            "endMoney",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "i.endMoney",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "suffix",
                                                          },
                                                          slot: "suffix",
                                                        },
                                                        [_vm._v("元")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 9 } },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  class: {
                                                    "default-input": _vm.isEdit,
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请输入最低服务费率",
                                                  },
                                                  nativeOn: {
                                                    input: function ($event) {
                                                      return _vm.changeBg(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: i.rate,
                                                    callback: function ($$v) {
                                                      _vm.$set(i, "rate", $$v)
                                                    },
                                                    expression: "i.rate",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [_vm._v("%")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("el-col", { attrs: { span: 2 } }, [
                                            k !== 0 &&
                                            k ===
                                              _vm.totalServiceLadders.length - 1
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-remove-outline text-danger u-font-18 u-point",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeList(
                                                        "totalServiceLadders",
                                                        k
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ]),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point",
                                  staticStyle: {
                                    width: "100px",
                                    margin: "auto",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addList("totalServiceLadders")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus-outline u-font-24",
                                  }),
                                  _vm._v("添加 "),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "auto-width",
                          attrs: {
                            "label-width": _vm.subLabelWidth,
                            label: "可开票类目",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "u-border" },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.taxLoading,
                                      expression: "taxLoading",
                                    },
                                  ],
                                  ref: "totalTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.taxTypeList,
                                    "tooltip-effect": "dark",
                                    "max-height": "400px",
                                  },
                                  on: {
                                    "selection-change": function ($event) {
                                      return _vm.handleSelectionChange(
                                        "totalTable",
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "selection", width: "55" },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序号",
                                      align: "center",
                                      width: "55",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.$index + 1) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3056706777
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "serviceType",
                                      align: "center",
                                      width: "120",
                                      label: "服务类型",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "serviceContent",
                                      label: "具体服务内容",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "billingCategory",
                                      width: "200",
                                      label: "开票类目",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: { width: "80", label: "操作" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _c(
                                                "el-button",
                                                {
                                                  attrs: {
                                                    type: "text",
                                                    size: "small",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.delTax(
                                                        scope.row
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("删除")]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3899815385
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-dashed-border u-text-center u-point u-m-t-10",
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogTableVisible = true
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus u-m-r-10",
                                  }),
                                  _vm._v("添加可开票类目"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("check-total", {
                        ref: "checkTotal",
                        attrs: {
                          "total-checked": _vm.total.checked,
                          data: _vm.value.totalLadders,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { staticClass: "u-m-t-20" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.crowd.checked,
                        callback: function ($$v) {
                          _vm.$set(_vm.crowd, "checked", $$v)
                        },
                        expression: "crowd.checked",
                      },
                    },
                    [_vm._v("自然人【众包】 (3%普票)")]
                  ),
                ],
                1
              ),
              _vm.crowd.checked
                ? _c(
                    "div",
                    {
                      staticClass: "u-border u-p-lf-10 u-p-tb-40",
                      staticStyle: { "margin-left": "150px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": _vm.subLabelWidth,
                            label: "综合税费率(多选)",
                          },
                        },
                        [
                          _c(
                            "el-col",
                            {
                              class: _vm.crowd.one ? "" : "box-un-active",
                              attrs: { span: 8 },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.crowd.one,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.crowd, "one", $$v)
                                    },
                                    expression: "crowd.one",
                                  },
                                },
                                [_vm._v("一口价")]
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticClass: "r80",
                                      attrs: {
                                        min: "1",
                                        onkeyup:
                                          "value=value.replace(/[^0-9]/g,'');if(String(value)==='0'){value=1}",
                                        placeholder: "请输入最低服务费率",
                                      },
                                      model: {
                                        value:
                                          _vm.value.manyTaxPackage.taxPrice,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.value.manyTaxPackage,
                                            "taxPrice",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "value.manyTaxPackage.taxPrice",
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          attrs: { slot: "suffix" },
                                          slot: "suffix",
                                        },
                                        [_vm._v("%")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              class: _vm.crowd.more ? "" : "box-un-active",
                              attrs: { span: 16 },
                            },
                            [
                              _c(
                                "el-checkbox",
                                {
                                  model: {
                                    value: _vm.crowd.more,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.crowd, "more", $$v)
                                    },
                                    expression: "crowd.more",
                                  },
                                },
                                [_vm._v("梯度价")]
                              ),
                              _c(
                                "el-row",
                                { attrs: { gutter: 10 } },
                                [
                                  _c(
                                    "el-col",
                                    {
                                      staticClass: "u-text-center",
                                      attrs: { span: 14 },
                                    },
                                    [_vm._v(" 单人单月额度 ")]
                                  ),
                                  _c("el-col", { attrs: { span: 10 } }, [
                                    _vm._v(" 服务费率 "),
                                  ]),
                                ],
                                1
                              ),
                              _vm.loadTag.manyServiceLadders
                                ? _vm._l(
                                    _vm.manyServiceLadders,
                                    function (i, k) {
                                      return _c(
                                        "el-row",
                                        {
                                          key: i.guid,
                                          staticClass: "u-m-b-10",
                                          attrs: { gutter: 10 },
                                        },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 13 } },
                                            [
                                              _c(
                                                "el-col",
                                                { attrs: { span: 11 } },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        readonly: "",
                                                        placeholder: "请输入",
                                                      },
                                                      model: {
                                                        value: i.startMoney,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            i,
                                                            "startMoney",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "i.startMoney",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "suffix",
                                                          },
                                                          slot: "suffix",
                                                        },
                                                        [_vm._v("元")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "el-col",
                                                {
                                                  staticClass: "u-text-center",
                                                  attrs: { span: 2 },
                                                },
                                                [_vm._v("至")]
                                              ),
                                              _c(
                                                "el-col",
                                                { attrs: { span: 11 } },
                                                [
                                                  _c(
                                                    "el-input",
                                                    {
                                                      attrs: {
                                                        placeholder: "请输入",
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeEnd(
                                                            "manyServiceLadders",
                                                            k,
                                                            $event
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: i.endMoney,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            i,
                                                            "endMoney",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "i.endMoney",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          attrs: {
                                                            slot: "suffix",
                                                          },
                                                          slot: "suffix",
                                                        },
                                                        [_vm._v("元")]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 9 } },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  class: {
                                                    "default-input": _vm.isEdit,
                                                  },
                                                  attrs: {
                                                    placeholder:
                                                      "请输入最低服务费率",
                                                  },
                                                  nativeOn: {
                                                    input: function ($event) {
                                                      return _vm.changeBg(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: i.rate,
                                                    callback: function ($$v) {
                                                      _vm.$set(i, "rate", $$v)
                                                    },
                                                    expression: "i.rate",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [_vm._v("%")]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("el-col", { attrs: { span: 2 } }, [
                                            k !== 0 &&
                                            k ===
                                              _vm.manyServiceLadders.length - 1
                                              ? _c("i", {
                                                  staticClass:
                                                    "el-icon-remove-outline text-danger u-font-18 u-point",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeList(
                                                        "manyServiceLadders",
                                                        k
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ]),
                                        ],
                                        1
                                      )
                                    }
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point",
                                  staticStyle: {
                                    width: "100px",
                                    margin: "auto",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addList("manyServiceLadders")
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus-outline u-font-24",
                                  }),
                                  _vm._v("添加 "),
                                ]
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "auto-width",
                          attrs: {
                            "label-width": _vm.subLabelWidth,
                            label: "可开票类目",
                            required: "",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "u-border" },
                            [
                              _c(
                                "el-table",
                                {
                                  directives: [
                                    {
                                      name: "loading",
                                      rawName: "v-loading",
                                      value: _vm.taxLoading,
                                      expression: "taxLoading",
                                    },
                                  ],
                                  ref: "crowdTable",
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    data: _vm.taxTypeList,
                                    "tooltip-effect": "dark",
                                    "max-height": "400px",
                                  },
                                  on: {
                                    "selection-change": function ($event) {
                                      return _vm.handleSelectionChange(
                                        "crowdTable",
                                        $event
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "selection", width: "55" },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      label: "序号",
                                      align: "center",
                                      width: "55",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (scope) {
                                            return [
                                              _vm._v(
                                                " " +
                                                  _vm._s(scope.$index + 1) +
                                                  " "
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3056706777
                                    ),
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "serviceType",
                                      align: "center",
                                      width: "120",
                                      label: "服务类型",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "serviceContent",
                                      label: "具体服务内容",
                                    },
                                  }),
                                  _c("el-table-column", {
                                    attrs: {
                                      prop: "billingCategory",
                                      width: "200",
                                      label: "开票类目",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-dashed-border u-text-center u-point u-m-t-10",
                                  on: {
                                    click: function ($event) {
                                      _vm.dialogTableVisible = true
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus u-m-r-10",
                                  }),
                                  _vm._v("添加可开票类目"),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c("check-crowd", {
                        ref: "checkCrowd",
                        attrs: {
                          "crowd-checked": _vm.crowd.checked,
                          data: _vm.value.manyLadders,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEdit
                ? _c(
                    "el-form-item",
                    { staticClass: "u-m-t-20" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "large" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("确定")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isEdit
        ? _c(
            "div",
            { staticClass: "wrap-fixed-footer btn--box" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small" },
                  on: {
                    click: function ($event) {
                      return _vm.next("formData")
                    },
                  },
                },
                [_vm._v("下一步")]
              ),
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.prev } },
                [_vm._v("上一步")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "新增开票类目",
            visible: _vm.dialogTableVisible,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [
          _c("add-tax", {
            ref: "addTax",
            on: {
              success: _vm.successAddTax,
              cancel: function ($event) {
                _vm.dialogTableVisible = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }