<template>
  <div>
    <div class="wrap-header u-flex u-col-center">
      <avatar :src="avatar" :size="72" />
      <div class="u-p-l-25">
        <div class="title">{{ dayType }}，{{ myName }}，祝你开心每一天！</div>
        <div class="sub">{{ name }} | {{ userinfo.roleName }}</div>
      </div>
    </div>
    <div class="u-p-15">
      <count />
    </div>
    <el-row class="u-p-15" :gutter="20">
      <el-col :md="24">
        <chart />
      </el-col>
      <!--      <el-col :md="8">-->
      <!--        <message />-->
      <!--      </el-col>-->
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Count from '@/views/dashboard/components/count'
import Chart from '@/views/dashboard/components/chart'
import { myName } from '@/settings'

export default {
  components: { Chart, Count },
  data() {
    return {
      hour: null,
      myName: myName
    }
  },
  computed: {
    ...mapGetters([
      'userinfo',
      'avatar',
      'name'
    ]),
    dayType() {
      const date = this.hour
      if (date < 11) {
        return '早安'
      } else if (date < 18) {
        return '午安'
      } else if (date < 24) {
        return '晚安'
      }
      return '你好'
    }
  },
  created() {
    this.hour = this.$dayjs().hour()
  }
}
</script>

<style lang="scss" scoped>
.wrap-header {
  background-color: #fff;
  padding: 25px 30px;
  .title {
    font-size:20px;
    font-weight:400;
    line-height:28px;
    color:rgba(0,0,0,0.85);
  }
  .sub {
    margin-top: 5px;
    font-size:14px;
    font-weight:400;
    line-height:22px;
    color:rgba(0,0,0,0.45);
  }
}
</style>
