var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.showViewer.show
        ? _c("el-image-viewer", {
            staticStyle: { "z-index": "9999" },
            attrs: {
              "initial-index": _vm.showViewer.initialIndex,
              "on-close": _vm.showViewerClose,
              "url-list": _vm.showViewer.url,
            },
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          ref: "el-viewer",
          staticStyle: { "text-align": "left" },
          attrs: { title: "查看文件", visible: _vm.dialogShow },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _vm._l(_vm.files, function (i, k) {
            return [
              _c(
                "span",
                { key: k + new Date() },
                [
                  i.type === "pdf"
                    ? _c("svg-icon", {
                        attrs: { "class-name": "pdf", "icon-class": "pdf" },
                        on: {
                          click: function ($event) {
                            return _vm.showSimple(i)
                          },
                        },
                      })
                    : i.type === "img"
                    ? _c("el-image", {
                        staticStyle: {
                          width: "50px",
                          height: "50px",
                          margin: "5px",
                        },
                        attrs: { src: i.path, fit: "contain" },
                        on: {
                          click: function ($event) {
                            return _vm.showSimple(i)
                          },
                        },
                      })
                    : i.type === "excel"
                    ? _c("svg-icon", {
                        attrs: { "class-name": "excel", "icon-class": "excel" },
                        on: {
                          click: function ($event) {
                            return _vm.showSimple(i)
                          },
                        },
                      })
                    : i.type === "video"
                    ? _c("i", {
                        staticClass: "el-icon-video-camera",
                        on: {
                          click: function ($event) {
                            return _vm.showSimple(i)
                          },
                        },
                      })
                    : i.type === "file"
                    ? _c("i", {
                        staticClass: "el-icon-tickets",
                        on: {
                          click: function ($event) {
                            return _vm.showSimple(i)
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }