var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      ref: "formData",
      staticClass: "u-form",
      staticStyle: { "max-width": "1200px" },
      attrs: { model: _vm.formData, rules: _vm.rules, "label-width": "150px" },
    },
    [
      _c(
        "el-form-item",
        { attrs: { label: "服务类型", prop: "serviceType" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入" },
            model: {
              value: _vm.formData.serviceType,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "serviceType", $$v)
              },
              expression: "formData.serviceType",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "具体服务内容", prop: "serviceContent" } },
        [
          _c("el-input", {
            attrs: { type: "textarea", placeholder: "请输入" },
            model: {
              value: _vm.formData.serviceContent,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "serviceContent", $$v)
              },
              expression: "formData.serviceContent",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "开票类目", prop: "billingCategory" } },
        [
          _c("el-input", {
            attrs: { placeholder: "请输入" },
            model: {
              value: _vm.formData.billingCategory,
              callback: function ($$v) {
                _vm.$set(_vm.formData, "billingCategory", $$v)
              },
              expression: "formData.billingCategory",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit("formData")
                },
              },
            },
            [_vm._v("提交")]
          ),
          _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }