<template>
  <div class="u-p-24">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="企业名称">
          <el-input v-model="formData.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="时间范围">
          <el-date-picker
            v-model="formData.beginDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择时间"
            style="width: 160px;"
          />
        </el-form-item>
        <el-form-item>至</el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="formData.endDate"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择时间"
            style="width: 160px;"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="编号"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="name"
          label="企业名称"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="count"
          label="待处理预警"
          align="center"
        />
        <el-table-column
          prop="createDate"
          label="预警时间"
          align="center"
        />
        <el-table-column
          label="操作"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)">处理</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getCompanylist } from '@/api/blackList'
export default {
  data() {
    return {
      loading: false,
      formData: {
        name: '',
        startDate: '',
        endDate: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    setFields(fields, val) {
      this.workerFormData[fields] = val
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getCompanylist(Object.assign({}, this.formData, this.paramsData)).then(res => {
        this.loading = false
        this.list = res.data || []
        this.pageTotal = res.pageCount
      }).catch(res => {
        this.loading = false
      })
    },
    handleEdit(i) {
      this.$router.push({
        path: '/warn/shop/detail',
        query: i
      })
    }
  }
}
</script>

<style scoped>
  .batch{
    text-align: left;
  }
</style>
