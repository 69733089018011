import request from '@/utils/request'

/**
 * 预警创客列表
 */
export function getWorkerlist(params) {
  return request({
    url: '/platform/warning/worker-list',
    method: 'get',
    params
  })
}
/**
 * 创客预警详情列表
 */
export function getWorkerInfo(params) {
  return request({
    url: '/platform/warning/worker-info',
    method: 'get',
    params
  })
}
/**
 * 创客线下审核
 */
export function workerOfflineAudit(params) {
  return request({
    url: '/platform/warning/worker-offline-audit',
    method: 'post',
    params
  })
}
/**
 * 创客解除预警
 */
export function workerCancelWarning(params) {
  return request({
    url: '/platform/warning/worker-cancel-warning',
    method: 'post',
    params
  })
}
/**
 * 预警商户列表
 */
export function getCompanylist(params) {
  return request({
    url: '/platform/warning/company-list',
    method: 'get',
    params
  })
}
/**
 * 商户预警详情列表
 */
export function getCompanyInfo(params) {
  return request({
    url: '/platform/warning/company-info',
    method: 'get',
    params
  })
}
/**
 * 商户线下审核
 */
export function companyOfflineAudit(params) {
  return request({
    url: '/platform/warning/company-offline-audit',
    method: 'post',
    params
  })
}
/**
 * 商户解除预警
 */
export function companyCancelWarning(params) {
  return request({
    url: '/platform/warning/company-cancel-warning',
    method: 'post',
    params
  })
}
/**
 * 获取黑名单列表
 */
export function getBlacklist(params) {
  return request({
    url: '/platform/blacklist',
    method: 'get',
    params
  })
}

/**
 * 添加黑名单
 */
export function postBlacklist(params) {
  return request({
    url: '/platform/blacklist',
    method: 'post',
    params
  })
}

/**
 * 删除黑名单
 */
export function delBlacklist(params) {
  return request({
    url: '/platform/blacklist',
    method: 'delete',
    params
  })
}

/**
 * 消息通知
 */
export function notificationList(params) {
  return request({
    url: '/platform/warning/notification-list',
    method: 'get',
    params
  })
}

/**
 * 删除预警消息通知
 */
export function deleteNotification(params) {
  return request({
    url: '/platform/warning/delete-alert-message-notification',
    method: 'post',
    params
  })
}
