<template>
  <div>
    <div class="search-box" style="padding: 0 30px">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="姓名">
          <el-input v-model="formData.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="身份证">
          <el-input v-model="formData.idcardNo" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="formData.phoneNumber" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        height="500"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="multiple"
          type="selection"
          align="center"
          width="55"
        />
        <el-table-column
          prop="name"
          label="姓名"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="phoneNumber"
          width="120"
          label="手机号"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="idcardNo"
          width="180"
          label="身份证号"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="身份证人像面"
          align="center"
        >
          <template slot-scope="scope">
            <file-icon :url="scope.row.idcardPic" img img-style="width: 60px;max-height: 80px" />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="身份证国徽面"
        >
          <template slot-scope="scope">
            <file-icon :url="scope.row.idcardPicBack" img img-style="width: 60px;max-height: 80px" />
          </template>
        </el-table-column>
        <!--        <el-table-column-->
        <!--          label="手持身份证正面"-->
        <!--          align="center"-->
        <!--        >-->
        <!--          <template slot-scope="scope">-->
        <!--            <file-icon :url="scope.row.idcardHand" img img-style="width: 60px" />-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column-->
        <!--          label="手持身份证背面"-->
        <!--          align="center"-->
        <!--        >-->
        <!--          <template slot-scope="scope">-->
        <!--            <file-icon :url="scope.row.idcardBackHand" img img-style="width: 60px" />-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
          v-if="!multiple"
          label="操作"
          align="center"
          width="80"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="selectItem(scope.row)">选择</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
      <div v-if="multiple" class="u-text-right">
        <el-button @click="selectItem(false)">取消</el-button>
        <el-button type="primary" @click="selectItem">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkerList } from '@/api/individualBusiness'

export default {
  name: 'WorkerLookup',
  props: {
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      formData: {
        name: '',
        idcardNo: '',
        phoneNumber: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      },
      multipleSelection: []
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getWorkerList(Object.assign({}, this.formData, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    selectItem(type) {
      if (type === false) {
        this.$emit('on-select', [])
      } else {
        if (this.multiple) {
          this.$emit('on-select', [].concat(this.multipleSelection))
        } else {
          this.$emit('on-select', [].concat([type]))
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
