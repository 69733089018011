var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: { model: _vm.value, "label-width": "250px" },
        },
        [
          _c(
            "div",
            {
              staticClass: "coop-box u-m-l-60",
              class: _vm.value._totalCheck ? "" : "box-uncheck",
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.value._totalCheck,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "_totalCheck", $$v)
                    },
                    expression: "value._totalCheck",
                  },
                },
                [_vm._v("合作类型：总包+分包（6%专票）")]
              ),
              _c(
                "div",
                { staticClass: "u-flex u-col-top u-row-left u-flex-wrap" },
                [
                  _vm._l(_vm.value._totalTax, function (item, k) {
                    return _c(
                      "div",
                      {
                        key: item.guid,
                        staticClass: "u-m-t-30 u-p-r-20",
                        staticStyle: { width: "45%" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "u-border u-p-20",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "el-icon-remove-outline text-danger u-font-18 u-point",
                              staticStyle: {
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeTotalList(k)
                                },
                              },
                            }),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "平台服务商",
                                  required: "",
                                  "label-width": "100px",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "平台服务商",
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeTotalService(k, $event)
                                      },
                                    },
                                    model: {
                                      value: item.taxId,
                                      callback: function ($$v) {
                                        _vm.$set(item, "taxId", $$v)
                                      },
                                      expression: "item.taxId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.totalServiceList,
                                    function (serviceItem) {
                                      return _c("el-option", {
                                        key: serviceItem.id,
                                        attrs: {
                                          label: serviceItem.taxName,
                                          value: serviceItem.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.serviceTotalPackage[item.taxId]
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "综合税费率",
                                        required: "",
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.chargeStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "chargeStatus",
                                                $$v
                                              )
                                            },
                                            expression: "item.chargeStatus",
                                          },
                                        },
                                        [
                                          _vm.serviceTotalPackage[item.taxId]
                                            .taxPrice
                                            ? _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("一口价")]
                                              )
                                            : _vm._e(),
                                          _vm.serviceTotalPackage[item.taxId]
                                            .invoiceLadderPriceDetailVOList
                                            ? _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("梯度价")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  item.chargeStatus === 0
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "100px" } },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              key: item.taxId,
                                              attrs: { type: "number", min: 0 },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.checkItem(
                                                    item,
                                                    _vm.serviceTotalPackage[
                                                      item.taxId
                                                    ].taxPrice || 0,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.serviceCharge,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "serviceCharge",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "item.serviceCharge",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "u-tips" }, [
                                            _vm._v(
                                              "参考值(不低于)：" +
                                                _vm._s(
                                                  _vm.serviceTotalPackage[
                                                    item.taxId
                                                  ]
                                                    ? _vm.serviceTotalPackage[
                                                        item.taxId
                                                      ].taxPrice || 0
                                                    : 0
                                                ) +
                                                "%"
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "100px" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "u-flex u-col-center u-row-center u-m-t-10 u-text-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "u-flex-2" },
                                                [_vm._v("单人单月额度")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "u-flex-1" },
                                                [_vm._v("服务费率")]
                                              ),
                                              _c("div", {
                                                staticStyle: { width: "50px" },
                                              }),
                                            ]
                                          ),
                                          item.addCompanyLadderServiceDtoList
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  item.addCompanyLadderServiceDtoList,
                                                  function (i, j) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: i.guid,
                                                        staticClass: "u-m-t-10",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "u-flex u-col-center u-row-center",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "u-flex-2 u-flex u-col-center u-row-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    attrs: {
                                                                      readonly:
                                                                        "",
                                                                      placeholder:
                                                                        "请输入",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        i.startMoney,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            i,
                                                                            "startMoney",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "i.startMoney",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "40px",
                                                                          },
                                                                        attrs: {
                                                                          slot: "suffix",
                                                                        },
                                                                        slot: "suffix",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "元"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "u-p-lf-5",
                                                                  },
                                                                  [_vm._v("至")]
                                                                ),
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    attrs: {
                                                                      readonly:
                                                                        "",
                                                                      placeholder:
                                                                        "请输入",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        i.endMoney,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            i,
                                                                            "endMoney",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "i.endMoney",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "40px",
                                                                          },
                                                                        attrs: {
                                                                          slot: "suffix",
                                                                        },
                                                                        slot: "suffix",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "元"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "u-flex-1 u-p-l-10",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    attrs: {
                                                                      placeholder:
                                                                        "请输入",
                                                                    },
                                                                    on: {
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.checkItem(
                                                                          i,
                                                                          _vm
                                                                            .serviceTotalPackage[
                                                                            item
                                                                              .taxId
                                                                          ]
                                                                            .invoiceLadderPriceDetailVOList[
                                                                            j
                                                                          ]
                                                                            .rate ||
                                                                            0,
                                                                          $event
                                                                        )
                                                                      },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        i.serviceCharge,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            i,
                                                                            "serviceCharge",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "i.serviceCharge",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "40px",
                                                                          },
                                                                        attrs: {
                                                                          slot: "suffix",
                                                                        },
                                                                        slot: "suffix",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "%"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "u-tips u-text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "参考值(不低于)： " +
                                                                _vm._s(
                                                                  _vm
                                                                    .serviceTotalPackage[
                                                                    item.taxId
                                                                  ]
                                                                    .invoiceLadderPriceDetailVOList[
                                                                    j
                                                                  ].rate || 0
                                                                ) +
                                                                "% "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                ]
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "合作合同",
                                  required: "",
                                  "label-width": "100px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "u-flex u-col-center" },
                                  [
                                    _c("button-upload", {
                                      attrs: {
                                        type: "image/pdf",
                                        text: "上传 jpg/pdf",
                                      },
                                      model: {
                                        value: item.contract,
                                        callback: function ($$v) {
                                          _vm.$set(item, "contract", $$v)
                                        },
                                        expression: "item.contract",
                                      },
                                    }),
                                    !_vm.$u.test.isEmpty(item.contract)
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "u-m-l-10",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$preViewer(
                                                  item.contract
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("查看")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "u-point",
                      staticStyle: {
                        display: "inline-block",
                        "margin-top": "100px",
                      },
                      on: { click: _vm.addTotalList },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus-outline u-font-30",
                      }),
                      _c("div", { staticClass: "u-m-t-5" }, [_vm._v("添加")]),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "coop-box u-m-l-60 u-m-t-40",
              class: _vm.value._crowdCheck ? "" : "box-uncheck",
            },
            [
              _c(
                "el-checkbox",
                {
                  model: {
                    value: _vm.value._crowdCheck,
                    callback: function ($$v) {
                      _vm.$set(_vm.value, "_crowdCheck", $$v)
                    },
                    expression: "value._crowdCheck",
                  },
                },
                [_vm._v("合作类型：众包(3%普票)")]
              ),
              _c(
                "div",
                { staticClass: "u-flex u-col-top u-row-left u-flex-wrap" },
                [
                  _vm._l(_vm.value._crowdTax, function (item, k) {
                    return _c(
                      "div",
                      {
                        key: item.guid,
                        staticClass: "u-m-t-30 u-p-r-20",
                        staticStyle: { width: "45%" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "u-border u-p-20",
                            staticStyle: { position: "relative" },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "el-icon-remove-outline text-danger u-font-18 u-point",
                              staticStyle: {
                                position: "absolute",
                                right: "5px",
                                top: "5px",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.removeCrowdList(k)
                                },
                              },
                            }),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "平台服务商",
                                  required: "",
                                  "label-width": "100px",
                                },
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "平台服务商",
                                      clearable: "",
                                      filterable: "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.changeCrowdService(k, $event)
                                      },
                                    },
                                    model: {
                                      value: item.taxId,
                                      callback: function ($$v) {
                                        _vm.$set(item, "taxId", $$v)
                                      },
                                      expression: "item.taxId",
                                    },
                                  },
                                  _vm._l(
                                    _vm.crowdServiceList,
                                    function (serviceItem) {
                                      return _c("el-option", {
                                        key: serviceItem.id,
                                        attrs: {
                                          label: serviceItem.taxName,
                                          value: serviceItem.id,
                                        },
                                      })
                                    }
                                  ),
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.serviceCrowdPackage[item.taxId]
                              ? [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "综合税费率",
                                        required: "",
                                        "label-width": "100px",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-radio-group",
                                        {
                                          model: {
                                            value: item.chargeStatus,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                item,
                                                "chargeStatus",
                                                $$v
                                              )
                                            },
                                            expression: "item.chargeStatus",
                                          },
                                        },
                                        [
                                          _vm.serviceCrowdPackage[item.taxId]
                                            .taxPrice
                                            ? _c(
                                                "el-radio",
                                                { attrs: { label: 0 } },
                                                [_vm._v("一口价")]
                                              )
                                            : _vm._e(),
                                          _vm.serviceCrowdPackage[item.taxId]
                                            .invoiceLadderPriceDetailVOList
                                            ? _c(
                                                "el-radio",
                                                { attrs: { label: 1 } },
                                                [_vm._v("梯度价")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  item.chargeStatus === 0
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "100px" } },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                type: "number",
                                                min: "0",
                                              },
                                              on: {
                                                blur: function ($event) {
                                                  return _vm.checkItem(
                                                    item,
                                                    _vm.serviceCrowdPackage[
                                                      item.taxId
                                                    ].taxPrice || 0,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.serviceCharge,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "serviceCharge",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression:
                                                  "item.serviceCharge",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                          _c("div", { staticClass: "u-tips" }, [
                                            _vm._v(
                                              "参考值(不低于)：" +
                                                _vm._s(
                                                  _vm.serviceCrowdPackage[
                                                    item.taxId
                                                  ]
                                                    ? _vm.serviceCrowdPackage[
                                                        item.taxId
                                                      ].taxPrice || 0
                                                    : 0
                                                ) +
                                                "%"
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "el-form-item",
                                        { attrs: { "label-width": "100px" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "u-flex u-col-center u-row-center u-m-t-10 u-text-center",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "u-flex-2" },
                                                [_vm._v("单人单月额度")]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "u-flex-1" },
                                                [_vm._v("服务费率")]
                                              ),
                                              _c("div", {
                                                staticStyle: { width: "50px" },
                                              }),
                                            ]
                                          ),
                                          item.addCompanyLadderServiceDtoList
                                            ? _c(
                                                "div",
                                                _vm._l(
                                                  item.addCompanyLadderServiceDtoList,
                                                  function (i, j) {
                                                    return _c(
                                                      "div",
                                                      {
                                                        key: i.guid,
                                                        staticClass: "u-m-t-10",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "u-flex u-col-center u-row-center u-m-t-10",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "u-flex-2 u-flex u-col-center u-row-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    attrs: {
                                                                      readonly:
                                                                        "",
                                                                      placeholder:
                                                                        "请输入",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        i.startMoney,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            i,
                                                                            "startMoney",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "i.startMoney",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "40px",
                                                                          },
                                                                        attrs: {
                                                                          slot: "suffix",
                                                                        },
                                                                        slot: "suffix",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "元"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "u-p-lf-5",
                                                                  },
                                                                  [_vm._v("至")]
                                                                ),
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    attrs: {
                                                                      readonly:
                                                                        "",
                                                                      placeholder:
                                                                        "请输入",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        i.endMoney,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            i,
                                                                            "endMoney",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "i.endMoney",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "40px",
                                                                          },
                                                                        attrs: {
                                                                          slot: "suffix",
                                                                        },
                                                                        slot: "suffix",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "元"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "u-flex-1 u-p-l-10",
                                                              },
                                                              [
                                                                _c(
                                                                  "el-input",
                                                                  {
                                                                    attrs: {
                                                                      placeholder:
                                                                        "请输入",
                                                                    },
                                                                    on: {
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.checkItem(
                                                                          i,
                                                                          _vm
                                                                            .serviceCrowdPackage[
                                                                            item
                                                                              .taxId
                                                                          ]
                                                                            .invoiceLadderPriceDetailVOList[
                                                                            j
                                                                          ]
                                                                            .rate ||
                                                                            0,
                                                                          $event
                                                                        )
                                                                      },
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        i.serviceCharge,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            i,
                                                                            "serviceCharge",
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "i.serviceCharge",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "line-height":
                                                                              "40px",
                                                                          },
                                                                        attrs: {
                                                                          slot: "suffix",
                                                                        },
                                                                        slot: "suffix",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "%"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "u-tips u-text-right",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "参考值(不低于)： " +
                                                                _vm._s(
                                                                  _vm
                                                                    .serviceCrowdPackage[
                                                                    item.taxId
                                                                  ]
                                                                    .invoiceLadderPriceDetailVOList[
                                                                    j
                                                                  ].rate || 0
                                                                ) +
                                                                "% "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                ]
                              : _vm._e(),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "合作合同",
                                  required: "",
                                  "label-width": "100px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "u-flex u-col-center" },
                                  [
                                    _c("button-upload", {
                                      attrs: {
                                        type: "image/pdf",
                                        text: "上传 jpg/pdf",
                                      },
                                      model: {
                                        value: item.contract,
                                        callback: function ($$v) {
                                          _vm.$set(item, "contract", $$v)
                                        },
                                        expression: "item.contract",
                                      },
                                    }),
                                    !_vm.$u.test.isEmpty(item.contract)
                                      ? _c(
                                          "el-button",
                                          {
                                            staticClass: "u-m-l-10",
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$preViewer(
                                                  item.contract
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("查看")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    )
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "u-point",
                      staticStyle: {
                        display: "inline-block",
                        "margin-top": "100px",
                      },
                      on: { click: _vm.addCrowdList },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-circle-plus-outline u-font-30",
                      }),
                      _c("div", { staticClass: "u-m-t-5" }, [_vm._v("添加")]),
                    ]
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }