<template>
  <div>
    <el-form ref="unionPayFormData" v-loading="loading" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
      <el-form-item label="银行信息" prop="unionpayBankType">
        <el-select v-model="formData.unionpayBankType" filterable placeholder="请选择" @change="changeBank($event)">
          <el-option v-for="(i, k) in $enum.unionpayBankType()" :key="k" :label="i.name" :value="i.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="开户行" prop="silverBankName">
        <el-input v-model="formData.silverBankName" placeholder="请输入" />
      </el-form-item>
      <template v-if="!isEditZs">
        <el-form-item :required="!formData.taxUnionpayId" :show-message="false" label="平台公钥" prop="pfmpubkey">
          <el-input v-model="formData.pfmpubkey" placeholder="请输入" />
        </el-form-item>
        <el-form-item :required="!formData.taxUnionpayId" :show-message="false" label="合作方私钥" prop="prikey">
          <el-input v-model="formData.prikey" placeholder="请输入" />
        </el-form-item>
      </template>
      <el-form-item label="平台帐户账号" prop="acctno">
        <el-input v-model="formData.acctno" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="回单信息ip">
        <el-input v-model="formData.receiptInfoIp" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="回单信息端口">
        <el-input v-model="formData.receiptInfoPort" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="回单信息账户">
        <el-input v-model="formData.receiptInfoAccount" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="回单信息密码">
        <el-input v-model="formData.receiptInfoPwd" placeholder="请输入" />
      </el-form-item>
      <template v-if="formData.unionpayBankType === 'SJBK'">
        <el-form-item label="清分子账户" prop="clearNo">
          <el-input v-model="formData.clearNo" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="手续费子账户" prop="serviceChargeNo">
          <el-input v-model="formData.serviceChargeNo" placeholder="请输入" />
        </el-form-item>
      </template>

      <template v-if="formData.unionpayBankType === 'ZSBK'">
        <el-form-item label="分行号" prop="bbknbr">
          <!-- <el-input v-model="formData.bbknbr" placeholder="请输入" /> -->
          <el-select v-model="formData.bbknbr" filterable placeholder="请选择">
            <el-option v-for="(i, k) in bbknbrAry" :key="k" :label="i.label" :value="i.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="开户行" prop="bankDeposit">
          <el-input v-model="formData.bankDeposit" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="户名" prop="accountName">
          <el-input v-model="formData.accountName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="aes加密串" prop="aesKey">
          <el-input v-model="formData.aesKey" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="用户id" prop="userid">
          <el-input v-model="formData.userid" placeholder="请输入" />
        </el-form-item>
        <el-form-item v-if="isEditZs" label="服务商银联ID" prop="taxZspayId">
          <el-input v-model="formData.taxZspayId" placeholder="请输入" />
        </el-form-item>
      </template>
      <template v-else>
        <!-- 如果不是招商银行，就显示商户号 -->
        <el-form-item label="商户号" prop="merchno">
          <el-input v-model="formData.merchno" placeholder="请输入" />
        </el-form-item>
      </template>

      <el-form-item>
        <el-button @click="resetForm">清空</el-button>
        <el-button type="primary" @click="submitForm('unionPayFormData')">确定</el-button>
      </el-form-item>
    </el-form>

    <div v-if="formData.unionpayBankType === 'ZSBK'">
      <el-table v-loading="tableLoading" :data="list" tooltip-effect="dark" max-height="350px" style="width: 100%">
        <el-table-column label="平台帐户账号" align="center">
          <template slot-scope="scope">
            {{ scope.row.acctno }}
          </template>
        </el-table-column>
        <el-table-column prop="aesKey" label="aes加密串" align="center" />
        <el-table-column prop="userid" label="用户id" align="center" />
        <el-table-column prop="id" label="服务商银联ID" align="center" />
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.boolEnable ? 0 : 1, $enum.authStatus(), true)" />
          </template>
        </el-table-column>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleToggleZs(scope.row)">{{ scope.row.boolEnable ? '停用' : '启用' }}</el-button>
            <el-button type="text" size="small" @click="handleEditZs(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <el-table v-loading="tableLoading" :data="list" tooltip-effect="dark" max-height="350px" style="width: 100%">
        <el-table-column label="银行类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.unionpayBankType | unionpayBankType }}
          </template>
        </el-table-column>
        <el-table-column prop="merchno" label="商户号" align="center" />
        <el-table-column label="平台帐户账号" align="center">
          <template slot-scope="scope">
            {{ scope.row.acctno }}
          </template>
        </el-table-column>
        <el-table-column prop="clearNo" label="清分子账户" align="center" />
        <el-table-column prop="serviceChargeNo" label="手续费子账户" align="center" />
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.boolEnable ? 0 : 1, $enum.authStatus(), true)" />
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center" />
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleToggle(scope.row)">{{ scope.row.boolEnable ? '停用' : '启用' }}</el-button>
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import {
  getUnionPayList,
  updateUnionPay,
  toggleUnionPayStatus,
  saveTaxZSBalance,
  queryTaxZS,
  updateBoolEnable,
} from "@/api/service";
import { getBbknBr } from "@/utils/enum.js";

const _defaultForm = () => {
  return {
    acctno: "",
    clearNo: "",
    merchno: "",
    silverBankName: "",
    pfmpubkey: "",
    prikey: "",
    serviceChargeNo: "",
    taxId: "",
    taxUnionpayId: undefined,
    unionpayBankType: "",
    receiptInfoIp: "",
    receiptInfoPort: "",
    receiptInfoAccount: "",
    receiptInfoPwd: "",
  };
};
export default {
  name: "UnionPayEdit",
  props: {
    taxId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      isEditZs: false,
      formData: Object.assign({}, _defaultForm()),
      loading: false,
      rules: {
        unionpayBankType: [
          { required: true, message: "请选择银行类型", trigger: "change" },
        ],
        silverBankName: [
          { required: true, message: "请输入开户行", trigger: "change" },
        ],
        merchno: [{ required: true, message: "请输入商户号", trigger: "blur" }],
        acctno: [
          { required: true, message: "请输入平台帐户账号", trigger: "blur" },
        ],
        bbknbr: [{ required: true, message: "请输入分行号", trigger: "blur" }],
        clearNo: [
          { required: true, message: "请输入清分子账户", trigger: "blur" },
        ],
        aesKey: [
          { required: true, message: "请输入aes加密串", trigger: "blur" },
        ],
        accountName: [
          { required: true, message: "请输入户名", trigger: "blur" },
        ],
        bankDeposit: [
          { required: true, message: "请输入开户行", trigger: "blur" },
        ],
        userid: [{ required: true, message: "请输入用户id", trigger: "blur" }],
        taxZspayId: [
          { required: true, message: "服务商银联ID", trigger: "blur" },
        ],
        serviceChargeNo: [
          { required: true, message: "请输入手续费子账户", trigger: "blur" },
        ],
      },
      tableLoading: false,
      bbknbrAry: getBbknBr(),
      list: [],
    };
  },
  created() {
    if (this.$enum.unionpayBankType()[0]) {
      this.formData.unionpayBankType = this.$enum.unionpayBankType()[0].value;
    }
    this.fetchData(this.formData.unionpayBankType);
  },
  methods: {
    resetForm() {
      this.isEditZs = false;
      this.$refs.unionPayFormData.resetFields();
      // this.formData = Object.assign({}, _defaultForm())
    },
    changeBank(val) {
      this.fetchData(val);
    },
    fetchData(type) {
      this.tableLoading = true;
      if (type === "ZSBK") {
        queryTaxZS({
          taxId: this.taxId,
        })
          .then((res) => {
            if (res.obj) {
              this.list = [res.obj];
            } else {
              this.list = res.data || [];
            }
            this.tableLoading = false;
          })
          .catch((res) => {
            this.tableLoading = false;
          });
      } else {
        getUnionPayList({
          taxId: this.taxId,
        })
          .then((res) => {
            this.list = res.data || [];
            this.tableLoading = false;
          })
          .catch((res) => {
            this.tableLoading = false;
          });
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData, {
            taxId: this.taxId,
          });
          if (!form.taxUnionpayId) {
            if (this.$u.test.isEmpty(form.pfmpubkey)) {
              return this.$message.error("请输入平台公钥");
            }
            if (this.$u.test.isEmpty(form.prikey)) {
              return this.$message.error("请输入合作方私钥");
            }
          }
          this.isEditZs = false;
          this.loading = true;
          if (this.formData.unionpayBankType === "ZSBK") {
            saveTaxZSBalance(form)
              .then((res) => {
                this.loading = false;
                this.formData = Object.assign({}, _defaultForm(), {
                  unionpayBankType: this.formData.unionpayBankType,
                });
                this.$message.success(res.message);
                this.fetchData("ZSBK");
              })
              .catch((res) => {
                this.loading = false;
              });
          } else {
            updateUnionPay(form)
              .then((res) => {
                this.loading = false;
                this.formData = Object.assign({}, _defaultForm(), {
                  unionpayBankType: this.formData.unionpayBankType,
                });
                this.$message.success(res.message);
                this.fetchData();
              })
              .catch((res) => {
                this.loading = false;
              });
          }
        } else {
          return false;
        }
      });
    },
    handleToggle(i) {
      const status = i.boolEnable;
      const text = i.boolEnable ? "停用" : "启用";
      this.$confirm("是否" + text + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableLoading = true;
          toggleUnionPayStatus({
            taxUnionpayId: i.id,
            boolEnable: !status,
          })
            .then((res) => {
              this.$message.success(text + "成功");
              this.fetchData();
            })
            .catch(() => {
              this.tableLoading = false;
            });
        })
        .catch(() => {});
    },
    handleEdit(i) {
      this.formData = Object.assign({}, i, {
        id: i.id,
        taxUnionpayId: i.id,
      });
    },
    handleToggleZs(i) {
      // const status = i.boolEnable
      const text = i.boolEnable ? "停用" : "启用";
      this.$confirm("是否" + text + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableLoading = true;
          updateBoolEnable({
            taxZspayId: i.id,
            // boolEnable: !status
          })
            .then((res) => {
              this.$message.success(text + "成功");
              i.boolEnable = !i.boolEnable;
              this.fetchData(this.formData.unionpayBankType);
            })
            .catch(() => {
              this.tableLoading = false;
            });
        })
        .catch(() => {});
    },
    handleEditZs(i) {
      this.isEditZs = true;
      this.formData = Object.assign(this.formData, i, {
        taxZspayId: i.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.u-form {
  font-size: 14px;
  ::v-deep {
    .el-form-item__content {
      max-width: 480px;
    }
  }
}
.box-uncheck {
  opacity: 0.3;
}
</style>
