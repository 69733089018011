var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "formData",
          attrs: { model: _vm.value, "label-width": "250px" },
        },
        [
          _c(
            "div",
            {
              class: _vm.value.settlementMethodCalc.waterCommission
                ? ""
                : "box-uncheck",
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.value.settlementMethodCalc.waterCommission,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.value.settlementMethodCalc,
                            "waterCommission",
                            $$v
                          )
                        },
                        expression:
                          "value.settlementMethodCalc.waterCommission",
                      },
                    },
                    [_vm._v("按流水提成（月度）")]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "u-border u-m-t-10 u-p-20",
                  staticStyle: { position: "relative" },
                },
                [
                  _c("el-row", [
                    _c("div", { staticClass: "u-m-t-20 u-p-r-20" }, [
                      _vm._v("直属商户"),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "u-m-t-10 u-p-r-20", attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { md: 12, sm: 24 } }, [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "text-align": "center" },
                            },
                            [_vm._v("总包+分包（6%专票）")]
                          ),
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-flex u-col-center u-row-center u-m-t-10 u-text-center",
                                },
                                [
                                  _c("div", { staticClass: "u-flex-1" }, [
                                    _vm._v("月度"),
                                  ]),
                                  _c("div", { staticClass: "u-flex-1" }, [
                                    _vm._v("提成率"),
                                  ]),
                                  _c("div", { staticStyle: { width: "50px" } }),
                                ]
                              ),
                              _vm._l(
                                _vm.value.directCommissionProportionStatus0,
                                function (i, k) {
                                  return _c(
                                    "div",
                                    {
                                      key: i.guid || i.id,
                                      staticClass:
                                        "u-flex u-col-center u-row-center u-m-t-10",
                                      class:
                                        _vm.errorIndex.direct === k
                                          ? "error-line"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "u-flex-2 u-flex u-col-center u-row-center",
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                readonly: "",
                                                placeholder: "请输入",
                                              },
                                              model: {
                                                value: i.startMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "startMoney", $$v)
                                                },
                                                expression: "i.startMoney",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "u-p-lf-5" },
                                            [_vm._v("至")]
                                          ),
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { placeholder: "请输入" },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.changeEnd(
                                                    "directCommissionProportionStatus0",
                                                    k,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: i.endMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "endMoney", $$v)
                                                },
                                                expression: "i.endMoney",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "u-flex-1 u-p-l-10" },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { placeholder: "请输入" },
                                              model: {
                                                value: i.commissionRate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    i,
                                                    "commissionRate",
                                                    $$v
                                                  )
                                                },
                                                expression: "i.commissionRate",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "u-p-l-5",
                                          staticStyle: { width: "50px" },
                                        },
                                        [
                                          k ===
                                          _vm.value
                                            .directCommissionProportionStatus0
                                            .length -
                                            1
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-remove-outline text-danger u-font-18 u-point",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeList(
                                                      "directCommissionProportionStatus0",
                                                      k
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-m-t-20 u-text-center u-flex u-col-center u-point",
                                  staticStyle: { width: "100px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addList(
                                        "directCommissionProportionStatus0",
                                        { packageStatus: 0 }
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus-outline u-font-24",
                                  }),
                                  _vm._v("添加 "),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { md: 12, sm: 24 } }, [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "text-align": "center" },
                            },
                            [_vm._v("众包(3%普票)")]
                          ),
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-flex u-col-center u-row-center u-m-t-10 u-text-center",
                                },
                                [
                                  _c("div", { staticClass: "u-flex-1" }, [
                                    _vm._v("月度"),
                                  ]),
                                  _c("div", { staticClass: "u-flex-1" }, [
                                    _vm._v("提成率"),
                                  ]),
                                  _c("div", { staticStyle: { width: "50px" } }),
                                ]
                              ),
                              _vm._l(
                                _vm.value.directCommissionProportionStatus1,
                                function (i, k) {
                                  return _c(
                                    "div",
                                    {
                                      key: i.guid || i.id,
                                      staticClass:
                                        "u-flex u-col-center u-row-center u-m-t-10",
                                      class:
                                        _vm.errorIndex.agent === k
                                          ? "error-line"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "u-flex-2 u-flex u-col-center u-row-center",
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                readonly: "",
                                                placeholder: "请输入",
                                              },
                                              model: {
                                                value: i.startMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "startMoney", $$v)
                                                },
                                                expression: "i.startMoney",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "u-p-lf-5" },
                                            [_vm._v("至")]
                                          ),
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { placeholder: "请输入" },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.changeEnd(
                                                    "directCommissionProportionStatus1",
                                                    k,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: i.endMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "endMoney", $$v)
                                                },
                                                expression: "i.endMoney",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "u-flex-1 u-p-l-10" },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { placeholder: "请输入" },
                                              model: {
                                                value: i.commissionRate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    i,
                                                    "commissionRate",
                                                    $$v
                                                  )
                                                },
                                                expression: "i.commissionRate",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "u-p-l-5",
                                          staticStyle: { width: "50px" },
                                        },
                                        [
                                          k ===
                                          _vm.value
                                            .directCommissionProportionStatus1
                                            .length -
                                            1
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-remove-outline text-danger u-font-18 u-point",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeList(
                                                      "directCommissionProportionStatus1",
                                                      k
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-m-t-20 u-text-center u-flex u-col-center u-point",
                                  staticStyle: { width: "100px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addList(
                                        "directCommissionProportionStatus1",
                                        { packageStatus: 1 }
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus-outline u-font-24",
                                  }),
                                  _vm._v("添加 "),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("el-row", [
                    _c("div", { staticClass: "u-m-t-30 u-p-r-20" }, [
                      _vm._v("下属代理商"),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "u-m-t-10 u-p-r-20", attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { md: 12, sm: 24 } }, [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "text-align": "center" },
                            },
                            [_vm._v("总包+分包（6%专票）")]
                          ),
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-flex u-col-center u-row-center u-m-t-10 u-text-center",
                                },
                                [
                                  _c("div", { staticClass: "u-flex-1" }, [
                                    _vm._v("月度"),
                                  ]),
                                  _c("div", { staticClass: "u-flex-1" }, [
                                    _vm._v("提成率"),
                                  ]),
                                  _c("div", { staticStyle: { width: "50px" } }),
                                ]
                              ),
                              _vm._l(
                                _vm.value.agentCommissionProportionStatus0,
                                function (i, k) {
                                  return _c(
                                    "div",
                                    {
                                      key: i.guid || i.id,
                                      staticClass:
                                        "u-flex u-col-center u-row-center u-m-t-10",
                                      class:
                                        _vm.errorIndex.direct === k
                                          ? "error-line"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "u-flex-2 u-flex u-col-center u-row-center",
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                readonly: "",
                                                placeholder: "请输入",
                                              },
                                              model: {
                                                value: i.startMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "startMoney", $$v)
                                                },
                                                expression: "i.startMoney",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "u-p-lf-5" },
                                            [_vm._v("至")]
                                          ),
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { placeholder: "请输入" },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.changeEnd(
                                                    "agentCommissionProportionStatus0",
                                                    k,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: i.endMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "endMoney", $$v)
                                                },
                                                expression: "i.endMoney",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "u-flex-1 u-p-l-10" },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { placeholder: "请输入" },
                                              model: {
                                                value: i.commissionRate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    i,
                                                    "commissionRate",
                                                    $$v
                                                  )
                                                },
                                                expression: "i.commissionRate",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "u-p-l-5",
                                          staticStyle: { width: "50px" },
                                        },
                                        [
                                          k ===
                                          _vm.value
                                            .agentCommissionProportionStatus0
                                            .length -
                                            1
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-remove-outline text-danger u-font-18 u-point",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeList(
                                                      "agentCommissionProportionStatus0",
                                                      k
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-m-t-20 u-text-center u-flex u-col-center u-point",
                                  staticStyle: { width: "100px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addList(
                                        "agentCommissionProportionStatus0",
                                        { packageStatus: 0 }
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus-outline u-font-24",
                                  }),
                                  _vm._v("添加 "),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                      _c("el-col", { attrs: { md: 12, sm: 24 } }, [
                        _c("div", { staticClass: "box" }, [
                          _c(
                            "div",
                            {
                              staticClass: "title",
                              staticStyle: { "text-align": "center" },
                            },
                            [_vm._v("众包(3%普票)")]
                          ),
                          _c(
                            "div",
                            { staticClass: "content" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-flex u-col-center u-row-center u-m-t-10 u-text-center",
                                },
                                [
                                  _c("div", { staticClass: "u-flex-1" }, [
                                    _vm._v("月度"),
                                  ]),
                                  _c("div", { staticClass: "u-flex-1" }, [
                                    _vm._v("提成率"),
                                  ]),
                                  _c("div", { staticStyle: { width: "50px" } }),
                                ]
                              ),
                              _vm._l(
                                _vm.value.agentCommissionProportionStatus1,
                                function (i, k) {
                                  return _c(
                                    "div",
                                    {
                                      key: i.guid || i.id,
                                      staticClass:
                                        "u-flex u-col-center u-row-center u-m-t-10",
                                      class:
                                        _vm.errorIndex.agent === k
                                          ? "error-line"
                                          : "",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "u-flex-2 u-flex u-col-center u-row-center",
                                        },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: {
                                                readonly: "",
                                                placeholder: "请输入",
                                              },
                                              model: {
                                                value: i.startMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "startMoney", $$v)
                                                },
                                                expression: "i.startMoney",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "u-p-lf-5" },
                                            [_vm._v("至")]
                                          ),
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { placeholder: "请输入" },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.changeEnd(
                                                    "agentCommissionProportionStatus1",
                                                    k,
                                                    $event
                                                  )
                                                },
                                              },
                                              model: {
                                                value: i.endMoney,
                                                callback: function ($$v) {
                                                  _vm.$set(i, "endMoney", $$v)
                                                },
                                                expression: "i.endMoney",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("元")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "u-flex-1 u-p-l-10" },
                                        [
                                          _c(
                                            "el-input",
                                            {
                                              attrs: { placeholder: "请输入" },
                                              model: {
                                                value: i.commissionRate,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    i,
                                                    "commissionRate",
                                                    $$v
                                                  )
                                                },
                                                expression: "i.commissionRate",
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "line-height": "40px",
                                                  },
                                                  attrs: { slot: "suffix" },
                                                  slot: "suffix",
                                                },
                                                [_vm._v("%")]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "u-p-l-5",
                                          staticStyle: { width: "50px" },
                                        },
                                        [
                                          k ===
                                          _vm.value
                                            .agentCommissionProportionStatus1
                                            .length -
                                            1
                                            ? _c("i", {
                                                staticClass:
                                                  "el-icon-remove-outline text-danger u-font-18 u-point",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeList(
                                                      "agentCommissionProportionStatus1",
                                                      k
                                                    )
                                                  },
                                                },
                                              })
                                            : _vm._e(),
                                        ]
                                      ),
                                    ]
                                  )
                                }
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "u-m-t-20 u-text-center u-flex u-col-center u-point",
                                  staticStyle: { width: "100px" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addList(
                                        "agentCommissionProportionStatus1",
                                        { packageStatus: 1 }
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass:
                                      "el-icon-circle-plus-outline u-font-24",
                                  }),
                                  _vm._v("添加 "),
                                ]
                              ),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }