var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      _c("el-card", [
        _c("div", { staticClass: "card--title" }, [_vm._v("支付信息")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c(
              "el-row",
              { staticClass: "card--info", attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { md: 12 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("支付编号：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderList.id)),
                  ]),
                ]),
              ],
              1
            ),
            _c(
              "el-row",
              { staticClass: "card--info", attrs: { gutter: 20 } },
              [
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("商户：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderList.companySName)),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [
                    _vm._v("平台服务商："),
                  ]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(_vm.paymentOrderList.platformServiceProvider)
                    ),
                  ]),
                ]),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("支付清单："),
                    ]),
                    _vm._l(
                      _vm.paymentOrderList.paymentInventoryList,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openFile(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("关联的任务："),
                    ]),
                    _vm._l(
                      _vm.paymentOrderList.taskVoList,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openTaskVo(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("交付支付验收单："),
                    ]),
                    _vm._l(
                      _vm.paymentOrderList.acceptanceCertificateList,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openFile(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c(
                  "el-col",
                  { attrs: { md: 8 } },
                  [
                    _c("span", { staticClass: "label" }, [
                      _vm._v("总包支付回单："),
                    ]),
                    _vm._l(
                      _vm.paymentOrderList.subpackagePaymentList,
                      function (item, index) {
                        return _c(
                          "span",
                          {
                            key: index,
                            staticClass: "pointer",
                            on: {
                              click: function ($event) {
                                return _vm.openFile(item)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        )
                      }
                    ),
                  ],
                  2
                ),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("状态：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("payTotalAndSubStatus")(
                          _vm.paymentOrderList.paymentOrderStatus
                        )
                      )
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("支付时间：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.paymentOrderList.paymentDate)),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("开票信息")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c("worker-pay-list", { ref: "payList", attrs: { type: 0 } }),
            _c("div", { staticClass: "table-wrap" }, [
              _c("ul", { staticClass: "table" }, [
                _c("li", { staticClass: "u-flex" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", { staticClass: "txt" }, [_vm._v("购买方")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _vm._v("名称：" + _vm._s(_vm.BuyerInfo.companyName)),
                    ]),
                    _c("p", [
                      _vm._v(
                        "纳税人识别号：" + _vm._s(_vm.BuyerInfo.creditCode)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址、电话：" +
                          _vm._s(_vm.BuyerInfo.addressAndTelephone)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "开户行及账号：" + _vm._s(_vm.BuyerInfo.bankAndAccount)
                      ),
                    ]),
                  ]),
                ]),
                _c("li", { staticClass: "count" }, [
                  _vm._v(
                    "价税合计：" + _vm._s(_vm.$u.formatMoney(_vm.totalMoney))
                  ),
                ]),
                _c("li", { staticClass: "u-flex" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", { staticClass: "txt" }, [_vm._v("销售方")]),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c("p", [
                      _vm._v(
                        "名称：" + _vm._s(_vm.sellerInfo.invoiceEnterpriseName)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "纳税人识别号：" + _vm._s(_vm.sellerInfo.invoiceTaxNo)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "地址、电话：" +
                          _vm._s(_vm.sellerInfo.invoiceAddressPhone)
                      ),
                    ]),
                    _c("p", [
                      _vm._v(
                        "开户行及账号：" +
                          _vm._s(_vm.sellerInfo.invoiceBankNameAccount)
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _c(
              "el-row",
              {
                staticClass: "card--info",
                staticStyle: { "margin-top": "20px" },
                attrs: { gutter: 20 },
              },
              [
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("票种：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.invoiceApplicationVo.invoiceForm == 1
                          ? "普通发票"
                          : "增值税专用发票"
                      )
                    ),
                  ]),
                ]),
                _c("el-col", { attrs: { md: 8 } }, [
                  _c("span", { staticClass: "label" }, [_vm._v("发票类型：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      _vm._s(
                        _vm.invoiceApplicationVo.invoiceType == 1
                          ? "电子发票"
                          : "纸质发票"
                      )
                    ),
                  ]),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.invoiceApplicationVo.invoiceType != 1
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("收件信息")]),
            _c(
              "div",
              {
                staticClass: "card--content",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c(
                  "el-row",
                  { staticClass: "card--info", attrs: { gutter: 20 } },
                  [
                    _c("el-col", { attrs: { md: 8 } }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("收件人："),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm.sendAndReceiveVo.linkName)),
                      ]),
                    ]),
                    _c("el-col", { attrs: { md: 8 } }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("联系电话："),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm.sendAndReceiveVo.linkMobile)),
                      ]),
                    ]),
                    _c("el-col", { attrs: { md: 8 } }, [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("详细地址："),
                      ]),
                      _c("span", { staticClass: "value" }, [
                        _vm._v(_vm._s(_vm.sendAndReceiveVo.addressName)),
                      ]),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("发票备注")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c("div", [
              _c("span", { staticClass: "label" }, [_vm._v("内容：")]),
              _c("span", { staticClass: "value" }, [
                _vm._v(_vm._s(_vm.suibmitFome.applicationDesc)),
              ]),
            ]),
          ]
        ),
      ]),
      _c("el-card", { staticClass: "u-m-t-20" }, [
        _c("div", { staticClass: "card--title" }, [_vm._v("开票类目")]),
        _c(
          "div",
          {
            staticClass: "card--content",
            staticStyle: { "margin-left": "20px" },
          },
          [
            _c("ul", { staticClass: "invoiceCatalogVo-wrap" }, [
              _c("li", { staticClass: "item flex align-items" }, [
                _c("span", [_vm._v("服务类型：")]),
                _c("span", [_vm._v(_vm._s(_vm.invoiceCatalogVo.serviceType))]),
              ]),
              _c("li", { staticClass: "item flex align-items" }, [
                _c("span", [_vm._v("具体服务内容：")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.invoiceCatalogVo.serviceContent)),
                ]),
              ]),
              _c("li", { staticClass: "item flex align-items" }, [
                _c("span", [_vm._v("开票类目：")]),
                _c("span", [
                  _vm._v(_vm._s(_vm.invoiceCatalogVo.billingCategory)),
                ]),
              ]),
            ]),
          ]
        ),
      ]),
      _vm.type === "examine"
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("发票信息")]),
            _c(
              "div",
              {
                staticClass: "card--content",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c("ul", { staticClass: "invoiceCatalogVo-wrap" }, [
                  _c(
                    "li",
                    { staticClass: "item flex align-items" },
                    [
                      _c("span", [_vm._v("发票文件：")]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.downFiles },
                        },
                        [_vm._v("下载")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.type != "examine"
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c(
              "div",
              {
                staticClass: "card--content",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c(
                  "el-form",
                  {
                    staticClass: "demo-form-inline",
                    attrs: {
                      "label-position": "right",
                      "label-width": "100px",
                    },
                  },
                  [
                    _vm.type == "add" || _vm.type == "editor"
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "发票：", required: "" } },
                          [
                            _c(
                              "div",
                              { staticClass: "u-flex u-col-center" },
                              [
                                _c("button-upload", {
                                  attrs: {
                                    "custom-type": ["pdf", "ofd"],
                                    text: "上传发票(pdf/ofd)格式文件",
                                  },
                                  on: {
                                    "upload-success": function ($event) {
                                      return _vm.setField("invoiceUrl", $event)
                                    },
                                  },
                                }),
                                !_vm.$u.test.isEmpty(_vm.formData.invoiceUrl)
                                  ? _c(
                                      "el-button",
                                      {
                                        staticClass: "u-m-l-10",
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$preViewer(
                                              _vm.formData.invoiceUrl
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("查看")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    (_vm.type == "add" || _vm.type == "editor") &&
                    _vm.invoiceApplicationVo.invoiceType != 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "物流公司：" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                },
                                model: {
                                  value: _vm.formData.expressCompanyName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.formData,
                                      "expressCompanyName",
                                      $$v
                                    )
                                  },
                                  expression: "formData.expressCompanyName",
                                },
                              },
                              _vm._l(_vm.expressOptions, function (i, k) {
                                return _c("el-option", {
                                  key: k,
                                  attrs: { label: i, value: i },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    (_vm.type == "add" || _vm.type == "editor") &&
                    _vm.invoiceApplicationVo.invoiceType != 1
                      ? _c(
                          "el-form-item",
                          { attrs: { label: "运单号：" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "300px" },
                              attrs: {
                                disabled: _vm.type === "examine",
                                placeholder: "请输入运单号",
                              },
                              model: {
                                value: _vm.formData.expressSheetNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "expressSheetNo", $$v)
                                },
                                expression: "formData.expressSheetNo",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.type === "add" || _vm.type === "editor"
                  ? _c(
                      "div",
                      {
                        staticClass: "flex justify-start align-items",
                        staticStyle: { height: "100px", "margin-left": "40px" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", loading: _vm.loading },
                            on: { click: _vm.submit },
                          },
                          [_vm._v("提交")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.type == "examine" && _vm.invoiceApplicationVo.invoiceType != 1
        ? _c("el-card", { staticClass: "u-m-t-20" }, [
            _c("div", { staticClass: "card--title" }, [_vm._v("物流信息")]),
            _c(
              "div",
              {
                staticClass: "card--content",
                staticStyle: { "margin-left": "20px" },
              },
              [
                _c("div", { staticClass: "express-info" }, [
                  _c("ul", [
                    _c("li", [
                      _vm._v(
                        "物流公司：" +
                          _vm._s(_vm.sendAndReceiveVo.logisticsCompany)
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        "物流单号：" +
                          _vm._s(_vm.sendAndReceiveVo.logisticsOrderNo)
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        " 收件信息：" +
                          _vm._s(_vm.sendAndReceiveVo.addressee) +
                          " " +
                          _vm._s(_vm.sendAndReceiveVo.addresseeTelephone) +
                          " " +
                          _vm._s(_vm.sendAndReceiveVo.toAddress) +
                          " "
                      ),
                    ]),
                    _c("li", [
                      _vm._v(
                        " 发件信息：" +
                          _vm._s(_vm.sendAndReceiveVo.from) +
                          " " +
                          _vm._s(_vm.sendAndReceiveVo.fromTelephone) +
                          " " +
                          _vm._s(_vm.sendAndReceiveVo.sendingAddress) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "logistics-info" },
                  _vm._l(_vm.expressLogisticsInfoList, function (item, index) {
                    return _c(
                      "el-row",
                      {
                        key: index,
                        staticStyle: { "margin-bottom": "15px" },
                        attrs: { gutter: 10 },
                      },
                      [
                        _c("el-col", { attrs: { md: 6 } }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(_vm._s(item.acceptTime)),
                          ]),
                        ]),
                        _c("el-col", { attrs: { md: 18 } }, [
                          _c("span", { staticClass: "label" }, [
                            _vm._v(_vm._s(item.acceptStation)),
                          ]),
                        ]),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "关联的任务", visible: _vm.showTaskVo },
          on: {
            "update:visible": function ($event) {
              _vm.showTaskVo = $event
            },
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("任务编号：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.taskCode)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("任务名称：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.taskName)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("任务类型：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.industryType)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("开始时间：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.releaseDate)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { staticStyle: { "margin-bottom": "15px" }, attrs: { md: 8 } },
                [
                  _c("span", { staticClass: "label" }, [_vm._v("结束时间：")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(_vm._s(_vm.taskInfo.deadlineDate)),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showTaskVo = false
                    },
                  },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }