var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-m-25 u-p-30 bg-white" },
    [
      _c(
        "el-form",
        {
          ref: "formData",
          staticStyle: { "max-width": "800px" },
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "250px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "个体户名称", prop: "candidatedNames" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入名称（首选）" },
                model: {
                  value: _vm.formData.companyName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "companyName", $$v)
                  },
                  expression: "formData.companyName",
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.otherName, function (i, k) {
            return _c(
              "el-form-item",
              { key: i.key },
              [
                _c(
                  "el-input",
                  {
                    attrs: { placeholder: "请输入名称（备选）" },
                    model: {
                      value: i.value,
                      callback: function ($$v) {
                        _vm.$set(i, "value", $$v)
                      },
                      expression: "i.value",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "el-input__icon el-icon-delete",
                      attrs: { slot: "suffix" },
                      on: {
                        click: function ($event) {
                          return _vm.delOtherName(k)
                        },
                      },
                      slot: "suffix",
                    }),
                  ]
                ),
              ],
              1
            )
          }),
          _c("el-form-item", [
            _c(
              "div",
              {
                staticClass: "u-dashed-border u-text-center u-point",
                on: {
                  click: function ($event) {
                    return _vm.addOtherName()
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-plus u-m-r-10" }),
                _vm._v("添加名称"),
              ]
            ),
          ]),
          _c(
            "el-form-item",
            {
              attrs: { label: "行业类型", prop: "mainIndustry", required: "" },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "" },
                  on: { change: _vm.changeIndustry },
                  model: {
                    value: _vm.formData.mainIndustry,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "mainIndustry", $$v)
                    },
                    expression: "formData.mainIndustry",
                  },
                },
                _vm._l(_vm.mainIndustryList, function (i, k) {
                  return _c("el-option", {
                    key: k,
                    attrs: { value: i.value, label: i.name },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "经营范围" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入经营范围", type: "textarea" },
                model: {
                  value: _vm.formData.bizScope,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "bizScope", $$v)
                  },
                  expression: "formData.bizScope",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "核定税种", required: "", prop: "bizType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: "请选择核定税种" },
                  model: {
                    value: _vm.formData.bizType,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "bizType", $$v)
                    },
                    expression: "formData.bizType",
                  },
                },
                _vm._l(_vm.bizType, function (i, k) {
                  return _c("el-option", {
                    key: k,
                    attrs: { label: i.name, value: i.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "注册资金", prop: "registeredMoney" } },
            [
              _c("el-input", {
                attrs: { type: "number", placeholder: "请输入注册资金" },
                model: {
                  value: _vm.formData.registeredMoney,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "registeredMoney", _vm._n($$v))
                  },
                  expression: "formData.registeredMoney",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系人", prop: "contactName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入联系人" },
                model: {
                  value: _vm.formData.contactName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "contactName", $$v)
                  },
                  expression: "formData.contactName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "联系电话", prop: "contactPhone" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入联系电话" },
                model: {
                  value: _vm.formData.contactPhone,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "contactPhone", $$v)
                  },
                  expression: "formData.contactPhone",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", [
            _c(
              "div",
              {
                staticClass: "u-dashed-border u-text-center u-point",
                on: {
                  click: function ($event) {
                    _vm.dialogTableVisible = true
                  },
                },
              },
              [
                _c("i", { staticClass: "el-icon-plus u-m-r-10" }),
                _vm._v("选择经营者"),
              ]
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "u-p-t-10" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: { data: _vm.selectWorker },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "name",
                  label: "经营者姓名",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "phoneNumber",
                  width: "120",
                  label: "经营者手机号",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "idcardNo",
                  width: "180",
                  label: "经营者身份证号",
                  align: "center",
                  "show-overflow-tooltip": "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "身份证人像面", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("file-icon", {
                          attrs: {
                            url: scope.row.idcardPic,
                            img: "",
                            "img-style": "width: 60px",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { align: "center", label: "身份证国徽面" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("file-icon", {
                          attrs: {
                            url: scope.row.idcardPicBack,
                            img: "",
                            "img-style": "width: 60px",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "u-text-center u-p-t-20" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("formData")
                },
              },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "选择经营者",
            visible: _vm.dialogTableVisible,
            width: "1200px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogTableVisible = $event
            },
          },
        },
        [_c("worker-lookup", { on: { "on-select": _vm.onSelect } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }