<template>
  <div class="u-m-25 u-p-30 bg-white wrap-fixed-content" style="min-height: 50vh;">
    模板下载：<el-button v-loading="downloadLoading" size="small" type="success" icon="el-icon-download" @click="download">下载模板</el-button>
    <div class="u-m-t-50">
      <el-steps :active="active" align-center process-status="process" finish-status="success">
        <el-step title="导入创客信息" />
        <el-step title="确认创客信息" />
        <el-step title="完成" />
      </el-steps>
    </div>
    <el-form v-show="active === 0" ref="formData" class="u-p-t-40" :model="formData" label-width="250px" style="max-width: 800px">
      <el-form-item label="商户" required>
        <el-select v-model="formData.merchantId" filterable placeholder="请选择" @change="changeMerchant">
          <el-option v-for="(i, k) in shop" :key="k" :label="i.companyName" :value="i.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="服务商" required>
        <el-select v-model="taxId" placeholder="请选择">
          <el-option v-for="(item,i) in serviceList" :key="i" :label="item.taxName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="导入创客" required>
        <button-upload :default-action="action" name="workerExcel" type="excel" text="上传excel创客列表" @upload-success="uploadSuccess" />
      </el-form-item>
    </el-form>
    <div v-show="active === 1">
      <el-table ref="multipleTable" :data="lists" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="accountName" label="姓名" align="center" show-overflow-tooltip />
        <el-table-column prop="mobileCode" width="120" label="手机号" align="center" show-overflow-tooltip />
        <el-table-column prop="idcardCode" width="180" label="身份证号" align="center" show-overflow-tooltip />
        <el-table-column prop="bankCode" label="银行卡" width="180" align="center" show-overflow-tooltip />
        <el-table-column prop="bankName" label="开户行" align="center" show-overflow-tooltip />
      </el-table>
    </div>
    <div v-show="active === 2">
      <div class="u-text-center u-m-t-40">
        <i style="font-size: 80px" class="el-icon-circle-check text-success" />
      </div>
      <div class="u-text-center u-font-32 u-m-tb-40">
        {{ msg }}
      </div>
    </div>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" type="primary" @click="next">{{ active >= 2 ? '完成' : '下一步' }}</el-button>
      <el-button v-if="active === 0" size="small" @click="$router.go(-1)">取消</el-button>
      <el-button v-else-if="active < 2" size="small" @click="active--">上一步</el-button>
    </div>

    <el-dialog ref="checkDialog" title="提示" :visible.sync="dialogShow" width="800px">
      <el-table ref="multipleTable" :data="errLists" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="accountName" label="姓名" align="center" show-overflow-tooltip />
        <el-table-column prop="mobileCode" width="120" label="手机号" align="center" show-overflow-tooltip />
        <el-table-column prop="idcardCode" width="180" label="身份证号" align="center" show-overflow-tooltip />
        <el-table-column prop="reason" label="原因" align="center" show-overflow-tooltip />
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogShow = false">确定</el-button>
        <!-- <el-button :loading="loading" type="primary" @click="rejectPay">确 定</el-button> -->
      </div>
    </el-dialog>

  </div>
</template>

<script>
import ButtonUpload from '@/components/Upload/buttonUpload'
import { downloadWorkerExcel, importWorker, readExcel } from '@/api/worker'
import {
  getAllList
} from '@/api/shop'
import { downFile } from '@/utils'
import { getTaxListByMerchantId } from '@/api/common'

export default {
  name: 'ImportPage',
  components: { ButtonUpload },
  data() {
    return {
      dialogShow: false,
      taxId: '',
      merchantId:'',
      serviceList: [],
      shop:[],
      active: 0,
      action: readExcel(),
      formData: {
        url: ''
      },
      msg: '',
      downloadLoading: false,
      lists: [],
      errLists: []
    }
  },
  created() {
    this.fetchShop()
  },
  methods: {
    download() {
      this.downloadLoading = true
      downloadWorkerExcel().then(res => {
        downFile(res.obj, '创客模板.xlsx')
        this.downloadLoading = false
      }).catch(res => {
        this.downloadLoading = false
      })
    },
    fetchService() {
      getTaxListByMerchantId({
        merchantId:this.merchantId,
        packageStatus: 0
      }).then(res => {
        this.serviceList = res.data || []
      })
    },
    fetchShop() {
      getAllList().then(res => {
        this.shop = res.data
      })
    },
    changeMerchant (merchantId) {
      this.merchantId = merchantId
      this.taxId = ''
      this.fetchService()
    },
    uploadSuccess(data) {
      // this.lists = data || []
      // this.next()
      if (data?.status) {
        this.dialogShow = false
        this.lists = data.workers || []
        // this.next()
      } else {
        this.errLists = data.repeatWorkerList || []
        if (this.errLists.length) {
          this.dialogShow = true
        }
      }
    },
    next() {
      if (this.active === 1) {
        if (!this.lists.length) {
          return this.$message.error('请上传创客')
        }
        const loading = this.$loading()
        importWorker(this.lists, { taxId: this.taxId,merchantId:this.merchantId }).then(res => {
          this.msg = res.msg
          this.active++
          loading.close()
        }).catch(res => {
          loading.close()
        })
      } else if (this.active >= 2) {
        this.$router.go(-1)
      } else {
        if (!this.merchantId) {
          return this.$message.error('请选择商户')
        }
        if (!this.taxId) {
          return this.$message.error('请选择服务商')
        }
        if (!this.lists.length) {
          return this.$message.error('请上传创客')
        }
        this.active++
      }
    },
    submitForm() {
      if (this.$u.test.isEmpty(this.url)) {
        return this.$message.error('请上传excel创客列表')
      }
    }
  }
}
</script>

<style scoped>
</style>
