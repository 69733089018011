<template>
  <div class="u-m-25 u-p-30 bg-white">
    <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
      <el-form-item label="内容文字" prop="content">
        <template v-if="type === 'IMG'">
          <div class="u-flex u-col-center">
            <button-upload v-model="formData.content" type="image" text="上传图片(jpg/png)" />
            <el-button v-if="!$u.test.isEmpty(formData.content)" class="u-m-l-10" type="text" @click="$preViewer(formData.content)">查看</el-button>
          </div>
        </template>
        <el-input v-else v-model="formData.content" type="textarea" placeholder="请输入" />
      </el-form-item>
      <el-form-item>
        <el-button v-if="!isView" type="primary" @click="submitForm('formData')">确定</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getOtherDetail as getDetail, updateOther as update } from '@/api/applet'
import ButtonUpload from '@/components/Upload/buttonUpload'

const defaultForm = () => {
  return {
    'content': '',
    'entryName': '',
    'id': ''
  }
}

export default {
  components: { ButtonUpload },
  data() {
    const DefaultForm = defaultForm()
    return {
      loading: false,
      formData: Object.assign({}, DefaultForm),
      salesMan: [],
      title: '',
      rules: {
        content: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ]
      }
    }
  },
  computed: {
    isView() {
      return this.$route.meta.isView
    }
  },
  created() {
    this.type = this.$route.query.type
    if (this.$route.query.id) {
      this.fetchData(this.$route.query.id)
    }
  },
  methods: {
    setField(field, file) {
      this.formData[field] = file
    },
    fetchData(id) {
      this.loading = true
      getDetail({
        id: id
      }).then(res => {
        const data = res.obj || {}
        const DefaultForm = defaultForm()
        this.formData = Object.assign({}, DefaultForm, data)
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          if (this.$u.test.isEmpty(form.content)) {
            return this.$message.error('请上传内容')
          }
          this.loading = true
          update(form).then(res => {
            this.$message.success(res.message)
            this.loading = false
            this.$router.go(-1)
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
