var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-upload",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "upload-demo btn--upload",
          attrs: {
            action: _vm.action,
            headers: _vm.header,
            data: _vm.extraData,
            "on-preview": _vm.handlePreview,
            "on-remove": _vm.handleRemove,
            "on-error": _vm.handleError,
            "before-remove": _vm.beforeRemove,
            multiple: _vm.multiple,
            "file-list": _vm.fileList,
            "show-file-list": _vm.multiple,
            name: _vm.fileName,
            "on-success": _vm.handleUploadSuccess,
            "before-upload": _vm.handleBeforeUpload,
          },
        },
        [
          _c(
            "el-button",
            {
              ref: "elUpload",
              attrs: {
                slot: "trigger",
                icon: _vm.btnType === "text" ? "" : "el-icon-upload2",
                size: "small",
                type: _vm.btnType,
              },
              slot: "trigger",
            },
            [_vm._v(_vm._s(_vm.text))]
          ),
        ],
        1
      ),
      _c("viewer", { ref: "viewer" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }