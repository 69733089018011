<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="创客编号">
          <el-input v-model="formData.workerId" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="创客姓名">
          <el-input v-model="formData.accountName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="创客手机号">
          <el-input v-model="formData.mobileCode" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
          <el-button :loading="loadingBtn" type="primary" @click="downloadZip" v-if="type=='cert'">导出创客照片</el-button>
          <el-button :loading="loadingBtn" type="primary" @click="downloadExcel">导出Excel</el-button>
          <el-button :loading="loadingBtn" type="primary" @click="downloadContract" v-if="type=='cert'">导出创客合同</el-button>
        </el-form-item>
      </el-form>
      <el-button v-if="$store.getters.userinfo.userSign==1" type="primary" size="small" icon="el-icon-plus" @click="handleAdd">
        导入创客
      </el-button>
      <el-button v-if="type === 'cert'" type="primary" size="small" @click="batchMatchServe">批量匹配服务商</el-button>
    </div>
    <div class="u-p-lf-10 bg-white">
      <!-- v-if="show" -->
      <el-button v-if="!$route.meta.cert" type="primary" :loading="btnLoading" @click="batchCheck">批量发送</el-button>
      <el-table ref="multipleTable" row-key="id" v-loading="loading" :data="list" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55" reserve-selection />
        <el-table-column prop="id" label="创客编号" width="100" show-overflow-tooltip align="center" />
        <el-table-column prop="accountName" label="姓名" align="center" show-overflow-tooltip />
        <el-table-column prop="mobileCode" label="手机号" align="center" show-overflow-tooltip />
        <el-table-column prop="idcardCode" label="身份证" align="center" show-overflow-tooltip />
        <el-table-column prop="bankCode" label="银行卡号" align="center" show-overflow-tooltip />
        <el-table-column key="attestation" label="实名认证" align="center" width="120">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.attestation, $enum.workAttestation(), true)" />
          </template>
        </el-table-column>
        <el-table-column key="agreementUrl" label="加盟合同" align="center">
          <template slot-scope="scope">
            <div v-if="$u.test.isEmpty(scope.row.agreementUrl)" v-html="$options.filters.filterContentItem('danger', '未签')" />
            <file-icon v-else :url="scope.row.agreementUrl" />
          </template>
        </el-table-column>
        <el-table-column key="practiceQualificationUrl" label="执业资质" align="center">
          <template slot-scope="scope">
            <div v-if="$u.test.isEmpty(scope.row.practiceQualificationUrl)" v-html="$options.filters.filterContentItem('danger', '未上传')" />
            <file-icon v-else :url="scope.row.practiceQualificationUrl" />
          </template>
        </el-table-column>
        <template v-if="!boolMakerVerify">
          <el-table-column key="freeWorkerAgreementUrl" label="自由者职业协议" align="center" width="120">
            <template slot-scope="scope">
              <div v-if="$u.test.isEmpty(scope.row.freeWorkerAgreementUrl)" v-html="$options.filters.filterContentItem('danger', '未签')" />
              <file-icon v-else :url="scope.row.freeWorkerAgreementUrl" />
            </template>
          </el-table-column>
        </template>
        <el-table-column prop="createDate" label="创建时间" align="center" width="100" />
        <el-table-column label="操作" width="120" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <!-- <el-button v-if="scope.row.attestation !== 1" type="text" size="small" @click="handleSend(scope.row)">发送信息</el-button> -->
            <el-button v-if="type === 'cert'" type="text" size="small" @click="handleMatchService(scope.row)">匹配服务商</el-button>
            <el-button v-if="!boolMakerVerify" type="text" size="small" @click="handleUpload(scope.row)">上传自由者协议</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
    </div>
    <div style="display: none">
      <button-upload ref="uploadRef" :success-toast="false" type="image/pdf" @upload-success="handleUploadSuccess" />
    </div>
    <el-dialog title="编辑创客" :visible.sync="dialogTableVisible" width="1000px">
      <el-form ref="workerFormRef" v-loading="formLoading" :model="workerFormData" :rules="rules" label-width="124px" style="max-width: 500px">
        <el-form-item label="创客编号">
          <el-input v-model="workerFormData.workerId" disabled />
        </el-form-item>
        <el-form-item label="创客姓名">
          <el-input v-model="workerFormData.accountName" />
        </el-form-item>
        <el-form-item label="身份证号码">
          <el-input v-model="workerFormData.idcardCode" />
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input v-model="workerFormData.mobileCode" />
        </el-form-item>
        <el-form-item label="银行卡号">
          <el-input v-model="workerFormData.bankCode" />
        </el-form-item>
        <el-form-item label="开户地">
          <el-input v-model="workerFormData.bankAddress" />
        </el-form-item>
        <el-form-item label="开户行">
          <el-input v-model="workerFormData.bankName" />
        </el-form-item>
        <el-form-item label="加盟合同" prop="agreementUrl">
          <div class="u-flex u-col-center">
            <button-upload type="pdf" text="上传PDF文件" @upload-success="setFields('agreementUrl', $event)" />
            <el-button v-if="!$u.test.isEmpty(workerFormData.agreementUrl)" class="u-m-l-10" type="text" @click="$preViewer(workerFormData.agreementUrl)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="身份证正面" prop="idcardFront">
          <div class="u-flex u-col-center">
            <button-upload type="image" text="上传jpg" @upload-success="setFields('idcardFront', $event)" />
            <el-button v-if="!$u.test.isEmpty(workerFormData.idcardFront)" class="u-m-l-10" type="text" @click="$preViewer(workerFormData.idcardFront)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="身份证背面" prop="idcardBack">
          <div class="u-flex u-col-center">
            <button-upload type="image" text="上传jpg" @upload-success="setFields('idcardBack', $event)" />
            <el-button v-if="!$u.test.isEmpty(workerFormData.idcardBack)" class="u-m-l-10" type="text" @click="$preViewer(workerFormData.idcardBack)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogTableVisible = false">取消</el-button>
          <el-button type="primary" @click="submitFormWorker()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <el-dialog title="匹配服务商" :visible.sync="dialogMatchServe" width="1000px">
      <el-form ref="refMatchServeForm" v-loading="formLoading" :model="matchFormData" :rules="matchRules" label-width="124px" style="max-width: 500px">
        <!-- <el-form-item label="业务类型" required>
          <el-select v-model="matchFormData.packageType" placeholder="请选择">
            <el-option label="总包+分包" value="TOTAL" />
            <el-option label="众包" value="MANY" />
          </el-select>
        </el-form-item> -->
        <el-form-item label="商户" prop="companyId">
          <el-select v-model="matchFormData.companyId" placeholder="请选择">
            <el-option v-for="(item,i) in shopList" :key="i" :label="item.companyName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="服务商" prop="taxId">
          <el-select v-model="matchFormData.taxId" placeholder="请选择">
            <el-option v-for="(item,i) in serviceList" :key="i" :label="item.taxName" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="dialogMatchServe = false">取消</el-button>
          <el-button type="primary" @click="matchServe()">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :visible.sync="linkDialog" title="短信链接">
      <div style="display:flex;margin-bottom:10px" v-for="(item,index) in linkList" :key="index">
        <div style="min-width:60px">{{item.accountName || '-'}}</div>
        <el-link type="primary" :underline="false" :href="` http://h5-llz.linggongmao.com.cn/#/?workerId=${item.id}&merchantId=${$store.getters.userinfo.id}#/`">{{` http://h5-llz.linggongmao.com.cn/#/?workerId=${item.id}&merchantId=${$store.getters.userinfo.id}#/`}}</el-link>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListCert,
  getListUnCert,
  uploadFree,
  updateWorker,
  getSendID,
  getSendBatch,
  matchTax,
  downloadWorkerZip,
  downloadExcel,
  downloadAgreement,
} from "@/api/worker";
import { getServiceList, getCrowdShopList } from "@/api/common";
import { downloadFile } from "@/utils";
import ButtonUpload from "@/components/Upload/buttonUpload";
import XLSX from "xlsx";
// import { checkMoney } from '@/api/task'
export default {
  name: "WorkerList",
  components: { ButtonUpload },
  props: {
    // 类型 cert/uncert
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialogTableVisible: false,
      formLoading: false,
      workerFormData: {
        accountName: "",
        idcardCode: "",
        bankAddress: "",
        workerId: "",
        mobileCode: "",
        bankCode: "",
        bankName: "",
        agreementUrl: "",
        idcardFront: "",
        idcardBack: "",
      },
      boolMakerVerify: true, // 非自由者协议
      uploadForm: {
        workerId: "",
        freeWorkerAgreementUrl: "",
      },
      certList: [
        { value: 0, name: "否", type: "danger" },
        { value: 1, name: "是", type: "success" },
      ],
      loading: false,
      loadingBtn: false,
      formData: {
        workerId: "",
        accountName: "",
        mobileCode: "",
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10,
      },
      rules: {
        mobileCode: [
          { required: true, trigger: "blur", message: "请输入手机号" },
        ],
        bankCode: [
          { required: true, trigger: "blur", message: "请输入银行卡" },
        ],
      },
      dialogMatchServe: false,
      serviceList: [],
      shopList: [],
      matchFormData: {
        // packageType: 'TOTAL',
        companyId: "",
        taxId: "",
        workerIds: undefined,
        workerId: undefined,
      },
      matchRules: {
        companyId: [{ required: true, trigger: "blur", message: "请选择商户" }],
        taxId: [{ required: true, trigger: "blur", message: "请选择服务商" }],
      },
      checkData: {
        id: "",
      },
      selectedWorkerId: [],
      show: true,
      linkDialog: false,
      linkList: [],
      id: [],
      btnLoading: false,
    };
  },
  created() {
    this.checkData.id = this.id;
    this.isCheck = this.$route.meta.isCheck;
    this.fetchData();
    console.log(this.$store.getters.userinfo, "userinfo");
  },
  activated() {
    this.fetchData();
  },
  methods: {
    setFields(fields, val) {
      this.workerFormData[fields] = val;
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10,
      });
      this.list = [];
      this.pageTotal = 0;
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    fetchData() {
      this.loading = true;
      if (this.type === "cert") {
        getListCert(Object.assign({}, this.formData, this.paramsData))
          .then((res) => {
            const data = res.data || res.obj;
            if (this.$u.test.object(data)) {
              this.boolMakerVerify = data.boolMakerVerify || false;
              this.list = data.workerIPage.records || [];
              this.pageTotal = data.workerIPage.pages;
            } else {
              this.list = data || [];
              this.pageTotal = res.pageCount;
            }
            this.loading = false;
          })
          .catch((res) => {
            this.loading = false;
          });
      } else {
        getListUnCert(Object.assign({}, this.formData, this.paramsData))
          .then((res) => {
            const data = res.data || res.obj;
            if (this.$u.test.object(data)) {
              this.boolMakerVerify = data.boolMakerVerify || false;
              this.list = data.workerIPage.records || [];
              this.pageTotal = data.workerIPage.pages;
            } else {
              this.list = data || [];
              this.pageTotal = res.pageCount;
            }
            this.loading = false;
          })
          .catch((res) => {
            this.loading = false;
          });
      }
    },
    handleUpload(row) {
      this.uploadForm.workerId = row.id;
      this.uploadForm.freeWorkerAgreementUrl = "";
      this.$refs.uploadRef.$refs.elUpload.$el.click();
    },
    handleEdit(i) {
      console.log(i,'--')
      this.dialogTableVisible = true;
      Object.keys(this.workerFormData).forEach((key) => {
        this.workerFormData[key] = i[key] || "";
      });
      this.workerFormData.workerId = i.id || "";
      console.log(this.workerFormData,'1111')
    },
    // 给单个用户发送信息
    handleSend(i) {
      this.linkList = [i];
      console.log(this.linkList, "this.linkList");
      this.$confirm("你确定要发送吗?", "系统提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.loading = true;
          getSendID({
            id: i.id,
          })
            .then((res) => {
              this.resetPage();
              this.$message({
                type: "success",
                message: "发送成功!",
              });
              this.linkDialog = true;
            })
            .catch((res) => {
              this.loading = false;
            });
        })
        .catch(() => {});
    },
    // 批量发送
    batchCheck() {
      this.btnLoading = true;
      console.log(this.id, "iddd");
      let ids = this.id.length ? this.id.toString() : "all";
      console.log(ids, "ids");
      const formData = new FormData();
      formData.append("ids", ids);
      getSendBatch(formData).then((res) => {
        this.$message.success("发送成功");
        this.fetchData();
        this.btnLoading = false;
        this.$refs.multipleTable.clearSelection();
        // this.linkDialog = true
        // console.log(this.linkList,'linklist');
      });
    },
    handleSelectionChange(val) {
      console.log(val, "fhsyhf");
      if (val.length) {
        this.linkList = val;
      }
      console.log(this.linkList, "handleSelectionChange");
      this.id = [];
      this.selectedWorkerId = this.id;
      console.log(this.id, "this.idthis.id");
      // checkData selectedWorkerId
      if (val.length) {
        val.forEach((element) => {
          this.id.push(element.id);
          // console.log(element.id)
        });
      }
      this.matchFormData.workerIds = this.id.join(",");
      this.matchFormData.workerId = undefined;
    },
    // handleSelectionChange(rows) {
    //   const ids = [];
    //   rows.forEach((val) => {
    //     ids.push(val.id);
    //   });
    //   this.matchFormData.workerIds = ids.join(",");
    //   this.matchFormData.workerId = undefined;
    // },
    downloadZip() {
      this.loadingBtn = true;
      downloadWorkerZip({
        ids: this.selectedWorkerId.toString(),
      })
        .then((res) => {
          this.loadingBtn = false;
          downloadFile(res, "创客照片.zip");
          this.$refs.multipleTable.clearSelection();
        })
        .catch((res) => {
          this.loadingBtn = false;
        });
    },
    downloadExcel() {
      this.loadingBtn = true;
      const workerType = this.$route.meta.cert ? 0 : 1;
      const paramsData = Object.assign({}, this.formData, this.paramsData);
      downloadExcel({ workerType }, paramsData)
        .then((res) => {
          console.log(res, "resss");
          const data = res.data || res.obj;
          if (data.length) {
            const tableData = [
              [
                "创客编号",
                "创建时间",
                "姓名",
                "手机号",
                "身份证号",
                "银行卡",
                "实名认证",
              ],
            ];
            let rowData = [];
            data.forEach((item, index) => {
              if (item.attestation === 0) {
                item.attestation = "未认证";
              } else if (item.attestation === 1) {
                item.attestation = "已认证";
              } else {
                item.attestation = "认证失败";
              }
              rowData = [
                item.id,
                item.createDate,
                item.accountName,
                item.mobileCode,
                item.idcardCode,
                item.bankCode,
                item.attestation,
              ];
              tableData.push(rowData);
            });
            const ws = XLSX.utils.aoa_to_sheet(tableData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "创客明细");
            XLSX.writeFile(wb, "创客明细.xlsx");

            this.loadingBtn = false;
          } else {
            this.$message.error("没有查询到数据");
          }
        })
        .catch((res) => {
          this.loadingBtn = false;
        });
    },
    async downloadContract() {
      this.loadingBtn = true;
      try {
        const res = await downloadAgreement({
          ...this.formData,
          ids: this.selectedWorkerId.toString(),
        });
        window.open(res.obj)
        this.loadingBtn = false;
        this.$refs.multipleTable.clearSelection();
      } catch {
        this.loadingBtn = false;
      }
    },
    submitFormWorker() {
      this.formLoading = true;
      this.workerFormData.workAccountName = this.workerFormData.accountName;
      updateWorker(this.workerFormData)
        .then((res) => {
          this.formLoading = false;
          this.$message.success("更新成功");
          this.dialogTableVisible = false;
          // this.$forceUpdate()
          this.fetchData();
        })
        .catch((res) => {
          this.formLoading = false;
        });
    },
    handleUploadSuccess(data) {
      this.uploadForm.freeWorkerAgreementUrl = data;
      if (this.$u.test.isEmpty(this.uploadForm.freeWorkerAgreementUrl)) {
        return this.$message.error("请上传自由职业者协议");
      }
      uploadFree(this.uploadForm)
        .then((res) => {
          this.$message.success("上传成功");
          this.fetchData();
        })
        .catch((res) => {});
    },
    handleAdd() {
      this.$router.push({
        path: "/worker/importPage",
      });
    },
    handleShow(i) {
      if (this.type === "cert") {
        this.$router.push({
          path: "/worker/view",
          query: {
            id: i.id,
            boolMakerVerify: this.boolMakerVerify ? 0 : 1,
          },
        });
      } else {
        this.$router.push({
          path: "/worker/uncertView",
          query: {
            id: i.id,
            boolMakerVerify: this.boolMakerVerify ? 0 : 1,
          },
        });
      }
    },
    batchMatchServe() {
      if (this.matchFormData.workerIds) {
        this.matchFormData.taxId = "";
        if (this.serviceList.length === 0) {
          this.fetchService();
          this.fetchShop();
        }
        this.dialogMatchServe = true;
      } else {
        this.$message.error("请先选中创客");
      }
    },
    fetchService() {
      getServiceList({
        packageStatus: 0,
      }).then((res) => {
        this.serviceList = res.data || [];
      });
    },
    fetchShop() {
      getCrowdShopList().then((res) => {
        this.shopList = res.data || [];
      });
    },
    handleMatchService(i) {
      this.matchFormData.workerIds = i.id;
      this.matchFormData.companyId = "";
      this.matchFormData.taxId = "";
      if (this.serviceList.length === 0) {
        this.fetchService();
        this.fetchShop();
      }
      this.dialogMatchServe = true;
    },
    matchServe() {
      this.$refs.refMatchServeForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          matchTax(this.matchFormData)
            .then((res) => {
              this.loading = false;
              this.$message.success("操作成功");
              this.dialogMatchServe = false;
              this.fetchData();
            })
            .catch((res) => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
