<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="创客名称">
          <el-input v-model="formData.workerName" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="签署状态">
          <el-select v-model="formData.signState" style="width: 100%" placeholder="全部" clearable filterable>
            <el-option label="未签署" value="UNSIGN" />
            <el-option label="已签署" value="SIGNED" />
          </el-select>
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker v-model="formData.startTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px" />
        </el-form-item>
        <el-form-item> 至 </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.endTime" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <tableList :table-options="tableOptions" :table-list="tableList" :params-data="paramsData" :loading="loading" :page-total="pageTotal" @fetchData="fetchData" />
  </div>
</template>

<script>
import tableList from "./components/table-list.vue";
import { contractWorker } from "@/api/contract";
export default {
  components: {
    tableList,
  },
  data() {
    return {
      tableOptions: [
        {
          label: "合同编号",
          props: "agreementNo",
        },
        {
          label: "姓名",
          props: "partyBName",
        },
        {
          label: "合同",
          props: "agreementUrl",
        },
        {
          label: "签署方式",
          props: "signType",
        },
        {
          label: "签署状态",
          props: "signState",
        },
        {
          label: "签署时间",
          props: "updateDate",
        },
      ],
      taxNameList: [],
      companyNameList: [],
      tableList: [],
      formData: {},
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const res = await contractWorker({ ...this.paramsData, ...this.formData });
      this.tableList = res.data || [];
      this.pageTotal = res.pageCount;
      this.loading = false;
      console.log(res, "res");
    },
    resetPage() {
      this.paramsData = {
        pageNo: 1,
        pageSize: 10,
      };
      this.fetchData();
    },
  },
};
</script>

<style>
</style>
