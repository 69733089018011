<template>
  <div>
    <el-row v-loading="loading" class="section-body" :gutter="10">
      <el-col :span="md">
        <span class="label">任务编号:</span>
        <span>{{ item.taskCode }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">商户名称:</span>
        <span>{{ item.merchantName }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">任务名称:</span>
        <span>{{ item.taskName }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">任务说明文字:</span>
        <span>{{ item.taskDesc }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">费用:</span>
        <span>{{ item.taskCostMin }} 至 {{ item.taskCostMax }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">创客所需技能:</span>
        <span>{{ item.taskSkill }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">任务上限人数:</span>
        <span>{{ item.upperLimit }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">合作类型:</span>
        <span>{{ item.cooperateMode | cooperateMode }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">任务模式:</span>
        <span>{{ item.taskMode | taskMode }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">状态:</span>
        <span>{{ item.state | taskState }}</span>
      </el-col>
      <el-col :span="md">
        <span class="label">发布时间:</span>
        <span>{{ item.createDate }}</span>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getDetail } from '@/api/task'

export default {
  name: 'TaskDetail',
  props: {
    taskId: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      md: 8,
      item: {}
    }
  },
  created() {
    if (!this.$u.test.isEmpty(this.taskId)) {
      this.fetchData()
    }
  },
  methods: {
    fetchData(taskId) {
      taskId = taskId || this.taskId
      this.loading = true
      getDetail({
        taskId: taskId
      }).then(res => {
        this.item = res.obj || {}
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.section-body {
  > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .label {
    margin-right: 5px;
  }
}
</style>
