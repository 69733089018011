<template>
  <div>
    <el-image-viewer
      v-if="showViewer.show"
      style="z-index: 9999"
      :initial-index="showViewer.initialIndex"
      :on-close="showViewerClose"
      :url-list="showViewer.url"
    />
    <el-dialog ref="el-viewer" title="查看文件" :visible.sync="dialogShow" style="text-align: left">
      <template v-for="(i, k) in files">
        <span :key="k + new Date()">
          <svg-icon v-if="i.type === 'pdf'" class-name="pdf" icon-class="pdf" @click="showSimple(i)" />
          <el-image
            v-else-if="i.type === 'img'"
            style="width: 50px; height: 50px;margin: 5px"
            :src="i.path"
            fit="contain"
            @click="showSimple(i)"
          />
          <svg-icon v-else-if="i.type === 'excel'" class-name="excel" icon-class="excel" @click="showSimple(i)" />
          <i v-else-if="i.type === 'video'" class="el-icon-video-camera" @click="showSimple(i)" />
          <i v-else-if="i.type === 'file'" class="el-icon-tickets" @click="showSimple(i)" />
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  name: 'Viewer',
  components: {
    ElImageViewer
  },
  props: {
    multi: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dialogShow: false,
      showViewer: {
        show: false,
        url: '',
        initialIndex: 0
      },
      files: []
    }
  },
  computed: {
    imgFiles() {
      const files = this.files
      const img = []
      for (let i = 0, len = files.length; i < len; i++) {
        if (files[i].type === 'img') {
          img.push(files[i].path)
        }
      }
      return img
    }
  },
  methods: {
    showSimple(i) {
      if (i.type === 'img') {
        this.showViewer = {
          show: true,
          url: this.imgFiles,
          initialIndex: i.imgIndex
        }
      } else {
        window.open(i.path)
      }
    },
    show(url) {
      if (this.$u.test.isEmpty(url)) {
        return this.$message.error('文件不存在')
      }
      const files = url.split(',')
      if (!files.length) {
        return this.$message.error('文件不存在')
      }
      if (files.length === 1) {
        const type = this.$checkFileType(url)
        if (type === 'img') {
          this.showViewer = {
            show: true,
            url: files
          }
        } else {
          window.open(url)
        }
      } else {
        const filesArray = []
        let imgIndex = -1
        for (let i = 0, len = files.length; i < len; i++) {
          const type = this.$checkFileType(files[i])
          if (type) {
            filesArray.push({
              path: files[i],
              type: type,
              imgIndex: type === 'img' ? ++imgIndex : 0
            })
          }
        }
        this.files = filesArray
        this.dialogShow = true
      }
    },
    showViewerClose() {
      this.showViewer.show = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .pdf {
    width: 50px;
    height: 50px;
    color: #F4696C;
  }
  .img {
    width: 50px;
    height: 50px;
    color: #2178AB;
  }
  .excel {
    width: 50px;
    height: 50px;
    color: #58B994;
  }
  .el-icon-tickets, .el-icon-video-camera {
    font-size: 50px;
    color: #58B994;
  }
</style>
