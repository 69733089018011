<template>
  <div>
    <el-form ref="forgetForm" :model="forgetForm" :rules="forgetRules" auto-complete="on" label-position="left">
      <el-form-item prop="phoneNumber">
        <span class="svg-container">
          <svg-icon icon-class="mobile" />
        </span>
        <el-input
          ref="mobile"
          v-model="forgetForm.phoneNumber"
          placeholder="请输入手机号"
          name="mobile"
          type="text"
          tabindex="1"
          auto-complete="on"
        />
      </el-form-item>
      <el-form-item prop="smsCode" class="with-button">
        <span class="svg-container">
          <svg-icon icon-class="code" />
        </span>
        <el-input
          :key="passwordType"
          ref="code"
          v-model="forgetForm.smsCode"
          placeholder="请输入验证码"
          name="code"
          type="text"
          tabindex="2"
          auto-complete="off"
        />
        <el-button type="default" :disabled="disabled" @click="getCode">{{ btnText }}</el-button>
      </el-form-item>
      <el-form-item prop="newPassword">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="passwordType"
          ref="password"
          v-model="forgetForm.newPassword"
          :type="passwordType"
          placeholder="请输入密码"
          name="password"
          tabindex="2"
          auto-complete="on"
        />
      </el-form-item>
      <el-form-item prop="repassword">
        <span class="svg-container">
          <svg-icon icon-class="password" />
        </span>
        <el-input
          :key="passwordType"
          v-model="forgetForm.repassword"
          :type="passwordType"
          placeholder="请确认密码"
          name="password"
          tabindex="2"
          auto-complete="on"
        />
      </el-form-item>
    </el-form>
    <div class="u-flex u-col-center u-row-between u-p-b-20">
      <div />
      <a href="javascript:" class="text-primary u-font-14" @click="changeType">返回登录</a>
    </div>
    <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;" @click.native.prevent="handleForget">确  定</el-button>
  </div>
</template>

<script>
import { isMobile } from '@/utils/validate'
import { getCode } from '@/api/common'
import { updatePassword } from '@/api/user'
const btnText = '获取验证码'
function defaultForm() {
  return {
    newPassword: '',
    phoneNumber: '',
    smsCode: '',
    repassword: ''
  }
}
export default {
  name: 'Forget',
  data() {
    const validateMobile = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请输入手机号'))
      } else if (isMobile(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    const DefaultForm = defaultForm()
    return {
      forgetForm: Object.assign({}, DefaultForm),
      forgetRules: {
        newPassword: [{ required: true, trigger: 'blur', validator: 'required', message: '请输入密码' }],
        phoneNumber: [{ required: true, trigger: 'blur', validator: validateMobile }],
        smsCode: [{ required: true, trigger: 'blur', validator: 'required', message: '请输入验证码' }]
      },
      passwordType: 'password',
      btnText: btnText,
      disabled: false,
      loading: false
    }
  },
  methods: {
    setPhone(val) {
      const DefaultForm = defaultForm()
      this.forgetForm = Object.assign({}, DefaultForm, {
        phoneNumber: val
      })
    },
    doLoop: function(seconds) {
      seconds = seconds || 60
      this.btnText = seconds + 's后获取'
      const countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = btnText
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    getCode() {
      const mobile = this.forgetForm.phoneNumber
      if (!mobile.length) {
        return this.$message.error('请输入手机号')
      } else if (isMobile(mobile)) {
        return this.$message.error('请输入正确的手机号')
      }
      this.disabled = true
      getCode({
        codeType: 'UPDATEPASSWORD',
        mobileCode: mobile
      }).then(res => {
        this.$message.success('发送短信成功')
        setTimeout(() => {
          this.doLoop(60)
        }, 500)
      }).catch(res => {
        this.disabled = false
      })
    },
    changeType() {
      this.$emit('change-type')
    },
    handleForget() {
      this.$refs.forgetForm.validate(valid => {
        if (valid) {
          const formData = this.forgetForm
          if (this.$u.test.isEmpty(formData.newPassword)) {
            return this.$message.error('密码不能为空')
          }
          if (formData.newPassword !== formData.repassword) {
            return this.$message.error('两次密码不一致')
          }
          this.loading = true
          updatePassword({
            checkCode: formData.smsCode,
            loginMobile: formData.phoneNumber,
            newPassWord: formData.newPassword
          }).then(res => {
            this.$message.success('密码修改成功')
            this.$emit('success')
            this.loading = false
          }).catch(res => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
  $bg:#66A2FE;
  $dark_gray:#889aa4;
  $light_gray:#eee;

  .topbar {
    background-color: #fff;
    height: 80px;
    padding: 20px 260px;

    .el-button {
      width: 90px;
      height: 32px;
      font-size: 12px;
      border-radius:6px;
    }
    .el-button--default {
      color: #4065E1;
      border-color: #4065E1;
    }
  }
  .el-button--primary {
    background-color: #4065E1;
    border-color: #4065E1;
  }
  .login-container {
    min-height: 100%;
    width: 100%;
    background-color: $bg;
    overflow: hidden;
    position: relative;

    .login-form {
      position: absolute;
      max-width: 100%;
      width:420px;
      /*height: 500px;*/
      left: 50%;
      top: 50%;
      padding: 0;
      margin: -250px 0 0 -210px;
      overflow: hidden;
      border-radius:8px;
    }

    .logo-title {
      font-size:38px;
      font-weight:400;
      line-height:60px;
      color:rgba(255,255,255,1);
      text-align: center;
      margin-bottom: 20px;
    }

    .tips {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .svg-container {
      padding: 0 5px 0 15px;
      color: $dark_gray;
      vertical-align: middle;
      width: 30px;
      display: inline-block;
    }

    .title-container {
      position: absolute;
      left: 0;
      top: 0;
      padding-left: 50px;
      width: 280px;
      bottom: 0;
      background:rgba(249,250,251,1);
      font-size:28px;
      color:rgba(51,51,51,1);
      padding-top: 80px;
      .title {
        font-size:38px;
        font-weight:400;
        line-height:60px;
        color:rgba(64,101,225,1);
      }
      .sub {
        font-size:25px;
        font-weight:400;
        line-height:72px;
        color:rgba(0,0,0,0.65);
      }
    }

    .login-box {
      background-color: #fff;
      padding: 30px;
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 2px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }
  }
  .footer {
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #FFFFFF;
  }
</style>
