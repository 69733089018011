<template>
  <div class="u-p-20 wrap-fixed-content" style="min-width: 1300px">
    <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" class="u-form" label-width="150px">
      <el-card header="基本信息">
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="formData.companyName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="法定代表人" prop="companyMan">
          <el-input v-model="formData.companyMan" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="注册资本" prop="registeredCapital">
          <el-input v-model.number="formData.registeredCapital" min="0" type="number" placeholder="请输入">
            <span slot="suffix">万元</span>
          </el-input>
        </el-form-item>
        <el-form-item label="成立日期" prop="companyCreateDate">
          <el-date-picker v-model="formData.companyCreateDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期" />
        </el-form-item>
        <el-form-item label="社会统一信用代码" prop="creditCode">
          <el-input v-model="formData.creditCode" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="账户名称" prop="titleOfAccount">
          <el-input v-model="formData.titleOfAccount" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="开户行" prop="bankName">
          <el-select v-model="formData.bankName" filterable placeholder="请选择">
            <el-option v-for="(i, k) in bankOptions" :key="k" :label="i.dictValue" :value="i.dictValue" />
          </el-select>
        </el-form-item>
        <el-form-item label="银行卡号" prop="bankCode">
          <el-input v-model="formData.bankCode" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="营业执照" required>
          <div class="u-flex u-col-center">
            <button-upload type="image/pdf" text="上传 jpg/pdf" @upload-success="setFields('businessLicense', $event)" />
            <el-button v-if="!$u.test.isEmpty(formData.businessLicense)" class="u-m-l-10" type="text"
              @click="$preViewer(formData.businessLicense)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="创客任务规则">
          <el-select v-model="formData.workRules" placeholder="请选择">
            <el-option label="需审核" value="NEEDREVIEW" />
            <el-option label="无需审核" value="PASS" />
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="是否关联任务">
          <el-select v-model="formData.relevance" placeholder="请选择">
            <el-option label="是" :value="1" />
            <el-option label="否" :value="0" />
          </el-select>
        </el-form-item> -->
      </el-card>
      <el-card header="组织结构" class="u-m-t-20">
        <el-form-item label="平台业务员" prop="salesManId">
          <el-select v-model="formData.salesManId" :disabled="userSign !== 3" placeholder="平台业务员" clearable filterable
            @change="fetchAgent">
            <el-option v-for="serviceItem in assistantList" :key="serviceItem.id" :label="serviceItem.realName"
              :value="serviceItem.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="代理商" prop="agentId">
          <el-select v-model="formData.agentId" :disabled="userSign === 1" placeholder="代理商" clearable filterable
            @change="fetchService('change')">
            <el-option v-for="serviceItem in agentList" :key="serviceItem.id" :label="serviceItem.agentName"
              :value="serviceItem.id" />
          </el-select>
        </el-form-item>
      </el-card>
      <el-card header="合作信息" class="u-m-t-20">
        <el-form-item label="平台加盟合同" required>
          <div class="u-flex u-col-center">
            <button-upload type="image/pdf" text="上传 jpg/pdf" @upload-success="setFields('contract', $event)" />
            <el-button v-if="!$u.test.isEmpty(formData.contract)" class="u-m-l-10" type="text"
              @click="$preViewer(formData.contract)">查看</el-button>
          </div>
        </el-form-item>
        <!-- 总包+分包 -->
        <div class="coop-box u-m-l-60" :class="totalChecked ? '' : 'box-uncheck'">
          <el-checkbox v-model="totalChecked">合作类型：总包+分包（6%专票）</el-checkbox>
          <div class="u-flex u-col-top u-row-left u-flex-wrap">
            <div v-for="(item, k) in totalList" :key="item.guid" class="u-m-t-30 u-p-r-20" style="width: 45%">
              <div class="u-border u-p-20" style="position: relative">
                <i v-if="k !== 0" class="el-icon-remove-outline text-danger u-font-18 u-point"
                  style="position: absolute;right: 5px;top: 5px" @click="removeTotalList(k)" />
                <el-form-item label="平台服务商" required label-width="100px">
                  <el-select v-model="item.taxId" placeholder="平台服务商" clearable filterable
                    @change="changeTotalService(k, $event)">
                    <el-option v-for="serviceItem in totalServiceList" :key="serviceItem.id" :label="serviceItem.taxName"
                      :value="serviceItem.id" />
                  </el-select>
                </el-form-item>
                <template v-if="serviceTotalPackage[item.taxId]">
                  <el-form-item label="综合税费率" required label-width="100px">
                    <el-radio-group v-model="item.chargeStatus">
                      <el-radio v-if="serviceTotalPackage[item.taxId].taxPrice" :label="0">一口价</el-radio>
                      <el-radio v-if="serviceTotalPackage[item.taxId].invoiceLadderPriceDetailVOList"
                        :label="1">梯度价</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="item.chargeStatus === 0" label-width="100px">
                    <el-input :key="item.taxId" v-model.number="item.serviceCharge" type="number" :min="0"
                      @blur="checkTotalItem(item, serviceTotalPackage[item.taxId].taxPrice || 0, $event)">
                      <span slot="suffix" style="line-height: 40px">%</span>
                    </el-input>
                    <div class="u-tips">参考值(不低于)：{{ serviceTotalPackage[item.taxId] ?
                      (serviceTotalPackage[item.taxId].taxPrice || 0) : 0 }}%</div>
                  </el-form-item>
                  <el-form-item v-else label-width="100px">
                    <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                      <div class="u-flex-2">单人单月额度</div>
                      <div class="u-flex-1">服务费率</div>
                      <div style="width: 50px" />
                    </div>
                    <div v-if="item.addCompanyLadderServiceDtoList">
                      <div v-for="(i, j) in item.addCompanyLadderServiceDtoList" :key="i.guid" class="u-m-t-10">
                        <div class="u-flex u-col-center u-row-center">
                          <div class="u-flex-2 u-flex u-col-center u-row-center">
                            <el-input v-model="i.startMoney" readonly placeholder="请输入">
                              <span slot="suffix" style="line-height: 40px">元</span>
                            </el-input>
                            <span class="u-p-lf-5">至</span>
                            <el-input v-model="i.endMoney" readonly placeholder="请输入">
                              <span slot="suffix" style="line-height: 40px">元</span>
                            </el-input>
                          </div>
                          <div class="u-flex-1 u-p-l-10">
                            <el-input v-model="i.serviceCharge" placeholder="请输入"
                              @blur="checkTotalItem(i, serviceTotalPackage[item.taxId].invoiceLadderPriceDetailVOList[j].rate || 0, $event)">
                              <span slot="suffix" style="line-height: 40px">%</span>
                            </el-input>
                          </div>
                        </div>
                        <div class="u-tips u-text-right">参考值(不低于)：
                          {{ serviceTotalPackage[item.taxId].invoiceLadderPriceDetailVOList[j].rate || 0 }}%
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <el-form-item label="合作合同" required label-width="100px">
                  <div class="u-flex u-col-center">
                    <button-upload type="image/pdf" text="上传 jpg/pdf"
                      @upload-success="setTotalFiled('contract', k, $event)" />
                    <el-button v-if="!$u.test.isEmpty(item.contract)" class="u-m-l-10" type="text"
                      @click="$preViewer(item.contract)">查看</el-button>
                  </div>
                </el-form-item>
                <el-form-item label-width="100px">
                  <el-checkbox-group v-model="item._payType"
                    @change="changeItemPayType(item, '_payType', 'crowdList', $event)">
                    <el-checkbox label="offline" disabled>线下支付</el-checkbox>
                    <el-checkbox label="unionPay">银联支付</el-checkbox>
                    <!-- 先查询接口，服务商有没有使用招行 -->
                    <el-checkbox label="isTaxZspay">招行支付</el-checkbox>
                    <el-checkbox label="isTaxAlipay">支付宝</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <template v-if="item._payType.indexOf('unionPay') !== -1">
                  <el-form-item required label="银联支付银行" label-width="120px">
                    <el-checkbox-group v-if="serviceUnionType[item.taxId]" v-model="item.unionpayBankTypeList"
                      @change="changeItemPayType(item, 'unionpayBankTypeList', 'crowdList', $event)">
                      <el-checkbox v-for="unionpayBank in serviceUnionType[item.taxId]" :key="unionpayBank"
                        :label="unionpayBank">{{ unionpayBank | unionpayBankType }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </template>
              </div>
            </div>
            <div class="u-point" style="display: inline-block;margin-top: 100px" @click="addTotalList">
              <i class="el-icon-circle-plus-outline u-font-30" />
              <div class="u-m-t-5">添加</div>
            </div>
          </div>
        </div>
        <!-- 众包 -->
        <div class="coop-box u-m-l-60 u-m-t-40" :class="crowdChecked ? '' : 'box-uncheck'">
          <el-checkbox v-model="crowdChecked">合作类型：众包(3%普票)</el-checkbox>
          <div class="u-flex u-col-top u-row-left u-flex-wrap">
            <div v-for="(item, k) in crowdList" :key="item.guid" class="u-m-t-30 u-p-r-20" style="width: 45%">
              <div class="u-border u-p-20" style="position: relative">
                <i v-if="k !== 0" class="el-icon-remove-outline text-danger u-font-18 u-point"
                  style="position: absolute;right: 5px;top: 5px" @click="removeCrowdList(k)" />
                <el-form-item label="平台服务商" required label-width="100px">
                  <el-select v-model="item.taxId" placeholder="平台服务商" clearable filterable
                    @change="changeCrowdService(k, $event)">
                    <el-option v-for="serviceItem in crowdServiceList" :key="serviceItem.id" :label="serviceItem.taxName"
                      :value="serviceItem.id" />
                  </el-select>
                </el-form-item>
                <template v-if="serviceCrowdPackage[item.taxId]">
                  <el-form-item label="综合税费率" required label-width="100px">
                    <el-radio-group v-model="item.chargeStatus">
                      <el-radio v-if="serviceCrowdPackage[item.taxId].taxPrice" :label="0">一口价</el-radio>
                      <el-radio v-if="serviceCrowdPackage[item.taxId].invoiceLadderPriceDetailVOList"
                        :label="1">梯度价</el-radio>
                    </el-radio-group>
                  </el-form-item>
                  <el-form-item v-if="item.chargeStatus === 0" label-width="100px">
                    <el-input v-model.number="item.serviceCharge" type="number" min="0"
                      @blur="checkCrowdItem(item, serviceCrowdPackage[item.taxId].taxPrice || 0, $event)">
                      <span slot="suffix" style="line-height: 40px">%</span>
                    </el-input>
                    <div class="u-tips">参考值(不低于)：{{ serviceCrowdPackage[item.taxId] ?
                      (serviceCrowdPackage[item.taxId].taxPrice || 0) : 0 }}%</div>
                  </el-form-item>
                  <el-form-item v-else label-width="100px">
                    <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                      <div class="u-flex-2">单人单月额度</div>
                      <div class="u-flex-1">服务费率</div>
                      <div style="width: 50px" />
                    </div>
                    <div v-if="item.addCompanyLadderServiceDtoList">
                      <div v-for="(i, j) in item.addCompanyLadderServiceDtoList" :key="i.guid" class="u-m-t-10">
                        <div class="u-flex u-col-center u-row-center u-m-t-10">
                          <div class="u-flex-2 u-flex u-col-center u-row-center">
                            <el-input v-model="i.startMoney" readonly placeholder="请输入">
                              <span slot="suffix" style="line-height: 40px">元</span>
                            </el-input>
                            <span class="u-p-lf-5">至</span>
                            <el-input v-model="i.endMoney" readonly placeholder="请输入">
                              <span slot="suffix" style="line-height: 40px">元</span>
                            </el-input>
                          </div>
                          <div class="u-flex-1 u-p-l-10">
                            <el-input v-model="i.serviceCharge" placeholder="请输入"
                              @blur="checkTotalItem(i, serviceCrowdPackage[item.taxId].invoiceLadderPriceDetailVOList[j].rate || 0, $event)">
                              <span slot="suffix" style="line-height: 40px">%</span>
                            </el-input>
                          </div>
                        </div>
                        <div class="u-tips u-text-right">参考值(不低于)：
                          {{ serviceCrowdPackage[item.taxId].invoiceLadderPriceDetailVOList[j].rate || 0 }}%
                        </div>
                      </div>
                    </div>
                  </el-form-item>
                </template>
                <el-form-item label="合作合同" required label-width="100px">
                  <div class="u-flex u-col-center">
                    <button-upload type="image/pdf" text="上传 jpg/pdf"
                      @upload-success="setCrowdFiled('contract', k, $event)" />
                    <el-button v-if="!$u.test.isEmpty(item.contract)" class="u-m-l-10" type="text"
                      @click="$preViewer(item.contract)">查看</el-button>
                  </div>
                </el-form-item>
                <el-form-item label-width="100px">
                  <el-checkbox-group v-model="item._payType"
                    @change="changeItemPayType(item, '_payType', 'totalList', $event)">
                    <el-checkbox label="offline" disabled>线下支付</el-checkbox>
                    <el-checkbox label="unionPay">银联支付</el-checkbox>
                    <el-checkbox label="isTaxZspayCrowd">招行支付</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <template v-if="item._payType.indexOf('unionPay') !== -1">
                  <el-form-item required label="银联支付银行" label-width="120px">
                    <el-checkbox-group v-if="serviceUnionType[item.taxId]" v-model="item.unionpayBankTypeList"
                      @change="changeItemPayType(item, 'unionpayBankTypeList', 'totalList', $event)">
                      <el-checkbox v-for="unionpayBank in serviceUnionType[item.taxId]" :key="unionpayBank"
                        :label="unionpayBank">{{ unionpayBank | unionpayBankType }}</el-checkbox>
                    </el-checkbox-group>
                  </el-form-item>
                </template>
              </div>
            </div>
            <div class="u-point" style="display: inline-block;margin-top: 100px" @click="addCrowdList">
              <i class="el-icon-circle-plus-outline u-font-30" />
              <div class="u-m-t-5">添加</div>
            </div>
          </div>
        </div>
      </el-card>
      <el-card header="开票信息" class="u-m-t-20">
        <el-form-item label="公司名称" prop="addCompanyInvoiceInfoDto.companyName">
          <el-input v-model="formData.addCompanyInvoiceInfoDto.companyName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="addCompanyInvoiceInfoDto.taxCode">
          <el-input v-model="formData.addCompanyInvoiceInfoDto.taxCode" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="地址、电话" prop="addCompanyInvoiceInfoDto.addressAndTelephone">
          <el-input v-model="formData.addCompanyInvoiceInfoDto.addressAndTelephone" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="开户行及账号" prop="addCompanyInvoiceInfoDto.bankAndAccount">
          <el-input v-model="formData.addCompanyInvoiceInfoDto.bankAndAccount" placeholder="请输入" />
        </el-form-item>
      </el-card>
      <el-card header="联系人" class="u-m-t-20">
        <link-edit v-if="id" :merchant-id="id" :company-id="companyId" />
        <template v-else>
          <el-form-item label="姓名" prop="addLinkmanDto.linkName">
            <el-input v-model="formData.addLinkmanDto.linkName" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="职位" prop="addLinkmanDto.post">
            <el-input v-model="formData.addLinkmanDto.post" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="手机号" prop="addLinkmanDto.linkMobile">
            <el-input v-model="formData.addLinkmanDto.linkMobile" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="邮箱" prop="addLinkmanDto.email">
            <el-input v-model="formData.addLinkmanDto.email" placeholder="请输入" />
          </el-form-item>
        </template>
      </el-card>
      <el-card header="快递信息" class="u-m-t-20">
        <address-edit v-if="id" :merchant-id="id" />
        <template v-else>
          <el-form-item label="收件人" prop="addressDto.linkName">
            <el-input v-model="formData.addressDto.linkName" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="电话/手机号" prop="addressDto.linkMobile">
            <el-input v-model="formData.addressDto.linkMobile" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="地址" prop="addressDto.addressName">
            <el-input v-model="formData.addressDto.addressName" placeholder="请输入" />
          </el-form-item>
        </template>
      </el-card>
      <el-card header="账户信息" class="u-m-t-20">
        <el-form-item label="姓名" prop="addMerchantDto.realName">
          <el-input v-model="formData.addMerchantDto.realName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="登录账号" prop="addMerchantDto.userName">
          <el-input v-model="formData.addMerchantDto.userName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="绑定手机号" prop="addMerchantDto.loginMobile">
          <el-input v-model="formData.addMerchantDto.loginMobile" placeholder="请输入" />
        </el-form-item>
        <el-form-item v-if="isEdit" label="修改密码">
          <el-switch v-model="isShowPwd" />
        </el-form-item>
        <el-form-item v-if="isShowPwd" label="密码" :prop="isEdit ? 'none' : 'addMerchantDto.passWord'">
          <el-input v-model="formData.addMerchantDto.passWord" type="text" placeholder="请输入" class="no-autofill-pwd"
            autocomplete="new-password" />
        </el-form-item>
        <el-form-item v-if="isShowPwd" label="确认密码">
          <el-input v-model="repassWord" placeholder="请输入" type="text" class="no-autofill-pwd"
            autocomplete="new-password" />
        </el-form-item>
        <el-form-item v-if="isEdit" label="修改支付密码">
          <el-switch v-model="isShowPayPwd" />
        </el-form-item>
        <el-form-item v-if="isShowPayPwd" label="支付密码" :prop="isEdit ? 'none' : 'addMerchantDto.payPwd'">
          <el-input v-model="formData.addMerchantDto.payPwd" type="text" class="no-autofill-pwd"
            autocomplete="new-password" placeholder="请输入" />
        </el-form-item>
        <el-form-item v-if="isShowPayPwd" label="确认支付密码">
          <el-input v-model="repayPwd" type="text" class="no-autofill-pwd" autocomplete="new-password"
            placeholder="请输入" />
        </el-form-item>
      </el-card>
    </el-form>
    <div class="wrap-fixed-footer btn--box">
      <el-button v-loading="loading" size="small" type="primary" @click="submit('formData')">确定</el-button>
      <el-button size="small" @click="$router.go(-1)">取消</el-button>
    </div>
  </div>
</template>

<script>
import ButtonUpload from "@/components/Upload/buttonUpload";
import { getGuid } from "@/utils";
import {
  add,
  edit,
  getDetail,
  getServiceList,
  getServicePackage,
} from "@/api/shop";
import { queryTaxZspayMethod } from "@/api/service";
import { getAgentList, getAssistantList, getBankList } from "@/api/common";
import { getUnionPayType, getAlipayMethod } from "@/api/service";
import AddressEdit from "@/views/shop/components/addressEdit";
import LinkEdit from "@/views/shop/components/linkEdit";

function defaultForm() {
  return {
    addCompanyInvoiceInfoDto: {
      addressAndTelephone: "",
      bankAndAccount: "",
      companyName: "",
      taxCode: "",
    },
    addLinkmanDto: {
      email: "",
      isNot: 0,
      linkMobile: "",
      linkName: "",
      post: "",
    },
    addMerchantDto: {
      loginMobile: "",
      passWord: "",
      payPwd: "",
      realName: "",
      userName: "",
    },
    addressDto: {
      addressName: "",
      isNot: 0,
      linkMobile: "",
      linkName: "",
    },
    agentId: "",
    businessLicense: "",
    workRules: "NEEDREVIEW",
    // 'relevance': 1,
    companyCreateDate: "",
    companyMan: "",
    bankName: "",
    bankCode: "",
    inBankCode: "",
    titleOfAccount: "",
    companyName: "",
    contract: "",
    creditCode: "",
    registeredCapital: 0,
    salesManId: "",
  };
}
export default {
  name: "ShopAdd",
  components: { LinkEdit, AddressEdit, ButtonUpload },
  data() {
    const formData = defaultForm();
    const validateNumber = (rule, value, callback) => {
      if (
        this.$u.test.isEmpty(value) ||
        (typeof value === "number" && value >= 0)
      ) {
        callback();
      } else {
        callback(new Error("请输入大于等于0的数字"));
      }
    };
    return {
      isShowPwd: true,
      isShowPayPwd: true,
      id: undefined,
      isEdit: false,
      bankOptions: [],
      isTaxZspay: false,
      isTaxZspayCrowd: false,
      serviceUnionType: {}, // 服务商可用银联
      serviceTotalPackage: {}, // 服务商可用合作信息
      serviceCrowdPackage: {}, // 服务商可用合作信息
      serviceAliType: {}, // 服务商可用支付宝
      companyId: "",
      repassWord: "",
      repayPwd: "",
      loading: false,
      totalChecked: false,
      crowdChecked: false,
      totalServiceList: [],
      crowdServiceList: [],
      assistantList: [],
      agentList: [],
      totalList: [], // 总包+分包
      totalLadders: {},
      crowdList: [], // 众包
      formData: Object.assign({}, formData),
      rules: {
        companyName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: ["change", "blur"],
          },
        ],
        companyMan: [
          {
            required: true,
            message: "请输入法定代表人",
            trigger: ["change", "blur"],
          },
        ],
        registeredCapital: [
          {
            type: "number",
            required: true,
            message: "请输入注册资本",
            trigger: ["change", "blur"],
          },
          { validator: validateNumber, trigger: ["change", "blur"] },
        ],
        companyCreateDate: [
          {
            required: true,
            message: "请选择成立日期",
            trigger: ["change", "blur"],
          },
        ],
        creditCode: [
          {
            required: true,
            message: "请输入社会统一信用代码",
            trigger: ["change", "blur"],
          },
        ],
        salesManId: [
          {
            required: true,
            message: "请选择平台业务员",
            trigger: ["change", "blur"],
          },
        ],
        bankName: [
          { required: true, message: "请输入开户行", trigger: ["blur"] },
        ],
        bankCode: [
          { required: true, message: "请输入银行卡号", trigger: ["blur"] },
        ],
        titleOfAccount: [
          { required: true, message: "请输入账户名称", trigger: ["blur"] },
        ],
        "addCompanyInvoiceInfoDto.addressAndTelephone": [
          {
            required: true,
            message: "请输入地址、电话",
            trigger: ["change", "blur"],
          },
        ],
        "addCompanyInvoiceInfoDto.bankAndAccount": [
          {
            required: true,
            message: "请输入开户行及账号",
            trigger: ["change", "blur"],
          },
        ],
        "addCompanyInvoiceInfoDto.companyName": [
          {
            required: true,
            message: "请输入公司名称",
            trigger: ["change", "blur"],
          },
        ],
        "addCompanyInvoiceInfoDto.taxCode": [
          {
            required: true,
            message: "请输入纳税人识别号",
            trigger: ["change", "blur"],
          },
        ],
        "addLinkmanDto.linkName": [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["change", "blur"],
          },
        ],
        "addLinkmanDto.linkMobile": [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.isEmpty(value) && !this.$u.test.mobile(value)) {
                callback(new Error("手机号码不正确"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        "addressDto.linkName": [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["change", "blur"],
          },
        ],
        "addressDto.linkMobile": [
          {
            required: true,
            message: "请输入手机号",
            trigger: ["change", "blur"],
          },
        ],
        "addressDto.addressName": [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["change", "blur"],
          },
        ],
        "addMerchantDto.realName": [
          {
            required: true,
            message: "请输入姓名",
            trigger: ["change", "blur"],
          },
        ],
        "addMerchantDto.userName": [
          {
            required: true,
            message: "请输入登录账号",
            trigger: ["change", "blur"],
          },
        ],
        "addMerchantDto.loginMobile": [
          {
            required: true,
            message: "请输入绑定手机号",
            trigger: ["change", "blur"],
          },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.isEmpty(value) && !this.$u.test.mobile(value)) {
                callback(new Error("手机号码不正确"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        "addMerchantDto.passWord": [
          {
            required: true,
            message: "请输入登录密码",
            trigger: ["change", "blur"],
          },
        ],
        "addMerchantDto.payPwd": [
          {
            required: true,
            message: "请输入支付密码",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  computed: {
    // 1.代理商 2.业务员 3.平台管理员
    userSign() {
      const userSign = this.$store.getters.userinfo.userSign || 3;
      return +userSign;
    },
  },
  created() {
    this.fetchService();
    this.fetchAgent();
    this.fetchAssistant();
    this.fetchDict();
    if (this.$route.query.id) {
      this.isShowPwd = false;
      this.isShowPayPwd = false;
      this.isEdit = this.$route.meta.isEdit;
      this.id = this.$route.query.id;
      this.fetchData();
    } else {
      // this.addCrowdList()
      this.addTotalList();
    }
  },
  methods: {
    fetchDict() {
      getBankList({
        code: "bank",
      }).then((res) => {
        this.bankOptions = res.data || [];
      });
    },
    /**
     * 同步总包/众包支付状态方法
     * @param data 赋值总数据
     * @param field 赋值字段
     * @param toData 目标数据 crowdList/totalList
     * @param value 值
     */
    changeItemPayType(data, field, toData, value) {
      console.log(value, 'value');
      if (!this.$u.test.isEmpty(data.taxId)) {
        if (this[toData]) {
          this[toData].forEach((val) => {
            if (val.taxId === data.taxId) {
              val[field] = value;
            }
          });
        }
        if (value.includes("isTaxZspay") || value.includes("isTaxZspayCrowd")) {
          data.zspayBank = true;
        } else {
          data.zspayBank = false;
        }
        if (value.includes("isTaxAlipay")) {
          if (this.serviceUnionType[data.taxId] && this.serviceUnionType[data.taxId].includes("PABK")) {
            data.unionpayBankTypeList = ['PABK']
          } else {
            return this.$message.error('该服务商暂未开通银联平安支付')
          }
          data.alipay = true;
          if (!value.includes("isTaxAlipay")) {
            data._payType.push('isTaxAlipay')
          }
          if (!value.includes("unionPay")) {
            data._payType.push('unionPay')
          }
          // this.$forceUpdate()
          console.log(data._payType, 'paytype')
        } else {
          data.alipay = false;
        }
      }
    },
    /**
     * 获取已有的总包/众包支付状态并赋值
     * @param taxId 服务商id
     * @param toData 目标数据 crowdList/totalList
     */
    getItemPayType(taxId, toData) {
      const data = {
        unionpayBankTypeList: [],
        inBankNo: "",
        _payType: ["offline"],
      };
      if (!this.$u.test.isEmpty(taxId)) {
        if (this[toData]) {
          this[toData].every((val) => {
            if (val.taxId === taxId) {
              data.unionpayBankTypeList = val.unionpayBankTypeList;
              data.inBankNo = val.inBankNo;
              data._payType = val._payType;
              return false;
            }
            return true;
          });
        }
      }
      return Object.assign({}, data);
    },
    _setServiceUnionType(taxId) {
      if (this.$u.test.isEmpty(taxId)) {
        return;
      }
      if (!this.serviceUnionType[taxId]) {
        getUnionPayType({
          taxId: taxId,
        })
          .then((res) => {
            this.serviceUnionType[taxId] = res.data || [];
            console.log(this.serviceUnionType, 'this.serviceUnionType');
          })
          .catch((res) => { });
        getAlipayMethod({
          taxId: taxId,
        })
          .then((res) => {
            this.serviceAliType[taxId] = res.data || [];
            console.log(this.serviceAliType, 'this.serviceAliType');
          })
          .catch((res) => { });
      }
    },
    _setServiceZhaoShang(taxId, type) {
      if (this.$u.test.isEmpty(taxId)) {
        return;
      }
      // if (!this.serviceUnionType[taxId]) {
      queryTaxZspayMethod({
        taxId: taxId,
      })
        .then((res) => {
          console.log("queryTaxZspayMethod返回:", res);
          if (type === "crowd") {
            this.isTaxZspayCrowd = res.obj;
          } else {
            this.isTaxZspay = res.obj;
          }
        })
        .catch((res) => { });
      // }
    },
    // 验证总包数据
    checkTotalItem(item, validata, e) {
      validata = validata || 0;
      if (!this.$u.test.validateRange(item.serviceCharge, validata + "~")) {
        e.target.style.borderColor = "#f56c6c";
        return this.$message.error("输入数据需不低于" + validata);
      } else {
        e.target.style.borderColor = "#DCDFE6";
      }
    },
    async _setTotalServicePackage(taxId, index) {
      if (this.$u.test.isEmpty(taxId)) {
        return;
      }
      if (!this.serviceTotalPackage[taxId]) {
        await getServicePackage({
          agentId: this.$u.test.isEmpty(this.formData.agentId)
            ? undefined
            : this.formData.agentId,
          taxId: taxId,
          packageStatus: 0,
        })
          .then((res) => {
            this.serviceTotalPackage = Object.assign(
              {},
              this.serviceTotalPackage,
              { [taxId]: res.obj || {} }
            );
          })
          .catch((res) => { });
      }
      const list =
        this.serviceTotalPackage[taxId].invoiceLadderPriceDetailVOList || [];
      const totalList = [];
      list.map((val) => {
        totalList.push(
          this.addLadderItem({
            endMoney: val.endMoney || null,
            serviceCharge: val.rate || null,
            startMoney: val.startMoney || null,
          })
        );
      });
      this.totalList[index].addCompanyLadderServiceDtoList = totalList;
    },
    // 验证众包数据
    checkCrowdItem(item, validata, e) {
      validata = validata || 0;
      if (!this.$u.test.validateRange(item.serviceCharge, validata + "~")) {
        e.target.style.borderColor = "#f56c6c";
        return this.$message.error("输入数据需不低于" + validata);
      } else {
        e.target.style.borderColor = "#DCDFE6";
      }
    },
    async _setCrowdServicePackage(taxId, index) {
      if (this.$u.test.isEmpty(taxId)) {
        return;
      }
      if (!this.serviceCrowdPackage[taxId]) {
        await getServicePackage({
          agentId: this.$u.test.isEmpty(this.formData.agentId)
            ? undefined
            : this.formData.agentId,
          taxId: taxId,
          packageStatus: 1,
        })
          .then((res) => {
            this.serviceCrowdPackage = Object.assign(
              {},
              this.serviceCrowdPackage,
              { [taxId]: res.obj || {} }
            );
          })
          .catch((res) => { });
      }
      const list =
        this.serviceCrowdPackage[taxId].invoiceLadderPriceDetailVOList || [];
      const crowdList = [];
      list.map((val) => {
        crowdList.push(
          this.addLadderItem({
            endMoney: val.endMoney || null,
            serviceCharge: val.rate || null,
            startMoney: val.startMoney || null,
          })
        );
      });
      this.crowdList[index].addCompanyLadderServiceDtoList = crowdList;
    },
    changeTotalService(index, val) {
      // 获取服务商拥有的银联方式
      this._setServiceUnionType(val);
      this._setServiceZhaoShang(val, "total");
      // 获取服务商合作信息
      this._setTotalServicePackage(val, index);
      const { unionpayBankTypeList, inBankNo, _payType } = this.getItemPayType(
        val,
        "crowdList"
      );
      this.totalList[index].unionpayBankTypeList = unionpayBankTypeList;
      this.totalList[index].inBankNo = inBankNo;
      this.totalList[index]._payType = _payType;
      // const list = this.totalServiceList || []
      // for (let i = 0, len = list.length; i < len; i++) {
      //   if (list[i].id === val) {
      //     const { bankCode = '', bankName = '' } = list[i]
      //     this.totalList[index].bankCode = bankCode
      //     this.totalList[index].bankName = bankName
      //     break
      //   }
      // }
    },
    changeCrowdService(index, val) {
      this._setServiceUnionType(val);
      this._setServiceZhaoShang(val, "crowd");
      this._setCrowdServicePackage(val, index);
      const { unionpayBankTypeList, inBankNo, _payType } = this.getItemPayType(
        val,
        "totalList"
      );
      this.crowdList[index].unionpayBankTypeList = unionpayBankTypeList;
      this.crowdList[index].inBankNo = inBankNo;
      this.crowdList[index]._payType = _payType;
      // const list = this.crowdServiceList || []
      // for (let i = 0, len = list.length; i < len; i++) {
      //   if (list[i].id === val) {
      //     const { bankCode = '', bankName = '' } = list[i]
      //     this.crowdList[index].bankCode = bankCode
      //     this.crowdList[index].bankName = bankName
      //     break
      //   }
      // }
    },
    fetchData() {
      this.loading = true;
      // 编辑页面时，读取服务器端的商户资料进行回显
      getDetail({
        merchantId: this.id,
      })
        .then((res) => {
          const data = res.obj || {};
          this.formData.addCompanyInvoiceInfoDto = Object.assign(
            {},
            this.formData.addCompanyInvoiceInfoDto,
            data.queryInvoiceInfoVo || {}
          );
          const companyInfoVoField = {
            businessLicense: "",
            companyCreateDate: "",
            companyMan: "",
            companyName: "",
            contract: "",
            creditCode: "",
            bankName: "",
            bankCode: "",
            titleOfAccount: "",
            workRules: "",
            // 'relevance': '',
            registeredCapital: 0,
          };
          Object.keys(companyInfoVoField).forEach((val) => {
            this.formData[val] = data.companyInfoVo[val] || "";
          });
          this.companyId = data.companyInfoVo.id || "";
          const cooperationInfoVoField = {
            agentId: "",
            salesManId: "",
            companyInfoId: "",
          };
          Object.keys(cooperationInfoVoField).forEach((val) => {
            this.formData[val] = data.queryCooperationInfoVo[val] || "";
          });
          this.formData.addMerchantDto = Object.assign(
            {},
            this.formData.addCompanyInvoiceInfoDto,
            data.queryMerchantInfoVo || {}
          );
          const taxIds = [];
          // debugger
          if (data.cooperationInfoVoList && data.cooperationInfoVoList.length) {
            const serviceTotalPackage = {};
            const serviceCrowdPackage = {};
            data.cooperationInfoVoList.forEach((val) => {
              if (taxIds.indexOf(val.taxId) === -1) {
                taxIds.push(val.taxId);
              }
              if (parseInt(val.packageStatus) === 0) {
                this.addTotalList(true, val);
                serviceTotalPackage[val.taxId] = {
                  taxPrice: val.taxPrice || null,
                  invoiceLadderPriceDetailVOList:
                    val.companyLadderServiceVos || null,
                };
              } else {
                this.addCrowdList(true, val);
                serviceCrowdPackage[val.taxId] = {
                  taxPrice: val.taxPrice || null,
                  invoiceLadderPriceDetailVOList:
                    val.companyLadderServiceVos || null,
                };
              }
            });
            this.serviceTotalPackage = serviceTotalPackage;
            this.serviceCrowdPackage = serviceCrowdPackage;
          }
          // 编辑时对服务商查询拥有的银联类型
          taxIds.forEach((val) => {
            this._setServiceUnionType(val);
          });
          this.formData.addMerchantDto.passWord = "";
          this.formData.addMerchantDto.payPwd = "";
          this.fetchAgent();
          this.loading = false;
        })
        .catch((res) => {
          this.loading = false;
        });
    },
    addLadderItem(val) {
      val = val || {};
      return {
        guid: getGuid(),
        companyTaxId: "",
        id: val.companyLadderServiceId || "",
        endMoney: val.endMoney || null,
        serviceCharge: val.serviceCharge || null,
        startMoney: val.startMoney || 0,
      };
    },
    addCrowdLadder(index) {
      const item = this.addLadderItem();
      const length =
        this.crowdList[index].addCompanyLadderServiceDtoList.length;
      if (length === 0) {
        item.startMoney = 0;
      } else {
        item.startMoney =
          this.crowdList[index].addCompanyLadderServiceDtoList[
            length - 1
          ].endMoney;
      }
      this.crowdList[index].addCompanyLadderServiceDtoList.push(item);
    },
    addCrowdList(edit, val) {
      this.crowdChecked = true;
      const addCompanyLadderServiceDtoList = [];
      if (edit === true) {
        val = val || {};
        if (val.companyLadderServiceVos) {
          val.companyLadderServiceVos.forEach((x) => {
            addCompanyLadderServiceDtoList.push(this.addLadderItem(x));
          });
        }
      } else {
        val = {};
        addCompanyLadderServiceDtoList.push(this.addLadderItem());
      }
      const unionpayBankTypeList = val.companyUnionpayBankTypeList || [];
      if (val.taxId) {
        this.serviceUnionType[val.taxId] = val.taxUnionpayBankTypeList || [];
      }
      const _payType = unionpayBankTypeList.length
        ? ["offline", "unionPay"]
        : ["offline"];
      this.crowdList.push({
        guid: getGuid(),
        addCompanyLadderServiceDtoList: addCompanyLadderServiceDtoList,
        id: val.id || "",
        chargeStatus: val.chargeStatus ? parseInt(val.chargeStatus) : 0,
        companyId: val.companyId || "",
        contract: val.contract || "",
        packageStatus: val.packageStatus || 1,
        serviceCharge: val.serviceCharge || 0,
        taxId: val.taxId || "",
        _payType: _payType, // 支付方式
        inBankNo: val.inBankNo || "",
        unionpayBankTypeList: unionpayBankTypeList,
      });
    },
    // 删除众包合作模块
    removeCrowdList(index) {
      this.crowdList.splice(index, 1);
    },
    setCrowdFiled(filed, index, data) {
      this.crowdList[index][filed] = data;
    },
    setTotalFiled(filed, index, data) {
      this.totalList[index][filed] = data;
    },
    // 删除众包合作模块梯度价
    removeCrowdLadder(pIndex, index) {
      this.crowdList[pIndex].addCompanyLadderServiceDtoList.splice(index, 1);
    },
    addTotalLadder(index) {
      const item = this.addLadderItem();
      const length =
        this.totalList[index].addCompanyLadderServiceDtoList.length;
      if (length === 0) {
        item.startMoney = 0;
      } else {
        item.startMoney =
          this.totalList[index].addCompanyLadderServiceDtoList[
            length - 1
          ].endMoney;
      }
      this.totalList[index].addCompanyLadderServiceDtoList.push(item);
    },
    addTotalList(edit, val) {
      this.totalChecked = true;
      const addCompanyLadderServiceDtoList = [];
      if (edit === true) {
        val = val || {};
        if (val.companyLadderServiceVos) {
          val.companyLadderServiceVos.forEach((x) => {
            addCompanyLadderServiceDtoList.push(this.addLadderItem(x));
          });
        }
      } else {
        val = {};
        addCompanyLadderServiceDtoList.push(this.addLadderItem());
      }
      const unionpayBankTypeList = val.companyUnionpayBankTypeList || [];
      if (val.taxId) {
        this.serviceUnionType[val.taxId] = val.taxUnionpayBankTypeList || [];
      }
      const _payType = unionpayBankTypeList.length
        ? ["offline", "unionPay"]
        : ["offline"];
      val.alipay ? _payType.push('isTaxAlipay') : ''
      this.totalList.push({
        guid: getGuid(),
        addCompanyLadderServiceDtoList: addCompanyLadderServiceDtoList,
        id: val.id || "",
        chargeStatus: val.chargeStatus ? parseInt(val.chargeStatus) : 0,
        companyId: val.companyId || "",
        contract: val.contract || "",
        packageStatus: val.packageStatus || 0,
        serviceCharge: val.serviceCharge || 0,
        taxId: val.taxId || "",
        _payType: _payType, // 支付方式
        alipay: val.alipay,
        inBankNo: val.inBankNo || "",
        unionpayBankTypeList: unionpayBankTypeList,
      });
    },
    // 删除总包合作模块
    removeTotalList(index) {
      this.totalList.splice(index, 1);
    },
    // 删除总包合作模块梯度价
    removeTotalLadder(pIndex, index) {
      this.totalList[pIndex].addCompanyLadderServiceDtoList.splice(index, 1);
    },
    /**
     * 检查合作信息，删除无效信息，(当服务商变动时)
     * @param type {string} total|crowd
     */
    checkLadders(type) {
      const serviceIds = []; // 现在存在的serviceId
      if (type === "total") {
        this.totalServiceList.map((val) => {
          serviceIds.push(val.id);
        });
        this.totalList = this.totalList.filter((val) => {
          return (
            serviceIds.indexOf(val.taxId) !== -1 ||
            this.$u.test.isEmpty(val.taxId)
          );
        });
      } else {
        this.crowdServiceList.map((val) => {
          serviceIds.push(val.id);
        });
        this.crowdList = this.crowdList.filter((val) => {
          return (
            serviceIds.indexOf(val.taxId) !== -1 ||
            this.$u.test.isEmpty(val.taxId)
          );
        });
      }
    },
    fetchService(e) {
      getServiceList({
        agentId: this.$u.test.isEmpty(this.formData.agentId)
          ? undefined
          : this.formData.agentId,
        packageStatus: 0,
      }).then((res) => {
        this.totalServiceList = res.data || [];
        if (e === "change") {
          this.checkLadders("total");
        }
      });
      getServiceList({
        agentId: this.$u.test.isEmpty(this.formData.agentId)
          ? undefined
          : this.formData.agentId,
        packageStatus: 1,
      }).then((res) => {
        this.crowdServiceList = res.data || [];
        if (e === "change") {
          this.checkLadders("crowd");
        }
      });
    },
    // 获取平台业务员
    fetchAssistant() {
      getAssistantList().then((res) => {
        const data = res.data || [];
        this.assistantList = data;
        if (!this.isEdit) {
          if (this.userSign === 2) {
            this.formData.salesManId = this.$store.getters.userinfo.id || "";
          }
          if (this.userSign === 1 && data.length) {
            this.formData.salesManId = data[0].id;
          }
        }
      });
    },
    // 获取代理商
    fetchAgent() {
      if (this.$u.test.isEmpty(this.formData.salesManId)) {
        return;
      }
      getAgentList({
        salesmanId: this.$u.test.isEmpty(this.formData.salesManId)
          ? undefined
          : this.formData.salesManId,
      }).then((res) => {
        this.agentList = res.data || [];
        if (!this.isEdit) {
          if (this.userSign === 1) {
            this.formData.agentId = this.$store.getters.userinfo.id || "";
          }
        }
      });
    },
    setFields(fields, val) {
      this.formData[fields] = val;
    },
    // 梯度价/一口价检测
    validateLadders(list) {
      let result = true;
      if (!list.length) {
        return {
          success: false,
          data: [],
        };
      }
      const error = {
        msg: "数据错误",
        index: null,
      };
      const newData = [];
      for (let i = 0, len = list.length; i < len; i++) {
        const listItem = list[i];
        if (this.$u.test.isEmpty(listItem.taxId)) {
          error.msg = "请选择平台服务商";
          error.index = i;
          result = false;
          break;
        }
        if (this.$u.test.isEmpty(listItem.contract)) {
          error.msg = "请上传合作合同";
          error.index = i;
          result = false;
          break;
        }
        if (listItem._payType.indexOf("unionPay") !== -1) {
          if (
            listItem.unionpayBankTypeList &&
            !listItem.unionpayBankTypeList.length
          ) {
            error.msg = "请选择支付银行";
            error.index = i;
            result = false;
            break;
          }
        } else {
          // 线下，需要清洗银联数据
          listItem.unionpayBankTypeList = [];
        }
        // if (this.$u.test.isEmpty(listItem.bankName)) {
        //   error.msg = '请输入开户行'
        //   error.index = i
        //   result = false
        //   break
        // }
        // if (this.$u.test.isEmpty(listItem.bankCode)) {
        //   error.msg = '请输入银行卡号'
        //   error.index = i
        //   result = false
        //   break
        // }
        const newItem = []; // 用于存放梯度价
        if (parseInt(listItem.chargeStatus) === 1) {
          const ladders = listItem.addCompanyLadderServiceDtoList || [];
          // 梯度价检测
          if (!ladders.length) {
            result = false;
            break;
          }
          for (let j = 0, jlen = ladders.length; j < jlen; j++) {
            const item = ladders[j];
            if (!this.$u.test.validateRange(item.serviceCharge, "0~100")) {
              result = false;
              break;
            }
            if (!this.$u.test.validateRange(item.startMoney, "0~")) {
              result = false;
              break;
            }
            item.startMoney = parseFloat(item.startMoney);
            if (
              !this.$u.test.validateRange(item.endMoney, item.startMoney + "~")
            ) {
              result = false;
              break;
            }
            item.endMoney = parseFloat(item.endMoney);
            item.serviceCharge = parseFloat(item.serviceCharge);
            newItem.push(item);
          }
          if (!result) {
            error.msg = "梯度价不正确";
            error.index = i;
            break;
          }
          // 不设置一口价
          listItem.serviceCharge = "";
        } else {
          // 一口价检测
          if (!this.$u.test.validateRange(listItem.serviceCharge, "0~100")) {
            error.msg = "一口价不正确";
            error.index = i;
            result = false;
            break;
          }
          listItem.serviceCharge = parseFloat(listItem.serviceCharge);
        }

        listItem.addCompanyLadderServiceDtoList = newItem;
        newData.push(listItem);
      }
      return {
        success: result,
        data: newData,
        error: error,
      };
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData);
          if (this.repassWord !== (form.addMerchantDto.passWord || "")) {
            return this.$message.error("两次登录密码不一致");
          }
          if (this.$u.test.isEmptyNotZero(form.addMerchantDto.passWord)) {
            form.addMerchantDto.passWord = undefined;
          }
          if (this.repayPwd !== (form.addMerchantDto.payPwd || "")) {
            return this.$message.error("两次支付密码不一致");
          }
          if (this.$u.test.isEmptyNotZero(form.addMerchantDto.payPwd)) {
            form.addMerchantDto.payPwd = undefined;
          }
          form.companyTaxDtos = [];
          if (this.totalChecked) {
            const totalValidate = this.validateLadders(this.totalList);
            if (!totalValidate.success) {
              return this.$message.error(
                "总包" + totalValidate.error.msg + "！"
              );
            }
            form.companyTaxDtos = form.companyTaxDtos.concat(
              totalValidate.data
            );
          }
          if (this.crowdChecked) {
            const crowdValidate = this.validateLadders(this.crowdList);
            if (!crowdValidate.success) {
              return this.$message.error(
                "众包" + crowdValidate.error.msg + "！"
              );
            }
            form.companyTaxDtos = form.companyTaxDtos.concat(
              crowdValidate.data
            );
          }
          let inBankCode;
          this.bankOptions.every((val) => {
            if (val.dictValue === form.bankName) {
              inBankCode = val.dictKey;
              return false;
            }
            return true;
          });
          if (inBankCode) {
            form.inBankCode = inBankCode;
          }
          this.loading = true;
          if (this.id) {
            const updateCompanyTaxDtoList = form.companyTaxDtos.map((o) => {
              const newO = Object.assign({}, o);
              console.log(newO, 'ooo');
              newO.updateCompanyLadderServiceDtoList =
                o.addCompanyLadderServiceDtoList;
              delete newO.addCompanyLadderServiceDtoList;
              return newO;
            });
            const editForm = {
              updateCompanyInfoDto: {
                businessLicense: form.businessLicense,
                companyCreateDate: form.companyCreateDate,
                companyMan: form.companyMan,
                companyName: form.companyName,
                contract: form.contract,
                creditCode: form.creditCode,
                registeredCapital: form.registeredCapital,
                id: this.id,
                bankCode: form.bankCode,
                inBankCode: form.inBankCode,
                bankName: form.bankName,
                titleOfAccount: form.titleOfAccount,
                workRules: form.workRules,
                // relevance: form.relevance
              },
              updateCompanyTaxDtoList: updateCompanyTaxDtoList,
              updateCooperationDto: {
                agentId: form.agentId,
                salesManId: form.salesManId,
              },
              updateMerchantInfDto: form.addMerchantDto,
              updetaInvoiceInfoDto: form.addCompanyInvoiceInfoDto,
            };
            console.log(editForm, 'editForm');
            edit(editForm)
              .then((res) => {
                this.$message.success(res.message);
                this.loading = false;
                this.$router.go(-1);
              })
              .catch((res) => {
                this.loading = false;
              });
          } else {
            add(form)
              .then((res) => {
                this.$message.success(res.message);
                this.loading = false;
                this.$router.go(-1);
              })
              .catch((res) => {
                this.loading = false;
              });
          }
        } else {
          this.$message.error("表单存在错误，请核对后提交");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.u-form {
  font-size: 14px;

  ::v-deep {
    .el-form-item__content {
      max-width: 480px;
    }
  }
}

.box-uncheck {
  opacity: 0.3;
}

.u-tips {
  line-height: 2;
  font-size: 12px;
  color: #909399;
}

.error-line {
  ::v-deep {
    .el-input__inner {
      border-color: #f56c6c;
    }
  }
}
</style>
