<template>
  <div class="u-m-25 u-p-30 bg-white">
    <el-form ref="formData" v-loading="loading" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
      <el-form-item label="姓名" prop="realName">
        <el-input v-model="formData.realName" placeholder="请输入姓名" />
      </el-form-item>
      <el-form-item label="角色" prop="roleName">
        <el-select v-model="formData.roleName" filterable placeholder="请选择">
          <el-option v-for="(i, k) in roleList" :key="k" :label="i" :value="i" />
        </el-select>
      </el-form-item>
      <el-form-item label="手机号码" prop="mobileCode">
        <el-input v-model="formData.mobileCode" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item label="登录账号" prop="userName">
        <el-input v-model="formData.userName" placeholder="请输入登录账号" />
      </el-form-item>
      <el-form-item label="权限" required>
        <div class="u-border u-p-20">
          <el-tree
            ref="tree"
            v-loading="treeLoading"
            :data="menus"
            show-checkbox
            node-key="id"
            :default-checked-keys="menuId"
            :props="defaultProps"
          />
        </div>
      </el-form-item>
      <el-form-item v-if="!formData.id" label="初始密码" prop="passWord">
        <el-input v-model="formData.passWord" type="text" class="no-autofill-pwd" placeholder="请输入初始密码" autocomplete="new-password" />
      </el-form-item>
      <el-form-item v-else label="修改密码">
        <el-input v-model="formData.passWord" type="text" class="no-autofill-pwd" placeholder="为空则不修改" autocomplete="new-password" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('formData')">确定</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getAuthDetail, updateAuth, getMenus } from '@/api/auth'

const defaultForm = () => {
  return {
    'id': '',
    'menuIds': '',
    'mobileCode': '',
    'passWord': '',
    'realName': '',
    'roleName': '',
    'userName': ''
  }
}

export default {
  data() {
    const DefaultForm = defaultForm()
    return {
      defaultProps: {
        children: 'list',
        label: 'menuZhname'
      },
      loading: false,
      roleList: [
        '管理员', '运营', '产品', '财务', '出纳', '测试'
      ],
      treeLoading: false,
      menus: [],
      menuId: [],
      formData: Object.assign({}, DefaultForm),
      rules: {
        realName: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        roleName: [
          { required: true, message: '请选择职位', trigger: 'change' }
        ],
        userName: [
          { required: true, message: '请输入登录账号', trigger: 'blur' }
        ],
        mobileCode: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.mobile(value)) {
                callback(new Error('手机号码不正确'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ],
        passWord: [
          { required: true, message: '请输入登录密码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.fetchMenus()
    if (this.$route.query.id) {
      this.fetchData(this.$route.query.id)
    }
  },
  methods: {
    fetchMenus() {
      this.treeLoading = true
      getMenus().then(res => {
        this.menus = res.data || []
        this.treeLoading = false
      }).catch(res => {
        this.treeLoading = false
      })
    },
    fetchData(id) {
      this.loading = true
      getAuthDetail({
        managersId: id
      }).then(res => {
        const data = res.obj || {}
        const list = data.list || []
        const menuId = []
        list.forEach(val => {
          menuId.push(val.id)
        })
        this.menuId = menuId
        delete data.list
        const DefaultForm = defaultForm()
        this.formData = Object.assign({}, DefaultForm, {
          'mobileCode': data.loginMoblie,
          'realName': data.realNmae,
          'roleName': data.roleName,
          'userName': data.userName,
          id: data.managersId
        })
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          const selectMenus = this.$refs.tree.getCheckedNodes()
          const menuIds = []
          for (let i = 0, len = selectMenus.length; i < len; i++) {
            menuIds.push(selectMenus[i].id)
          }
          form.menuIds = menuIds.join(',')
          const loading = this.$loading()
          updateAuth(form).then(res => {
            this.$message.success(res.message)
            loading.close()
            this.$router.go(-1)
          }).catch(res => {
            loading.close()
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
