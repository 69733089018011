<template>
  <div :class="!isComponent ? 'u-p-20 wrap-fixed-content' : ''">
    <el-card v-if="(+baseInfo.paymentOrderStatus) === 5" style="border: 1px solid #FF1A2E" class="u-m-b-10">
      <div class="u-flex u-col-center u-p-lf-40">
        <div class="u-text-center">
          <i class="el-icon-error" style="font-size: 72px;color: #FF1A2E" />
          <div class="u-m-t-10" style="font-size: 24px;">被驳回</div>
        </div>
        <div class="u-m-l-20 u-p-l-40 u-flex" style="width: 100%">
          <div class="u-flex-1">
            <div class="u-tips-color u-font-14 u-m-l-20">请核对并修改以下信息后，再重新提交。</div>
            <div style="background-color: #fafafa" class="u-p-20 u-m-t-10">
              <div class="u-font-16 u-m-b-10">您提交的内容有如下问题：</div>
              <div class="u-font-14" style="color: #8e9193">{{ baseInfo.reasonsForRejection }}</div>
            </div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-loading="loading">
      <div class="card--title">众包信息</div>
      <div class="card--content">
        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">支付编号：</span>
            <span class="value">{{ baseInfo.paymentOrderId }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">商户：</span>
            <span class="value">{{ baseInfo.companyName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">平台服务商：</span>
            <span class="value">{{ baseInfo.platformServiceProvider }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">项目合同：</span>
            <span class="value">
              <file-icon :url="baseInfo.companyContract" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付清单：</span>
            <span class="value">
              <file-icon :url="baseInfo.paymentInventory" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">关联任务：</span>
            <span class="value">
              <el-button v-if="!$u.test.isEmpty(baseInfo.taskId)" type="text" @click="showTask(baseInfo.taskId)">查看</el-button>
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">交付支付验收单：</span>
            <span class="value">
              <file-icon :url="baseInfo.acceptanceCertificate" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">状态：</span>
            <span class="value" v-html="$options.filters.filterContent(baseInfo.paymentOrderStatus, $enum.payCrowdStatus())" />
          </el-col>
          <el-col :md="8">
            <span class="label">创建时间：</span>
            <span class="value">{{ baseInfo.creatDate }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付时间：</span>
            <span class="value">{{ baseInfo.paymentDate }}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card v-loading="loading" class="u-m-t-20">
      <div class="card--title">支付清单明细</div>
      <div class="card--content">
        <el-button type="primary" :loading="loading" @click="downloadExcel">导出Excel</el-button>
        <el-table ref="multipleTable" v-loading="loading" :data="list" max-height="600px" :summary-method="getSummaries" show-summary tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" />
          <el-table-column prop="workerName" label="姓名" width="100" align="center" />
          <el-table-column prop="mobileCode" width="120" label="电话" align="center" />
          <el-table-column prop="idCardCode" width="180" label="身份证号" align="center" />
          <el-table-column label="发放金额" align="center">
            <template slot-scope="scope">
              {{ !$u.test.isEmptyNotZero(scope.row.taskMoney) ? ('￥' + $u.formatMoney(scope.row.taskMoney)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="创客到手费" align="center">
            <template slot-scope="scope">
              {{ !$u.test.isEmptyNotZero(scope.row.realMoney) ? ('￥' + $u.formatMoney(scope.row.realMoney)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="服务费" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>创客：{{ ('￥' + (((+scope.row.receviceTax) * (+scope.row.taskMoney) / 100) || 0).toFixed(2)) }}</div>
              <div>商户：{{ ('￥' + (((+scope.row.merchantTax) * (+scope.row.taskMoney) / 100) || 0).toFixed(2)) }}</div>
            </template>
          </el-table-column>
          <el-table-column label="服务费率" width="120" show-overflow-tooltip>
            <template slot-scope="scope">
              <div>创客：{{ !$u.test.isEmptyNotZero(scope.row.receviceTax) ? (scope.row.receviceTax + '%') : '-' }}</div>
              <div>商户：{{ !$u.test.isEmptyNotZero(scope.row.merchantTax) ? (scope.row.merchantTax + '%') : '-' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="商户总支出" align="center">
            <template slot-scope="scope">
              {{ !$u.test.isEmptyNotZero(scope.row.merchantPaymentMoney) ? ('￥' + $u.formatMoney(scope.row.merchantPaymentMoney)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="支付状态" width="120" align="center">
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.paymentStatus, $enum.paymentStatus())" />
            </template>
          </el-table-column>
          <el-table-column prop="tradeFailReason" label="支付失败原因" align="center" />
        </el-table>
      </div>
    </el-card>
    <el-card class="u-m-t-20">
      <div class="card--title">众包支付信息</div>
      <div class="card--content">
        <div class="card--info">
          <div class="card--info-item">
            <span class="label">支付方式：</span>
            <span class="value">
              {{ baseInfo.paymentMode | allUnionpayBankType }}
            </span>
          </div>
          <template v-if="(+baseInfo.paymentMode) === 0">
            <table class="c-table is-edit">
              <tr>
                <td>
                  <div class="card--info-item">
                    <span class="label">支付方：</span>
                    <span class="value">{{ baseInfo.companyName }}</span>
                  </div>
                  <div class="card--info-item">
                    <span class="label">开户行：</span>
                    <span class="value">{{ baseInfo.bankName }}</span>
                  </div>
                  <div class="card--info-item">
                    <span class="label">银行账号：</span>
                    <span class="value">{{ baseInfo.bankCode }}</span>
                  </div>
                </td>
                <td>
                  <div class="card--info-item">
                    <span class="label">收款方：</span>
                    <span class="value">{{ baseInfo.payee }}</span>
                  </div>
                  <div class="card--info-item">
                    <span class="label">开户行：</span>
                    <span class="value">{{ baseInfo.payeeBankName }}</span>
                  </div>
                  <div class="card--info-item">
                    <span class="label">银行账号：</span>
                    <span class="value">{{ baseInfo.payeeBankCode }}</span>
                  </div>
                </td>
              </tr>
              <tr :class="isEdit ? '' : 'no-border'">
                <td colspan="2">
                  <div class="blue-box" :class="isEdit ? 'is-edit' : 'is-view'">转账金额：<span class="money">￥ {{ $u.formatMoney(baseInfo.realMoney) }}</span></div>
                </td>
              </tr>
            </table>
            <div class="card--info-item">
              <span class="label">支付回单：</span>
              <span class="value">
                <file-icon :url="baseInfo.manyPayment" btn />
              </span>
            </div>
          </template>
          <!--    线上查看模式      -->
          <template v-else-if="!isEdit">
            <div v-if="unionPay.length" class="u-flex u-col-top u-row-left u-flex-wrap u-m-b-10">
              <union-info v-for="(i, k) in unionPay" :key="k + Date.now()" :show-balance="false" :info="i" />
            </div>
            <div class="blue-box is-view">转账金额：<span class="money">￥ {{ $u.formatMoney(baseInfo.realMoney) }}</span></div>
          </template>
        </div>
      </div>
    </el-card>
    <el-card v-if="taxInfo" class="u-m-t-20">
      <div class="card--title">发票信息</div>
      <div class="card--content">
        <div class="card--info">
          <div class="card--info-item">
            <span class="label">众包发票：</span>
            <span class="value">
              <file-icon :url="taxInfo.invoice" btn />
            </span>
          </div>
        </div>
      </div>
      <template v-if="!$u.test.isEmpty(express.expressCode)">
        <div class="card--title">物流信息</div>
        <div class="card--content">
          <div class="wl-box">
            <div>物流公司：{{ express.expressCompanyName }}</div>
            <div>物流单号：{{ express.expressCode }}</div>
          </div>
          <div v-if="!$u.test.isEmpty(express.expressLogisticsInfos)" class="wl-content">
            <div v-for="(i, k) in express.expressLogisticsInfos" :key="k" class="wl-content-item">
              <span class="u-m-r-20">{{ i.AcceptTime }}</span>
              <span>{{ i.acceptStation }}</span>
            </div>
          </div>
        </div>
      </template>
    </el-card>
    <div v-if="!isComponent" class="wrap-fixed-footer btn--box">
      <template v-if="isPay">
        <el-button v-loading="submitLoading" size="small" type="primary" @click="doPay">
          <div class="u-flex u-row-between u-col-center">
            <i class="el-icon-success text-success" />
            <div>&nbsp;点击确认收款</div>
          </div>
        </el-button>
        <el-button :loading="submitLoading" size="small" type="warning" @click="checkDialog = true">支付驳回</el-button>
      </template>
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
    <el-dialog title="查看关联任务" :visible.sync="visibleDialog" width="800px">
      <task-detail ref="taskDetail" />
    </el-dialog>
    <el-dialog ref="checkDialog" title="请填写驳回原因" :visible.sync="checkDialog" width="800px">
      <div>
        <el-input v-model="checkData.reasonsForRejection" :rows="4" type="textarea" placeholder="请输入" />
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialog = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="rejectPay">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCrowdDetail, checkCrowdPay, getCrowdUnionBalance } from '@/api/pay'
import TaskDetail from '@/views/task/components/taskDetail'
import UnionInfo from '@/components/UnionInfo'
import XLSX from 'xlsx'

export default {
  components: { UnionInfo, TaskDetail },
  props: {
    isComponent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isEdit: false,
      isPay: false,
      id: undefined,
      visibleDialog: false,
      loading: false,
      submitLoading: false,
      baseInfo: {},
      paymentListExcelVO: [], // 用来传参导出elcel
      list: [],
      taxInfo: undefined,
      express: {},
      crowdForm: {
        turnkeyProjectPayment: '',
        paymentMode: 0, // 银联支付方式
        payType: 0 // 支付方式
      },
      crowdPayType: [], // 众包支付方式
      checkDialog: false,
      checkData: {
        reasonsForRejection: ''
      },
      unionPay: []
    }
  },
  created() {
    this.id = this.$route.query.id
    this.fetchData()
    this.isPay = this.$route.meta.isPay // 确认收款
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
        } else if (index === (columns.length - 3)) {
          let total = 0
          data.map(item => {
            total = total + (!this.$u.test.isEmptyNotZero(item.merchantPaymentMoney) ? (+item.merchantPaymentMoney) : 0)
          })
          sums[index] = '￥' + this.$u.formatMoney(total.toFixed(2))
        } else {
          sums[index] = ''
        }
      })
      return sums
    },
    setCrowdField(field, val) {
      this.crowdForm[field] = val
    },
    fetchData() {
      this.loading = true
      getCrowdDetail({
        id: this.id
      }).then(res => {
        const data = res.obj || {}
        this.baseInfo = data.paymentOrderInfoPO || {}
        const paymentMode = +this.baseInfo.paymentMode
        this.baseInfo.paymentMode = paymentMode
        if (paymentMode !== 0) {
          if (paymentMode === 1) {
            this.crowdForm.payType = 'alipay'
          } else if (paymentMode === 6) {
            this.crowdForm.payType = 'alipayUnionPay'
          } else {
            this.crowdForm.payType = 1
            this.fetchBalance(paymentMode)
          }
          this.crowdForm.paymentMode = paymentMode
        }
        this.baseInfo.turnkeyProjectPayment = this.baseInfo.manyPayment
        const companyUnionpayBankTypeList = this.baseInfo.companyUnionpayBankTypeList || []
        const crowdPayType = []
        const allType = this.$enum.unionpayBankType()
        companyUnionpayBankTypeList.map(val => {
          allType.every(item => {
            if (item.value === val) {
              crowdPayType.push(item)
              return false
            }
            return true
          })
        })
        this.crowdPayType = crowdPayType
        this.list = data.paymentInventories || []
        this.express = data.expressInfoVO || {}
        if (!this.$u.test.isEmpty(data.invoice) || !this.$u.test.isEmpty(data.subpackageInvoice)) {
          this.taxInfo = {
            invoice: data.invoice,
            subpackageInvoice: data.subpackageInvoice
          }
        }
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    fetchBalance(val) {
      if (this.$u.test.isEmpty(val)) {
        this.unionPay = []
      } else {
        const unionpayBankTypeList = this.$enum.unionpayBankType()
        let unionpayBankType = ''
        unionpayBankTypeList.every(item => {
          if (item.mode === val) {
            unionpayBankType = item.value
            return false
          }
          return true
        })
        getCrowdUnionBalance({
          merchantId: this.baseInfo.merchantId,
          taxId: this.baseInfo.taxId,
          unionpayBankType: unionpayBankType
        }).then(res => {
          this.unionPay = res.data || []
        }).catch(res => {
          this.unionPay = []
        })
      }
    },
    doPay() {
      this.$confirm('是否确认收款?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.submitLoading = true
        checkCrowdPay({
          boolPass: true,
          paymentOrderId: this.id
        }).then(res => {
          this.$message.success('操作成功')
          this.submitLoading = false
          this.$router.go(-1)
        }).catch(res => {
          this.submitLoading = false
        })
      }).catch(() => {
      })
    },
    rejectPay() {
      if (this.$u.test.isEmpty(this.checkData.reasonsForRejection)) {
        return this.$message.error('请填写驳回原因')
      }
      this.loading = true
      checkCrowdPay({
        boolPass: false,
        paymentOrderId: this.id,
        reasonsForRejection: this.checkData.reasonsForRejection
      }).then(res => {
        this.$message.success('操作成功')
        this.$router.go(-1)
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleSelectionChange(val) {
      this.paymentListExcelVO = []
      let tmpObj = {}
      if (val.length) {
        val.forEach(element => {
          tmpObj = Object.assign({}, element)
          tmpObj.paymentOrderId = this.baseInfo.paymentOrderId
          tmpObj.creatDate = this.baseInfo.creatDate
          tmpObj.paymentDate = this.baseInfo.paymentDate
          tmpObj.companyName = this.baseInfo.companyName
          tmpObj.platformServiceProvider = this.baseInfo.platformServiceProvider
          tmpObj.workerMoney = (tmpObj.receviceTax * tmpObj.taskMoney / 100).toFixed(2)
          tmpObj.merchantMoney = (tmpObj.merchantTax * tmpObj.taskMoney / 100).toFixed(2)
          if (element.paymentStatus === 1) {
            tmpObj.paymentStatus = '支付成功'
          } else if (element.paymentStatus === 0) {
            tmpObj.paymentStatus = '未支付'
          } else {
            tmpObj.paymentStatus = '支付失败'
          }
          this.paymentListExcelVO.push(tmpObj)
        })
        console.log('paymentListExcelVO:', this.paymentListExcelVO)
      }
    },
    downloadExcel() {
      if (this.paymentListExcelVO.length) {
        this.loading = true

        const tableData = [
          ['支付单编号', '创建时间', '商户', '服务商', '姓名', '电话', '身份证号', '银行卡号', '发放金额', '创客服务费率', '创客服务费', '商户服务费率', '商户服务费', '创客到手金额', '商户支出', '支付状态', '支付失败原因', '支付时间']
        ]
        let rowData = []
        this.paymentListExcelVO.forEach((item, index) => {
          rowData = [
            item.paymentOrderId, item.creatDate, item.companyName, item.platformServiceProvider, item.workerName, item.mobileCode, item.idCardCode, item.bankCode, item.taskMoney, item.receviceTax, item.workerMoney, item.merchantTax, item.merchantMoney, item.realMoney, item.merchantPaymentMoney, item.paymentStatus, item.tradeFailReason, item.paymentDate
          ]
          tableData.push(rowData)
        })
        const ws = XLSX.utils.aoa_to_sheet(tableData)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, '支付明细')
        XLSX.writeFile(wb, '支付清单明细.xlsx')

        setTimeout(() => {
          this.loading = false
        }, 4000)
      } else {
        this.$message.error('请先选中内容，后再点击导出')
      }
    },
    handleShow() {},
    showTask(taskId) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.taskDetail.fetchData(taskId)
      })
    }
  }
}
</script>

<style scoped lang="scss">
.is-edit {
  &.c-table {
    border-spacing: 0;
    border-collapse: collapse;
    td {
      min-width: 300px;
      padding: 8px;
      vertical-align: top;
      border: 1px solid #ddd;
    }
  }
  .no-border {
    td {
      border: 1px solid #fff !important;
    }
  }
}
.is-view {
  &.c-table {
    border-spacing: 0;
    border-collapse: collapse;
    td {
      min-width: 300px;
      padding: 0;
      vertical-align: top;
      border: 0;
    }
  }
  &.blue-box {
    margin-bottom: 20px;
    max-width: 700px;
    background: #e6f7ff;
    border: 1px solid #91d5ff;
    border-radius: 4px;
    padding: 10px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    .money {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
    }
  }
}
.wl-box {
  padding: 20px;
  font-size: 14px;
  line-height: 1.6;
  max-width: 930px;
  width: 80%;
  background: rgba(246, 250, 255, 1);
  border: 1px solid rgba(186, 220, 254, 1);
  > div {
    margin: 5px 0;
  }
}
.wl-content {
  max-width: 930px;
  width: 80%;
  padding: 20px;
  font-size: 14px;
  color: #333;
  line-height: 1.6;
  background: #fbfbfc;
  border: 1px solid #eaeaea;
}
</style>
