<template>
  <div>
    <div class="u-p-lf-10 bg-white">
      <el-button class="u-p-lf-20" v-if="$route.path=='/contract/tax-worker'" :loading="btnLoading" type="primary" size="small" @click="batchCheck">批量发送</el-button>
      <el-table ref="multipleTable" row-key="id" v-loading="loading" :data="tableList" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <template v-for="(item,index) in tableOptions" >
          <el-table-column v-if="item.props == 'selection'" reserve-selection :selectable="isSelect" type="selection" width="55" :key="index" />
          <el-table-column v-else-if="item.props == 'agreementUrl'" :key="index" :label="item.label" align="center">
            <template slot-scope="scope">
              <div v-if="$u.test.isEmpty(scope.row.agreementUrl)" v-html="$options.filters.filterContentItem('danger', '未签')" />
              <file-icon v-else :url="scope.row.agreementUrl" />
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.label == '签署状态'" :key="index" :prop="item.props" :label="item.label" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <div v-if="scope.row.signState == 'UNSIGN'" v-html="$options.filters.filterContentItem('danger', '未签署')" />
              <div v-else v-html="$options.filters.filterContentItem('success', '已签署')" />
            </template>
          </el-table-column>
          <el-table-column v-else-if="item.label == '签署方式'" :key="index" :prop="item.props" :label="item.label" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.signType=='EQIANBAO'?'E签宝':scope.row.signType=='YIYUNZHANG'?'易云章':'线下签约' }}
            </template>
          </el-table-column>
          <el-table-column v-else :prop="item.props" :label="item.label" :key="index" align="center" show-overflow-tooltip />
        </template>
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleDownload(scope.row.agreementUrl)">下载</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="$emit('fetchData')" />
      </div>
      <!-- <a href="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.jj20.com%2Fup%2Fallimg%2F4k%2Fs%2F02%2F2109242332225H9-0-lp.jpg&refer=http%3A%2F%2Fimg.jj20.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1648954761&t=7023994239f7970bf7de1dea3a2ad930" download="228e9051120c4c648d07fcb16d644b92">111</a> -->
    </div>
  </div>
</template>

<script>
import { sendUrl } from "@/api/contract";
export default {
  props: {
    tableList: {
      type: Array,
    },
    tableOptions: {
      type: Array,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pageTotal: {
      type: Number,
    },
    paramsData: {
      type: Object,
    },
  },
  data() {
    return {
      ids: [],
      btnLoading:false
    };
  },
  methods: {
    handleDownload(agreementUrl) {
      console.log(1111);
      const url = agreementUrl;
      const index = url.lastIndexOf("/");
      this.downloadFile(url, url.substr(index + 1));
    },
    downloadFile(path, name) {
      const xhr = new XMLHttpRequest();
      xhr.open("get", path);
      xhr.responseType = "blob";
      xhr.send();
      xhr.onload = function () {
        const blob = new Blob([this.response], {
          type: xhr.getResponseHeader("Content-Type"),
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        if (name) {
          a.download = name;
        }
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      };
    },
    // 批量发送
    batchCheck() {
      this.btnLoading = true
      console.log(this.id, "iddd");
      let ids = this.ids.length ? this.ids.toString() : "all";
      console.log(ids, "ids");
      const formData = new FormData()
      formData.append('ids',ids)
      sendUrl(formData).then((res) => {
        this.$message.success("发送成功");
        this.fetchData();
        this.btnLoading = false
        this.$refs.multipleTable.clearSelection();
        // this.linkDialog = true
        // console.log(this.linkList,'linklist');
      });
    },
    handleSelectionChange(val) {
      console.log(val, "fhsyhf");
      this.ids = [];
      console.log(this.ids, "this.idthis.id");
      // checkData selectedWorkerId
      if (val.length) {
        val.forEach((element) => {
          this.ids.push(element.id);
          // console.log(element.id)
        });
      }
    },
    isSelect(row) {
      return row.signState == 'UNSIGN';
    },
  },
};
</script>

<style>
</style>
