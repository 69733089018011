<template>
  <div>
    <el-form :inline="true" :model="paramsData" size="small">
      <el-form-item label="组织结构">
        <el-select v-model="paramsData.customerType" style="width: 100%" placeholder="请选择" clearable filterable>
          <el-option label="直属商户流水" :value="0" />
          <el-option label="下属代理商" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="resetPage">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table ref="multipleTable" v-loading="listLoading" :data="list" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="tradeNo" label="订单号" width="100" show-overflow-tooltip align="center" />
      <el-table-column prop="companyName" label="商户名称" align="center" />
      <el-table-column prop="taxName" label="服务商名称" align="center" />
      <el-table-column label="代理商" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmpty(scope.row.agentName) ? (scope.row.agentName) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="合作类型" align="center">
        <template slot-scope="scope">
          {{ scope.row.paymentOrderType | cooperateMode }}
        </template>
      </el-table-column>
      <el-table-column prop="peopleNum" label="总人数" align="center" />
      <el-table-column label="实发总额" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.workerMoney) ? ('￥' + $u.formatMoney(scope.row.workerMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column :label="'服务费\n企业/个人'" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.merchantServiceCharge) ? ('￥' + $u.formatMoney(scope.row.merchantServiceCharge)) : '-' }} /
          {{ !$u.test.isEmptyNotZero(scope.row.workerServiceCharge) ? ('￥' + $u.formatMoney(scope.row.workerServiceCharge)) : '-' }}
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        label="企业总支出"-->
      <!--        align="center"-->
      <!--      >-->
      <!--        <template slot-scope="scope">-->
      <!--          {{ !$u.test.isEmptyNotZero(scope.row.realMoney) ? ('￥' + scope.row.realMoney) : '-' }}-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="提成金额" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.totalDifference) ? ('￥' + $u.formatMoney(scope.row.totalDifference)) : '-' }}
        </template>
      </el-table-column>
      <!--      <el-table-column-->
      <!--        prop="paymentDate"-->
      <!--        label="完成时间"-->
      <!--        align="center"-->
      <!--      />-->
      <el-table-column label="操作" width="80" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pager-box">
      <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
    </div>
  </div>
</template>

<script>
import { getCountDetailList } from '@/api/assistant'

export default {
  name: 'CountDetailPayList',
  props: {
    managersId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      listLoading: false,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10,
        customerType: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.listLoading = true
      getCountDetailList(Object.assign({}, {
        managersId: this.managersId
      }, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.listLoading = false
      }).catch(res => {
        this.listLoading = false
      })
    },
    handleShow(i) {
      this.$router.push({
        path: i.paymentOrderType === 0 ? '/pay/totalAndSub/view' : '/pay/crowd/view',
        query: {
          id: i.id
        }
      })
    }
  }
}
</script>

<style scoped>
</style>
