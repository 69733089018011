<template>
  <div>
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="个体户名称">
          <el-input v-model="formData.ibname" placeholder="请输入个体户名称" />
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="formData.ibstate" style="width: 100%" placeholder="请选择状态" clearable>
            <el-option v-for="(i, k) in stateList" :key="k" :label="i.name" :value="i.value" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.beginDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择注册时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          至
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.endDate" value-format="yyyy-MM-dd" type="date" placeholder="请选择注册时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button v-if="!isService" type="primary" size="small" icon="el-icon-receiving" @click="create">申请注册个体户</el-button>
    </div>
    <div class="u-m-25 u-p-30 bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="serviceProviderName"
          label="服务商"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="个体户名称"
          align="center"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ $ibname(scope.row.ibname, scope.row.candidatedNames) }}
          </template>
        </el-table-column>
        <el-table-column
          prop="ibtaxNo"
          label="纳税人识别号"
          width="120"
          align="center"
          show-overflow-tooltip
        />
        <!-- <el-table-column
          label="核定税种"
          align="center"
          width="120"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.bizType | filterTax }}
          </template>
        </el-table-column> -->
        <el-table-column
          prop="bizName"
          label="经营者"
          width="100"
          align="center"
          show-overflow-tooltip
        />
        <!-- <el-table-column
          label="注册资金"
          align="center"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.registeredMoney }} 万
          </template>
        </el-table-column> -->
        <el-table-column
          label="营业执照"
          align="center"
        >
          <template slot-scope="scope">
            <file-icon :url="scope.row.businessLicenceUrl" />
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
          width="100"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row.ibstate | filterState }}
          </template>
        </el-table-column>
        <el-table-column
          prop="registeredDate"
          label="注册时间"
          align="center"
          width="150"
        />
        <el-table-column
          label="操作"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
            <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
            <el-button v-if="!isService && scope.row.ibstate === 'EDITING'" type="text" size="small" @click="handleService(scope.row)">匹配服务商</el-button>
            <el-button v-if="scope.row.serviceProviderName && !scope.row.businessLicenceUrl && !scope.row.registeredDate" type="text" size="small" @click="handleLicence(scope.row)">上传营业执照</el-button>
            <el-button v-if="scope.row.ibstate !== 'CANCELLED'" type="text" size="small" @click="handleCancel(scope.row)">注销</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
    <el-dialog key="b" title="选择服务商" :visible.sync="visibleDialogB" width="400px">
      <el-select v-model="serviceData.serviceProviderId" style="width: 100%" placeholder="请选择">
        <el-option v-for="(i, k) in serviceList" :key="k" :label="i.taxName" :value="i.id" />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleDialogB = false">取 消</el-button>
        <el-button type="primary" @click="doService">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog key="c" title="上传营业执照" :visible.sync="visibleDialogC" width="600px">
      <div class="u-flex u-col-center">
        <span>营业执照：</span>
        <button-upload type="image" text="上传营业执照(png/jpg)" @upload-success="setField('businessLicenceUrl', $event)" />
        <el-button v-if="!$u.test.isEmpty(licenceData.businessLicenceUrl)" class="u-m-l-10" type="text" @click="$preViewer(licenceData.businessLicenceUrl)">查看</el-button>
      </div>
      <div class="u-flex u-m-t-20">
        <span style="width: 16%">信用代码：</span>
        <el-input v-model="licenceData.ibtaxNo" placeholder="请输入社会信用代码" />
      </div>
      <div class="u-flex u-m-t-20">
        <span style="width: 14%">注册时间：</span>
        <el-date-picker
          v-model="licenceData.buildDateTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleDialogC = false">取 消</el-button>
        <el-button type="primary" @click="doLicence">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getList, getServiceList, setService, cancel, updateLicence } from '@/api/individualBusiness'
import { getPersonalTax, getIbState } from '@/utils/enum.js'
import ButtonUpload from '@/components/Upload/buttonUpload'
export default {
  name: 'PersonalTable',
  components: { ButtonUpload },
  filters: {
    filterTax(val) {
      if (val) {
        return getPersonalTax(val)
      }
      return ''
    },
    filterState(val) {
      if (val) {
        return getIbState(val)
      }
      return ''
    }
  },
  props: {
    // 状态 "BUSINESSREGISTERING": "工商注册中" "BANKACCOUNTING": "银行开户中" "TAXREGISTERING":"税务登记中" "OPERATING" 运营中 "CANCELLED": "已注销"
    ibstate: {
      type: String,
      default: ''
    }
  },
  data() {
    const stateList = getIbState()
    return {
      serviceData: {
        serviceProviderId: '',
        individualBusinessId: ''
      },
      loading: false,
      visibleDialog: false,
      visibleDialogB: false,
      visibleDialogC: false,
      licenceData: {
        buildDateTime: null,
        businessLicenceUrl: '',
        ibtaxNo: ''
      },
      serviceList: [],
      formData: {
        beginDate: '',
        endDate: '',
        ibname: '',
        individualBusinessEnterpriseId: '',
        ibstate: '',
        taxId: undefined
      },
      stateList: stateList,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  computed: {
    isService() {
      return !!this.$route.meta.isService
    }
  },
  created() {
    this.formData.ibstate = this.ibstate
    this.formData.taxId = this.$route.query.taxId
    this.$nextTick(() => {
      this.fetchData()
      this.fetchService()
    })
  },
  methods: {
    setField(field, file) {
      this.licenceData[field] = file
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      const form = Object.assign({}, this.formData, this.paramsData)
      if (this.$u.test.isEmpty(form.ibstate)) {
        form.ibstate = undefined
      }
      getList(form).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    create() {
      this.$router.push({
        path: '/individualBusiness/add'
      })
    },
    edit(i) {
      this.$router.push({
        path: this.isService ? '/service/individualBusiness/edit' : '/individualBusiness/edit',
        query: {
          id: i.id
        }
      })
    },
    handleShow(i) {
      this.$router.push({
        path: this.isService ? '/service/individualBusiness/detail' : '/individualBusiness/detail',
        query: {
          id: i.id
        }
      })
    },
    handleShowReport(i) {
      this.visibleDialog = true
      this.$nextTick(() => {
        this.$refs.reportList.resetPage(i.id)
      })
    },
    handleService(i) {
      this.serviceData.individualBusinessId = i.id
      this.visibleDialogB = true
    },
    handleLicence(i) {
      this.licenceData.individualId = i.id
      this.visibleDialogC = true
    },
    handleCancel(row) {
      this.$confirm('你确定要注销吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        cancel({
          individualBusinessId: row.id
        }, this.isService).then(res => {
          this.fetchData()
        }).catch(() => {
          this.loading = false
        })
      })
        .catch(() => {})
    },
    doService() {
      this.loading = true
      setService(this.serviceData).then(res => {
        this.serviceData.serviceProviderId = ''
        this.fetchData()
        this.$message.success('操作成功')
        this.visibleDialogB = false
      }).catch(res => {
        this.loading = false
      })
    },
    doLicence() {
      this.loading = true
      if (this.$u.test.isEmpty(this.licenceData.businessLicenceUrl)) {
        return this.$message.error('请上传营业执照')
      } else if (this.$u.test.isEmpty(this.licenceData.ibtaxNo)) {
        return this.$message.error('请输入社会信用代码')
      } else if (this.$u.test.isEmpty(this.licenceData.buildDateTime)) {
        return this.$message.error('请选择注册时间')
      }
      updateLicence(this.licenceData).then(res => {
        this.serviceData.serviceProviderId = ''
        this.fetchData()
        this.$message.success('操作成功')
        this.visibleDialogC = false
      }).catch(res => {
        this.loading = false
      })
    },
    fetchService() {
      if (this.isService) {
        return
      }
      getServiceList({
        taxName: ''
      }).then(res => {
        this.serviceList = res.data || []
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
