<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-card>
      <!-- <div class="card--title">【总包+分包】 总包开票</div> -->
      <div class="card--title">支付信息</div>
      <div class="card--content" style="margin-left:20px">
        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">支付编号：</span>
            <span class="value">{{ paymentOrderInfo.id }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">商户：</span>
            <span class="value">{{ paymentOrderInfo.companySName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">平台服务商：</span>
            <span class="value">{{ paymentOrderInfo.platformServiceProvider }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付清单：</span>
            <span v-if="!$u.test.isEmpty(paymentOrderInfo.paymentInventory)" class="pointer"
              @click="openFile(paymentOrderInfo.paymentInventory)">查看</span>
          </el-col>
          <el-col :md="8">
            <span class="label">关联的任务：</span>
            <span v-if="!$u.test.isEmpty(taskInfo.taskName)" class="pointer" @click="showTaskVo = true">查看</span>
          </el-col>

          <el-col :md="8">
            <span class="label">交付支付验收单：</span>
            <span v-if="!$u.test.isEmpty(paymentOrderInfo.acceptanceCertificate)" class="pointer"
              @click="openFile(paymentOrderInfo.acceptanceCertificate)">查看</span>
          </el-col>
          <el-col :md="8">
            <span class="label">总包支付回单：</span>
            <span v-if="!$u.test.isEmpty(paymentOrderInfo.subpackagePayment)" class="pointer"
              @click="openFile(paymentOrderInfo.subpackagePayment)">查看</span>
          </el-col>

          <el-col :md="8">
            <span class="label">状态：</span>
            <span class="value">{{ paymentOrderInfo.paymentOrderStatus | payTotalAndSubStatus }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">支付时间：</span>
            <span class="value">{{ paymentOrderInfo.paymentDate }}</span>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card class="u-m-t-20">
      <div class="card--title">开票清单明细信息</div>
      <div class="card--content" style="margin-left:20px">
        <worker-pay-list ref="payList" :type="0" />
        <div class="table-wrap">
          <ul class="table">
            <li class="u-flex">
              <div class="title">
                <span class="txt">购买方</span>
              </div>
              <div class="content">
                <p>名称：{{ buyerInfo.companyName }}</p>
                <p>纳税人识别号：{{ buyerInfo.creditCode }}</p>
                <p>地址、电话：{{ buyerInfo.addressAndTelephone }}</p>
                <p>
                  开户行及账号：{{ buyerInfo.bankAndAccount }}
                </p>
              </div>
            </li>
            <li class="count">价税合计：{{ $u.formatMoney(totalTaxPrice) }}</li>
          </ul>
        </div>
        <div class="form-wrap" />
      </div>
    </el-card>

    <el-card class="u-m-t-20">
      <div class="card--title">开票类目</div>
      <div class="card--content" style="margin-left:20px">
        <ul class="invoiceCatalogVo-wrap">
          <li class="item flex align-items">
            <span>服务类型：</span>
            <span>{{ invoiceCatalogVo.billingCategory }}</span>
          </li>
          <li class="item flex align-items">
            <span>具体服务内容：</span>
            <span>{{ invoiceCatalogVo.serviceType }}</span>
          </li>
          <li class="item flex align-items">
            <span>开票类目：</span>
            <span>{{ invoiceCatalogVo.serviceType }}</span>
          </li>
        </ul>
      </div>
    </el-card>

    <el-card class="u-m-t-20">
      <el-form label-position="right" label-width="100px" class="demo-form-inline">
        <el-form-item label="发票：" required>
          <div class="u-flex u-col-center">
            <button-upload v-if="type !== 'examine'" :custom-type="['pdf', 'jpg', 'png', 'rar', 'zip', '7z', 'arj']"
              text="发票(png/jpg/png/zip)" @upload-success="setField('makerInvoiceUrl', $event)" />
            <el-button v-if="!$u.test.isEmpty(formData.makerInvoiceUrl)" class="u-m-l-10" type="text"
              @click="$preViewer(formData.makerInvoiceUrl)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="税票：">
          <div class="u-flex u-col-center">
            <button-upload v-if="type !== 'examine'" :custom-type="['pdf', 'jpg', 'png', 'rar', 'zip', '7z', 'arj']"
              text="上传税票(png/jpg/png/zip)" @upload-success="setField('makerTaxUrl', $event)" />
            <el-button v-if="!$u.test.isEmpty(formData.makerTaxUrl)" class="u-m-l-10" type="text"
              @click="$preViewer(formData.makerTaxUrl)">查看</el-button>
          </div>
        </el-form-item>
      </el-form>

      <div v-if="type == 'add' || type == 'editor'" style="height:100px;margin-left:80px"
        class="flex justify-start align-items">
        <el-button type="primary" @click="submit">提交</el-button>
      </div>
    </el-card>
    <!-- 关联任务 -->
    <el-dialog title="关联的任务" :visible.sync="showTaskVo">
      <div>
        <span class="label">任务编号：</span>
        <span class="value">{{ taskInfo.taskCode }}</span>
      </div>
      <div>
        <span class="label">任务名称：</span>
        <span class="value">{{ taskInfo.taskName }}</span>
      </div>
    </el-dialog>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import {
  getInvoiceInfo,
  getInvoiceListQuery,
  saveInvoiceInfo,
  getInvoiceDetails,
  getMakerList
} from '@/api/subPackage'

import ButtonUpload from '@/components/Upload/buttonUpload'
import WorkerPayList from '@/views/tax/components/workerPayList'
export default {
  components: {
    WorkerPayList,
    ButtonUpload
  },
  data() {
    return {
      id: '',
      payId: '',
      showTaskVo: false, // 是否显示关联任务
      taskInfo: {
        taskCode: '',
        taskName: '',
        industryType: '',
        releaseDate: '',
        deadlineDate: ''
      },
      loading: false,
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10,
        invoiceId: ''
      },
      totalTaxPrice: '', // 税额
      formData: {
        // id: '',
        invoiceCategory: '', // 服务类型
        invoiceSerialNo: '', // 发票号码
        invoiceTypeNo: '', // 发票代码
        makerInvoiceDesc: '', // 开票说明
        makerInvoiceUrl: '', // 分包发票url
        makerTaxUrl: '', // 分包完税证明url
        paymentOrderId: '', // 总包支付id，多个，隔开
        saleCompany: '', // 销售方名称
        taxAmount: '', // 税额
        totalAmount: 0 // 价税合计
      }, // 提交数据
      type: '', // 页面类型
      buyerInfo: {}, // 购买方信息
      makeList: [],
      paymentOrderInfo: {}, // 支付信息
      billingInfoList: [], // 开票信息
      defaultAddress: false,

      expressLogisticsInfoList: [], // 物流信息
      sendAndReceiveVo: {}, // 快递信息
      invoiceCatalogVo: {}, // 已开票-开票类目信息
      invoiceVo: {} // 开票信息
    }
  },
  created() {
    this.id = this.$route.query.id
    this.type = this.$route.query.type
    this.payId = this.$route.query.payId
    const form = {}
    if (this.type === 'editor' || this.type === 'examine') {
      this.fetchData(getMakerList)
      getInvoiceDetails({ invoiceId: this.id }).then(res => {
        const data = res.obj
        const paymentOrderVoList = JSON.parse(
          JSON.stringify(data.paymentOrderVoList)
        )
        const idList = []
        form.companySName = paymentOrderVoList[0].companySName
        form.platformServiceProvider =
          paymentOrderVoList[0].platformServiceProvider
        form.paymentOrderStatus = paymentOrderVoList[0].paymentOrderStatus
        form.paymentDate = paymentOrderVoList[0].paymentDate
        form.acceptanceCertificate = paymentOrderVoList[0].acceptanceCertificate
        form.subpackagePayment = paymentOrderVoList[0].subpackagePayment
        form.paymentInventory = paymentOrderVoList[0].paymentInventory
        this.taskInfo = paymentOrderVoList[0].taskVo || {}

        form.paymentInventoryList = []
        form.taskVoList = []
        form.subpackagePaymentList = []
        form.acceptanceCertificateList = []

        paymentOrderVoList.forEach(item => {
          idList.push(item.id)
          form.paymentInventoryList.push(item.paymentInventory)
          form.subpackagePaymentList.push(item.subpackagePayment)
          form.taskVoList.push(item.taskVo)
          form.acceptanceCertificateList.push(item.acceptanceCertificate)
        })
        form.id = idList.join(',')
        this.$nextTick(() => {
          this.$refs.payList.fetchData(form.id)
        })
        this.paymentOrderInfo = form
        this.buyerInfo = res.obj.queryBuyer
        this.invoiceCatalogVo = res.obj.invoiceCatalogVo

        this.formData = JSON.parse(
          JSON.stringify(data.makerTotalInvoiceDetails)
        )
      })
    } else if (this.type === 'add') {
      getInvoiceInfo({ invoiceIds: this.id }).then(res => {
        const data = res.obj
        const paymentOrderVoList = JSON.parse(
          JSON.stringify(data.paymentOrderVoList)
        )
        const idList = []

        form.companySName = paymentOrderVoList[0].companySName
        form.platformServiceProvider =
          paymentOrderVoList[0].platformServiceProvider
        form.paymentOrderStatus = paymentOrderVoList[0].paymentOrderStatus
        form.paymentDate = paymentOrderVoList[0].paymentDate

        form.paymentInventoryList = []
        form.taskVoList = []
        form.subpackagePaymentList = []
        form.acceptanceCertificateList = []

        paymentOrderVoList.forEach(item => {
          idList.push(item.id)
          form.paymentInventoryList.push(item.paymentInventory)
          form.subpackagePaymentList.push(item.subpackagePayment)
          form.taskVoList.push(item.taskVo)
          form.acceptanceCertificateList.push(item.acceptanceCertificate)
        })
        form.id = idList.join(',')
        this.$nextTick(() => {
          this.$refs.payList.fetchData(form.id)
        })
        this.paymentOrderInfo = form
        this.buyerInfo = res.obj.buyerVo
        this.invoiceCatalogVo = res.obj.invoiceCatalogVo
      })
      this.fetchData(getInvoiceListQuery)
    }
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10,
        id: this.id
      })
      this.makeList = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData(callback) {
      this.loading = true
      this.paramsData.invoiceId = this.id
      // const params = JSON.parse(JSON.stringify(this.paramsData))
      callback(Object.assign(this.paramsData, {}))
        .then(res => {
          this.makeList = res.obj.voList || []
          this.totalTaxPrice = res.obj.totalTaxPrice || 0
          this.pageTotal = res.pageCount
          this.loading = false
        })
        .catch(res => {
          this.loading = false
        })
    },
    openFile(url) {
      this.$preViewer(url)
    },
    submit() {
      if (this.type === 'add') {
        const taxAmountList = []
        this.makeList.forEach(item => {
          taxAmountList.push(item.taxAmount)
        })
        this.formData.taxAmount = taxAmountList.join(',')
        this.formData.saleCompany = this.buyerInfo.companyName
        this.formData.paymentOrderId = this.paymentOrderInfo.id
        this.formData.invoiceId = this.id
        this.formData.totalAmount = this.totalTaxPrice
        this.formData.invoiceCategory = this.invoiceCatalogVo.id
      }
      const params = JSON.parse(JSON.stringify(this.formData))
      saveInvoiceInfo(params).then(res => {
        this.$message.success('提交成功！！！')
        this.goBack()
      })
    },
    // 上传文件
    setField(field, file) {
      this.formData[field] = file
    },
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row)
    },
    handleCurrentChange(val) {
      this.currentRow = val
    }
  }
}
</script>

<style scoped lang="scss">
ul,
li,
p {
  list-style: none;
  /*清除列表默认样式*/
  padding: 0;
  /*清除padding*/
  margin: 0;
}

.invoiceVo-wrap,
.invoiceCatalogVo-wrap {
  .item {
    padding-bottom: 10px;

    li {
      span {
        margin-right: 8px;
      }
    }
  }
}

.card--content {
  .el-col {
    .value {
      // width: 450px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .pointer {
    margin-left: 10px;
    color: #3f9ffc;
    cursor: pointer;
    // text-decoration: underline;
  }
}

.blue-box {
  max-width: 700px;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 4px;
  padding: 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  .money {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
}

.add-address {
  margin-top: 30px;
  height: 50px;
  cursor: pointer;
  line-height: 50px;
  text-align: center;
  border: 1px dotted #ebeef5;
}

.table-radio {
  // border: 1px solid red;
  max-height: 400px;
  overflow-y: auto;
}

.logistics-info,
.express-info {

  ul,
  li,
  p {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
}

.express-info {
  ul {
    padding: 10px 15px;
    width: 928px;
    background: #f6faff;
    border: 1px solid #badcfe;

    li {
      height: 30px;
      line-height: 30px;
    }
  }
}

.logistics-info {
  padding: 10px 25px;
  width: 928px;
  min-height: 200px;
  background: #fbfbfc;
  border: 1px solid #eaeaea;
}

.tabl-title {
  width: 100%;
  text-align: center;
  height: 30px;
  font-size: 16px;
}

.form-wrap {
  margin-top: 20px;
  width: 614px;
}

.table-wrap {
  margin-top: 20px;
  margin-left: 80px;

  ul,
  li,
  p {
    list-style: none;
    /*清除列表默认样式*/
    padding: 0;
    /*清除padding*/
    margin: 0;
  }

  .table {
    border: 1px solid #ebeef5;
    min-width: 750px;

    .title {
      position: relative;
      width: 80px;
      text-align: center;

      // line-height: 160px;
      .txt {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -33px;
        margin-left: -10px;
        display: inline-block;
        width: 20px;
      }
    }

    .content {
      border-left: 1px solid #ebeef5;
      width: 100%;

      p {
        width: 100%;
        line-height: 1.6;
        padding: 5px 20px;
      }

      // p:not(:last-child){
      //      border-bottom: 1px solid #EBEEF5;

      // }
    }

    .count {
      border: 1px solid #ebeef5;
      border-left: 0;
      border-right: 0;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
    }
  }
}
</style>
