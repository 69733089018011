<template>
  <el-card shadow="never" style="width: 40%" :body-style="{ padding: 0 }">
    <div v-if="isAliPay" slot="header" class="clearfix u-text-center">
      支付宝
    </div>
    <div v-else slot="header" class="clearfix u-text-center">
      银行类型: {{ info.unionpayBankType | unionpayBankType }}
    </div>
    <div class="u-flex u-col-top u-row-between">
      <div class="u-flex-1 u-padding-10 u-border-right">
        <div class="u-m-b-10">子账号户名：{{ info.subAccountName }}</div>
        <div v-if="info.unionpayBankType === 'SJBK'" class="u-m-b-10">来款银行账号：{{ info.inBankNo }}</div>
        <div class="u-m-b-10">子账户账号：{{ info.subAccountCode }}</div>
        <div class="u-m-b-10" v-if="info.bankName && !isAliPay">开户行：{{ info.bankName }}</div>
        <template v-if="showBalance">
          <!-- <div class="u-m-b-10">开户行：平安银行宁波分行营业部</div> -->
          <div class="u-m-b-10">可用余额：￥{{ $u.formatMoney(info.useBal) }}</div>
          <div class="u-m-b-10">冻结余额(平台)：￥{{ $u.formatMoney(info.pfrzBal) }}</div>
          <div class="u-m-b-10">冻结余额(银行)：￥{{ $u.formatMoney(info.bfrzBal) }}</div>
          <div class="u-m-b-10">在途余额(入)：￥{{ $u.formatMoney(info.iwayBal) }}</div>
          <div class="u-m-b-10">在途余额(出)：￥{{ $u.formatMoney(info.owayBal) }}</div>
          <div class="u-m-b-10">账面余额：￥{{ $u.formatMoney(info.actBal) }}</div>
        </template>
      </div>
      <div class="u-flex-1 u-padding-10">
        <div class="u-m-b-10">服务商名称：{{ info.taxName }}</div>
        <div class="u-m-b-10">商户号：{{ info.merchno }}</div>
        <div class="u-m-b-10">平台账户号：{{ info.acctno }}</div>
      </div>
    </div>
    <div v-if="withdraw" class="u-text-center u-p-t-10 u-p-b-10 u-border-top">
      <el-button type="primary" @click="handleWithdraw(info)">提现</el-button>
    </div>
    <el-dialog title="提现" append-to-body :visible.sync="withdrawDialog" width="600px">
      <withdraw-by-union-pay ref="withdrawForm" :merchant-id="merchantId" :isAliPay="isAliPay"
        @success="getTotalAlipayBalance" />
    </el-dialog>
  </el-card>
</template>

<script>
import WithdrawByUnionPay from '@/views/shop/components/WithdrawByUnionPay'
export default {
  name: 'UnionInfo',
  components: { WithdrawByUnionPay },
  props: {
    withdraw: {
      type: Boolean,
      default: false
    },
    merchantId: {
      type: [Number, String],
      default: ''
    },
    info: {
      type: Object,
      default() {
        return {}
      }
    },
    showBalance: {
      type: Boolean,
      default: true
    },
    isAliPay: {
      type: Boolean,
      default: false
    },
    companyAlipayId: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      withdrawDialog: false
    }
  },
  methods: {
    handleWithdraw(row) {
      this.withdrawDialog = true
      this.$nextTick(() => {
        this.$refs.withdrawForm.setData({
          companyUnionpayId: row.companyUnionpayId || this.companyAlipayId
        })
      })
    },
    getTotalAlipayBalance() {
      this.$emit('getTotalAlipayBalance')
      this.withdrawDialog = false
    }
  }
}
</script>

<style scoped></style>
