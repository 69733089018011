import request from '@/utils/request'

/**
 * 列表
 * @param data
 * @returns {AxiosPromise}
 */
export function getList(data) {
  return request({
    url: '/platform/task/getplatformTasks',
    method: 'post',
    data
  })
}

/**
 * 创客列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getWorkList(params) {
  return request({
    url: '/platform/worker/queryWorkerCompanyByID',
    method: 'post',
    params
  })
}
/**
 * 剔除创客信息
 * @param params
 * @returns {AxiosPromise}
 */
export function delWorker(params) {
  return request({
    url: '/platform/worker/eliminateWorker',
    method: 'post',
    params
  })
}
/**
 * 详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getDetail(params) {
  return request({
    url: '/platform/task/getTaskById',
    method: 'post',
    params
  })
}

/**
 * 开启
 * @param params
 * @returns {AxiosPromise}
 */
export function openTask(params) {
  return request({
    url: '/platform/task/openTask',
    method: 'post',
    params
  })
}
/**
 * 关闭
 * @param params
 * @returns {AxiosPromise}
 */
export function closeTask(params) {
  return request({
    url: '/platform/task/colseTask',
    method: 'post',
    params
  })
}

/**
 * 删除
 * @param params
 * @returns {AxiosPromise}
 */
export function del(params) {
  return request({
    url: '/platform/task/deleteTask',
    method: 'delete',
    params
  })
}

/**
 * 新增
 * @param data
 * @returns {AxiosPromise}
 */
export function add(data) {
  return request({
    url: '/platform/task/addPlatformTask',
    method: 'post',
    data
  })
}

/**
 * 验收
 * @param params
 * @returns {AxiosPromise}
 */
export function checkMoney(params) {
  return request({
    url: '/platform/worker/updateCheck',
    method: 'post',
    params
  })
}

/**
 * 审核状态
 * @param params
 * @returns {AxiosPromise}
 */
export function setCheck(params) {
  return request({
    url: '/platform/task/updatePlatfromTaskAuditState',
    method: 'post',
    params
  })
}

/**
 * 下载导入创客任务模板
 * @param params
 * @returns {AxiosPromise}
 */
export function getImportWorkerTask() {
  return request({
    url: '/templateFile/getImportWorkerTask',
    method: 'get'
  })
}
