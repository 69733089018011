<template>
  <el-card v-loading="loadingA" class="box-card">
    <div slot="header" class="header u-flex u-col-center u-row-between">
      <div class="left">
        <span v-for="(i, k) in typeList" :key="k" :class="{active: i.value === type}" class="item" @click="changeType(i.value, null)">{{ i.name }}</span>
      </div>
      <div class="right">
        <span v-for="(i, k) in dateTypeList" :key="k" :class="{active: i.value === dateType}" class="item" @click="changeType(null, i.value)">{{ i.name }}</span>
      </div>
    </div>
    <div class="body u-flex u-col-center u-row-around">
      <base-echarts :options="chartOptions" style="width: 100%;height: 280px" />
    </div>
  </el-card>
</template>

<script>
import BaseEcharts from "@/components/echart";
import { getChart } from "@/api/dashboard";
function _dayChart(data) {
  const fields = [
    "oneHourIncome",
    "twoHourIncome",
    "threeHourIncome",
    "fourHourIncome",
    "fiveHourIncome",
    "sixHourIncome",
    "sevenHourIncome",
    "eightHourIncome",
    "nineHourIncome",
    "tenHourIncome",
    "elevenHourIncome",
    "twelveHourIncome",
    "thirteenHourIncome",
    "fourteenHourIncome",
    "fifteenHourIncome",
    "sixteenHourIncome",
    "seventeenHourIncome",
    "eighteenHourIncome",
    "nineteenHourIncome",
    "twentyHourIncome",
    "twentyOneHourIncome",
    "twentyTwoHourIncome",
    "twentyThreeHourIncome",
    "twentyFourHourIncome",
  ];

  const x = [];
  const y = [];
  for (let i = 0, len = fields.length; i < len; i++) {
    if (data[fields[i]] !== null) {
      x.push(i + 1 + "点");
      y.push(data[fields[i]]);
    }
  }
  return {
    x,
    y,
  };
}

function _weekChart(data) {
  const fields = [
    "monIncome",
    "tueIncome",
    "wedIncome",
    "thuIncome",
    "friIncome",
    "satIncome",
    "sunIncome",
  ];
  const names = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];

  const x = [];
  const y = [];
  for (let i = 0, len = fields.length; i < len; i++) {
    if (data[fields[i]] !== null) {
      x.push(names[i]);
      y.push(data[fields[i]]);
    }
  }
  return {
    x,
    y,
  };
}

function _monthChart(data) {
  const fields = [
    "oneDayIncome",
    "twoDayIncome",
    "threeDayIncome",
    "fourDayIncome",
    "fiveDayIncome",
    "sixDayIncome",
    "sevenDayIncome",
    "eightDayIncome",
    "nineDayIncome",
    "tenDayIncome",
    "elevenDayIncome",
    "twelveDayIncome",
    "thirteenDayIncome",
    "fourteenDayIncome",
    "fifteenDayIncome",
    "sixteenDayIncome",
    "seventeenDayIncome",
    "eighteenDayIncome",
    "nineteenDayIncome",
    "twentyDayIncome",
    "twentyOneDayIncome",
    "twentyTwoDayIncome",
    "twentyThreeDayIncome",
    "twentyFourDayIncome",
    "twentyFiveDayIncome",
    "twentySixDayIncome",
    "twentySevenDayIncome",
    "twentyEightDayIncome",
    "twentyNineDayIncome",
    "thirtyDayIncome",
    "thirtyOneDayIncome",
  ];

  const x = [];
  const y = [];
  for (let i = 0, len = fields.length; i < len; i++) {
    if (data[fields[i]] !== null) {
      x.push(i + 1 + "日");
      y.push(data[fields[i]]);
    }
  }
  return {
    x,
    y,
  };
}

function _yearChart(data) {
  console.log(data, "datadata");
  const fields = [
    "janIncome",
    "febIncome",
    "marIncome",
    "aprIncome",
    "mayIncome",
    "junIncome",
    "julIncome",
    "augIncome",
    "sepIncome",
    "octIncome",
    "novIncome",
    "decIncome",
  ];
  const names = [
    "一月",
    "二月",
    "三月",
    "四月",
    "五月",
    "六月",
    "七月",
    "八月",
    "九月",
    "十月",
    "十一月",
    "十二月",
  ];

  const x = [];
  const y = [];
  data.map((item, index) => {
    let list = [];
    for (let i = 0, len = fields.length; i < len; i++) {
      if (data[index][fields[i]] !== null) {
        list.push(data[index][fields[i]]);
      }
    }
    y.push(list);
  });
  console.log(y, "yy");
  return {
    x:names,
    y,
  };
}

function _setChart(dateType, data) {
  switch (dateType) {
    case "day":
      return _dayChart(data);
    case "week":
      return _weekChart(data);
    case "month":
      return _monthChart(data);
    case "year":
      return _yearChart(data);
  }
  return {};
}

export default {
  name: "Chart",
  components: {
    BaseEcharts
  },
  data() {
    return {
      chartA: null,
      chartB:null,
      loadingA: false,
      chartData: [],
      chartOptions:{},
      type: "totalSub",
      dateType: "day",
      typeList: [
        { value: "totalSub", name: "总包+分包支付额" },
        { value: "crowd", name: "众包支付额" },
      ],
      dateTypeList: [
        { value: "day", name: "今日" },
        { value: "week", name: "本周" },
        { value: "month", name: "本月" },
        { value: "year", name: "全年" },
      ],
    };
  },
  mounted() {
    // this.initChart();
    this.fetchData();
  },
  beforeDestroy() {
    if (this.chartA) {
      this.chartA.dispose();
      this.chartA = null;
    }
  },
  methods: {
    changeType(type, dateType) {
      if (type) {
        this.type = type;
      }
      if (dateType) {
        this.dateType = dateType;
      }

      this.$nextTick(() => {
        this.fetchData();
      });
    },
    initChart() {
      this.chartA = echarts.init(this.$refs.chartA);
      this.$nextTick(() => {
        this.fetchData();
      });
    },
    fetchData() {
      this.loadingA = true;
      getChart(this.type, this.dateType)
        .then((res) => {
          this.loadingA = false;
          const data = res.obj || res.data || {};
          const chart = _setChart(this.dateType, data);
          const x = chart.x || [];
          this.chartData = chart.y || [];
          this.$nextTick(() => {
            if (this.dateType == "year") {
              this.setOptionsB(x);
            } else {
              this.setOptionsA(x);
            }
          });
        })
        .catch((res) => {
          this.loadingA = false;
        });
    },
    setOptionsA (x) {
      console.log(this.chartData,'chartData');
      this.chartOptions = {
        tooltip: {},
        color: ['#1890FF'],
        xAxis: {
          data: x,
          axisLabel: {
            color: '#767676'
          },
          axisLine: {
            lineStyle: {
              color: '#D9D9D9'
            }
          },
          axisTick: {
            lineStyle: {
              color: '#D9D9D9'
            }
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        yAxis: {
          axisLabel: {
            color: '#767676'
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        series: [{
          name: '交易额',
          type: 'bar',
          data: this.chartData
        }]
      }
    },
    setOptionsB (x) {
      
      this.chartOptions = {
        legend: {
          data: ["今年交易额", "去年交易额"],
        },
        tooltip: {},
        color: ["#1890FF", "#68BBC4"],
        xAxis: {
          data: x,
          axisLabel: {
            color: "#767676",
          },
          axisLine: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          axisTick: {
            lineStyle: {
              color: "#D9D9D9",
            },
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        yAxis: {
          axisLabel: {
            color: "#767676",
          },
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          splitLine: {
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: "今年交易额",
            type: "bar",
            data: this.chartData[0],
          },
          {
            name: "去年交易额",
            type: "bar",
            data: this.chartData[1],
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";
.body {
  height: 350px;
  overflow-y: auto;
}
.header {
  font-size: 14px;
  color: #595959;

  .item {
    cursor: pointer;
    margin: 4px 10px;
    &.active {
      font-size: 14px;
      color: $--color-primary;
    }
  }

  .left {
    .item {
      &.active {
        font-size: 16px;
      }
    }
  }
}
</style>

