var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-p-20 wrap-fixed-content" },
    [
      _c(
        "el-card",
        { staticClass: "u-m-t-20", attrs: { header: "支付方式" } },
        [_c("pay-type-list", { attrs: { "merchant-id": _vm.id } })],
        1
      ),
      _c(
        "el-card",
        { staticClass: "u-m-t-20", attrs: { header: "交易记录" } },
        [_c("pay-history-list", { attrs: { "merchant-id": _vm.id } })],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("返回")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }