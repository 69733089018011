<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="商户名称">
          <el-input v-model="formData.merchantName" placeholder="请输入商户名称" />
        </el-form-item>
        <el-form-item label="任务名称">
          <el-input v-model="formData.taskName" placeholder="请输入任务名称" />
        </el-form-item>
        <el-form-item label="合作类型">
          <el-select v-model="formData.cooperateMode" style="width: 100%" placeholder="合作类型">
            <el-option v-for="(i, k) in cooperateMode" :key="k" :label="i.name" :value="i.value" />
          </el-select>
        </el-form-item>
        <el-form-item label="发布时间">
          <el-date-picker v-model="formData.releaseDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          至
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.deadlineDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd()">
        发布新任务
      </el-button>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="list"
      >
        <el-table-column
          prop="taskCode"
          label="任务编号"
          width="100"
          show-overflow-tooltip
          align="center"
        />
        <el-table-column
          prop="merchantName"
          label="商户名称"
          align="center"
          width="160"
          show-overflow-tooltip
        />
        <el-table-column
          prop="taskName"
          label="任务名称"
          show-overflow-tooltip
        />
        <el-table-column
          label="合作类型"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.cooperateMode | cooperateMode }}
          </template>
        </el-table-column>
        <el-table-column
          label="任务模式"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.taskMode | taskMode }}
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.auditState === 'PASSAUDIT'">
              <div v-html="$options.filters.filterContent(scope.row.state, $enum.taskState())" />
            </template>
            <template v-else>
              <div v-html="$options.filters.filterContent(scope.row.auditState, $enum.taskCheckStatus())" />
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="发布时间"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            {{ scope.row.createDate }}
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="120"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDel(scope.row)">删除</el-button>
            <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
            <template v-if="scope.row.auditState === 'PASSAUDIT'">
              <el-button v-if="scope.row.state === 0" type="text" size="small" @click="handleClose(scope.row)">关单</el-button>
              <el-button v-else-if="scope.row.state === 1" type="text" size="small" @click="handleOpen(scope.row)">开启</el-button>
              <el-button v-if="scope.row.state === 1 || scope.row.state === 2" type="text" size="small" @click="handleCheck(scope.row)">验收</el-button>
            </template>
            <template v-else>
              <el-button type="text" size="small" @click="handleCheckStatus(scope.row)">审核</el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
    <el-dialog key="b" title="审核" :visible.sync="visibleDialogB" width="400px">
      <el-select v-model="checkData.auditState" style="width: 100%" placeholder="请选择">
        <el-option v-for="(i, k) in $enum.taskCheckStatus()" :key="k" :label="i.name" :value="i.value" />
      </el-select>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleDialogB = false">取 消</el-button>
        <el-button type="primary" @click="doCheck">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { closeTask, del, getList, openTask, setCheck } from '@/api/task'

export default {
  name: 'Task',
  data() {
    return {
      checkData: {
        auditState: '',
        taskId: ''
      },
      visibleDialogB: false,
      loading: false,
      cooperateMode: this.$enum.cooperateMode(),
      formData: {
        merchantName: '',
        taskName: '',
        cooperateMode: '',
        releaseDate: '',
        deadlineDate: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    console.log('task页面---created---------------')
    this.fetchData()
  },
  activated() {
    console.log('task页面---activated---------------')
    // if (this.$store.getters.isUpdatePage) {
    //   this.$store.commit('common/setUpdatePage', false)
    //   this.fetchData()
    // }
    this.fetchData()
  },
  deactivated() {
    console.log('task页面---deactivated---------------')
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getList(Object.assign({}, this.formData, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleAdd() {
      this.$store.commit('common/setUpdatePage', true)
      this.$router.push({
        path: '/task/add'
      })
    },
    handleShow(i) {
      this.$router.push({
        path: '/task/view',
        query: {
          id: i.id
        }
      })
    },
    handleCheck(i) {
      this.$router.push({
        path: '/task/check',
        query: {
          id: i.id
        }
      })
    },
    handleDel(i) {
      this.$confirm('你确定要删除任务吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        del({
          taskId: i.id
        }).then(res => {
          this.resetPage()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    },
    handleCheckStatus(row) {
      this.checkData.taskId = row.id
      this.checkData.auditState = row.auditState
      this.visibleDialogB = true
    },
    doCheck() {
      this.loading = true
      setCheck(this.checkData).then(res => {
        this.checkData.taskId = ''
        this.fetchData()
        this.$message.success('操作成功')
        this.visibleDialogB = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleClose(i) {
      this.$confirm('你确定要关单吗？', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        closeTask({
          taskId: i.id
        }).then(res => {
          this.resetPage()
          this.$message({
            type: 'success',
            message: '关单成功!'
          })
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    },
    handleOpen(i) {
      this.$confirm('你确定要重新开启任务吗？', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        openTask({
          taskId: i.id
        }).then(res => {
          this.resetPage()
          this.$message({
            type: 'success',
            message: '重新开启任务成功!'
          })
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
