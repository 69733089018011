<template>
  <div class="Nest-rv">
    <!-- <keep-alive :include="cachedViews2"> -->
    <router-view />
    <!-- </keep-alive> -->
  </div>
</template>

<script>
export default {
  // name: 'Nest',
  // computed: {
  //   cachedViews2() {
  //     console.log('Nest-cachedViews:', this.$store.state.tagsView.cachedViews)
  //     return this.$store.state.tagsView.cachedViews
  //   }
  // },
  // created() {
  //   console.log(this.$options.name + '组件-----created')
  // },
  // activated() {
  //   console.log(this.$options.name + '组件-----activated')
  // }
}
</script>

<style scoped>

</style>
