<template>
  <div>
    <el-form ref="formData" :model="value" :rules="rules" class="u-form" label-width="250px">
      <el-card header="基本信息">
        <el-form-item label="公司名称" prop="taxName">
          <el-input v-model="value.taxName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="社会信用代码" prop="creditCode">
          <el-input v-model="value.creditCode" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="法定代表人" prop="taxMan">
          <el-input v-model="value.taxMan" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="是否关联任务" prop="relevance">
          <el-select v-model="value.relevance" placeholder="请选择">
            <el-option label="是" :value="1" />
            <el-option label="否" :value="0" />
          </el-select>
        </el-form-item>
        <el-form-item label="创客发放限制" prop="workerSigningType">
          <el-select v-model="value.workerSigningType" placeholder="请选择">
            <el-option label="认证+签约" value="AUTHENTANDSIGN" />
            <el-option label="认证" value="AUTHENT" />
            <el-option label="无限制" value="UNLIMITED" />
          </el-select>
        </el-form-item>
        <el-form-item label="创客发放限额" required>
          <el-select v-model="value.quota" placeholder="请选择" @change="selectChange">
            <el-option label="不设额度" value="NOTHING" />
            <!-- <el-option label="月" value="MOUTH" />
            <el-option label="年" value="YEAR" /> -->
            <el-option label="年月" value="YEARANDMOUTH" />
          </el-select>
        </el-form-item>
        <el-form-item v-if="value.quota == 'MOUTH' || value.quota == 'YEARANDMOUTH'" label="月限额(万元)">
          <el-input v-model="value.mouthPay" placeholder="请输入" min="0" type="number" />
        </el-form-item>
        <el-form-item v-if="value.quota == 'YEAR' || value.quota == 'YEARANDMOUTH'" label="年限额(万元)">
          <el-input v-model="value.yearPay" placeholder="请输入" min="0" type="number" />
        </el-form-item>
      </el-card>
      <el-card header="联系人信息" class="u-m-t-20">
        <el-form-item label="姓名" prop="linkMan">
          <el-input v-model="value.linkMan" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="职位" prop="linkPosition">
          <el-select v-model="value.linkPosition" filterable placeholder="请选择">
            <el-option v-for="(i, k) in roleList" :key="k" :label="i" :value="i" />
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="linkMobile">
          <el-input v-model="value.linkMobile" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="联系人邮箱" prop="linkEmail">
          <el-input v-model="value.linkEmail" placeholder="请输入" />
        </el-form-item>
      </el-card>
      <el-card header="开票信息" class="u-m-t-20">
        <el-form-item label="公司名称" prop="invoiceEnterpriseName">
          <div class="u-flex u-col-center">
            <div>
              <el-input v-model="value.invoiceEnterpriseName" :readonly="isEdit" placeholder="请输入" />
            </div>
            <span class="u-tips-color u-font-14 u-p-l-10">不支持修改</span>
          </div>
        </el-form-item>
        <el-form-item label="纳税识别号" prop="invoiceTaxNo">
          <div class="u-flex u-col-center">
            <div>
              <el-input v-model="value.invoiceTaxNo" :readonly="isEdit" placeholder="请输入" />
            </div>
            <span class="u-tips-color u-font-14 u-p-l-10">不支持修改</span>
          </div>
        </el-form-item>
        <el-form-item label="地址电话" prop="invoiceAddressPhone">
          <el-input v-model="value.invoiceAddressPhone" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="开户行及账号" prop="invoiceBankNameAccount">
          <el-input v-model="value.invoiceBankNameAccount" placeholder="请输入" />
        </el-form-item>
      </el-card>
      <el-card header="对公账户信息(用于线下支付收款)" class="u-m-t-20">
        <el-form-item label="收款单位名称" prop="titleOfAccount">
          <el-input v-model="value.titleOfAccount" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="收款单位账号" prop="bankCode">
          <el-input v-model="value.bankCode" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="开户行名称" prop="bankName">
          <el-input v-model="value.bankName" placeholder="请输入" />
        </el-form-item>
      </el-card>
      <el-card header="快递信息" class="u-m-t-20">
        <el-form-item label="收件人" prop="receiptName">
          <el-input v-model="value.receiptName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="收件人手机号" prop="receiptPhone">
          <el-input v-model="value.receiptPhone" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="收件地址" prop="receiptName">
          <el-input v-model="value.receiptAddress" placeholder="请输入" />
        </el-form-item>
      </el-card>
      <el-card header="附件信息" class="u-m-t-20">
        <el-form-item label="营业执照" required>
          <div class="u-flex u-col-center">
            <button-upload v-model="value.businessLicense" type="image" text="上传(png/jpg)" />
            <el-button v-if="!$u.test.isEmpty(value.businessLicense)" class="u-m-l-10" type="text" @click="$preViewer(value.businessLicense)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="法人身份证">
          <div class="u-flex u-col-center">
            <button-upload v-model="value.taxManIdcard" type="image" text="上传(png/jpg)" />
            <el-button v-if="!$u.test.isEmpty(value.taxManIdcard)" class="u-m-l-10" type="text" @click="$preViewer(value.taxManIdcard)">查看</el-button>
          </div>
        </el-form-item>
        <el-form-item label="平台加盟合同" required>
          <div class="u-flex u-col-center">
            <button-upload v-model="value.joinContract" type="image/pdf" text="上传(jpg/pdf)" />
            <el-button v-if="!$u.test.isEmpty(value.joinContract)" class="u-m-l-10" type="text" @click="$preViewer(value.joinContract)">查看</el-button>
          </div>
        </el-form-item>
      </el-card>
      <el-card header="管理员账号信息" class="u-m-t-20">
        <el-form-item label="姓名" prop="realName">
          <el-input v-model="value.realName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="账户号" prop="userName">
          <el-input v-model="value.userName" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="手机号" prop="loginMobile">
          <el-input v-model="value.loginMobile" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="密码" :prop="isEdit ? 'none' : 'passWord'">
          <el-input v-model="value.passWord" type="text" placeholder="请输入" class="no-autofill-pwd" autocomplete="new-password" />
        </el-form-item>
        <el-form-item label="确认密码" :prop="isEdit ? 'none' : 'repassWord'">
          <el-input v-model="value.repassWord" placeholder="请输入" type="text" class="no-autofill-pwd" autocomplete="new-password" />
        </el-form-item>
        <el-form-item label="支付密码" :prop="isEdit ? 'none' : 'payPwd'">
          <el-input v-model="value.payPwd" type="text" class="no-autofill-pwd" autocomplete="new-password" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="确认支付密码" :prop="isEdit ? 'none' : 'repayPwd'">
          <el-input v-model="value.repayPwd" type="text" class="no-autofill-pwd" autocomplete="new-password" placeholder="请输入" />
        </el-form-item>
      </el-card>
    </el-form>
    <div v-if="!isEdit" class="wrap-fixed-footer btn--box">
      <el-button type="primary" size="small" @click="next('formData')">下一步</el-button>
      <el-button size="small" @click="$router.go(-1)">取消</el-button>
    </div>
  </div>
</template>

<script>
import ButtonUpload from "@/components/Upload/buttonUpload";
export default {
  components: {
    ButtonUpload,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      repayPwd: '',
      repassWord: "",
      roleList: ["管理员", "运营", "产品", "财务", "出纳"],
      rules: {
        taxName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        taxMan: [
          {
            required: true,
            message: "请输入法定人",
            trigger: "blur",
          },
        ],
        creditCode: [
          {
            required: true,
            message: "请输入社会信用代码",
            trigger: "blur",
          },
        ],
        workerSigningType: [
          {
            required: true,
            message: "请选择创客发放限制",
            trigger: "change",
          },
        ],
        relevance: [
          {
            required: true,
            message: "请选择是否关联任务",
            trigger: "change",
          },
        ],
        linkMan: [
          {
            required: true,
            message: "请输入联系人名称",
            trigger: "blur",
          },
        ],
        linkPosition: [
          {
            required: true,
            message: "请选择联系人职位",
            trigger: "change",
          },
        ],
        linkMobile: [
          {
            required: true,
            message: "请输入联系人手机号码",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.isEmpty(value) && !this.$u.test.mobile(value)) {
                callback(new Error("联系人手机号码不正确"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        linkEmail: [
          {
            required: true,
            message: "请输入联系人邮箱",
            trigger: "blur",
          },
        ],
        invoiceEnterpriseName: [
          {
            required: true,
            message: "请输入开票-公司名称",
            trigger: "blur",
          },
        ],
        invoiceTaxNo: [
          {
            required: true,
            message: "请输入开票-纳税识别号",
            trigger: "blur",
          },
        ],
        invoiceAddressPhone: [
          {
            required: true,
            message: "请输入开票-地址电话",
            trigger: "blur",
          },
        ],
        invoiceBankNameAccount: [
          {
            required: true,
            message: "请输入开票-开户行及账号",
            trigger: "blur",
          },
        ],
        titleOfAccount: [
          {
            required: true,
            message: "请输入收款单位名称",
            trigger: "blur",
          },
        ],
        bankCode: [
          {
            required: true,
            message: "请输入收款单位账号",
            trigger: "blur",
          },
        ],
        bankName: [
          {
            required: true,
            message: "请输入开户行名称",
            trigger: "blur",
          },
        ],
        receiptName: [
          {
            required: true,
            message: "请输入收件人",
            trigger: "blur",
          },
        ],
        receiptPhone: [
          {
            required: true,
            message: "请输入收件人手机号码",
            trigger: "blur",
          },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.isEmpty(value) && !this.$u.test.mobile(value)) {
                callback(new Error("请输入有效的收件人手机号"));
              }
              callback();
            },
            trigger: "blur",
          },
        ],
        receiptAddress: [
          {
            required: true,
            message: "请输入收件地址",
            trigger: "blur",
          },
        ],
        businessLicense: [
          {
            required: true,
            message: "请上传营业执照",
            trigger: "blur",
          },
        ],
        realName: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        // ,
        userName: [{
          required: true,
          message: '请输入管理员账户号',
          trigger: 'blur'
        }],
        loginMobile: [{
          required: true,
          message: '请输入管理员手机号码',
          trigger: 'blur'
        },
        {
          validator: (rule, value, callback) => {
            if (!this.$u.test.isEmpty(value) && !this.$u.test.mobile(value)) {
              callback(new Error('请输入有效的管理员手机号'))
            }
            callback()
          },
          trigger: 'blur'
        }
        ],
        passWord: [{
          required: true,
          message: '请输入管理员密码',
          trigger: 'blur'
        }],
        repassWord: [{
          required: true,
          message: '请输入确认密码',
          trigger: 'blur'
        }],
        payPwd: [{
          required: true,
          message: '请输入支付密码',
          trigger: 'blur'
        }],
        repayPwd: [{
          required: true,
          message: '请输入确认支付密码',
          trigger: 'blur'
        }]
      },
    };
  },
  methods: {
    next() {
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          if (this.$u.test.isEmpty(this.value.businessLicense)) {
            return this.$message.error("请上传营业执照");
          }
          if (this.$u.test.isEmpty(this.value.joinContract)) {
            return this.$message.error("请上传平台加盟合同");
          }
          if (this.value.repassWord && this.value.repassWord !== (this.value.passWord || '')) {
            return this.$message.error('两次登录密码不一致')
          }
          if (this.value.repayPwd && this.value.repayPwd !== (this.value.payPwd || '')) {
            return this.$message.error('两次支付密码不一致')
          }
          this.$emit("next");
        } else {
          this.$message.error("请检查表单");
        }
      });
    },
    selectChange (val) {
      if (val == 'NOTHING') {
        this.value.mouthPay = null
        this.value.yearPay = null
      }
    }
  },
};
</script>

<style scoped lang="scss">
.u-form {
  ::v-deep {
    .el-input__inner {
      width: 480px;
    }

    .auto-width {
      .el-form-item__content {
        max-width: 800px;
      }
    }
  }
}

.error-line {
  ::v-deep {
    .el-input__inner {
      border-color: #f56c6c;
    }
  }
}

.box-un-active {
  .error-line {
    ::v-deep {
      .el-input__inner {
        border-color: #dcdfe6;
      }
    }
  }

  opacity: 0.3;
}
</style>
