module.exports = {

  myName: '零工云服务平台',

  copyright: '2021-2023 - 零工云 粤ICP备666号',

  title: '零工云服务平台',

  slogan: '赋能企业转型「灵活用工」',

  homeUrl: '',

  /**
   * @type {boolean} true | false
   * @description Whether fix the header
   */
  fixedHeader: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true
}
