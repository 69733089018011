// 服务商管理/支付方式/招行提现
<template>
  <div>
    <el-form ref="withdrawForm" v-loading="withdrawLoading" :model="withdrawForm" :rules="withdrawRules" label-width="100px" style="max-width: 400px">
      <el-form-item label="收款方账号" prop="crtAcc">
        <el-input v-model="withdrawForm.crtAcc" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="收方户名" prop="crtNam">
        <el-input v-model="withdrawForm.crtNam" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="提现金额" prop="trsAmt">
        <el-input v-model="withdrawForm.trsAmt" placeholder="请输入" />
      </el-form-item>
      <el-form-item label="收款银行" prop="trsAmt">
        <el-radio v-model="withdrawForm.isZs" label="0">招商银行</el-radio>
        <el-radio v-model="withdrawForm.isZs" label="1">其它银行</el-radio>
        <div v-show="withdrawForm.isZs === '1'">
          <el-form-item label="开户行名称" prop="crtBnk" :required="withdrawForm.isZs === '1'">
            <el-input v-model="withdrawForm.crtBnk" placeholder="请输入" />
          </el-form-item>
          <el-form-item label="联行号" prop="brdNbr" style="padding: 10px 0" :required="withdrawForm.isZs === '1'">
            <el-select v-model="withdrawForm.brdNbr" filterable placeholder="请选择">
              <el-option v-for="(i, k) in bankOptions" :key="k" :label="i.dictValue" :value="i.dictKey" />
            </el-select>
          </el-form-item>
          <el-form-item label="开户行地址" prop="crtAdr" :required="withdrawForm.isZs === '1'">
            <el-input v-model="withdrawForm.crtAdr" placeholder="请输入" />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('withdrawForm')">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>

import { getBankList } from '@/api/common'
import { serviceZsWithdrawal } from '@/api/service'

const _defaultWithdrawForm = () => {
  return {
    crtAcc: '',
    crtNam: '',
    trsAmt: '',
    isZs: '0',
    crtBnk: '',
    brdNbr: '',
    crtAdr: '',
    taxZspayId: ''
  }
}
export default {
  // props: {
  //   taxId: {
  //     type: [Number, String],
  //     required: true
  //   }
  // },
  data() {
    return {
      bankOptions: [],
      withdrawLoading: false,
      withdrawForm: Object.assign({}, _defaultWithdrawForm()),
      withdrawRules: {
        // destBankCode: [
        //   { required: true, trigger: 'blur', message: '请输入开户行' }
        // ],
        crtAcc: [
          { required: true, trigger: 'blur', message: '请输入收款方账号' }
        ],
        crtNam: [
          { required: true, trigger: 'blur', message: '请输入收方户名' }
        ],
        trsAmt: [
          { required: true, trigger: 'blur', message: '请选择提现金额' },
          { trigger: 'blur',
            validator: (rule, value, callback) => {
              if (!this.$u.test.validateRange(value, '0~', false)) {
                callback(new Error('请输入大于0的数'))
              } else {
                callback()
              }
            }
          }
        ]
      },
      tel: ''
    }
  },
  created() {
    this.fetchDict()
  },
  methods: {
    fetchDict() {
      getBankList({
        code: 'bank'
      }).then(res => {
        this.bankOptions = res.data || []
      })
    },
    setData(data = {}) {
      this.$refs['withdrawForm'].resetFields()
      // this.withdrawForm.companyUnionpayId = data.companyUnionpayId
      this.withdrawForm.taxZspayId = data.taxZspayId
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.withdrawLoading = true
          // this.withdrawForm.trsAmt = (this.withdrawForm.trsAmt * 1).toFixed(2)
          if (this.withdrawForm.isZs === '0') {
            this.withdrawForm.crtBnk = undefined;
            this.withdrawForm.brdNbr = undefined;
            this.withdrawForm.crtAdr = undefined;
          }
          serviceZsWithdrawal(this.withdrawForm).then(res => {
            this.$message.success(res.message)
            this.$emit('success')
            this.withdrawLoading = false
          }).catch(res => {
            this.withdrawLoading = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
