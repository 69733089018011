<template>
  <div>
    <el-table ref="multipleTable" v-loading="listLoading" :data="list" tooltip-effect="dark" style="width: 100%">
      <el-table-column prop="paymentOrderId" label="支付编号" width="100" show-overflow-tooltip align="center" />
      <el-table-column prop="taxName" width="120" label="服务商名称" align="center" />
      <el-table-column prop="merchantName" width="180" label="商户名称" align="center" />
      <el-table-column label="合作类型" align="center">
        <template slot-scope="scope">
          {{ scope.row.packageStatus | cooperateMode }}
        </template>
      </el-table-column>
      <el-table-column label="交易流水" align="center">
        <template slot-scope="scope">
          {{ !$u.test.isEmptyNotZero(scope.row.realMoney) ? ('￥' + $u.formatMoney(scope.row.realMoney)) : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center">
        <template slot-scope="scope">
          <div v-html="$options.filters.filterContent(scope.row.isInvoice, $enum.taxStatus(), true)" />
        </template>
      </el-table-column>
      <el-table-column prop="paymentDate" label="完成时间" align="center" />
    </el-table>
    <div class="pager-box">
      <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
    </div>
  </div>
</template>

<script>
import { getPayList } from '@/api/agent'

export default {
  name: 'PayList',
  props: {
    agentId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      listLoading: false,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.listLoading = true
      getPayList(Object.assign({}, {
        agentId: this.agentId
      }, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.listLoading = false
      }).catch(res => {
        this.listLoading = false
      })
    }
  }
}
</script>

<style scoped>
</style>
