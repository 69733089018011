<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="服务商名称">
          <el-input v-model="formData.taxName" placeholder="请输入服务商名称" />
        </el-form-item>
        <el-form-item label="创建时间">
          <el-date-picker v-model="formData.startDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          至
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="formData.endDate" type="date" value-format="yyyy-MM-dd" placeholder="请选择时间" style="width: 160px;" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">
        添加服务商
      </el-button>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="taxName"
          label="服务商名称"
          align="center"
        />
        <el-table-column
          prop="linkMan"
          label="联系人"
          align="center"
        />
        <el-table-column
          prop="linkMobile"
          label="联系人手机号"
          align="center"
        />
        <el-table-column
          label="总包+分包"
          align="center"
        >
          <template slot-scope="scope">
            <i v-if="parseInt(scope.row.totalStatus) === 1" class="text-success el-icon-success" />
            <i v-else class="text-danger el-icon-error" />
          </template>
        </el-table-column>
        <el-table-column
          label="众包"
          align="center"
        >
          <template slot-scope="scope">
            <i v-if="parseInt(scope.row.manyStatus) === 1" class="text-success el-icon-success" />
            <i v-else class="text-danger el-icon-error" />
          </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center"
        >
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.taxStatus, $enum.authStatus(), true)" />
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.createDate }}
          </template>
        </el-table-column>
        <el-table-column
          width="240"
          label="操作"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
            <el-button type="text" size="small" @click="handleStatus(scope.row)">{{ (+scope.row.taxStatus) === 0 ? '停用' : '启用' }}</el-button>
            <el-button type="text" size="small" @click="handleShowIndividual(scope.row)">个体户</el-button>
            <el-button type="text" size="small" @click="handleShowPay(scope.row)">支付方式</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getList, changeStatus } from '@/api/service'

export default {
  data() {
    return {
      loading: false,
      list: [],
      formData: {
        startDate: '',
        endDate: '',
        taxName: ''
      },
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    // 因为这个页面没有做缓存，所以要留着此方法
    this.fetchData()
  },
  activated() {
    // console.log(this.$options.name + '服务商管理-----activated')
    // if (this.$store.getters.isUpdatePage) {
    //   console.log(this.$options.name + '服务商管理-----activated--刷新数据')
    //   this.$store.commit('common/setUpdatePage', false)
    //   this.fetchData()
    // }
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getList(Object.assign({}, this.formData, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleAdd() {
      this.$store.commit('common/setUpdatePage', true)
      this.$router.push({
        path: '/service/add'
      })
    },
    handleEdit(i) {
      this.$router.push({
        path: '/service/edit',
        query: {
          id: i.taxId
        }
      })
    },
    handleStatus(row) {
      const status = (+row.taxStatus)
      this.$confirm('你确定要' + (status === 0 ? '停用' : '启用') + '服务商吗？', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        const newVal = status === 0 ? 1 : 0
        changeStatus({
          taxStatus: newVal,
          taxId: row.taxId
        }).then(res => {
          this.loading = false
          row.taxStatus = newVal
          this.$message.success(res.message)
        }).catch(res => {
          this.loading = false
        })
      }).catch(res => {
      })
    },
    handleShow(i) {
      this.$router.push({
        path: '/service/view',
        query: {
          id: i.taxId
        }
      })
    },
    handleShowPay(i) {
      this.$router.push({
        path: '/service/pay',
        query: {
          id: i.taxId
        }
      })
    },
    handleShowIndividual(row) {
      this.$router.push({
        path: '/service/individualBusiness/index',
        query: {
          taxId: row.taxId
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
