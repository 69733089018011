var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-m-25 u-p-30 bg-white" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formData",
          staticStyle: { "max-width": "800px" },
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "250px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "姓名", prop: "realName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入姓名" },
                model: {
                  value: _vm.formData.realName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "realName", $$v)
                  },
                  expression: "formData.realName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "角色", prop: "roleName" } },
            [
              _c(
                "el-select",
                {
                  attrs: { filterable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.formData.roleName,
                    callback: function ($$v) {
                      _vm.$set(_vm.formData, "roleName", $$v)
                    },
                    expression: "formData.roleName",
                  },
                },
                _vm._l(_vm.roleList, function (i, k) {
                  return _c("el-option", {
                    key: k,
                    attrs: { label: i, value: i },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "手机号码", prop: "mobileCode" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入手机号" },
                model: {
                  value: _vm.formData.mobileCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "mobileCode", $$v)
                  },
                  expression: "formData.mobileCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "登录账号", prop: "userName" } },
            [
              _c("el-input", {
                attrs: { placeholder: "请输入登录账号" },
                model: {
                  value: _vm.formData.userName,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "userName", $$v)
                  },
                  expression: "formData.userName",
                },
              }),
            ],
            1
          ),
          _c("el-form-item", { attrs: { label: "权限", required: "" } }, [
            _c(
              "div",
              { staticClass: "u-border u-p-20" },
              [
                _c("el-tree", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.treeLoading,
                      expression: "treeLoading",
                    },
                  ],
                  ref: "tree",
                  attrs: {
                    data: _vm.menus,
                    "show-checkbox": "",
                    "node-key": "id",
                    "default-checked-keys": _vm.menuId,
                    props: _vm.defaultProps,
                  },
                }),
              ],
              1
            ),
          ]),
          !_vm.formData.id
            ? _c(
                "el-form-item",
                { attrs: { label: "初始密码", prop: "passWord" } },
                [
                  _c("el-input", {
                    staticClass: "no-autofill-pwd",
                    attrs: {
                      type: "text",
                      placeholder: "请输入初始密码",
                      autocomplete: "new-password",
                    },
                    model: {
                      value: _vm.formData.passWord,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "passWord", $$v)
                      },
                      expression: "formData.passWord",
                    },
                  }),
                ],
                1
              )
            : _c(
                "el-form-item",
                { attrs: { label: "修改密码" } },
                [
                  _c("el-input", {
                    staticClass: "no-autofill-pwd",
                    attrs: {
                      type: "text",
                      placeholder: "为空则不修改",
                      autocomplete: "new-password",
                    },
                    model: {
                      value: _vm.formData.passWord,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "passWord", $$v)
                      },
                      expression: "formData.passWord",
                    },
                  }),
                ],
                1
              ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }