<template>
  <div class="u-form-item">
    <div class="u-flex u-row-between u-col-top u-m-t-20">
      <div>
        <el-checkbox v-model="options.crowdOneCheck">众包单人单开税率：</el-checkbox>
      </div>
      <div class="u-flex-1" :class="options.crowdOneCheck && options.crowdOneMonth ? '' : 'box-un-active'">
        <el-checkbox v-model="options.crowdOneMonth" :disabled="!options.crowdOneCheck" @change="changeCheck('crowdOneMonth', $event)">月度</el-checkbox>
        <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
          <div class="u-flex-1">起始金额</div>
          <div class="u-flex-1">结束金额</div>
          <div class="u-flex-1">税率</div>
          <div style="width: 50px" />
        </div>
        <div v-for="(i, k) in crowdOneMonthList" :key="i.guid" :class="errorIndex.crowdOneMonth === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
          <div class="u-flex-2 u-flex u-col-center u-row-center">
            <el-input v-model="i.startMoney" readonly placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
            <span class="u-p-lf-5">至</span>
            <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('crowdOneMonthList', k, $event)">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
          </div>
          <div class="u-flex-1 u-p-l-10">
            <el-input v-model="i.rate" placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">%</span>
            </el-input>
          </div>
          <div class="u-p-l-5" style="width: 50px">
            <i v-if="k !== 0 && k === (crowdOneMonthList.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('crowdOneMonthList', k)" />
          </div>
        </div>
        <div class="u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point" style="width: 100px;margin: auto" @click="addList('crowdOneMonthList')">
          <i class="el-icon-circle-plus-outline u-font-24" />添加
        </div>
      </div>
      <div class="u-flex-1" :class="options.crowdOneCheck && options.crowdOneQuarterly ? '' : 'box-un-active'">
        <el-checkbox v-model="options.crowdOneQuarterly" :disabled="!options.crowdOneCheck" @change="changeCheck('crowdOneQuarterly', $event)">季度</el-checkbox>
        <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
          <div class="u-flex-1">起始金额</div>
          <div class="u-flex-1">结束金额</div>
          <div class="u-flex-1">税率</div>
          <div style="width: 50px" />
        </div>
        <div v-for="(i, k) in crowdOneQuarterlyList" :key="i.guid" :class="errorIndex.crowdOneQuarterly === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
          <div class="u-flex-2 u-flex u-col-center u-row-center">
            <el-input v-model="i.startMoney" readonly placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
            <span class="u-p-lf-5">至</span>
            <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('crowdOneQuarterlyList', k, $event)">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
          </div>
          <div class="u-flex-1 u-p-l-10">
            <el-input v-model="i.rate" placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">%</span>
            </el-input>
          </div>
          <div class="u-p-l-5" style="width: 50px">
            <i v-if="k !== 0 && k === (crowdOneQuarterlyList.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('crowdOneQuarterlyList', k)" />
          </div>
        </div>
        <div class="u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point" style="width: 100px;margin: auto" @click="addList('crowdOneQuarterlyList')">
          <i class="el-icon-circle-plus-outline u-font-24" />添加
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGuid } from '@/utils'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    crowdChecked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      subLabelWidth: '150px',
      options: {
        crowdAllCheck: false, // 众包汇总代开
        crowdAllMonth: false, // 众包汇总月度
        crowdAllQuarterly: false, // 众包汇总季度
        crowdOneCheck: false, // 众包单开
        crowdOneMonth: false,
        crowdOneQuarterly: false
      },
      // 错误行数
      errorIndex: {
        crowdOneCheck: undefined, // 众包单开
        crowdOneMonth: undefined,
        crowdOneQuarterly: undefined
      },
      crowdAllMonthList: [], // 众包汇总月度梯度
      crowdAllQuarterlyList: [], // 众包汇总季度梯度
      crowdOneMonthList: [], // 众包单开月度梯度
      crowdOneQuarterlyList: [] // 众包单开季度梯度
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler: function(newVal) {
        this.initialize(newVal || [])
      }
    }
  },
  methods: {
    // 梯度价上修改
    changeEnd(name, index, val) {
      if (this[name].length > index + 1) {
        this[name][index + 1].startMoney = val
      }
    },
    /**
     * 梯度价初始化
     */
    initialize(data) {
      data.map(val => {
        // 总包+分包
        const packageStatus = parseInt(val.packaegStatus)
        const status = parseInt(val.status)
        let name
        // 汇总
        if (packageStatus === 3) {
          this.options.crowdAllCheck = true
          if (status === 0) {
            // 月
            name = 'crowdAllMonthList'
            this.options.crowdAllMonth = true
          } else if (status === 1) {
            // 季度
            name = 'crowdAllQuarterlyList'
            this.options.crowdAllQuarterly = true
          }
        } else if (packageStatus === 2) { // 单开
          this.options.crowdOneCheck = true
          if (status === 0) {
            // 月
            name = 'crowdOneMonthList'
            this.options.crowdOneMonth = true
          } else if (status === 1) {
            // 季度
            this.options.crowdOneQuarterly = true
            name = 'crowdOneQuarterlyList'
          }
        }
        if (name) {
          this.addList(name, val)
        }
      })
    },
    changeCheck(name, val) {
      if (val) {
        if (name === 'crowdOneMonth') {
          this.options.crowdOneQuarterly = false
        } else if (name === 'crowdOneQuarterly') {
          this.options.crowdOneMonth = false
        }
      }
    },
    /**
     * 添加梯度价
     * @param name 变量名
     * @param data
     */
    addList(name, data = {}) {
      data = Object.assign({}, data)
      const guid = getGuid()
      if (this.$u.test.isEmptyNotZero(data.startMoney)) {
        const length = this[name].length
        if (length === 0) {
          data.startMoney = 0
        } else {
          const last = this[name][length - 1]
          if (!this.$u.test.validateRange(last.endMoney, '0~')) {
            return this.$message.error('请填写正确的梯度价')
          }
          if ((+last.startMoney) >= (+last.endMoney)) {
            return this.$message.error('请填写正确的梯度价')
          }
          if (!this.$u.test.validateRange(last.rate, '0~')) {
            return this.$message.error('请填写正确的服务费率')
          }
          data.startMoney = last.endMoney
        }
      }
      this[name].push({
        'guid': guid,
        'id': data.id,
        'startMoney': data.startMoney || 0,
        'endMoney': data.endMoney || '',
        'rate': data.rate || ''
      })
    },
    /**
     * 删除梯度价
     * @param name 变量名
     * @param index 序号
     */
    removeList(name, index) {
      this[name].splice(index, 1)
    },
    /**
     * 验证梯度价
     * @param list
     * @param withObj 每个梯度价附加信息
     * @returns {{msg: string, data: [], success: boolean}}
     */
    validateLadders(list, withObj) {
      if (!list.length) {
        return {
          success: false,
          data: [],
          index: undefined,
          msg: '数据不能为空'
        }
      }
      const newData = []
      let result = true
      let msg = ''
      let preRowEndMoney = 0 // 上一行的结束金额
      let index
      for (let i = 0, len = list.length; i < len; i++) {
        const item = list[i]
        if (!this.$u.test.validateRange(item.rate, '0~100')) {
          result = false
          index = i
          break
        }
        if (!this.$u.test.validateRange(item.startMoney, preRowEndMoney + '~')) {
          result = false
          index = i
          break
        }
        item.startMoney = parseFloat(item.startMoney)
        if (!this.$u.test.validateRange(item.endMoney, item.startMoney + '~')) {
          result = false
          index = i
          break
        }
        item.endMoney = parseFloat(item.endMoney)
        preRowEndMoney = item.endMoney
        item.rate = parseFloat(item.rate)
        newData.push(Object.assign({}, item, withObj || {}))
      }
      if (!result) {
        msg = '梯度价不正确'
      }
      return {
        success: result,
        index: index,
        data: newData,
        msg: msg
      }
    },
    getLadders() {
      const options = this.options
      let crowdLadders = []
      if (this.crowdChecked) {
        // 众包
        if (options.crowdAllCheck) {
          // 众包汇总
          if (options.crowdAllMonth) {
            // 月梯度价
            const validateData = this.validateLadders(this.crowdAllMonthList, {
              'packaegStatus': 4,
              'status': 0
            })
            this.errorIndex.crowdAllMonth = validateData.index
            if (!validateData.success) {
              this.$message.error('众包汇总月梯度价不正确')
              return false
            }
            crowdLadders = crowdLadders.concat(validateData.data)
          }
          if (options.crowdAllQuarterly) {
            // 季度
            const validateData = this.validateLadders(this.crowdAllQuarterlyList, {
              'packaegStatus': 4,
              'status': 1
            })
            this.errorIndex.crowdAllQuarterly = validateData.index
            if (!validateData.success) {
              this.$message.error('众包汇总季度梯度价不正确')
              return false
            }
            crowdLadders = crowdLadders.concat(validateData.data)
          }
        }
        // 众包单开
        if (options.crowdOneCheck) {
          if (options.crowdOneMonth) {
            // 月梯度价
            const validateData = this.validateLadders(this.crowdOneMonthList, {
              'packaegStatus': 2,
              'status': 0
            })
            this.errorIndex.crowdOneMonth = validateData.index
            if (!validateData.success) {
              this.$message.error('众包单开月梯度价不正确')
              return false
            }
            crowdLadders = crowdLadders.concat(validateData.data)
          }
          if (options.crowdOneQuarterly) {
            // 季度
            const validateData = this.validateLadders(this.crowdOneQuarterlyList, {
              'packaegStatus': 2,
              'status': 1
            })
            this.errorIndex.crowdOneQuarterly = validateData.index
            if (!validateData.success) {
              this.$message.error('众包单开季度梯度价不正确')
              return false
            }
            crowdLadders = crowdLadders.concat(validateData.data)
          }
        }
      }
      return crowdLadders
    }
  }
}
</script>

<style scoped lang="scss">
  .u-form-item {
    font-size: 14px;
    ::v-deep {
      .el-form-item__content {
        max-width: 480px;
      }
      .auto-width {
        .el-form-item__content {
          max-width: 800px;
        }
      }
    }
  }
  .error-line {
    ::v-deep {
      .el-input__inner {
        border-color: #f56c6c;
      }
    }
  }
  .box-un-active {
    .error-line {
      ::v-deep {
        .el-input__inner {
          border-color: #dcdfe6;
        }
      }
    }
    opacity: .3;
  }
</style>
