<template>
  <div>
    <el-form ref="formData" :model="value" label-width="250px">
      <div :class="value.settlementMethodCalc.waterCommission ? '' : 'box-uncheck'">
        <el-row>
          <el-checkbox v-model="value.settlementMethodCalc.waterCommission">按流水提成（月度）</el-checkbox>
        </el-row>

        <div class="u-border u-m-t-10 u-p-20" style="position: relative">
          <el-row>
            <div class="u-m-t-20 u-p-r-20">直属商户</div>
          </el-row>

          <el-row :gutter="20" class="u-m-t-10 u-p-r-20">
            <el-col :md="12" :sm="24">
              <div class="box">
                <div class="title" style="text-align:center;">总包+分包（6%专票）</div>
                <div class="content">
                  <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                    <div class="u-flex-1">月度</div>
                    <div class="u-flex-1">提成率</div>
                    <div style="width: 50px" />
                  </div>
                  <div v-for="(i, k) in value.directCommissionProportionStatus0" :key="i.guid || i.id" :class="errorIndex.direct === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
                    <div class="u-flex-2 u-flex u-col-center u-row-center">
                      <el-input v-model="i.startMoney" readonly placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                      <span class="u-p-lf-5">至</span>
                      <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('directCommissionProportionStatus0', k, $event)">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                    </div>
                    <div class="u-flex-1 u-p-l-10">
                      <el-input v-model="i.commissionRate" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">%</span>
                      </el-input>
                    </div>
                    <div class="u-p-l-5" style="width: 50px">
                      <i v-if="k === (value.directCommissionProportionStatus0.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('directCommissionProportionStatus0', k)" />
                    </div>
                  </div>
                  <div class="u-m-t-20 u-text-center u-flex u-col-center u-point" style="width: 100px;" @click="addList('directCommissionProportionStatus0',{'packageStatus':0})">
                    <i class="el-icon-circle-plus-outline u-font-24" />添加
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :md="12" :sm="24">
              <div class="box">
                <div class="title" style="text-align:center;">众包(3%普票)</div>
                <div class="content">
                  <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                    <div class="u-flex-1">月度</div>
                    <div class="u-flex-1">提成率</div>
                    <div style="width: 50px" />
                  </div>
                  <div v-for="(i, k) in value.directCommissionProportionStatus1" :key="i.guid || i.id" :class="errorIndex.agent === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
                    <div class="u-flex-2 u-flex u-col-center u-row-center">
                      <el-input v-model="i.startMoney" readonly placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                      <span class="u-p-lf-5">至</span>
                      <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('directCommissionProportionStatus1', k, $event)">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                    </div>
                    <div class="u-flex-1 u-p-l-10">
                      <el-input v-model="i.commissionRate" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">%</span>
                      </el-input>
                    </div>
                    <div class="u-p-l-5" style="width: 50px">
                      <i v-if="k === (value.directCommissionProportionStatus1.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('directCommissionProportionStatus1', k)" />
                    </div>
                  </div>
                  <div class="u-m-t-20 u-text-center u-flex u-col-center u-point" style="width: 100px;" @click="addList('directCommissionProportionStatus1',{'packageStatus':1})">
                    <i class="el-icon-circle-plus-outline u-font-24" />添加
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>

          <el-row>
            <div class="u-m-t-30 u-p-r-20">下属代理商</div>
          </el-row>
          <el-row :gutter="20" class="u-m-t-10 u-p-r-20">
            <el-col :md="12" :sm="24">
              <div class="box">
                <div class="title" style="text-align:center;">总包+分包（6%专票）</div>
                <div class="content">
                  <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                    <div class="u-flex-1">月度</div>
                    <div class="u-flex-1">提成率</div>
                    <div style="width: 50px" />
                  </div>
                  <div v-for="(i, k) in value.agentCommissionProportionStatus0" :key="i.guid || i.id" :class="errorIndex.direct === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
                    <div class="u-flex-2 u-flex u-col-center u-row-center">
                      <el-input v-model="i.startMoney" readonly placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                      <span class="u-p-lf-5">至</span>
                      <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('agentCommissionProportionStatus0', k, $event)">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                    </div>
                    <div class="u-flex-1 u-p-l-10">
                      <el-input v-model="i.commissionRate" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">%</span>
                      </el-input>
                    </div>
                    <div class="u-p-l-5" style="width: 50px">
                      <i v-if="k === (value.agentCommissionProportionStatus0.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('agentCommissionProportionStatus0', k)" />
                    </div>
                  </div>
                  <div class="u-m-t-20 u-text-center u-flex u-col-center u-point" style="width: 100px;" @click="addList('agentCommissionProportionStatus0',{'packageStatus':0})">
                    <i class="el-icon-circle-plus-outline u-font-24" />添加
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :md="12" :sm="24">
              <div class="box">
                <div class="title" style="text-align:center;">众包(3%普票)</div>
                <div class="content">
                  <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                    <div class="u-flex-1">月度</div>
                    <div class="u-flex-1">提成率</div>
                    <div style="width: 50px" />
                  </div>
                  <div v-for="(i, k) in value.agentCommissionProportionStatus1" :key="i.guid || i.id" :class="errorIndex.agent === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
                    <div class="u-flex-2 u-flex u-col-center u-row-center">
                      <el-input v-model="i.startMoney" readonly placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                      <span class="u-p-lf-5">至</span>
                      <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('agentCommissionProportionStatus1', k, $event)">
                        <span slot="suffix" style="line-height: 40px">元</span>
                      </el-input>
                    </div>
                    <div class="u-flex-1 u-p-l-10">
                      <el-input v-model="i.commissionRate" placeholder="请输入">
                        <span slot="suffix" style="line-height: 40px">%</span>
                      </el-input>
                    </div>
                    <div class="u-p-l-5" style="width: 50px">
                      <i v-if="k === (value.agentCommissionProportionStatus1.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('agentCommissionProportionStatus1', k)" />
                    </div>
                  </div>
                  <div class="u-m-t-20 u-text-center u-flex u-col-center u-point" style="width: 100px;" @click="addList('agentCommissionProportionStatus1',{'packageStatus':1})">
                    <i class="el-icon-circle-plus-outline u-font-24" />添加
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <!-- <div :class="value.settlementMethodCalc.serviceRateBalance ? '' : 'box-uncheck'">
        <el-row class="u-m-t-50">
          <el-checkbox v-model="value.settlementMethodCalc.serviceRateBalance">按服务费率差额提成</el-checkbox>
        </el-row>

        <div class="u-border u-m-t-10 u-p-20">
          <div v-for="item in value.serviceRateBalanceList" :key="item.taxName" class="u-m-t-10">
            <el-row>
              <el-col :md="24" :sm="24" class="u-m-t-20 u-p-r-20">平台服务商： {{ item.taxName }}</el-col>
            </el-row>
            <div class="u-border u-m-t-10">
              <el-row :gutter="10">
                <el-col :md="12" :sm="24" class="u-m-t-10 u-p-20">
                  <el-form>
                    <el-form-item label="总包+分包（6%专票）" label-width="160px" />
                    <el-radio>一口价</el-radio>
                    <el-form-item label="综合税费率" label-width="90px">
                      <el-input
                        :key="item.taxId"
                        v-model="item.taxItem['0'].taxPrice"
                        type="number"
                        readonly
                        style="width: 70%"
                      >
                        <span slot="suffix" style="line-height: 40px">%</span>
                      </el-input>
                      <div class="u-tips u-text-center">参考值(不低于)：{{ item.taxItem['0'].taxPrice }}%</div>
                    </el-form-item>

                    <div v-if="item.taxItem['0'].invoiceLadderPriceDetailVOList">
                      <el-radio>梯度价</el-radio>
                      <el-form-item>
                        <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                          <div class="u-flex-2">单人单月额度</div>
                          <div class="u-flex-1">服务费率</div>
                          <div style="width: 50px" />
                        </div>
                        <div v-for="(i, k) in item.taxItem['0'].invoiceLadderPriceDetailVOList" :key="k" class="u-m-t-10">
                          <div class="u-flex ">
                            <div class="u-flex-2 u-flex u-col-center u-row-center">
                              <el-input v-model="i.startMoney" readonly placeholder="请输入">
                                <span slot="suffix" style="line-height: 40px">元</span>
                              </el-input>
                              <span class="u-p-lf-5">至</span>
                              <el-input v-model="i.endMoney" readonly placeholder="请输入">
                                <span slot="suffix" style="line-height: 40px">元</span>
                              </el-input>
                            </div>
                            <div class="u-flex-1 u-p-l-10">
                              <el-input
                                v-model="i.rate"
                                placeholder="请输入"
                                readonly
                              >
                                <span slot="suffix" style="line-height: 40px">%</span>
                              </el-input>
                            </div>
                          </div>
                          <div class="u-tips u-text-right">参考值(不低于)：
                            {{ i.rate || 0 }}%
                          </div>
                        </div>
                      </el-form-item>
                    </div>
                  </el-form>

                </el-col>
                <el-col :md="12" :sm="24" class="u-m-t-10 u-p-20">
                  <el-form>
                    <el-form-item label="众包(3%普票)" label-width="105px" />
                    <el-radio>一口价</el-radio>
                    <el-form-item label="综合税费率" label-width="90px">
                      <el-input
                        :key="item.taxId"
                        v-model="item.taxItem['1'].taxPrice"
                        type="number"
                        readonly
                        style="width: 70%"
                      >
                        <span slot="suffix" style="line-height: 40px">%</span>
                      </el-input>
                      <div class="u-tips u-text-center">参考值(不低于)：{{ item.taxItem['1'].taxPrice }}%</div>
                    </el-form-item>

                    <div v-if="item.taxItem['1'].invoiceLadderPriceDetailVOList">
                      <el-radio>梯度价</el-radio>
                      <el-form-item>
                        <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
                          <div class="u-flex-2">单人单月额度</div>
                          <div class="u-flex-1">服务费率</div>
                          <div style="width: 50px" />
                        </div>
                        <div v-for="(i, k) in item.taxItem['1'].invoiceLadderPriceDetailVOList" :key="k" class="u-m-t-10">
                          <div class="u-flex u-col-center u-row-center">
                            <div class="u-flex-2 u-flex u-col-center u-row-center">
                              <el-input v-model="i.startMoney" readonly placeholder="请输入">
                                <span slot="suffix" style="line-height: 40px">元</span>
                              </el-input>
                              <span class="u-p-lf-5">至</span>
                              <el-input v-model="i.endMoney" readonly placeholder="请输入">
                                <span slot="suffix" style="line-height: 40px">元</span>
                              </el-input>
                            </div>
                            <div class="u-flex-1 u-p-l-10">
                              <el-input
                                v-model="i.rate"
                                placeholder="请输入"
                                readonly
                              >
                                <span slot="suffix" style="line-height: 40px">%</span>
                              </el-input>
                            </div>
                          </div>
                          <div class="u-tips u-text-right">参考值(不低于)：
                            {{ i.rate || 0 }}%
                          </div>
                        </div>
                      </el-form-item>
                    </div>

                  </el-form>

                </el-col>
              </el-row>
            </div>

          </div>
        </div>
      </div> -->
    </el-form>
  </div>
</template>

<script>
import { getGuid } from '@/utils'

export default {
  props: {
    value: {
      type: Object,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      errorIndex: {
        direct: null,
        agent: null
      }
    }
  },
  methods: {
    // 梯度价上修改
    changeEnd(name, index, val) {
      if (this.value[name].length > index + 1) {
        this.value[name][index + 1].startMoney = val
      }
    },
    removeList(name, index) {
      this.value[name].splice(index, 1)
    },
    addList(name, data = {}) {
      data = Object.assign({}, data)
      const guid = getGuid()
      if (this.$u.test.isEmptyNotZero(data.startMoney)) {
        const length = this.value[name].length
        if (length === 0) {
          data.startMoney = 0
        } else {
          const last = this.value[name][length - 1]
          if (!this.$u.test.validateRange(last.endMoney, '0~')) {
            return this.$message.error('请填写正确的梯度价')
          }
          if ((+last.startMoney) >= (+last.endMoney)) {
            return this.$message.error('请填写正确的梯度价')
          }
          if (!this.$u.test.validateRange(last.commissionRate, '0~')) {
            return this.$message.error('请填写正确的服务费率')
          }
          data.startMoney = last.endMoney
        }
      }
      this.value[name].push({
        'guid': guid,
        'commissionRate': data.commissionRate || null,
        'customerType': data.customerType || (name.indexOf('directCommissionProportion') !== -1 ? 0 : 1), // 0代表直客，1代表代理商
        'endMoney': data.endMoney || null,
        'id': data.id || undefined,
        'objectId': data.objectId, // 业务员或代理商id
        'objectType': 0, // 0代表业务，1代表代理商
        'startMoney': data.startMoney || 0,
        'packageStatus': data.packageStatus || 0
      })
    }
  }
}
</script>

<style scoped lang="scss">
  .box {
    border: 1px solid #ccc;
    padding: 0;
    font-size: 14px;
    .title {
      padding: 10px 20px;
      border-bottom: 1px solid #ccc;
      font-size: 15px;
      font-weight: 500;
    }
    .content {
      padding: 10px 20px;
    }
  }

  .box-uncheck {
    opacity: .3;
  }
</style>
