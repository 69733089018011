var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "section-body",
          attrs: { gutter: 10 },
        },
        [
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("任务编号:")]),
            _c("span", [_vm._v(_vm._s(_vm.item.taskCode))]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("商户名称:")]),
            _c("span", [_vm._v(_vm._s(_vm.item.merchantName))]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("任务名称:")]),
            _c("span", [_vm._v(_vm._s(_vm.item.taskName))]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("任务说明文字:")]),
            _c("span", [_vm._v(_vm._s(_vm.item.taskDesc))]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("费用:")]),
            _c("span", [
              _vm._v(
                _vm._s(_vm.item.taskCostMin) +
                  " 至 " +
                  _vm._s(_vm.item.taskCostMax)
              ),
            ]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("创客所需技能:")]),
            _c("span", [_vm._v(_vm._s(_vm.item.taskSkill))]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("任务上限人数:")]),
            _c("span", [_vm._v(_vm._s(_vm.item.upperLimit))]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("合作类型:")]),
            _c("span", [
              _vm._v(_vm._s(_vm._f("cooperateMode")(_vm.item.cooperateMode))),
            ]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("任务模式:")]),
            _c("span", [_vm._v(_vm._s(_vm._f("taskMode")(_vm.item.taskMode)))]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("状态:")]),
            _c("span", [_vm._v(_vm._s(_vm._f("taskState")(_vm.item.state)))]),
          ]),
          _c("el-col", { attrs: { span: _vm.md } }, [
            _c("span", { staticClass: "label" }, [_vm._v("发布时间:")]),
            _c("span", [_vm._v(_vm._s(_vm.item.createDate))]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }