import request from '@/utils/request';

/**
 * 总包开票，待开票列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getWaitList(data) {
  return request({
    url: '/platform/invoice/getPlaInvoiceList',
    method: 'post',
    data,
  });
}
/**
 * 总包开票，待开票列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getWaitApplyList(data) {
  return request({
    url: '/platform/invoice/getAgentInvoiced',
    method: 'post',
    data,
  });
}
/**
 * 获取支付明细
 * @param params
 * @returns {AxiosPromise}
 */
export function getPayList(params) {
  return request({
    url: '/platform/invoice/getTotalBranchList',
    method: 'post',
    params,
  });
}

/**
 * 已开票列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getDoneList(data) {
  return request({
    url: '/platform/invoice/listInvoiceQuery',
    method: 'post',
    data,
  });
}

/**
 * 总包开票，详情数据
 * @param params
 * @returns {AxiosPromise}
 */
export function getPlaInvoiceInfo(params) {
  return request({
    url: '/platform/invoice/getPlaInvoiceInfo',
    method: 'post',
    params,
  });
}

/**
 * 总包开票提及
 * @param data
 * @returns {AxiosPromise}
 */
export function addInvoice(data) {
  return request({
    url: '/platform/invoice/saveInvoice',
    method: 'post',
    data,
  });
}

/**
 * 总包开票，编辑详情数据
 * @param params
 * @returns {AxiosPromise}
 */
export function updateInvoice(params) {
  return request({
    url: '/platform/invoice/queryInvoice',
    method: 'post',
    params,
  });
}

/** ***********************************/
/**
 * 获取总包已开票详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getDoneDetail(params) {
  return request({
    url: '/merchant/invoice/getInvInfoById',
    method: 'post',
    params,
  });
}

/**
 * 添加申请开票信息
 * @param params
 * @returns {AxiosPromise}
 */
export function addInvApplication(params) {
  return request({
    url: '/merchant/invoice/addInvApplication',
    method: 'post',
    params,
  });
}

/**
 * 开票信息，支付
 * @param params
 * @returns {AxiosPromise}
 */
export function getBillingInfo(params) {
  return request({
    url: '/merchant/invoice/getBillingInfo',
    method: 'get',
    params,
  });
}

/**
 * 去申请开票
 * @param params
 * @returns {AxiosPromise}
 */
export function goInvApplication(params) {
  return request({
    url: '/merchant/invoice/goInvApplication',
    method: 'post',
    params,
  });
}

/**
 * 开票类目
 * @param params
 * @returns {AxiosPromise}
 */
export function getListInv(params) {
  return request({
    url: '/merchant/invoice/getListInv',
    method: 'get',
    params,
  });
}

/**
 * 去编辑开票信息
 * @param params
 * @returns {AxiosPromise}
 */
export function queryApplicationInfo(params) {
  return request({
    url: '/merchant/invoice/queryApplicationInfo',
    method: 'post',
    params,
  });
}

/**
 * 修改开票申请
 * @param data
 * @returns {AxiosPromise}
 */
export function updateApplication(data) {
  return request({
    url: '/merchant/invoice/updateApplication',
    method: 'post',
    data,
  });
}

/**
 * 总包发票列表已开票,发票信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getInvInfoById(params) {
  console.log('请求参数:', params);
  return request({
    url: '/merchant/invoice/getInvInfoById',
    method: 'post',
    params,
  });
}
export function deleteTax(params) {
  return request({
    url: '/platform/invoice/deleteInvoiceApplication',
    method: 'post',
    params,
  });
}
