<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card>
          <div class="u-flex u-col-center user-base-box">
            <div class="name u-flex u-col-center u-flex-1">
              <avatar class="u-m-r-10" :src="queryData.user_head" :size="50" />
              <div>
                <div class="u-font-18">商户：{{ queryData.merchantName }}</div>
                <div class="u-font-14 u-m-t-5">商户编号：{{ queryData.merchantId }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div class="u-flex u-col-center user-base-box">
            <div class="name u-flex u-col-center u-flex-1">
              <avatar class="u-m-r-10" :src="queryData.user_head" :size="50" />
              <div>
                <div class="u-font-18">服务商：{{ queryData.taxName }}</div>
                <div class="u-font-14 u-m-t-5">服务商编号：{{ queryData.taxId }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row v-loading="countLoading" :gutter="20">
      <el-col :md="{span: 'w20'}" :sm="8" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">最近30天支付流水</div>
          <div>
            <div class="u-font-14 u-tips-color">总包+分包</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.payment30TotalMoney) || 0 }}</div>
            <div class="u-font-14 u-tips-color u-m-t-10">众包</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.payment30ManyMoney) || 0 }}</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="{span: 'w20'}" :sm="8" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">总支付流水</div>
          <div>
            <div class="u-font-14 u-tips-color">总包+分包</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.paymentTotalMoney) || 0 }}</div>
            <div class="u-font-14 u-tips-color u-m-t-10">众包</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.paymentManyMoney) || 0 }}</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="{span: 'w20'}" :sm="8" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">总包+分包发票</div>
          <div>
            <div class="u-font-14 u-tips-color">已开票数</div>
            <div class="u-font-20 u-m-t-5">{{ count.invoiceTotalCount || 0 }}</div>
            <div class="u-font-14 u-tips-color u-m-t-10">已开票金额</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.invoiceTotalMoney) || 0 }}</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="{span: 'w20'}" :sm="12" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">众包发票</div>
          <div>
            <div class="u-font-14 u-tips-color">已开票数</div>
            <div class="u-font-20 u-m-t-5">{{ count.invoiceManyCount || 0 }}</div>
            <div class="u-font-14 u-tips-color u-m-t-10">已开票金额</div>
            <div class="u-font-20 u-m-t-5">￥ {{ $u.formatMoney(count.invoiceManyMoney) || 0 }}</div>
          </div>
        </el-card>
      </el-col>
      <el-col :md="{span: 'w20'}" :sm="12" class="u-m-t-20">
        <el-card style="min-height: 200px">
          <div slot="header" class="u-font-14">商户&创客</div>
          <div>
            <div class="u-flex u-col-center u-row-between">
              <div>
                <div class="u-font-14 u-tips-color">总商户数</div>
                <div class="u-font-20 u-m-t-5">{{ count.merchantTotal || 0 }}</div>
              </div>
              <div>
                <div class="u-font-14 u-tips-color">总创客数</div>
                <div class="u-font-20 u-m-t-5">{{ count.workerTotal || 0 }}</div>
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-card header="成交明细列表" class="u-m-t-20">
      <pay-list :merchant-id="queryData.merchantId" :tax-id="queryData.taxId" />
    </el-card>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getCoopServiceCount } from '@/api/shop'
import PayList from '@/views/shop/components/payList'

export default {
  components: { PayList },
  data() {
    return {
      queryData: {},
      countLoading: false,
      count: {}
    }
  },
  created() {
    const { merchantId, merchantName, taxId, taxName } = this.$route.query
    this.queryData = { merchantId, merchantName, taxId, taxName }
    this.fetchDetail()
  },
  methods: {
    fetchDetail() {
      this.loading = true
      getCoopServiceCount({
        merchantId: this.queryData.merchantId,
        taxId: this.queryData.taxId
      }).then(res => {
        this.count = res.obj || {}
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.renzheng {
  width: 12px;
  height: 12px;
  color: #ffbd00;
}
.user-base-box {
  .name {
    margin-left: 20px;
    font-size: 20px;
    color: #333;
  }

  .tag {
    display: inline-block;
    margin-left: 2px;
    height: 20px;
    line-height: 16px;
    padding: 2px 5px;
    background: rgba(64, 108, 255, 1);
    border-radius: 25px;
    font-size: 9px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .sub {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }

  .value {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
