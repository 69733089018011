import { login, testPassLogin, getInfo, loginByMobile } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'

const getDefaultState = () => {
  return {
    token: getToken(),
    name: '',
    avatar: '',
    roles: [],
    userinfo: {}
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_USER: (state, userinfo) => {
    state.userinfo = userinfo
  }
}

const actions = {
  refreshHead({ commit, state }, src) {
    commit('SET_AVATAR', src)
  },
  // user login
  login({ commit }, userInfo) {
    const { username, password, type, mobile, code, serialNumber } = userInfo
    return new Promise((resolve, reject) => {
      if (type === 'code') {
        loginByMobile({
          mobileCode: mobile,
          checkCode: code
        }).then(response => {
          const token = response.obj
          commit('SET_TOKEN', token)
          // commit('SET_AVATAR', data.avatar)
          // commit('SET_NAME', data.userName)
          // commit('SET_USER', data)
          setToken(token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      } else if (type === 'pwd') {
        login({ username: username.trim(), password: password }).then(response => {
          const token = response.obj
          commit('SET_TOKEN', token)
          // commit('SET_AVATAR', data.userHead)
          // commit('SET_NAME', data.userName)
          // commit('SET_USER', data)
          // commit('SET_ROLES', data.permissions || ['common'])
          setToken(token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      } else {
        testPassLogin({ userName: username.trim(), passWord: password, serialNumber: serialNumber }).then(response => {
          const token = response.obj
          commit('SET_TOKEN', token)
          // commit('SET_AVATAR', data.userHead)
          // commit('SET_NAME', data.userName)
          // commit('SET_USER', data)
          // commit('SET_ROLES', data.permissions || ['common'])
          setToken(token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      }
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        const data = response.obj
        if (!data) {
          return reject({})
        }

        commit('SET_AVATAR', data.userHead)
        commit('SET_NAME', data.realName)
        commit('SET_USER', data)
        commit('SET_ROLES', data.permissions || ['common'])
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      removeToken() // must remove  token  first
      resetRouter()
      commit('RESET_STATE')
      resolve()
      // logout(state.token).then(() => {
      //   removeToken() // must remove  token  first
      //   resetRouter()
      //   commit('RESET_STATE')
      //   resolve()
      // }).catch(error => {
      //   reject(error)
      // })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      removeToken() // must remove  token  first
      commit('RESET_STATE')
      resolve()
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

