import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: (resolve) => require(['@/views/redirect'], resolve),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true,
  },

  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true,
  },

  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/index'),
        meta: { title: '首页', icon: 'home', affix: true },
      },
      {
        path: '/warn/shop/notice',
        component: () => import('@/views/warn/shopNotice'),
        hidden: true,
        meta: {
          title: '通知',
          activeMenu: '/warn/shop',
          parent: { path: '/warn/shop' },
        },
      },
    ],
  },

  // 404 page must be placed at the end !!!
  // { path: '*', redirect: '/404', hidden: true }
];
export const asyncRoutes = [
  {
    path: '/task',
    component: Layout,
    redirect: '/task/index',
    children: [
      {
        path: '/task/index',
        name: 'Task',
        component: () => import('@/views/task/index'),
        meta: { title: '任务管理', icon: 'rw' },
      },
      {
        path: '/task/add',
        // name: 'TaskAdd',
        component: () => import('@/views/task/add'),
        hidden: true,
        meta: {
          title: '发布新任务',
          noCache: false,
          activeMenu: '/task/index',
          parent: { path: '/task/index' },
        },
      },
      {
        path: '/task/view',
        component: () => import('@/views/task/view'),
        hidden: true,
        meta: {
          title: '查看任务详情',
          activeMenu: '/task/index',
          parent: { path: '/task/index' },
        },
      },
      {
        path: '/task/check',
        component: () => import('@/views/task/view'),
        hidden: true,
        meta: {
          title: '验收',
          isCheck: true,
          activeMenu: '/task/index',
          parent: { path: '/task/index' },
        },
      },
    ],
  },

  {
    path: '/pay',
    component: Layout,
    redirect: '/pay/totalAndSub/index',
    meta: { title: '支付管理', icon: 'zf' },
    children: [
      {
        path: '/pay/totalAndSub/index',
        name: 'PayTotal',
        component: () => import('@/views/pay/totalAndSub/index'),
        meta: { title: '总包+分包支付' },
      },
      {
        path: '/pay/totalAndSub/add',
        // name: 'PayTotalAdd',
        component: () => import('@/views/pay/totalAndSub/add'),
        hidden: true,
        meta: {
          title: '新建总包+分包支付',
          activeMenu: '/pay/totalAndSub/index',
          parent: { path: '/pay/totalAndSub/index' },
        },
      },
      {
        path: '/pay/totalAndSub/view',
        // name: 'payTotalShow',
        component: () => import('@/views/pay/totalAndSub/view'),
        hidden: true,
        meta: {
          title: '查看总包+分包支付',
          activeMenu: '/pay/totalAndSub/index',
          parent: { path: '/pay/totalAndSub/index' },
        },
      },
      {
        path: '/pay/totalAndSub/check',
        // name: 'PayTotalCheck',
        component: () => import('@/views/pay/totalAndSub/view'),
        hidden: true,
        meta: {
          title: '审核',
          activeMenu: '/pay/totalAndSub/index',
          isCheck: true,
          parent: { path: '/pay/totalAndSub/index' },
        },
      },
      {
        path: '/pay/totalAndSub/subPay',
        // name: 'SubPay',
        component: () => import('@/views/pay/totalAndSub/view'),
        hidden: true,
        meta: {
          title: '分包支付',
          activeMenu: '/pay/totalAndSub/index',
          isSubPay: true,
          parent: { path: '/pay/totalAndSub/index' },
        },
      },
      {
        path: '/pay/totalAndSub/pay',
        component: () => import('@/views/pay/totalAndSub/pay'),
        hidden: true,
        meta: {
          title: '支付',
          activeMenu: '/pay/totalAndSub/index',
          isEdit: true,
          parent: { path: '/pay/totalAndSub/index' },
        },
      },
      // {
      //   path: '/pay/crowd/index',
      //   name: 'PayCrowd',
      //   component: () => import('@/views/pay/crowd/index'),
      //   meta: { title: '众包支付' },
      // },
      {
        path: '/pay/crowd/add',
        // name: 'PayCrowdAdd',
        component: () => import('@/views/pay/crowd/add'),
        hidden: true,
        meta: {
          title: '新建众包支付',
          activeMenu: '/pay/crowd/index',
          parent: { path: '/pay/crowd/index' },
        },
      },
      {
        path: '/pay/crowd/view',
        // name: 'PayCrowdView',
        component: () => import('@/views/pay/crowd/view'),
        hidden: true,
        meta: {
          title: '查看众包支付',
          activeMenu: '/pay/crowd/index',
          parent: { path: '/pay/crowd/index' },
        },
      },
      {
        path: '/pay/crowd/doPay',
        // name: 'PayCrowdDone',
        component: () => import('@/views/pay/crowd/view'),
        hidden: true,
        meta: {
          title: '确认收款',
          activeMenu: '/pay/crowd/index',
          isPay: true,
          parent: { path: '/pay/crowd/index' },
        },
      },
    ],
  },
  {
    path: '/tax',
    component: Layout,
    // name: 'Tax',
    redirect: 'noRedirect',
    meta: { title: '发票管理', icon: 'fp' },
    children: [
      {
        path: 'total',
        // todo,把Nest单独设置为总包组件，从而实现只缓存当前children
        component: () => import('@/views/tax/Nest'),
        redirect: 'noRedirect',
        alwaysShow: true,
        meta: { title: '【总包+分包】总包发票' },
        children: [
          {
            path: '/tax/total/waitApply',
            // name: 'TotalTaxWaitApply',
            component: () => import('@/views/tax/total/waitApply'),
            meta: {
              title: '总包待申请开票',
            },
          },
          {
            path: '/tax/total/wait',
            // name: 'TotalTaxWait',
            component: () => import('@/views/tax/total/wait'),
            meta: {
              title: '总包待开票',
            },
          },
          {
            path: '/tax/total/done',
            // name: 'TotalDone',
            component: () => import('@/views/tax/total/done'),
            meta: {
              title: '总包已开票',
            },
          },
          {
            path: '/tax/total/wait-editor',
            hidden: true,
            component: () => import('@/views/tax/total/editor'),
            meta: {
              title: '待开票详情',
              activeMenu: '/tax/total/wait',
              parent: { path: '/tax/total/wait' },
            },
          },
          {
            path: '/tax/total/apply-editor',
            hidden: true,
            component: () => import('@/views/tax/total/apply_editor'),
            meta: {
              title: '待开票详情',
              activeMenu: '/tax/total/waitApply',
              parent: { path: '/tax/total/waitApply' },
            },
          },
          {
            path: '/tax/total/done-editor',
            hidden: true,
            component: () => import('@/views/tax/total/editor'),
            meta: {
              title: '已开票详情',
              activeMenu: '/tax/total/done',
              parent: { path: '/tax/total/done' },
            },
          },
        ],
      },
      {
        path: '/tax/subPackage',
        component: () => import('@/views/tax/Nest'),
        meta: { title: '【总包+分包】分包发票' },
        alwaysShow: true,
        redirect: 'noRedirect',
        children: [
          {
            path: '/tax/subPackage/wait',
            // name: 'SubWait',
            component: () => import('@/views/tax/subPackage/wait'),
            meta: {
              title: '分包待开票',
            },
          },
          {
            path: '/tax/subPackage/done',
            // name: 'SubDone',
            component: () => import('@/views/tax/subPackage/done'),
            meta: {
              title: '分包已开票',
            },
          },
          {
            path: '/tax/subPackage/wait-editor',
            hidden: true,
            component: () => import('@/views/tax/subPackage/editor'),
            meta: {
              title: '待开票详情',
              activeMenu: '/tax/subPackage/wait',
              parent: { path: '/tax/subPackage/wait' },
            },
          },
          {
            path: '/tax/subPackage/done-editor',
            hidden: true,
            component: () => import('@/views/tax/subPackage/editor'),
            meta: {
              title: '已开票详情',
              activeMenu: '/tax/subPackage/done',
              parent: { path: '/tax/subPackage/done' },
            },
          },
        ],
      },
      // {
      //   path: '/tax/morePackage',
      //   component: () => import('@/views/tax/Nest'),
      //   meta: { title: '【众包】发票' },
      //   alwaysShow: true,
      //   redirect: 'noRedirect',
      //   children: [
      //     {
      //       path: '/tax/morePackage/wait',
      //       // name: 'MoreWait',
      //       component: () => import('@/views/tax/morePackage/wait'),
      //       meta: { title: '众包待开票' },
      //     },
      //     {
      //       path: '/tax/morePackage/done',
      //       // name: 'MoreDone',
      //       component: () => import('@/views/tax/morePackage/done'),
      //       meta: { title: '众包已开票' },
      //     },
      //     {
      //       path: '/tax/morePackage/wait-editor',
      //       hidden: true,
      //       component: () => import('@/views/tax/morePackage/editor'),
      //       meta: {
      //         title: '待开票详情',
      //         activeMenu: '/tax/morePackage/wait',
      //         parent: { path: '/tax/morePackage/wait' },
      //       },
      //     },
      //     {
      //       path: '/tax/morePackage/done-editor',
      //       hidden: true,
      //       component: () => import('@/views/tax/morePackage/editor'),
      //       meta: {
      //         title: '已开票详情',
      //         activeMenu: '/tax/morePackage/done',
      //         parent: { path: '/tax/morePackage/done' },
      //       },
      //     },
      //   ],
      // },
    ],
  },

  {
    path: '/shop',
    component: Layout,
    redirect: '/shop/pass',
    meta: { title: '商户管理', icon: 'sh' },
    children: [
      {
        path: '/shop/pass',
        name: 'ShopIndex',
        component: () => import('@/views/shop/index'),
        meta: { title: '签约商户' },
      },
      {
        path: '/shop/wait',
        component: () => import('@/views/shop/index'),
        meta: { title: '待审核商户', isWait: true },
      },
      {
        path: '/shop/add',
        // name: 'ShopAdd',
        component: () => import('@/views/shop/add'),
        hidden: true,
        meta: {
          title: '新增商户',
          activeMenu: '/shop/pass',
          parent: { path: '/shop/pass' },
        },
      },
      {
        path: '/shop/edit',
        component: () => import('@/views/shop/add'),
        hidden: true,
        meta: {
          title: '编辑商户',
          activeMenu: '/shop/pass',
          isEdit: true,
          parent: { path: '/shop/pass' },
        },
      },
      {
        path: '/shop/view',
        component: () => import('@/views/shop/view'),
        hidden: true,
        meta: {
          title: '查看',
          activeMenu: '/shop/pass',
          isEdit: true,
          parent: { path: '/shop/pass' },
        },
      },
      {
        path: '/shop/pay',
        component: () => import('@/views/shop/pay'),
        hidden: true,
        meta: {
          title: '支付方式',
          activeMenu: '/shop/pass',
          parent: { path: '/shop/pass' },
        },
      },
      {
        path: '/shop/serviceDetail',
        component: () => import('@/views/shop/serviceDetail'),
        hidden: true,
        meta: {
          title: '合作服务商详情',
          activeMenu: '/shop/pass',
          parent: { path: '/shop/view' },
        },
      },
      {
        path: '/shop/payDetail',
        component: () => import('@/views/shop/payDetail'),
        hidden: true,
        meta: {
          title: '支付详情',
          activeMenu: '/shop/pass',
          parent: { path: '/shop/serviceDetail' },
        },
      },
    ],
  },
  {
    path: '/worker',
    component: Layout,
    redirect: '/worker/cert',
    meta: { title: '创客管理', icon: 'ck' },
    children: [
      {
        path: '/worker/cert',
        name: 'WorkerCert',
        component: () => import('@/views/worker/cert'),
        meta: { title: '已认证创客', cert: true },
      },
      {
        path: '/worker/uncert',
        name: 'WorkerUncert',
        component: () => import('@/views/worker/uncert'),
        meta: { title: '待认证创客', cert: false },
      },
      {
        path: '/worker/view',
        component: () => import('@/views/worker/view'),
        hidden: true,
        meta: {
          title: '创客详情',
          activeMenu: '/worker/cert',
          parent: { path: '/worker/cert' },
        },
      },
      {
        path: '/worker/uncertView',
        component: () => import('@/views/worker/view'),
        hidden: true,
        meta: {
          title: '创客详情',
          activeMenu: '/worker/uncert',
          parent: { path: '/worker/uncert' },
        },
      },
      {
        path: '/worker/importPage',
        component: () => import('@/views/worker/importPage'),
        hidden: true,
        meta: {
          title: '导入创客',
          activeMenu: '/worker/index',
          parent: { path: '/worker/index' },
        },
      },
    ],
  },
  {
    path: '/service',
    component: Layout,
    redirect: '/service/index',
    children: [
      {
        path: '/service/index',
        component: () => import('@/views/service/index'),
        meta: { title: '服务商管理', icon: 'fws' },
      },
      {
        path: '/service/add',
        // name: 'ServiceAdd',
        component: () => import('@/views/service/add'),
        hidden: true,
        meta: {
          title: '新增服务商',
          activeMenu: '/service/index',
          parent: { path: '/service/index' },
        },
      },
      {
        path: '/service/edit',
        component: () => import('@/views/service/edit'),
        hidden: true,
        meta: {
          title: '编辑',
          activeMenu: '/service/index',
          parent: { path: '/service/index' },
        },
      },
      {
        path: '/service/view',
        component: () => import('@/views/service/view'),
        hidden: true,
        meta: {
          title: '查看',
          activeMenu: '/service/index',
          parent: { path: '/service/index' },
        },
      },
      {
        path: '/service/pay',
        component: () => import('@/views/service/pay'),
        hidden: true,
        meta: {
          title: '支付方式',
          activeMenu: '/service/index',
          parent: { path: '/service/index' },
        },
      },
      {
        path: '/service/shopDetail',
        component: () => import('@/views/service/shopDetail'),
        hidden: true,
        meta: {
          title: '合作商户详情',
          activeMenu: '/service/index',
          parent: { path: '/service/view' },
        },
      },
      {
        path: '/service/payDetail',
        component: () => import('@/views/service/payDetail'),
        hidden: true,
        meta: {
          title: '支付详情',
          activeMenu: '/service/index',
          parent: { path: '/service/shopDetail' },
        },
      },
      {
        path: '/service/individualBusiness/index',
        component: () => import('@/views/individualBusiness/index'),
        hidden: true,
        meta: {
          title: '个体户',
          activeMenu: '/service/index',
          parent: { path: '/service/index' },
          isService: true,
        },
      },
      {
        path: '/service/individualBusiness/detail',
        component: () => import('@/views/individualBusiness/detail'),
        hidden: true,
        meta: {
          title: '查看',
          activeMenu: '/service/index',
          parent: { path: '/service/individualBusiness/index' },
          isService: true,
        },
      },
      {
        path: '/service/individualBusiness/edit',
        component: () => import('@/views/individualBusiness/editByService'),
        hidden: true,
        meta: {
          title: '编辑',
          activeMenu: '/service/index',
          parent: { path: '/service/individualBusiness/index' },
          isService: true,
        },
      },
    ],
  },
  {
    path: '/contract',
    component: Layout,
    redirect: 'noRedirect',
    meta: { title: '合同管理', icon: 'contract' },
    children: [
      {
        path: '/contract/tax-platform',
        component: () => import('@/views/contract/tax-platform'),
        hidden: false,
        meta: { title: '服务商加盟平台加盟合同' },
      },
      {
        path: '/contract/shop-platform',
        component: () => import('@/views/contract/shop-platform'),
        hidden: false,
        meta: { title: '商户加盟平台合同' },
      },
      {
        path: '/contract/worker-platform',
        component: () => import('@/views/contract/worker-platform'),
        hidden: false,
        meta: { title: '创客加盟平台合同' },
      },
      {
        path: '/contract/tax-shop',
        component: () => import('@/views/contract/tax-shop'),
        hidden: false,
        meta: { title: '服务商与商户合同' },
      },
      {
        path: '/contract/tax-worker',
        component: () => import('@/views/contract/tax-worker'),
        hidden: false,
        meta: { title: '服务商与创客合同' },
      },
    ],
  },
  {
    path: '/supervision',
    component: Layout,
    redirect: '/supervision/index',
    children: [
      {
        path: '/supervision/index',
        component: () => import('@/views/supervision/index'),
        meta: { title: '监督部门管理', icon: 'jdbm' },
      },
      {
        path: '/supervision/add',
        component: () => import('@/views/supervision/add'),
        hidden: true,
        meta: {
          title: '新增',
          activeMenu: '/supervision/index',
          parent: { path: '/supervision/index' },
        },
      },
      {
        path: '/supervision/edit',
        component: () => import('@/views/supervision/add'),
        hidden: true,
        meta: {
          title: '编辑',
          activeMenu: '/supervision/index',
          parent: { path: '/supervision/index' },
        },
      },
      {
        path: '/supervision/view',
        component: () => import('@/views/supervision/view'),
        hidden: true,
        meta: {
          title: '查看',
          activeMenu: '/supervision/index',
          parent: { path: '/supervision/index' },
        },
      },
      {
        path: '/supervision/serviceDetail',
        component: () => import('@/views/supervision/serviceDetail'),
        hidden: true,
        meta: {
          title: '服务商详情',
          activeMenu: '/supervision/index',
          parent: { path: '/supervision/view' },
        },
      },
    ],
  },
  {
    path: '/individualBusiness',
    component: Layout,
    redirect: '/individualBusiness/index',
    children: [
      {
        path: '/individualBusiness/index',
        component: () => import('@/views/individualBusiness/index'),
        meta: { title: '个体户管理', icon: 'geti' },
      },
      {
        path: '/individualBusiness/add',
        hidden: true,
        component: () => import('@/views/individualBusiness/edit'),
        meta: {
          title: '申请注册个体户',
          activeMenu: '/individualBusiness/index',
          parent: { path: '/individualBusiness/index' },
        },
      },
      {
        path: '/individualBusiness/edit',
        hidden: true,
        component: () => import('@/views/individualBusiness/edit'),
        meta: {
          title: '个体户编辑',
          activeMenu: '/individualBusiness/index',
          parent: { path: '/individualBusiness/index' },
        },
      },
      {
        path: '/individualBusiness/detail',
        hidden: true,
        component: () => import('@/views/individualBusiness/detail'),
        meta: {
          title: '个体户详情',
          activeMenu: '/individualBusiness/index',
          parent: { path: '/individualBusiness/index' },
        },
      },
    ],
  },
  {
    path: '/organization',
    component: Layout,
    redirect: 'noRedirect',
    meta: { title: '组织结构', icon: 'zz' },
    children: [
      {
        path: '/organization/assistant',
        component: () => import('@/views/organization/assistant/index'),
        meta: { title: '平台业务专员' },
      },
      {
        path: '/organization/assistant/add',
        component: () => import('@/views/organization/assistant/add'),
        hidden: true,
        meta: {
          title: '新增',
          activeMenu: '/organization/assistant',
          parent: { path: '/organization/assistant' },
        },
      },
      {
        path: '/organization/assistant/edit',
        component: () => import('@/views/organization/assistant/add'),
        hidden: true,
        meta: {
          title: '编辑',
          activeMenu: '/organization/assistant',
          parent: { path: '/organization/assistant' },
        },
      },
      {
        path: '/organization/assistant/view',
        component: () => import('@/views/organization/assistant/view'),
        hidden: true,
        meta: {
          title: '查看',
          activeMenu: '/organization/assistant',
          parent: { path: '/organization/assistant' },
        },
      },
      {
        path: '/organization/assistant/countList',
        component: () => import('@/views/organization/assistant/countList'),
        meta: {
          title: '业务员佣金统计',
        },
      },
      {
        path: '/organization/assistant/countDetail',
        component: () => import('@/views/organization/assistant/countDetail'),
        hidden: true,
        meta: {
          title: '查看',
          activeMenu: '/organization/assistant/countList',
          parent: { path: '/organization/assistant/countList' },
        },
      },
      {
        path: '/organization/agent',
        component: () => import('@/views/organization/agent/index'),
        meta: { title: '代理商' },
      },
      {
        path: '/organization/agent/add',
        component: () => import('@/views/organization/agent/add'),
        hidden: true,
        meta: {
          title: '新增',
          activeMenu: '/organization/agent',
          parent: { path: '/organization/agent' },
        },
      },
      {
        path: '/organization/agent/edit',
        component: () => import('@/views/organization/agent/add'),
        hidden: true,
        meta: {
          title: '编辑',
          activeMenu: '/organization/agent',
          parent: { path: '/organization/agent' },
        },
      },
      {
        path: '/organization/agent/view',
        component: () => import('@/views/organization/agent/view'),
        hidden: true,
        meta: {
          title: '查看',
          activeMenu: '/organization/agent',
          parent: { path: '/organization/agent' },
        },
      },
      {
        path: '/organization/agent/countList',
        component: () => import('@/views/organization/agent/countList'),
        meta: {
          title: '代理商佣金统计',
        },
      },
      {
        path: '/organization/agent/countDetail',
        component: () => import('@/views/organization/agent/countDetail'),
        hidden: true,
        meta: {
          title: '查看',
          activeMenu: '/organization/agent/countList',
          parent: { path: '/organization/agent/countList' },
        },
      },
    ],
  },
  {
    path: '/applet',
    component: Layout,
    redirect: 'noRedirect',
    meta: { title: '小程序设置', icon: 'xcx' },
    children: [
      {
        path: '/applet/fq',
        component: () => import('@/views/applet/fq/index'),
        meta: { title: '常见问题' },
      },
      {
        path: '/applet/fq/add',
        component: () => import('@/views/applet/fq/add'),
        hidden: true,
        meta: {
          title: '新增',
          activeMenu: '/applet/fq',
          parent: { path: '/applet/fq' },
        },
      },
      {
        path: '/applet/fq/edit',
        component: () => import('@/views/applet/fq/add'),
        hidden: true,
        meta: {
          title: '编辑',
          activeMenu: '/applet/fq',
          parent: { path: '/applet/fq' },
        },
      },
      {
        path: '/applet/fq/view',
        component: () => import('@/views/applet/fq/add'),
        hidden: true,
        meta: {
          isView: true,
          title: '查看',
          activeMenu: '/applet/fq',
          parent: { path: '/applet/fq' },
        },
      },
      {
        path: '/applet/banner',
        component: () => import('@/views/applet/banner/index'),
        meta: { title: 'Banner轮播图' },
      },
      {
        path: '/applet/banner/add',
        component: () => import('@/views/applet/banner/add'),
        hidden: true,
        meta: {
          title: '新增',
          activeMenu: '/applet/banner',
          parent: { path: '/applet/banner' },
        },
      },
      {
        path: '/applet/banner/edit',
        component: () => import('@/views/applet/banner/add'),
        hidden: true,
        meta: {
          title: '编辑',
          activeMenu: '/applet/banner',
          parent: { path: '/applet/banner' },
        },
      },
      {
        path: '/applet/other',
        component: () => import('@/views/applet/other/index'),
        meta: { title: '其他信息' },
      },
      {
        path: '/applet/other/edit',
        component: () => import('@/views/applet/other/add'),
        hidden: true,
        meta: {
          title: '编辑',
          activeMenu: '/applet/other',
          parent: { path: '/applet/other' },
        },
      },
      {
        path: '/applet/other/view',
        component: () => import('@/views/applet/other/add'),
        hidden: true,
        meta: {
          isView: true,
          title: '查看',
          activeMenu: '/applet/other',
          parent: { path: '/applet/other' },
        },
      },
    ],
  },
  {
    path: '/auth',
    component: Layout,
    redirect: '/auth/index',
    children: [
      {
        path: '/auth/index',
        component: () => import('@/views/auth/index'),
        meta: { title: '权限管理', icon: 'qx' },
      },
      {
        path: '/auth/add',
        component: () => import('@/views/auth/add'),
        hidden: true,
        meta: {
          title: '新增子账号',
          activeMenu: '/auth/index',
          parent: { path: '/auth/index' },
        },
      },
      {
        path: '/auth/edit',
        component: () => import('@/views/auth/add'),
        hidden: true,
        meta: {
          title: '编辑子账号',
          activeMenu: '/auth/index',
          parent: { path: '/auth/index' },
        },
      },
    ],
  },
  {
    path: '/warn',
    component: Layout,
    redirect: 'noRedirect',
    meta: { title: '预警管理', icon: 'rw' },
    children: [
      {
        path: '/warn/shop',
        component: () => import('@/views/warn/shopWarn'),
        meta: { title: '预警商户' },
      },
      {
        path: '/warn/shop/detail',
        component: () => import('@/views/warn/shopWarnDetail'),
        hidden: true,
        meta: {
          title: '预警商户处理',
          activeMenu: '/warn/shop',
          parent: { path: '/warn/shop' },
        },
      },
      {
        path: '/warn/shop/notice',
        component: () => import('@/views/warn/shopNotice'),
        hidden: true,
        meta: {
          title: '通知',
          activeMenu: '/warn/shop',
          parent: { path: '/warn/shop' },
        },
      },
      {
        path: '/warn/worker',
        component: () => import('@/views/warn/workerWarn'),
        meta: { title: '预警创客' },
      },
      {
        path: '/warn/worker/detail',
        component: () => import('@/views/warn/workerWarnDetail'),
        hidden: true,
        meta: {
          title: '预警创客处理',
          activeMenu: '/warn/worker',
          parent: { path: '/warn/worker' },
        },
      },
      {
        path: '/warn/blacklist',
        component: () => import('@/views/warn/blacklist/index'),
        meta: { title: '黑名单管理' },
      },
    ],
  },
];
const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes,
  });

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
