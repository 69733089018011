var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "u-m-25 u-p-30 bg-white" },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formData",
          staticStyle: { "max-width": "800px" },
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "250px",
          },
        },
        [
          _c("el-form-item", { attrs: { label: "图片" } }, [
            _c(
              "div",
              { staticClass: "u-flex u-col-center" },
              [
                _c("button-upload", {
                  attrs: { type: "image", text: "上传图片" },
                  on: {
                    "upload-success": function ($event) {
                      return _vm.setField("picture", $event)
                    },
                  },
                }),
                !_vm.$u.test.isEmpty(_vm.formData.picture)
                  ? _c(
                      "el-button",
                      {
                        staticClass: "u-m-l-10",
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.$preViewer(_vm.formData.picture)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _c(
            "el-form-item",
            { attrs: { label: "排序", prop: "serialNumber" } },
            [
              _c("el-input", {
                attrs: {
                  type: "number",
                  min: "0",
                  step: "1",
                  placeholder: "请输入",
                },
                model: {
                  value: _vm.formData.serialNumber,
                  callback: function ($$v) {
                    _vm.$set(_vm.formData, "serialNumber", _vm._n($$v))
                  },
                  expression: "formData.serialNumber",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("formData")
                    },
                  },
                },
                [_vm._v("确定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }