import axios from 'axios';
import { MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/utils/auth';

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 165000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken();
      config.headers['Request-Id'] =
        Date.now() + getToken().slice(0, 20) + Math.random();
    }
    return config;
  },
  (error) => {
    // do something with request error
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    const res = response.data || {};

    if (!res?.code) {
      if (res) {
        return res;
      } else {
        return Promise.reject({
          message: '请求失败',
          code: 300,
          data: null,
        });
      }
    }

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      if (res.code === 220) {
        return Promise.reject(new Error(res.message));
      }
      Message({
        message: res.message || '请求失败',
        type: 'error',
        duration: 5 * 1000,
      });

      if (res.code === 401) {
        // to re-login
        MessageBox.confirm('登录超时，请重新登录', '重登确认', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          store.dispatch('user/resetToken').then(() => {
            location.reload();
          });
        });
      }
      return Promise.reject(new Error(res.message || '请求失败'));
    } else {
      return res;
    }
  },
  (error) => {
    const data = error.response ? error.response.data : {};
    if (data.code === 401) {
      // to re-login
      MessageBox.confirm('登录超时，请重新登录', '重登确认', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload();
        });
      });
    } else {
      Message({
        message: data.message || '请求失败',
        type: 'error',
        duration: 5 * 1000,
      });
    }
    return Promise.reject(error);
  }
);

export default service;
