var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "u-p-20 wrap-fixed-content",
      staticStyle: { "min-width": "1300px" },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "formData",
          staticClass: "u-form",
          attrs: {
            model: _vm.formData,
            rules: _vm.rules,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-card",
            { attrs: { header: "基本信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称", prop: "companyName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "companyName", $$v)
                      },
                      expression: "formData.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "法定代表人", prop: "companyMan" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.companyMan,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "companyMan", $$v)
                      },
                      expression: "formData.companyMan",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "注册资本", prop: "registeredCapital" } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: {
                        min: "0",
                        type: "number",
                        placeholder: "请输入",
                      },
                      model: {
                        value: _vm.formData.registeredCapital,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formData,
                            "registeredCapital",
                            _vm._n($$v)
                          )
                        },
                        expression: "formData.registeredCapital",
                      },
                    },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "suffix" }, slot: "suffix" },
                        [_vm._v("万元")]
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "成立日期", prop: "companyCreateDate" } },
                [
                  _c("el-date-picker", {
                    attrs: {
                      "value-format": "yyyy-MM-dd",
                      type: "date",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.formData.companyCreateDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "companyCreateDate", $$v)
                      },
                      expression: "formData.companyCreateDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "社会统一信用代码", prop: "creditCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.creditCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "creditCode", $$v)
                      },
                      expression: "formData.creditCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账户名称", prop: "titleOfAccount" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.titleOfAccount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "titleOfAccount", $$v)
                      },
                      expression: "formData.titleOfAccount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "开户行", prop: "bankName" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { filterable: "", placeholder: "请选择" },
                      model: {
                        value: _vm.formData.bankName,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "bankName", $$v)
                        },
                        expression: "formData.bankName",
                      },
                    },
                    _vm._l(_vm.bankOptions, function (i, k) {
                      return _c("el-option", {
                        key: k,
                        attrs: { label: i.dictValue, value: i.dictValue },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "银行卡号", prop: "bankCode" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.bankCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "bankCode", $$v)
                      },
                      expression: "formData.bankCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "营业执照", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-center" },
                    [
                      _c("button-upload", {
                        attrs: { type: "image/pdf", text: "上传 jpg/pdf" },
                        on: {
                          "upload-success": function ($event) {
                            return _vm.setFields("businessLicense", $event)
                          },
                        },
                      }),
                      !_vm.$u.test.isEmpty(_vm.formData.businessLicense)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(
                                    _vm.formData.businessLicense
                                  )
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: "创客任务规则" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.formData.workRules,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "workRules", $$v)
                        },
                        expression: "formData.workRules",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: "需审核", value: "NEEDREVIEW" },
                      }),
                      _c("el-option", {
                        attrs: { label: "无需审核", value: "PASS" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "组织结构" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台业务员", prop: "salesManId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.userSign !== 3,
                        placeholder: "平台业务员",
                        clearable: "",
                        filterable: "",
                      },
                      on: { change: _vm.fetchAgent },
                      model: {
                        value: _vm.formData.salesManId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "salesManId", $$v)
                        },
                        expression: "formData.salesManId",
                      },
                    },
                    _vm._l(_vm.assistantList, function (serviceItem) {
                      return _c("el-option", {
                        key: serviceItem.id,
                        attrs: {
                          label: serviceItem.realName,
                          value: serviceItem.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "代理商", prop: "agentId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        disabled: _vm.userSign === 1,
                        placeholder: "代理商",
                        clearable: "",
                        filterable: "",
                      },
                      on: {
                        change: function ($event) {
                          return _vm.fetchService("change")
                        },
                      },
                      model: {
                        value: _vm.formData.agentId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "agentId", $$v)
                        },
                        expression: "formData.agentId",
                      },
                    },
                    _vm._l(_vm.agentList, function (serviceItem) {
                      return _c("el-option", {
                        key: serviceItem.id,
                        attrs: {
                          label: serviceItem.agentName,
                          value: serviceItem.id,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "合作信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "平台加盟合同", required: "" } },
                [
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-center" },
                    [
                      _c("button-upload", {
                        attrs: { type: "image/pdf", text: "上传 jpg/pdf" },
                        on: {
                          "upload-success": function ($event) {
                            return _vm.setFields("contract", $event)
                          },
                        },
                      }),
                      !_vm.$u.test.isEmpty(_vm.formData.contract)
                        ? _c(
                            "el-button",
                            {
                              staticClass: "u-m-l-10",
                              attrs: { type: "text" },
                              on: {
                                click: function ($event) {
                                  return _vm.$preViewer(_vm.formData.contract)
                                },
                              },
                            },
                            [_vm._v("查看")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "coop-box u-m-l-60",
                  class: _vm.totalChecked ? "" : "box-uncheck",
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.totalChecked,
                        callback: function ($$v) {
                          _vm.totalChecked = $$v
                        },
                        expression: "totalChecked",
                      },
                    },
                    [_vm._v("合作类型：总包+分包（6%专票）")]
                  ),
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-top u-row-left u-flex-wrap" },
                    [
                      _vm._l(_vm.totalList, function (item, k) {
                        return _c(
                          "div",
                          {
                            key: item.guid,
                            staticClass: "u-m-t-30 u-p-r-20",
                            staticStyle: { width: "45%" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "u-border u-p-20",
                                staticStyle: { position: "relative" },
                              },
                              [
                                k !== 0
                                  ? _c("i", {
                                      staticClass:
                                        "el-icon-remove-outline text-danger u-font-18 u-point",
                                      staticStyle: {
                                        position: "absolute",
                                        right: "5px",
                                        top: "5px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeTotalList(k)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "平台服务商",
                                      required: "",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "平台服务商",
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeTotalService(
                                              k,
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.taxId,
                                          callback: function ($$v) {
                                            _vm.$set(item, "taxId", $$v)
                                          },
                                          expression: "item.taxId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.totalServiceList,
                                        function (serviceItem) {
                                          return _c("el-option", {
                                            key: serviceItem.id,
                                            attrs: {
                                              label: serviceItem.taxName,
                                              value: serviceItem.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.serviceTotalPackage[item.taxId]
                                  ? [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "综合税费率",
                                            required: "",
                                            "label-width": "100px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: item.chargeStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "chargeStatus",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.chargeStatus",
                                              },
                                            },
                                            [
                                              _vm.serviceTotalPackage[
                                                item.taxId
                                              ].taxPrice
                                                ? _c(
                                                    "el-radio",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("一口价")]
                                                  )
                                                : _vm._e(),
                                              _vm.serviceTotalPackage[
                                                item.taxId
                                              ].invoiceLadderPriceDetailVOList
                                                ? _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("梯度价")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      item.chargeStatus === 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: { "label-width": "100px" },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  key: item.taxId,
                                                  attrs: {
                                                    type: "number",
                                                    min: 0,
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.checkTotalItem(
                                                        item,
                                                        _vm.serviceTotalPackage[
                                                          item.taxId
                                                        ].taxPrice || 0,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.serviceCharge,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "serviceCharge",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.serviceCharge",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "line-height": "40px",
                                                      },
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [_vm._v("%")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "u-tips" },
                                                [
                                                  _vm._v(
                                                    "参考值(不低于)：" +
                                                      _vm._s(
                                                        _vm.serviceTotalPackage[
                                                          item.taxId
                                                        ]
                                                          ? _vm
                                                              .serviceTotalPackage[
                                                              item.taxId
                                                            ].taxPrice || 0
                                                          : 0
                                                      ) +
                                                      "%"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "el-form-item",
                                            {
                                              attrs: { "label-width": "100px" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "u-flex u-col-center u-row-center u-m-t-10 u-text-center",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "u-flex-2" },
                                                    [_vm._v("单人单月额度")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "u-flex-1" },
                                                    [_vm._v("服务费率")]
                                                  ),
                                                  _c("div", {
                                                    staticStyle: {
                                                      width: "50px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              item.addCompanyLadderServiceDtoList
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      item.addCompanyLadderServiceDtoList,
                                                      function (i, j) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: i.guid,
                                                            staticClass:
                                                              "u-m-t-10",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "u-flex u-col-center u-row-center",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "u-flex-2 u-flex u-col-center u-row-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          readonly:
                                                                            "",
                                                                          placeholder:
                                                                            "请输入",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            i.startMoney,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                i,
                                                                                "startMoney",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "i.startMoney",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "line-height":
                                                                                  "40px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                slot: "suffix",
                                                                              },
                                                                            slot: "suffix",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "元"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "u-p-lf-5",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "至"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          readonly:
                                                                            "",
                                                                          placeholder:
                                                                            "请输入",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            i.endMoney,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                i,
                                                                                "endMoney",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "i.endMoney",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "line-height":
                                                                                  "40px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                slot: "suffix",
                                                                              },
                                                                            slot: "suffix",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "元"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "u-flex-1 u-p-l-10",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          placeholder:
                                                                            "请输入",
                                                                        },
                                                                        on: {
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.checkTotalItem(
                                                                              i,
                                                                              _vm
                                                                                .serviceTotalPackage[
                                                                                item
                                                                                  .taxId
                                                                              ]
                                                                                .invoiceLadderPriceDetailVOList[
                                                                                j
                                                                              ]
                                                                                .rate ||
                                                                                0,
                                                                              $event
                                                                            )
                                                                          },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            i.serviceCharge,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                i,
                                                                                "serviceCharge",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "i.serviceCharge",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "line-height":
                                                                                  "40px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                slot: "suffix",
                                                                              },
                                                                            slot: "suffix",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "%"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "u-tips u-text-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "参考值(不低于)： " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .serviceTotalPackage[
                                                                        item
                                                                          .taxId
                                                                      ]
                                                                        .invoiceLadderPriceDetailVOList[
                                                                        j
                                                                      ].rate ||
                                                                        0
                                                                    ) +
                                                                    "% "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "合作合同",
                                      required: "",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "u-flex u-col-center" },
                                      [
                                        _c("button-upload", {
                                          attrs: {
                                            type: "image/pdf",
                                            text: "上传 jpg/pdf",
                                          },
                                          on: {
                                            "upload-success": function (
                                              $event
                                            ) {
                                              return _vm.setTotalFiled(
                                                "contract",
                                                k,
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        !_vm.$u.test.isEmpty(item.contract)
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "u-m-l-10",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$preViewer(
                                                      item.contract
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("查看")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "100px" } },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeItemPayType(
                                              item,
                                              "_payType",
                                              "crowdList",
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: item._payType,
                                          callback: function ($$v) {
                                            _vm.$set(item, "_payType", $$v)
                                          },
                                          expression: "item._payType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              label: "offline",
                                              disabled: "",
                                            },
                                          },
                                          [_vm._v("线下支付")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "unionPay" } },
                                          [_vm._v("银联支付")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "isTaxZspay" } },
                                          [_vm._v("招行支付")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "isTaxAlipay" } },
                                          [_vm._v("支付宝")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                item._payType.indexOf("unionPay") !== -1
                                  ? [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            required: "",
                                            label: "银联支付银行",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm.serviceUnionType[item.taxId]
                                            ? _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeItemPayType(
                                                        item,
                                                        "unionpayBankTypeList",
                                                        "crowdList",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      item.unionpayBankTypeList,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "unionpayBankTypeList",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.unionpayBankTypeList",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.serviceUnionType[
                                                    item.taxId
                                                  ],
                                                  function (unionpayBank) {
                                                    return _c(
                                                      "el-checkbox",
                                                      {
                                                        key: unionpayBank,
                                                        attrs: {
                                                          label: unionpayBank,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "unionpayBankType"
                                                            )(unionpayBank)
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "u-point",
                          staticStyle: {
                            display: "inline-block",
                            "margin-top": "100px",
                          },
                          on: { click: _vm.addTotalList },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "el-icon-circle-plus-outline u-font-30",
                          }),
                          _c("div", { staticClass: "u-m-t-5" }, [
                            _vm._v("添加"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "coop-box u-m-l-60 u-m-t-40",
                  class: _vm.crowdChecked ? "" : "box-uncheck",
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.crowdChecked,
                        callback: function ($$v) {
                          _vm.crowdChecked = $$v
                        },
                        expression: "crowdChecked",
                      },
                    },
                    [_vm._v("合作类型：众包(3%普票)")]
                  ),
                  _c(
                    "div",
                    { staticClass: "u-flex u-col-top u-row-left u-flex-wrap" },
                    [
                      _vm._l(_vm.crowdList, function (item, k) {
                        return _c(
                          "div",
                          {
                            key: item.guid,
                            staticClass: "u-m-t-30 u-p-r-20",
                            staticStyle: { width: "45%" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "u-border u-p-20",
                                staticStyle: { position: "relative" },
                              },
                              [
                                k !== 0
                                  ? _c("i", {
                                      staticClass:
                                        "el-icon-remove-outline text-danger u-font-18 u-point",
                                      staticStyle: {
                                        position: "absolute",
                                        right: "5px",
                                        top: "5px",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeCrowdList(k)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "平台服务商",
                                      required: "",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "el-select",
                                      {
                                        attrs: {
                                          placeholder: "平台服务商",
                                          clearable: "",
                                          filterable: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeCrowdService(
                                              k,
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: item.taxId,
                                          callback: function ($$v) {
                                            _vm.$set(item, "taxId", $$v)
                                          },
                                          expression: "item.taxId",
                                        },
                                      },
                                      _vm._l(
                                        _vm.crowdServiceList,
                                        function (serviceItem) {
                                          return _c("el-option", {
                                            key: serviceItem.id,
                                            attrs: {
                                              label: serviceItem.taxName,
                                              value: serviceItem.id,
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm.serviceCrowdPackage[item.taxId]
                                  ? [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            label: "综合税费率",
                                            required: "",
                                            "label-width": "100px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-radio-group",
                                            {
                                              model: {
                                                value: item.chargeStatus,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "chargeStatus",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.chargeStatus",
                                              },
                                            },
                                            [
                                              _vm.serviceCrowdPackage[
                                                item.taxId
                                              ].taxPrice
                                                ? _c(
                                                    "el-radio",
                                                    { attrs: { label: 0 } },
                                                    [_vm._v("一口价")]
                                                  )
                                                : _vm._e(),
                                              _vm.serviceCrowdPackage[
                                                item.taxId
                                              ].invoiceLadderPriceDetailVOList
                                                ? _c(
                                                    "el-radio",
                                                    { attrs: { label: 1 } },
                                                    [_vm._v("梯度价")]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      item.chargeStatus === 0
                                        ? _c(
                                            "el-form-item",
                                            {
                                              attrs: { "label-width": "100px" },
                                            },
                                            [
                                              _c(
                                                "el-input",
                                                {
                                                  attrs: {
                                                    type: "number",
                                                    min: "0",
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.checkCrowdItem(
                                                        item,
                                                        _vm.serviceCrowdPackage[
                                                          item.taxId
                                                        ].taxPrice || 0,
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: item.serviceCharge,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "serviceCharge",
                                                        _vm._n($$v)
                                                      )
                                                    },
                                                    expression:
                                                      "item.serviceCharge",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "line-height": "40px",
                                                      },
                                                      attrs: { slot: "suffix" },
                                                      slot: "suffix",
                                                    },
                                                    [_vm._v("%")]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "u-tips" },
                                                [
                                                  _vm._v(
                                                    "参考值(不低于)：" +
                                                      _vm._s(
                                                        _vm.serviceCrowdPackage[
                                                          item.taxId
                                                        ]
                                                          ? _vm
                                                              .serviceCrowdPackage[
                                                              item.taxId
                                                            ].taxPrice || 0
                                                          : 0
                                                      ) +
                                                      "%"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "el-form-item",
                                            {
                                              attrs: { "label-width": "100px" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "u-flex u-col-center u-row-center u-m-t-10 u-text-center",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "u-flex-2" },
                                                    [_vm._v("单人单月额度")]
                                                  ),
                                                  _c(
                                                    "div",
                                                    { staticClass: "u-flex-1" },
                                                    [_vm._v("服务费率")]
                                                  ),
                                                  _c("div", {
                                                    staticStyle: {
                                                      width: "50px",
                                                    },
                                                  }),
                                                ]
                                              ),
                                              item.addCompanyLadderServiceDtoList
                                                ? _c(
                                                    "div",
                                                    _vm._l(
                                                      item.addCompanyLadderServiceDtoList,
                                                      function (i, j) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: i.guid,
                                                            staticClass:
                                                              "u-m-t-10",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "u-flex u-col-center u-row-center u-m-t-10",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "u-flex-2 u-flex u-col-center u-row-center",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          readonly:
                                                                            "",
                                                                          placeholder:
                                                                            "请输入",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            i.startMoney,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                i,
                                                                                "startMoney",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "i.startMoney",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "line-height":
                                                                                  "40px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                slot: "suffix",
                                                                              },
                                                                            slot: "suffix",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "元"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "u-p-lf-5",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "至"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          readonly:
                                                                            "",
                                                                          placeholder:
                                                                            "请输入",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            i.endMoney,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                i,
                                                                                "endMoney",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "i.endMoney",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "line-height":
                                                                                  "40px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                slot: "suffix",
                                                                              },
                                                                            slot: "suffix",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "元"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "u-flex-1 u-p-l-10",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "el-input",
                                                                      {
                                                                        attrs: {
                                                                          placeholder:
                                                                            "请输入",
                                                                        },
                                                                        on: {
                                                                          blur: function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.checkTotalItem(
                                                                              i,
                                                                              _vm
                                                                                .serviceCrowdPackage[
                                                                                item
                                                                                  .taxId
                                                                              ]
                                                                                .invoiceLadderPriceDetailVOList[
                                                                                j
                                                                              ]
                                                                                .rate ||
                                                                                0,
                                                                              $event
                                                                            )
                                                                          },
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            i.serviceCharge,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                i,
                                                                                "serviceCharge",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "i.serviceCharge",
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "line-height":
                                                                                  "40px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                slot: "suffix",
                                                                              },
                                                                            slot: "suffix",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "%"
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "u-tips u-text-right",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "参考值(不低于)： " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .serviceCrowdPackage[
                                                                        item
                                                                          .taxId
                                                                      ]
                                                                        .invoiceLadderPriceDetailVOList[
                                                                        j
                                                                      ].rate ||
                                                                        0
                                                                    ) +
                                                                    "% "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                    ]
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  {
                                    attrs: {
                                      label: "合作合同",
                                      required: "",
                                      "label-width": "100px",
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "u-flex u-col-center" },
                                      [
                                        _c("button-upload", {
                                          attrs: {
                                            type: "image/pdf",
                                            text: "上传 jpg/pdf",
                                          },
                                          on: {
                                            "upload-success": function (
                                              $event
                                            ) {
                                              return _vm.setCrowdFiled(
                                                "contract",
                                                k,
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        !_vm.$u.test.isEmpty(item.contract)
                                          ? _c(
                                              "el-button",
                                              {
                                                staticClass: "u-m-l-10",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$preViewer(
                                                      item.contract
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("查看")]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { "label-width": "100px" } },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        on: {
                                          change: function ($event) {
                                            return _vm.changeItemPayType(
                                              item,
                                              "_payType",
                                              "totalList",
                                              $event
                                            )
                                          },
                                        },
                                        model: {
                                          value: item._payType,
                                          callback: function ($$v) {
                                            _vm.$set(item, "_payType", $$v)
                                          },
                                          expression: "item._payType",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: {
                                              label: "offline",
                                              disabled: "",
                                            },
                                          },
                                          [_vm._v("线下支付")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          { attrs: { label: "unionPay" } },
                                          [_vm._v("银联支付")]
                                        ),
                                        _c(
                                          "el-checkbox",
                                          {
                                            attrs: { label: "isTaxZspayCrowd" },
                                          },
                                          [_vm._v("招行支付")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                item._payType.indexOf("unionPay") !== -1
                                  ? [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            required: "",
                                            label: "银联支付银行",
                                            "label-width": "120px",
                                          },
                                        },
                                        [
                                          _vm.serviceUnionType[item.taxId]
                                            ? _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.changeItemPayType(
                                                        item,
                                                        "unionpayBankTypeList",
                                                        "totalList",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      item.unionpayBankTypeList,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        item,
                                                        "unionpayBankTypeList",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "item.unionpayBankTypeList",
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.serviceUnionType[
                                                    item.taxId
                                                  ],
                                                  function (unionpayBank) {
                                                    return _c(
                                                      "el-checkbox",
                                                      {
                                                        key: unionpayBank,
                                                        attrs: {
                                                          label: unionpayBank,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f(
                                                              "unionpayBankType"
                                                            )(unionpayBank)
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        )
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "u-point",
                          staticStyle: {
                            display: "inline-block",
                            "margin-top": "100px",
                          },
                          on: { click: _vm.addCrowdList },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "el-icon-circle-plus-outline u-font-30",
                          }),
                          _c("div", { staticClass: "u-m-t-5" }, [
                            _vm._v("添加"),
                          ]),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "开票信息" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "公司名称",
                    prop: "addCompanyInvoiceInfoDto.companyName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.addCompanyInvoiceInfoDto.companyName,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.addCompanyInvoiceInfoDto,
                          "companyName",
                          $$v
                        )
                      },
                      expression:
                        "formData.addCompanyInvoiceInfoDto.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "纳税人识别号",
                    prop: "addCompanyInvoiceInfoDto.taxCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.addCompanyInvoiceInfoDto.taxCode,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.addCompanyInvoiceInfoDto,
                          "taxCode",
                          $$v
                        )
                      },
                      expression: "formData.addCompanyInvoiceInfoDto.taxCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "地址、电话",
                    prop: "addCompanyInvoiceInfoDto.addressAndTelephone",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value:
                        _vm.formData.addCompanyInvoiceInfoDto
                          .addressAndTelephone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.addCompanyInvoiceInfoDto,
                          "addressAndTelephone",
                          $$v
                        )
                      },
                      expression:
                        "formData.addCompanyInvoiceInfoDto.addressAndTelephone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "开户行及账号",
                    prop: "addCompanyInvoiceInfoDto.bankAndAccount",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value:
                        _vm.formData.addCompanyInvoiceInfoDto.bankAndAccount,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.addCompanyInvoiceInfoDto,
                          "bankAndAccount",
                          $$v
                        )
                      },
                      expression:
                        "formData.addCompanyInvoiceInfoDto.bankAndAccount",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "联系人" } },
            [
              _vm.id
                ? _c("link-edit", {
                    attrs: {
                      "merchant-id": _vm.id,
                      "company-id": _vm.companyId,
                    },
                  })
                : [
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "姓名",
                          prop: "addLinkmanDto.linkName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formData.addLinkmanDto.linkName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.addLinkmanDto,
                                "linkName",
                                $$v
                              )
                            },
                            expression: "formData.addLinkmanDto.linkName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "职位", prop: "addLinkmanDto.post" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formData.addLinkmanDto.post,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.addLinkmanDto, "post", $$v)
                            },
                            expression: "formData.addLinkmanDto.post",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "手机号",
                          prop: "addLinkmanDto.linkMobile",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formData.addLinkmanDto.linkMobile,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.addLinkmanDto,
                                "linkMobile",
                                $$v
                              )
                            },
                            expression: "formData.addLinkmanDto.linkMobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { attrs: { label: "邮箱", prop: "addLinkmanDto.email" } },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formData.addLinkmanDto.email,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.addLinkmanDto, "email", $$v)
                            },
                            expression: "formData.addLinkmanDto.email",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "快递信息" } },
            [
              _vm.id
                ? _c("address-edit", { attrs: { "merchant-id": _vm.id } })
                : [
                    _c(
                      "el-form-item",
                      {
                        attrs: { label: "收件人", prop: "addressDto.linkName" },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formData.addressDto.linkName,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData.addressDto, "linkName", $$v)
                            },
                            expression: "formData.addressDto.linkName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "电话/手机号",
                          prop: "addressDto.linkMobile",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formData.addressDto.linkMobile,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.addressDto,
                                "linkMobile",
                                $$v
                              )
                            },
                            expression: "formData.addressDto.linkMobile",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      {
                        attrs: {
                          label: "地址",
                          prop: "addressDto.addressName",
                        },
                      },
                      [
                        _c("el-input", {
                          attrs: { placeholder: "请输入" },
                          model: {
                            value: _vm.formData.addressDto.addressName,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.formData.addressDto,
                                "addressName",
                                $$v
                              )
                            },
                            expression: "formData.addressDto.addressName",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _c(
            "el-card",
            { staticClass: "u-m-t-20", attrs: { header: "账户信息" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "addMerchantDto.realName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.addMerchantDto.realName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.addMerchantDto, "realName", $$v)
                      },
                      expression: "formData.addMerchantDto.realName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "登录账号", prop: "addMerchantDto.userName" },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.addMerchantDto.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData.addMerchantDto, "userName", $$v)
                      },
                      expression: "formData.addMerchantDto.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "绑定手机号",
                    prop: "addMerchantDto.loginMobile",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.formData.addMerchantDto.loginMobile,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.formData.addMerchantDto,
                          "loginMobile",
                          $$v
                        )
                      },
                      expression: "formData.addMerchantDto.loginMobile",
                    },
                  }),
                ],
                1
              ),
              _vm.isEdit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "修改密码" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.isShowPwd,
                          callback: function ($$v) {
                            _vm.isShowPwd = $$v
                          },
                          expression: "isShowPwd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isShowPwd
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "密码",
                        prop: _vm.isEdit ? "none" : "addMerchantDto.passWord",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "no-autofill-pwd",
                        attrs: {
                          type: "text",
                          placeholder: "请输入",
                          autocomplete: "new-password",
                        },
                        model: {
                          value: _vm.formData.addMerchantDto.passWord,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData.addMerchantDto,
                              "passWord",
                              $$v
                            )
                          },
                          expression: "formData.addMerchantDto.passWord",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isShowPwd
                ? _c(
                    "el-form-item",
                    { attrs: { label: "确认密码" } },
                    [
                      _c("el-input", {
                        staticClass: "no-autofill-pwd",
                        attrs: {
                          placeholder: "请输入",
                          type: "text",
                          autocomplete: "new-password",
                        },
                        model: {
                          value: _vm.repassWord,
                          callback: function ($$v) {
                            _vm.repassWord = $$v
                          },
                          expression: "repassWord",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isEdit
                ? _c(
                    "el-form-item",
                    { attrs: { label: "修改支付密码" } },
                    [
                      _c("el-switch", {
                        model: {
                          value: _vm.isShowPayPwd,
                          callback: function ($$v) {
                            _vm.isShowPayPwd = $$v
                          },
                          expression: "isShowPayPwd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isShowPayPwd
                ? _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "支付密码",
                        prop: _vm.isEdit ? "none" : "addMerchantDto.payPwd",
                      },
                    },
                    [
                      _c("el-input", {
                        staticClass: "no-autofill-pwd",
                        attrs: {
                          type: "text",
                          autocomplete: "new-password",
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.formData.addMerchantDto.payPwd,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData.addMerchantDto, "payPwd", $$v)
                          },
                          expression: "formData.addMerchantDto.payPwd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isShowPayPwd
                ? _c(
                    "el-form-item",
                    { attrs: { label: "确认支付密码" } },
                    [
                      _c("el-input", {
                        staticClass: "no-autofill-pwd",
                        attrs: {
                          type: "text",
                          autocomplete: "new-password",
                          placeholder: "请输入",
                        },
                        model: {
                          value: _vm.repayPwd,
                          callback: function ($$v) {
                            _vm.repayPwd = $$v
                          },
                          expression: "repayPwd",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "wrap-fixed-footer btn--box" },
        [
          _c(
            "el-button",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { size: "small", type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit("formData")
                },
              },
            },
            [_vm._v("确定")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [_vm._v("取消")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }