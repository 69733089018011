<template>
  <div class="login-container">
    <div class="topbar u-flex u-row-between u-col-center">
      <div class="u-flex u-col-center">
        <img src="@/assets/logo.png" width="160px">
        <span class="u-m-l-15 u-font-16">{{ slogan }}</span>
      </div>
      <div class="u-flex u-col-center">
        <el-button v-if="!$u.test.isEmpty(homeUrl)" type="primary" size="small" @click="toMain">返回官网</el-button>
      </div>
    </div>
    <div class="login-form">
      <div class="logo-title">{{ title || '平台' }}管理中心</div>
      <div v-if="!forgetPwd" class="login-box">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" auto-complete="on" label-position="left">
          <el-tabs v-model="loginForm.type" stretch :before-leave="changeTabs">
            <el-tab-pane class="tab-name" label="账户密码登录" name="pwd">
              <div v-if="loginForm.type === 'pwd'">
                <el-form-item prop="username">
                  <span class="svg-container">
                    <svg-icon icon-class="username" />
                  </span>
                  <el-input ref="username" v-model="loginForm.username" placeholder="请输入用户名" name="username" type="text"
                    tabindex="1" auto-complete="on" />
                </el-form-item>
                <el-form-item prop="password">
                  <span class="svg-container">
                    <svg-icon icon-class="password" />
                  </span>
                  <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"
                    placeholder="请输入密码" name="password" tabindex="2" auto-complete="on"
                    @keyup.enter.native="handleLogin" />
                  <span class="show-pwd" @click="showPwd">
                    <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
                  </span>
                </el-form-item>
                <el-form-item>
                  <verify v-if="loginForm.type === 'pwd'" key="pwd" :type="3" :show-button="false"
                    :bar-size="{ width: '357px', height: '42px' }" @success="verifyBool = true"
                    @error="verifyBool = false" />
                </el-form-item>
              </div>
            </el-tab-pane>
            <!-- <el-tab-pane class="tab-name" label="手机号登录" name="code">
              <div v-if="loginForm.type === 'code'">
                <el-form-item prop="mobile">
                  <span class="svg-container">
                    <svg-icon icon-class="mobile" />
                  </span>
                  <el-input ref="mobile" v-model="loginForm.mobile" placeholder="请输入手机号" name="mobile" type="text"
                    tabindex="1" auto-complete="on" />
                </el-form-item>
                <el-form-item prop="code" class="with-button">
                  <span class="svg-container">
                    <svg-icon icon-class="code" />
                  </span>
                  <el-input :key="passwordType" ref="code" v-model="loginForm.code" placeholder="请输入验证码" name="code"
                    type="text" tabindex="2" auto-complete="off" @keyup.enter.native="handleLogin" />
                  <el-button type="default" :disabled="disabled" @click="getCode">{{ btnText }}</el-button>
                </el-form-item>
                <el-form-item>
                  <verify v-if="loginForm.type === 'code'" key="code" :type="3" :show-button="false"
                    :bar-size="{ width: '357px', height: '42px' }" @success="verifyBool = true"
                    @error="verifyBool = false" />
                </el-form-item>
              </div>
            </el-tab-pane> -->
            <el-tab-pane v-if="isLgy" class="tab-name" label="测试账户登录" name="test">
              <div v-if="loginForm.type === 'test'">
                <el-form-item prop="serialNumber">
                  <span class="svg-container">
                    <i class="el-icon-edit" />
                  </span>
                  <el-input ref="serialNumber" v-model="loginForm.serialNumber" placeholder="请输入序列号" name="serialNumber"
                    type="text" tabindex="1" auto-complete="on" />
                </el-form-item>
                <el-form-item prop="username">
                  <span class="svg-container">
                    <svg-icon icon-class="username" />
                  </span>
                  <el-input ref="username" v-model="loginForm.username" placeholder="请输入用户名" name="username" type="text"
                    tabindex="1" auto-complete="on" />
                </el-form-item>
                <el-form-item prop="password">
                  <span class="svg-container">
                    <svg-icon icon-class="password" />
                  </span>
                  <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"
                    placeholder="请输入密码" name="password" tabindex="2" auto-complete="on"
                    @keyup.enter.native="handleLogin" />
                  <span class="show-pwd" @click="showPwd">
                    <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" />
                  </span>
                </el-form-item>
                <el-form-item>
                  <verify v-if="loginForm.type === 'test'" key="pwd" :type="3" :show-button="false"
                    :bar-size="{ width: '357px', height: '42px' }" @success="verifyBool = true"
                    @error="verifyBool = false" />
                </el-form-item>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-form>
        <!-- <div class="u-flex u-col-center u-row-between u-p-b-20">
          <div />
          <a href="javascript:" class="login-text u-font-14" @click="forgetPwd = true">忘记密码</a>
        </div> -->
        <el-button :loading="loading" type="primary" style="width:100%;margin-bottom:30px;"
          @click.native.prevent="handleLogin">登 录</el-button>
      </div>
      <div v-else class="login-box">
        <forget @change-type="forgetPwd = false" />
      </div>
    </div>
    <a class="footer" href="https://beian.miit.gov.cn/">COPYRIGHT © {{ copyright }}</a>
  </div>
</template>

<script>

import { isMobile } from '@/utils/validate'
import { getCode } from '@/api/common'
import Verify from 'vue2-verify'
import Forget from '@/views/login/components/forget'
import { homeUrl, copyright, slogan, myName } from '@/settings'
const btnText = '获取验证码'
export default {
  components: {
    Forget,
    Verify
  },
  data() {
    const validateMobile = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error('请输入手机号'))
      } else if (isMobile(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    return {
      isLgy: myName === '零工云众包',
      homeUrl: homeUrl,
      copyright: copyright,
      forgetPwd: false,
      verifyBool: false,
      slogan: slogan,
      title: process.env.VUE_APP_BASE_NAME,
      loginForm: {
        username: (process.env.NODE_ENV === 'development' ? 'superAdmin' : ''),
        password: (process.env.NODE_ENV === 'development' ? 'linggongyun_v3' : ''),
        serialNumber: '',
        mobile: '',
        code: '',
        type: 'pwd' // pwd：密码 code:验证码
      },
      btnText: btnText,
      disabled: false,
      loginRules: {
        serialNumber: [{ required: true, trigger: 'blur', validator: 'required', message: '请输入序列号' }],
        username: [{ required: true, trigger: 'blur', validator: 'required', message: '请输入用户名' }],
        password: [{ required: true, trigger: 'blur', validator: 'required', message: '请输入密码' }],
        mobile: [{ required: true, trigger: 'blur', validator: validateMobile }],
        code: [{ required: true, trigger: 'blur', validator: 'required', message: '请输入验证码' }]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    toMain() {
      if (!this.$u.test.isEmpty(homeUrl)) {
        window.open(homeUrl)
      }
    },
    changeTabs() {
      this.verifyBool = false
      return true
    },
    doLoop: function (seconds) {
      seconds = seconds || 60
      this.btnText = seconds + 's后获取'
      const countdown = setInterval(() => {
        if (seconds > 0) {
          this.btnText = seconds + 's后获取'
          --seconds
        } else {
          this.btnText = btnText
          this.disabled = false
          clearInterval(countdown)
        }
      }, 1000)
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    getCode() {
      if (!this.verifyBool) {
        return this.$message.error('请先进行滑动验证')
      }
      const mobile = this.loginForm.mobile
      if (!mobile.length) {
        return this.$message.error('请输入手机号')
      } else if (isMobile(mobile)) {
        return this.$message.error('请输入正确的手机号')
      }
      this.disabled = true
      getCode({
        codeType: 'LOGIN',
        mobileCode: mobile
      }).then(res => {
        this.$message.success('发送短信成功')
        setTimeout(() => {
          this.doLoop(60)
        }, 500)
      }).catch(res => {
        this.disabled = false
      })
    },
    handleLogin() {
      if (!this.verifyBool) {
        return this.$message.error('请先进行滑动验证')
      }
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.$store.dispatch('user/login', this.loginForm).then(() => {
            this.$router.push({ path: this.redirect || '/' })
            this.loading = false
          }).catch(() => {
            this.loading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */
.verify-bar-area {
  border: none;
}
</style>

<style lang="scss" scoped>
$loginButtonText: #fff !default;
@import "~@/styles/variables.scss";
$dark_gray: #889aa4;
$light_gray: #eee;
$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

.topbar {
  font-size: 16px;
  color: #333333;
  background-color: #fff;
  height: 80px;
  padding: 20px 260px;

  .el-button {
    width: 90px;
    height: 32px;
    font-size: 12px;
    border-radius: 6px;
  }

  .el-button--default {
    color: $loginBg;
    border-color: $loginBg;
  }
}

.el-button--primary {
  background-color: $loginButton;
  border-color: $loginButton;
  color: $loginButtonText;
}

/* reset element-ui css */
.login-container {
  .login-text {
    color: $loginText;
  }

  ::v-deep {
    .el-form-item {
      /*height: 42px;*/
    }

    .el-input {
      display: inline-block;
      /*height: 40px;*/
      width: 85%;

      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        /*padding: 12px 5px 12px 15px;*/
        color: #333;
        /*height: 42px;*/

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: $cursor !important;
        }
      }
    }

    .el-form-item {
      border: 1px solid #ccc;
      color: #454545;

      &.with-button {
        position: relative;

        .el-input {
          position: absolute;
          left: 32px;
        }

        .el-button {
          position: absolute;
          border: 0;
          top: 0;
          bottom: 0;
          border-radius: 0;
          border-left: 1px solid #ccc;
          right: 0;
        }
      }
    }

    .el-tabs__active-bar {
      background-color: $loginTabBarActiveBg;
    }

    .el-tabs__item {
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      color: #CCCCCC;

      &.is-active {
        color: $loginTabActiveText;
      }
    }

    .el-tabs__content {
      margin-top: 45px;
    }
  }
}

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $loginBg;
  overflow: hidden;
  position: relative;

  .login-form {
    position: absolute;
    max-width: 100%;
    width: 420px;
    /*height: 500px;*/
    left: 50%;
    top: 50%;
    padding: 0;
    margin: -250px 0 0 -210px;
    overflow: hidden;
    border-radius: 8px;
  }

  .logo-title {
    font-size: 38px;
    font-weight: 400;
    line-height: 60px;
    color: $loginTitleText;
    text-align: center;
    margin-bottom: 20px;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 0 5px 0 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 50px;
    width: 280px;
    bottom: 0;
    background: rgba(249, 250, 251, 1);
    font-size: 28px;
    color: rgba(51, 51, 51, 1);
    padding-top: 80px;

    .title {
      font-size: 38px;
      font-weight: 400;
      line-height: 60px;
      color: rgba(64, 101, 225, 1);
    }

    .sub {
      font-size: 25px;
      font-weight: 400;
      line-height: 72px;
      color: rgba(0, 0, 0, 0.65);
    }
  }

  .login-box {
    background-color: #fff;
    padding: 30px;
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 2px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}

.footer {
  position: absolute;
  bottom: 55px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
}
</style>
