<template>
  <div class="u-p-6">
    <div class="bg-white">
      <el-form :inline="true" :model="formData" size="small">
        <el-form-item label="商户姓名">
          <el-input v-model="formData.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="唯一社会信用代码">
          <el-input v-model="formData.code" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-button type="primary" :loading="loading" @click="addWorker">添加商户</el-button>
    <div class="u-p-lf-10 bg-white">
      <el-table
        ref="multipleTable"
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="商户名称"
          min-width="160"
          align="center"
        />
        <el-table-column
          prop="code"
          label="社会信用代码"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="cause"
          label="备注"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="createDate"
          label="创建时间"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="操作"
          width="180"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDel(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
    <el-dialog key="b" title="添加商户黑名单" :visible.sync="visibleDialogB" width="600px">
      <el-form ref="shopFormRef" v-loading="loading" :model="addFormData" :rules="addRules">
        <el-form-item label="商户名" prop="name">
          <el-input v-model="addFormData.name" />
          <!-- <el-select v-model="addFormData.name" style="width: 100%" placeholder="商户" clearable filterable>
            <el-option v-for="i in shopList" :key="i.id" :label="i.companyName" :value="i.id" />
          </el-select> -->
        </el-form-item>
        <el-form-item label="商户社会统一信用代码" prop="code">
          <el-input v-model="addFormData.code" />
        </el-form-item>
        <el-form-item label="原因备注">
          <el-input v-model="addFormData.cause" type="textarea" :rows="4" placeholder="请输入" maxlength="80" show-word-limit />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleDialogB = false">取 消</el-button>
        <el-button type="primary" @click="doCheck('shopFormRef')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { getCrowdShopList } from '@/api/common'
import { getBlacklist, delBlacklist, postBlacklist } from '@/api/blackList'
export default {
  props: {
    // 类型 cert/uncert
    // type: {
    //   type: String,
    //   required: true
    // }
  },
  data() {
    return {
      visibleDialogB: false,
      loading: false,
      // shopList: [],
      addFormData: {
        name: '',
        cause: '',
        userType: 'MERCHANT',
        code: ''
      },
      formData: {
        name: '',
        objectType: 'MERCHANT',
        code: ''
      },
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      },
      addRules: {
        name: [
          { required: true, message: '请输入商户姓名', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '商户社会信用代码', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    setFields(fields, val) {
      this.workerFormData[fields] = val
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getBlacklist(Object.assign({}, this.formData, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleEdit() {
    },
    doCheck(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.visibleDialogB = false
          this.loading = true
          postBlacklist(this.addFormData).then(res => {
            this.loading = false
            this.fetchData()
            this.$refs[formName].resetFields()
          }).catch(res => {
            this.loading = false
          })
        } else {
          return false
        }
      })
    },
    addWorker() {
      this.visibleDialogB = true
      // getCrowdShopList().then(res => {
      //   this.shopList = res.data || []
      // })
    },
    handleDel(i) {
      this.$confirm('你确定要删除黑名单吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        delBlacklist({
          ids: i.id
        }).then(res => {
          this.resetPage()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.fetchData()
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
  .batch{
    text-align: left;
  }
</style>
