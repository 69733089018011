var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "u-p-24 bg-white notice" }, [
    _c(
      "div",
      { staticClass: "u-m-b-36" },
      [
        _c("span", [_vm._v("消息通知")]),
        _c(
          "el-button",
          {
            staticStyle: { float: "right" },
            attrs: { type: "primary", size: "small" },
            on: {
              click: function ($event) {
                return _vm.clearNotice()
              },
            },
          },
          [_vm._v("一键清空消息")]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "box" }, [
      _c(
        "div",
        { staticStyle: { width: "60%" } },
        _vm._l(_vm.noticeList, function (notice, k) {
          return _c("div", { key: k, staticClass: "box-item" }, [
            _c("i", {
              staticClass: "el-icon-close",
              staticStyle: { float: "right" },
              on: {
                click: function ($event) {
                  return _vm.clearOneNotice(notice)
                },
              },
            }),
            _c("div", { staticClass: "u-flex u-row-between u-p-8" }, [
              _c("span", { staticClass: "shop-name" }, [
                _vm._v(_vm._s(notice.name)),
              ]),
              _c("div", [
                _c("span", {
                  staticClass: "u-badge success",
                  class: notice.status == 0 ? "primary" : "success",
                }),
                _vm._v(
                  " " + _vm._s(notice.status == 0 ? "处理中" : "已完成") + " "
                ),
              ]),
              _c("span", { staticClass: "shop-date" }, [
                _vm._v(_vm._s(notice.createDate)),
              ]),
            ]),
          ])
        }),
        0
      ),
    ]),
    _vm.noticeList && _vm.noticeList.length
      ? _c(
          "div",
          { staticClass: "pager-box" },
          [
            _c("el-pagination", {
              attrs: {
                background: "",
                layout: "prev, pager, next, jumper",
                "current-page": _vm.paramsData.pageNo,
                "page-size": _vm.paramsData.pageSize,
                "page-count": _vm.pageTotal,
              },
              on: {
                "update:currentPage": function ($event) {
                  return _vm.$set(_vm.paramsData, "pageNo", $event)
                },
                "update:current-page": function ($event) {
                  return _vm.$set(_vm.paramsData, "pageNo", $event)
                },
                "current-change": _vm.fetchData,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }