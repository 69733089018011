<template>
  <div class="u-form-item">
    <div class="u-flex u-row-between u-col-top">
      <div>
        <el-checkbox v-model="options.subAllCheck">分包汇总代开税率：</el-checkbox>
      </div>
      <div class="u-flex-1" :class="options.subAllCheck && options.subAllMonth ? '' : 'box-un-active'">
        <el-checkbox v-model="options.subAllMonth" :disabled="!options.subAllCheck" @change="changeCheck('subAllMonth', $event)">月度</el-checkbox>
        <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
          <div class="u-flex-1">起始金额</div>
          <div class="u-flex-1">结束金额</div>
          <div class="u-flex-1">税率</div>
          <div style="width: 50px" />
        </div>
        <div v-for="(i, k) in subAllMonthList" :key="i.guid" :class="errorIndex.subAllMonth === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
          <div class="u-flex-2 u-flex u-col-center u-row-center">
            <el-input v-model="i.startMoney" readonly placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
            <span class="u-p-lf-5">至</span>
            <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('subAllMonthList', k, $event)">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
          </div>
          <div class="u-flex-1 u-p-l-10">
            <el-input v-model="i.rate" placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">%</span>
            </el-input>
          </div>
          <div class="u-p-l-5" style="width: 50px">
            <i v-if="k !== 0 && k === (subAllMonthList.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('subAllMonthList', k)" />
          </div>
        </div>
        <div class="u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point" style="width: 100px;margin: auto" @click="addList('subAllMonthList')">
          <i class="el-icon-circle-plus-outline u-font-24" />添加
        </div>
      </div>
      <div class="u-flex-1" :class="options.subAllCheck && options.subAllQuarterly ? '' : 'box-un-active'">
        <el-checkbox v-model="options.subAllQuarterly" :disabled="!options.subAllCheck" @change="changeCheck('subAllQuarterly', $event)">季度</el-checkbox>
        <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
          <div class="u-flex-1">起始金额</div>
          <div class="u-flex-1">结束金额</div>
          <div class="u-flex-1">税率</div>
          <div style="width: 50px" />
        </div>
        <div v-for="(i, k) in subAllQuarterlyList" :key="i.guid" :class="errorIndex.subAllQuarterly=== k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
          <div class="u-flex-2 u-flex u-col-center u-row-center">
            <el-input v-model="i.startMoney" placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
            <span class="u-p-lf-5">至</span>
            <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('subAllQuarterlyList', k, $event)">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
          </div>
          <div class="u-flex-1 u-p-l-10">
            <el-input v-model="i.rate" placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">%</span>
            </el-input>
          </div>
          <div class="u-p-l-5" style="width: 50px">
            <i v-if="k !== 0" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('subAllQuarterlyList', k)" />
          </div>
        </div>
        <div class="u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point" style="width: 100px;margin: auto" @click="addList('subAllQuarterlyList')">
          <i class="el-icon-circle-plus-outline u-font-24" />添加
        </div>
      </div>
    </div>
    <div class="u-flex u-row-between u-col-top u-m-t-20">
      <div>
        <el-checkbox v-model="options.subOneCheck">分包单人单开税率：</el-checkbox>
      </div>
      <div class="u-flex-1" :class="options.subOneCheck && options.subOneMonth ? '' : 'box-un-active'">
        <el-checkbox v-model="options.subOneMonth" :disabled="!options.subOneCheck" @change="changeCheck('subOneMonth', $event)">月度</el-checkbox>
        <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
          <div class="u-flex-1">起始金额</div>
          <div class="u-flex-1">结束金额</div>
          <div class="u-flex-1">税率</div>
          <div style="width: 50px" />
        </div>
        <div v-for="(i, k) in subOneMonthList" :key="i.guid" :class="errorIndex.subOneMonth === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
          <div class="u-flex-2 u-flex u-col-center u-row-center">
            <el-input v-model="i.startMoney" readonly placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
            <span class="u-p-lf-5">至</span>
            <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('subOneMonthList', k, $event)">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
          </div>
          <div class="u-flex-1 u-p-l-10">
            <el-input v-model="i.rate" placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">%</span>
            </el-input>
          </div>
          <div class="u-p-l-5" style="width: 50px">
            <i v-if="k !== 0 && k === (subOneMonthList.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('subOneMonthList', k)" />
          </div>
        </div>
        <div class="u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point" style="width: 100px;margin: auto" @click="addList('subOneMonthList')">
          <i class="el-icon-circle-plus-outline u-font-24" />添加
        </div>
      </div>
      <div class="u-flex-1" :class="options.subOneCheck && options.subOneQuarterly ? '' : 'box-un-active'">
        <el-checkbox v-model="options.subOneQuarterly" :disabled="!options.subOneCheck" @change="changeCheck('subOneQuarterly', $event)">季度</el-checkbox>
        <div class="u-flex u-col-center u-row-center u-m-t-10 u-text-center">
          <div class="u-flex-1">起始金额</div>
          <div class="u-flex-1">结束金额</div>
          <div class="u-flex-1">税率</div>
          <div style="width: 50px" />
        </div>
        <div v-for="(i, k) in subOneQuarterlyList" :key="i.guid" :class="errorIndex.subOneQuarterly === k ? 'error-line' : ''" class="u-flex u-col-center u-row-center u-m-t-10">
          <div class="u-flex-2 u-flex u-col-center u-row-center">
            <el-input v-model="i.startMoney" readonly placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
            <span class="u-p-lf-5">至</span>
            <el-input v-model="i.endMoney" placeholder="请输入" @input="changeEnd('subOneQuarterlyList', k, $event)">
              <span slot="suffix" style="line-height: 40px">元</span>
            </el-input>
          </div>
          <div class="u-flex-1 u-p-l-10">
            <el-input v-model="i.rate" placeholder="请输入">
              <span slot="suffix" style="line-height: 40px">%</span>
            </el-input>
          </div>
          <div class="u-p-l-5" style="width: 50px">
            <i v-if="k !== 0 && k === (subOneQuarterlyList.length - 1)" class="el-icon-remove-outline text-danger u-font-18 u-point" @click="removeList('subOneQuarterlyList', k)" />
          </div>
        </div>
        <div class="u-m-t-20 u-text-center u-flex u-row-center u-col-center u-point" style="width: 100px;margin: auto" @click="addList('subOneQuarterlyList')">
          <i class="el-icon-circle-plus-outline u-font-24" />添加
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGuid } from '@/utils'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    totalChecked: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      subLabelWidth: '150px',
      options: {
        subAllCheck: false, // 分包汇总代开
        subAllMonth: false, // 分包汇总月度
        subAllQuarterly: false, // 分包汇总季度
        subOneCheck: false, // 分包单开
        subOneMonth: false,
        subOneQuarterly: false
      },
      // 错误行数
      errorIndex: {
        subAllCheck: undefined, // 分包汇总代开
        subAllMonth: undefined, // 分包汇总月度
        subAllQuarterly: undefined, // 分包汇总季度
        subOneCheck: undefined, // 分包单开
        subOneMonth: undefined,
        subOneQuarterly: undefined
      },
      subAllMonthList: [], // 分包汇总月度梯度
      subAllQuarterlyList: [], // 分包汇总季度梯度
      subOneMonthList: [], // 分包单开月度梯度
      subOneQuarterlyList: [] // 分包单开季度梯度
    }
  },
  watch: {
    data: {
      immediate: true,
      deep: true,
      handler: function(newVal) {
        this.initialize(newVal || [])
      }
    }
  },
  methods: {
    // 梯度价上修改
    changeEnd(name, index, val) {
      if (this[name].length > index + 1) {
        this[name][index + 1].startMoney = val
      }
    },
    changeCheck(name, val) {
      if (val) {
        if (name === 'subAllMonth') {
          this.options.subAllQuarterly = false
        } else if (name === 'subAllQuarterly') {
          this.options.subAllMonth = false
        } else if (name === 'subOneMonth') {
          this.options.subOneQuarterly = false
        } else if (name === 'subOneQuarterly') {
          this.options.subOneMonth = false
        }
      }
    },
    /**
     * 梯度价初始化
     */
    initialize(data) {
      data.map(val => {
        // 总包+分包
        const packageStatus = parseInt(val.packaegStatus)
        const status = parseInt(val.status)
        let name
        // 汇总
        if (packageStatus === 0) {
          this.options.subAllCheck = true
          if (status === 0) {
            // 月
            name = 'subAllMonthList'
            this.options.subAllMonth = true
          } else if (status === 1) {
            // 季度
            name = 'subAllQuarterlyList'
            this.options.subAllQuarterly = true
          }
        } else if (packageStatus === 1) { // 单开
          this.options.subOneCheck = true
          if (status === 0) {
            // 月
            name = 'subOneMonthList'
            this.options.subOneMonth = true
          } else if (status === 1) {
            // 季度
            this.options.subOneQuarterly = true
            name = 'subOneQuarterlyList'
          }
        }
        if (name) {
          this.addList(name, val)
        }
      })
    },
    /**
     * 添加梯度价
     * @param name 变量名
     * @param data
     */
    addList(name, data = {}) {
      data = Object.assign({}, data)
      const guid = getGuid()
      if (this.$u.test.isEmptyNotZero(data.startMoney)) {
        const length = this[name].length
        if (length === 0) {
          data.startMoney = 0
        } else {
          const last = this[name][length - 1]
          if (!this.$u.test.validateRange(last.endMoney, '0~')) {
            return this.$message.error('请填写正确的梯度价')
          }
          if ((+last.startMoney) >= (+last.endMoney)) {
            return this.$message.error('请填写正确的梯度价')
          }
          if (!this.$u.test.validateRange(last.rate, '0~')) {
            return this.$message.error('请填写正确的服务费率')
          }
          data.startMoney = last.endMoney
        }
      }
      this[name].push({
        'guid': guid,
        'id': data.id,
        'startMoney': data.startMoney || 0,
        'endMoney': data.endMoney || '',
        'rate': data.rate || ''
      })
    },
    /**
     * 删除梯度价
     * @param name 变量名
     * @param index 序号
     */
    removeList(name, index) {
      this[name].splice(index, 1)
    },
    /**
     * 验证梯度价
     * @param list
     * @param withObj 每个梯度价附加信息
     * @returns {{msg: string, data: [], success: boolean}}
     */
    validateLadders(list, withObj) {
      if (!list.length) {
        return {
          success: false,
          data: [],
          index: undefined,
          msg: '数据不能为空'
        }
      }
      const newData = []
      let result = true
      let msg = ''
      let preRowEndMoney = 0 // 上一行的结束金额
      let index
      for (let i = 0, len = list.length; i < len; i++) {
        const item = list[i]
        if (!this.$u.test.validateRange(item.rate, '0~100')) {
          result = false
          index = i
          break
        }
        if (!this.$u.test.validateRange(item.startMoney, preRowEndMoney + '~')) {
          result = false
          index = i
          break
        }
        item.startMoney = parseFloat(item.startMoney)
        if (!this.$u.test.validateRange(item.endMoney, item.startMoney + '~')) {
          result = false
          index = i
          break
        }
        item.endMoney = parseFloat(item.endMoney)
        preRowEndMoney = item.endMoney
        item.rate = parseFloat(item.rate)
        newData.push(Object.assign({}, item, withObj || {}))
      }
      if (!result) {
        msg = '梯度价不正确'
      }
      return {
        success: result,
        index: index,
        data: newData,
        msg: msg
      }
    },
    getLadders() {
      const options = this.options
      let totalCollectLadders = [] // 汇总
      let totalSingleLadders = [] // 单开
      if (this.totalChecked) {
        // 总包+分包
        if (options.subAllCheck) {
          // 分包汇总
          if (options.subAllMonth) {
            // 月梯度价
            const validateData = this.validateLadders(this.subAllMonthList, {
              'packaegStatus': 0,
              'status': 0
            })
            this.errorIndex.subAllMonth = validateData.index
            if (!validateData.success) {
              this.$message.error('分包汇总月梯度价不正确')
              return false
            }
            totalCollectLadders = totalCollectLadders.concat(validateData.data)
          }
          if (options.subAllQuarterly) {
            // 季度
            const validateData = this.validateLadders(this.subAllQuarterlyList, {
              'packaegStatus': 0,
              'status': 1
            })
            this.errorIndex.subAllQuarterly = validateData.index
            if (!validateData.success) {
              this.$message.error('分包汇总季度梯度价不正确')
              return false
            }
            totalCollectLadders = totalCollectLadders.concat(validateData.data)
          }
        }
        // 分包单开
        if (options.subOneCheck) {
          if (options.subOneMonth) {
            // 月梯度价
            const validateData = this.validateLadders(this.subOneMonthList, {
              'packaegStatus': 1,
              'status': 0
            })
            this.errorIndex.subOneMonth = validateData.index
            if (!validateData.success) {
              this.$message.error('分包单开月梯度价不正确')
              return false
            }
            totalSingleLadders = totalSingleLadders.concat(validateData.data)
          }
          if (options.subOneQuarterly) {
            // 季度
            const validateData = this.validateLadders(this.subOneQuarterlyList, {
              'packaegStatus': 1,
              'status': 1
            })
            this.errorIndex.subOneQuarterly = validateData.index
            if (!validateData.success) {
              this.$message.error('分包单开季度梯度价不正确')
              return false
            }
            totalSingleLadders = totalSingleLadders.concat(validateData.data)
          }
        }
      }
      return { totalSingleLadders, totalCollectLadders }
    }
  }
}
</script>

<style scoped lang="scss">
  .u-form-item {
    font-size: 14px;
    ::v-deep {
      .el-form-item__content {
        max-width: 480px;
      }
      .auto-width {
        .el-form-item__content {
          max-width: 800px;
        }
      }
    }
  }
  .error-line {
    ::v-deep {
      .el-input__inner {
        border-color: #f56c6c;
      }
    }
  }
  .box-un-active {
    .error-line {
      ::v-deep {
        .el-input__inner {
          border-color: #dcdfe6;
        }
      }
    }
    opacity: .3;
  }
</style>
