<template>
  <div class="u-p-25">
    <div class="search-box">
      <el-form :inline="true" :model="formData" size="small" class="u-border-bottom">
        <el-form-item label="时间">
          <el-date-picker v-model="formData.time" type="month" placeholder="请选择" value-format="yyyy-MM" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="resetPage">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="u-flex u-col-center u-row-left">
        <div class="u-p-20">
          <div class="u-tips-color u-font-14">代理商总人数</div>
          <div class="u-font-22 u-m-t-10">{{ count.num || 0 }}</div>
        </div>
        <!--<div class="u-p-20">
          <div class="u-tips-color u-font-14">流水提成（直属商户/下属代理商）</div>
          <div class="u-font-22 u-m-t-10">&yen; {{ count.totalMerchantCommission || 0 }} / {{ count.totalAgentCommission || 0 }}</div>
        </div>-->
        <div class="u-p-20">
          <div class="u-tips-color u-font-14">差额提成</div>
          <div class="u-font-22 u-m-t-10">&yen; {{ $u.formatMoney(count.totalMerchantDifference) || 0 }} / {{ $u.formatMoney(count.totalAgentDifference) || 0 }}</div>
        </div>
        <div class="u-p-20">
          <div class="u-tips-color u-font-14">提成总额</div>
          <div class="u-font-22 u-m-t-10">&yen; {{ $u.formatMoney(count.totalCommission) || 0 }}</div>
        </div>
      </div>
    </div>
    <div class="u-p-lf-10 bg-white u-m-t-20">
      <el-table v-loading="loading" class="c-table" :data="list" tooltip-effect="dark" style="width: 100%">
        <el-table-column prop="realName" label="代理商" align="center" />
        <el-table-column prop="merchantCount" label="直属商户" align="center" />
        <el-table-column label="商户交易额" align="center">
          <template slot-scope="scope">
            {{ !$u.test.isEmptyNotZero(scope.row.merchantBusiness) ? ('￥' + $u.formatMoney(scope.row.merchantBusiness)) : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="代理交易额" align="center">
          <template slot-scope="scope">
            {{ !$u.test.isEmptyNotZero(scope.row.agentBusiness) ? ('￥' + $u.formatMoney(scope.row.agentBusiness)) : '-' }}
          </template>
        </el-table-column>
        <!--<el-table-column
          width="180"
          :label="'流水提成\n（直属商户）'"
          align="center"
        >
          <template slot-scope="scope">
            {{ !$u.test.isEmptyNotZero(scope.row.merchantCommission) ? ('￥' + scope.row.merchantCommission) : '-' }} /
          </template>
        </el-table-column>-->
        <el-table-column width="180" :label="'差额提成\n（直属商户）'" align="center">
          <template slot-scope="scope">
            {{ !$u.test.isEmptyNotZero(scope.row.merchantDifference) ? ('￥' + $u.formatMoney(scope.row.merchantDifference)) : '-' }} /
          </template>
        </el-table-column>
        <el-table-column label="提成总额" align="center">
          <template slot-scope="scope">
            {{ !$u.test.isEmptyNotZero(scope.row.totalCommission) ? ('￥' + $u.formatMoney(scope.row.totalCommission)) : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="结算状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.settlementState, $enum.settlementState(), true)" />
          </template>
        </el-table-column>
        <el-table-column label="时间" width="150" align="center">
          <template slot-scope="scope">
            {{ scope.row.createDate }}
          </template>
        </el-table-column>
        <el-table-column width="120" label="操作" align="center">
          <template slot-scope="scope">
            <el-button v-if="(+scope.row.settlementState) === 0" type="text" size="small" @click="handleSettle(scope.row)">结算</el-button>
            <el-button type="text" size="small" @click="handleShow(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
      </div>
    </div>
  </div>
</template>

<script>
import { getCountList, getCountListTotal, settle } from '@/api/agent'

export default {
  data() {
    return {
      loading: false,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      },
      formData: {
        time: this.$dayjs().subtract(1, 'month').format('YYYY-MM'),
        objectType: 1
      },
      countLoading: false,
      count: {}
    }
  },
  created() {
    this.fetchData()
    this.fetchCount()
  },
  methods: {
    fetchCount() {
      this.countLoading = true
      getCountListTotal({
        objectType: 1
      }).then(res => {
        this.countLoading = false
        this.count = res.obj || {}
      }).catch(res => {
        this.countLoading = false
      })
    },
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getCountList(Object.assign({}, this.paramsData, this.formData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleShow(i) {
      this.$router.push({
        path: '/organization/agent/countDetail',
        query: {
          achievementStatisticsId: i.achievementStatisticsId,
          managersId: i.objectId,
          realName: i.objectName
        }
      })
    },
    handleSettle(row) {
      this.$confirm('你确定要结算吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        settle({
          achievementStatisticsId: row.achievementStatisticsId,
          managersId: row.objectId
        }).then(res => {
          this.$message.success('操作成功')
          this.fetchCount()
          this.fetchData()
        })
      })
        .catch(res => {})
    }
  }
}
</script>

<style scoped lang="scss">
</style>
