<template>
  <div>
    <el-upload
      v-loading="loading"
      class="upload-demo btn--upload"
      :action="action"
      :headers="header"
      :data="extraData"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :on-error="handleError"
      :before-remove="beforeRemove"
      :multiple="multiple"
      :file-list="fileList"
      :show-file-list="multiple"
      :name="fileName"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
    >
      <el-button slot="trigger" ref="elUpload" :icon="btnType === 'text' ? '' : 'el-icon-upload2'" size="small" :type="btnType">{{ text }}</el-button>
    </el-upload>
    <viewer ref="viewer" />
  </div>
</template>

<script>
import { getHeader } from '@/components/Upload/index'

export default {
  name: 'ButtonUpload',
  props: {
    value: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: '点击上传'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default() {
        return []
      }
    },
    btnType: {
      type: String,
      default: 'primary'
    },
    type: {
      type: String,
      default() {
        return 'image'
      }
    },
    // 自定义上传类型
    customType: {
      type: [Array, Boolean],
      default: false
    },
    successToast: {
      type: Boolean,
      default: true
    },
    errorToast: {
      type: Boolean,
      default: true
    },
    defaultAction: {
      type: [String],
      default: ''
    },
    name: {
      type: [String],
      default: ''
    },
    // 是否返回全部
    returnAll: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      header: {},
      // action: process.env.VUE_APP_BASE_API + '/platform/file/uploadJpgOrPdf',
      extraData: {},
      fileName: 'uploadJpgOrPdf'
    }
  },
  computed: {
    action() {
      if (this.defaultAction) {
        return process.env.VUE_APP_BASE_API + this.defaultAction
      } else {
        return process.env.VUE_APP_BASE_API + '/platform/file/uploadJpgOrPdf'
      }
    }
  },
  created() {
    this.header = getHeader()
    // if (!this.$u.test.isEmpty(this.defaultAction)) {
    //   this.action = process.env.VUE_APP_BASE_API + this.defaultAction
    // }
    const type = this.type
    const extraData = {}
    if (!this.$u.test.isEmpty(this.name)) {
      this.fileName = this.name
    } else if (type === 'image') {
      this.fileName = 'uploadJpgOrPdf'
      extraData.suffix = 'jpg,png,gif,bmp'
    } else if (type === 'excel') {
      this.fileName = 'workerExcel'
      extraData.suffix = 'xls,xlsx'
    } else if (type === 'pdf') {
      this.fileName = 'uploadJpgOrPdf'
      extraData.suffix = 'pdf'
    } else if (type === 'image/pdf' || type === 'pdf/image') {
      this.fileName = 'uploadJpgOrPdf'
      extraData.suffix = 'jpg,png,pdf'
    } else if (type === 'video') {
      extraData.suffix = 'mp4,mov,m4v,avi,m3u8'
    }
    this.extraData = extraData
  },
  methods: {
    handleRemove(file, fileList) {
      const newFileList = []
      for (let i = 0, len = fileList.length; i < len; i++) {
        const item = fileList[i]
        if (item.url) {
          newFileList.push(item.url)
        } else if (item.response && item.response.data) {
          newFileList.push(item.response.data)
        }
      }
      this.$emit('upload-remove', newFileList)
    },
    handleError() {
      this.loading = false
    },
    handlePreview(file) {
      if (file.url) {
        this.$preViewer(file.url)
      } else if (file.response && file.response.data) {
        this.$preViewer(file.response.data)
      }
    },
    beforeRemove(file, fileList) {
      this.loading = false
      if (this.multiple) {
        return this.$confirm(`确定移除 ${file.name}？`)
      }
    },
    handleUploadSuccess(res, file) {
      this.loading = false
      const code = parseInt(res.code)
      if (code === 200) {
        this.$emit('input', res.obj || res.data || '')
        if (this.successToast) {
          this.$message.success('上传成功')
        }
        if (this.returnAll) {
          this.$emit('upload-success', res)
        } else {
          this.$emit('upload-success', res.obj || res.data || '')
        }
      } else {
        if (this.errorToast) {
          this.$message.error(res.message)
        }
        this.$emit('upload-error', res)
      }
    },
    handleBeforeUpload(file) {
      this.loading = true
      if (typeof this.customType === 'object') {
        const nameArr = file.name.split('.')
        const nameSuffix = (nameArr.length ? nameArr[nameArr.length - 1] : '').toLowerCase()
        const ok = this.customType.indexOf(nameSuffix) !== -1
        if (!ok) {
          this.$message.error('上传文件格式不正确!')
        }
        return ok
      } else if (this.type === 'image') {
        const isJPG = ['image/jpeg', 'image/png'].indexOf(file.type) !== -1
        if (!isJPG) {
          this.$message.error('上传图片只能是 JPG/PNG 格式!')
          return isJPG
        } else {
          // 图片大小大于1MB则进行压缩
          const isLt2M = (file.size / 1024 / 1024) > 1
          if (isLt2M) {
            return new Promise((resolve, reject) => {
              const image = new Image(); let resultBlob = ''
              image.src = URL.createObjectURL(file)
              image.onload = () => {
                // 调用方法获取blob格式
                resultBlob = this.compressUpload(image, file)
                resolve(resultBlob)
              }
              image.onerror = () => {
                reject()
              }
            })
          } else {
           return true
          }
        }
      } else if (this.type === 'pdf') {
        const isPDF = ['application/pdf'].indexOf(file.type) !== -1
        if (!isPDF) {
          this.$message.error('只允许上传 PDF 格式!')
        }
        return isPDF
      } else if (this.type === 'image/pdf' || this.type === 'pdf/image') {
        const ok = ['image/jpeg', 'image/png', 'application/pdf'].indexOf(file.type) !== -1
        if (!ok) {
          this.$message.error('上传文件只能是 JPG/PNG/PDF 格式!')
        }
        return ok
      } else if (this.type === 'video') {
        const nameArr = file.name.split('.')
        const nameSuffix = (nameArr.length ? nameArr[nameArr.length - 1] : '').toLowerCase()
        const ok = ['mp4', 'mov', 'm4v', 'avi', 'm3u8'].indexOf(nameSuffix) !== -1
        if (!ok) {
          this.$message.error('上传视频只能是 mp4/mov/m4v/avi/m3u8 格式!')
        }
        return ok
      } else if (this.type === 'excel') {
        const nameArr = file.name.split('.')
        const nameSuffix = (nameArr.length ? nameArr[nameArr.length - 1] : '').toLowerCase()
        const ok = ['xlsx', 'xls', 'xml'].indexOf(nameSuffix) !== -1
        if (!ok) {
          this.$message.error('只允许上传 excel 格式!')
        }
        return ok
      }
      return true
    },
    // 图片压缩方法
    compressUpload(image, file) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // let initSize = image.src.length
        const { width } = image; const { height } = image
        canvas.width = width
        canvas.height = height
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        ctx.drawImage(image, 0, 0, width, height)

        const compressData = canvas.toDataURL('image/jpeg', 0.5)
        // console.log("压缩后base64URL：" + compressData);

        // 压缩后调用方法进行base64转Blob，方法写在下边
        const blobImg = this.dataURItoBlob(compressData)
        return blobImg
    },
    // base64转Blob对象
    dataURItoBlob(data) {
        let byteString;
        if (data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(data.split(',')[1])
        } else {
            byteString = unescape(data.split(',')[1])
        }
        const mimeString = data
            .split(',')[0]
            .split(':')[1]
            .split(';')[0];
        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i += 1) {
            ia[i] = byteString.charCodeAt(i)
        }
        return new Blob([ia], { type: mimeString })
    }
  }
}
</script>

<style lang="scss">
.btn--upload {
  .el-upload {
    border: none;
  }
}
</style>
