<template>
  <div class="u-p-25">
    <!-- 1 -->
    <div class="search-box">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="handleAdd">
        添加图片
      </el-button>
    </div>
    <div class="u-p-lf-10 bg-white">
      <el-table
        v-loading="loading"
        :data="list"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="serialNumber"
          label="排序"
          align="center"
        />
        <el-table-column
          label="图片"
          align="center"
        >
          <template slot-scope="scope">
            <el-image :src="scope.row.picture" fit="contain" style="height: 70px" />
          </template>
        </el-table-column>
        <el-table-column
          label="创建时间"
          align="center"
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.createDate }}
          </template>
        </el-table-column>
        <el-table-column
          width="200"
          label="操作"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDel(scope.row)">删除</el-button>
            <el-button type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="$preViewer(scope.row.picture)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pager-box">
        <el-pagination
          background
          layout="prev, pager, next, jumper"
          :current-page.sync="paramsData.pageNo"
          :page-size="paramsData.pageSize"
          :page-count="pageTotal"
          @current-change="fetchData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getBannerList as getList, delBanner as del } from '@/api/applet'

export default {
  data() {
    return {
      loading: false,
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    resetPage() {
      this.paramsData = Object.assign({}, this.paramsData, {
        pageNo: 1,
        pageSize: 10
      })
      this.list = []
      this.pageTotal = 0
      this.$nextTick(() => {
        this.fetchData()
      })
    },
    fetchData() {
      this.loading = true
      getList(Object.assign({}, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    handleAdd() {
      this.$router.push({
        path: '/applet/banner/add'
      })
    },
    handleEdit(i) {
      this.$router.push({
        path: '/applet/banner/edit',
        query: {
          id: i.id
        }
      })
    },
    handleDel(i) {
      this.$confirm('你确定要删除吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        del({
          id: i.id
        }).then(res => {
          this.resetPage()
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }).catch(res => {
          this.loading = false
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>

</style>
