import request from '@/utils/request'

/**
 * 查看轮播图列表
 * @returns {AxiosPromise}
 */
export function getBannerList(params) {
  return request({
    url: '/platform/applet/selectAppletBanner',
    method: 'post',
    params
  })
}
/**
 * 查看轮播图详情
 * @returns {AxiosPromise}
 */
export function getBannerDetail(params) {
  return request({
    url: '/platform/applet/queryAppletBannerInfo',
    method: 'post',
    params
  })
}

/**
 * 更新轮播图
 * @returns {AxiosPromise}
 */
export function updateBanner(data) {
  return request({
    url: '/platform/applet/saveOrUpdateAppletBanner',
    method: 'post',
    data
  })
}
/**
 * 删除轮播图
 * @returns {AxiosPromise}
 */
export function delBanner(params) {
  return request({
    url: '/platform/applet/deleteAppletBanner',
    method: 'delete',
    params
  })
}
/**
 * 查看常见问题列表
 * @returns {AxiosPromise}
 */
export function getFqList(params) {
  return request({
    url: '/platform/applet/selectAppletFaq',
    method: 'post',
    params
  })
}
/**
 * 查看常见问题详情
 * @returns {AxiosPromise}
 */
export function getFqDetail(params) {
  return request({
    url: '/platform/applet/queryAppletFaqInfo',
    method: 'post',
    params
  })
}

/**
 * 更新常见问题
 * @returns {AxiosPromise}
 */
export function updateFq(data) {
  return request({
    url: '/platform/applet/addOrUpdateAppletFaq',
    method: 'post',
    data
  })
}
/**
 * 删除常见问题
 * @returns {AxiosPromise}
 */
export function delFq(params) {
  return request({
    url: '/platform/applet/deleteAppletFaq',
    method: 'delete',
    params
  })
}
/**
 * 查看其他信息列表
 * @returns {AxiosPromise}
 */
export function getOtherList(params) {
  return request({
    url: '/platform/applet/selectAppletOtherInfo',
    method: 'post',
    params
  })
}
/**
 * 查看其他信息详情
 * @returns {AxiosPromise}
 */
export function getOtherDetail(params) {
  return request({
    url: '/platform/applet/queryAppletOtherInfo',
    method: 'post',
    params
  })
}

/**
 * 更新其他信息
 * @returns {AxiosPromise}
 */
export function updateOther(data) {
  return request({
    url: '/platform/applet/saveOrUpdateAppletOtherInfo',
    method: 'post',
    data
  })
}

