import request from '@/utils/request';

/**
 * 获取银联余额
 * @param params
 * @returns {AxiosPromise}
 */
export function getTotalUnionBalance(params) {
  return request({
    url: '/platform/paymentOrder/queryCompanyUnionpayBalance',
    method: 'get',
    params,
  });
}
/**
 * 获取银联余额
 * @param params
 * @returns {AxiosPromise}
 */
export function getCrowdUnionBalance(params) {
  return request({
    url: '/platform/paymentOrderMany/queryCompanyUnionpayBalance',
    method: 'get',
    params,
  });
}
/**
 * 查询商户相应支付宝的余额详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getTotalAlipayBalance(params) {
  return request({
    url: '/platform/paymentOrder/queryCompanyAlipayBalance',
    method: 'get',
    params,
  });
}

/**
 * 修改支付清单创客银行卡
 * @param params
 * @returns {AxiosPromise}
 */
export function updateBankCode(params) {
  return request({
    url: '/merchant/paymentOrder/updatePaymentInventory',
    method: 'post',
    params,
  });
}

/**
 * 修改支付清单创客银行卡
 * @param params
 * @returns {AxiosPromise}
 */
export function updateInventoryFee(data) {
  return request({
    url: '/platform/paymentOrder/updateInventoryFee',
    method: 'post',
    data,
  });
}

/**
 * 总包+分包列表
 * @param data
 * @returns {AxiosPromise}
 */
export function getTotalAndSubList(data) {
  return request({
    url: '/platform/paymentOrder/getPaymentOrderAll',
    method: 'post',
    data,
  });
}
/**
 * 总包+分包删除
 * @param params
 * @returns {AxiosPromise}
 */
export function delAndSub(params) {
  return request({
    url: '/platform/paymentOrder/deletePaymentOrder',
    method: 'post',
    params,
  });
}
/**
 * 总包+分包详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getTotalAndSubDetail(params) {
  return request({
    url: '/platform/paymentOrder/getPaymentOrderInfo',
    method: 'get',
    params: {
      ...params,
      isReturnInventory:false
    },
  });
}

/**
 * 总包支付
 * @param data
 * @returns {AxiosPromise}
 */
export function payTotal(data) {
  return request({
    url: '/merchant/paymentOrder/paymentOrderPay',
    method: 'post',
    data,
  });
}

/**
 * 获取关联任务
 * @param params
 * @returns {AxiosPromise}
 */
export function getTaskList(params) {
  return request({
    url: '/platform/paymentOrder/associatedTasks',
    method: 'post',
    params,
  });
}
/**
 * 更新总包+分包
 * @param data
 * @returns {AxiosPromise}
 */
export function updateTotalAndSub(data, params) {
  return request({
    url: '/platform/paymentOrder/saveOrUpdata',
    method: 'post',
    data,
    params,
  });
}

/**
 * 下载总包支付模板
 * @param type
 * @returns {AxiosPromise}
 */
export function downloadTotalTemp(type) {
  let url;
  if (type === 1) {
    // 下载总包+分包支付清单模板
    url = '/templateFile/getTotalPayInventory';
  } else {
    // 下载总包+分包交付支付验收单
    url = '/templateFile/getTotalAcceptanceCertificate';
  }
  return request({
    url: url,
    method: 'get',
  });
}

/**
 * 众包列表
 * @param data
 * @returns {AxiosPromise}
 */
export function getCrowdList(data) {
  return request({
    url: '/platform/paymentOrderMany/getPaymentOrderManyAll',
    method: 'post',
    data,
  });
}
/**
 * 众包详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getCrowdDetail(params) {
  return request({
    url: '/platform/paymentOrderMany/getPaymentOrderManyInfo',
    method: 'get',
    params,
  });
}
/**
 * 更新众包
 * @param data
 * @returns {AxiosPromise}
 */
export function updateCrowd(data, params) {
  return request({
    url: '/platform/paymentOrderMany/saveOrUpdata',
    method: 'post',
    data,
    params,
  });
}
/**
 * 分包线下支付
 * @param params
 * @returns {AxiosPromise}
 */
export function paySubOffline(params) {
  return request({
    url: '/platform/paymentOrder/subpackagePayment',
    method: 'post',
    params,
  });
}
/**
 * 下载众包支付模板
 * @param type
 * @returns {AxiosPromise}
 */
export function downloadCrowdTemp(type) {
  let url;
  if (type === 1) {
    // 下载总包+分包支付清单模板
    url = '/templateFile/getManyPayInventory';
  } else {
    // 下载总包+分包交付支付验收单
    url = '/templateFile/getManyAcceptanceCertificate';
  }
  return request({
    url: url,
    method: 'get',
  });
}

/**
 * 上传支付清单
 * @param type 0 总包 1 众包
 * @returns {string}
 */
export function readExcel(type) {
  return '/platform/file/uploadInvoice?isNot=' + type;
}

/**
 * 总包审核(确认收款/驳回)
 * @param params
 * @returns {AxiosPromise}
 */
export function checkTotalPay(params) {
  return request({
    url: '/platform/paymentOrder/paymentOrderAudit',
    method: 'post',
    params,
  });
}

/**
 * 众包审核(确认收款/驳回)
 * @param params
 * @returns {AxiosPromise}
 */
export function checkCrowdPay(params) {
  return request({
    url: '/platform/paymentOrderMany/paymentOrderManyAudit',
    method: 'post',
    params,
  });
}
/**
 * 获取梯度价
 * @param params
 * @returns {AxiosPromise}
 */
export function getPrice(params) {
  return request({
    url: '/platform/paymentOrder/gradientPrice',
    method: 'post',
    params,
  });
}

/**
 * 分包发薪转账
 * @param params
 * @returns {AxiosPromise}
 */
export function alipaySubpackage(params) {
  return request({
    url: '/platform/paymentOrder/alipaySubpackage',
    method: 'post',
    params,
  });
}

/**
 * 下载支付清单
 * @param params
 * @returns {AxiosPromise}
 */
export function downloadInvoice(params, data) {
  return request({
    url: '/platform/file/downloadInvoice',
    method: 'post',
    params,
    data,
  });
}
//
/**
 * 一键导出
 * @param params
 * @returns {AxiosPromise}
 */
export function getInventory(params) {
  return request({
    url: '/platform/paymentOrder/inventory',
    method: 'get',
    params,
  });
}
/**
 * 导出错误清单
 * @param params
 * @returns {AxiosPromise}
 */
export function getInventoryIssue(params) {
  return request({
    url: '/platform/paymentOrder/inventoryIssue',
    method: 'get',
    params,
  });
}
/**
 * 导出回单
 * @returns {AxiosPromise}
 */
export function downloadReceipt(data) {
  return request({
    url: '/platform/file/downloadReceipt',
    method: 'post',
    data,
  });
}
/**
 * 批量关闭支付
 * @param params
 * @returns {AxiosPromise}
 */
export function batchClosePayment(params) {
  return request({
    url: '/platform/paymentOrder/batchClosePayment',
    method: 'post',
    params,
  });
}
/**
 * 查询搜索支付订单下的分包明细信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getPaymentInventoryList(params) {
  return request({
    url: '/platform/paymentOrder/getPaymentInventoryList',
    method: 'get',
    params,
  });
}
/**
 * 修改总包订单服务费
 * @param params
 * @returns {AxiosPromise}
 */
export function updatePaymentOrderFee(data) {
  return request({
    url: '/platform/paymentOrder/updatePaymentOrderFee',
    method: 'post',
    data,
  });
}