<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-card v-loading="loading">
      <div class="u-flex u-col-center user-base-box">
        <div class="name u-flex u-col-center u-flex-1">
          <avatar class="u-m-r-10" :src="baseInfo.avatar" :size="50" />
          {{ baseInfo.accountName }}
          <span v-if="parseInt(baseInfo.attestation) === 2" class="tag">
            <svg-icon class-name="renzheng" icon-class="renzheng" />认证用户
          </span>
        </div>
        <div class="u-flex u-col-center u-flex-2 u-row-around">
          <div>
            <div class="sub">任务总数</div>
            <div class="value">{{ count.taskNumber || 0 }}</div>
          </div>
          <div>
            <div class="sub">收入总额</div>
            <div class="value">{{ count.totalRevenue || 0 }}</div>
          </div>
          <div>
            <div class="sub">纳税总额</div>
            <div class="value">{{ count.totalTax || 0 }}</div>
          </div>
          <div>
            <div class="sub">对应发票数</div>
            <div class="value">{{ count.invoiceNumber || 0 }}</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-loading="loading" class="u-m-t-20">
      <div class="card--title">创客信息</div>
      <div class="card--content">
        <el-row :gutter="20" class="card--info">
          <el-col :md="8">
            <span class="label">创客编号：</span>
            <span class="value">{{ baseInfo.id }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">创客姓名：</span>
            <span class="value">{{ baseInfo.accountName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">手机号：</span>
            <span class="value">{{ baseInfo.mobileCode }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">实名认证：</span>
            <span class="value" v-html="$options.filters.filterContent(baseInfo.attestation, $enum.workAttestation())" />
          </el-col>
          <el-col :md="8">
            <span class="label">签约状态：</span>
            <span class="value" v-html="$options.filters.filterContent(baseInfo.agreementSign, $enum.workAgreementSign())" />
          </el-col>
          <el-col :md="8">
            <span class="label">身份证号：</span>
            <span class="value">{{ baseInfo.idcardCode }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">身份证照片：</span>
            <span class="value">
              <file-icon class="u-m-r-5" :url="baseInfo.idcardFront" btn btn-text="查看正面" />
              <file-icon :url="baseInfo.idcardBack" btn btn-text="查看背面" />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">银行卡号：</span>
            <span class="value">{{ baseInfo.bankCode }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">开户行：</span>
            <span class="value">{{ baseInfo.bankName }}</span>
          </el-col>
          <el-col :md="8">
            <span class="label">认证视频：</span>
            <span class="value">
              <file-icon :url="baseInfo.attestationVideo" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">加盟合同：</span>
            <span class="value">
              <file-icon :url="baseInfo.agreementUrl" btn />
            </span>
          </el-col>
          <el-col :md="8">
            <span class="label">入驻时间：</span>
            <span class="value">{{ baseInfo.createDate }}</span>
          </el-col>
          <el-col v-if="!boolMakerVerify" :md="8">
            <span class="label">自由者职业协议：</span>
            <span class="value">
              <file-icon :url="baseInfo.freeWorkerAgreementUrl" btn />
            </span>
          </el-col>
          <!--          <el-col :md="8">-->
          <!--            <span class="label">状态：</span>-->
          <!--            <span class="value">{{ baseInfo.creatDate }}</span>-->
          <!--          </el-col>-->
        </el-row>
      </div>
    </el-card>
    <el-card class="u-m-t-20">
      <div class="card--title">合同详情</div>
      <div class="card--content">
        <contract-list :worker-id="id" />
      </div>
    </el-card>
    <el-card v-loading="listLoading" class="u-m-t-20">
      <div class="card--title">创客任务详情信息</div>
      <div class="card--content">
        <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="accountName" label="姓名" width="100" align="center" />
          <el-table-column prop="companyName" width="120" label="发包商户名称" align="center" />
          <el-table-column prop="taskName" width="180" label="任务名称" align="center" />
          <el-table-column label="任务金额" align="center">
            <template slot-scope="scope">
              {{ !$u.test.isEmptyNotZero(scope.row.taskAmount) ? ('￥' + $u.formatMoney(scope.row.taskAmount)) : '-' }}
            </template>
          </el-table-column>
          <el-table-column label="任务状态" align="center">
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.taskStatus, $enum.taskState())" />
            </template>
          </el-table-column>
          <el-table-column prop="createDate" label="接单时间" align="center" />
          <el-table-column prop="checkDate" label="完成时间" align="center" />
        </el-table>
        <div class="pager-box">
          <el-pagination background layout="prev, pager, next, jumper" :current-page.sync="paramsData.pageNo" :page-size="paramsData.pageSize" :page-count="pageTotal" @current-change="fetchData" />
        </div>
      </div>
    </el-card>
    <el-card class="u-m-t-20">
      <div class="card--title">支付列表明细</div>
      <div class="card--content">
        <pay-list :worker-id="id" />
      </div>
    </el-card>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getCountInfo, getDetail, getTaskList } from '@/api/worker'
import PayList from '@/views/worker/components/payList'
import ContractList from './components/contractList'

export default {
  components: { PayList, ContractList },
  data() {
    return {
      boolMakerVerify: true,
      id: undefined,
      loading: false,
      listLoading: false,
      baseInfo: {},
      count: {},
      list: [],
      pageTotal: 0,
      paramsData: {
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  created() {
    this.id = this.$route.query.id
    this.boolMakerVerify = ((+this.$route.query.boolMakerVerify) === 0)
    this.fetchData()
    this.fetchDetail()
  },
  // activated() {
  //   this.fetchData()
  // },
  methods: {
    fetchDetail() {
      this.loading = true
      getDetail({
        id: this.id
      }).then(res => {
        this.baseInfo = res.obj || {}
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
      getCountInfo({
        workerId: this.id
      }).then(res => {
        this.count = res.obj || {}
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    },
    fetchData() {
      this.listLoading = true
      getTaskList(Object.assign({}, {
        workerId: this.id
      }, this.paramsData)).then(res => {
        this.list = res.data || []
        this.pageTotal = res.pageCount
        this.listLoading = false
      }).catch(res => {
        this.listLoading = false
      })
    },
    handleShow() {
    }
  }
}
</script>

<style scoped lang="scss">
.renzheng {
  width: 12px;
  height: 12px;
  color: #ffbd00;
}
.user-base-box {
  .name {
    margin-left: 20px;
    font-size: 20px;
    color: #333;
  }

  .tag {
    display: inline-block;
    margin-left: 2px;
    height: 20px;
    line-height: 16px;
    padding: 2px 5px;
    background: rgba(64, 108, 255, 1);
    border-radius: 25px;
    font-size: 9px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .sub {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }

  .value {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
