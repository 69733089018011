<template>
  <el-breadcrumb class="app-breadcrumb" separator="/">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
        <span v-if="index==levelList.length-1">{{ item.meta.title }}</span>
        <span v-else class="no-redirect">{{ item.meta.title }}</span>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from 'path-to-regexp'

export default {
  data() {
    return {
      levelList: null
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    }
  },
  created() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      const insertPosition = matched.length - 1
      if (matched[insertPosition] && matched[insertPosition].meta) {
        const meta = matched[insertPosition].meta
        if (meta.catch) {
          this.$store.dispatch('route/changeCache', {
            key: this.$route.path,
            value: this.$route.query || {}
          })
        }
        let parent
        if (this.$route.query && this.$route.query.__path) {
          parent = { path: this.$route.query.__path }
        } else {
          parent = meta.parent
        }
        while (parent) {
          const route = this.$router.resolve(parent).route
          matched.splice(insertPosition, 0, route)
          parent = route.meta.parent
        }
        if (meta.childTitle) {
          matched = matched.concat([{ path: matched[insertPosition].path + '-child' + (new Date()).getMilliseconds(), meta: { title: meta.childTitle, activeMenu: matched[insertPosition].path }}])
        }
        this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
      }
    },
    isDashboard(route) {
      const name = route && route.name
      if (!name) {
        return false
      }
      return name.trim().toLocaleLowerCase() === 'Dashboard'.toLocaleLowerCase()
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route
      var toPath = pathToRegexp.compile(path)
      return toPath(params)
    },
    handleLink(item) {
      // const { redirect, path } = item
      // let query = {}
      // if (redirect) {
      //   query = this.$store.getters.route_cache[redirect] || {}
      //   this.$router.push({
      //     path: redirect,
      //     query: query
      //   })
      //   return
      // }
      // query = this.$store.getters.route_cache[path] || {}
      // this.$router.push({
      //   path: this.pathCompile(path),
      //   query: query
      // })
    }
  }
}
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
  display: block;
  font-size: 14px;
  line-height: 50px;
  padding-left: 25px;
  background-color: #fff;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }

  .default {
    color: #303133;
  }
}
</style>
