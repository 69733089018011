/**
 * 格式化其他类型
 * @param value 当前 value值
 * @param options [{value:xx,name:xxx,bool:true}]
 * @returns {string}
 */
export function formatContent(value, options) {
  let text;
  let type;
  for (let i = 0, len = options.length; i < len; i++) {
    if (value === options[i].value) {
      text = options[i].name;
      type = options[i].type ? options[i].type : '';
      break;
    }
  }
  if (text) {
    return contentHtml(type, text);
  }
  return '';
}

export function contentHtml(type, text) {
  return '<span class="u-badge ' + type + '"></span>' + text;
}

export function formatMoney(val) {
  if (val) {
    const toNumber = Number(val);
    return toNumber.toLocaleString();
  } else {
    return val;
  }
}
