<template>
  <div class="u-p-20 wrap-fixed-content">
    <el-card v-loading="loading">
      <div class="u-flex u-col-center user-base-box">
        <div class="name u-flex u-col-center">
          <avatar class="u-m-r-10" :src="query.user_head" :size="50" />
          <div>
            <div class="u-font-18">代理商：{{ query.realName }}</div>
            <div class="u-font-14 u-m-t-5">代理商编号：{{ query.managersId }}</div>
          </div>
        </div>
        <div class="u-flex u-col-center u-row-left">
          <div class="u-p-20">
            <div class="u-tips-color u-font-14">流水提成（直属商户）</div>
            <div class="u-font-22 u-m-t-10">&yen; {{ $u.formatMoney(count.totalMerchantCommission) || 0 }}</div>
          </div>
          <div class="u-p-20">
            <div class="u-tips-color u-font-14">差额提成（直属商户）</div>
            <div class="u-font-22 u-m-t-10">&yen; {{ $u.formatMoney(count.totalMerchantDifference) || 0 }}</div>
          </div>
          <div class="u-p-20">
            <div class="u-tips-color u-font-14">提成总额</div>
            <div class="u-font-22 u-m-t-10">&yen; {{ $u.formatMoney(count.totalCommission) || 0 }}</div>
          </div>
        </div>
      </div>
    </el-card>
    <el-card v-loading="loading" header="流水提成信息" class="u-m-t-20">
      <el-table class="c-table" :data="list" tooltip-effect="dark" style="width: 100%">
        <el-table-column label="时间" width="150" align="center">
          <template slot-scope="scope">
            {{ scope.row.createDate }}
          </template>
        </el-table-column>
        <el-table-column label="流水（直属商户）" align="center">
          <template slot-scope="scope">
            {{ !$u.test.isEmptyNotZero(scope.row.merchantBusiness) ? ('￥' + $u.formatMoney(scope.row.merchantBusiness)) : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="提成率" align="center">
          <template slot-scope="scope">
            {{ !$u.test.isEmptyNotZero(scope.row.merchantRate) ? (scope.row.merchantRate + '%') : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="流水提成（直属商户）" align="center">
          <template slot-scope="scope">
            {{ !$u.test.isEmptyNotZero(scope.row.merchantCommission) ? ('￥' + $u.formatMoney(scope.row.merchantCommission)) : '-' }}
          </template>
        </el-table-column>
        <el-table-column label="合计" align="center">
          <template slot-scope="scope">
            ￥{{ $u.formatMoney((+scope.row.merchantCommission)) }}
          </template>
        </el-table-column>
      </el-table>

      <div>
        <el-row :gutter="20" class="box">
          <el-col :md="12" :sm="24">
            <div class="title">直属商户流水：¥ {{ $u.formatMoney(merchantBusiness) }} </div>
            <div v-for="(i) in directCommissionProportion" :key="i.id" class="u-flex u-col-center u-row-center u-m-t-10">
              <div class="u-p-r-5" style="width: 50px">
                <i v-if="merchantBusiness >= (+i.startMoney) && merchantBusiness < (+i.endMoney)" class="el-icon-success text-success u-font-18" />
              </div>
              <div class="u-flex-2 u-flex u-col-center u-row-center">
                <el-input v-model="i.startMoney" readonly placeholder="请输入">
                  <span slot="suffix" style="line-height: 40px">元</span>
                </el-input>
                <span class="u-p-lf-5">至</span>
                <el-input v-model="i.endMoney" readonly placeholder="请输入">
                  <span slot="suffix" style="line-height: 40px">元</span>
                </el-input>
              </div>
              <div class="u-flex-1 u-p-l-10">
                <el-input v-model="i.commissionRate" readonly placeholder="请输入">
                  <span slot="suffix" style="line-height: 40px">%</span>
                </el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <el-card header="差额提成信息" class="u-m-t-20">
      <count-detail-pay-list :managers-id="query.managersId" />
    </el-card>
    <div class="wrap-fixed-footer btn--box">
      <el-button size="small" @click="$router.go(-1)">返回</el-button>
    </div>
  </div>
</template>

<script>
import { getCountDetailTotal } from '@/api/agent'
import CountDetailPayList from '@/views/organization/components/countDetailPayList'

export default {
  components: { CountDetailPayList },
  data() {
    return {
      id: undefined,
      loading: false,
      countLoading: false,
      list: [],
      count: {},
      query: {},
      directCommissionProportion: []
    }
  },
  computed: {
    merchantBusiness() {
      return this.list.length ? (+this.list[0].merchantBusiness) : 0
    }
  },
  created() {
    this.query = Object.assign({}, this.$route.query)
    this.fetchDetail()
  },
  methods: {
    fetchDetail() {
      this.loading = true
      getCountDetailTotal({
        managersId: this.query.managersId,
        achievementStatisticsId: this.query.achievementStatisticsId
      }).then(res => {
        const data = res.obj || {}
        this.count = data.totalAchievementStatisticsVO || {}
        this.list = data.achievementStatistics ? [data.achievementStatistics] : []
        this.merchantCommissionProportion = data.merchantCommissionProportion || []
        this.loading = false
      }).catch(res => {
        this.loading = false
      })
    }
  }
}
</script>

<style scoped lang="scss">
.box {
  margin: 40px;
  padding: 0 10px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  .title {
    font-size: 15px;
    line-height: 24px;
  }
}
.renzheng {
  width: 12px;
  height: 12px;
  color: #ffbd00;
}
.user-base-box {
  .name {
    margin-left: 20px;
    font-size: 20px;
    color: #333;
    padding-right: 50px;
  }

  .tag {
    display: inline-block;
    margin-left: 2px;
    height: 20px;
    line-height: 16px;
    padding: 2px 5px;
    background: rgba(64, 108, 255, 1);
    border-radius: 25px;
    font-size: 9px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }

  .sub {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
  }

  .value {
    font-size: 30px;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
