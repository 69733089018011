import request from '@/utils/request';

/**
 * 获取已接单创客明细
 * @param params
 * @returns {AxiosPromise}
 */
export function getJoinList(params) {
  return request({
    url: '/platform/worker/getYjWorkerDetails',
    method: 'post',
    params,
  });
}
/**
 * 获取已接单创客明细列表(验收)
 * @param params
 * @returns {AxiosPromise}
 */
export function getJoinListForCheck(params) {
  return request({
    url: '/platform/worker/getCheckByTaskId',
    method: 'post',
    params,
  });
}
/**
 * 获取所有已认证创客
 * @param data
 * @returns {AxiosPromise}
 */
export function getListCert(data) {
  return request({
    url: '/platform/worker/getWorkerQuery',
    method: 'post',
    data,
  });
}
/**
 * 获取未认证创客列表
 * @param data
 */
export function getListUnCert(data) {
  return request({
    url: '/platform/worker/getWorkerQueryNot',
    method: 'post',
    data,
  });
}
/**
 * 更新创客信息
 */
export function updateWorker(data) {
  return request({
    url: '/platform/worker/updateWorker',
    method: 'post',
    data,
  });
}
/**
 * 获取创客详情
 * @param params
 */
export function getDetail(params) {
  return request({
    url: '/platform/worker/getWorkerInfo',
    method: 'post',
    params,
  });
}
/**
 * 获取创客统计
 * @param params
 * @returns {AxiosPromise}
 */
export function getCountInfo(params) {
  return request({
    url: '/platform/worker/queryPassWorkerInfo',
    method: 'post',
    params,
  });
}

/**
 * 获取创客任务列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getTaskList(params) {
  return request({
    url: '/platform/worker/queryPassWorkerTaskInfo',
    method: 'post',
    params,
  });
}
/**
 * 创客支付列表信息
 * @param params
 * @returns {AxiosPromise}
 */
export function getPayList(params) {
  return request({
    url: '/platform/worker/queryWorkerPayInfo',
    method: 'post',
    params,
  });
}

/**
 * 导入创客
 * @returns {string}
 */
export function importWorker(data, params) {
  return request({
    url: '/platform/worker/saveWorker',
    method: 'post',
    data,
    params,
  });
}
/**
 * 读取创客excel
 * @returns {string}
 */
export function readExcel() {
  return '/platform/file/uploadWorker';
}

/**
 * 下载创客模块
 * @returns {AxiosPromise}
 */
export function downloadWorkerExcel() {
  return request({
    url: '/templateFile/getImportWorker',
    method: 'get',
  });
}

/**
 * 上传自由职业者协议
 * @param params
 * @returns {AxiosPromise}
 */
export function uploadFree(params) {
  return request({
    url: '/platform/worker/uploadFreeWorkerAgreementUrl',
    method: 'post',
    params,
  });
}
// 确认匹配服务商
export function matchTax(params) {
  return request({
    url: '/platform/worker/confirm-match-tax',
    method: 'post',
    params,
  });
}
// 批量匹配服务商
export function batchMatchTax(params) {
  return request({
    url: '/platform/worker/batch-match-tax',
    method: 'post',
    params,
  });
}
// 查询创客的承揽协议
export function queryWorkerTaxContract(params) {
  return request({
    url: '/platform/worker/queryWorkerTaxContract',
    method: 'get',
    params,
  });
}
// 上传创客合同
export function uploadAgreement(params) {
  return request({
    url: '/platform/worker/upload-agreement',
    method: 'post',
    params,
  });
}
/**
 * 通过ID单个发送短信通知创客签约功能
 * @param params
 * @returns {AxiosPromise}
 */
export function getSendID(params) {
  return request({
    url: '/platform/worker/sendOneMessage',
    method: 'post',
    params,
  });
}
/**
 * 批量发送短信通知创客签约功能
 * @param params
 * @returns {AxiosPromise}
 */
export function getSendBatch(data) {
  return request({
    url: '/platform/worker/send-short-url',
    method: 'post',
    data,
  });
}
/**
 * 批量导出创客照片
 */
export function downloadWorkerZip(params) {
  return request({
    url: '/platform/file/zip-upload',
    method: 'post',
    responseType: 'blob',
    params,
  });
}
/**
 * 批量导出四要素(Excel)
 */
export function downloadExcel(params, data) {
  return request({
    url: '/platform/file/downloadWorker',
    method: 'post',
    params,
    data,
  });
}
/**
 * 一键验收
 * @param params
 * @returns {AxiosPromise}
 */
export function getAcceptance(params) {
  return request({
    url: '/platform/task/check',
    method: 'put',
    params,
  });
}
/**
 * 导出创客合同
 * @param params
 * @returns {AxiosPromise}
 */
export function downloadAgreement(data) {
  return request({
    url: '/platform/file/downloadAgreement',
    method: 'post',
    data,
  });
}
