var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "u-text-right u-m-b-10" },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "small", icon: "el-icon-plus" },
              on: { click: _vm.handleAdd },
            },
            [_vm._v(" 添加监管服务商 ")]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%" },
          attrs: { data: _vm.list, "tooltip-effect": "dark" },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "taxName", label: "服务商名称", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "总包+分包交易流水", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" ￥" + _vm._s(scope.row.totalTab || 0) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "众包交易流水", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_vm._v(" ￥" + _vm._s(scope.row.manyTba || 0) + " ")]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$options.filters.filterContent(
                            scope.row.status,
                            _vm.$enum.openStatus(),
                            true
                          )
                        ),
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "开始监管时间", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(" " + _vm._s(scope.row.startRegulatorDate) + " "),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { width: "150", label: "操作", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleShow(scope.row)
                          },
                        },
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleToggle(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.status === 0 ? "关闭" : "开启"))]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "small" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCancel(scope.row)
                          },
                        },
                      },
                      [_vm._v("撤销")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pager-box" },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              layout: "prev, pager, next, jumper",
              "current-page": _vm.paramsData.pageNo,
              "page-size": _vm.paramsData.pageSize,
              "page-count": _vm.pageTotal,
            },
            on: {
              "update:currentPage": function ($event) {
                return _vm.$set(_vm.paramsData, "pageNo", $event)
              },
              "update:current-page": function ($event) {
                return _vm.$set(_vm.paramsData, "pageNo", $event)
              },
              "current-change": _vm.fetchData,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "查看关联任务",
            visible: _vm.visibleDialog,
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.visibleDialog = $event
            },
          },
        },
        [
          _c("check-service-list", {
            ref: "checkTable",
            attrs: { "regulator-id": _vm.regulatorId },
            on: {
              success: _vm.onSuccess,
              close: function ($event) {
                _vm.visibleDialog = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }