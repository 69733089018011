import request from '@/utils/request'

// 个体户相关接口

/**
 * 列表 / 传递id时为详情
 * @param params
 * @returns {AxiosPromise}
 */
export function getList(params) {
  const isService = params.taxId
  return request({
    url: isService ? '/platform/tax/individualBusiness/queryIndividualBusinessList' : '/platform/admin/individualBusiness/queryIndividualBusinessList',
    method: 'get',
    params: params
  })
}

/**
 * 查询详情
 * @param params
 * @param isService
 * @returns {AxiosPromise}
 */
export function getDetail(params, isService) {
  return request({
    url: isService ? '/platform/tax/individualBusiness/queryIndividualBusinessDetail' : '/platform/admin/individualBusiness/queryIndividualBusinessDetail',
    method: 'get',
    params: params
  })
}
/**
 * 查询编辑详情
 * @param params
 * @param isService
 * @returns {AxiosPromise}
 */
export function getEditDetail(params, isService) {
  return request({
    url: isService ? '/platform/tax/individualBusiness/queryUpdateIndividualBusinessDetail' : '/platform/admin/individualBusiness/queryUpdateIndividualBusinessDetail',
    method: 'get',
    params: params
  })
}

/**
 * 新增/编辑
 * @param data
 * @param isService
 * @returns {AxiosPromise}
 */
export function edit(data, isService) {
  return request({
    url: !isService ? '/platform/admin/individualBusiness/addOrUpdateIndividualBusiness' : '/platform/tax/individualBusiness/updateIndividualBusiness',
    method: 'post',
    data: data
  })
}

/**
 * 获取创客列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getWorkerList(params) {
  return request({
    url: '/platform/admin/individualBusiness/queryMakerList',
    method: 'get',
    params: params
  })
}

/**
 * 注销
 * @param params
 * @param isService
 * @returns {AxiosPromise}
 */
export function cancel(params, isService) {
  return request({
    url: !isService ? '/platform/tax/individualBusiness/cancelIndividualBusiness' : '/platform/tax/individualBusiness/cancelIndividualBusiness',
    method: 'post',
    params: params
  })
}

/**
 * 获取服务商列表
 * @param params
 * @returns {AxiosPromise}
 */
export function getServiceList(params) {
  return request({
    url: '/platform/admin/individualBusiness/queryTaxIdNameList',
    method: 'get',
    params: params
  })
}
/**
 * 匹配服务商
 * @param params
 * @returns {AxiosPromise}
 */
export function setService(params) {
  return request({
    url: '/platform/admin/individualBusiness/mate-service-provider',
    method: 'post',
    params: params
  })
}
/**
 * 上传营业执照
 * @param params
 * @returns {AxiosPromise}
 */
export function updateLicence(data) {
  return request({
    url: '/platform/admin/individualBusiness/update-businessLicence',
    method: 'post',
    data: data
  })
}
