/**
 * 用于面包屑组件动态参数
 * @returns {{query: {}}}
 */
const getDefaultState = () => {
  return {
    query: {}
  }
}

const state = getDefaultState()

const mutations = {
  RESET_STATE: (state) => {
    Object.assign(state, getDefaultState())
  },
  SET_CACHE: (state, { key, value }) => {
    state.query[key] = value
  }
}
const actions = {
  changeCache({ commit }, data) {
    commit('SET_CACHE', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
