<template>
  <div class="u-m-25 u-p-30 bg-white">
    <el-form ref="formData" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
      <el-form-item label="个体户名称" prop="candidatedNames">
        <el-input v-model="formData.companyName" placeholder="请输入名称（首选）" />
      </el-form-item>
      <el-form-item v-for="(i, k) in otherName" :key="i.key">
        <el-input v-model="i.value" placeholder="请输入名称（备选）">
          <i slot="suffix" class="el-input__icon el-icon-delete" @click="delOtherName(k)" />
        </el-input>
      </el-form-item>
      <el-form-item>
        <div class="u-dashed-border u-text-center u-point" @click="addOtherName()"><i class="el-icon-plus u-m-r-10" />添加名称</div>
      </el-form-item>
      <el-form-item label="行业类型" prop="mainIndustry" required>
        <el-select v-model="formData.mainIndustry" filterable @change="changeIndustry">
          <el-option v-for="(i, k) in mainIndustryList" :key="k" :value="i.value" :label="i.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="经营范围">
        <el-input v-model="formData.bizScope" placeholder="请输入经营范围" type="textarea" />
      </el-form-item>
      <el-form-item label="核定税种" required prop="bizType">
        <el-select v-model="formData.bizType" style="width: 100%" placeholder="请选择核定税种">
          <el-option v-for="(i, k) in bizType" :key="k" :label="i.name" :value="i.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="注册资金" prop="registeredMoney">
        <el-input v-model.number="formData.registeredMoney" type="number" placeholder="请输入注册资金" />
      </el-form-item>
      <el-form-item label="联系人" prop="contactName">
        <el-input v-model="formData.contactName" placeholder="请输入联系人" />
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input v-model="formData.contactPhone" placeholder="请输入联系电话" />
      </el-form-item>
      <el-form-item>
        <div class="u-dashed-border u-text-center u-point" @click="dialogTableVisible = true"><i class="el-icon-plus u-m-r-10" />选择经营者</div>
      </el-form-item>
    </el-form>
    <div class="u-p-t-10">
      <el-table
        :data="selectWorker"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          label="经营者姓名"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="phoneNumber"
          width="120"
          label="经营者手机号"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          prop="idcardNo"
          width="180"
          label="经营者身份证号"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column
          label="身份证人像面"
          align="center"
        >
          <template slot-scope="scope">
            <file-icon :url="scope.row.idcardPic" img img-style="width: 60px" />
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          label="身份证国徽面"
        >
          <template slot-scope="scope">
            <file-icon :url="scope.row.idcardPicBack" img img-style="width: 60px" />
          </template>
        </el-table-column>
        <!--        <el-table-column-->
        <!--          label="手持身份证正面"-->
        <!--          align="center"-->
        <!--        >-->
        <!--          <template slot-scope="scope">-->
        <!--            <file-icon :url="scope.row.idcardHand" img img-style="width: 60px" />-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column-->
        <!--          label="手持身份证背面"-->
        <!--          align="center"-->
        <!--        >-->
        <!--          <template slot-scope="scope">-->
        <!--            <file-icon :url="scope.row.idcardBackHand" img img-style="width: 60px" />-->
        <!--          </template>-->
        <!--        </el-table-column>-->
      </el-table>
    </div>
    <div class="u-text-center u-p-t-20">
      <el-button type="primary" @click="submitForm('formData')">确定</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </div>
    <el-dialog title="选择经营者" :visible.sync="dialogTableVisible" width="1200px">
      <worker-lookup @on-select="onSelect" />
    </el-dialog>
  </div>
</template>

<script>
import { getGuid, trim } from '@/utils'
import { getPersonalTax } from '@/utils/enum.js'
import { edit, getEditDetail } from '@/api/individualBusiness'
import { getDictList } from '@/api/common'
import WorkerLookup from '@/views/individualBusiness/components/workerLookup'
export default {
  components: { WorkerLookup },
  data() {
    return {
      // 备用名称
      otherName: [],
      selectWorker: [],
      dialogTableVisible: false,
      showViewer: {
        show: false,
        url: ''
      },
      mainIndustryList: [],
      firstChange: false, // 为true时，可禁止一次bizScope变化
      formData: {
        individualId: undefined,
        bizScope: '',
        bizType: 'SMALL',
        companyName: '', // 候选名称
        contactName: '',
        contactPhone: '',
        mainIndustry: '',
        makerId: undefined,
        registeredMoney: null
      },
      bizType: getPersonalTax(),
      rules: {
        companyName: [
          { required: true, message: '请输入企业名称（首选）', trigger: 'blur' }
        ],
        mainIndustry: [
          { required: true, message: '请输入行业类型', trigger: 'change' }
        ],
        bizType: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        registeredMoney: [
          { required: true, message: '请输入注册资金', trigger: 'blur' },
          { type: 'number', message: '请输入正确注册资金', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.mobile(value)) {
                callback(new Error('手机号码不正确'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    bizScope() {
      const mainIndustry = this.formData.mainIndustry
      const mainIndustryList = this.mainIndustryList
      if (this.$u.test.isEmpty(mainIndustry)) {
        return ''
      }
      let res = ''
      for (let i = 0, len = mainIndustryList.length; i < len; i++) {
        if (mainIndustryList[i].value === mainIndustry) {
          res = mainIndustryList[i].extra
          break
        }
      }
      return res
    },
    isServer() {
      return !!this.$route.meta.isServer
    }
  },
  created() {
    this.formData.individualId = this.$route.query.id
    this.fetchData()
    this.fetchDict()
  },
  methods: {
    changeIndustry() {
      this.$nextTick(() => {
        if (!this.firstChange) {
          this.formData.bizScope = this.bizScope
        }
      })
      this.firstChange = false
    },
    fetchDict() {
      getDictList({
        code: 'industry'
      }).then(res => {
        const data = res.data || []
        const newData = []
        if (data.length) {
          for (let i = 0, len = data.length; i < len; i++) {
            newData.push({
              value: data[i].dictValue,
              label: data[i].dictValue,
              extra: data[i].remark
            })
          }
        }
        this.mainIndustryList = newData
      })
    },
    fetchData() {
      if (this.formData.individualId) {
        getEditDetail({
          individualBusinessId: this.formData.individualId
        }).then(res => {
          this.firstChange = true
          this.loading = false
          const data = res.obj || {}
          const ibname = this.$ibname(data.ibname, data.candidatedNames)
          this.formData = Object.assign(this.formData, {
            bizScope: data.bizScope,
            bizType: data.bizType,
            companyName: ibname,
            contactName: data.contactName,
            contactPhone: data.contactPhone,
            mainIndustry: data.mainIndustry,
            makerId: undefined,
            registeredMoney: data.registeredMoney
          })
          if (!this.$u.test.isEmpty(data.candidatedNames)) {
            const candidatedNames = data.candidatedNames.split(',')
            for (let i = 0, len = candidatedNames.length; i < len; i++) {
              if (candidatedNames[i] !== ibname) {
                this.addOtherName(candidatedNames[i])
              }
            }
          }
          this.selectWorker = [
            {
              id: data.makerId,
              name: data.name,
              phoneNumber: data.phoneNumber,
              idcardNo: data.idcardNo,
              idcardPic: data.idcardPic,
              idcardPicBack: data.idcardPicBack,
              idcardHand: data.idcardHand,
              idcardBackHand: data.idcardBackHand
            }
          ]
        }).catch(res => {
          this.loading = false
        })
      }
    },
    onSelect(workers) {
      this.dialogTableVisible = false
      this.selectWorker = [].concat(workers)
    },
    // 新增备用名称
    addOtherName(name) {
      this.otherName.push({
        key: getGuid(),
        value: name || ''
      })
    },
    // 删除备用名称
    delOtherName(index) {
      this.otherName.splice(index, 1)
    },
    uploadSuccess(fileName, file) {
      this.formData[fileName] = file
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          if (!this.selectWorker.length) {
            return this.$message.error('请选择经营者')
          }
          form.workerId = this.selectWorker[0].id

          const other = this.otherName
          const otherArray = []
          otherArray.push(form.companyName)

          other.map((val, index) => {
            const value = trim(val.value)
            if (value.length) {
              otherArray.push(value)
            }
          })

          form.candidatedNames = otherArray.join(',')
          const loading = this.$loading()
          edit(form, this.isServer).then(res => {
            this.$message.success(res.message)
            loading.close()
            this.$router.go(-1)
          }).catch(res => {
            loading.close()
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
