<template>
  <div id="app">
    <router-view />
    <viewer ref="appViewer" />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
