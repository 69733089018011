<template>
  <worker-list type="cert" />
</template>

<script>
import WorkerList from '@/views/worker/components/workerList'
export default {
  name: 'WorkerCert',
  components: { WorkerList }
}
</script>

<style scoped>

</style>
