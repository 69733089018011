<template>
  <div>
    <el-tabs v-model="activeName" type="card" @tab-click="fetchData">
      <el-tab-pane label="银联支付" name="unionPay" />
      <el-tab-pane label="支付宝" name="alipay" />
      <el-tab-pane label="支付宝银联联动" name="AliPayUnionPay" />
      <el-tab-pane label="招行" name="zsyhPay" />
    </el-tabs>
    <div v-if="activeName === 'unionPay'">
      <el-table key="unionPay" ref="multipleTable" v-loading="listLoading" :data="list" tooltip-effect="dark" style="width: 100%">
        <el-table-column label="银行类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.unionpayBankType | unionpayBankType }}
          </template>
        </el-table-column>
        <el-table-column prop="merchno" label="商户号" align="center" />
        <el-table-column label="平台帐户账号" align="center">
          <template slot-scope="scope">
            {{ scope.row.acctno }}
          </template>
        </el-table-column>
        <el-table-column prop="clearNo" label="清分子账户" align="center" />
        <el-table-column prop="serviceChargeNo" label="手续费子账户" align="center" />
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.boolEnable ? 0 : 1, $enum.authStatus(), true)" />
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center" />
        <el-table-column width="220" label="操作" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleShow(scope.row)">查看余额</el-button>
            <el-button v-if="scope.row.unionpayBankType === 'SJBK'" type="text" size="small" @click="handleClear(scope.row)">清分</el-button>
            <el-button type="text" size="small" @click="handleDownLoad(scope.row)">对账文件下载</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else-if="activeName === 'AliPayUnionPay'">
      <div v-if="$u.test.isEmpty(alipayUnionPayData)" class="u-text-center">
        <el-button :loading="listLoading" type="primary" @click="handleAlipayUnionPayAgreement(true)">点击授权</el-button>
      </div>
      <el-table v-else key="AliPayUnionPayTable" v-loading="listLoading" :data="alipayUnionPayData" tooltip-effect="dark" style="width: 100%">
        <!--        <el-table-column-->
        <!--          prop="signOrderType"-->
        <!--          label="授权签约对象"-->
        <!--          align="center"-->
        <!--        />-->
        <el-table-column prop="signOrderId" label="用户签约号" align="center" />
        <el-table-column key="alipayUnionpaySignType" label="子账户类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.alipayUnionpaySignType | alipayUnionpaySignType }}
          </template>
        </el-table-column>
        <el-table-column prop="alipayUserId" label="支付宝唯一用户号" align="center" />
        <el-table-column prop="subAccountCode" label="子账户账号" align="center" />
        <el-table-column label="子账户户名" prop="subAccountName" align="center" />
        <el-table-column prop="createDate" width="180" label="创建时间" align="center" />
        <el-table-column key="alipayBalance" label="可用余额" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleAlipayUnionPayBalance(scope.row)">查看</el-button>
          </template>
        </el-table-column>
        <el-table-column width="140" label="操作" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.subAccountCode">
              <el-button v-if="scope.row.alipayUnionpaySignType == 'SERVICECHARGE'" type="text" @click="handleAlipayUnionPayWithdraw(scope.row)">提现</el-button>
              <el-button v-if="scope.row.alipayUnionpaySignType == 'SERVICECHARGE'" type="text" @click="handleAlipayUnionPayRefund(scope.row)">退款</el-button>
            </div>
            <el-button v-else type="text" @click="handleAlipayUnionPaySub(scope.row)">开通子账户</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else-if="activeName === 'alipay'">
      <!-- <div v-if="$u.test.isEmpty(alipayData)" class="u-text-center">
        <el-button :loading="listLoading" type="primary" @click="handleAlipayAgreement(true)">点击授权</el-button>
      </div>
      <div v-else>
        <el-table ref="multipleTable1" v-loading="listLoading" :data="alipayData" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="agreementNo" label="支付宝协议号" align="center" />
          <el-table-column prop="accountBookId" label="资金记账本ID" align="center" />
          <el-table-column prop="alipayUserId" label="支付宝唯一用户号" align="center" />
          <el-table-column key="alipayStatus" label="协议当前状态" align="center">
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.status, $enum.alipayStatus(), false)" />
            </template>
          </el-table-column>
          <el-table-column key="alipaySignType" label="记事本类型" align="center">
            <template slot-scope="scope">
              {{ scope.row.alipaySignType | alipaySignType }}
            </template>
          </el-table-column>
          <el-table-column prop="createDate" width="180" label="创建时间" align="center" />
          <el-table-column key="alipayBalance" label="可用余额" align="center">
            <template slot-scope="scope">
              {{ scope.row.alipayBalance ? scope.row.alipayBalance : '获取中' }}
            </template>
          </el-table-column>
          <el-table-column width="120" label="操作" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.status !== 'NORMAL'" type="text" @click="handleAlipayAgreement(false, scope.row)">授权</el-button>
              <el-button v-else type="text" @click="handleAlipayUnsign(scope.row)">解约</el-button>
              <el-button v-if="scope.row.status === 'NORMAL' && scope.row.alipaySignType === 'SERVICECHARGE'" type="text" @click="handleAlipayWithdraw(scope.row)">提现</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div> -->
      <el-table v-loading="listLoading" :data="alipayData" tooltip-effect="dark" max-height="350px" style="width: 100%">
        <!-- <el-table-column prop="alipayAccount" label="支付宝账号" align="center" />
        <el-table-column label="银行类型" align="center">
          <template slot-scope="scope">
            {{ scope.row.unionpayBankType | unionpayBankType }}
          </template>
        </el-table-column> -->
        <el-table-column prop="merchno" label="商户号" align="center" />
        <el-table-column label="平台帐户账号" align="center">
          <template slot-scope="scope">
            {{ scope.row.acctno }}
          </template>
        </el-table-column>
        <el-table-column prop="clearNo" label="清分子账户" align="center" />
        <el-table-column prop="serviceChargeNo" label="手续费子账户" align="center" />
        <el-table-column label="状态" align="center">
          <template slot-scope="scope">
            <div v-html="$options.filters.filterContent(scope.row.boolEnable ? 0 : 1, $enum.authStatus(), true)" />
          </template>
        </el-table-column>
        <el-table-column prop="createDate" label="创建时间" align="center" />
        <el-table-column label="操作" width="120" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleShowAliPay(scope.row)">查看余额</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-else>
      <div>
        <el-table ref="multipleTable1" v-loading="listLoading" :data="list" tooltip-effect="dark" style="width: 100%">
          <el-table-column prop="acctno" label="平台帐户账号" align="center" />
          <el-table-column prop="aesKey" label="aes加密串" align="center" />
          <el-table-column prop="userid" label="用户id" align="center" />
          <el-table-column prop="id" label="服务商银联ID" align="center" />
          <el-table-column label="状态" align="center">
            <template slot-scope="scope">
              <div v-html="$options.filters.filterContent(scope.row.boolEnable ? 0 : 1, $enum.authStatus(), true)" />
            </template>
          </el-table-column>
          <el-table-column width="120" label="操作" align="center">
            <template slot-scope="scope">
              <el-button v-if="scope.row.boolEnable" type="text" size="small" @click="handleShowZs(scope.row)">查看余额</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <el-dialog ref="showDialog" title="余额" append-to-body :visible.sync="showDialog" width="800px">
      <div>
        <div class="u-flex u-col-top u-row-around u-flex-wrap">
          <el-card v-for="(info, k) in unionPay" :key="k + Date.now()" shadow="never" style="width: 45%" :body-style="{padding: 0}">
            <div slot="header" class="clearfix u-text-center">
              {{ info.account === selectNo ? '清分子账户' : '手续费子账户' }}
            </div>
            <div class="u-flex u-col-top u-row-between">
              <div class="u-flex-1 u-padding-10 u-border-right">
                <div class="u-m-b-10">可用余额：￥{{ $u.formatMoney(info.useBal) }}</div>
                <div class="u-m-b-10">冻结余额(平台)：￥{{ $u.formatMoney(info.pfrzBal) }}</div>
                <div class="u-m-b-10">冻结余额(银行)：￥{{ $u.formatMoney(info.bfrzBal) }}</div>
                <div class="u-m-b-10">在途余额(入)：￥{{ $u.formatMoney(info.iwayBal) }}</div>
                <div class="u-m-b-10">在途余额(出)：￥{{ $u.formatMoney(info.owayBal) }}</div>
                <div class="u-m-b-10">账面余额：￥{{ $u.formatMoney(info.actBal) }}</div>
              </div>
            </div>
            <div v-if="info.account !== selectNo" class="u-text-center u-p-b-10">
              <el-button type="primary" @click="handleWithdraw(info)">提现</el-button>
            </div>
          </el-card>
        </div>
      </div>
    </el-dialog>
    <el-dialog ref="showDialog" title="余额" append-to-body :visible.sync="showDialogZhaoHang" width="800px">
      <div>
        <div class="u-flex u-col-top u-row-around u-flex-wrap">
          <div class="u-flex-1 u-padding-10 u-border-right">
            <div class="u-m-b-10">可用余额：￥{{ zhaoHangBalance.actbal }}</div>
            <div class="u-m-b-10">账号：{{ zhaoHangBalance.accnbr }}</div>
            <div class="u-m-b-10">记账子单元名称：{{ zhaoHangBalance.dmanam }}</div>
          </div>
          <div class="u-text-center u-p-b-10">
            <el-button type="primary" @click="handleWithdrawZs()">提现</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="清分" :visible.sync="clearDialog" width="600px">
      <el-form ref="clearForm" v-loading="clearLoading" :model="form" :rules="rules" label-width="100px" style="max-width: 400px">
        <el-form-item label="商户" prop="companyId">
          <el-select v-model="form.companyId" filterable placeholder="请选择">
            <el-option v-for="(i) in shopUnionPayList" :key="i.id" :label="i.companyName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="清分金额" prop="amount">
          <el-input v-model.number="form.amount" type="number" min="0" placeholder="请输入" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('clearForm')">确定</el-button>
          <el-button @click="clearDialog = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="对账文件下载" :visible.sync="showDownload" width="600px">
      <el-form ref="downloadForm" v-loading="downloadLoading" :model="downloadForm" :rules="downloadRules" label-width="100px" style="max-width: 400px">
        <el-form-item label="时间" prop="date">
          <el-date-picker v-model="downloadForm.date" value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('downloadForm')">确定</el-button>
          <el-button @click="downloadLoading = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog title="提现" :visible.sync="withdrawDialog" width="600px">
      <withdraw-by-union-pay ref="withdrawForm" :tax-id="taxId" @success="withdrawDialog = false" />
    </el-dialog>
    <el-dialog title="提现" :visible.sync="withdrawDialogZs" width="600px">
      <withdraw-by-zhao-hang ref="withdrawFormZs" :tax-id="taxId" @success="withdrawDialogZs = false" />
    </el-dialog>
    <el-dialog title="提现" :visible.sync="alipayWithdrawDialog">
      <withdraw-by-alipay ref="withdrawByAlipay" width="600px" :tax-id="taxId" @success="alipayWithdrawDialog = false" />
    </el-dialog>
    <!-- 银联支付宝-开通子账户 -->
    <el-dialog title="开通子账户" :visible.sync="alipayUnionPaySignDialog">
      <alipay-union-pay-sign ref="alipayUnionPaySign" width="600px" :uid="uuidAlipayUnionPay" :type="alipayUnionpaySignType" @success="alipayUnionPaySignDialog = false" />
    </el-dialog>
    <el-dialog title="支付宝银联联动余额" :visible.sync="alipayUnionPayBalanceDialog.show">
      <el-row v-loading="alipayUnionPayBalanceDialog.loading" class="">
        <div class="u-m-b-10">可用余额：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.useBal) }}</div>
        <div class="u-m-b-10">冻结余额(平台)：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.pfrzBal) }}</div>
        <div class="u-m-b-10">冻结余额(银行)：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.bfrzBal) }}</div>
        <div class="u-m-b-10">在途余额(入)：￥{{$u.formatMoney(alipayUnionPayBalanceDialog.data.iwayBal)  }}</div>
        <div class="u-m-b-10">在途余额(出)：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.owayBal) }}</div>
        <div class="u-m-b-10">账面余额：￥{{ $u.formatMoney(alipayUnionPayBalanceDialog.data.actBal) }}</div>
      </el-row>
    </el-dialog>
    <el-dialog title="支付宝银联联动提现" :visible.sync="alipayUnionPayWithdrawDialog">
      <withdraw-by-alipay-union-pay ref="withdrawByAlipayUnionPay" width="600px" :tax-id="taxId" @success="alipayUnionPayWithdrawDialog = false" />
    </el-dialog>
    <el-dialog ref="showDialog" title="支付宝银联退款" append-to-body :visible.sync="showDialogRefund" width="800px">
      <!--  ref="downloadForm" :rules="refundRules" -->
      <el-form v-loading="refundLoading" :model="refundForm" label-width="100px" style="max-width: 400px">
        <el-form-item label="商户" prop="merchantId" required>
          <el-select v-model="refundForm.merchantId" style="width: 100%" placeholder="商户" clearable filterable>
            <el-option v-for="i in shopList" :key="i.id" :label="i.companyName" :value="i.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="退款金额" prop="merchantId" required>
          <el-input v-model="refundForm.serviceCharge " type="number" placeholder="请输入退款金额" />
        </el-form-item>
        <div class="u-text-center u-p-b-10">
          <el-button type="primary" @click="alipayUnionPayRefundBtn()">退款</el-button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getTotalShopList } from '@/api/common'
import { creatAUSign, getUnionBalance, getShopUnionList, clarify, downloadBillFile, getTaxAlipayList, getAlipayAgreement, getAlipayBalance, alipayUnSign, getAlipayUnionPayList, getAlipayUnionPayBalance, feeSubAccountRefund, getUnionPayList, queryTaxZS, queryTaxZSBalance,queryTaxAlipayBalance } from '@/api/service'
import WithdrawByUnionPay from '@/views/service/components/WithdrawByUnionPay'
import WithdrawByAlipay from '@/views/service/components/WithdrawByAlipay'
import WithdrawByAlipayUnionPay from '@/views/service/components/WithdrawByAlipayUnionPay'
import AlipayUnionPaySign from '@/views/service/components/AlipayUnionPaySign'
import WithdrawByZhaoHang from '@/views/service/components/WithdrawByZhaoHang'
// import { downFile } from '@/utils'
// 银联信息
const _defaultForm = () => {
  return {
    amount: null,
    companyId: '',
    taxUnionpayId: ''
  }
}
const _defaultDownloadForm = () => {
  return {
    date: '',
    taxUnionpayId: ''
  }
}

export default {
  name: 'UnionList',
  components: { AlipayUnionPaySign, WithdrawByAlipayUnionPay, WithdrawByUnionPay, WithdrawByAlipay, WithdrawByZhaoHang },
  props: {
    taxId: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      uuidAlipayUnionPay: '',
      alipayUnionpaySignType: '',
      showDialogRefund: false,
      refundLoading: false,
      shopList: [],
      refundForm: {
        merchantId: '',
        serviceCharge: '',
        taxId: '',
        taxUId: ''
      },
      activeName: 'unionPay',
      showDialog: false,
      listLoading: false,
      showLoading: false,
      showDownload: false,
      downloadLoading: false,
      unionPay: [],
      selectNo: undefined,
      showDialogZhaoHang: false,
      zhaoHangBalance: {}, // 招行余额查询结果
      list: [],
      pageTotal: 0,
      clearDialog: false,
      clearLoading: false,
      shopUnionPayList: [],
      rules: {
        amount: [
          { required: true, trigger: 'blur', message: '请输入清分金额' }
        ],
        companyId: [
          { required: true, trigger: 'change', message: '请选择商户' }
        ]
      },
      form: Object.assign({}, _defaultForm()),
      downloadRules: {
        date: [
          { required: true, trigger: 'change', message: '请选择时间' }
        ]
      },
      downloadForm: Object.assign({}, _defaultDownloadForm()),
      alipayData: undefined,
      balanceLoading: false,
      alipayBalance: '获取中',
      withdrawDialog: false,
      alipayUnionPayData: undefined,
      alipayUnionPaySignDialog: false,
      alipayUnionPayWithdrawDialog: false,
      alipayUnionPayBalanceDialog: {
        loading: false,
        data: {},
        show: false
      },
      withdrawDialogZs: false,
      alipayWithdrawDialog: false
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.listLoading = true
      if (this.activeName === 'unionPay') {
        getUnionPayList({
          taxId: this.taxId
        }).then(res => {
          this.list = res.data || []
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      } else if (this.activeName === 'AliPayUnionPay') {
        getAlipayUnionPayList({
          taxId: this.taxId
        }).then(res => {
          const data = res.data || []
          const isNull = !data.length
          this.alipayUnionPayData = isNull ? [] : data
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      } else if (this.activeName === 'alipay') {
        getTaxAlipayList({
          taxId: this.taxId
        }).then(res => {
          const data = res.data || []
          const isNull = !data.length
          this.alipayData = isNull ? [] : data
          if (!isNull) {
            // this.handleAlipayBalance(data)
          }
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      } else {
        queryTaxZS({
          taxId: this.taxId
        }).then(res => {
          this.listLoading = false
          if (res.obj) {
            this.list = [res.obj]
          } else {
            this.list = res.data || []
          }
        }).catch(res => {
          this.listLoading = false
        })
      }
    },
    handleAlipayAgreement(isNew, row = {}) {
      this.listLoading = true
      getAlipayAgreement({
        taxId: this.taxId,
        alipaySignId: row.id
      }, isNew === true).then(res => {
        if (isNew) {
          this.fetchData()
        } else {
          window.open(res.obj)
        }
        this.listLoading = false
      }).catch(res => {
        this.listLoading = false
      })
    },
    handleAlipayUnionPayAgreement() {
      // this.alipayUnionPaySignDialog = true
      this.listLoading = true
      creatAUSign({
        taxId: this.taxId
      }).then(res => {
        this.listLoading = false
        this.fetchData()
      }).catch(res => {
        this.listLoading = false
      })
    },
    handleAlipayUnionPaySub(item) {
      this.uuidAlipayUnionPay = item.uid
      this.alipayUnionpaySignType = item.alipayUnionpaySignType
      this.alipayUnionPaySignDialog = true
    },
    // 退款时先获取商户list
    handleAlipayUnionPayRefund(row = {}) {
      this.showDialogRefund = true
      this.refundForm.taxId = this.taxId
      this.refundForm.taxUId = row.uid
      this.refundForm.serviceCharge = ''
      getTotalShopList().then(res => {
        this.shopList = res.data || []
      })
    },
    // 银联支付宝退款
    alipayUnionPayRefundBtn() {
      if (this.$u.test.isEmpty(this.refundForm.serviceCharge)) {
        return this.$message.error('请输入金额')
      }
      this.refundLoading = true
      feeSubAccountRefund(this.refundForm).then(res => {
        this.refundLoading = false
        this.showDialogRefund = false
        if (res.data) {
          window.open(res.data)
        }
        console.log('getAlipayFundTransPagePay', res)
      }).catch(res => {
        this.refundLoading = false
        this.showDialogRefund = false
        // this.$message.error(res.message)
      })
    },
    async handleAlipayBalance(arr) {
      const newData = []
      await Promise.all(arr.map(async(row) => {
        const balance = await getAlipayBalance({
          taxId: this.taxId,
          alipaySignId: row.id
        }).then(res => {
          return '￥' + (res.obj || 0)
        }).catch(res => {
          return '获取失败'
        })
        newData.push(Object.assign({}, row, {
          alipayBalance: balance
        }))
      }))
      this.alipayData = newData
    },
    handleAlipayUnionPayBalance(row) {
      this.alipayUnionPayBalanceDialog.show = true
      this.alipayUnionPayBalanceDialog.loading = true
      getAlipayUnionPayBalance({
        signOrderId: row.signOrderId,
        alipayUnionSignType: row.alipayUnionpaySignType
      }).then(res => {
        this.alipayUnionPayBalanceDialog.data = res.obj || {}
        this.alipayUnionPayBalanceDialog.loading = false
      }).catch(res => {
        this.alipayUnionPayBalanceDialog.loading = false
      })
    },
    handleAlipayUnsign(row) {
      this.$confirm('你确定要解约吗?', '系统提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.listLoading = true
        alipayUnSign({
          taxId: this.taxId,
          alipaySignId: row.id
        }).then(res => {
          this.$message.success(res.message)
          this.listLoading = false
        }).catch(res => {
          this.listLoading = false
        })
      }).catch(() => {})
    },
    handleShow(row) {
      this.showDialog = true
      this.showLoading = true
      this.selectNo = row.clearNo
      getUnionBalance({
        taxUnionpayId: row.id
      }).then(res => {
        this.showLoading = false
        const data = res.data || []
        console.log(data)
        data.forEach(val => {
          val.taxUnionpayId = row.id
          return val
        })
        this.unionPay = data
      }).catch(res => {
        this.showLoading = false
      })
    },
    handleShowAliPay(row) {
      this.showDialog = true
      this.showLoading = true
      this.selectNo = row.clearNo
      queryTaxAlipayBalance({
        taxAlipayId: row.id
      }).then(res => {
        this.showLoading = false
        const data = res.data || []
        console.log(data)
        data.forEach(val => {
          val.taxUnionpayId = row.id
          return val
        })
        this.unionPay = data
      }).catch(res => {
        this.showLoading = false
      })
    },
    handleShowZs(row) {
      this.showLoading = true
      this.selectNo = row.clearNo
      queryTaxZSBalance({
        taxZspayId: row.id
      }).then(res => {
        this.showLoading = false
        if (res.obj) {
          try {
            this.zhaoHangBalance = JSON.parse(res.obj.result).response.body.ntdmabalz[0]
            this.zhaoHangBalance.taxZspayId = row.id
            this.showDialogZhaoHang = true
          } catch (error) {
            console.log('服务器返回格式有变')
          }
        } else {
          console.log('服务器返回格式有变')
        }
      }).catch(res => {
        this.showLoading = false
      })
    },
    handleClear(row) {
      this.form.taxUnionpayId = row.id
      this.fetchShopList(row.id)
      if (this.$refs.clearForm) {
        this.$refs.clearForm.resetFields()
      }
      this.clearDialog = true
    },
    fetchShopList(taxUnionpayId) {
      this.clearLoading = true
      getShopUnionList({
        taxUnionpayId: taxUnionpayId
      }).then(res => {
        this.shopUnionPayList = res.data || []
        this.clearLoading = false
      }).catch(res => {
        this.clearLoading = false
      })
    },
    handleDownLoad(row) {
      this.downloadForm.taxUnionpayId = row.id
      if (this.$refs.downloadForm) {
        this.$refs.downloadForm.resetFields()
      }
      this.showDownload = true
      this.downloadLoading = false
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (formName === 'clearForm') {
            this.clearLoading = true
            clarify(this.form).then(res => {
              this.$message.success(res.message)
              this.clearDialog = false
              this.clearLoading = false
            }).catch(res => {
              this.clearLoading = false
            })
          } else {
            // this.downloadLoading = true
            const form = Object.assign({}, this.downloadForm)
            const url = downloadBillFile() + '?taxUnionpayId=' + form.taxUnionpayId + '&beginDate=' + form.date[0] + '&endDate=' + form.date[1]
            window.open(url, '_blank')
            // downloadBillFile({
            //   taxUnionpayId: form.taxUnionpayId,
            //   beginDate: form.date[0],
            //   endDate: form.date[1]
            // }).then(res => {
            //   downFile(res.obj, '对账文件.xlsx')
            //   this.showDownload = false
            //   this.downloadLoading = false
            // }).catch(res => {
            //   this.downloadLoading = false
            // })
          }
        }
      })
    },
    handleWithdraw(row) {
      this.withdrawDialog = true
      this.$nextTick(() => {
        this.$refs.withdrawForm.setData({
          companyUnionpayId: row.id,
          taxUnionpayId: row.taxUnionpayId
        })
      })
    },
    handleWithdrawZs() {
      this.withdrawDialogZs = true
      this.$nextTick(() => {
        this.$refs.withdrawFormZs.setData({
          taxZspayId: this.zhaoHangBalance.taxZspayId
        })
      })
    },
    handleAlipayWithdraw(row) {
      this.alipayWithdrawDialog = true
      this.$nextTick(() => {
        this.$refs.withdrawByAlipay.setData({
          alipaySignId: row.id
        })
      })
    },
    handleAlipayUnionPayWithdraw(row) {
      this.alipayUnionPayWithdrawDialog = true
      this.$nextTick(() => {
        this.$refs.withdrawByAlipayUnionPay.setData({
          signOrderId: row.signOrderId,
          alipayUnionpaySignType: row.alipayUnionpaySignType
        })
      })
    }
  }
}
</script>

<style scoped>
</style>
