<template>
  <div class="u-m-25 u-p-30 bg-white">
    <el-form ref="formData" :model="formData" :rules="rules" label-width="250px" style="max-width: 800px">
      <el-form-item label="个体户名称" prop="ibname">
        <el-input v-model="formData.ibname" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item label="核定税种" required prop="bizType">
        <el-select v-model="formData.bizType" style="width: 100%" placeholder="请选择核定税种">
          <el-option v-for="(i, k) in bizType" :key="k" :label="i.name" :value="i.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="ibtaxNo">
        <el-input v-model="formData.ibtaxNo" placeholder="请输入统一社会信用代码" />
      </el-form-item>
      <el-form-item label="营业执照的注册日期" prop="buildDateTime">
        <el-date-picker v-model="formData.buildDateTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
      </el-form-item>
      <el-form-item label="园区" prop="bizPark">
        <el-input v-model="formData.bizPark" placeholder="请输入园区" />
      </el-form-item>
      <el-form-item label="地址" required>
        <el-cascader
          v-model="selectedOptions"
          style="width: 300px"
          size="medium"
          :options="options"
          @change="handleChange"
        />
      </el-form-item>
      <el-form-item label="注册资金" prop="registeredMoney">
        <el-input v-model.number="formData.registeredMoney" type="number" placeholder="请输入注册资金" />
      </el-form-item>
      <el-form-item label="经营场所" prop="businessAddress">
        <el-input v-model="formData.businessAddress" placeholder="请输入经营场所" />
      </el-form-item>
      <el-form-item label="行业类型" prop="mainIndustry" required>
        <el-select v-model="formData.mainIndustry" filterable @change="changeIndustry">
          <el-option v-for="(i, k) in mainIndustryList" :key="k" :value="i.value" :label="i.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="经营范围" prop="bizScope">
        <el-input v-model="formData.bizScope" placeholder="请输入经营范围" type="textarea" />
      </el-form-item>
      <el-form-item label="网络经营场所" prop="netBusinessAddress">
        <el-input v-model="formData.netBusinessAddress" placeholder="请输入网络经营场所" />
      </el-form-item>
      <el-form-item label="营业执照正本" prop="businessLicenceUrl">
        <div class="u-flex u-col-center">
          <button-upload type="image" text="上传jpg" @upload-success="handleUpload('businessLicenceUrl', $event)" />
          <el-button v-if="!$u.test.isEmpty(formData.businessLicenceUrl)" class="u-m-l-20" type="text" @click="$preViewer(formData.businessLicenceUrl)">查看</el-button>
        </div>
      </el-form-item>
      <el-form-item label="营业执照副本" prop="businessLicenceCopyUrl">
        <div class="u-flex u-col-center">
          <button-upload type="image" text="上传jpg" @upload-success="handleUpload('businessLicenceCopyUrl', $event)" />
          <el-button v-if="!$u.test.isEmpty(formData.businessLicenceCopyUrl)" class="u-m-l-20" type="text" @click="$preViewer(formData.businessLicenceCopyUrl)">查看</el-button>
        </div>
      </el-form-item>
      <el-form-item label="状态" prop="ibstate">
        <el-select v-model="formData.ibstate" style="width: 100%" placeholder="请选择状态" clearable>
          <el-option v-for="(i, k) in stateList" :key="k" :label="i.name" :value="i.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="提交日期" prop="submitDateTime">
        <el-date-picker v-model="formData.submitDateTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
      </el-form-item>
      <el-form-item label="注册日期" prop="registeredDate">
        <el-date-picker v-model="formData.registeredDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
      </el-form-item>
      <el-form-item label="税务登记日期" prop="taxRegisterDateTime">
        <el-date-picker v-model="formData.taxRegisterDateTime" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" />
      </el-form-item>
      <el-form-item label="联系人" prop="contactName">
        <el-input v-model="formData.contactName" placeholder="请输入联系人" />
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input v-model="formData.contactPhone" placeholder="请输入联系电话" />
      </el-form-item>
      <el-form-item label="服务费率" prop="serviceRate">
        <el-input v-model.number="formData.serviceRate" type="number" placeholder="请输入服务费率">
          <span
            slot="suffix"
          >
            %
          </span>
        </el-input>
      </el-form-item>
    </el-form>
    <div class="u-text-center u-p-t-20">
      <el-button type="primary" @click="submitForm('formData')">确定</el-button>
      <el-button @click="$router.go(-1)">取消</el-button>
    </div>
    <el-dialog title="选择经营者" :visible.sync="dialogTableVisible" width="1200px">
      <worker-lookup @on-select="onSelect" />
    </el-dialog>
    <viewer ref="viewer" />
  </div>
</template>

<script>
import { regionData, CodeToText, TextToCode } from 'element-china-area-data'
import { getGuid } from '@/utils'
import { getIbState, getPersonalTax } from '@/utils/enum.js'
import { edit, getEditDetail } from '@/api/individualBusiness'
import WorkerLookup from '@/views/individualBusiness/components/workerLookup'
import { getDictList } from '@/api/common'
import ButtonUpload from '@/components/Upload/buttonUpload'

const name = ['province', 'city', 'area']
const defaultForm = {
  'area': '',
  'bizPark': '',
  'bizScope': '',
  'bizType': 'SMALL',
  'buildDateTime': '',
  'businessAddress': '',
  'businessLicenceCopyUrl': '',
  'businessLicenceUrl': '',
  'candidatedNames': '',
  'city': 'string',
  'contactName': '',
  'contactPhone': '',
  'ibname': '',
  'ibstate': '',
  'ibtaxNo': '',
  'id': undefined,
  'mainIndustry': '',
  'netBusinessAddress': '',
  'province': '',
  'registeredDate': '',
  'registeredMoney': 0,
  'serviceRate': 0,
  'submitDateTime': '',
  'taxRegisterDateTime': ''
}
export default {
  components: { ButtonUpload, WorkerLookup },
  data() {
    const stateList = getIbState()
    return {
      stateList: stateList,
      // 备用名称
      otherName: [],
      selectWorker: [],
      dialogTableVisible: false,
      options: regionData,
      selectedOptions: [],
      showViewer: {
        show: false,
        url: ''
      },
      mainIndustryList: [],
      firstChange: false, // 为true时，可禁止一次bizScope变化
      formData: Object.assign({}, defaultForm),
      bizType: getPersonalTax(),
      rules: {
        companyName: [
          { required: true, message: '请输入企业名称（首选）', trigger: 'blur' }
        ],
        mainIndustry: [
          { required: true, message: '请输入行业类型', trigger: 'change' }
        ],
        bizType: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        registeredMoney: [
          { required: true, message: '请输入注册资金', trigger: 'blur' },
          { type: 'number', message: '请输入正确注册资金', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '请输入联系人', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!this.$u.test.mobile(value)) {
                callback(new Error('手机号码不正确'))
              }
              callback()
            },
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    bizScope() {
      const mainIndustry = this.formData.mainIndustry
      const mainIndustryList = this.mainIndustryList
      if (this.$u.test.isEmpty(mainIndustry)) {
        return ''
      }
      let res = ''
      for (let i = 0, len = mainIndustryList.length; i < len; i++) {
        if (mainIndustryList[i].value === mainIndustry) {
          res = mainIndustryList[i].extra
          break
        }
      }
      return res
    }
  },
  created() {
    this.formData.id = this.$route.query.id
    this.fetchData()
    this.fetchDict()
  },
  methods: {
    handleUpload(field, data) {
      this.formData[field] = data
    },
    setData(data) {
      const selectedOptions = []
      let area
      for (let i = 0, len = name.length; i < len; i++) {
        if (!this.$u.test.isEmpty(data[name[i]])) {
          if (area) {
            area = area[data[name[i]]]
            selectedOptions.push(area.code)
          } else {
            area = TextToCode[data[name[i]]]
            if (area) {
              selectedOptions.push(area.code)
            }
          }
        }
      }
      this.selectedOptions = selectedOptions
    },
    handleChange(value) {
      for (let i = 0, len = value.length; i < len; i++) {
        this.formData[name[i]] = CodeToText[value[i]]
      }
    },
    changeIndustry() {
      this.$nextTick(() => {
        if (!this.firstChange) {
          this.formData.bizScope = this.bizScope
        }
      })
      this.firstChange = false
    },
    fetchDict() {
      getDictList({
        code: 'industry'
      }).then(res => {
        const data = res.data || []
        const newData = []
        if (data.length) {
          for (let i = 0, len = data.length; i < len; i++) {
            newData.push({
              value: data[i].dictValue,
              label: data[i].dictValue,
              extra: data[i].remark
            })
          }
        }
        this.mainIndustryList = newData
      })
    },
    fetchData() {
      if (this.formData.id) {
        getEditDetail({
          individualBusinessId: this.formData.id
        }, true).then(res => {
          this.firstChange = true
          this.loading = false
          const data = res.obj || {}
          const ibname = this.$ibname(data.ibname, data.candidatedNames)
          this.formData = Object.assign(this.formData, data, {
            ibname: ibname
          })
          this.setData(this.formData)
        }).catch(res => {
          this.loading = false
        })
      }
    },
    onSelect(workers) {
      this.dialogTableVisible = false
      this.selectWorker = [].concat(workers)
    },
    // 新增备用名称
    addOtherName(name) {
      this.otherName.push({
        key: getGuid(),
        value: name || ''
      })
    },
    // 删除备用名称
    delOtherName(index) {
      this.otherName.splice(index, 1)
    },
    uploadSuccess(fileName, file) {
      this.formData[fileName] = file
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const form = Object.assign({}, this.formData)
          form.individualId = form.id
          const loading = this.$loading()
          edit(form, true).then(res => {
            this.$message.success(res.message)
            loading.close()
            this.$router.go(-1)
          }).catch(res => {
            loading.close()
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
